import * as React from 'react';
import {Box} from '@mui/material';
import {POStatus, Mode} from 'features/purchaseOrder/types';
import NotesAttachmentsView from 'features/purchaseOrder/sections/generalInfo/notes&AttachmentView';
import NotesAttachmentsEdit from 'features/purchaseOrder/sections/generalInfo/notesAttachmentEdit';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {poNotesAttachmentSagaActions} from 'redux/purchaseOrder/details/notes/poNotesAttachmentSaga';
import {useLocation} from 'react-router-dom';

interface INotesAttachMents {
  poStatus: string;
  mode: string;
}

const NotesAttachMents: React.FC<INotesAttachMents> = (props: INotesAttachMents) => {
  const {mode, poStatus} = props;
  const location: any = useLocation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch({
      type: poNotesAttachmentSagaActions.FETCH_PONOTESATTACHMENT_DATA,
      payload: {
        poId: location.state?.poId,
        file: [{}]
      }
    });
  }, []);

  const {poNotesAttachment} = useAppSelector((state) => state.poNotesAttachmentSlice);

  const decodeFileBase64 = (content: string) => {
    const getData = content?.slice(content.indexOf(',') + 1);
    // From Bytestream to Percent-encoding to Original string
    const temp = (getData && decodeURIComponent(escape(window.atob(getData)))) || '';
    return temp;
  };

  const decodeBase64 = decodeFileBase64(
    poNotesAttachment && poNotesAttachment.attachment && poNotesAttachment?.attachment[0]
  );

  return (
    <Box
      sx={{
        pt: 4,
        mx: '160px'
      }}
    >
      <>
        {mode === Mode.VIEW && poStatus?.toUpperCase() !== POStatus.DRAFT ? (
          <NotesAttachmentsView files={['xyz.doc']} fileContent={decodeBase64} />
        ) : (
          <NotesAttachmentsEdit />
        )}
      </>
    </Box>
  );
};

export default NotesAttachMents;
