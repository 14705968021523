import * as React from 'react';
import {Box} from '@mui/material';
import POItemView from 'features/purchaseOrder/sections/generalInfo/poItemsView';
import POItemEdit from 'features/purchaseOrder/sections/generalInfo/poItemsEdit';
import {POStatus, Mode, IPOItems} from 'features/purchaseOrder/types';

interface IPoItems {
  poStatus: string;
  mode: string;
  poItems: IPOItems[];
  updateProductItems: (newItems: IPOItems[]) => void;
}

const PoItems: React.FC<IPoItems> = (props: IPoItems) => {
  const {mode, poStatus} = props;

  const availableItems: IPOItems[] = props.poItems;

  const addNewItem = (item: IPOItems) => {
    const newItem = {
      ...item,
      poStatus: 'Added',
      lineNo: `00${availableItems.length + 1}`
    } as IPOItems;
    props.updateProductItems([...availableItems, newItem]);
  };

  const updateItem = (item: IPOItems) => {
    if (item.itemId) {
      const targetIndex = availableItems.findIndex((ele: IPOItems) => ele.itemId === item.itemId);
      if (targetIndex === -1) {
        return;
      }
      const updatedObj = {
        ...item,
        poStatus: item.poStatus === 'Added' ? 'Added' : 'Update'
      } as IPOItems;
      const updatedItems = [
        ...availableItems.slice(0, targetIndex),
        updatedObj,
        ...availableItems.slice(targetIndex + 1)
      ];
      props.updateProductItems([...updatedItems]);
    }
  };

  const deleteItems = (items: IPOItems[]) => {
    const newPOItems = availableItems.map((ele: IPOItems) => {
      const foundItem = items.find((item: IPOItems) => ele.itemId === item.itemId);
      if (foundItem?.itemId) {
        return {
          ...ele,
          poStatus: 'Delete'
        };
      } else return ele;
    });
    props.updateProductItems([...newPOItems]);
  };

  const addNewPOItem = (oldItem: IPOItems, newItem: IPOItems) => {
    let newPOItems: IPOItems[] = [];
    ////Add New Item
    const newMaterialItem = {
      ...newItem,
      poStatus: 'Added',
      lineNo: `00${availableItems.length + 1}`
    } as IPOItems;

    ////While delete, check if status if Added
    if (oldItem.poStatus === 'Added') {
      const targetIndex = availableItems.findIndex(
        (ele: IPOItems) => ele.itemId === oldItem.itemId
      );
      if (targetIndex > -1) {
        availableItems.splice(targetIndex, 1);
        newPOItems = availableItems;
      }
    } else {
      ///Update the status as delete
      newPOItems = availableItems.map((ele: IPOItems) => {
        if (ele.itemId === oldItem.itemId) {
          return {
            ...ele,
            poStatus: 'Delete'
          };
        } else return ele;
      });
    }
    props.updateProductItems([...newPOItems, newMaterialItem]);
  };

  return (
    <Box
      sx={{
        pt: 4,
        mx: '160px'
      }}
    >
      <>
        {mode === Mode.VIEW && poStatus?.toUpperCase() !== POStatus.DRAFT ? (
          <POItemView poItems={props.poItems.length ? props.poItems : []} />
        ) : (
          <POItemEdit
            poItems={availableItems}
            addNewItem={addNewItem}
            deleteItems={deleteItems}
            updateItem={updateItem}
            updateProductItem={addNewPOItem}
          />
        )}
      </>
    </Box>
  );
};

export default PoItems;
