import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  warehouseLoading,
  warehouseFailure,
  warehouseSuccess
} from 'redux/dashboard/master/warehouseSlice';

export const warehouseSagaActions = {
  FETCH_WAREHOUSE_DATA: 'FETCH_WAREHOUSE_DATA'
};

export function* fetchWarehouseData(): any {
  try {
    yield put(warehouseLoading());
    const {data} = yield call(ProductAPIS.getWarehouseData);
    yield put(warehouseSuccess(data));
  } catch (error) {
    yield put(warehouseFailure());
  }
}

export default function* watchFetchWarehouseData() {
  yield takeEvery(warehouseSagaActions.FETCH_WAREHOUSE_DATA, fetchWarehouseData);
}
