import {takeEvery, put, call} from 'redux-saga/effects';
import {PurchaseOrderAPIS} from 'redux/purchaseOrder/purchaseOrder_service';
import {
  supplierContactFailure,
  supplierContactSuccess,
  supplierContactLoading
} from 'redux/purchaseOrder/details/supplierContact/supplierContactSlice';

export const supplierContactSagaActions = {
  FETCH_SUPPLIERCONTACT_DATA: 'FETCH_SUPPLIERCONTACT_DATA'
};

export function* fetchSupplierContactData(action: any): any {
  try {
    yield put(supplierContactLoading());
    const {data} = yield call(PurchaseOrderAPIS.getSupplierContact, action.payload);
    yield put(supplierContactSuccess(data));
  } catch (error) {
    yield put(supplierContactFailure());
  }
}

export default function* watchFetchSupplierContactData() {
  yield takeEvery(supplierContactSagaActions.FETCH_SUPPLIERCONTACT_DATA, fetchSupplierContactData);
}
