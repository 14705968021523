import * as React from 'react';
import {styled, TextField} from '@mui/material';
import NumberFormat, {InputAttributes} from 'react-number-format';

interface CustomProps {
  id: string;
  onChange: (event: {target: {name: string; value: string}}) => void;
  name: string;
}

const CustomInput = styled(TextField)(({theme}) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 3,
      border: '0px'
    },
    '&:hover fieldset': {
      borderColor: '#0ab093'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    }
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grayscale?.[200]}`,
    fontSize: 16,
    height: '32px',
    padding: '0 4px',
    transition: theme.transitions.create(['border-color', 'box-shadow'])
  }
}));

export const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const {onChange, id, ...others} = props;

    return (
      <NumberFormat
        id={`custom-input${id}`}
        {...others}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator
        isNumericString
        prefix='$'
      />
    );
  }
);

export const MaskedCustomInput = styled(TextField)(({theme}) => ({
  borderRadius: 4,
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  border: '1px solid #ced4da',
  fontSize: 16,
  height: '32px',
  padding: '0 4px',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  '& .MuiOutlinedInput-root': {
    '& .MuiInputBase-input': {
      height: '32px',
      padding: '0 4px'
    },
    '& fieldset': {
      borderRadius: 3,
      border: '0px'
    },
    '&.Mui-focused fieldset': {
      borderRadius: 4,
      borderColor: '#80bdff'
    }
  }
}));

export default CustomInput;
