import {Grid, Box, Typography, Stack, useTheme} from '@mui/material';
import React from 'react';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {generateLabelValue} from 'util/arrayOperations';
import CustomSelect from 'components/baseComponents/customSelect';

const buyerIdOpts = [
  {
    label: '80',
    value: '80'
  }
];
const initialState = {
  departments: 'Grocery',
  class: 'Snacks & Chips',
  subClass: 'All Subclasses',
  supplyManagement: ''
};
interface IPropType {
  atpEnabler?: (val: boolean) => void;
}
const Preferences: React.FC<IPropType> = (props: any) => {
  const [dropDownValue, setDropDownValue] = React.useState(initialState);
  const onDropDownChange = (event: any) => {
    setDropDownValue({
      ...dropDownValue,
      [event.target.name]: event.target.value
    });
  };
  const theme = useTheme();
  const dispatch = useAppDispatch();
  //For Binding the MasterData
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
  }, []);
  const {master} = useAppSelector((state) => state.masterSlice);
  const [dataOne, dataTwo, dataThree] = master;
  return (
    <>
      <Box id='profile-body-preferences' sx={{paddingTop: '20px'}}>
        <Stack sx={{marginBottom: '20px', paddingTop: '10px'}}>
          <Typography
            sx={{
              height: '22px',
              width: '130px',
              color: theme.palette.common.black,
              fontFamily: 'Roboto Medium',
              fontSize: '16px',
              letterSpacing: '0.15px',
              lineHeight: '22px'
            }}
          >
            Default Settings
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack sx={{margin: 0}}>
              <Typography
                variant='caption'
                sx={{
                  height: '14px',
                  width: '68px',
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '14px',
                  letterSpacing: '0.4px'
                }}
              >
                Departments
              </Typography>
              <CustomSelect
                id='custom-select-departments'
                name='departments'
                value={dropDownValue.departments}
                options={generateLabelValue(dataOne?.data, 'departmentName')}
                onChange={onDropDownChange}
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  border: `1px solid ${theme.palette.grayscale?.[200]}`,
                  borderRadius: 1
                }}
              />
            </Stack>
            <Stack sx={{paddingTop: '10px'}}>
              <Typography
                variant='caption'
                sx={{
                  height: '14px',
                  width: '32px',
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '14px',
                  letterSpacing: '0.4px'
                }}
              >
                Class
              </Typography>
              <CustomSelect
                id='custom-select-class'
                name='class'
                value={dropDownValue.class}
                options={generateLabelValue(dataTwo?.data, 'className')}
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  px: '5px',
                  border: `1px solid ${theme.palette.grayscale?.[200]}`,
                  borderRadius: 1
                }}
                onChange={onDropDownChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography
                variant='caption'
                sx={{
                  height: '14px',
                  width: '121px',
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '14px',
                  letterSpacing: '0.4px'
                }}
              >
                Supply Measurement
              </Typography>
              <CustomSelect
                id='custom-select-supply-measurement'
                options={buyerIdOpts}
                value='80'
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  px: '5px',
                  border: `1px solid ${theme.palette.grayscale?.[200]}`,
                  borderRadius: 1
                }}
                onChange={onDropDownChange}
              />
            </Stack>
            <Stack sx={{paddingTop: '10px'}}>
              <Typography
                variant='caption'
                sx={{
                  height: '14px',
                  width: '32px',
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '14px',
                  letterSpacing: '0.4px'
                }}
              >
                Subclass
              </Typography>
              <CustomSelect
                id='custom-select-supply-measurement'
                value={dropDownValue.subClass}
                options={generateLabelValue(dataThree?.data, 'subClassName')}
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  px: '5px',
                  border: `1px solid ${theme.palette.grayscale?.[200]}`,
                  borderRadius: 1
                }}
                onChange={props.handleCancel}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Preferences;
