import * as React from 'react';
import {Box, Tab, Tabs, Stack, Paper, Divider, Button, Typography, styled} from '@mui/material';
import CustomDrawer from 'components/baseComponents/customDrawer';
import {ReactComponent as RightArrow} from 'assets/images/icons/Icons & UI-Carets-Caret-Right_Active.svg';
import {ReactComponent as Eraser} from 'assets/images/icons/Icons & UI-Edit-Erase_Blue.svg';
import CustomToggle from 'components/baseComponents/customToggle';
import CustomInput, {
  MaskedCustomInput,
  NumberFormatCustom
} from 'components/baseComponents/customInput';
import CustomSelect from 'components/baseComponents/customSelect';
import EnhancedTable from 'components/customTable/enhancedTable';
import {IColumnCell} from 'components/customTable/types';
import SalesListGrid from 'features/product/details/salesList';
import StoresList from 'features/product/details/storesList';
import OrderListGrid from 'features/product/details/orderList';
import DemandListGrid from 'features/product/details/demandList';
import DemandForecasting from 'features/product/details/demandForceasting';
import PopUp from 'features/product/details/popup';
import CustomButton from 'components/baseComponents/customButton';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {useNavigate, useLocation} from 'react-router-dom';
import {retailZoneSagaActions} from 'redux/product/details/retails/retailZoneSaga';
import {useTheme} from '@mui/material';
import Warehouse from 'features/product/details/warehouse';

const CustomTab = styled(Tab)({
  padding: '12px 0',
  minWidth: 0,
  marginRight: '24px'
});

interface IProductDetailsProps {
  productId: string;
}

//Creating interface for Reatil Zone Data
interface IZoneGridProps {
  id: string;
  zone: string;
  retail: string;
  comp: string;
  margin: string;
  pcDate: string;
}

const zoneColumnCell: IColumnCell<IZoneGridProps>[] = [
  {
    id: 'zone',
    name: 'zone',
    displayName: 'zone',
    isColumnSortable: false,
    headerTextAlign: 'left',
    sx: {
      borderBottom: 0,
      textAlign: 'left',
      paddingTop: '20px'
    }
  },
  {
    id: 'retail',
    name: 'retail',
    displayName: 'retail',
    isColumnSortable: false,
    headerTextAlign: 'left',
    prefixText: '$',
    sx: {
      borderBottom: 0,
      paddingTop: '20px'
    }
  },
  {
    id: 'comp',
    name: 'comp',
    displayName: 'comp',
    isColumnSortable: false,
    headerTextAlign: 'left',
    prefixText: '$',
    sx: {
      borderBottom: 0,
      textAlign: 'left',
      paddingTop: '20px'
    }
  },
  {
    id: 'margin',
    name: 'margin',
    displayName: 'margin',
    isColumnSortable: false,
    headerTextAlign: 'left',
    suffixText: '%',
    sx: {
      borderBottom: 0,
      textAlign: 'left',
      paddingTop: '20px'
    }
  },
  {
    id: 'pcDate',
    name: 'pcDate',
    displayName: 'pc date',
    isColumnSortable: false,
    headerTextAlign: 'left',
    sx: {
      borderBottom: 0,
      textAlign: 'left',
      paddingTop: '20px'
    }
  }
];

const data = [
  {
    label: 'Pre-paid',
    value: 'prepaid'
  },
  {
    label: 'Owned',
    value: 'owned'
  }
];

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = styled((props: TabPanelProps) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{px: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
})<TabPanelProps>();

const ProductDetailGrid: React.FC<IProductDetailsProps> = (props) => {
  const [value, setValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [toggleValue, setToggleValue] = React.useState<string>('margin');
  const [shipping, setShipping] = React.useState<string>('prepaid');
  const [actualMargin, setActualMargin] = React.useState<string>('');
  const [retail, setRetail] = React.useState<string>('');
  const [avgCost, setAvgCost] = React.useState<string>('1.99');
  const [additionalCost, setAdditionalCost] = React.useState<string>('0.55');
  const [actionOpen, setActionOpen] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const theme = useTheme();
  const productId = props.productId;
  if (!productId || productId === '') {
    navigate('/Product');
  }
  /**For Retail zone Data */
  const {retailZone} = useAppSelector((state) => state.retailZoneSlice);
  const [retailZoneData, setRetailZoneData] = React.useState(retailZone);
  React.useEffect(() => {
    dispatch({
      type: retailZoneSagaActions.FETCH_RETAILZONE_DATA
    });
  }, []);
  React.useEffect(() => {
    setRetailZoneData(retailZone);
  }, [retailZone]);
  // Creating zoneRowData constant
  const zoneRowData: IZoneGridProps[] = retailZoneData.length > 0 ? retailZoneData : [];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getActualMargin = () => {
    setActualMargin(
      `${Math.round(Number(retail) - Number(avgCost) - Number(additionalCost)) / Number(retail)}`
    );
  };

  const handleAction = () => {
    setActionOpen(!actionOpen);
  };

  const resetForm = () => {
    setActualMargin('');
    setRetail('');
    setAvgCost('1.99');
    setShipping('prepaid');
  };

  const inputHandler = (e: any) => {
    switch (e.target.name) {
      case 'retail':
        setRetail(e.target.value);
        break;
      case 'avgCost':
        setAvgCost(e.target.value);
        break;
      case 'shipping':
        setShipping(e.target.value);
        break;
      default:
        setAdditionalCost(e.target.value);
    }
  };

  return (
    <Box sx={{mt: 5, pb: '44px'}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            '& .MuiTab-root': {
              color: theme.palette?.grayscale?.[400],
              p: '12px 0',
              mr: 3,
              minWidth: 'fit-content'
            }
          }}
        >
          <CustomTab value={0} label='SALES' />
          <CustomTab value={1} label='WAREHOUSE' />
          <CustomTab value={2} label='STORE' />
          <CustomTab value={3} label={'ON ORDER & RCVG'} />
          <CustomTab value={4} label='DEMAND TREND' />
          {(location.state.itemId === '000000000000002826' ||
            location.state.itemId === '000000000000002827' ||
            location.state.itemId === '000000000000000027') && (
            <CustomTab value={5} label='DEMAND FORECASTING' />
          )}
        </Tabs>
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{
            p: '12px 0px',
            '& div:nth-child(1)': {
              marginRight: '15px'
            },
            width: '272px'
          }}
        >
          <CustomButton
            variant={'outlined'}
            id='product-details-detailGrid'
            onClick={() => {
              setDrawerOpen(true);
            }}
          >
            <Typography variant='caption' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
              RETAIL ZONES
            </Typography>
          </CustomButton>
          <CustomButton
            onClick={handleAction}
            variant={'contained'}
            id='product-details-detailGrid-addPO'
          >
            <Typography variant='caption' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
              ADD TO PO
            </Typography>
          </CustomButton>
        </Stack>
      </Box>
      {actionOpen && <PopUp open={actionOpen} handleAction={handleAction} title='Add To PO' />}
      <TabPanel value={value} index={0}>
        <SalesListGrid productID={productId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* <EnhancedTable<IProductWarehouseGrid>
          rowData={rowData}
          columnCell={columnCell}
          rowKey={'wh'}
          defaultSortKey={'wh'}
          rowsPerPage={9}
          showTags={true}
          id='product-details-warehouse'
        /> */}
        <Warehouse productId={productId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StoresList productId={productId} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <OrderListGrid productId={productId} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <DemandListGrid productId={productId} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DemandForecasting productId={productId} />
      </TabPanel>
      <CustomDrawer
        id='customDrawer-productDetailGrid'
        open={drawerOpen}
        anchor='right'
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <Box sx={{overflowY: 'scroll', height: '100vh'}}>
          <Paper sx={{width: '480px', height: '682px'}}>
            <Stack direction='row' sx={{margin: 2}}>
              <RightArrow onClick={() => setDrawerOpen(false)} />
              <Typography
                sx={{
                  color: theme.palette?.grayscale?.[600],
                  ml: '2.34px',
                  mt: '4px',
                  height: '24px',
                  width: '114px',
                  fontFamily: 'Roboto Medium',
                  fontSize: '20px',
                  fontWeight: '500',
                  letterSpacing: '0.15px',
                  lineHeight: '24px'
                }}
              >
                Retail Zones
              </Typography>
            </Stack>
            <Divider />
            <Box sx={{margin: 3}}>
              <Typography
                sx={{
                  color: theme.palette?.grayscale?.[600],
                  height: '24px',
                  width: '114px',
                  fontFamily: 'Roboto Regular',
                  fontSize: '20px',
                  fontWeight: '500',
                  letterSpacing: '0.15px',
                  lineHeight: '24px'
                }}
              >
                Zone Pricing
              </Typography>
              <Box
                sx={{
                  marginTop: '20px',
                  marginLeft: '0px',
                  '& .MuiTableContainer-root': {
                    '& .MuiTableHead-root': {
                      '& th': {
                        paddingLeft: 0
                      }
                    },
                    '& .MuiTableBody-root': {
                      '& td': {
                        paddingLeft: 0
                      }
                    }
                  }
                }}
              >
                <EnhancedTable<IZoneGridProps>
                  width={'300px'}
                  rowData={zoneRowData}
                  columnCell={zoneColumnCell}
                  rowKey={'zone'}
                  defaultSortKey={'zone'}
                  componentType='box'
                  dense={true}
                  hidePagination
                  borderless={true}
                  id='product-details-zone'
                />
              </Box>
            </Box>
          </Paper>
          <Box sx={{bgcolor: 'grayscale.100', minheight: '382px'}}>
            <Stack direction={'row'} sx={{width: '100%', justifyContent: 'space-between'}}>
              <Typography
                variant='h6'
                sx={{
                  color: theme.palette?.grayscale?.[600],
                  pt: 3,
                  pl: 3,
                  height: '24px',
                  width: '93px',
                  fontFamily: 'Roboto Regular',
                  fontSize: '20px',
                  fontWeight: '500',
                  letterSpacing: '0.15px',
                  lineHeight: '24px'
                }}
              >
                Calculator
              </Typography>
              <Button
                id='product-detail-grid-reset'
                sx={{
                  '&:hover': {
                    bgcolor: 'unset'
                  },
                  pt: 3,
                  pr: 3
                }}
                onClick={resetForm}
              >
                <Eraser />
                <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                  RESET
                </Typography>
              </Button>
            </Stack>
            <Stack pt={3} pl={3}>
              <Typography
                variant='subtitle2'
                sx={{
                  height: '20px',
                  width: '60px',
                  color: theme.palette?.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '14px',
                  fontWeight: 500,
                  letterSpacing: '0.1px',
                  lineHeight: '20px'
                }}
              >
                Calculate
              </Typography>
              <CustomToggle
                id='product-details-grid'
                value={toggleValue}
                options={[
                  {value: 'margin', label: 'Margin'},
                  {value: 'retail', label: 'Retail'}
                ]}
                handleChange={(e, value) => {
                  setToggleValue(value);
                }}
              />
            </Stack>
            <Stack pl={3} pt={4} direction={'row'} spacing={3}>
              <Stack>
                <Typography
                  variant='subtitle2'
                  sx={{
                    height: '14px',
                    width: '33px',
                    color: theme.palette?.grayscale?.[600],
                    fontFamily: 'Roboto Medium',
                    fontSize: '12px',
                    fontWeight: '500',
                    letterSpacing: '0.4px',
                    lineHeight: '14px'
                  }}
                >
                  {toggleValue === 'retail' ? 'Margin' : 'Retail'}
                </Typography>
                <MaskedCustomInput
                  id='retail-numberformat-input'
                  name='retail'
                  value={retail}
                  onChange={inputHandler}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any
                  }}
                  sx={{width: '200px'}}
                  variant='outlined'
                />
              </Stack>
              <Stack>
                <Typography
                  variant='subtitle2'
                  sx={{
                    height: '14px',
                    width: '77px',
                    color: theme.palette?.grayscale?.[600],
                    fontFamily: 'Roboto Medium',
                    fontSize: '12px',
                    fontWeight: '500',
                    letterSpacing: '0.4px',
                    lineHeight: '14px'
                  }}
                >
                  Average Cost
                </Typography>
                <MaskedCustomInput
                  name='avgCost'
                  value={avgCost}
                  onChange={inputHandler}
                  id='avgCost-numberformat-input'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any
                  }}
                  sx={{width: '200px'}}
                  variant='outlined'
                />
              </Stack>
            </Stack>
            <Stack pl={3} pt={2} direction={'row'} spacing={3}>
              <Stack>
                <Typography
                  variant='subtitle2'
                  sx={{
                    height: '14px',
                    width: '33px',
                    color: theme.palette?.grayscale?.[600],
                    fontFamily: 'Roboto Medium',
                    fontSize: '12px',
                    fontWeight: '500',
                    letterSpacing: '0.4px',
                    lineHeight: '14px'
                  }}
                >
                  Shipping
                </Typography>
                <CustomSelect
                  id='customSelect'
                  name='shipping'
                  options={data}
                  sx={{backgroundColor: '#fff', width: '210px', px: '5px'}}
                  onChange={inputHandler}
                  value={shipping}
                />
              </Stack>
              <Stack>
                <Typography
                  variant='subtitle2'
                  sx={{
                    height: '14px',
                    width: '96px',
                    color: theme.palette?.grayscale?.[600],
                    fontFamily: 'Roboto Medium',
                    fontSize: '12px',
                    fontWeight: '500',
                    letterSpacing: '0.4px',
                    lineHeight: '14px'
                  }}
                >
                  Additional Costs
                </Typography>
                <MaskedCustomInput
                  id='additionalCost-numberformat-input'
                  name='additionalCost'
                  value={additionalCost}
                  onChange={inputHandler}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any
                  }}
                  sx={{width: '200px'}}
                  variant='outlined'
                  disabled
                />
              </Stack>
            </Stack>
            <Stack pl={3} pt={4} pb={3} direction={'row'} spacing={3} alignItems='center'>
              <Button
                id='product-detail-grid-equalto'
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'common.white',
                  width: '42px',
                  height: '32px',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'primary.main'
                  }
                }}
                onClick={getActualMargin}
              >
                =
              </Button>
              <CustomInput type='number' value={actualMargin} sx={{width: '204px'}} />
            </Stack>
          </Box>
        </Box>
      </CustomDrawer>
    </Box>
  );
};

export default ProductDetailGrid;
