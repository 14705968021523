/**
 *
 * @param arr Array of Values which contains the column name
 * @param columnName Column name of value rendering into chart
 * @defaultColor If value exists and greated than 0 return color
 * return the list of colours for the data to display
 */
export const SetColor = (arr: Array<any>, columnName: string, defaultColor?: string) => {
  const defaultValue = defaultColor || '#263EB5';
  const negativeColor = '#FE5144';
  return arr.map((ele) => {
    if (ele[columnName] && Number(ele[columnName])) {
      if (Number(ele[columnName]) >= 0) return defaultValue;
      else return negativeColor;
    } else {
      return defaultValue;
    }
  });
};
