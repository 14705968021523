import * as React from 'react';
import Typography from '@mui/material/Typography';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import EnhancedTable from 'components/customTable/enhancedTable';
import {IColumnCell} from 'components/customTable/types';
import {CustomCard} from 'components/baseComponents/customCard';
import {useTheme} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {Box, Grid, styled} from '@mui/material';
import {adPromosSagaActions} from 'redux/dashboard/adPromos/adPromosSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import moment from 'moment';
import {generateLabelValue} from 'util/arrayOperations';
import {AdDetailPageUrl, TILE_WIDTH} from 'config/constants';

interface IAdPromosGridProps {
  fromDate: string;
  toDate: string;
  region: string;
  sales: string;
  plan: string;
  margin: string;
  marginPlan: string;
  url?: string;
  id: string;
}

const CustomBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

interface IPropType {
  type: 'full' | 'compact' | string;
  filteredData: any;
}
export default function AdPromosTile(props: IPropType) {
  const [selectedRegion, setSelectedRegion] = React.useState('All Region');

  const th = useTheme();
  const dispatch = useAppDispatch();
  /**
   * dispatch adPromos tile Data
   */
  const dispatchdata = () => {
    dispatch({
      type: adPromosSagaActions.FETCH_AD_PROMOS_DATA
    });
  };
  React.useEffect(() => {
    dispatchdata();
  }, [props?.filteredData]);

  React.useEffect(() => {
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
  }, [selectedRegion]);

  const {adPromosTileData, loading, error} = useAppSelector((state) => state.adPromosTileSlice);

  const {region} = useAppSelector((state) => state.regionSlice);

  /**
   *
   * @param event dropdown onChange event
   */
  const handleSelect = (event: any): void => {
    setSelectedRegion(event?.target.value);
  };
  const defaultDateFormat = 'MM/DD/YYYY';
  const defaultShortDateFormat = 'MM/DD';

  const LinkComponent = (row: any) => {
    const from = moment(row?.fromDate).format(
      props.type == 'full' ? defaultDateFormat : defaultShortDateFormat
    );
    const to = moment(row?.toDate).format(
      props.type == 'full' ? defaultDateFormat : defaultShortDateFormat
    );

    return (
      <>
        <a target='_blank' href={AdDetailPageUrl} rel='noreferrer'>
          {`${from} - ${to}`}
        </a>
      </>
    );
  };

  const columnCellCompact: IColumnCell<IAdPromosGridProps>[] = [
    {
      id: 'fromDate',
      name: 'fromDate',
      displayName: 'DATE',
      textColor: th.palette.primary.main,
      customCell: LinkComponent
    },
    {
      id: 'sales',
      name: 'sales',
      displayName: 'sales',
      isNumeric: true,
      headerTextAlign: 'left',
      cellTextAlign: 'center',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    },
    {
      id: 'plan',
      name: 'plan',
      displayName: 'plan',
      isNumeric: true,
      headerTextAlign: 'left',
      cellTextAlign: 'center',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    }
  ];

  const columnCellFull: IColumnCell<IAdPromosGridProps>[] = [
    {
      id: 'fromDate',
      name: 'fromDate',
      displayName: 'DATE',
      textColor: th.palette.primary.main,
      customCell: LinkComponent,
      sx: {
        maxWidth: '150px'
      }
    },
    {
      id: 'region',
      name: 'region',
      displayName: 'RGN',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center'
    },
    {
      id: 'sales',
      name: 'sales',
      displayName: 'sales',
      isNumeric: true,
      headerTextAlign: 'left',
      cellTextAlign: 'center',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    },
    {
      id: 'plan',
      name: 'plan',
      displayName: 'sales plan',
      isNumeric: true,
      headerTextAlign: 'left',
      cellTextAlign: 'center',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    },
    {
      id: 'margin',
      name: 'margin',
      displayName: 'MGN',
      isNumeric: true,
      headerTextAlign: 'left',
      cellTextAlign: 'center',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    },
    {
      id: 'marginPlan',
      name: 'marginPlan',
      displayName: 'MGN Plan',
      isNumeric: true,
      headerTextAlign: 'left',
      cellTextAlign: 'center',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    }
  ];

  const Header = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{margin: 'auto'}}>
            <Typography sx={{color: 'common.black'}} variant='subtitle2' textAlign='left'>
              ADs/Promos
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <DropDownWrapper>
              <CustomSelect
                id='ADs/PromosTile'
                name={'Region'}
                value={selectedRegion}
                options={generateLabelValue(region, 'regionName') || []}
                onChange={handleSelect}
                allowAllOptions
                title={'Region'}
              />
            </DropDownWrapper>
          </Grid>
        </Grid>
      </>
    );
  };

  const Content = () => {
    return (
      <>
        <EnhancedTable<IAdPromosGridProps>
          width={'100%'}
          rowData={adPromosTileData}
          columnCell={props.type === 'full' ? columnCellFull : columnCellCompact}
          rowKey={'fromDate'}
          defaultSortKey={'fromDate'}
          allowAlternateColor={false}
          componentType='box'
          dense={true}
          hidePagination
          id={'dashboard-adPromos'}
        />
      </>
    );
  };

  if (adPromosTileData && adPromosTileData.length > 0 && !error) {
    return (
      <CustomCard
        id='adPromos'
        width={props.type === 'full' ? TILE_WIDTH.two : undefined}
        header={<Header />}
        headerSx={{marginBottom: '8px'}}
        content={
          loading ? (
            <CustomBox>
              <CustomLoader id='adProms-loader' />
            </CustomBox>
          ) : (
            <Content />
          )
        }
      />
    );
  } else {
    return (
      <CustomCard
        id='adPromos-noData'
        header={<Header />}
        width={props.type === 'full' ? TILE_WIDTH.two : undefined}
        headerSx={{marginBottom: '8px'}}
        content={
          loading ? (
            <CustomBox>
              <CustomLoader id='adProms-loader' />
            </CustomBox>
          ) : (
            <CustomError
              id='adProms-error'
              onReload={() => {
                dispatchdata();
              }}
            />
          )
        }
      />
    );
  }
}
