import {takeEvery, put, call} from 'redux-saga/effects';
import {VendorAPIS} from 'redux/vendor/vendorDetails_service';
import {
  productItemFailure,
  productItemLoading,
  productItemSuccess
} from 'redux/vendor/detailsItemTab/productItemSlice';

export const productItemSagaActions = {
  FETCH_PRODUCTITEM_DATA: 'FETCH_PRODUCTITEM_DATA'
};

export function* fetchProductItemData(action: any): any {
  try {
    yield put(productItemLoading());
    const {data} = yield call(VendorAPIS.getProductItemData, action.payload);
    yield put(productItemSuccess(data));
  } catch (error) {
    yield put(productItemFailure());
  }
}

export default function* watchFetchProductItemData() {
  yield takeEvery(productItemSagaActions.FETCH_PRODUCTITEM_DATA, fetchProductItemData);
}
