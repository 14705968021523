import {createSlice} from '@reduxjs/toolkit';
export interface productSalesListState {
  loading: boolean;
  productSalesList: Array<any>;
  error: string;
}

const initialState: productSalesListState = {
  loading: false,
  productSalesList: [],
  error: ''
};

const productSalesListSlice = createSlice({
  name: 'productSalesList',
  initialState,
  reducers: {
    productSalesListLoading: (state) => {
      state.loading = true;
    },
    productSalesListSuccess: (state, action) => {
      state.productSalesList = action.payload;
      state.loading = false;
    },
    productSalesListFailure: (state) => {
      state.loading = false;
      state.productSalesList = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default productSalesListSlice.reducer;
export const {productSalesListLoading, productSalesListSuccess, productSalesListFailure} =
  productSalesListSlice.actions;
