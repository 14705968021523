import * as React from 'react';
import {useState} from 'react';
import {Box, useTheme} from '@mui/material';
import EnhancedTable from 'components/customTable/enhancedTable';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {supplyChainSagaActions} from 'redux/vendor/detailsItemTab/supplyChainSaga';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {generateLabelValue} from 'util/arrayOperations';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {buyerSagaActions} from 'redux/dashboard/master/buyerSaga';
import {useLocation} from 'react-router-dom';

interface IPropType {
  venId: string;
}
//Below we are creating three interface as formate of API receive
interface IVendorDetails {
  date: string;
  value: string;
}
interface IVendorSupplyGridProps {
  metrics: string;
  [key: string]: any;
  id: string;
}
interface IVendorSupplyDetails {
  metrics: string;
  supplychain: [IVendorDetails];
  id: string;
}
//Below we are creating column Cell
const supplyColumnCell: IColumnCell<IVendorSupplyGridProps>[] = [
  {
    id: 'metrics',
    name: 'metrics',
    displayName: 'METRICS',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    isColumnSortable: false,
    sticky: true
  }
];
//here we are creating function to add column dynamically to table header
const addcolumnCellData = (rowData: IVendorSupplyGridProps[]) => {
  Object.keys(rowData[0] || {}).forEach((element: string) => {
    if (!supplyColumnCell.find((e) => e.id === element)) {
      supplyColumnCell.push({
        id: element,
        name: element,
        displayName: element,
        headerTextAlign: 'left',
        isGroupBorderRequire: false,
        suffixText: '%',
        isColumnSortable: false,
        sx: {
          textAlign: 'right'
        }
      });
    }
  });
  return supplyColumnCell;
};
//Below we are creating tha main component
const SupplyChain: React.FC<IPropType> = (props) => {
  const location: any = useLocation();
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const {supplyChain, loading, error} = useAppSelector((state) => state.supplyChainSlice);

  const generatePayload = (values: IFilterValues) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      temp.push({key: key, value: value, condition: 'equal'});
    }
    return temp;
  };

  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };
  //For Binding the MasterData
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: buyerSagaActions.FETCH_BUYER_DATA
    });
  }, []);

  const {master} = useAppSelector((state) => state.masterSlice);

  const [dataOne, dataTwo, dataThree] = master;
  const {buyer} = useAppSelector((state) => state.buyerSlice);
  //Here we are creating Function to formate the array of object
  const formateRowData = (listData: IVendorSupplyDetails[]) => {
    const gridData: IVendorSupplyGridProps[] = listData.map((ele: IVendorSupplyDetails) => {
      const supplyItem: IVendorSupplyGridProps = {...ele};
      const supplychainItem: IVendorDetails[] = ele.supplychain;

      supplychainItem.forEach((item: IVendorDetails) => {
        supplyItem[item.date] = item.value;
      });
      delete supplyItem.supplychain;
      return supplyItem;
    });
    return gridData;
  };
  const rowData: IVendorSupplyGridProps[] =
    supplyChain && supplyChain.length > 0 ? formateRowData(supplyChain) : [];
  //Filter Template
  const supplyFilterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewBy',
      title: 'View By',
      defaultValue: 'week',
      sx: {
        width: '178px'
      },
      options: [
        {
          label: 'week',
          value: 'week'
        },
        {
          label: 'month',
          value: 'month'
        }
      ]
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      defaultValue: 'All Departments',
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName',
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      sx: {
        width: '178px'
      },
      allowAllOptions: true,
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'buyerName',
      title: 'Buyer',
      defaultValue: 'All Buyers',
      placeholder: 'Buyers',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(buyer, 'buyerName')
    }
  ];
  const defaultData = supplyFilterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);
  React.useEffect(() => {
    dispatch({
      type: supplyChainSagaActions.FETCH_SUPPLYCHAIN_DATA,
      payload: {
        id: props.venId,
        data: generatePayload(filtervalues)
      }
    });
  }, [filtervalues]);
  if (supplyChain && supplyChain.length > 0 && !error) {
    return (
      <>
        <Box
          sx={{
            marginTop: '16px',
            '& tr td:nth-child(1)': {
              borderRight: `5px solid ${theme.palette.grayscale?.[300]}`,
              '& td': {
                border: 'none'
              }
            },
            '& tr th:nth-child(1)': {
              borderRight: `5px solid ${theme.palette.grayscale?.[300]}`,
              '& th': {
                border: 'none'
              }
            }
          }}
        >
          <GridFilter
            values={filtervalues}
            filterTemplate={supplyFilterTemplate}
            onFilterChange={onFilterApply}
            onReset={() => {
              setFilterValues(defaultData);
            }}
            hideMoreFilter={true}
            id='vendor-details-supplyChain'
          />
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <CustomLoader id='supply-chain-load' />
            </Box>
          )}
          <EnhancedTable<IVendorSupplyGridProps>
            rowData={rowData}
            columnCell={addcolumnCellData(rowData)}
            rowKey={'metrics'}
            defaultSortKey={'metrics'}
            allowAlternateColor={true}
            rowsPerPage={5}
            id='vendor-details-supplyChain'
          />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          values={filtervalues}
          filterTemplate={supplyFilterTemplate}
          onFilterChange={onFilterApply}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='vendor-details-supplyChain'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='supply-chain-load' />
          ) : (
            <CustomError id='supply-chain-error' />
          )}
        </Box>
      </>
    );
  }
};
export default SupplyChain;
