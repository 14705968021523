import {createSlice} from '@reduxjs/toolkit';

export interface IPerpetualInventoryState {
  loading: boolean;
  perpetualInventory: Array<any>;
  error: string;
}

const initialState: IPerpetualInventoryState = {
  loading: false,
  perpetualInventory: [],
  error: ''
};

const perpetualInventorySlice = createSlice({
  name: 'perpetualInventory',
  initialState,
  reducers: {
    perpetualInventoryLoading: (state) => {
      state.loading = true;
    },
    perpetualInventorySuccess: (state, action) => {
      state.perpetualInventory = action.payload;
      state.loading = false;
    },
    perpetualInventoryFailure: (state) => {
      state.loading = false;
      state.perpetualInventory = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default perpetualInventorySlice.reducer;
export const {perpetualInventoryLoading, perpetualInventorySuccess, perpetualInventoryFailure} =
  perpetualInventorySlice.actions;
