import {takeEvery, put, call} from 'redux-saga/effects';
import {PurchaseOrderAPIS} from '../../purchaseOrder_service';
import {
  poNotesAttachmentFailure,
  poNotesAttachmentLoading,
  poNotesAttachmentSuccess
} from './poNotesAttachmentSlice';

export const poNotesAttachmentSagaActions = {
  FETCH_PONOTESATTACHMENT_DATA: 'FETCH_PONOTESATTACHMENT_DATA'
};

export function* fetchPoNotesAttachmentData(action: any): any {
  try {
    yield put(poNotesAttachmentLoading());
    const {data} = yield call(PurchaseOrderAPIS.getPoNotesAttachmentData, action.payload);
    yield put(poNotesAttachmentSuccess(data));
  } catch (error) {
    yield put(poNotesAttachmentFailure());
  }
}

export default function* watchFetchPoNotesAttachmentData() {
  yield takeEvery(
    poNotesAttachmentSagaActions.FETCH_PONOTESATTACHMENT_DATA,
    fetchPoNotesAttachmentData
  );
}
