import * as React from 'react';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {Box, TableHead, TableRow, Typography} from '@mui/material';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {ContentComponent} from 'features/product/details/productUtil';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import moment from 'moment';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {districtSagaActions} from 'redux/dashboard/master/districtSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {useState} from 'react';
import {productItemStoreSagaActions} from 'redux/product/details/storeItem/storeItemSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import LineGraphComponent from 'components/tinyLineGraph';
import {viewInFilterOptions} from 'config/constants';
import {useLocation} from 'react-router-dom';
import {useTheme} from '@mui/material';

interface IRolling16WSales {
  date: string;
  value: string;
}
interface IProductStoreGridProps {
  store: string;
  wh: string;
  uom: string;
  oh: string;
  oo: string;
  ohWos: string;
  ohOoWos: string;
  avgRtl: string;
  sales: string;
  storeRcpt: string;
  rolling16WSales?: IRolling16WSales[];
  exp: string;
  id: string;
}

export const TinyLineComponent = (row: any) => {
  const rolling16WSales: IRolling16WSales[] = row?.rolling16WSales;
  ////xAxis is 16 week
  const xAxis = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16'
  ];
  const yAxis = rolling16WSales?.map((ele: IRolling16WSales) => ele.value) || [];

  return <LineGraphComponent id={`${row.store}-rolling16WSales`} xAxis={xAxis} yAxis={yAxis} />;
};

const storeColumnCell: IColumnCell<IProductStoreGridProps>[] = [
  {
    id: 'store',
    name: 'store',
    displayName: 'STORE',
    headerTextAlign: 'left',
    isGroupBorderRequire: false,
    customCell: ContentComponent
  },
  {
    id: 'wh',
    name: 'wh',
    displayName: 'WH',
    isColumnSortable: false,
    isGroupBorderRequire: true
  },
  {
    id: 'uom',
    name: 'uom',
    displayName: 'UOM',
    isColumnSortable: false,
    isGroupBorderRequire: true
  },
  {
    id: 'oh',
    name: 'oh',
    displayName: 'OH',
    isColumnSortable: true,
    isGroupBorderRequire: false,
    consolidateType: 'SUM',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'oo',
    name: 'oo',
    displayName: 'OO',
    isColumnSortable: true,
    isGroupBorderRequire: true,
    consolidateType: 'SUM',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'ohWos',
    name: 'ohWos',
    displayName: 'OH WOS',
    isColumnSortable: true,
    isGroupBorderRequire: false,
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'ohOoWos',
    name: 'ohOoWos',
    displayName: 'OH+OO WOS',
    isColumnSortable: true,
    isGroupBorderRequire: true,
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'avgRtl',
    name: 'avgRtl',
    displayName: 'AVG RTL',
    isColumnSortable: true,
    isGroupBorderRequire: true,
    prefixText: '$',
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'sales',
    name: 'sales',
    displayName: 'SALES',
    isColumnSortable: true,
    isGroupBorderRequire: false,
    consolidateType: 'SUM',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'storeRcpt',
    name: 'storeRcpt',
    displayName: 'STORE RCPT',
    isColumnSortable: true,
    isGroupBorderRequire: true,
    consolidateType: 'SUM',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'rolling16WSales',
    name: 'rolling16WSales',
    displayName: 'ROLLING 16W SALES',
    isColumnSortable: false,
    customCell: TinyLineComponent,
    isGroupBorderRequire: true
  },
  {
    id: 'exp',
    name: 'exp',
    displayName: 'EXP',
    isColumnSortable: true,
    isGroupBorderRequire: true,
    headerTextAlign: 'center'
  }
];

interface IPropType {
  productId: string;
}
const StoresListGrid: React.FC<IPropType> = (props) => {
  const location: any = useLocation();
  const theme: any = useTheme();
  const dispatch = useAppDispatch();
  //For Binding the MasterData
  React.useEffect(() => {
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
  }, []);
  const {region} = useAppSelector((state) => state.regionSlice);
  const {district} = useAppSelector((state) => state.districtSlice);

  const defaultDateFormat = 'MM/DD/YY';
  const startDate = moment().startOf('week').format(defaultDateFormat);
  const endDate = moment().format(defaultDateFormat);

  /**
   * Create the filter array to send as API request
   * @param values Array of objects
   */
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      temp.push({key: key, value: value, condition: 'equal'});
    }
    return temp;
  };

  /**
   * OnChange of filter
   * @param values Filter values
   */
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };

  const storefilterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date',
      format: 'MM/DD/YY',
      defaultValue: `${startDate}|${endDate}`,
      sx: {
        width: '210px'
      }
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View In (Sales & Receipt)',
      defaultValue: 'Units',
      sx: {
        width: '178px'
      },
      options: viewInFilterOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'region',
      title: 'Store Region',
      defaultValue: 'All Regions',
      placeholder: 'Regions',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(region, 'storeRegion')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'districts',
      title: 'Store Districts',
      defaultValue: 'All Districts',
      placeholder: 'Districts',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(district, 'storeDistrict')
    }
  ];

  const StickyHeaderCell = (props: any) => {
    const consolidatedData = generateStickyHeaderValues(props.data, storeColumnCell);
    const aws = Number(consolidatedData.avgRtl.slice(1));
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              colSpan={2}
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'Background',
                zIndex: theme.zIndex?.appBar + 1,
                paddingRight: 0
              }}
            >
              <Typography variant='subtitle2' sx={{color: 'grayscale.400', borderBottom: 0}}>
                CONSOLIDATED STATS
              </Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell align='left'>
              <Typography variant='subtitle2'>Case</Typography>
            </BorderedTableStickyCell>
            <TableStickyCell align='right'>
              <Typography variant='subtitle2'>{consolidatedData.oh}</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='right'>
              <Typography variant='subtitle2'>{consolidatedData.oo}</Typography>
            </BorderedTableStickyCell>
            <TableStickyCell align='right'>
              <Typography variant='subtitle2'>{consolidatedData.ohWos}</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='right'>
              <Typography variant='subtitle2'>{consolidatedData.ohOoWos}</Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell align='right'>
              <Typography variant='subtitle2'>{aws}</Typography>
            </BorderedTableStickyCell>
            <TableStickyCell align='right'>
              <Typography variant='subtitle2'>{consolidatedData.sales}</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='right'>
              <Typography variant='subtitle2'>{consolidatedData.storeRcpt}</Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell align='right'>
              <Typography variant='subtitle2'></Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell align='left'>
              <Typography variant='subtitle2'></Typography>
            </BorderedTableStickyCell>
          </TableRow>
        </TableHead>
      </>
    );
  };

  const defaultData = storefilterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});
  const [filtervalues, setFilterValues] = useState(defaultData);

  React.useEffect(() => {
    dispatch({
      type: productItemStoreSagaActions.FETCH_PRODUCT_ITEM_STORE_DATA,
      payload: {
        id: props.productId,
        data: generatePayload(filtervalues)
      }
    });
  }, [filtervalues]);
  const {productItemStore, loading, error} = useAppSelector((state) => state.productItemStoreSlice);
  const rowData = productItemStore.length > 0 ? productItemStore : [];
  if (productItemStore && productItemStore.length > 0 && !error) {
    return (
      <>
        <GridFilter
          values={filtervalues}
          onFilterChange={onFilterApply}
          filterTemplate={storefilterTemplate}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-details-storesList'
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='store-list-load' />
          </Box>
        )}
        <EnhancedTable<IProductStoreGridProps>
          rowData={rowData}
          columnCell={storeColumnCell}
          stickyRows={<StickyHeaderCell data={rowData} />}
          rowKey={'store'}
          defaultSortKey={'store'}
          rowsPerPage={9}
          id='product-details-storesList'
        />
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          values={filtervalues}
          onFilterChange={onFilterApply}
          filterTemplate={storefilterTemplate}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-details-storesList'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? <CustomLoader id='store-list-load' /> : <CustomError id='order-list-error' />}
        </Box>
      </>
    );
  }
};

export default StoresListGrid;
