import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  topWeeklyStoreLoading,
  topWeeklyStoreSuccess,
  topWeeklyStoreFailure
} from 'redux/dashboard/topWeeklySales/topWeeklySalesSlice';

export const topWeeklyStoreActions = {
  FETCH_TOPWEEKLYSALES_SAGA: 'FETCH_TOPWEEKLYSALES_SAGA'
};

export function* fetchTopWeeklyStoreData(action: any): any {
  try {
    yield put(topWeeklyStoreLoading());
    const {data} = yield call(DashboardServiceAPIS.getTopWeeklyStoreData, action.payload);
    yield put(topWeeklyStoreSuccess(data));
  } catch (error) {
    yield put(topWeeklyStoreFailure());
  }
}

export default function* watchFetchTopWeeklyStoreData() {
  yield takeEvery(topWeeklyStoreActions.FETCH_TOPWEEKLYSALES_SAGA, fetchTopWeeklyStoreData);
}
