import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  expiryProductListFailure,
  expiryProductListLoading,
  expiryProductListSuccess
} from 'redux/product/list/expiryList/expiryProductListSlice';

export const expiryProductListSagaActions = {
  FETCH_EXPIRYPRODUCTLIST_DATA: 'FETCH_EXPIRYPRODUCTLIST_DATA'
};

export function* fetchExpiryProductListData(action: any): any {
  try {
    yield put(expiryProductListLoading());
    const {data} = yield call(ProductAPIS.getExpiryProductListData, action.payload);
    yield put(expiryProductListSuccess(data));
  } catch (error) {
    yield put(expiryProductListFailure());
  }
}

export default function* watchFetchExpiryProductListData() {
  yield takeEvery(
    expiryProductListSagaActions.FETCH_EXPIRYPRODUCTLIST_DATA,
    fetchExpiryProductListData
  );
}
