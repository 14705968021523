import {createSlice} from '@reduxjs/toolkit';
export interface vendorSalesItemState {
  loading: boolean;
  vendorSalesItem: Array<any>;
  error: string;
}

const initialState: vendorSalesItemState = {
  loading: false,
  vendorSalesItem: [],
  error: ''
};

const vendorSalesItemSlice = createSlice({
  name: 'vendorSalesItem',
  initialState,
  reducers: {
    vendorSalesItemLoading: (state) => {
      state.loading = true;
    },
    vendorSalesItemSuccess: (state, action) => {
      state.vendorSalesItem = action.payload;
      state.loading = false;
    },
    vendorSalesItemFailure: (state) => {
      state.loading = false;
      state.vendorSalesItem = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default vendorSalesItemSlice.reducer;
export const {vendorSalesItemLoading, vendorSalesItemSuccess, vendorSalesItemFailure} =
  vendorSalesItemSlice.actions;
