import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  productItemStoreLoading,
  productItemStoreFailure,
  productItemStoreSuccess
} from 'redux/product/details/storeItem/storeItemSlice';

export const productItemStoreSagaActions = {
  FETCH_PRODUCT_ITEM_STORE_DATA: 'FETCH_PRODUCT_ITEM_STORE_DATA'
};

export function* fetchProductItemStoreData(action: any): any {
  try {
    yield put(productItemStoreLoading());
    const {data} = yield call(ProductAPIS.getStoreItemData, action.payload);
    yield put(productItemStoreSuccess(data));
  } catch (error) {
    yield put(productItemStoreFailure());
  }
}

export default function* watchFetchProductItemStoreData() {
  yield takeEvery(
    productItemStoreSagaActions.FETCH_PRODUCT_ITEM_STORE_DATA,
    fetchProductItemStoreData
  );
}
