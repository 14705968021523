import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  classPerformanceFailure,
  classPerformanceLoading,
  classPerformanceSuccess
} from 'redux/dashboard/classPerformance/classPerformanceSlice';

export const classPerformanceSagaActions = {
  FETCH_CLASS_PERFORMANCE_DATA: 'FETCH_CLASS_PERFORMANCE_DATA'
};

export function* fetchClassPerformanceData(action: any): any {
  try {
    yield put(classPerformanceLoading());
    const {data} = yield call(DashboardServiceAPIS.getClassPerformanceData, action.payload);
    yield put(classPerformanceSuccess(data));
  } catch (error) {
    yield put(classPerformanceFailure());
  }
}

export default function* watchFetchClassPerformanceData() {
  yield takeEvery(
    classPerformanceSagaActions.FETCH_CLASS_PERFORMANCE_DATA,
    fetchClassPerformanceData
  );
}
