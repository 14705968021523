import {createSlice} from '@reduxjs/toolkit';
export interface poListState {
  loading: boolean;
  poList: Array<any>;
  error: string;
}

const initialState: poListState = {
  loading: false,
  poList: [],
  error: ''
};

const poListSlice = createSlice({
  name: 'poList',
  initialState,
  reducers: {
    poListLoading: (state) => {
      state.loading = true;
    },
    poListSuccess: (state, action) => {
      state.poList = action.payload;
      state.loading = false;
    },
    poListFailure: (state) => {
      state.loading = false;
      state.poList = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default poListSlice.reducer;
export const {poListLoading, poListSuccess, poListFailure} = poListSlice.actions;
