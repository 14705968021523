import {Typography, Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import CustomButton from 'components/baseComponents/customButton';

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px'
      }}
    >
      <Typography variant='subtitle1' component={'h5'} sx={{fontWeight: 600}}>
        Page not Found Redirect to Login
      </Typography>
      <CustomButton id='notFound' onClick={() => navigate('/Login')}>
        Login
      </CustomButton>
    </Box>
  );
};
