const seasonalitytrendbacktoschool = [
  {
    store: '008-Redmond',
    demandForecast: [
      //   {date: ' 2024/07/01', value: '8944'},
      //   {date: ' 2024/06/01', value: '8722'},
      //   {date: ' 2024/05/01', value: '8724'},
      //   {date: ' 2024/04/01', value: '9048'},
      //   {date: ' 2024/03/01', value: '9071'},
      //   {date: ' 2024/02/01', value: '8826'},
      //   {date: ' 2024/01/01', value: '9058'},
      //   {date: ' 2023/12/01', value: '9038'},
      //   {date: ' 2023/11/01', value: '8975'},
      //   {date: ' 2023/10/01', value: '9016'},
      //   {date: ' 2023/09/01', value: '9111'},
      {date: ' 2024', value: '8944'},
      {date: ' 2025', value: '8722'},
      {date: ' 2026', value: '8724'},
      {date: ' 2027', value: '9048'}
    ]
  }
];
const seasonalitytrendbbqgrillingseason = [
  {
    store: '008-Redmond',
    demandForecast: [
      //   {date: ' 2024/07/01', value: '9944'},
      //   {date: ' 2024/06/01', value: '9722'},
      //   {date: ' 2024/05/01', value: '9724'},
      //   {date: ' 2024/04/01', value: '10048'},
      //   {date: ' 2024/03/01', value: '10071'},
      //   {date: ' 2024/02/01', value: '9826'},
      //   {date: ' 2024/01/01', value: '10058'},
      //   {date: ' 2023/12/01', value: '10038'},
      //   {date: ' 2023/11/01', value: '9975'},
      //   {date: ' 2023/10/01', value: '10016'},
      //   {date: ' 2023/09/01', value: '10111'},
      //   {date: ' 2023/08/01', value: '9859'}

      {date: ' 2024', value: '9944'},
      {date: ' 2025', value: '9722'},
      {date: ' 2026', value: '9724'},
      {date: ' 2027', value: '10048'}
    ]
  }
];
const seasonalitytrendthanksgiving = [
  {
    store: '008-Redmond',
    demandForecast: [
      //   {date: ' 2024/07/01', value: '10194'},
      //   {date: ' 2024/06/01', value: '9972'},
      //   {date: ' 2024/05/01', value: '9974'},
      //   {date: ' 2024/04/01', value: '10298'},
      //   {date: ' 2024/03/01', value: '10321'},
      //   {date: ' 2024/02/01', value: '10076'},
      //   {date: ' 2024/01/01', value: '10308'},
      //   {date: ' 2023/12/01', value: '10288'},
      //   {date: ' 2023/11/01', value: '10225'},
      //   {date: ' 2023/10/01', value: '10266'},
      //   {date: ' 2023/09/01', value: '10361'},
      //   {date: ' 2023/08/01', value: '10109'}

      {date: ' 2023', value: '10194'},
      {date: ' 2024', value: '9972'},
      {date: ' 2025', value: '9974'},
      {date: ' 2026', value: '10298'}
    ]
  }
];
const seasonalitytrendchristmas = [
  {
    store: '008-Redmond',
    demandForecast: [
      //   {date: ' 2024/07/01', value: '8694'},
      //   {date: ' 2024/06/01', value: '8472'},
      //   {date: ' 2024/05/01', value: '8474'},
      //   {date: ' 2024/04/01', value: '8798'},
      //   {date: ' 2024/03/01', value: '8821'},
      //   {date: ' 2024/02/01', value: '8576'},
      //   {date: ' 2024/01/01', value: '8808'},
      //   {date: ' 2023/12/01', value: '8788'},
      //   {date: ' 2023/11/01', value: '8725'},
      //   {date: ' 2023/10/01', value: '8766'},
      //   {date: ' 2023/09/01', value: '8861'},
      //   {date: ' 2023/08/01', value: '8609'}

      {date: ' 2023', value: '8694'},
      {date: ' 2024', value: '8472'},
      {date: ' 2025', value: '8474'},
      {date: ' 2026', value: '8798'}
    ]
  }
];
const seasonalitytrendeaster = [
  {
    store: '008-Redmond',
    demandForecast: [
      //   {date: ' 2024/07/01', value: '18888'},
      //   {date: ' 2024/06/01', value: '18444'},
      //   {date: ' 2024/05/01', value: '18448'},
      //   {date: ' 2024/04/01', value: '19096'},
      //   {date: ' 2024/03/01', value: '19142'},
      //   {date: ' 2024/02/01', value: '18652'},
      //   {date: ' 2024/01/01', value: '19116'},
      //   {date: ' 2023/12/01', value: '19076'},
      //   {date: ' 2023/11/01', value: '18950'},
      //   {date: ' 2023/10/01', value: '19032'},
      //   {date: ' 2023/09/01', value: '19222'},
      //   {date: ' 2023/08/01', value: '18718'}
      {date: ' 2024', value: '18888'},
      {date: ' 2025', value: '18444'},
      {date: ' 2026', value: '18448'},
      {date: ' 2027', value: '19096'}
    ]
  }
];
const seasonalitytrendvalentinesday = [
  {
    store: '008-Redmond',
    demandForecast: [
      //   {date: ' 2024/07/01', value: '10194'},
      //   {date: ' 2024/06/01', value: '9972'},
      //   {date: ' 2024/05/01', value: '9974'},
      //   {date: ' 2024/04/01', value: '10298'},
      //   {date: ' 2024/03/01', value: '10321'},
      //   {date: ' 2024/02/01', value: '10076'},
      //   {date: ' 2024/01/01', value: '10308'},
      //   {date: ' 2023/12/01', value: '10288'},
      //   {date: ' 2023/11/01', value: '10225'},
      //   {date: ' 2023/10/01', value: '10266'},
      //   {date: ' 2023/09/01', value: '10361'},
      //   {date: ' 2023/08/01', value: '10109'}
      {date: ' 2024', value: '10194'},
      {date: ' 2025', value: '9972'},
      {date: ' 2026', value: '9974'},
      {date: ' 2027', value: '10298'}
    ]
  }
];
const seasonalitytrendnewyear = [
  {
    store: '008-Redmond',
    demandForecast: [
      //   {date: ' 2024/07/01', value: '19888'},
      //   {date: ' 2024/06/01', value: '19444'},
      //   {date: ' 2024/05/01', value: '19448'},
      //   {date: ' 2024/04/01', value: ' 20096'},
      //   {date: ' 2024/03/01', value: ' 20142'},
      //   {date: ' 2024/02/01', value: '19652'},
      //   {date: ' 2024/01/01', value: ' 20116'},
      //   {date: ' 2023/12/01', value: ' 20076'},
      //   {date: ' 2023/11/01', value: '19950'},
      //   {date: ' 2023/10/01', value: ' 20032'},
      //   {date: ' 2023/09/01', value: ' 20222'},
      //   {date: ' 2023/08/01', value: '19718'}
      {date: ' 2024', value: '19888'},
      {date: ' 2025', value: '19444'},
      {date: ' 2026', value: '19448'},
      {date: ' 2027', value: ' 20096'}
    ]
  }
];
const weathersummer = [
  {
    store: '008-Redmond',
    demandForecast: [
      //   {date: ' 2024/07/01', value: ' 20088'},
      //   {date: ' 2024/06/01', value: '19644'},
      //   {date: ' 2024/05/01', value: '19648'},
      //   {date: ' 2024/04/01', value: ' 20296'},
      //   {date: ' 2024/03/01', value: ' 20342'},
      //   {date: ' 2024/02/01', value: '19852'},
      //   {date: ' 2024/01/01', value: ' 20316'},
      //   {date: ' 2023/12/01', value: ' 20276'},
      //   {date: ' 2023/11/01', value: ' 20150'},
      //   {date: ' 2023/10/01', value: ' 20232'},
      //   {date: ' 2023/09/01', value: ' 20422'},
      //   {date: ' 2023/08/01', value: '19918'}
      {date: ' 2024', value: ' 20088'},
      {date: ' 2025', value: '19644'},
      {date: ' 2026', value: '19648'},
      {date: ' 2027', value: ' 20296'}
    ]
  }
];

const weatherfall = [
  {
    store: '008-Redmond',
    demandForecast: [
      //   {date: ' 2024/07/01', value: '19588'},
      //   {date: ' 2024/06/01', value: '19144'},
      //   {date: ' 2024/05/01', value: '19148'},
      //   {date: ' 2024/04/01', value: '19796'},
      //   {date: ' 2024/03/01', value: '19842'},
      //   {date: ' 2024/02/01', value: '19352'},
      //   {date: ' 2024/01/01', value: '19816'},
      //   {date: ' 2023/12/01', value: '19776'},
      //   {date: ' 2023/11/01', value: '19650'},
      //   {date: ' 2023/10/01', value: '19732'},
      //   {date: ' 2023/09/01', value: '19922'},
      //   {date: ' 2023/08/01', value: '19418'}
      {date: ' 2023', value: '19588'},
      {date: ' 2024', value: '19144'},
      {date: ' 2025', value: '19148'},
      {date: ' 2026', value: '19796'}
    ]
  }
];

const weatherwinter = [
  {
    store: '008-Redmond',
    demandForecast: [
      // {date: ' 2024/07/01', value: '9944'},
      // {date: ' 2024/06/01', value: '9722'},
      // {date: ' 2024/05/01', value: '9724'},
      // {date: ' 2024/04/01', value: '10048'},
      // {date: ' 2024/03/01', value: '10071'},
      // {date: ' 2024/02/01', value: '9826'},
      // {date: ' 2024/01/01', value: '10058'},
      // {date: ' 2023/12/01', value: '10038'},
      // {date: ' 2023/11/01', value: '9975'},
      // {date: ' 2023/10/01', value: '10016'},
      // {date: ' 2023/09/01', value: '10111'},
      // {date: ' 2023/08/01', value: '9859'}

      {date: ' 2023', value: '9944'},
      {date: ' 2024', value: '9722'},
      {date: ' 2025', value: '9724'},
      {date: ' 2026', value: '10048'}
    ]
  }
];

const weatherspring = [
  {
    store: '008-Redmond',
    demandForecast: [
      // {date: ' 2024/07/01', value: ' 20038'},
      // {date: ' 2024/06/01', value: '19594'},
      // {date: ' 2024/05/01', value: '19598'},
      // {date: ' 2024/04/01', value: ' 20246'},
      // {date: ' 2024/03/01', value: ' 20292'},
      // {date: ' 2024/02/01', value: '19802'},
      // {date: ' 2024/01/01', value: ' 20266'},
      // {date: ' 2023/12/01', value: ' 20226'},
      // {date: ' 2023/11/01', value: ' 20100'},
      // {date: ' 2023/10/01', value: ' 20182'},
      // {date: ' 2023/09/01', value: ' 20372'},
      // {date: ' 2023/08/01', value: '19868'}
      {date: ' 2024', value: ' 20038'},
      {date: ' 2025', value: '19594'},
      {date: ' 2026', value: '19598'},
      {date: ' 2027', value: ' 20246'}
    ]
  }
];
const commonmock = [
  {
    store: '008-Redmond',
    demandForecast: [
      {date: ' 2024/07/01', value: '10'},
      {date: ' 2024/06/01', value: '10'},
      {date: ' 2024/05/01', value: '10'},
      {date: ' 2024/04/01', value: '10'},
      {date: ' 2024/03/01', value: '10'},
      {date: ' 2024/02/01', value: '10'},
      {date: ' 2024/01/01', value: '10'},
      {date: ' 2023/12/01', value: '10'},
      {date: ' 2023/11/01', value: '10'},
      {date: ' 2023/10/01', value: '10'},
      {date: ' 2023/09/01', value: '10'},
      {date: ' 2023/08/01', value: '10'}
    ]
  }
];
const mockData = {
  seasonalitytrendbacktoschool,
  seasonalitytrendbbqgrillingseason,
  seasonalitytrendthanksgiving,
  seasonalitytrendchristmas,
  seasonalitytrendeaster,
  seasonalitytrendvalentinesday,
  seasonalitytrendnewyear,
  weathersummer,
  weatherfall,
  weatherwinter,
  weatherspring,
  commonmock
} as any;

export {mockData};
