import {createSlice} from '@reduxjs/toolkit';
export interface wareHouseItemState {
  loading: boolean;
  wareHouseItem: Array<any>;
  error: string;
}

const initialState: wareHouseItemState = {
  loading: false,
  wareHouseItem: [],
  error: ''
};

const wareHouseItemSlice = createSlice({
  name: 'wareHouseItem',
  initialState,
  reducers: {
    wareHouseItemLoading: (state) => {
      state.loading = true;
    },
    wareHouseItemSuccess: (state, action) => {
      state.wareHouseItem = action.payload;
      state.loading = false;
    },
    wareHouseItemFailure: (state) => {
      state.loading = false;
      state.wareHouseItem = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default wareHouseItemSlice.reducer;
export const {wareHouseItemLoading, wareHouseItemSuccess, wareHouseItemFailure} =
  wareHouseItemSlice.actions;
