import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {shrinkLoading, shrinkFailure, shrinkSuccess} from 'redux/dashboard/shrinkTile/shrinkSlice';

export const shrinkSagaActions = {
  FETCH_SHRINK_DATA_SAGA: 'FETCH_SHRINK_DATA_SAGA'
};

export function* fetchShrinkData(action: any): any {
  try {
    yield put(shrinkLoading());
    const {data} = yield call(DashboardServiceAPIS.getShrinkData, action.payload);
    yield put(shrinkSuccess(data));
  } catch (error) {
    yield put(shrinkFailure());
  }
}

export default function* watchFetchShrinkData() {
  yield takeEvery(shrinkSagaActions.FETCH_SHRINK_DATA_SAGA, fetchShrinkData);
}
