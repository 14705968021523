import {TableHead, TableRow, Typography, Box, Stack, Tooltip} from '@mui/material';
import CustomButton from 'components/baseComponents/customButton';
import React, {useState} from 'react';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import StyledChip from 'components/baseComponents/customChip';
import {BreadcrumbNav as ReceivedPoItemBreadcrumbNav} from 'features/report/reportBreadCrumbs';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {receivedPoItemSagaActions} from 'redux/report/inventory/receivedPoItem/receivedPoItemSaga';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {tagSagaActions} from 'redux/dashboard/master/tagSaga';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import {warehouseSagaActions} from 'redux/dashboard/master/warehouseSaga';
import ExportModal from 'components/baseComponents/exportOption';
import {viewInFilterOptions} from 'config/constants';
import {useLocation} from 'react-router-dom';

interface IPropType {
  atpEnabler?: (val: boolean) => void;
}
//Below Interface will use to Structure the API coming data
interface IReportInventoryReceivedPoItemProps {
  poID: string;
  itemID: string;
  itemName: string;
  pack: string;
  size: string;
  wh: string;
  issue: string;
  planDlvr: string;
  receipt: string;
  ordered: string;
  received: string;
  expiration: string;
  buyer: string;
  hasAlert?: boolean;
  tags?: Array<string>;
  id: string;
}
//Sticky Header which is top of Table
const StickyHeaderCell = (props: any) => {
  const consolidatedData = generateStickyHeaderValues(props.data, props.columnCell);
  return (
    <>
      <TableHead>
        <TableRow>
          <BorderedTableStickyCell
            colSpan={6}
            sx={{top: '64px', color: 'grayscale.400', borderBottom: 0}}
          >
            <Typography variant='subtitle2'>CONSOLIDATED STATS</Typography>
          </BorderedTableStickyCell>

          <TableStickyCell align='right' sx={{top: '64px'}}>
            -
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            -
          </TableStickyCell>
          <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
            -
          </BorderedTableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            <Typography variant='subtitle2'>{consolidatedData.ordered}</Typography>
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            <Typography variant='subtitle2'>{consolidatedData.received}</Typography>
          </TableStickyCell>
          <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
            -
          </BorderedTableStickyCell>
          <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
            -
          </BorderedTableStickyCell>
        </TableRow>
      </TableHead>
    </>
  );
};
//For item name with not more than 30 character/Ellipsis
const CustomComponent = (row: any) => {
  if (row.itemName.length > 30)
    return (
      <Tooltip title={row.itemName} placement='top'>
        <Box
          sx={{
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {row.itemName}
        </Box>
      </Tooltip>
    );
  else {
    return <Box sx={{maxWidth: '100px'}}>{row.itemName}</Box>;
  }
};

const ReceivedPOItems: React.FC<IPropType> = (props) => {
  const location: any = useLocation();
  const [showExpModal, setShowExpModal] = useState(false);
  //handle-click function
  const handleClick = () => {
    setShowExpModal(true);
  };
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const {receivedPoItem, loading, error} = useAppSelector((state) => state.receivedPoItemSlice);

  const rowData: IReportInventoryReceivedPoItemProps[] =
    receivedPoItem.length > 0 ? receivedPoItem : [];

  //For Binding the MasterData
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: tagSagaActions.FETCH_TAG_DATA
    });
    dispatch({
      type: warehouseSagaActions.FETCH_WAREHOUSE_DATA
    });
  }, []);
  const {warehouse} = useAppSelector((state) => state.warehouseSlice);
  const {master} = useAppSelector((state) => state.masterSlice);
  const [, dataTwo, dataThree] = master;
  const {tag} = useAppSelector((state) => state.tagSlice);
  //For Filter change and generate Payload data
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      temp.push({key: key, value: value, condition: 'equal'});
    }
    return temp;
  };
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };

  const columnCell: IColumnCell<IReportInventoryReceivedPoItemProps>[] = [
    {
      id: 'poID',
      name: 'poID',
      displayName: 'PO#',
      sx: {
        width: '96px'
      }
    },
    {
      id: 'itemID',
      name: 'itemID',
      displayName: 'ITEM ID',
      sx: {
        width: '96px'
      }
    },
    {
      id: 'itemName',
      name: 'itemName',
      displayName: 'ITEM',
      hasTags: true,
      customCell: CustomComponent,
      sx: {
        minWidth: '150px'
      }
    },
    {
      id: 'pack',
      name: 'pack',
      displayName: 'PACK',
      isNumeric: true,
      isColumnSortable: false,
      sx: {
        textAlign: 'right',
        width: '96px'
      }
    },
    {
      id: 'size',
      name: 'size',
      displayName: 'SIZE',
      isColumnSortable: false,
      isNumeric: true,
      isGroupBorderRequire: false,
      sx: {
        textAlign: 'right',
        width: '96px'
      }
    },
    {
      id: 'wh',
      name: 'wh',
      displayName: 'WH',
      isNumeric: true,
      isColumnSortable: false,
      isGroupBorderRequire: true,
      sx: {
        textAlign: 'right',
        width: '96px'
      }
    },
    {
      id: 'issue',
      name: 'issue',
      displayName: 'ISSUE',
      isGroupBorderRequire: false,
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'planDlvr',
      name: 'planDlvr',
      displayName: 'PLAN DLVR',
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'receipt',
      name: 'receipt',
      displayName: 'RECEIPT',
      isGroupBorderRequire: true,
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'ordered',
      name: 'ordered',
      displayName: 'ORDERED',
      consolidateType: 'SUM',
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'received',
      name: 'received',
      displayName: 'RECEIVED',
      consolidateType: 'SUM',
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'expiration',
      name: 'expiration',
      displayName: 'EXPIRATION',
      isGroupBorderRequire: true,
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'buyer',
      name: 'buyer',
      displayName: 'BUYER',
      sx: {
        textAlign: 'right'
      }
    }
  ];

  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date',
      format: 'MM/DD/YY',
      defaultValue: '10/08/2021|10/15/21',
      sx: {
        width: '210px'
      }
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View in (Ordered, Received)',
      defaultValue: 'Units',
      sx: {
        width: '182px'
      },
      options: viewInFilterOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'wareHouse',
      title: 'WareHouse',
      defaultValue: '97',
      sx: {
        width: '182px'
      },
      options: generateLabelValue(warehouse, 'warehouseID')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '182px'
      },
      options: generateLabelValue(dataTwo?.data, 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName',
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      sx: {
        width: '182px'
      },
      options: generateLabelValue(dataThree?.data, 'subClassName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'tag',
      title: 'Tag',
      defaultValue: 'All Tags',
      placeholder: 'Tags',
      sx: {
        width: '182px'
      },
      options: generateLabelValue(tag, 'tag')
    }
  ];
  const defaultData = filterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);
  React.useEffect(() => {
    dispatch({
      type: receivedPoItemSagaActions.FETCH_RECEIVEDPOITEM_SAGA,
      payload: generatePayload(filtervalues)
    });
  }, [filtervalues]);
  return (
    <>
      <Box sx={{ml: 5, mr: 5, pb: 3, mt: 5}}>
        <ReceivedPoItemBreadcrumbNav />
        {/* For Making the two elements in same div left and right */}
        <Stack sx={{justifyContent: 'space-between'}} direction='row'>
          <Box sx={{pl: 0, pt: 1, pb: 1}} flexGrow={1}>
            <Typography variant='h4' align='left'>
              Received PO Items
            </Typography>
          </Box>
          <Box sx={{pl: 0, pt: 1, pb: 1}}>
            <CustomButton
              variant='contained'
              onClick={handleClick}
              sx={{height: 40}}
              id='report-inventory-received-export'
            >
              Export
            </CustomButton>
          </Box>
        </Stack>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='report-inventory-receivedPoItem'
        />
        <EnhancedTable<IReportInventoryReceivedPoItemProps>
          height={844}
          rowData={rowData}
          stickyRows={<StickyHeaderCell data={rowData} columnCell={columnCell} />}
          columnCell={columnCell}
          rowKey={'itemID'}
          defaultSortKey={'itemID'}
          showTags={true}
          componentType='paper'
          allowAlternateColor={true}
          rowsPerPage={25}
          id='report-inventory-poItem'
        />
      </Box>
      {showExpModal && (
        <ExportModal
          id='receivedPoItem-export'
          open={showExpModal}
          handleClose={() => setShowExpModal(false)}
        />
      )}
    </>
  );
};
export default ReceivedPOItems;
