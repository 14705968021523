import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  onOrderRcvgItemFailure,
  onOrderRcvgItemLoading,
  onOrderRcvgItemSuccess
} from 'redux/product/details/onOrderRcvgItem/onOrderRcvgItemSlice';

export const onOrderRcvgItemSagaActions = {
  FETCH_ONORDERRCVGITEM_DATA: 'FETCH_ONORDERRCVGITEM_DATA'
};

export function* fetchOnOrderRcvgItemData(action: any): any {
  try {
    yield put(onOrderRcvgItemLoading());
    const {data} = yield call(ProductAPIS.getOrderListData, action.payload);
    yield put(onOrderRcvgItemSuccess(data));
  } catch (error) {
    yield put(onOrderRcvgItemFailure());
  }
}

export default function* watchFetchOnOrderRcvgItemData() {
  yield takeEvery(onOrderRcvgItemSagaActions.FETCH_ONORDERRCVGITEM_DATA, fetchOnOrderRcvgItemData);
}
