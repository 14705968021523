import * as React from 'react';
import {Box, Tab, Tabs, Stack, Typography, useTheme} from '@mui/material';
import SupplyChain from 'features/vendor/section/supplyChain';
import ProductItem from 'features/vendor/section/productItem';
import SalesItem from 'features/vendor/section/salesItem';
import handleRoleMapping, {handleAccessFilter} from 'util/helperRouterMap';
import CustomTabPanel from 'components/baseComponents/customTabPanel';
import {useLocation} from 'react-router-dom';

interface IRoleType {
  name: string;
  permission: Array<any>;
  routePath: string;
}

interface IVendorDetailsProps {
  vendorId: string;
}

//Below we are returning the components.
const VendorDetailTabs: React.FC<IVendorDetailsProps> = (props) => {
  const location = useLocation();
  const vendorId = props.vendorId;
  const [value, setValue] = React.useState(0);
  const [getRoleData, setRoleData] = React.useState<IRoleType>();
  const [checkAccess, setAccess] = React.useState<Array<string>>([]);
  const theme = useTheme();
  React.useEffect(() => {
    setRoleData(handleRoleMapping(location?.pathname));
  }, []);

  React.useEffect(() => {
    const temp: any = getRoleData && handleAccessFilter(getRoleData);
    setAccess(temp);
  }, [getRoleData]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabNames = [
    {
      id: 'vendor-sales-tab',
      label: 'sales',
      order: 0
    },
    {
      id: 'vendor-product-tab',
      label: 'products',
      order: 1
    },
    {
      id: 'vendor-supply-chain-tab',
      label: 'supply chain',
      order: 2
    }
  ];

  const generateTabNames = () => {
    return tabNames.map((tab) => {
      if (checkAccess?.includes(tab.label.toLowerCase())) {
        return (
          <Tab
            key={`product-${tab.order}`}
            id={tab.id}
            value={tab.order}
            label={<Typography variant='button'>{tab.label}</Typography>}
          />
        );
      } else {
        return <></>;
      }
    });
  };

  const shouldRender = checkAccess && checkAccess.every((e) => e === undefined);
  return (
    <Box sx={{mt: 5}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            '& .MuiTab-root': {
              color: theme.palette.grayscale?.[400],
              p: '12px 0',
              mr: 3,
              minWidth: 'fit-content'
            }
          }}
        >
          {checkAccess && shouldRender ? <></> : generateTabNames()}
        </Tabs>
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{
            p: '12px 0px',
            '& div:nth-child(1)': {
              marginRight: '15px'
            }
          }}
        ></Stack>
      </Box>
      <CustomTabPanel value={value} index={0} id={'vendor-sales'}>
        <SalesItem vendorId={vendorId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} id={'vendor-product'}>
        <ProductItem venId={vendorId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} id={'vendor-supplyChain'}>
        <SupplyChain venId={vendorId} />
      </CustomTabPanel>
    </Box>
  );
};

export default VendorDetailTabs;
