import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';

import {
  purchaseOrderTypeLoading,
  purchaseOrderTypeFailure,
  purchaseOrderTypeSuccess
} from 'redux/dashboard/master/purchaseOrderSlice';

export const purchaseOrderTypeSagaActions = {
  FETCH_PURCHASEORDER_TYPE_DATA: 'FETCH_PURCHASEORDER_TYPE_DATA'
};

export function* fetchPurchaseOrderTypeData(): any {
  try {
    yield put(purchaseOrderTypeLoading());
    const {data} = yield call(ProductAPIS.getPurchaseOrderTypeData);
    yield put(purchaseOrderTypeSuccess(data));
  } catch (error) {
    yield put(purchaseOrderTypeFailure());
  }
}

export default function* watchPurchaseOrderTypeData() {
  yield takeEvery(
    purchaseOrderTypeSagaActions.FETCH_PURCHASEORDER_TYPE_DATA,
    fetchPurchaseOrderTypeData
  );
}
