import {Box, styled} from '@mui/material';
import React from 'react';
import BarGraphComponent, {IAxis} from 'components/barGraph';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import {CustomCard} from 'components/baseComponents/customCard';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {shrinkSagaActions} from 'redux/dashboard/shrinkTile/shrinkSaga';
import {useTheme} from '@mui/material/styles';
import {CustomError, CustomLoader} from '../baseComponents/customMessage';
import {generatePayload} from 'util/arrayOperations';

const CustomBox = styled(Box)({
  '& div.user-select-none': {
    left: '-2px'
  },
  '& .MuiPaper-root .MuiCardContent-root .MuiBox-root:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

interface IPropType {
  defaultValue: string;
  filteredData: any;
}

const dropdownData = [
  {
    label: 'Department Shrink',
    value: 'department'
  },
  {
    label: 'Store Shrink',
    value: 'store'
  }
];

const ShrinkTile: React.FC<IPropType> = (props) => {
  const [shrinkType, setShrinkType] = React.useState(props.defaultValue);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  /**
   * dispatch shrink tile data
   */
  const dispatchData = () => {
    dispatch({
      type: shrinkSagaActions.FETCH_SHRINK_DATA_SAGA,
      payload: {
        type: shrinkType,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [shrinkType, props?.filteredData]);
  const {shrink, loading, error} = useAppSelector((state) => state.shrinkSlice);

  const handleSelect = (event: any): void => {
    dispatch({
      type: shrinkSagaActions.FETCH_SHRINK_DATA_SAGA,
      payload: {
        ...props?.filteredData,
        type: event?.target.value
      }
    });
    setShrinkType(event?.target.value);
  };

  const generateContent = () => {
    switch (shrinkType) {
      case 'department':
        return (
          <BarGraphComponent
            data={shrink}
            xAxis={[...xAxis]}
            yAxis={yAxis}
            showPlan={true}
            plan={planAxis}
            id='departmentShrinkCompBar'
            redirectUrl={'preprtualinventoryaverage'}
          />
        );
      default:
        return (
          <BarGraphComponent
            id='storeShrinkCompBar'
            data={shrink}
            xAxis={[...xAxis]}
            yAxis={yAxis}
            showPlan={true}
            plan={planAxis}
            redirectUrl={'preprtualinventoryaverage'}
          />
        );
    }
  };
  const xAxis: Array<IAxis> = [
    {
      name: 'last4Week',
      displayTitle: 'last4Week',
      color: theme.palette.primary.dark,
      unit: 'K',
      prefix: '$'
    },
    {
      name: 'last13Week',
      displayTitle: 'last13Week',
      color: theme.palette.neutral?.secondary2,
      unit: 'K',
      prefix: '$'
    }
  ];

  const yAxis: IAxis = {
    name: shrinkType === 'department' ? 'departmentName' : 'storeName',
    displayTitle: 'name',
    unit: 'K',
    prefix: '$'
  };

  const planAxis: IAxis = {
    name: 'plan',
    displayTitle: 'plan',
    color: theme.palette.neutral?.secondary1,
    unit: 'K',
    prefix: '$'
  };

  const Header = () => {
    return (
      <DropDownWrapper>
        <CustomSelect
          id='departmentCompTile'
          name={'departmentCompTileSelect'}
          options={dropdownData}
          onChange={handleSelect}
          value={shrinkType}
        />
      </DropDownWrapper>
    );
  };

  const Content = () => {
    return <CustomBox>{generateContent()}</CustomBox>;
  };

  if (shrink && shrink.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='shrinkTile'
          header={<Header />}
          content={loading ? <CustomLoader id='shrink-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='shrinkTile-withoutData'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='shrink-load' />
            ) : (
              <CustomError
                id='shrink-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default ShrinkTile;
