import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {ReactComponent as Eraser} from 'assets/images/icons/Icons & UI-Edit-Erase_Blue.svg';
import {ReactComponent as Preference} from 'assets/images/icons/Icons & UI-Settings-Preferences_Blue.svg';
import {ReactComponent as RightArrow} from 'assets/images/icons/Icons & UI-Carets-Caret-Right_Active.svg';
import {IDropDownFieldTypes, IFilterTemplate} from 'components/customTable/types';
import CustomDrawer from 'components/baseComponents/customDrawer';
import {Divider, useTheme} from '@mui/material';
import {Box} from '@mui/system';
import FilterMapper from 'components/customTable/gridFilterUtility/filterMapper';
import {ISelectOptions} from 'components/baseComponents/customSelect';

export interface IFilterValues {
  [key: string]: string | number | string[]; /// <<IMP>>---->key will be same as field name property
}

interface IGridFilter {
  filterTemplate: IFilterTemplate[];
  values: IFilterValues;
  onFilterChange: (values: IFilterValues) => void;
  hideMoreFilter?: boolean;
  id: string;
  onReset: () => void;
  customButton?: React.ReactNode;
}

const GridFilter: React.FC<IGridFilter> = (props: IGridFilter) => {
  const [resetStatus, setResetStatus] = React.useState<boolean>(true);
  const [filterTemplate, setFilterTemplate] = React.useState<any[]>(props.filterTemplate);
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    setFilterTemplate(props.filterTemplate);
  }, [JSON.stringify(props.filterTemplate)]);

  /**
   * When called finds and returns child with updated options
   * @param dependentName  Parent Target name
   * @param dependentFilterValue Parent target value
   * @param filterTemplate original copy of props.filterTemplate
   * @param parent Parent element in filterTemplate with updated options.
   * @returns new child or empty
   */
  function findDependantFilter(
    dependentName: string,
    dependentFilterValue: string,
    filterTemplate: any[],
    parent?: any
  ) {
    const updatedFilterTemplate: any[] = [...filterTemplate];
    const child: IDropDownFieldTypes = updatedFilterTemplate.find(
      // find child of dependent filter
      (e) => e.type === 'DROPDOWN' && e?.dependantFilterName === dependentName
    );

    ///if child exist then update child options
    if (child) {
      let updatedOptions = child?.options;

      let rootParent = parent;
      /// if parent not passed
      if (parent && parent.property) {
        rootParent = parent;
      } else {
        ////Find parent node from template
        const templateParent: IDropDownFieldTypes = updatedFilterTemplate.find(
          // find current element in filter template
          (e) => e.type === 'DROPDOWN' && e?.property === dependentName
        );
        rootParent = templateParent;
      }

      const defaultCheck = `All ${
        rootParent?.placeholder ? rootParent.placeholder : rootParent?.name
      }`;
      if (dependentFilterValue.trim().length > 0 && dependentFilterValue !== defaultCheck) {
        /* 
        If parent selected value exists (i.e. dependentFilterValue != null and not ALL options)
        then update child options and return only options with depends on == dependentFilterValue
         */
        updatedOptions = child?.options?.filter(
          (e: ISelectOptions) => e.dependsOn === dependentFilterValue
        );
      } else {
        /**
         * If parent selected value doesnot exists(i.e select All options of parent)
         * then update child options and return only options that matches depends on of child in All the options of parent.
         */
        updatedOptions = child?.options?.filter((e: ISelectOptions) => {
          return rootParent.options?.find((ele: ISelectOptions) => e.dependsOn === ele.value);
        });
      }

      const updatedChild = {
        ...child,
        options: updatedOptions
      };
      return updatedChild;
    } else {
      return {};
    }
  }

  /**
   * On change of any field update the filter State and call the props.onchange
   * @param event Field property
   */
  const onFieldValueChange = (event: any) => {
    let updatedState: any = {
      ...props.values,
      [event?.target.name]: event?.target.value
    };
    const updatedFilterTemplate: any[] = [...props.filterTemplate];
    let updatedChild: any = findDependantFilter(
      event?.target.name,
      event?.target.value,
      updatedFilterTemplate
    );
    // if updated child is defined then prepare the updated filterTemplate and update the template state
    if (Object.keys(updatedChild).length > 0) {
      const finalTemplate: any = [];
      while (updatedChild.property) {
        // find child filters if any using this loop and update its options
        finalTemplate.push(updatedChild);
        updatedState = {...updatedState, [updatedChild.property]: updatedChild.defaultValue};
        updatedChild = findDependantFilter(
          updatedChild.property,
          updatedChild.defaultValue,
          updatedFilterTemplate,
          updatedChild
        );
      }
      const newfinalTemplate = filterTemplate.map((ele) => {
        const finalChild = finalTemplate.find((e: any) => e.property === ele.property);
        // if filter objects exist with updated options then keep them in final template
        if (finalChild?.property && ele.property === finalChild.property) {
          return finalChild;
        } else {
          return ele;
        }
      });
      setFilterTemplate([...newfinalTemplate]);
    }
    props.onFilterChange(updatedState);
    setResetStatus(false);
  };

  const resetFilter = () => {
    setResetStatus(true);
    props.onReset ? props.onReset() : null;
  };
  const primaryFilterTemplate = filterTemplate.filter((ele) =>
    ele.mode == null || ele.mode === 'Primary' ? true : false
  );
  const secondaryFilterTemplate = filterTemplate.filter((ele) => ele.mode === 'Secondary');
  const appliedFilterCount = filterTemplate.filter(
    (ele) =>
      ele.mode === 'Secondary' &&
      !(
        props.values[ele.property]?.toString().includes('All') ||
        props.values[ele.property]?.toString().length === 0
      )
  ).length;
  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          height: '80px',
          borderRadius: 0,
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          '&.MuiPaper-root': {
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            borderBottom: '1px solid rgba(224, 224, 224, 1)'
          }
        }}
      >
        <Stack direction='row' pl={2} spacing={3}>
          {primaryFilterTemplate.map((filter: any, i: number) => {
            return (
              <FilterMapper
                id={`primaryFilter-${i}`}
                filter={filter}
                key={`primary-${filter.property}-${i}`}
                onFieldValueChange={onFieldValueChange}
                values={props.values}
                index={i}
              />
            );
          })}
        </Stack>
        <Stack direction='row' sx={{pr: 2}}>
          {!props.hideMoreFilter ? (
            <Button
              id={`${props.id}-gridFilter-moreFilters-hide-filter`}
              onClick={() => setDrawerOpen(true)}
              sx={{
                '&:hover': {
                  bgcolor: 'unset'
                }
              }}
            >
              <Preference />
              <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                MORE FILTERS
              </Typography>
              {appliedFilterCount ? (
                <Typography
                  variant='button'
                  sx={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    backgroundColor: theme.palette.neutral?.secondary2,
                    padding: '2px',
                    marginLeft: '2px'
                  }}
                >
                  {appliedFilterCount}
                </Typography>
              ) : (
                <Typography></Typography>
              )}
            </Button>
          ) : null}
          <Button
            onClick={resetFilter}
            disabled={resetStatus}
            id={`${props.id}-gridFilter-resetAll`}
            sx={{
              '&:hover': {
                bgcolor: 'unset'
              }
            }}
          >
            <Eraser fill={resetStatus ? '#9c9e99' : '#4664fb'} />
            <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
              RESET ALL
            </Typography>
          </Button>
          {props.customButton ? <>{props.customButton}</> : null}
        </Stack>
      </Paper>
      <CustomDrawer
        id='customDrawer-gridFilter'
        open={drawerOpen}
        anchor='right'
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <>
          <Paper sx={{width: '280px', height: '100%'}}>
            <Stack direction='row' sx={{margin: 2}}>
              <RightArrow
                id={`more-filter-right-arrow-${props.id}`}
                onClick={() => setDrawerOpen(false)}
              />
              <Typography
                variant='h6'
                sx={{
                  color: theme.palette.grayscale?.[600],
                  mt: '3px'
                }}
                id={`${props.id}-gridFilter-moreFilters`}
              >
                More Filters
              </Typography>
            </Stack>
            <Divider />
            <Box sx={{margin: 3}}>
              {secondaryFilterTemplate.map((filter: any, index: number) => (
                <Box sx={{mt: 2, mb: 2}} key={`Secondary${filter.property}${index}`}>
                  <FilterMapper
                    id={`secondaryFilter-${index}`}
                    filter={filter}
                    onFieldValueChange={onFieldValueChange}
                    values={props.values}
                    index={index}
                  />
                </Box>
              ))}
            </Box>
          </Paper>
        </>
      </CustomDrawer>
    </>
  );
};

export default GridFilter;
