import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  retailZoneLoading,
  retailZoneFailure,
  retailZoneSuccess
} from 'redux/product/details/retails/retailZoneSlice';

export const retailZoneSagaActions = {
  FETCH_RETAILZONE_DATA: 'FETCH_RETAILZONE_DATA'
};

export function* fetchRetailZoneData(action: any): any {
  try {
    yield put(retailZoneLoading());
    const {data} = yield call(ProductAPIS.getRetailZoneData, action.payload);
    yield put(retailZoneSuccess(data));
  } catch (error) {
    yield put(retailZoneFailure());
  }
}

export default function* watchFetchRetailZoneData() {
  yield takeEvery(retailZoneSagaActions.FETCH_RETAILZONE_DATA, fetchRetailZoneData);
}
