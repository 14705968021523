import {createSlice} from '@reduxjs/toolkit';
export interface buyerState {
  loading: boolean;
  buyer: Array<any>;
  error: string;
}

const initialState: buyerState = {
  loading: false,
  buyer: [],
  error: ''
};

const buyerSlice = createSlice({
  name: 'buyer',
  initialState,
  reducers: {
    buyerLoading: (state) => {
      state.loading = true;
    },
    buyerSuccess: (state, action) => {
      state.buyer = action.payload;
      state.loading = false;
    },
    buyerFailure: (state) => {
      state.loading = false;
      state.buyer = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default buyerSlice.reducer;
export const {buyerLoading, buyerSuccess, buyerFailure} = buyerSlice.actions;
