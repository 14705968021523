import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';

import {
  loadConditionLoading,
  loadConditionFailure,
  loadConditionSuccess
} from 'redux/dashboard/master/loadConditionSlice';

export const loadConditionSagaActions = {
  FETCH_LOADCONDITION_DATA: 'FETCH_LOADCONDITION_DATA'
};

export function* fetchLoadConditionData(): any {
  try {
    yield put(loadConditionLoading());
    const {data} = yield call(ProductAPIS.getLoadConditionData);
    yield put(loadConditionSuccess(data));
  } catch (error) {
    yield put(loadConditionFailure());
  }
}

export default function* watchFetchLoadConditionData() {
  yield takeEvery(loadConditionSagaActions.FETCH_LOADCONDITION_DATA, fetchLoadConditionData);
}
