import React from 'react';
import Typography from '@mui/material/Typography';
import {DropDownWrapper} from 'util/commonStyles';
import {Link} from 'react-router-dom';
import {CustomCard} from 'components/baseComponents/customCard';
import {styled} from '@mui/material/styles';
import {Box, Button, useTheme} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {itemAlertSagaActions} from 'redux/dashboard/itemAlert/itemAlertSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root > div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});

interface IAlertPrimaryItem {
  count: number;
  title: string;
}

interface AlertProps {
  count?: number;
}

const ItemAlertTile: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  /**
   * dispatch itemAlertData
   */
  const dispatchData = () => {
    dispatch({
      type: itemAlertSagaActions.FETCH_ITEMALERT_DATA,
      payload: 'itemAlerts'
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, []);

  const {itemAlert, loading, error} = useAppSelector((state) => state.itemAlertSlice);

  const AlertWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'count',
    name: 'AlertWrapper',
    slot: 'Root'
  })<AlertProps>(({count}) => ({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    height: 60,
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      '& p': {
        fontSize: 14,
        padding: '0px 8px',
        borderRadius: '2px',
        maxHeight: 24,
        ':first-child': {
          color: count !== 0 ? theme.palette.error.main : theme.palette.grayscale?.[600],
          backgroundColor: count
            ? theme.palette.secondary.light
            : theme.palette.primary.contrastText
        }
      }
    }
  }));

  const ItemAlertButton = styled(Button)({
    color: theme.palette.primary.main,
    fontWeight: 500,
    letterSpacing: 1.25,
    lineHeight: 16,
    textAlign: 'right',
    '&:hover': {
      backgroundColor: 'unset'
    }
  });

  const AlertPrimaryItem = (props: IAlertPrimaryItem) => {
    return (
      <AlertWrapper count={props?.count}>
        <div>
          <p>
            <Typography variant='subtitle2' textAlign='center'>
              {props?.count}
            </Typography>
          </p>
          <p className='title'>
            <Typography variant='subtitle2' textAlign='center'>
              {props?.title}
            </Typography>
          </p>
        </div>
        <ItemAlertButton variant='text'>
          <Typography variant='body2' textAlign='center' sx={{fontFamily: 'Roboto Medium'}}>
            <Link to={{pathname: `/Product`}} state={{filter: {alert: props?.title}}}>
              VIEW ITEMS
            </Link>{' '}
          </Typography>
        </ItemAlertButton>
      </AlertWrapper>
    );
  };

  const generateContent = () => {
    return itemAlert.map((item, index) => {
      return (
        <AlertPrimaryItem key={`${item}-${index}`} count={item.count} title={item.alertType} />
      );
    });
  };

  const Header = () => {
    return (
      <DropDownWrapper sx={{paddingRight: '12px'}}>
        <Typography sx={{color: 'common.black'}} variant='subtitle2' textAlign='center'>
          Items Alerts
        </Typography>
        {itemAlert.length ? (
          <span>
            <Typography variant='body2' textAlign='center' sx={{fontWeight: 'bold'}}>
              {itemAlert.length}
            </Typography>
          </span>
        ) : null}
      </DropDownWrapper>
    );
  };

  const Content = () => {
    return <>{generateContent()}</>;
  };

  if (itemAlert && itemAlert.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='itemAlert'
          header={<Header />}
          content={loading ? <CustomLoader id='item-alert-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='itemAlert-ID'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='item-alert-load' />
            ) : (
              <CustomError
                id='item-alert-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default ItemAlertTile;
