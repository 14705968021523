import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  getProfileFailure,
  getProfileLoading,
  getProfileSuccess
} from 'redux/dashboard/profile/getProfileSlice';

export const getProfileSagaActions = {
  FETCH_GETPROFILE_DATA: 'FETCH_GETPROFILE_DATA'
};

export function* fetchGetProfileData(action: any): any {
  try {
    yield put(getProfileLoading());
    const {data} = yield call(DashboardServiceAPIS.getProfileData, action.payload);
    yield put(getProfileSuccess(data));
  } catch (error) {
    yield put(getProfileFailure());
  }
}

export default function* watchFetchGetProfileData() {
  yield takeEvery(getProfileSagaActions.FETCH_GETPROFILE_DATA, fetchGetProfileData);
}
