import {Box, Grid, Tab, Tabs} from '@mui/material';
import React from 'react';
import ReportTile from 'components/reportTile';
import {TabPanel} from 'components/tabPanel';
import {styled} from '@mui/system';
import handleRoleMapping from 'util/helperRouterMap';
import {useLocation} from 'react-router-dom';

const CustomTabs = styled(Tabs)({
  paddingLeft: '24px'
});

const CustomTab = styled(Tab)(({theme}) => ({
  color: theme.palette.grayscale?.[400],
  padding: '12px 0',
  minWidth: 0,
  marginRight: '24px',
  '&.Mui-disabled': {
    color: theme.palette.grayscale?.[400]
  }
}));

interface IRoleType {
  name: string;
  permission: Array<any>;
  routePath: string;
}

interface IAccessItemType {
  accessItem: string;
  access: Array<string>;
}

const Report: React.FC = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [getRoleData] = React.useState<IRoleType>(handleRoleMapping(location?.pathname));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const buyingReportTemplate = [
    {
      title: 'Demand Forecast',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: true,
      path: '/demandForecast'
    },
    {
      title: 'Category/Supplier Sales',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false,
      path: '/SupplierSales'
    },
    {
      title: 'Report 3',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false
    },
    {
      title: 'Report 4',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false
    },
    {
      title: 'Report 5',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false
    },
    {
      title: 'Report 6',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false
    },
    {
      title: 'Report 7',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false
    }
  ];

  const planningReportTemplate = [
    {
      title: 'Warehouse Liability',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: true,
      path: '/warehouse-Liability'
    },
    {
      title: 'Inventory Balances',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false,
      path: '/InventoryBalance'
    },
    {
      title: 'Perpetual Inventory Averages',
      timeStamp: '10:00 AM EST, 10/21/21',
      path: '/PreprtualInventoryAverage',
      status: false
    },
    {
      title: 'Received PO Items',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false,
      path: '/ReceivedPo'
    },
    {
      title: 'Report 5',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false
    },
    {
      title: 'Report 6',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false
    },
    {
      title: 'Report 7',
      timeStamp: '10:00 AM EST, 10/21/21',
      status: false
    }
  ];

  const salesPermissionArray = getRoleData?.permission.find((i) => i.accessItem === 'sales');
  const inventoryPermissionArray = getRoleData?.permission.find(
    (i) => i.accessItem === 'inventory'
  );

  const modifiedBuyingReportTemplate = buyingReportTemplate.filter((ele) => {
    const temp = salesPermissionArray?.accessSubItems?.find(
      (i: IAccessItemType) => i.accessItem === ele.title
    );
    return temp !== undefined ? true : false;
  });

  const modifiedPlanningReportTemplate = planningReportTemplate.filter((ele) => {
    const temp = inventoryPermissionArray?.accessSubItems?.find(
      (i: IAccessItemType) => i.accessItem === ele.title
    );
    return temp !== undefined ? true : false;
  });

  return (
    <Box sx={{mt: '60px'}}>
      <CustomTabs value={value} onChange={handleChange}>
        <CustomTab label='SALES' />
        <CustomTab label='INVENTORY' />
      </CustomTabs>

      <TabPanel value={value} index={0}>
        <Grid container spacing={{xs: 2, md: 3}}>
          {modifiedBuyingReportTemplate.map((element, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={`buyingReportTemplate${index}`}>
              <ReportTile
                title={element.title}
                status={element.status}
                timeStamp={element.timeStamp}
                path={element.path ? element.path : 'null'}
              />
            </Grid>
          ))}
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container spacing={{xs: 2, md: 3}}>
          {modifiedPlanningReportTemplate.map((element, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={`planningReportTemplate${index}`}>
              <ReportTile
                title={element.title}
                status={element.status}
                timeStamp={element.timeStamp}
                path={element.path ? element.path : 'null'}
              />
            </Grid>
          ))}
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
};

export default Report;
