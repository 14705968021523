import * as React from 'react';
import {Box, Typography, styled, TableHead, TableRow, Link, Tooltip} from '@mui/material';
import EnhancedTable from 'components/customTable/enhancedTable';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {productItemSagaActions} from 'redux/vendor/detailsItemTab/productItemSaga';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {viewInFilterOptions} from 'config/constants';
import {useLocation} from 'react-router-dom';
import moment from 'moment';
const CustomTableStickyCell = styled(TableStickyCell)({
  top: '64px',
  fontFamily: 'Roboto Medium',
  letterSpacing: '0.1px',
  textAlign: 'right'
});
interface IPropType {
  venId: string;
}
//interface
interface IVendorProductProps {
  itemId: string;
  itemName: string;
  pack: string;
  size: string;
  oh: string;
  oo: string;
  ohWos: string;
  ohOoWos: string;
  rcvd: string;
  vendCost: string;
  exp: string;
  sales: string;
  comp: string;
  mng: string;
  id: string;
}
//Link Component
const LinkComponent = (row: any) => {
  return (
    <>
      <Link sx={{textDecoration: 'none'}}>{row.itemId}</Link>{' '}
    </>
  );
};

const ContentComponent = (row: any) => {
  if (row.itemName.length > 30)
    return (
      <Tooltip title={row.itemName} placement='top'>
        <Box
          sx={{
            maxWidth: '225px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {row.itemName}
        </Box>
      </Tooltip>
    );
  else {
    return <Box sx={{maxWidth: '100px'}}>{row.itemName}</Box>;
  }
};

//Sticky Header
const StickyProductHeaderCell = (props: any) => {
  //const theme = useTheme();
  const consolidatedData = generateStickyHeaderValues(props.data, props.columnCell);
  return (
    <>
      <TableHead>
        <TableRow>
          <BorderedTableStickyCell
            sx={{
              position: 'sticky',
              left: 0,
              backgroundColor: 'Background',
              zIndex: (theme) => theme.zIndex.appBar + 20,
              top: '64px'
            }}
          >
            <CustomTableStickyCell colSpan={6} sx={{color: 'grayscale.500', borderBottom: 0}}>
              <Typography variant='subtitle2'>CONSOLIDATED STATS</Typography>
            </CustomTableStickyCell>
          </BorderedTableStickyCell>
          <CustomTableStickyCell>{consolidatedData.oh}</CustomTableStickyCell>
          <CustomTableStickyCell>{consolidatedData.oo}</CustomTableStickyCell>
          <CustomTableStickyCell>{consolidatedData.ohWos}</CustomTableStickyCell>
          <BorderedTableStickyCell
            align='right'
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px'
            }}
          >
            {consolidatedData.ohOoWos}
          </BorderedTableStickyCell>
          <CustomTableStickyCell>{consolidatedData.rcvd}</CustomTableStickyCell>
          <CustomTableStickyCell>
            <Typography variant='subtitle2'>-</Typography>
          </CustomTableStickyCell>
          <BorderedTableStickyCell
            align='right'
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px'
            }}
          >
            <Typography variant='subtitle2'>-</Typography>
          </BorderedTableStickyCell>
          <CustomTableStickyCell sx={{textAlign: 'center'}}>
            <Typography variant='subtitle2'>{consolidatedData.sales}</Typography>
          </CustomTableStickyCell>
          <CustomTableStickyCell sx={{textAlign: 'center'}}>
            <Typography variant='subtitle2'>{consolidatedData.comp}</Typography>
          </CustomTableStickyCell>
          <BorderedTableStickyCell
            align='center'
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px'
            }}
          >
            <Typography variant='subtitle2'>{consolidatedData.mng}</Typography>
          </BorderedTableStickyCell>
        </TableRow>
      </TableHead>
    </>
  );
};
//Column Cell
const productsColumnCell: IColumnCell<IVendorProductProps>[] = [
  {
    id: 'itemId',
    name: 'itemId',
    displayName: 'ITEM ID',
    isNumeric: true,
    sticky: true,
    isColumnSortable: false,
    headerTextAlign: 'right',
    customCell: LinkComponent
  },
  {
    id: 'itemName',
    name: 'itemName',
    displayName: 'ITEM',
    sticky: true,
    isColumnSortable: false,
    sx: {
      width: '225px',
      boxSizing: 'border-box'
    },
    customCell: ContentComponent
  },
  {
    id: 'pack',
    name: 'pack',
    displayName: 'PACK',
    isNumeric: true,
    sticky: true,
    isColumnSortable: false,
    sx: {
      textAlign: 'right',
      width: '125px'
    }
  },
  {
    id: 'size',
    name: 'size',
    displayName: 'SIZE',
    sticky: true,
    isGroupBorderRequire: true,
    isColumnSortable: false,
    headerTextAlign: 'left',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'oh',
    name: 'oh',
    displayName: 'OH',
    isNumeric: true,
    prefixText: '$',
    consolidateType: 'SUM',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'oo',
    name: 'oo',
    displayName: 'OO',
    isNumeric: true,
    prefixText: '$',
    consolidateType: 'SUM',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'ohWos',
    name: 'ohWos',
    displayName: 'OH WOS',
    consolidateType: 'AVG',
    isNumeric: true
  },
  {
    id: 'ohOoWos',
    name: 'ohOoWos',
    displayName: 'OH+ OO WOS',
    consolidateType: 'AVG',
    isNumeric: true,
    isGroupBorderRequire: true
  },
  {
    id: 'rcvd',
    name: 'rcvd',
    displayName: 'RCVD',
    prefixText: '$',
    consolidateType: 'SUM',
    isNumeric: true
  },
  {
    id: 'vendCost',
    name: 'vendCost',
    displayName: 'VEND COST',
    prefixText: '$',
    isNumeric: true
  },
  {
    id: 'exp',
    name: 'exp',
    displayName: 'EXP',
    isNumeric: true,
    isGroupBorderRequire: true,
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'sales',
    name: 'sales',
    displayName: 'SALES',
    prefixText: '$',
    consolidateType: 'SUM',
    isNumeric: true
  },
  {
    id: 'comp',
    name: 'comp',
    displayName: 'COMP',
    prefixText: '$',
    consolidateType: 'SUM',
    isNumeric: true
  },
  {
    id: 'mng',
    name: 'mng',
    displayName: 'MGN',
    isNumeric: true,
    suffixText: '%',
    consolidateType: 'AVG',
    isGroupBorderRequire: true
  }
];

const ProductItem: React.FC<IPropType> = (props) => {
  const id = props.venId;
  //For Filteration
  //For Data Fetching from API
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const {productItem, loading} = useAppSelector((state) => state.productItemSlice);

  //For Binding the MasterData
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
  }, []);
  const {master} = useAppSelector((state) => state.masterSlice);
  const [dataOne, dataTwo, dataThree] = master;

  //For Filter change and generate Payload data
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      temp.push({key: key, value: value, condition: 'equal'});
    }
    return temp;
  };
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };
  //Row-Data
  const rowData: IVendorProductProps[] = productItem && productItem.length > 0 ? productItem : [];
  //Filter Template
  const defaultDateFormat = 'MM/DD/YY';
  const startDate = moment().startOf('week').format(defaultDateFormat);
  const endDate = moment().format(defaultDateFormat);
  const productsFilterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date (Sales, Comp, Receipt)',
      format: defaultDateFormat,
      defaultValue: `${startDate}|${endDate}`,
      sx: {
        width: '210px'
      }
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      defaultValue: 'All Departments',
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similarb to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      sx: {
        width: '178px'
      },
      allowAllOptions: true,
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View In (OH, OO, RCVD)',
      defaultValue: 'Dollars',
      sx: {
        width: '178px'
      },
      options: viewInFilterOptions
    }
  ];
  const defaultData = productsFilterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);
  React.useEffect(() => {
    dispatch({
      type: productItemSagaActions.FETCH_PRODUCTITEM_DATA,
      payload: {
        id: id,
        data: generatePayload(filtervalues)
      }
    });
  }, [filtervalues]);
  return (
    <>
      <Box sx={{marginTop: '16px'}}>
        <GridFilter
          values={filtervalues}
          filterTemplate={productsFilterTemplate}
          onFilterChange={onFilterApply}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='vendor-details-productItem'
        />
        {productItem && productItem.length > 0 ? (
          <>
            {loading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {' '}
                <CustomLoader id='product-item-load' />
              </Box>
            )}
            <EnhancedTable<IVendorProductProps>
              rowData={rowData}
              stickyRows={
                <StickyProductHeaderCell data={rowData} columnCell={productsColumnCell} />
              }
              columnCell={productsColumnCell}
              rowKey={'itemId'}
              defaultSortKey={'itemId'}
              allowAlternateColor={true}
              rowsPerPage={25}
              id='vendor-details-product'
            />
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              maxHeight: '844px'
            }}
          >
            {' '}
            {loading ? (
              <CustomLoader id='product-item-load' />
            ) : (
              <CustomError id='product-item-error' />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
export default ProductItem;
