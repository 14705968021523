import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {sizeLoading, sizeFailure, sizeSuccess} from 'redux/dashboard/master/sizeSlice';

export const sizeSagaActions = {
  FETCH_SIZE_DATA: 'FETCH_SIZE_DATA'
};

export function* fetchSizeData(): any {
  try {
    yield put(sizeLoading());
    const {data} = yield call(ProductAPIS.getSizeData);
    yield put(sizeSuccess(data));
  } catch (error) {
    yield put(sizeFailure());
  }
}

export default function* watchFetchSizeData() {
  yield takeEvery(sizeSagaActions.FETCH_SIZE_DATA, fetchSizeData);
}
