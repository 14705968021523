import * as React from 'react';
import {Box, Typography, Stack} from '@mui/material';
import {Mode, POStatus} from 'features/purchaseOrder/types';

interface ISupplierContact {
  poStatus: string;
  mode: string;
  supplierData: any;
}

const SupplierContact: React.FC<ISupplierContact> = ({poStatus, ...props}) => {
  if (poStatus?.toUpperCase() === POStatus.DRAFT) {
    return (
      <Box
        sx={{
          mt: 4,
          px: 2,
          mx: '160px',
          bgcolor: 'grayscale.50',
          boxShadow: '0 1px 2px 0 rgba(0,0,0,0.5)'
        }}
      >
        <Typography variant='subtitle1' sx={{pb: 3}}>
          Supplier Contact
        </Typography>
        <Stack direction={'row'} sx={{pb: 3.625, gap: '1.5%'}}>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                pb: '9px',
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Contact
            </Typography>
            <Typography variant='body2'>Danny Harish</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                pb: '9px',
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Email
            </Typography>
            <Typography variant='body2'>{props.supplierData?.email}</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Telephone
            </Typography>
            <Typography variant='body2'>{props.supplierData?.telephone}</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Fax
            </Typography>
            <Typography variant='body2'>{props.supplierData?.fax}</Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} sx={{pb: 3.625, gap: '1.5%'}}>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                pb: '9px',
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Street
            </Typography>
            <Typography variant='body2'>{props.supplierData?.street}</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                pb: '9px',
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              House Number
            </Typography>
            <Typography variant='body2'>{props.supplierData?.houseNo}</Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} sx={{gap: '1.5%'}}>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                pb: '9px',
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Postal Code
            </Typography>
            <Typography variant='body2'>{props.supplierData?.postalCode}</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                pb: '9px',
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              City
            </Typography>
            <Typography variant='body2'>{props.supplierData?.city}</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                pb: '9px',
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Region (State/Province)
            </Typography>
            <Typography variant='body2'>{props.supplierData?.region}</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                pb: '9px',
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Country Key
            </Typography>
            <Typography variant='body2'>{props.supplierData?.country}</Typography>
          </Stack>
        </Stack>
      </Box>
    );
  } else {
    return null;
  }
};

export default SupplierContact;
