import React from 'react';
import {Box, Typography} from '@mui/material';
import ExportModal from 'components/baseComponents/exportOption';
import CustomButton from 'components/baseComponents/customButton';
import {BreadcrumbNav as SupplierSalesBreadcrumbNav} from 'features/report/reportBreadCrumbs';
import PerpetualInventoryGraph from 'features/report/inventory/perpetualInventoryGraph';

interface IPropType {
  defaultValue?: number;
}

//Below is Main components
const PreprtualInventoryAverage: React.FC<IPropType> = () => {
  const [showExpModal, setShowExpModal] = React.useState(false);
  const handleClick = () => {
    setShowExpModal(true);
  };
  return (
    <>
      <Box sx={{mx: 5, mb: 5, mt: 5}}>
        <SupplierSalesBreadcrumbNav />
        {/* For Making the two elements in same div left and right */}
        <Box
          sx={{
            pl: 0,
            pt: 1,
            pb: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end'
          }}
          flexGrow={1}
        >
          <Typography variant='h4' align='left'>
            Preprtual Inventory Averages
          </Typography>
          <CustomButton
            disableElevation
            id='perpetualInventoryExport'
            onClick={handleClick}
            sx={{
              bgcolor: 'primary.main',
              color: 'common.white',
              '&.Mui-disabled': {
                bgcolor: 'grayscale.200'
              }
            }}
            variant={'contained'}
          >
            <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
              Export
            </Typography>
          </CustomButton>
        </Box>
        <Box>
          <PerpetualInventoryGraph />
        </Box>
      </Box>
      {showExpModal && (
        <ExportModal
          id='perpetualInventoryModal'
          open={showExpModal}
          handleClose={() => setShowExpModal(false)}
        />
      )}
    </>
  );
};

export default PreprtualInventoryAverage;
