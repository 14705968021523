import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  productDetailsFailure,
  productDetailsLoading,
  productDetailsSuccess
} from 'redux/product/details/productDetails/productDetailsSlice';

export const productDetailsSagaActions = {
  FETCH_PRODUCTDETAILS_DATA: 'FETCH_PRODUCTDETAILS_DATA'
};

export function* fetchProductDetailsData(action: any): any {
  try {
    yield put(productDetailsLoading());
    const {data} = yield call(ProductAPIS.getProductDetailsData, action.payload);
    yield put(productDetailsSuccess(data));
  } catch (error) {
    yield put(productDetailsFailure());
  }
}

export default function* watchFetchProductDetailsData() {
  yield takeEvery(productDetailsSagaActions.FETCH_PRODUCTDETAILS_DATA, fetchProductDetailsData);
}
