import React from 'react';
import {Box, Typography, InputLabel, SxProps} from '@mui/material';

export type FileUploadProps = {
  id: string;
  imageButton?: boolean;
  accept: string;
  hoverLabel?: string;
  dropLabel?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  image?: {
    url: string;
    imageStyle?: {
      width?: string;
      height?: string;
    };
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
  sx?: SxProps;
};

const FileUpload: React.FC<FileUploadProps> = ({
  accept,
  id,
  imageButton = false,
  hoverLabel = 'Drag & drop here to attach files.',
  dropLabel = 'Drop file here',
  width = '100%',
  height = '100px',
  backgroundColor = '#fff',
  image: {
    imageStyle = {
      height: 'inherit'
    }
  } = {},
  onChange,
  onDrop,
  sx = {}
}) => {
  const [imageUrl, setImageUrl] = React.useState('');
  const [labelText, setLabelText] = React.useState<string>(hoverLabel);
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false);
  const stopDefaults = (e: React.DragEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      if (imageButton && e?.dataTransfer.files[0]) {
        setImageUrl(URL.createObjectURL(e?.dataTransfer.files[0]));
      }
      onDrop(e);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if (imageButton && event.target.files[0]) {
    //     setImageUrl(URL.createObjectURL(event.target.files[0]))
    // }

    onChange(event);
  };

  return (
    <Box sx={sx} id={`customFileUpload-${id}`}>
      <input
        onChange={handleChange}
        accept={accept}
        style={{display: 'none'}}
        id='file-upload'
        type='file'
      />

      <InputLabel
        htmlFor='file-upload'
        sx={{
          cursor: 'pointer',
          textAlign: 'center',
          display: 'flex',
          '&:hover p,&:hover svg,& img': {
            opacity: 1
          },
          '& p, svg': {
            opacity: 0.4
          },
          '&:hover img': {
            opacity: 0.3
          }
        }}
        {...dragEvents}
      >
        <Box width={width} height={height} bgcolor={backgroundColor} sx={{pointerEvents: 'none'}}>
          {imageButton && (
            <Box position='absolute' height={height} width={width}>
              <img alt='file upload' src={imageUrl} style={imageStyle} />
            </Box>
          )}

          {(!imageButton || isDragOver || isMouseOver) && (
            <>
              <Box
                height={height}
                width={width}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'absolute'
                }}
              >
                <Typography>{labelText}</Typography>
              </Box>
            </>
          )}
        </Box>
      </InputLabel>
    </Box>
  );
};

export default FileUpload;
