import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {classOTBFailure, classOTBSuccess, classOTBLoading} from 'redux/dashboard/otb/classOTBSlice';

export const classOTBSagaActions = {
  FETCH_CLASSOTB_DATA: 'FETCH_CLASSOTB_DATA'
};

export function* fetchClassOTBData(action: any): any {
  try {
    yield put(classOTBLoading());
    const {data} = yield call(DashboardServiceAPIS.getClassOTBData, action.payload);
    yield put(classOTBSuccess(data));
  } catch (error) {
    yield put(classOTBFailure());
  }
}

export default function* watchFetchClassOTBData() {
  yield takeEvery(classOTBSagaActions.FETCH_CLASSOTB_DATA, fetchClassOTBData);
}
