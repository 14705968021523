import React from 'react';
import {Box, Card, CardContent, CardHeader, styled, SxProps, Theme} from '@mui/material';
import {TILE_WIDTH, TILE_DEFULT_HEIGHT} from 'config/constants';

interface ICardProp {
  id: string;
  header: any;
  content: any;
  width?: string;
  headerSx?: SxProps<Theme>;
  height?: string;
}

export const CustomCardHeader = styled(CardHeader)({
  height: 'auto',
  padding: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  marginBottom: '24px'
});

export const CustomCardContent = styled(CardContent)({
  padding: 0,
  maxHeight: '264px',
  display: 'flex',
  justifyContent: 'flex-start'
});

export const CustomCard: React.FC<ICardProp> = (props) => {
  const {id, header, headerSx, content, ...rest} = props;
  return (
    <Card
      id={`customCard${id}`}
      sx={{
        width: props.width ? props.width : TILE_WIDTH.one,
        padding: props.width ? '16px 16px 0 32px' : '32px 16px 0 32px',
        height: props.height ? props.height : TILE_DEFULT_HEIGHT,
        boxSizing: 'border-box'
      }}
      {...rest}
    >
      <CustomCardHeader subheader={header} sx={headerSx ? headerSx : {}} />
      <CustomCardContent>
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
            paddingRight: '12px',
            boxSizing: 'border-box'
          }}
        >
          {content}
        </Box>
      </CustomCardContent>
    </Card>
  );
};
