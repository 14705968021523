import React from 'react';
import {Route, Routes, useLocation, Navigate} from 'react-router-dom';
import Dashboard from 'features/dashboard/dashboard';
import Products from 'features/product/details/product';
import PurchaseOrder from 'features/purchaseOrder/purchaseOrder';
import Vendor from 'features/vendor/vendor';
import Report from 'features/report/report';
import PortalThemeProvider from 'themes/portalThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import {Layout, ContainerLayout, ExtendedContainerLayout} from 'components/layout';
import ProductDetail from 'features/product/details/productDetail';
import PODetail from 'features/purchaseOrder/poDetails';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import SupplierSales from 'features/report/sales/supplierSales';
import store from 'store/configureStore';
import VendorDetails from 'features/vendor/vendorDetails';
import {ReduxProvider} from 'redux-provider';
import WarehouseLiability from 'features/report/inventory/warehouseLiability';
import Login from 'features/login/login';
import ReceivedPOItems from 'features/report/inventory/receivedPOItem';
import DemandForecast from 'features/report/sales/demandForecast';
import InventoryBalance from 'features/report/inventory/inventoryBalance';
import PreprtualInventoryAverage from 'features/report/inventory/preprtualInventoryAverage';
import {NotFound} from 'features/login/notFound';
import {PrivateRoute} from 'features/login/privateRoute';
import UserContext from 'features/login/authProvider';
import {getLocalStorage} from 'util/tokenizer';
import ProductSearch from 'features/product/list/productSearch';
import NewApp from 'features/newapp/newapp';

function App() {
  const location = useLocation();
  const userLoggedIn = getLocalStorage('isLoggedIn') ? true : false;

  return (
    <ReduxProvider reduxStore={store}>
      <PortalThemeProvider theme={'normal'}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <UserContext.Provider value={{loggedIn: userLoggedIn}}>
            <>
              <Routes>
                <Route path='/' element={<Navigate to='/Login' />} />
                <Route path='/Login' element={<Login />} />
                <Route path='/TaskManagement' element={<NewApp />} />
                <Route
                  path='/Dashboard'
                  element={
                    <Layout path={location?.pathname}>
                      <ContainerLayout path={location?.pathname}>
                        <PrivateRoute path={location?.pathname}>
                          <Dashboard />
                        </PrivateRoute>
                      </ContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Product'
                  element={
                    <Layout path={location?.pathname}>
                      <ExtendedContainerLayout>
                        {' '}
                        <PrivateRoute path={location.pathname}>
                          <Products />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/ProductSearch/:searchText'
                  element={
                    <Layout path={location?.pathname}>
                      <ExtendedContainerLayout>
                        {' '}
                        <PrivateRoute path={location.pathname}>
                          <ProductSearch />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Product/:id'
                  element={
                    <Layout path={location?.pathname} key={location?.pathname}>
                      <ContainerLayout>
                        {' '}
                        <PrivateRoute path={location.pathname}>
                          <ProductDetail />
                        </PrivateRoute>
                      </ContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/PO/:id'
                  element={
                    <Layout path={location?.pathname} key={location?.pathname}>
                      <ExtendedContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <PODetail />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/PO/Create'
                  element={
                    <Layout path={location?.pathname} key={location?.pathname}>
                      <ExtendedContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <PODetail />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Vendor'
                  element={
                    <Layout path={location?.pathname}>
                      <ExtendedContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <Vendor />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Vendor/:id'
                  element={
                    <Layout path={location?.pathname} key={location?.pathname}>
                      <ContainerLayout>
                        {' '}
                        <PrivateRoute path={location.pathname}>
                          <VendorDetails />
                        </PrivateRoute>
                      </ContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/PO'
                  element={
                    <Layout path={location?.pathname}>
                      <ExtendedContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <PurchaseOrder />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Report'
                  element={
                    <Layout path={location?.pathname}>
                      <ContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <Report />
                        </PrivateRoute>
                      </ContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Report/demandForecast'
                  element={
                    <Layout path={location?.pathname}>
                      <ContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <DemandForecast />
                        </PrivateRoute>
                      </ContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Report/ReceivedPo'
                  element={
                    <Layout path={location?.pathname}>
                      <ExtendedContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <ReceivedPOItems />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Report/warehouse-Liability'
                  element={
                    <Layout path={location?.pathname}>
                      <ExtendedContainerLayout>
                        {' '}
                        <PrivateRoute path={location.pathname}>
                          <WarehouseLiability />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Report/InventoryBalance'
                  element={
                    <Layout path={location?.pathname}>
                      <ExtendedContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <InventoryBalance />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Report/PreprtualInventoryAverage'
                  element={
                    <Layout path={location?.pathname}>
                      <ExtendedContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <PreprtualInventoryAverage />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='/Report/SupplierSales'
                  element={
                    <Layout path={location?.pathname}>
                      <ExtendedContainerLayout>
                        <PrivateRoute path={location.pathname}>
                          <SupplierSales />
                        </PrivateRoute>
                      </ExtendedContainerLayout>
                    </Layout>
                  }
                />
                <Route
                  path='*'
                  element={
                    <ContainerLayout>
                      <NotFound />
                    </ContainerLayout>
                  }
                />
              </Routes>
            </>
            <CssBaseline />
          </UserContext.Provider>
        </LocalizationProvider>
      </PortalThemeProvider>
    </ReduxProvider>
  );
}

export default App;
