import {IColumnCell} from 'components/customTable/types';
import moment from 'moment';
import {IFilterValues} from 'components/customTable/gridFilter';

/**
 * Function to return all values of key in array
 * @param arr Array of any type
 * @param columnName key in array
 * @returns Collection ofvalue for the specified key
 */
export function getColumnValues<T>(arr: Array<any>, columnName: string, length?: number): Array<T> {
  return arr.map((elem: any) =>
    elem[columnName]
      ? length && elem[columnName].length > length
        ? elem[columnName].substring(0, length) + '...'
        : elem[columnName]
      : null
  );
}

/**
 * function required for any sort
 * @param a Array of any type
 * @param b Array of any type
 * @param orderBy Key in array
 */
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  //console.log('sort', a, b, orderBy);
  const lowerCaseA = (a[orderBy] as unknown as string)?.toLocaleLowerCase();
  const lowerCaseB = (b[orderBy] as unknown as string)?.toLocaleLowerCase();
  return lowerCaseA?.localeCompare(lowerCaseB, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
}

/**
 * Return the re-ordered list
 * @param list Original List
 * @param startIndex Source index
 * @param endIndex Destination index
 * @returns Ordered List
 */
export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

interface IKeyValue {
  label: string;
  value: string;
  dependsOn?: string;
}

/**
 * Return array of options
 * @param data
 * @param property
 * @returns
 */
export const generateLabelValue = (
  data: any,
  label: string,
  value?: string,
  dependsOn?: string
) => {
  const newArr = [];
  for (let i = 0; i < data?.length; i++) {
    const obj: IKeyValue = {
      label: '',
      value: ''
    };
    if (data[i][`${label}`]) {
      obj.label = data[i][`${label}`];
      obj.value = data[i][`${value || label}`];
      obj.dependsOn = data[i][`${dependsOn}`];
      newArr.push(obj);
    }
  }
  return newArr;
};

export const generatePayload = (values: IFilterValues) => {
  const temp = [];
  for (const [key, value] of Object.entries(values)) {
    if (value instanceof Array) {
      temp.push({key: key, value: JSON.stringify(value), condition: 'LIKE'});
    } else {
      if (value && value != '' && value.toString().substring(0, 4).toLowerCase() != 'all ') {
        temp.push({key: key, value: value, condition: 'EQUAL'});
      }
    }
  }
  return temp;
};

/**
 * Format string
 * @param str Property for format to be done
 * @returns formated string
 */
export const formatString = (str: string) => {
  let temp = str;
  temp = temp?.replace('%20', '');
  return temp;
};

export const dateRangeCalculator = (rangeOption: string) => {
  const defaultDateFormat = 'MM/DD/YYYY';
  let startDate, endDate;
  switch (rangeOption) {
    case 'Yesterday':
      startDate = moment().subtract(1, 'days').format(defaultDateFormat);
      endDate = moment().format(defaultDateFormat);
      break;
    case 'weekToDate':
      //To-do- Remove this once demo done
      //startDate = moment().startOf('week').format(defaultDateFormat);
      startDate = '01/01/2023';
      endDate = moment().format(defaultDateFormat);
      break;
    case 'lastWeek':
      startDate = moment().subtract(1, 'weeks').startOf('week').format(defaultDateFormat);
      endDate = moment().subtract(1, 'weeks').endOf('week').format(defaultDateFormat);
      break;
    case 'last4Weeks':
      startDate = moment().subtract(4, 'weeks').startOf('week').format(defaultDateFormat);
      endDate = moment().format(defaultDateFormat);
      break;
    case 'monthToDate':
      startDate = moment().startOf('month').format(defaultDateFormat);
      endDate = moment().format(defaultDateFormat);
      break;
    case 'quarterToDate':
      startDate = moment().startOf('year').format(defaultDateFormat);
      endDate = moment().format(defaultDateFormat);
      break;
    case 'yearToDate':
      startDate = moment().startOf('quarter').format(defaultDateFormat);
      endDate = moment().format(defaultDateFormat);
      break;
  }
  return startDate + '|' + endDate;
};

export const generateStickyHeaderValues = <T>(data: T[], columnCell: IColumnCell<T>[]) => {
  const filterColumnCell = columnCell?.filter(
    (ele: any) => ele.consolidateType && ele.consolidateType != null
  );
  const consolidatedData = data?.reduce((accumulator: any, c: any) => {
    filterColumnCell?.forEach((ele: any) => {
      if (ele.groupedKeys) {
        ele.groupedKeys.forEach((subele: any) => {
          let value = c[subele];
          if (value) {
            value = value[0] === '$' ? value.substring(1) : value;
            if (accumulator[subele]) {
              accumulator[subele] = accumulator[subele] + parseFloat(value);
            } else {
              accumulator[subele] = parseFloat(value);
            }
          }
        });
      } else {
        let value = c[ele.id];
        if (value) {
          value = value && value[0] === '$' ? value.substring(1) : value;

          if (accumulator[ele.id]) {
            accumulator[ele.id] = accumulator[ele.id] + parseFloat(value);
          } else {
            accumulator[ele.id] = parseFloat(value);
          }
        }
      }
    });
    return accumulator;
  }, {});
  filterColumnCell?.forEach((ele: any) => {
    if (ele.groupedKeys) {
      ele.groupedKeys.forEach((subele: any) => {
        if (consolidatedData[subele]) {
          if (ele.consolidateType == 'AVG') {
            const count =
              data.filter((record: any) => record[subele] && record[subele] != '') || [];
            consolidatedData[subele] = consolidatedData[subele] / count.length;
          }
          consolidatedData[subele] = +Number(consolidatedData[subele]).toFixed(2);
          consolidatedData[subele] = consolidatedData[subele].toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          if (ele.suffixText == '%') {
            consolidatedData[subele] = consolidatedData[subele].toFixed(1);
          }
          consolidatedData[ele.id] = consolidatedData[ele.id]?.toLocaleString();
          if (ele.prefixText) {
            consolidatedData[subele] = ele.prefixText + consolidatedData[subele].toString();
          }
          if (ele.suffixText) {
            consolidatedData[subele] = consolidatedData[subele].toString() + ele.suffixText;
          }
          if (!consolidatedData[subele]) {
            consolidatedData[subele] = 0;
          }
        }
      });
    } else {
      if (consolidatedData[ele.id]) {
        if (ele.consolidateType == 'AVG') {
          const count = data.filter((record: any) => record[ele.id] && record[ele.id] != '') || [];
          consolidatedData[ele.id] = consolidatedData[ele.id] / count.length;
        }
        consolidatedData[ele.id] = +consolidatedData[ele.id].toFixed(2);
        consolidatedData[ele.id] = consolidatedData[ele.id]?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        if (ele.prefixText) {
          consolidatedData[ele.id] = ele.prefixText + consolidatedData[ele.id].toString();
        }
        if (ele.suffixText) {
          consolidatedData[ele.id] = consolidatedData[ele.id].toString() + ele.suffixText;
        }
        if (!consolidatedData[ele.id]) {
          consolidatedData[ele.id] = 0;
        }
      }
    }
  });
  return consolidatedData;
};

export const decimalConverter = (value: any, fractionDigits: number | undefined = 2) => {
  // eslint-disable-next-line use-isnan
  if (value === undefined || parseFloat(value) === NaN || value === null) {
    return '';
  } else {
    return parseFloat(value).toFixed(fractionDigits);
  }
};
