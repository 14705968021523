import {createSlice} from '@reduxjs/toolkit';
export interface alertState {
  loading: boolean;
  alert: Array<any>;
  error: string;
}

const initialState: alertState = {
  loading: false,
  alert: [],
  error: ''
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    alertLoading: (state) => {
      state.loading = true;
    },
    alertSuccess: (state, action) => {
      state.alert = action.payload;
      state.loading = false;
    },
    alertFailure: (state) => {
      state.loading = false;
      state.alert = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default alertSlice.reducer;
export const {alertLoading, alertSuccess, alertFailure} = alertSlice.actions;
