import {useEffect} from 'react';
import Typography from '@mui/material/Typography';

export default function NewApp() {
  useEffect(() => {
    const role = localStorage.getItem('role');

    if (role === 'BUYER') {
      //replace http://localhost:3000 with the deployed app base url

      window.location.replace('http://34.134.6.21:3000/redirect-to-owner');
    } else if (role === 'EXECUTIVE') {
      //replace http://localhost:3000 with the deployed app base url

      window.location.replace('http://34.134.6.21:3000/redirect-to-staff');
    }
  }, []);
  return <Typography>Redirecting...</Typography>;
}
