import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  inventoryFailure,
  inventoryLoading,
  inventorySuccess
} from 'redux/dashboard/inventory/inventorySlice';

export const inventorySagaActions = {
  FETCH_INVENTORY_DATA: 'FETCH_INVENTORY_DATA'
};

export function* fetchInventoryData(action: any): any {
  try {
    yield put(inventoryLoading({type: action.payload}));
    const {data} = yield call(DashboardServiceAPIS.getInventoryData, action.payload);
    yield put(inventorySuccess({result: data, type: action.payload.type}));
  } catch (error) {
    yield put(inventoryFailure(action));
  }
}

export default function* watchFetchInventoryData() {
  yield takeEvery(inventorySagaActions.FETCH_INVENTORY_DATA, fetchInventoryData);
}
