import {Box, styled} from '@mui/material';
import React from 'react';
import BarGraphComponent, {IAxis} from 'components/barGraph';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import {CustomCard} from 'components/baseComponents/customCard';
import {useTheme} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {marginSagaActions} from 'redux/dashboard/margin/marginSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {generatePayload} from 'util/arrayOperations';

interface IPropType {
  defaultValue: string;
  filteredData: any;
}

const dropdownData = [
  {
    label: 'Class Margin',
    value: 'class'
  },
  {
    label: 'Department Margin',
    value: 'department'
  },
  {
    label: 'Regional Margin',
    value: 'regional'
  }
];

const CustomBox = styled(Box)({
  '& .js-plotly-plot .plotly, .js-plotly-plot .plotly div': {
    fontFamily: 'Roboto'
  },
  '& .MuiPaper-root .MuiCardContent-root .MuiBox-root:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const MarginTile: React.FC<IPropType> = (props) => {
  const [marginComp, setMarginComp] = React.useState(props.defaultValue);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  /**
   * disptach margin tile data
   */
  const dispatchData = () => {
    dispatch({
      type: marginSagaActions.FETCH_MARGIN_DATA,
      payload: {
        type: marginComp,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [marginComp, props.filteredData]);

  const {margin, loading, error} = useAppSelector((state) => state.marginSlice);
  const handleSelect = (event: any): void => {
    dispatch({
      type: marginSagaActions.FETCH_MARGIN_DATA,
      payload: {
        ...props?.filteredData,
        type: event?.target.value
      }
    });
    setMarginComp(event?.target.value);
  };
  const queryArgs = {
    selectTabNo: 3
  };

  const generateContent = () => {
    switch (marginComp) {
      case 'department':
        return (
          <BarGraphComponent
            data={margin[marginComp]}
            xAxis={[...xAxis]}
            yAxis={departMentYAxis}
            showPlan={true}
            plan={planAxis}
            redirectUrl={'products'}
            queryData={queryArgs}
            id='departMarginCompBar'
          />
        );
      case 'regional':
        return (
          <BarGraphComponent
            data={margin[marginComp]}
            xAxis={[...xAxis]}
            yAxis={regionYAxis}
            showPlan={true}
            plan={planAxis}
            redirectUrl={'products'}
            queryData={queryArgs}
            id='regionalMarginCompBar'
          />
        );
      default:
        return (
          <BarGraphComponent
            data={margin[marginComp]}
            xAxis={[...xAxis]}
            yAxis={classYAxis}
            showPlan={true}
            plan={planAxis}
            redirectUrl={'products'}
            queryData={queryArgs}
            id='classMarginCompBar'
          />
        );
    }
  };

  const xAxis: Array<IAxis> = [
    {
      name: 'yearToDate',
      displayTitle: 'Year to Date',
      color: theme.palette.primary.dark,
      unit: '%'
    },
    {
      name: 'lastYear',
      displayTitle: 'Last Year',
      color: theme.palette.neutral?.secondary2,
      unit: '%'
    }
  ];

  const classYAxis = {
    name: 'className',
    displayTitle: 'name',
    unit: '%'
  };
  const departMentYAxis = {
    name: 'departmentName',
    displayTitle: 'name',
    unit: '%'
  };
  const regionYAxis = {
    name: 'storeRegion',
    displayTitle: 'name',
    unit: '%'
  };

  const planAxis = {
    name: 'plan',
    displayTitle: 'plan',
    color: theme.palette.neutral?.secondary1,
    unit: '%'
  };

  const Header = () => {
    return (
      <DropDownWrapper>
        <CustomSelect
          options={dropdownData}
          id='marginTile'
          value={marginComp}
          onChange={handleSelect}
        />
      </DropDownWrapper>
    );
  };

  const Content = () => {
    return <CustomBox>{generateContent()}</CustomBox>;
  };

  if (margin[marginComp] && margin[marginComp].length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='marging-tileCard'
          header={<Header />}
          content={loading[marginComp] ? <CustomLoader id='margin-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='margin-tileCard-noData'
          header={<Header />}
          content={
            loading[marginComp] ? (
              <CustomLoader id='margin-load' />
            ) : (
              <CustomError
                id='margin-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default MarginTile;
