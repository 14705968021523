import {Box, Stack, styled, TableHead, TableRow, Typography, useTheme} from '@mui/material';
import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import StyledChip from 'components/baseComponents/customChip';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {tagSagaActions} from 'redux/dashboard/master/tagSaga';
import {buyerSagaActions} from 'redux/dashboard/master/buyerSaga';
import {vendorListSagaActions} from 'redux/vendor/list/vendorListSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import {ContentComponent} from 'features/vendor/vendorUtil';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import moment from 'moment';
import {viewInFilterOptions} from 'config/constants';

const CustomTableStickyCell = styled(TableStickyCell)({
  top: '64px',
  fontFamily: 'Roboto Medium',
  letterSpacing: '0.1px',
  textAlign: 'right'
});
//Below is interface for Grid update
interface IVendorGridProps {
  vendorId: string;
  vendorName: string;
  onHand: string;
  onOrder: string;
  sales: string;
  compSales: string;
  compPercent: string;
  plan: string;
  receipts: string;
  receiptsVariance: string;
  perfectOrder: string;
  margin: string;
  penetration: string;
  tags?: Array<string>;
  id: string;
}
//StickyHeader [which describe the header part of table]
const StickyHeaderCell = (props: any) => {
  const theme = useTheme();
  const consolidatedData = generateStickyHeaderValues(props.data, columnCell);
  return (
    <>
      <TableHead>
        <TableRow>
          <BorderedTableStickyCell
            sx={{
              position: 'sticky',
              borderBottom: 0,
              backgroundColor: 'Background',
              // zIndex: theme.zIndex.appBar + 20,
              top: '64px'
            }}
          >
            <CustomTableStickyCell colSpan={2} sx={{color: 'grayscale.500', borderBottom: 0}}>
              <Typography variant='subtitle2'>CONSOLIDATED STATS</Typography>
            </CustomTableStickyCell>
          </BorderedTableStickyCell>
          <CustomTableStickyCell>{consolidatedData.onHand}</CustomTableStickyCell>
          <BorderedTableStickyCell
            align='right'
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px'
            }}
          >
            {consolidatedData.onOrder}
          </BorderedTableStickyCell>
          <CustomTableStickyCell sx={{textAlign: 'center'}}>
            {consolidatedData.sales}
          </CustomTableStickyCell>
          {/* <CustomTableStickyCell>{consolidatedData.compSales}</CustomTableStickyCell>
          <CustomTableStickyCell>{consolidatedData.compPercent}</CustomTableStickyCell> */}
          <BorderedTableStickyCell
            align='right'
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px'
            }}
          >
            <Typography variant='subtitle2'>
              {consolidatedData.compSales ? consolidatedData.compSales : '$0'}
            </Typography>
          </BorderedTableStickyCell>
          <CustomTableStickyCell>
            <Typography variant='subtitle2'>{consolidatedData.receipts}</Typography>
          </CustomTableStickyCell>
          <CustomTableStickyCell>
            <Typography variant='subtitle2'>{consolidatedData.receiptsVariance}</Typography>
          </CustomTableStickyCell>
          <BorderedTableStickyCell
            align='right'
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px'
            }}
          >
            <Typography variant='subtitle2'>{consolidatedData.perfectOrder}</Typography>
          </BorderedTableStickyCell>
          <CustomTableStickyCell>
            <Typography variant='subtitle2'>{consolidatedData.margin}</Typography>
          </CustomTableStickyCell>
          <BorderedTableStickyCell
            align='right'
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px'
            }}
          >
            <Typography variant='subtitle2'>-</Typography>
          </BorderedTableStickyCell>
        </TableRow>
      </TableHead>
    </>
  );
};

const ColumnLabelStack = (tags: any, classID: string, subClassID: string) => {
  let updatedTags = [...tags];
  updatedTags = updatedTags.length < 3 ? updatedTags : updatedTags.splice(0, 2);
  return (
    <Stack sx={{flexGrow: 1, maxHeight: 2}} direction='row' spacing={1}>
      <Typography
        variant='caption'
        sx={{fontSize: '13px', opacity: 0.5, fontWeight: 'bold', lineHeight: 1.2}}
        textAlign='center'
      >
        {classID && subClassID ? `${classID}/${subClassID}` : null}
      </Typography>
      {updatedTags.map((name: any) => {
        return (
          <StyledChip
            key={`${name}chip`}
            id={`vendorList-chip-${name}`}
            label={
              <Typography
                variant='caption'
                sx={{
                  fontSize: '10px',
                  color: 'primary.main',
                  fontWeight: 'bold'
                }}
                textAlign='center'
              >
                {name}
              </Typography>
            }
          />
        );
      })}
      {tags.length > 2 ? (
        <Typography
          id={`vendorList-tagschip-count`}
          variant='caption'
          sx={{
            fontSize: '10px',
            color: 'primary.main',
            fontWeight: 'bold'
          }}
          textAlign='center'
        >
          +{tags.length - 2}
        </Typography>
      ) : null}
    </Stack>
  );
};
//Below link component code will be use to link Item Associated with it
const LinkComponent = (row: any) => {
  return (
    <>
      <Link
        to={{pathname: `/Vendor/${row.vendorId}`}}
        state={{
          data: {accessData: [], myRow: row},
          vendorId: row.vendorId
        }}
      >
        {row.vendorId}
      </Link>{' '}
    </>
  );
};

const columnCell: IColumnCell<IVendorGridProps>[] = [
  {
    id: 'vendorId',
    name: 'Vendor Id',
    displayName: 'VEN ID',
    customCell: LinkComponent,
    sticky: true,
    sx: {
      minWidth: '80px',
      fontFamily: 'Roboto Medium'
    }
  },
  {
    id: 'vendorName',
    name: 'Vendor Name',
    displayName: 'VENDOR NAME',
    sticky: true,
    hasTags: true,
    isGroupBorderRequire: true,
    customCell: ContentComponent,
    sx: {
      minWidth: '220px'
    }
  },
  {
    id: 'onHand',
    name: 'On Hand',
    displayName: 'OH',
    isNumeric: true,
    prefixText: '$',
    consolidateType: 'SUM',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'onOrder',
    name: 'On Order',
    displayName: 'OO',
    isNumeric: true,
    isGroupBorderRequire: true,
    prefixText: '$',
    consolidateType: 'SUM',
    sx: {
      minWidth: '100px',
      textAlign: 'right'
    }
  },
  {
    id: 'sales',
    name: 'Sales',
    displayName: 'SALES',
    isNumeric: true,
    prefixText: '$',
    consolidateType: 'SUM',
    sx: {
      minWidth: '100px',
      textAlign: 'center'
    }
  },
  {
    id: 'compSales',
    name: 'Comp Sales',
    displayName: 'COMP SALES',
    consolidateType: 'SUM',
    isNumeric: true,
    prefixText: '$',
    isGroupBorderRequire: true,
    sx: {
      textAlign: 'right'
    }
  },
  // {
  //   id: 'compPercent',
  //   name: 'compPercent',
  //   displayName: 'COMP %',
  //   isNumeric: true,
  //   suffixText: '%',
  //   consolidateType: 'AVG',
  //   sx: {
  //     textAlign: 'right'
  //   }
  // },
  // {
  //   id: 'plan',
  //   name: 'plan',
  //   displayName: 'PLAN',
  //   isNumeric: true,
  //   isGroupBorderRequire: true,
  //   consolidateType: 'AVG',
  //   suffixText: '%',
  //   sx: {
  //     textAlign: 'right'
  //   }
  // },
  {
    id: 'receipts',
    name: 'Receipts',
    displayName: 'RECEIPTS',
    isNumeric: true,
    prefixText: '$',
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'receiptsVariance',
    name: 'Receipts Variance',
    displayName: 'RCPT VAR',
    isNumeric: true,
    suffixText: '%',
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'perfectOrder',
    name: 'Perfect Order',
    displayName: 'PERFECT ORDER',
    isNumeric: true,
    isGroupBorderRequire: true,
    suffixText: '%',
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'margin',
    name: 'Margin',
    displayName: 'MARGIN',
    isNumeric: true,
    suffixText: '%',
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'penetration',
    name: 'Penetration',
    displayName: 'PEN',
    isNumeric: true,
    consolidateType: 'AVG',
    isGroupBorderRequire: true,
    suffixText: '%'
  }
];

const generateFilterTemplate = (
  masterData: Array<any>,
  startDate: any,
  endDate: any,
  buyerData: any,
  tagData: any
) => {
  const [dataOne, dataTwo, dataThree] = masterData;
  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date (Sales, Receipts, Margin)',
      format: 'MM/DD/YYYY',
      defaultValue: `${startDate}|${endDate}`,
      sx: {
        width: '210px'
      }
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      defaultValue: 'All Departments',
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      sx: {
        width: '178px'
      },
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      allowAllOptions: true,
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'tagName',
      title: 'Tag',
      defaultValue: 'All Tags',
      placeholder: 'Tags',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      options: generateLabelValue(tagData, 'tagName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'buyerId',
      title: 'Buyer',
      defaultValue: 'All Buyers',
      placeholder: 'Buyers',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      options: generateLabelValue(buyerData, 'buyerName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View in (OH,OO,Sales,Rcpts)',
      defaultValue: 'Dollars',
      sx: {
        width: '178px'
      },
      options: viewInFilterOptions
    }
  ];
  return filterTemplate;
};

//Below we are creating and returing the main componenet
const VendorList: React.FC = () => {
  //For API data fetching and Filter Update.
  const dispatch = useAppDispatch();
  const location: any = useLocation();

  const {master} = useAppSelector((state) => state.masterSlice);
  const defaultDateFormat = 'MM/DD/YYYY';
  //To-do- Remove this once demo done
  //const startDate = moment().startOf('week').format(defaultDateFormat);
  const startDate = '01/01/2023';
  const endDate = moment().format(defaultDateFormat);

  const {buyer} = useAppSelector((state) => state.buyerSlice);

  const {tag} = useAppSelector((state) => state.tagSlice);

  const defaultData = generateFilterTemplate(master, startDate, endDate, buyer, tag).reduce(
    (acc: any, obj) => {
      if (!acc[obj.property]) {
        acc[obj['property']] = null;
      }
      // Add value to list for given key's value
      acc[obj['property']] =
        location.state?.filter && location?.state?.filter[obj.property]
          ? location?.state?.filter[obj.property]
          : obj.defaultValue || '';
      return acc;
    },
    {}
  );
  const [filtervalues, setFilterValues] = useState(defaultData);

  const generatePayload = (values: IFilterValues) => {
    const temp = [];

    for (const [key, value] of Object.entries(values)) {
      if (value && value != '' && value?.toString()?.substring(0, 4)?.toLowerCase() != 'all ') {
        if (key === 'date') {
          temp.push({key: key, value: value, condition: 'BETWEEN'});
        } else {
          temp.push({key: key, value: value, condition: 'EQUAL'});
        }
      }
    }

    return temp;
  };
  /**For Reload the vendorList */
  const dispatchData = () => {
    dispatch({
      type: vendorListSagaActions.FETCH_VENDORLIST_DATA,
      payload: generatePayload(filtervalues)
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);
  //For Binding the Master Data

  const {vendorList, loading} = useAppSelector((state) => state.vendorListSlice);

  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: tagSagaActions.FETCH_TAG_DATA
    });

    dispatch({
      type: buyerSagaActions.FETCH_BUYER_DATA
    });
    // eslint-disable-next-line
  }, []);

  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };
  //Here we are returning Enhanced table and GridFilter with IF-ELSE condition
  if (vendorList && vendorList?.length > 0) {
    return (
      <Box sx={{mt: 7, pb: 2}}>
        <GridFilter
          id='vendor-overview'
          values={filtervalues}
          filterTemplate={generateFilterTemplate(master, startDate, endDate, buyer, tag)}
          onFilterChange={onFilterApply}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='vendor-list-load' />
          </Box>
        )}
        <EnhancedTable<IVendorGridProps>
          rowData={vendorList}
          stickyRows={<StickyHeaderCell data={vendorList} />}
          columnCell={columnCell}
          rowKey={'vendorId'}
          defaultSortKey={'vendorId'}
          allowAlternateColor={true}
          showTags={true}
          rowsPerPage={25}
          id='vendorListOverview'
        />
      </Box>
    );
  } else {
    return (
      <Box sx={{mt: 7, pb: 2}}>
        <GridFilter
          values={filtervalues}
          filterTemplate={generateFilterTemplate(master, startDate, endDate, buyer, tag)}
          onFilterChange={onFilterApply}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='vendor-overview'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='vendor-list-load' />
          ) : (
            <CustomError
              id='vendor-list-error'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      </Box>
    );
  }
};

export default VendorList;
