import * as React from 'react';
import {styled, Box, Grid} from '@mui/material';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import EnhancedTable from 'components/customTable/enhancedTable';
import {IColumnCell} from 'components/customTable/types';
import {CustomCard} from 'components/baseComponents/customCard';
import {useTheme} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {formatString, generatePayload} from 'util/arrayOperations';
import {topTwentyVendorsActions} from 'redux/dashboard/topTwentyVendors/topTwentyVendorsSaga';
import {Link} from 'react-router-dom';
import {TILE_WIDTH} from 'config/constants';

const dateDropDownData = [
  {
    label: 'Yesterday',
    value: 'Yesterday'
  },
  {
    label: 'Week to Date',
    value: 'weekToDate'
  },
  {
    label: 'Last Week',
    value: 'lastWeek'
  },
  {
    label: 'Last 4 Weeks',
    value: 'last4Weeks'
  },
  {
    label: 'Month-to-Date',
    value: 'monthToDate'
  },
  {
    label: 'Quarter-to-Date',
    value: 'quarterToDate'
  },
  {
    label: 'Year-to-Date',
    value: 'yearToDate'
  }
];

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root .MuiBox-root:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 0
  },
  '& .MuiTableContainer-root': {
    overflowX: 'hidden'
  },
  '& .MuiTable-root': {
    marginRight: '16px'
  }
});

interface ITopTwentyVendorsGridProps {
  vendor: string;
  sales: string;
  comp: string;
  mgn: string;
  ooOhMgn: string;
  compMgn: string;
  id: string;
}

interface IPropType {
  filteredData: any;
  defaultValue: string;
}
/**
 *
 * We are creating this to Navigate on Vendor Page.
 *
 */
const LinkComponent = (row: any) => {
  return (
    <>
      <Link to={{pathname: `/Vendor/${row?.venId}`}} state={{venId: row?.venId}}>
        {row?.vendor}
      </Link>
    </>
  );
};

const TopTwentyVendors: React.FC<IPropType> = (props) => {
  const [topTwentyVendorsDate, settopTwentyVendorsDate] = React.useState('weekToDate');
  const [vendorType, setVendorType] = React.useState(props?.defaultValue);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  /**
   * dispatch top-twenty-vendor tile data
   */
  const dispatchdata = () => {
    dispatch({
      type: topTwentyVendorsActions.FETCH_TOPTWENTYVENDORS_SAGA,
      payload: {
        type: vendorType,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchdata();
  }, [topTwentyVendorsDate, props?.filteredData, vendorType]);

  /**
   *
   * @param event dropdown onChange event
   */
  const handleSelect = (event: any): void => {
    settopTwentyVendorsDate(event?.target.value);
  };

  const handleTypeSelect = (event: any): void => {
    setVendorType(formatString(event?.target.value));
  };

  const {topTwentyVendors, loading, error} = useAppSelector((state) => state.topTwentyVendorsSlice);

  const columnCell: IColumnCell<ITopTwentyVendorsGridProps>[] = [
    {
      id: 'vendor',
      name: 'vendor',
      displayName: 'VENDOR',
      textColor: theme.palette.primary.main,
      isGroupBorderRequire: true,
      customCell: LinkComponent,
      sx: {height: '32px'}
    },
    {
      id: 'sales',
      name: 'sales',
      displayName: 'SALES',
      isNumeric: true,
      prefixText: '$',
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    },
    {
      id: 'comp',
      name: 'comp',
      displayName: 'COMP',
      isNumeric: true,
      prefixText: '$',
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    },
    {
      id: 'mgn',
      name: 'mgn',
      displayName: 'MGN',
      isNumeric: true,
      suffixText: '%',
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    },
    {
      id: 'ooOhMgn',
      name: 'ooOhMgn',
      displayName: 'OH+OO MGN',
      isNumeric: true,
      suffixText: '%',
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    },
    {
      id: 'compMgn',
      name: 'compMgn',
      displayName: 'COMP MGN',
      isNumeric: true,
      suffixText: '%',
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    }
  ];

  const vendorOptions = [
    {
      label: 'Top 20 Vendors',
      value: 'Top 20 Vendors'
    },
    {
      label: 'Top 20 High Growth Vendors',
      value: 'Top 20 High Growth Vendors'
    }
  ];

  const Header = () => {
    return (
      <>
        <Grid container>
          <DropDownWrapper>
            <CustomSelect
              id='topTwentyVendorsTile'
              value={vendorType}
              onChange={handleTypeSelect}
              options={vendorOptions}
            />

            <CustomSelect
              id='topTwentyVendorsTile'
              name={'topTwentyVendors_Date'}
              value={topTwentyVendorsDate}
              options={dateDropDownData}
              onChange={handleSelect}
              title={'Date'}
            />
          </DropDownWrapper>
        </Grid>
      </>
    );
  };

  const Content = () => {
    return (
      <>
        <EnhancedTable<ITopTwentyVendorsGridProps>
          width={'100%'}
          rowData={topTwentyVendors}
          columnCell={columnCell}
          rowKey={'sales'}
          defaultSortKey={'sales'}
          allowAlternateColor={false}
          componentType='box'
          dense={true}
          hidePagination
          id='dashboard-topTwentyVendors'
        />
      </>
    );
  };

  if (topTwentyVendors && topTwentyVendors.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='topTwentyVendor'
          width={TILE_WIDTH.two}
          header={<Header />}
          headerSx={{marginBottom: '8px'}}
          content={loading ? <CustomLoader id='top-20-vendor-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='topTwentyVendor-ID'
          width={TILE_WIDTH.two}
          header={<Header />}
          headerSx={{marginBottom: '8px'}}
          content={
            loading ? (
              <CustomLoader id='top-20-vendor-load' />
            ) : (
              <CustomError
                id='top-20-vendor-error'
                onReload={() => {
                  dispatchdata();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default TopTwentyVendors;
