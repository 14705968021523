import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {styled, TextField} from '@mui/material';

export const TimePickerInput = styled(TextField)(({theme}) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${theme.palette.grayscale?.[200]} !important`
    },
    '&:hover fieldset': {
      borderColor: '#0ab093'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    },
    height: '32px',
    width: '300px',
    backgroundColor: 'common.white'
  }
}));
const CustomTimePicker = styled(TimePicker)(({theme}) => ({
  'label + &': {
    marginTop: 0
  }
}));
export default CustomTimePicker;
