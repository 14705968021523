import React, {useEffect} from 'react';
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  Typography,
  useTheme,
  IconButton
} from '@mui/material';
import CustomButton from 'components/baseComponents/customButton';
import {decimalConverter} from 'util/arrayOperations';
import CloseIcon from '@mui/icons-material/Close';
import {DebounceInput} from 'react-debounce-input';
import {productListSagaActions} from 'redux/product/list/productList/productListSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {CustomLoader} from 'components/baseComponents/customMessage';

interface IPropType {
  searchItems: any;
  open: boolean;
  text: string;
  handleClose: () => void;
  addNewItem: (item: any) => void;
  loading: boolean;
}

const POSearchItems: React.FC<IPropType> = (props) => {
  const theme = useTheme();
  //const { searchItems } = props;
  const [searchText, setSearchText] = React.useState(props.text);

  const dispatch = useAppDispatch();
  const {searchProductList, productList} = useAppSelector((state) => state.productListSlice);

  const handleAddItem = (item: any) => {
    let newItem = item;
    if (newItem.itemId) {
      const newPOItem = productList.find((ele: any) => ele.itemId && ele.itemId === newItem.itemId);

      if (newPOItem?.itemId) {
        newItem = {
          ...newPOItem,
          itemDescription: newPOItem.itemName
        };
      }
      props.addNewItem(newItem);
    }
  };

  const generateSearchPayload = (val: string) => {
    dispatch({
      type: productListSagaActions.FETCH_PRODUCTSEARCH_DATA,
      payload: {
        itemName: val,
        filter: []
      }
    });
  };

  const handleSearchChange = (value: any) => {
    setSearchText(value);
    if (searchText != '' && searchText.length >= 3) {
      generateSearchPayload(searchText);
    }
  };

  useEffect(() => {
    if (searchText != '' && searchText.length >= 2) {
      generateSearchPayload(searchText);
    }
  }, [searchText, props.text]);

  return (
    <Box>
      {/* <Modal open={props.open} onClose={props.handleClose}> */}
      <Box display='flex' alignItems='center' sx={{padding: 1}}>
        <Box flexGrow={1} sx={{padding: 1}}>
          Search
        </Box>
        <Box flexGrow={1} sx={{padding: 1}}>
          <DebounceInput
            minLength={2}
            value={searchText}
            placeholder='Search by item ID, UPC or description to add items'
            debounceTimeout={500}
            style={{
              '& input:hover, input:visited, input:focus': {
                outline: '1px solid grayscale.200'
              },
              backgroundColor: 'common.white',
              width: '464px',
              padding: '8px 0 8px 16px',
              border: '1px solid grayscale.200',
              outline: '1px solid grayscale.200'
            }}
            onChange={(e: any) => handleSearchChange(e.target.value)}
          />
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              props.handleClose();
            }}
            id={`poEdit-close`}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {props.loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {' '}
          <CustomLoader id='product-list-load' />
        </Box>
      )}
      <TableContainer
        component={Paper}
        sx={{maxHeight: '500px', width: '100%', overflowX: 'hidden', overflowY: 'scroll'}}
      >
        <Table id={'topTenSalesItemstableId'}>
          <TableBody>
            {searchProductList.length > 0 &&
              searchProductList.map((ele: any, index: number) => {
                return (
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      alignItems: 'center',
                      mx: 1,
                      borderBottom: '1px solid rgba(224, 224, 224, 1)'
                    }}
                    key={`popup-${ele.itemId + index}`}
                  >
                    <TableCell sx={{padding: 0.95, border: 'none'}}>
                      {ele?.image ? (
                        <img
                          style={{
                            width: '49px',
                            height: '49px',
                            border: `1px solid ${theme.palette.primary.main}`
                          }}
                          src={ele.image}
                        />
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell sx={{border: 'none', width: '450px'}}>
                      <Typography
                        variant='caption'
                        sx={{
                          color: 'grayscale.600',
                          fontSize: '12px',
                          fontFamily: 'Roboto Medium'
                        }}
                      >
                        #{ele.itemId}&nbsp;&#x2022;&nbsp;
                        {ele.upc}&nbsp;&#x2022;&nbsp;
                        {ele.pack}/{decimalConverter(ele.size)}
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          color: 'common.black',
                          width: '450px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          boxSizing: 'border-box',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {ele.itemDescription}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{border: 'none', width: 50}}>
                      <CustomButton
                        onClick={() => {
                          handleAddItem(ele);
                        }}
                        id='searchTile-vendor-showAll'
                      >
                        ADD
                      </CustomButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* </Modal> */}
    </Box>
  );
};

export default POSearchItems;
