import {takeEvery, put, call} from 'redux-saga/effects';
import {ReportAPIS} from '../../report_service';
import {
  categorySupplierLoading,
  categorySupplierSuccess,
  categorySupplierFailure
} from './categorySupplierSlice';

export const categorySupplierSagaActions = {
  FETCH_CATEGORYSUPPLIER_SAGA: 'FETCH_CATEGORYSUPPLIER_SAGA'
};

export function* fetchCategorySupplierData(action: any) {
  try {
    yield put(categorySupplierLoading());
    const {data} = yield call(ReportAPIS.getCategorySupplierData, action.payload);
    yield put(categorySupplierSuccess({data, type: action.payload.type}));
  } catch (error) {
    yield put(categorySupplierFailure(action.payload));
  }
}

export default function* watchFetchCategorySupplierData() {
  yield takeEvery(
    categorySupplierSagaActions.FETCH_CATEGORYSUPPLIER_SAGA,
    fetchCategorySupplierData
  );
}
