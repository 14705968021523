import {createSlice} from '@reduxjs/toolkit';
export interface supplierContactState {
  loading: boolean;
  supplierContact: any;
  error: string;
}

const initialState: supplierContactState = {
  loading: false,
  supplierContact: {},
  error: ''
};

const supplierContactMasterSlice = createSlice({
  name: 'supplierContact',
  initialState,
  reducers: {
    supplierContactLoading: (state) => {
      state.loading = true;
    },
    supplierContactSuccess: (state, action) => {
      state.supplierContact = action.payload;
      state.loading = false;
    },
    supplierContactFailure: (state) => {
      state.loading = false;
      state.supplierContact = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default supplierContactMasterSlice.reducer;
export const {supplierContactLoading, supplierContactSuccess, supplierContactFailure} =
  supplierContactMasterSlice.actions;
