import React from 'react';
import Plot from 'react-plotly.js';
import {getColumnValues} from 'util/arrayOperations';
import {SetColor} from 'util/plotlyUtil';
import {useTheme} from '@mui/material/styles';
import Plotly from 'plotly.js';
import {urlSeperator} from '../config/constants';

/**
 * This Graph is veritically aligned plotly graph,
 * Y axis should contain string array.
 * X axis should contain only number type
 */
interface IPropType {
  //Div id for which the plotly needs to update
  id: string;
  // Array of objects
  data: Plotly.Data[];
  // object for plotly
  layout?: Partial<Plotly.Layout>;
  // xAxis column names
  xAxis: Array<IAxis>;
  // yAxis column name
  yAxis: IAxis;
  // zAxis: IAxis;
  // include target
  showPlan?: boolean;
  // target column name
  plan?: IAxis;
  // trace properties
  traceProps?: Partial<Plotly.Data>;
  //allows label redirection
  redirectUrl?: string;
  //filterData
  queryData?: any;
  //externalurl
  externalUrl?: boolean;

  // vary the bargap
  isFixedBarSize?: boolean;
}

export interface IAxis {
  name: string;
  displayTitle?: string;
  color?: string;
  unit?: string;
  prefix?: string;
}
// export function getyAxisBoldValues(arr: Array<any>, columnName: string) {
//   return arr.map((elem: any) => elem[columnName] ? `<b>${elem[columnName]}</b>` : null)
// }

/**
 * Function to return updated bargap based on no of records to display
 * @param layout Layout object
 * @param count No of records to display
 */
const getUpdatedLayoutBarGap = (layout: Plotly.Layout, count: number, isFixedBarSize?: boolean) => {
  if (isFixedBarSize && count === 5) {
    layout.bargap = 0.3;
    layout.bargroupgap = 0.1;
    layout.height = count * 15 + 100;
  } else if (isFixedBarSize && count > 5) {
    layout.bargap = 0.3;
    layout.bargroupgap = 0.1;
    layout.height = count * 15 + 180;
  } else {
    if (count <= 4) {
      layout.bargap = 0.3;
      layout.bargroupgap = 0.1;
    } else if (count > 4 && count <= 8) {
      layout.bargap = 0.3;
      layout.bargroupgap = 0.1;
      layout.height = count * 50 + 100;
    } else if (count > 8 && count <= 15) {
      layout.bargap = 0.3;
      layout.bargroupgap = 0.2;
      layout.height = count * 60 + 100;
    } else {
      layout.bargap = 0.3;
      layout.bargroupgap = 0.1;
      layout.height = count * 60 + 100;
    }
  }
  return layout;
};

/**
 * Function to return zoom values based on data min/max
 * @param zoomMin Min Value
 * @param zoomMax Max Value
 */
const getZoomValues = (zoomMin: number, zoomMax: number) => {
  const zmin = zoomMin; //getRange(zoomMin, 'min');
  const zmax = getRangeMax(zoomMax);

  const zoomRange = [zmin, zmax];
  return zoomRange;
};

const getRangeMax = (value: number) => {
  if (value <= 150) return value + 20;
  else if (value <= 500) return value + value * 0.3;
  else if (value <= 2000) return value + value * 0.4;
  else return value;
};

// export function getyAxisBoldValues(arr: Array<any>, columnName: string) {
//   return arr.map((elem: any) => elem[columnName] ? `<b>${elem[columnName]}</b>` : null)
// }

/**
 * Function to return all values of key in array
 * @param arr Array of any type
 * @param columnName key in array
 * @returns Collection ofvalue for the specified key
 */
function getColumnValuesBarGraph<T>(arr: Array<any>, columnName: string): Array<T> {
  return arr.map((elem: any) => (elem[columnName] ? elem[columnName] : null));
}

export const BarGraphComponent: React.FC<IPropType> = (props) => {
  const theme = useTheme();
  const generateEncodedURL = (landingPage = '', obj: {}, queryArgs: any = {}) => {
    const updatedQueryArgs = {...obj, ...queryArgs};
    const displayName: string = obj[props.yAxis.name as keyof {}] || obj['label' as keyof {}] || '';
    let args = '';
    if (Object.entries(updatedQueryArgs).length > 0) {
      args = updatedQueryArgs && JSON.stringify(updatedQueryArgs);
    }
    const encodedQuery = encodeURI(
      `?redirect=${landingPage}${args.length > 0 ? urlSeperator + 'filter=' + args : ''}`
    ).replace(/ /gi, '%23');
    if (props.externalUrl) {
      const url = `<a target="_blank" style="max-width: 5px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis" 
    href="${props.redirectUrl}" rel='noreferrer'>${
        displayName.length > 10 ? displayName.substring(6, length) + '...' : displayName
      }</a>`;
      return url;
    } else {
      const url = `<a target="_self" style="max-width: 5px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis" 
    href="${encodedQuery}">${
        displayName.length > 10 ? displayName.substring(6, length) + '...' : displayName
      }</a>`;
      return url;
    }
  };

  const layout = {
    width: 320,
    autosize: false,
    font: {
      size: 12
    },
    bargap: 0.4,
    bargroupgap: 0.1,
    hovermode: 'closest',
    xaxis: {
      showgrid: false,
      automargin: true,
      fixedrange: true,
      showticklabels: false,
      zerolinecolor: theme.palette.neutral?.secondary10,
      zerolinewidth: 0,
      family: 'Roboto Regular',
      size: 12,
      letterSpacing: 0.4
    } as unknown as Plotly.LayoutAxis,

    yaxis: {
      showgrid: false,
      automargin: true,
      fixedrange: true,
      tickfont: {
        color: theme.palette.primary.main,
        family: 'Roboto Medium',
        size: 12,
        letterSpacing: 0.4
      }
    },
    margin: {
      l: 5,
      r: 6,
      b: 5,
      t: 0,
      pad: 8
    },
    legend: {
      orientation: 'h',
      x: -0.4,
      y: 1.1,
      font: {
        family: 'Roboto Regular',
        size: 12,
        letterSpacing: 0.4
      }
    },
    annotations: []
  } as unknown as Plotly.Layout;
  const plotlyLayout = {...layout, ...props.layout};
  let traces: Array<Plotly.Data> = [];
  let zoomMin = 0,
    zoomMax = 10;
  let zoomRange: number[] = [zoomMin, zoomMax];
  try {
    let graphData = props.data;
    if (props.redirectUrl) {
      graphData = props.data?.map((e: any) => {
        const obj = {
          ...e
        };
        obj[props.yAxis.name] = generateEncodedURL(props.redirectUrl, e, props.queryData);
        return obj;
      });
    }
    const yAxisData = getColumnValuesBarGraph<string>(graphData, props.yAxis.name);
    traces = props.xAxis.map((element: IAxis) => {
      const xAxisData = getColumnValues<number>(props.data, element.name);
      const min = Math.min(...xAxisData),
        max = Math.max(...xAxisData);

      if (zoomMin > min) {
        zoomMin = min;
      }
      if (zoomMax <= max) {
        zoomMax = max;
      }

      return {
        x: xAxisData,
        y: yAxisData,
        text: xAxisData as unknown as string[],
        textposition: 'outside',
        type: 'bar',
        name: element.displayTitle || element.name,
        orientation: 'h',
        texttemplate: `${element?.prefix || ''}` + '%{text}' + `${element?.unit || ''}`,
        marker: {
          color: SetColor(props.data, element.name, element.color || theme.palette.primary.dark)
        },
        ...props.traceProps
      } as Plotly.Data;
    });

    if (props.showPlan) {
      const xAxisPlanData = getColumnValues<number>(props.data, props?.plan?.name || 'plan');

      traces.push({
        x: xAxisPlanData,
        y: yAxisData,
        text: xAxisPlanData as unknown as string[],
        marker: {
          symbol: '142',
          size: 35,
          color: theme.palette.neutral?.secondary1
        },
        orientation: 'h',
        mode: 'text+markers',
        textposition: 'top center',
        texttemplate: `${props?.plan?.prefix || ''}` + '%{text}' + `${props?.plan?.unit || ''}`,
        name: 'Plan'
      });
    }
    zoomRange = getZoomValues(zoomMin, zoomMax);
  } catch (err) {
    return err;
  }

  return (
    <Plot
      divId={props.id || 'chartmonitoring'}
      data={[...traces]}
      layout={{
        ...getUpdatedLayoutBarGap({...plotlyLayout}, props?.data.length, props?.isFixedBarSize),
        xaxis: {...plotlyLayout.xaxis, range: zoomRange}
      }}
      config={{staticPlot: false, displayModeBar: false}}
    />
  );
};
export default BarGraphComponent;
