import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectProps} from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {ReactComponent as Arrow} from 'assets/images/icons/Icons & UI-Dropdown-Dropdown-Caret_Passive.svg';
import {useTheme} from '@mui/material';

export interface ISelectOptions {
  label: string;
  value: string;
  dependsOn?: string;
}

interface ISelectProps extends SelectProps {
  id: string;
  options: ISelectOptions[];
  placeholder?: string;
  disabled?: boolean;
  allowAllOptions?: boolean;
}

const CustomSelect = (props: ISelectProps) => {
  const theme = useTheme();
  const defaultCheck = `All ${props?.placeholder ? props.placeholder : props?.name}`;
  return (
    <FormControl
      sx={{
        alignSelf: 'center',
        '& .MuiSelect-icon.MuiSelect-iconStandard': {
          right: '4px',
          top: '10px'
        },
        '& .MuiSelect-standard': {
          '&:focus': {
            backgroundColor: 'unset'
          }
        }
      }}
      variant='standard'
      disabled={props?.disabled}
    >
      {props?.title && (
        <Typography
          sx={{
            p: 0,
            my: 0.8,
            fontWeight: 'bold',
            color: theme.palette.grayscale?.[600],
            fontSize: '12px',
            opacity: props?.disabled ? 0.5 : 1
          }}
          textAlign='left'
          id={`label-text-${props?.id}`}
        >
          {props?.title}
        </Typography>
      )}
      <Select
        sx={{
          '& .MuiSelect-select': {
            h6: {paddingRight: '4px', textAlign: 'left'}
          },
          width: 'auto',
          marginLeft: '0',
          textAlign: 'left',
          ...props?.sx
        }}
        name={props?.name ? props.name : props?.title}
        {...props}
        IconComponent={Arrow}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        disableUnderline
        displayEmpty
        renderValue={(selected: any) => {
          let selectedValue;
          props?.options.forEach((opt) => {
            if (opt.value === selected) {
              if (typeof opt.label === 'object') {
                selectedValue = opt.label[0];
              } else {
                selectedValue = opt.label;
              }
            }
          });

          if (!selected) {
            return <label style={{opacity: 0.5}}>{defaultCheck}</label>;
          } else if (selected === defaultCheck) {
            return <label style={{opacity: 0.5}}>{defaultCheck}</label>;
          }
          return selectedValue;
        }}
      >
        {props?.allowAllOptions && (
          <MenuItem
            id={`id-customSelect${defaultCheck}menu`}
            key={`customSelect${defaultCheck}menu`}
            value=''
          >
            <Typography variant='subtitle2' sx={{fontSize: '14px'}} textAlign='left'>
              {defaultCheck}
            </Typography>
          </MenuItem>
        )}
        {props?.options.map((ele, i) => {
          return (
            <MenuItem
              id={`customSelect-${ele.label}-menu-${i}`}
              key={`customSelect-${ele.label}-menu${i}`}
              value={ele.value}
            >
              <Typography variant='subtitle2' sx={{fontSize: '14px'}} textAlign='left'>
                {ele.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
