import {createSlice} from '@reduxjs/toolkit';
export interface shrinkState {
  loading: boolean;
  shrink: Array<any>;
  error: string;
}

const initialState: shrinkState = {
  loading: false,
  shrink: [],
  error: ''
};

const shrinkSlice = createSlice({
  name: 'shrink',
  initialState,
  reducers: {
    shrinkLoading: (state) => {
      state.loading = true;
    },
    shrinkSuccess: (state, action) => {
      state.shrink = action.payload;
      state.loading = false;
    },
    shrinkFailure: (state) => {
      state.loading = false;
      state.shrink = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default shrinkSlice.reducer;
export const {shrinkLoading, shrinkSuccess, shrinkFailure} = shrinkSlice.actions;
