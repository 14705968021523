import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {vendorLoading, vendorSuccess, vendorFailure} from 'redux/dashboard/master/vendorSlice';

export const vendorSagaActions = {
  FETCH_VENDOR_DATA: 'FETCH_VENDOR_DATA'
};

export function* fetchVendorData(): any {
  try {
    yield put(vendorLoading());
    const {data} = yield call(ProductAPIS.getVendorData);
    yield put(vendorSuccess(data));
  } catch (error) {
    yield put(vendorFailure());
  }
}

export default function* watchFetchVendorData() {
  yield takeEvery(vendorSagaActions.FETCH_VENDOR_DATA, fetchVendorData);
}
