import {Breadcrumbs, Typography, Link} from '@mui/material';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

export const BreadcrumbNav = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    navigate('/Vendor', {state: location.state});
  };

  const breadcrumbs = [
    <Link underline='hover' key='1' href='/' onClick={handleClick}>
      <Typography
        variant='body1'
        sx={{color: 'primary.main', letterSpacing: '0.15px', fontWeight: 600}}
        textAlign='left'
      >
        Vendors
      </Typography>
    </Link>,
    <Typography
      key='4'
      variant='body1'
      sx={{color: 'common.black', letterSpacing: '0.15px'}}
      textAlign='left'
    >
      {location && location.state?.data?.myRow?.vendorName}
    </Typography>
  ];

  return (
    <Breadcrumbs
      separator='>'
      sx={{
        fontSize: '16px',
        color: 'bpGreyFont.main'
      }}
      aria-label='breadcrumb'
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};
