import * as React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import StyledChip from 'components/baseComponents/customChip';
import Grid from '@mui/material/Grid';
import ProductDetailGrid from 'features/product/details/productDetailGrid';
import {productDetailsSagaActions} from 'redux/product/details/productDetails/productDetailsSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {Popover} from '@mui/material';
import {vendorDetailsSagaActions} from 'redux/vendor/details/vendorDetailsSaga';
import {useTheme} from '@mui/material';
import {display} from '@mui/system';

export function BreadcrumbNav() {
  const location: any = useLocation();
  const filter = {
    filter: {
      className: location?.state?.className || '',
      subClassName: location.state?.subClassName || ''
    }
  };
  const breadcrumbs = [
    <Link key='1' to='/Product'>
      <Typography variant='body1' sx={{color: 'primary.main'}} textAlign='left'>
        Products
      </Typography>
    </Link>,
    <Link key='2' to='/Product' state={{filter: {className: location.state.className}}}>
      <Typography variant='body1' sx={{color: 'primary.main'}} textAlign='left'>
        {location.state.className}
      </Typography>
    </Link>,
    <Link key='3' to={{pathname: `/Product`}} state={filter}>
      <Typography variant='body1' sx={{color: 'primary.main'}} textAlign='left'>
        {location.state.subClassName}
      </Typography>
    </Link>,
    <Typography key='4' variant='body1' sx={{color: 'common.black'}} textAlign='left'>
      {`${location.state.itemId} ${
        location.state.itemName ? location.state.itemName : location.state.itemDescription
      }`}
    </Typography>
  ];

  return (
    <Breadcrumbs
      separator='>'
      sx={{
        fontSize: '12px',
        color: 'bpGreyFont.main'
      }}
      aria-label='breadcrumb'
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
}

interface TagProps {
  labels: string[];
}

export const AddLabel: React.FC<TagProps> = (props) => {
  return (
    <Stack sx={{flexGrow: 1, mt: 1}} direction='row' spacing={1}>
      {props.labels.map((name) => {
        return (
          <StyledChip
            key={`${name}chip`}
            label={
              <Typography
                variant='caption'
                sx={{
                  fontSize: '10px',
                  color: 'primary.main',
                  fontWeight: 'bold'
                }}
                textAlign='center'
              >
                {name}
              </Typography>
            }
          />
        );
      })}
    </Stack>
  );
};

interface IProductDescProps {
  productId: string;
}
export const ProductDesc: React.FC<IProductDescProps> = (props) => {
  const theme: any = useTheme();
  const dispatch = useAppDispatch();
  const {productDetails} = useAppSelector((state: any) => state.productDetailsSlice);

  React.useEffect(() => {
    if (props.productId) {
      dispatch({
        type: productDetailsSagaActions.FETCH_PRODUCTDETAILS_DATA,
        payload: props.productId
      });
    }
  }, []);
  return (
    <Box sx={{flexGrow: 1, display: 'flex'}}>
      {productDetails?.image ? (
        <Box
          component='img'
          sx={{
            bgcolor: 'common.white',
            border: `1px solid ${theme.palette?.grayscale?.[300]}`,
            height: 176,
            width: 176
          }}
          alt='Image Not Available'
          src={productDetails?.image}
        />
      ) : (
        <></>
      )}
      <Box ml={2} sx={{width: '552px'}}>
        <Box sx={{display: 'flex', gap: '1%'}}>
          <Typography variant='h6' sx={{color: 'grayscale.600', lineHeight: 1.2}} textAlign='left'>
            {`#${productDetails?.itemId} • UPC ${productDetails?.upc}`}
          </Typography>
          <Avatar
            sx={{
              bgcolor: 'primary.dark',
              color: 'common.white',
              width: 24,
              height: 24
            }}
          >
            <Typography variant='caption' textAlign='left'>
              {productDetails?.noOfAlternateUpc
                ? `+${[productDetails?.noOfAlternateUpc || null].length}`
                : 0}
            </Typography>
          </Avatar>
        </Box>
        <Typography
          variant='h4'
          sx={{color: 'common.black', lineHeight: 1.25, py: 1.5}}
          textAlign='left'
        >
          {productDetails.itemName}
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{color: 'grayscale.600', lineHeight: 1.15}}
          textAlign='left'
        >
          {productDetails?.pack === null || productDetails?.size === null
            ? 0
            : `${Number(productDetails?.pack)?.toFixed(1)}/${Number(productDetails?.size)?.toFixed(
                1
              )}${
                productDetails &&
                productDetails.netWeight != null &&
                productDetails.netWeight != 'null'
                  ? `, ${productDetails.netWeight}`
                  : ''
              }`}
        </Typography>
        {/* <AddLabel labels={productDetails?.tags || []} /> */}
      </Box>
    </Box>
  );
};

interface IProductStats {
  productId: string;
}

export const ProductStats = ({productId}: IProductStats) => {
  //const [lastCostLabel, setLastCostLabel] = React.useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [anchorLastCost, setAnchorLastCost] = React.useState<HTMLElement | null>(null);
  const theme: any = useTheme();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverOpenLastCost = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLastCost(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handlePopoverLastCostClose = () => {
    setAnchorLastCost(null);
  };
  const open = Boolean(anchorEl);
  const openLastcost = Boolean(anchorLastCost);
  React.useEffect(() => {
    if (productId) {
      dispatch({
        type: productDetailsSagaActions.FETCH_PRODUCTDETAILS_DATA,
        payload: productId
      });
    }
  }, []);
  const {productDetails} = useAppSelector((state: any) => state.productDetailsSlice);
  const productLastcost =
    productDetails?.lastCosts && productDetails?.lastCosts.length > 0
      ? productDetails.lastCosts[0]
      : null;
  const productLC = productLastcost?.lastCostDollar ? `$${productLastcost.lastCostDollar}` : '';
  const productLastDate =
    productLastcost?.lastCostDate && productLastcost?.lastCostDate != null
      ? productLastcost.lastCostDate
      : '';
  const lastWH =
    productLastcost?.warehouseId &&
    productLastcost.warehouseId != null &&
    productLastcost.warehouseId != 'null'
      ? `${productLastcost.warehouseId} `
      : '';
  const groupLastWHDate =
    lastWH && productLastDate
      ? `(${lastWH}, ${productLastDate})`
      : lastWH == ''
      ? `(${productLastDate})`
      : `(${lastWH}, ${productLastDate})`;
  const lastCostLabel = `${productLC} ${groupLastWHDate}`;
  React.useEffect(() => {
    if (productDetails?.vendorId && productDetails.vendorId != null) {
      dispatch({
        type: vendorDetailsSagaActions.FETCH_VENDORDETAILS_DATA,
        payload: productDetails.vendorId
      });
    }
  }, [productDetails.vendorId]);
  const {vendorDetails} = useAppSelector((state) => state.vendorDetailsSlice);
  function popoverHandleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const venId = productDetails?.vendorId;
    event.preventDefault();
    navigate(`/Vendor/${venId}`, {
      state: {vendorId: venId, data: {myRow: {vendorName: productDetails?.vendorName}}}
    });
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <Grid container sx={{maxWidth: '520px'}}>
        <Grid item sm={3} md={3}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600]
            }}
            textAlign='left'
          >
            VENDOR:
          </Typography>
        </Grid>
        <Grid item sm={5} md={5}>
          <Box
            id={'productStatPopoverBox'}
            aria-owns={open ? 'mouse-vendor-over-popup' : undefined}
            aria-haspopup='true'
            onMouseEnter={(e) => handlePopoverOpen(e)}
            onMouseLeave={handlePopoverClose}
          >
            <Link onClick={popoverHandleClick} to={''}>
              <Typography
                variant='body1'
                sx={{
                  fontFamily: 'Roboto Medium',
                  color: 'primary.main',
                  lineHeight: 1.75
                }}
                textAlign='left'
              >
                {productDetails?.vendorName}
              </Typography>
            </Link>
          </Box>
          <Popover
            id='mouse-vendor-over-popup'
            elevation={1}
            sx={{
              boxShadow: 'unset',
              pointerEvents: 'none'
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus={true}
            style={{margin: '20px'}}
          >
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 100,
                fontSize: 15,
                margin: 2,
                color: theme.palette?.grayscale?.[600]
              }}
              textAlign='left'
            >
              {productDetails?.vendorName}
            </Typography>
            <Stack direction='row' spacing={3}>
              <Stack
                direction='column'
                spacing={1}
                sx={{textTransform: 'uppercase', padding: 1, marginLeft: 2}}
              >
                <Typography
                  variant='subtitle1'
                  sx={{
                    fontFamily: 'Roboto Medium',
                    fontWeight: 100,
                    fontSize: 13,
                    color: theme.palette?.grayscale?.[600]
                  }}
                  textAlign='left'
                >
                  COMP%:
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{
                    fontFamily: 'Roboto Medium',
                    fontWeight: 100,
                    fontSize: 13,
                    color: theme.palette?.grayscale?.[600]
                  }}
                  textAlign='left'
                >
                  MARGIN:
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{
                    fontFamily: 'Roboto Medium',
                    fontWeight: 100,
                    fontSize: 13,
                    color: theme.palette?.grayscale?.[600]
                  }}
                  textAlign='left'
                >
                  TERMS:
                </Typography>
              </Stack>
              <Stack direction='column' spacing={1} sx={{width: '232px', padding: 1}}>
                <Typography
                  variant='body1'
                  display='inline'
                  textAlign='left'
                  sx={{
                    fontSize: 13,
                    paddingBottom: 0.5
                  }}
                >
                  {vendorDetails?.comp ? vendorDetails?.comp : 0}%
                </Typography>
                <Typography
                  variant='body1'
                  textAlign='left'
                  sx={{
                    fontSize: 13,
                    paddingBottom: 0.5
                  }}
                >
                  {vendorDetails?.margin ? vendorDetails?.margin : 0}%
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    fontSize: 13,
                    paddingBottom: 0.5
                  }}
                  textAlign='left'
                >
                  {vendorDetails?.terms ? vendorDetails?.terms : '-'}
                </Typography>
              </Stack>
            </Stack>
          </Popover>
        </Grid>
        <Grid item sm={2} md={2}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600]
            }}
            textAlign='left'
          >
            ISSUES:
          </Typography>
        </Grid>
        <Grid item sm={2} md={2}>
          {productDetails?.issues ? `${productDetails?.issues} issues` : `-`}
        </Grid>
        <Grid item sm={3} md={3}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600]
            }}
            textAlign='left'
          >
            AWS:
          </Typography>
        </Grid>
        <Grid item sm={4} md={5}>
          <Typography variant='body1' sx={{lineHeight: 1.75}} display='inline' textAlign='left'>
            {productDetails?.awsMax === null ? (
              0
            ) : (
              <Box sx={{display: 'inline-flex'}}>
                {`$${Number(productDetails?.awsMax).toFixed(2)} `}
                <Typography sx={{color: 'gray'}}>&nbsp;(Max)</Typography>
                &nbsp;•
              </Box>
            )}
            {productDetails?.awsAvg === null ? (
              ''
            ) : (
              <>
                {` $${Number(productDetails?.awsAvg).toFixed(2)} `}
                <Typography display='inline' sx={{color: 'gray'}}>
                  (Avg)
                </Typography>
              </>
            )}
          </Typography>
        </Grid>
        <Grid item sm={2} md={2}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600],
              margingLeft: '5px'
            }}
            textAlign='left'
          >
            AVG RTL:
          </Typography>
        </Grid>
        <Grid item sm={2} md={2}>
          {Number(productDetails?.avgRtl).toFixed(2)}
        </Grid>

        <Grid item sm={3} md={3}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600]
            }}
            textAlign='left'
          >
            LAST COST:
          </Typography>
        </Grid>
        <Grid item sm={5} md={5}>
          <Box
            id='lastCostproductDetail'
            aria-owns={openLastcost ? 'mouse-over-lastCost-popo2' : undefined}
            aria-haspopup='true'
            onMouseEnter={(e) => handlePopoverOpenLastCost(e)}
            onMouseLeave={handlePopoverLastCostClose}
          >
            <Typography
              variant='body1'
              sx={{
                fontFamily: 'Roboto Medium',
                color: 'primary.main',
                lineHeight: 1.75
              }}
              textAlign='left'
            >
              {lastCostLabel?.slice(1, 5) === 'null' ? (
                0
              ) : (
                <Box sx={{display: 'inline-flex'}}>
                  <Typography>{productLC}</Typography>&nbsp;
                  <Typography sx={{color: 'gray'}}>{`${groupLastWHDate}`}</Typography>
                </Box>
              )}
            </Typography>
          </Box>

          <Popover
            id='mouse-over-lastCost-popo2'
            elevation={1}
            sx={{
              boxShadow: 'unset',
              pointerEvents: 'none'
            }}
            open={openLastcost}
            anchorEl={anchorLastCost}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            onClose={handlePopoverLastCostClose}
            disableRestoreFocus={true}
          >
            {productDetails?.lastCosts?.map((lastcost: any, index: number) => {
              const productLastDate =
                lastcost?.lastCostDate && lastcost?.lastCostDate ? lastcost?.lastCostDate : '';
              const lastWH =
                lastcost?.warehouseId && lastcost?.warehouseId && lastcost?.warehouseId != 'null'
                  ? `${lastcost.warehouseId}, `
                  : '';
              const lastcostVendorName =
                lastcost?.lastCostVendorName && lastcost?.lastCostVendorName
                  ? `${lastcost.lastCostVendorName}`
                  : '';
              return (
                <Grid
                  direction='row'
                  key={`product-LastCost-${lastcost?.lastCostDollar}-${index}`}
                  spacing={3}
                  container
                >
                  <Grid
                    item
                    spacing={2}
                    sx={{width: '130px', textTransform: 'uppercase'}}
                    xs={3}
                    md={3}
                    paddingBottom={1}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{
                        fontFamily: 'Roboto Medium',
                        fontWeight: 100,
                        fontSize: 20,
                        padding: 1,
                        color: theme.palette?.grayscale?.[600]
                      }}
                      textAlign='center'
                    >
                      ${lastcost?.lastCostDollar}
                    </Typography>
                  </Grid>
                  <Grid item spacing={2} sx={{width: '200px'}} xs={8} md={8}>
                    <Grid direction='row' spacing={0.3}>
                      <Typography variant='body1' display='inline' textAlign='left'>
                        {lastWH} {productLastDate}
                      </Typography>
                    </Grid>
                    <Grid direction='row' spacing={0.3}>
                      <Typography variant='body1' textAlign='left'>
                        {lastcostVendorName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Popover>
        </Grid>
        <Grid item sm={2} md={2}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600]
            }}
            textAlign='left'
          >
            AVG COST:
          </Typography>
        </Grid>
        <Grid item sm={2} md={2} pl={1}>
          {Number(productDetails?.avgCost).toFixed(2)}
        </Grid>

        <Grid item sm={3} md={3}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600]
            }}
            textAlign='left'
          >
            TOTAL OH:
          </Typography>
        </Grid>
        <Grid item sm={5} md={5}>
          <Typography variant='body1' sx={{lineHeight: 1.75}} textAlign='left'>
            {productDetails?.totalOnHandWarehouse === null ? (
              0
            ) : (
              <Box sx={{display: 'inline-flex'}}>
                {`${productDetails?.totalOnHandWarehouse}`}
                <Typography sx={{color: 'gray'}}>(WH) •</Typography>
                {`${productDetails?.totalOnHandStore}`}
                <Typography sx={{color: 'gray'}}>(S)</Typography>
              </Box>
            )}
          </Typography>
        </Grid>
        <Grid item sm={2} md={2}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600]
            }}
            textAlign='left'
          >
            AVG MGN:
          </Typography>
        </Grid>
        <Grid item sm={2} md={2} pl={1}>
          {Number(productDetails?.avgMgn).toFixed(2)}
        </Grid>

        <Grid item sm={3} md={3}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600]
            }}
            textAlign='left'
          >
            TOTAL OO:
          </Typography>
        </Grid>
        <Grid item sm={5} md={5}>
          <Typography variant='body1' sx={{lineHeight: 1.75}} textAlign='left'>
            {productDetails?.totalOnOrderWarehouse === null ||
            productDetails?.totalOnOrderStore === null ? (
              0
            ) : (
              <Box sx={{display: 'inline-flex'}}>
                {`${Number(productDetails?.totalOnOrderWarehouse).toFixed(2)} `}
                <Typography sx={{color: 'gray'}}>(WH) </Typography>&nbsp;•
                {`${Number(productDetails?.totalOnOrderStore).toFixed(2)} `}
                <Typography sx={{color: 'gray'}}> (S) </Typography>
              </Box>
            )}
          </Typography>
        </Grid>
        <Grid item sm={2} md={2}>
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 500,
              color: theme.palette?.grayscale?.[600]
            }}
            textAlign='left'
          >
            UNIT/CASE:
          </Typography>
        </Grid>
        <Grid item sm={2} md={2} pl={1}>
          {Number(productDetails?.unitPerCase).toFixed(2)}
        </Grid>
      </Grid>
    </Box>
  );
};
const ProductDetail: React.FC = () => {
  const location: any = useLocation();
  const productId = location.state.itemId;
  return (
    <Box sx={{mt: '50px'}}>
      <BreadcrumbNav />
      <Box sx={{flexGrow: 1, mt: '34px'}}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ProductDesc productId={productId} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ProductStats productId={productId} />
          </Grid>
        </Grid>
      </Box>
      <ProductDetailGrid productId={productId} />
    </Box>
  );
};

export default ProductDetail;
