import {createSlice} from '@reduxjs/toolkit';
export interface productWareHouseListState {
  loading: boolean;
  productWareHouseList: Array<any>;
  error: string;
}

const initialState: productWareHouseListState = {
  loading: false,
  productWareHouseList: [],
  error: ''
};

const productWareHouseListSlice = createSlice({
  name: 'productWareHouseList',
  initialState,
  reducers: {
    productWareHouseListLoading: (state) => {
      state.loading = true;
    },
    productWareHouseListSuccess: (state, action) => {
      state.productWareHouseList = action.payload;
      state.loading = false;
    },
    productWareHouseListFailure: (state) => {
      state.loading = false;
      state.productWareHouseList = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default productWareHouseListSlice.reducer;
export const {
  productWareHouseListLoading,
  productWareHouseListSuccess,
  productWareHouseListFailure
} = productWareHouseListSlice.actions;
