import {Grid, Box, Typography, Stack, Avatar, Badge, styled, useTheme} from '@mui/material';
import {indigo} from '@mui/material/colors';
import React from 'react';
import CustomInput from 'components/baseComponents/customInput';
import {ReactComponent as Pencil} from 'assets/images/icons/Icons & UI-Edit-Edit_Active.svg';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {getProfileSagaActions} from 'redux/dashboard/profile/getProfileSaga';

interface IImageState {
  mainState: string; // initial, search, gallery, uploaded
  imageUploaded: string;
  selectedFile: any;
}
interface IPropType {
  atpEnabler?: (val: boolean) => void;
}
interface IProfileData {
  name: string;
  workEmail: string;
  workPhoneNumber: string;
  mobilePhoneNumber: string;
  homePhoneNumber: string;
  personalEmail: string;
}

//creating main-component
const AccountOverview: React.FC<IPropType> = (props: any) => {
  const [accountState, setAccountState] = React.useState({} as IProfileData);
  const [state, setState] = React.useState({} as IImageState);
  const theme = useTheme();
  const SmallAvatar = styled(Avatar)({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`
  });
  function formatEmailChange(value: string) {
    const matches = value.replaceAll(/([^a-z0-9A-Z*,.@-_])/g, '').substring(0, 40);
    return matches;
  }
  /**Formate Phone Number */
  function formatPhoneNumber(value: string) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    let returnValue = '';
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '').substring(0, 11);
    returnValue = phoneNumber ? '+ ' + phoneNumber[0] : '';

    if (phoneNumber.length >= 2) {
      returnValue = returnValue + ' ' + phoneNumber.substring(1, 4);
    }
    if (phoneNumber.length > 4) {
      returnValue =
        returnValue + (phoneNumber.length >= 5 ? '-' + phoneNumber.substring(4, 7) : '');
    }
    if (phoneNumber.length > 7) {
      returnValue =
        returnValue + (phoneNumber.length >= 8 ? '-' + phoneNumber.substring(7, 11) : '');
    }

    return returnValue;
  }
  /**handle change function */
  const handleChange = (event: any) => {
    let {value} = event.target;
    const {name} = event.target;
    if (name === 'personalEmail') {
      value = formatEmailChange(value);
    } else {
      value = formatPhoneNumber(value);
    }
    setAccountState({
      ...accountState,
      [name]: value
    });
  };
  /**handle-upload */
  const handleUploadClick = (event: any) => {
    const reader = new FileReader();
    reader.onloadend = function (e: any) {
      setState({
        ...state,
        selectedFile: [reader.result]
      });
    }.bind(this);
    setState({
      mainState: 'uploaded',
      selectedFile: event?.target.files[0],
      imageUploaded: '1'
    });
  };
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const {getProfile} = useAppSelector((state) => state.getProfileSlice);
  //For Binding Data
  React.useEffect(() => {
    dispatch({
      type: getProfileSagaActions.FETCH_GETPROFILE_DATA
    });
  }, []);
  const profileData: IProfileData = getProfile as IProfileData;
  React.useEffect(() => {
    setAccountState({
      ...profileData
    });
  }, [getProfile]);
  return (
    <>
      <input
        style={{display: 'none'}}
        accept='image/*'
        id='contained-button-file'
        multiple
        type='file'
        onChange={handleUploadClick}
      />
      <Box id='profile' sx={{display: 'flex', justifyContent: 'center'}}>
        <Stack direction='row' spacing={2} sx={{alignContent: 'center'}}>
          <Badge
            overlap='circular'
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            badgeContent={
              <SmallAvatar>
                <label htmlFor='contained-button-file' id='custom-button-image-upload'>
                  <Pencil />
                </label>
              </SmallAvatar>
            }
          >
            <Avatar
              src={state?.selectedFile}
              sx={{
                bgcolor: indigo[700],
                width: '112px',
                height: '112px'
              }}
            >
              <Typography
                variant='body1'
                sx={{
                  height: '57px',
                  width: '55px',
                  color: theme.palette.common.white,
                  fontFamily: 'Roboto',
                  fontSize: '48px',
                  letterSpacing: 0,
                  lineHeight: '57px',
                  textAlign: 'center'
                }}
              >
                JS
              </Typography>
            </Avatar>
          </Badge>
          <Stack direction='column'>
            <Typography
              sx={{
                height: '28px',
                width: '160px',
                color: theme.palette.common.black,
                fontFamily: 'Roboto Medium',
                fontSize: '24px',
                letterSpacing: 0,
                lineHeight: '28px',
                paddingTop: '20px'
              }}
            >
              {profileData.name}
            </Typography>
            <Typography
              sx={{
                height: '2opx',
                width: '125px',
                color: theme.palette.grayscale?.[400],
                fontFamily: 'Roboto',
                fontSize: '14px',
                letterSpacing: '0.25px',
                lineHeight: '20px'
              }}
            >
              {profileData.workEmail}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box id='profile-body-form' sx={{paddingTop: '20px'}}>
        <Stack sx={{marginBottom: '10px', paddingTop: '10px'}}>
          <Typography
            sx={{
              height: '22px',
              width: '57px',
              color: theme.palette.common.black,
              fontFamily: 'Roboto Medium',
              fontSize: '16px',
              letterSpacing: '0.15px',
              lineHeight: '22px'
            }}
          >
            Contact
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack>
              <Typography
                id='workPhoneNumber'
                variant='caption'
                sx={{
                  height: '14px',
                  width: '118px',
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '14px',
                  letterSpacing: '0.4px'
                }}
              >
                Work Phone Number
              </Typography>
              <CustomInput
                id='work-phone-number'
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  borderRadius: 1
                }}
                name='workPhoneNumber'
                value={accountState.workPhoneNumber}
                type='text'
                onChange={handleChange}
                placeholder='+ 1 123-456-7890'
              />
            </Stack>
            <Stack sx={{paddingTop: '10px'}}>
              <Typography
                id='homePhone'
                variant='caption'
                sx={{
                  height: '14px',
                  width: '118px',
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '14px',
                  letterSpacing: '0.4px'
                }}
              >
                Home Phone Number
              </Typography>
              <CustomInput
                id='home-phone-number'
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  borderRadius: 1
                }}
                name='homePhoneNumber'
                value={accountState.homePhoneNumber}
                type='text'
                onChange={handleChange}
                placeholder='+ 1 123-456-7890'
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography
                id='mobilePhone'
                variant='caption'
                sx={{
                  height: '14px',
                  width: '118px',
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '14px',
                  letterSpacing: '0.4px'
                }}
              >
                Mobile Phone Number
              </Typography>
              <CustomInput
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  borderRadius: 1
                }}
                name='mobilePhoneNumber'
                value={accountState.mobilePhoneNumber}
                type='text'
                onChange={handleChange}
                placeholder='+ 1 123-456-7890'
              />
            </Stack>
            <Stack sx={{paddingTop: '10px'}}>
              <Typography
                id='personalEmail'
                variant='caption'
                sx={{
                  height: '14px',
                  width: '118px',
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '14px',
                  letterSpacing: '0.4px'
                }}
              >
                Personal Email
              </Typography>
              <CustomInput
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  borderRadius: 1
                }}
                name='personalEmail'
                value={accountState.personalEmail}
                type='text'
                onChange={handleChange}
                placeholder='jsmith@company.com'
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default AccountOverview;
