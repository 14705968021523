import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  topTwentyVendorsLoading,
  topTwentyVendorsSuccess,
  topTwentyVendorsFailure
} from 'redux/dashboard/topTwentyVendors/topTwentyVendorsSlice';

export const topTwentyVendorsActions = {
  FETCH_TOPTWENTYVENDORS_SAGA: 'FETCH_TOPTWENTYVENDORS_SAGA'
};

export function* fetchtopTwentyVendorsData(action: any): any {
  try {
    yield put(topTwentyVendorsLoading());
    const {data} = yield call(DashboardServiceAPIS.getTopTwentyVendorsData, action.payload);
    yield put(topTwentyVendorsSuccess(data));
  } catch (error) {
    yield put(topTwentyVendorsFailure());
  }
}

export default function* watchFetchtopTwentyVendorsData() {
  yield takeEvery(topTwentyVendorsActions.FETCH_TOPTWENTYVENDORS_SAGA, fetchtopTwentyVendorsData);
}
