import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  topVendorStoreLoading,
  topVendorStoreSuccess,
  topVendorStoreFailure
} from 'redux/dashboard/topVendor/topVendorSlice';

export const topVendorStoreActions = {
  FETCH_TOPVENDOR_SAGA: 'FETCH_TOPVENDOR_SAGA'
};

export function* fetchTopVendorStoreData(action: any): any {
  try {
    yield put(topVendorStoreLoading());
    const {data} = yield call(DashboardServiceAPIS.getTopVendorStoreData, action.payload);
    yield put(topVendorStoreSuccess({result: data, type: action.payload.type}));
  } catch (error) {
    yield put(topVendorStoreFailure(action));
  }
}

export default function* watchFetchTopVendorStoreData() {
  yield takeEvery(topVendorStoreActions.FETCH_TOPVENDOR_SAGA, fetchTopVendorStoreData);
}
