import {TableHead, TableRow, Typography, Box, TableCell} from '@mui/material';
import React, {useState} from 'react';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {
  BorderedTableStickyCell,
  StyledTableRow,
  TableCellHOC,
  TableStickyCell
} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import TinyLineGraphComponent from 'components/tinyLineGraph';
import {inventoryBalanceSagaActions} from 'redux/report/inventory/inventoryBalances/inventoryBalanceSaga';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import moment from 'moment';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {buyerSagaActions} from 'redux/dashboard/master/buyerSaga';
import {CustomLoader, CustomError} from 'components/baseComponents/customMessage';
import {productListSagaActions} from 'redux/product/list/productList/productListSaga';
import {useLocation} from 'react-router-dom';

interface IStoreRegionProps {
  hierachyGroup: string;
  wh: string;
  oh: string;
  oo: string;
  fcstWos: string;
  turns: string;
  fcstSales: string;
  aws: string;
  sales: string;
  strRcpt: string;
  rolling16wSales: IRolling16WSales[];
  id: string;
}

interface IRolling16WSales {
  date: string;
  value: string;
}

const filterType = 'Region';
const StickyHeaderCell = (props: any) => {
  const consolidatedData = generateStickyHeaderValues(props.data, props.columnCell);
  ////xAxis is 16 week
  const xAxis = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16'
  ];

  /////Loop through all records of IRolling16WSales and sum the array based on index
  const totalRolling16WSales = props.data?.reduce(
    (accumulator: number[], c: IStoreRegionProps) => {
      const salesData: IRolling16WSales[] = c.rolling16wSales;
      return salesData.map((ele: IRolling16WSales, index: number) => {
        return (accumulator[index] || 0) + parseInt(ele.value);
      });
    },
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  );
  return (
    <>
      <TableHead>
        <TableRow>
          <BorderedTableStickyCell
            colSpan={3}
            sx={{top: '64px', color: 'grayscale.400', borderBottom: 0}}
          >
            <Typography variant='subtitle2'>CONSOLIDATED STATS</Typography>
          </BorderedTableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            {consolidatedData.oh}
          </TableStickyCell>
          <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
            {consolidatedData.oo}
          </BorderedTableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            {consolidatedData.fcstWos}
          </TableStickyCell>
          <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
            {consolidatedData.turns}
          </BorderedTableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            {consolidatedData.fcstSales}
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            {consolidatedData.aws}
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            {consolidatedData.sales}
          </TableStickyCell>
          <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
            {consolidatedData.strRcpt}
          </BorderedTableStickyCell>
          <TableStickyCell sx={{top: '64px'}}>
            <TinyLineGraphComponent
              id={`Consolidated-rolling16WSales`}
              xAxis={xAxis}
              yAxis={totalRolling16WSales as any as string[]}
            />
          </TableStickyCell>
        </TableRow>
      </TableHead>
    </>
  );
};

/**
 * Component to display when render the group elements
 * @param row Row data
 * @param cellColumn Cell columns
 * @returns React component
 */
const RowContentComponent = (props: any) => {
  const row = props.row;
  const tableCellColumn = props.cellColumn || [];
  const listPayload = props.listPayload;
  const groupName = row.hierachyGroup;
  //For API Data Fetching
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const filter = {
      type: filterType,
      regionId: groupName,
      filter: listPayload
    };
    dispatch({
      type: inventoryBalanceSagaActions.FETCH_INVENTORY_BALANACE_REGION_SAGA,
      payload: filter
    });
  }, []);

  const {inventoryBalanceRegion} = useAppSelector((state) => state.inventoryBalanceSlice);
  const regionData: IStoreRegionProps[] =
    inventoryBalanceRegion && inventoryBalanceRegion[groupName]
      ? inventoryBalanceRegion[groupName]
      : [];

  return (
    <>
      {regionData?.map((ele: any) => {
        return (
          <StyledTableRow
            sx={{
              height: '55px',
              whiteSpace: 'nowrap',
              backgroundColor: 'common.white',
              '&:nth-of-type(even)': {
                backgroundColor: 'grayscale.50'
              }
            }}
            key={`gridGroupRow-${row['itemID']}}`}
          >
            <TableCell padding='none'></TableCell>
            {tableCellColumn?.map((item: IColumnCell<IStoreRegionProps>, index: number) => {
              const renderCell = item.customCell
                ? item.customCell(row)
                : `${item.prefixText ? item.prefixText : ''}${ele[item.name]}${
                    item.suffixText ? item.suffixText : ''
                  }`;
              return (
                <TableCellHOC
                  isGroupBorderRequire={item.isGroupBorderRequire}
                  key={`enhanced-table-cell-${index}`}
                  align={item.isNumeric ? item?.cellTextAlign || 'right' : 'left'}
                  sx={item.sx ? item.sx : {}}
                >
                  <Typography variant='body2' color={item.textColor ?? ''}>
                    {renderCell}
                  </Typography>
                </TableCellHOC>
              );
            })}
          </StyledTableRow>
        );
      })}
    </>
  );
};

const InventoryStoreRegion: React.FC = () => {
  const location: any = useLocation();
  const dispatch = useAppDispatch();

  const {master} = useAppSelector((state) => state.masterSlice);
  const [dataOne, dataTwo, dataThree] = master;
  const {productList} = useAppSelector((state) => state.productListSlice);
  const {buyer} = useAppSelector((state) => state.buyerSlice);

  /////Event to dispatch master data for filter
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: productListSagaActions.FETCH_PRODUCTLIST_DATA
    });
    dispatch({
      type: buyerSagaActions.FETCH_BUYER_DATA
    });
  }, []);

  /**
   * Returns the Line graph
   * @param row Table row
   * @returns Component
   */
  const TinyLineComponent = (row: any) => {
    const rolling16WSales: IRolling16WSales[] = row?.rolling16wSales;
    ////xAxis is 16 week
    const xAxis = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16'
    ];
    const yAxis = rolling16WSales?.map((ele: IRolling16WSales) => ele.value) || [];

    return (
      <TinyLineGraphComponent
        id={`${row.hierachyGroup}-rolling16WSales`}
        xAxis={xAxis}
        yAxis={yAxis}
      />
    );
  };

  const columnCell: IColumnCell<IStoreRegionProps>[] = [
    {
      id: 'hierachyGroup',
      name: 'hierachyGroup',
      displayName: 'hierachy Group',
      isGroupBorderRequire: false,
      sx: {
        textAlign: 'left',
        minWidth: '150px'
      }
    },
    {
      id: 'wh',
      name: 'wh',
      displayName: 'wh',
      isColumnSortable: false,
      isGroupBorderRequire: true
    },
    {
      id: 'oh',
      name: 'oh',
      displayName: 'oh',
      isGroupBorderRequire: false,
      prefixText: '$',
      consolidateType: 'SUM',
      sx: {
        textAlign: 'right',
        width: '96px'
      }
    },
    {
      id: 'oo',
      name: 'oo',
      displayName: 'oo',
      isGroupBorderRequire: true,
      prefixText: '$',
      consolidateType: 'SUM',
      sx: {
        textAlign: 'right',
        width: '96px'
      }
    },
    {
      id: 'fcstWos',
      name: 'fcstWos',
      displayName: 'fcst Wos',
      isNumeric: true,
      isGroupBorderRequire: false,
      consolidateType: 'AVG',
      sx: {
        textAlign: 'right',
        width: '96px'
      }
    },
    {
      id: 'turns',
      name: 'turns',
      displayName: 'turns',
      isNumeric: true,
      isGroupBorderRequire: true,
      consolidateType: 'AVG',
      sx: {
        textAlign: 'right',
        width: '96px'
      }
    },
    {
      id: 'fcstSales',
      name: 'fcstSales',
      displayName: 'fcst Sales',
      isGroupBorderRequire: false,
      consolidateType: 'SUM',
      prefixText: '$',
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'aws',
      name: 'aws',
      displayName: 'aws',
      isGroupBorderRequire: false,
      prefixText: '$',
      consolidateType: 'SUM',
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'sales',
      name: 'sales',
      displayName: 'sales',
      isGroupBorderRequire: false,
      prefixText: '$',
      consolidateType: 'SUM',
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'strRcpt',
      name: 'strRcpt',
      displayName: 'str Rcpt',
      isGroupBorderRequire: true,
      prefixText: '$',
      consolidateType: 'SUM',
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'rolling16wSales',
      name: 'rolling16wSales',
      displayName: 'rolling 16w Sales',
      customCell: TinyLineComponent,
      isColumnSortable: false
    }
  ];

  const RowContainerComponent = (row: any, cellColumn: IColumnCell<IStoreRegionProps>[]) => {
    return (
      <RowContentComponent
        row={row}
        cellColumn={cellColumn}
        listPayload={generatePayload(filtervalues)}
      />
    );
  };

  const {inventoryBalance, loading, error} = useAppSelector((state) => state.inventoryBalanceSlice);
  const rowData =
    inventoryBalance[filterType] && inventoryBalance[filterType].length > 0
      ? inventoryBalance[filterType]
      : [];

  const defaultDateFormat = 'MM/DD/YY';
  const startDate = moment().startOf('week').format(defaultDateFormat);
  const endDate = moment().format(defaultDateFormat);
  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'buyer',
      title: 'Buyer',
      sx: {
        width: '182px'
      },
      options: generateLabelValue(buyer, 'buyerName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      sx: {
        width: '182px'
      },
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      defaultValue: 'All Departments',
      allowAllOptions: true,
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentId') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '182px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'classId', 'departmentId')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      sx: {
        width: '182px'
      },
      allowAllOptions: true,
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassId', 'classId')
    },
    {
      type: filterFieldTypes.MULTISELECT,
      property: 'itemId',
      title: 'Item Id',
      defaultValue: [],
      sx: {
        width: '182px'
      },
      options: generateLabelValue(productList, 'itemName')
    },
    {
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date',
      defaultValue: `${startDate}|${endDate}`,
      sx: {
        width: '182px'
      }
    }
  ];
  const defaultData = filterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);

  /**
   * Create the filter array to send as API request
   * @param values Array of objects
   */
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      if (key === 'itemId') {
        temp.push({key: key, value: JSON.stringify(value), condition: true});
      } else {
        temp.push({key: key, value: value, condition: true});
      }
    }
    return temp;
  };

  /////On filter change
  React.useEffect(() => {
    const filter = {
      type: filterType,
      filter: generatePayload(filtervalues)
    };
    dispatch({
      type: inventoryBalanceSagaActions.FETCH_INVENTORY_BALANACE_SAGA,
      payload: filter
    });
  }, [filtervalues]);

  /**
   * OnChange of filter
   * @param values Filter values
   */
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };

  if (rowData && rowData.length > 0 && !error) {
    return (
      <>
        <Box sx={{pb: 3}}>
          <GridFilter
            values={filtervalues}
            filterTemplate={filterTemplate}
            onFilterChange={onFilterApply}
            hideMoreFilter={true}
            onReset={() => {
              setFilterValues(defaultData);
            }}
            id='report-inventory-storeRegion-filter'
          />
          <EnhancedTable<IStoreRegionProps>
            height={844}
            rowData={rowData}
            stickyRows={<StickyHeaderCell data={rowData} columnCell={columnCell} />}
            columnCell={columnCell}
            rowKey={'hierachyGroup'}
            defaultSortKey={'hierachyGroup'}
            componentType='paper'
            allowAlternateColor={true}
            rowsPerPage={25}
            allowRowGrouping={true}
            rowGroupTemplate={RowContainerComponent}
            id='report-inventory-store'
          />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='report-inventory-storeRegion-filter'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='inventory-store-region-load' />
          ) : (
            <CustomError id='inventory-store-region-error' />
          )}
        </Box>
      </>
    );
  }
};
export default InventoryStoreRegion;
