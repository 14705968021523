import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from '../../product/product_service';
import {channelLoading, channelFailure, channelSuccess} from './channelSlice';

export const channelSagaActions = {
  FETCH_CHANNEL_DATA: 'FETCH_CHANNEL_DATA'
};

export function* fetchChannelData(): any {
  try {
    yield put(channelLoading());
    const {data} = yield call(ProductAPIS.getChannelData);
    yield put(channelSuccess(data));
  } catch (error) {
    yield put(channelFailure());
  }
}

export default function* watchFetchChannelData() {
  yield takeEvery(channelSagaActions.FETCH_CHANNEL_DATA, fetchChannelData);
}
