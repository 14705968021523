import * as React from 'react';
import {Box, Typography, Stack} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {freightSummarySagaActions} from 'redux/purchaseOrder/details/freightSummary/freightSummarySaga';
import {useLocation} from 'react-router-dom';

const FreightSummary: React.FC = () => {
  const location: any = useLocation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch({
      type: freightSummarySagaActions.FETCH_FREIGHTSUMMARY_DATA,
      payload: location.state?.poId
    });
  }, []);

  const {freightSummary} = useAppSelector((state) => state.freightSummarySlice);
  return (
    <Box
      id='freightSummary-main'
      sx={{
        mt: 4,
        px: 2,
        mx: '160px',
        bgcolor: 'grayscale.50',
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.5)'
      }}
    >
      <Typography id='freightSummary-heading' variant='subtitle1' sx={{pb: 3, pt: 3}}>
        Freight Summary
      </Typography>
      <Stack direction={'row'} sx={{pb: 3.625, gap: '1.5%'}}>
        <Stack sx={{width: '300px'}}>
          <Typography
            id='freightSummary-poType'
            variant='caption'
            sx={{
              pb: '9px',
              color: 'grayscale.600',
              fontFamily: 'Roboto Medium',
              fontSize: '12px',
              display: 'flex'
            }}
          >
            Estimated Freight
          </Typography>
          <Typography variant='body2'>
            {freightSummary?.estimatedFreight
              ? `$${Number(freightSummary?.estimatedFreight).toFixed(2)}`
              : `$ 0`}
          </Typography>
        </Stack>
        <Stack sx={{width: '300px'}}>
          <Typography
            id='freightSummary-other-costs'
            variant='caption'
            sx={{
              pb: '9px',
              color: 'grayscale.600',
              fontFamily: 'Roboto Medium',
              fontSize: '12px',
              display: 'flex'
            }}
          >
            Other Costs
          </Typography>
          <Typography variant='body2'>
            {freightSummary?.otherCosts
              ? `$${Number(freightSummary?.otherCosts).toFixed(2)}`
              : ` $ 0`}
          </Typography>
        </Stack>
        <Stack sx={{width: '300px'}}>
          <Typography
            id='freightSummary-total-shippingCost'
            variant='caption'
            sx={{
              color: 'grayscale.600',
              fontFamily: 'Roboto Medium',
              fontSize: '12px',
              display: 'flex'
            }}
          >
            Total Shipping Cost
          </Typography>
          <Typography variant='body2'>
            {freightSummary?.totalShippingCost
              ? `$${Number(freightSummary?.totalShippingCost).toFixed(2)}`
              : `$ 0`}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} sx={{gap: '1.5%'}}>
        <Stack sx={{width: '300px'}}>
          <Typography
            id='freightSummary-weight'
            variant='caption'
            sx={{
              pb: '9px',
              color: 'grayscale.600',
              fontFamily: 'Roboto Medium',
              fontSize: '12px',
              display: 'flex'
            }}
          >
            Weight
          </Typography>
          <Typography variant='body2'>
            {freightSummary?.weight ? `${Number(freightSummary?.weight).toFixed(2)} LB` : `0 LB`}
          </Typography>
        </Stack>
        <Stack sx={{width: '300px'}}>
          <Typography
            id='freightSummary-total-cubicFeet'
            variant='caption'
            sx={{
              pb: '9px',
              color: 'grayscale.600',
              fontFamily: 'Roboto Medium',
              fontSize: '12px',
              display: 'flex'
            }}
          >
            Total Cubic Feet
          </Typography>
          <Typography variant='body2'>
            {freightSummary?.totalCubicFeet
              ? `${Number(freightSummary?.totalCubicFeet).toFixed(2)}`
              : `0`}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default FreightSummary;
