import {createSlice} from '@reduxjs/toolkit';
export interface adPromosState {
  loading: boolean;
  adPromosTileData: Array<any>;
  error: string;
}

const initialState: adPromosState = {
  loading: false,
  adPromosTileData: [],
  error: ''
};

const adPromosTileSlice = createSlice({
  name: 'adPromosTile',
  initialState,
  reducers: {
    adPromosLoading: (state) => {
      state.loading = true;
    },
    adPromosSuccess: (state, action) => {
      state.adPromosTileData = action.payload;
      state.loading = false;
    },
    adPromosFailure: (state) => {
      state.loading = false;
      state.adPromosTileData = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default adPromosTileSlice.reducer;
export const {adPromosLoading, adPromosSuccess, adPromosFailure} = adPromosTileSlice.actions;
