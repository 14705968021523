import React from 'react';
import {Grid, Pagination, PaginationItem, SxProps} from '@mui/material';
import {ReactComponent as LeftArrow} from 'assets/images/icons/Icons & UI-Carets-Caret-Left_Active.svg';
import {ReactComponent as RightArrow} from 'assets/images/icons/Icons & UI-Carets-Caret-Right_Active.svg';
import CustomInput from 'components/baseComponents/customInput';

interface IEnhancedTablePaginationProps {
  id: string;
  page: number;
  rowsPerPage: number;
  count: number;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  onRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement>;
  paginationSx?: SxProps;
}

export function EnhancedTablePagination(props: IEnhancedTablePaginationProps) {
  const {page, rowsPerPage, count} = props;
  const noOfPages = Math.ceil(count / rowsPerPage);

  const onJumpPageNoTo = (event: any) => {
    const value = Number(event.target.value || 1);
    if (value > 0 && value <= noOfPages) {
      props.onPageChange(event, value);
    }
  };

  return (
    <Grid
      container
      mt={2}
      justifyContent='center'
      alignItems='center'
      sx={props.paginationSx ? props.paginationSx : {}}
    >
      <Grid id={`${props.id}-page-info`} sx={{pt: 0}} item xs>
        Showing {page * rowsPerPage + 1}-
        {page * rowsPerPage + rowsPerPage <= count ? page * rowsPerPage + rowsPerPage : count} of{' '}
        {count}
      </Grid>
      <Grid sx={{pt: 0}} item xs={6}>
        <Grid sx={{pt: 0}} container justifyContent={'center'}>
          <Grid sx={{pt: 0}} item>
            <Pagination
              count={noOfPages}
              page={page + 1}
              shape='rounded'
              onChange={props.onPageChange}
              renderItem={(item) => {
                return (
                  <PaginationItem
                    id={`${props.id}-page-${item?.page}`}
                    sx={{
                      '&.MuiPaginationItem-root': {
                        fontSize: '16px',
                        color: 'primary.main',
                        '&.Mui-selected': {
                          backgroundColor: 'primary.contrastText'
                        }
                      }
                    }}
                    components={{previous: LeftArrow, next: RightArrow}}
                    {...item}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs sx={{pt: 0}}>
        <Grid sx={{pt: 0}} container direction='row-reverse'>
          <Grid sx={{pt: 0, display: 'flex', alignItems: 'center'}} item>
            Jump to:
            <CustomInput
              id={`${props.id}-jump-to`}
              sx={{ml: 1, width: '76px'}}
              type='number'
              inputProps={{
                max: noOfPages,
                min: 1
              }}
              onChange={onJumpPageNoTo}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
