export enum POStatus {
  DRAFT = 'DRAFT',
  ON_ORDER = 'ON ORDER',
  IN_TRANSIT = 'IN TRANSIT',
  IN_RECEIPT = 'IN RECEIPT',
  IN_QC = 'IN QC',
  RECEIVED = 'RECEIVED'
}

export enum Mode {
  EDIT = 'EDIT',
  VIEW = 'VIEW'
}

export interface IPOItems {
  itemId: string;
  lineNo: string;
  itemDescription: string;
  classId: string;
  subCLassId: string;
  pack: string;
  size: string;
  rcv: string;
  asn: string;
  quantity: string;
  onOrder: string;
  purchaseUnit: string;
  netCost: string;
  estimatedFreight: string;
  landed: string;
  retail: string;
  expiry: string;
  status: string;
  poStatus: string;
  vendCost: string;
}

export interface IGeneralInfo {
  poStatus: string;
  mode: string;
  submitStatus: boolean;
  supplierData?: Array<string | boolean | number>;
  onValueChange: (event: any) => void;
  data: any;
  dependentData?: any;
}
export interface ISupllierContact {
  supplierId: string;
  supplierName: string;
  email: string;
  contact: string;
  telephone: string;
  fax: string;
  street: string;
  houseNo: null;
  postalCode: string;
  city: string;
  region: string;
  country: string;
  fobOrigin: string;
  paymentTerms: string;
  invoicingParty: string;
}
export interface IGeneralInfoState {
  purchaseOrder: string;
  issueDate: string;
  supplierName: string;
  contact: string;
  buyerId: string;
  buyerName: string;
  warehouseStore: string;
  warehouseSection: string;
  freightTermDesc: string;
  fobOrigin: string;
  loadCondDesc: string;
  unloading: string;
  deliveryCondDesc: string;
  mustArriveBy: string;
  appointmentDate: string;
  appointmentTime: string;
  advanceShippingNotice: string;
  paymentTerms: string;
  invoicingParty: string;
  currency: string;
  fixedExchangeRate: string;
  exchangerate: string;
  purchaseOrderItem: any;
  poStatus: string;
}
