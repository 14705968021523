import {createSlice} from '@reduxjs/toolkit';
export interface createPOState {
  loading: boolean;
  createPO: any;
  error: string;
}

const initialState: createPOState = {
  loading: false,
  createPO: {},
  error: ''
};

const createPOSlice = createSlice({
  name: 'createPO',
  initialState,
  reducers: {
    createPOLoading: (state) => {
      state.loading = true;
      state.createPO = {};
    },
    createPOSuccess: (state, action) => {
      state.createPO = action.payload;
      state.loading = false;
    },
    createPOFailure: (state, action) => {
      state.loading = false;
      state.createPO = {};
      state.error = action.payload;
    },
    //for clear PO
    clearPOLoading: (state) => {
      state.loading = false;
      state.createPO = {};
      state.error = '';
    },
    clearPoFailure: (state) => {
      state.loading = false;
      state.createPO = {};
      state.error = '';
    }
  }
});

export default createPOSlice.reducer;
export const {createPOLoading, createPOSuccess, createPOFailure, clearPOLoading, clearPoFailure} =
  createPOSlice.actions;
