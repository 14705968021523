import {styled} from '@mui/system';

const DropDownWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '& div:nth-child(1)': {
    '& div.MuiInput-root': {
      minWidth: '70px'
    },
    '& div.MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  '& span': {
    padding: '2px 5px',
    borderRadius: 3,
    maxHeight: 24,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.error.main,
    '& p': {
      fontWeight: 500
    }
  }
}));

export {DropDownWrapper};
