import React from 'react';
import {getTileWidth} from './tileUtil';

export const CustomMasonryBlock: React.FC<any> = (props) => {
  const defaultStyle = {
    position: `absolute`,
    boxSizing: `border-box`
  };

  /**
   * Reference to the div element.
   * @type {HTMLElement|null}
   */
  //let divElement: any = null;

  const {width, parent, style, ...rest} = props,
    maxColumns = parent?.columns || 1,
    columns = Math.min(width || 1, maxColumns, props.parent?.props?.maxColumns || 1);
  style.width = getTileWidth(columns);

  return (
    <div data-width={columns} {...rest} style={{...style, ...defaultStyle}} className={`mblock`}>
      {props.children}
    </div>
  );
};
