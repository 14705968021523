import React, {useState} from 'react';
import Plot from 'react-plotly.js';
import {getColumnValues} from 'util/arrayOperations';
import {useTheme} from '@mui/material/styles';
import {Box, Grid, IconButton, Stack, Typography} from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';

/**
 * This Graph is line plotly graph,
 * Y axis should contain string.
 * X axis should contain string array
 */
interface IPropType {
  //Div id for which the plotly needs to update
  id: string;
  // Array of objects
  data: any[];
  // object for plotly
  layout?: Partial<Plotly.Layout>;
  // xAxis column names
  xAxis: IAxis;
  // yAxis column name
  yAxis: Array<IAxis>;
  // trace properties
  traceProps?: Partial<Plotly.Data>;
  //previous click
  prevClick: () => void;
  //next click
  nextClick: () => void;
  //page no to sent from parent
  pageNo: number;
  //size is used to display for dashboard tile or report
  size?: 'small' | 'large';
}

export interface IAxis {
  name: string;
  displayTitle?: string;
  color?: string;
  unit?: string;
  prefix?: string;
  format?: string;
}
export const defaultXAxisDateFormat = 'MM/DD/YY';

export const PerpetualLineGraphComponent: React.FC<IPropType> = (props) => {
  const theme = useTheme();
  const dataLength = 25; /// max we can show only 25 datapoints in one page

  const layout = {
    autosize: false,
    showlegend: false,
    font: {
      size: 12
    },
    colorway: colorMarker,
    xaxis: {
      visible: false
    } as unknown as Plotly.LayoutAxis,
    yaxis: {
      showline: false,
      automargin: true
    }
  } as unknown as Plotly.Layout;
  const plotlyLayout = {...layout, ...props.layout};
  let traces: Array<Plotly.Data> = [];
  let xAxisData: string[] = [];

  const sortedData = props.data;
  try {
    xAxisData = getColumnValues<string>(sortedData?.slice(0, dataLength), props.xAxis.name, 10);

    traces = props.yAxis.map((element: IAxis, index: number) => {
      const yAxisData = getColumnValues<number>(sortedData?.slice(0, dataLength), element.name);
      return {
        x: xAxisData,
        y: yAxisData,
        text: xAxisData as unknown as string[],
        type: 'scatter',
        line: {color: colorMarker[index], width: 2},
        fill: 'none',
        name: element.displayTitle || element.name,
        texttemplate: `${element?.prefix || ''}` + '%{text}' + `${element?.unit || ''}`,
        marker: {
          line: {
            width: 2,
            color: colorMarker[index]
          },
          symbol: 'circle',
          size: 8,
          color: '#FFFFFF'
        },
        ...props.traceProps
      } as Plotly.Data;
    });
  } catch (ex) {
    console.error(ex);
  }
  const tracePageLimit = 5;
  const [tracePage, setTracePage] = useState<number>(1);
  /**
   * OnClick of previous trace page
   */
  const prevTracePage = () => {
    if (tracePage > 1) {
      changeTracePage(tracePage - 1);
    }
  };
  /**
   * Get No of trace pages
   */
  function numOfTracePages() {
    return Math.ceil(traces.length / tracePageLimit);
  }
  /**
   * Format the x-axis
   * If date, display MM/DD
   * String, display only 5 char
   */
  const formatXAxis = (value: string) => {
    let returnValue = '';
    if (value) {
      // if (value.length == 10 && moment(value, defaultResponseDateFormat, true).isValid()) {
      //   returnValue = moment(value).format(props.xAxis.format || defaultXAxisDateFormat);
      // } else {
      returnValue = moment(value).format(props.xAxis.format || defaultXAxisDateFormat);
      //}
    }
    return returnValue;
  };

  /**
   * On trace Page change event
   */
  const changeTracePage = (page: number) => {
    if (page < 1) {
      setTracePage(1);
    }
    if (page > numOfTracePages()) {
      setTracePage(numOfTracePages());
    }
    setTracePage(page);
  };
  const traceCurrentPageList = [];
  for (
    let i = (tracePage - 1) * tracePageLimit;
    i < tracePage * tracePageLimit && i < traces.length;
    i++
  ) {
    traceCurrentPageList.push(traces[i].name);
  }
  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center'
        }}
      >
        {props.size && props.size == 'large' ? (
          <Grid item>
            <Box
              sx={{
                maxWidth: '250px',
                textAlign: 'center',
                marginRight: 2
              }}
            >
              1000&apos;s Dallars
            </Box>
          </Grid>
        ) : null}
        <Grid item sx={{width: props.size && props.size == 'large' ? '1200px' : '100%'}}>
          <Grid container>
            <Grid
              item
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'center'
              }}
              md={12}
              lg={12}
            >
              <Box
                sx={{
                  width: '10px',
                  opacity: '0.1',
                  background: 'linear-gradient(270deg, rgba(58,72,87,0) 0%, #3A4857 100%)'
                }}
              ></Box>
              <Box
                sx={{
                  backgroundColor: 'transparent',
                  marginLeft: props.size && props.size == 'large' ? -5 : -3.5
                }}
              >
                <Plot
                  divId={props.id || 'lineChartMonitoring'}
                  data={[...traces]}
                  layout={plotlyLayout}
                  config={{staticPlot: true, displayModeBar: true}}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: -1,
                  width: '10px',
                  opacity: '0.1',
                  background: 'linear-gradient(90deg, rgba(58,72,87,0) 0%, #3A4857 100%)'
                }}
              ></Box>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                flexWrap: 'nowrap'
              }}
              md={12}
              lg={12}
            >
              <Box>
                <IconButton
                  onClick={() => {
                    props.prevClick();
                  }}
                  id={`${props.id}lineGrph-leftIcon`}
                  sx={{
                    transform: 'rotate(90deg)',
                    padding: 0,
                    minWidth: '40px',
                    m: '-20px 0 0 4px'
                  }}
                >
                  <ExpandCircleDownIcon sx={{color: theme.palette.primary.dark}} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  width: '10px',
                  height: props.size && props.size == 'large' ? '56px' : '42px',
                  marginLeft: -2,
                  opacity: '0.1',
                  background: 'linear-gradient(270deg, rgba(58,72,87,0) 0%, #3A4857 100%)'
                }}
              ></Box>
              <Box
                sx={{
                  display: 'flex',
                  overflow: 'hidden',
                  width: props.size && props.size == 'large' ? '1200px' : '100%',
                  marginLeft: -1
                }}
              >
                {xAxisData
                  ?.slice(1, props.pageNo === 0 ? xAxisData.length : xAxisData.length - 1)
                  ?.map((ele) => {
                    return (
                      <Box
                        id='perpetual-table-header'
                        key={`xAxisData${ele}`}
                        sx={{
                          whiteSpace: 'nowrap',
                          width: 5,
                          textAlign: 'center',
                          borderTop: `2px solid ${theme.palette.grayscale?.[300]}`,
                          borderRight:
                            props.size && props.size == 'large'
                              ? `none`
                              : `1px solid ${theme.palette.grayscale?.[300]}`,
                          borderBottom: 'none',
                          backgroundColor: theme.palette.grayscale?.[100],
                          flexGrow: 1,
                          paddingTop: 2,
                          paddingBottom: 2,
                          '&:last-child': {
                            borderRight: 'none'
                          }
                        }}
                      >
                        <Typography
                          variant='caption'
                          sx={{
                            fontFamily: 'Roboto Bold',
                            fontSize: '12px',
                            letterSpacing: '0.4px',
                            paddingRight: 0.75
                          }}
                        >
                          {formatXAxis(ele)}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
              <Box
                sx={{
                  width: '10px',
                  height: props.size && props.size == 'large' ? '56px' : '42px',
                  marginLeft: -1.1,
                  opacity: '0.1',
                  background: 'linear-gradient(90deg, rgba(58,72,87,0) 0%, #3A4857 100%)'
                }}
              ></Box>
              <Box sx={{marginLeft: -2}}>
                <IconButton
                  onClick={() => {
                    props.nextClick();
                  }}
                  id={`${props.id}lineGrph-rightIcon`}
                  sx={{
                    padding: 0,
                    minWidth: '40px',
                    transform: 'rotate(270deg)',
                    m: '-20px 4px 0px 0px'
                  }}
                  disabled={props.pageNo === 0 ? true : false}
                >
                  <ExpandCircleDownIcon
                    sx={
                      props.pageNo === 0
                        ? {color: 'rgba(0,0,0,0.5)'}
                        : {color: theme.palette.primary.dark}
                    }
                  />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: props.size && props.size == 'large' ? '150px' : '100px'
          }}
        >
          <Stack alignItems='stretch' sx={{position: 'relative', height: '100%'}}>
            {props.size && props.size == 'large' ? (
              <Box
                key={`tracePaddingTop`}
                sx={{
                  marginBottom: 1,
                  marginTop: 1
                }}
              ></Box>
            ) : (
              <Box>
                <IconButton
                  onClick={() => {
                    prevTracePage();
                  }}
                  id={`${props.id}lineGrph-LeftIcon`}
                  sx={{
                    padding: 0,
                    width: '60px',
                    background: 'white'
                  }}
                  disabled={tracePage === 1 ? true : false}
                >
                  <KeyboardArrowUpIcon
                    height='40px'
                    sx={{
                      fontSize: '32px',
                      color: tracePage === 1 ? 'grey' : 'palette.primary.main'
                    }}
                  />
                </IconButton>
              </Box>
            )}

            {traceCurrentPageList?.map((ele, index) => {
              return (
                <Box
                  key={`traceName-${ele}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    lineHeight: '100%',
                    marginBottom: 1,
                    marginTop: 1
                  }}
                >
                  <Box
                    sx={{
                      height: '15px',
                      width: '15px',
                      marginRight: 1,
                      backgroundColor: colorMarker[index],
                      borderRadius: '50%',
                      display: 'inline-block'
                    }}
                  >
                    {' '}
                  </Box>{' '}
                  <Box>{ele}</Box>
                  <br></br>
                </Box>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
const colorMarker = [
  '#0208fd',
  '#ffc857',
  '#1ce8c2',
  '#FE5144',
  '#fdcd02',
  '#8302fd',
  '#02fd3a',
  '#fd0213',
  '#025cfd',
  '#a5fd02',
  '#fd02ef',
  '#02fdc2',
  '#fd7902',
  '#3002fd',
  '#1efd02',
  '#fd0267',
  '#02b0fd',
  '#f9fd02',
  '#b702fd',
  '#02fd6e',
  '#fd2502',
  '#0228fd',
  '#72fd02',
  '#fd02bb',
  '#02fdf6',
  '#fdad02',
  '#6302fd',
  '#02fd1a',
  '#fd0233',
  '#027cfd',
  '#c5fd02',
  '#eb02fd',
  '#02fda2',
  '#fd5902',
  '#1002fd',
  '#3efd02',
  '#fd0287',
  '#02d0fd',
  '#fde102',
  '#9702fd',
  '#02fd4e',
  '#fd0502',
  '#0248fd',
  '#92fd02',
  '#fd02db',
  '#02fdd6',
  '#fd8d02',
  '#4302fd',
  '#0afd02',
  '#fd0253',
  '#029cfd',
  '#e6fd02',
  '#cb02fd',
  '#02fd82',
  '#fd3902',
  '#0215fd',
  '#5efd02',
  '#fd02a7',
  '#02f0fd',
  '#fdc002',
  '#7702fd',
  '#02fd2e',
  '#fd021f',
  '#0268fd',
  '#b2fd02',
  '#fd02fb',
  '#02fdb6',
  '#fd6d02',
  '#2302fd',
  '#2afd02',
  '#fd0273',
  '#02bcfd',
  '#fdf402',
  '#ab02fd',
  '#02fd62',
  '#fd1902',
  '#0235fd',
  '#7efd02',
  '#fd02c7',
  '#02fdea',
  '#fda002',
  '#5702fd',
  '#02fd0e',
  '#fd023f',
  '#0288fd',
  '#d2fd02',
  '#df02fd',
  '#02fd96',
  '#fd4d02',
  '#0302fd',
  '#4afd02',
  '#fd0293',
  '#02dcfd',
  '#fdd402',
  '#8b02fd',
  '#02fd42',
  '#fd020b',
  '#0255fd',
  '#9efd02',
  '#fd02e7'
];
export default PerpetualLineGraphComponent;
