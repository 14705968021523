import {Box, Grid, Link, Stack, Typography, useTheme} from '@mui/material';
import {useLocation} from 'react-router-dom';
import React from 'react';
import {BreadcrumbNav as VendorBreadCrumbs} from 'features/vendor/vendorBreadCrumbs';
import CustomSelect from 'components/baseComponents/customSelect';
import VendorDetailTabs from 'features/vendor/vendorDetailsTabs';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {vendorDetailsSagaActions} from 'redux/vendor/details/vendorDetailsSaga';
import {vendorSagaActions} from 'redux/dashboard/master/vendorSaga';
interface IPropType {
  vendorId: string;
}
export const VendorDesc: React.FC<IPropType> = (props) => {
  const id = props.vendorId;

  const [vendorSelected, setVendorSelected] = React.useState(id);

  const dispatch = useAppDispatch();

  const {vendorDetails} = useAppSelector((state) => state.vendorDetailsSlice);

  const data: any = vendorDetails;

  React.useEffect(() => {
    dispatch({
      type: vendorDetailsSagaActions.FETCH_VENDORDETAILS_DATA,
      payload: id
    });
    dispatch({
      type: vendorSagaActions.FETCH_VENDOR_DATA
    });
  }, []);

  const handleSelect = (event: any): void => {
    setVendorSelected(event?.target.value);
  };

  const generateContent = (id: any, name: any) => {
    const content = (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Link
          sx={{
            textDecoration: 'none',
            fontSize: '12px',
            letterSpacing: '0.4px',
            fontFamily: 'Roboto Medium',
            display: 'inline-block',
            minWidth: '50px'
          }}
        >
          {id}
          {'  '}
        </Link>
        <Typography
          variant='subtitle1'
          sx={{
            fontSize: '12px',
            letterSpacing: '0.4px',
            display: 'inline-block'
          }}
        >
          {name}
        </Typography>
      </Box>
    );
    const text = content.props.children;
    return text;
  };
  const theme = useTheme();

  // const { vendor } = useAppSelector((state) => state.vendorSlice);
  const vendorOptions = [];
  const vendorObj = {label: '', value: ''};
  if (vendorDetails?.vendorId && vendorDetails?.vendorDescription) {
    vendorObj.label = generateContent(vendorDetails?.vendorId, vendorDetails?.vendorDescription);
    vendorObj.value = vendorDetails?.vendorId;
    vendorOptions.push(vendorObj);
  }
  /// To-Do subVendor list needs to display

  // for (let i = 0; i < vendor?.length; i++) {
  //   const obj = { label: '', value: '' };
  //   obj.label = generateContent(vendor[i][`vendorDescription`] + i, vendor[i][`vendorDescription`]);
  //   obj.value = vendor[i][`vendorName`];
  //   vendorOptions.push(obj);
  // }
  return (
    <Box sx={{flexGrow: 1, display: 'flex'}}>
      <Box sx={{width: '552px'}}>
        <Box
          sx={{
            display: 'flex',
            gap: '1%',
            '& .MuiTypography-root': {
              display: 'flex',
              alignItems: 'center'
            }
          }}
        >
          #
          <CustomSelect
            id='vendorDetails'
            name='vendorDetail_name'
            value={vendorSelected}
            options={vendorOptions}
            onChange={handleSelect}
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: '7px',
              '& svg': {
                width: '18px',
                height: '12px'
              },
              '& .MuiSelect-select a': {
                fontFamily: 'Roboto Regular',
                fontSize: '24px',
                color: theme.palette.common.black
              }
            }}
          />
        </Box>
        <Typography
          variant='h4'
          sx={{
            color: 'common.black',
            lineHeight: 1.25,
            py: 1.5,
            width: '750px'
          }}
          textAlign='left'
        >
          {data?.vendorDescription}
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{color: 'grayscale.600', lineHeight: '22px', width: '250px'}}
          textAlign='left'
        >
          {data?.vendorAddress}
        </Typography>
      </Box>
    </Box>
  );
};

export const VendorStats = (props: any) => {
  const {vendorDetails} = useAppSelector((state) => state.vendorDetailsSlice);
  const theme = useTheme();

  const data: any = vendorDetails;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
      id={`VendorStats-${props.vendorId}`}
    >
      <Stack direction='row' spacing={2}>
        <Stack direction='row' spacing={3}>
          <Stack direction='column' spacing={2} sx={{width: '104px', textTransform: 'uppercase'}}>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600]
              }}
              textAlign='left'
            >
              Contacts:
            </Typography>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600]
              }}
              textAlign='left'
            >
              Last PO:
            </Typography>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600]
              }}
              textAlign='left'
            >
              Terms:
            </Typography>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600]
              }}
              textAlign='left'
            >
              Shipping:
            </Typography>
          </Stack>
          <Stack direction='column' spacing={2} sx={{width: '232px'}}>
            <Typography
              variant='body1'
              sx={{
                fontFamily: 'Roboto Medium',
                color: 'primary.main',
                lineHeight: 1.75
              }}
              textAlign='left'
            >
              {data?.contacts}
            </Typography>
            <Stack direction='row' spacing={0.3}>
              <Typography
                variant='body1'
                sx={{
                  fontFamily: 'Roboto Medium',
                  color: 'primary.main',
                  lineHeight: 1.75
                }}
                textAlign='left'
              >
                {data?.lastPo}
                {data?.lastPoDate}
              </Typography>
            </Stack>
            <Stack direction='row' spacing={0.3}>
              <Typography variant='body1' sx={{lineHeight: 1.75}} textAlign='left'>
                {`${data?.terms} NET 30`}
              </Typography>
            </Stack>
            <Stack direction='row' spacing={0.3}>
              <Typography variant='body1' sx={{lineHeight: 1.75}} textAlign='left'>
                {/* {data?.shipping} */ `prepaid`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

const VendorDetails: React.FC = () => {
  const location: any = useLocation();
  const vendorId = location.state.vendorId;
  return (
    <Box sx={{mt: '60px'}}>
      <VendorBreadCrumbs />
      <Box sx={{flexGrow: 1, mt: '30px'}}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <VendorDesc vendorId={vendorId} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <VendorStats vendorId={vendorId} />
          </Grid>
        </Grid>
      </Box>
      <VendorDetailTabs vendorId={vendorId} />
    </Box>
  );
};

export default VendorDetails;
