import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  notificationFailure,
  notificationLoading,
  notificationSuccess
} from 'redux/dashboard/globalNotification/globalNotificationSlice';

export const notificationSagaActions = {
  FETCH_NOTIFICATION_DATA: 'FETCH_NOTIFICATION_DATA'
};

export function* fetchNotificationData() {
  try {
    yield put(notificationLoading());
    const {data} = yield call(DashboardServiceAPIS.getNotificationData);
    yield put(notificationSuccess(data));
  } catch (error) {
    yield put(notificationFailure());
  }
}

export default function* watchFetchGlobalNotificationData() {
  yield takeEvery(notificationSagaActions.FETCH_NOTIFICATION_DATA, fetchNotificationData);
}
