import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  productDemandForeCastItemSuccess,
  productDemandForeCastItemLoading,
  productDemandForeCastItemFailure
} from 'redux/product/details/productDemandForeCastItem/productDemandForeCastItemSlice';

export const productDemandForeCastItemSagaActions = {
  FETCH_PRODUCTDEMANDFORECASTITEM_DATA: 'FETCH_PRODUCTDEMANDFORECASTITEM_DATA'
};

export function* fetchProductDemandForeCastItemData(action: any): any {
  try {
    yield put(productDemandForeCastItemLoading());
    const {data} = yield call(ProductAPIS.getProductDemandForeCastItemData, action.payload);
    yield put(productDemandForeCastItemSuccess(data));
  } catch (error) {
    yield put(productDemandForeCastItemFailure());
  }
}

export default function* watchFetchProductDemandForeCastItemData() {
  yield takeEvery(
    productDemandForeCastItemSagaActions.FETCH_PRODUCTDEMANDFORECASTITEM_DATA,
    fetchProductDemandForeCastItemData
  );
}
