import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  distrubutionCenterLoading,
  distrubutionCenterFailure,
  distrubutionCenterSuccess
} from 'redux/dashboard/master/distrubutionCenterSlice';

export const distrubutionCenterSagaActions = {
  FETCH_DISTRUBUTION_CENTER_DATA: 'FETCH_DISTRUBUTION_CENTER_DATA'
};

export function* fetchDistrubutionCenterData(): any {
  try {
    yield put(distrubutionCenterLoading());
    const {data} = yield call(ProductAPIS.getDistrubutionCenterData);
    yield put(distrubutionCenterSuccess(data));
  } catch (error) {
    yield put(distrubutionCenterFailure());
  }
}

export default function* watchFetchdistrubutionCenterData() {
  yield takeEvery(
    distrubutionCenterSagaActions.FETCH_DISTRUBUTION_CENTER_DATA,
    fetchDistrubutionCenterData
  );
}
