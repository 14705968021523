import {createSlice} from '@reduxjs/toolkit';
export interface classOTBState {
  loading: boolean;
  otbData: Array<any>;
  error: string;
}

const initialState: classOTBState = {
  loading: false,
  otbData: [],
  error: ''
};

const classOTBSlice = createSlice({
  name: 'classOTB',
  initialState,
  reducers: {
    classOTBLoading: (state) => {
      state.loading = true;
    },
    classOTBSuccess: (state, action) => {
      state.otbData = action.payload;
      state.loading = false;
    },
    classOTBFailure: (state) => {
      state.loading = false;
      state.otbData = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default classOTBSlice.reducer;
export const {classOTBLoading, classOTBSuccess, classOTBFailure} = classOTBSlice.actions;
