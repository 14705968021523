import {createSlice} from '@reduxjs/toolkit';
export interface inventoryState {
  loading: {[key: string]: boolean};
  inventory: {[key: string]: Array<any>};
  error: string;
}

const initialState: inventoryState = {
  loading: {},
  inventory: {},
  error: ''
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    inventoryLoading: (state, action) => {
      state.loading[action.payload.type] = true;
    },
    inventorySuccess: (state, action) => {
      state.inventory[action.payload.type] = action.payload.result;
      state.loading[action.payload.type] = false;
      state.error = '';
    },
    inventoryFailure: (state, action) => {
      state.loading[action.payload.type] = false;
      state.inventory[action.payload.type] = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default inventorySlice.reducer;
export const {inventoryLoading, inventorySuccess, inventoryFailure} = inventorySlice.actions;
