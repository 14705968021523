import {createSlice} from '@reduxjs/toolkit';
export interface storeState {
  loading: boolean;
  store: Array<any>;
  error: string;
}

const initialState: storeState = {
  loading: false,
  store: [],
  error: ''
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    storeLoading: (state) => {
      state.loading = true;
    },
    storeSuccess: (state, action) => {
      state.store = action.payload;
      state.loading = false;
    },
    storeFailure: (state) => {
      state.loading = false;
      state.store = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default storeSlice.reducer;
export const {storeLoading, storeSuccess, storeFailure} = storeSlice.actions;
