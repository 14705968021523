import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Box, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import {CustomCard} from 'components/baseComponents/customCard';
import CustomButton from 'components/baseComponents/customButton';
import {IColumnCell} from 'components/customTable/types';
import EnhancedTable from 'components/customTable/enhancedTable';
import {useAppDispatch, useAppSelector} from 'store/configureStore';

import {vendorListSagaActions} from 'redux/vendor/list/vendorListSaga';
import {productListSagaActions} from 'redux/product/list/productList/productListSaga';
import {poListSagaActions} from 'redux/purchaseOrder/poList/poListSaga';

interface PropType {
  onClose: () => void;
  searchText: string;
  open: boolean;
}
interface IProductListProps {
  itemId: any;
  itemDescription: any;
  upc: any;
  id: string;
}
interface IVendorListProps {
  vendorId: number;
  vendorName: string;
  id: string;
}
interface IPoListProps {
  poId: number;
  vendorDescription: string;
  id: string;
}
const SearchOverlay: React.FC<PropType> = (props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const generatePayload = (val: string) => {
    const vendorPayload = [];
    const poPayload = [];
    vendorPayload.push({
      key: 'vendorDescription',
      value: val,
      condition: 'like'
    });

    poPayload.push({
      key: 'vendorDescription',
      value: val,
      condition: 'like'
    });
    dispatch({
      type: vendorListSagaActions.FETCH_VENDORLIST_DATA,
      payload: vendorPayload
    });
    dispatch({
      type: productListSagaActions.FETCH_PRODUCTSEARCH_DATA,
      payload: {
        itemName: val,
        filter: []
      }
    });
    dispatch({
      type: poListSagaActions.FETCH_POLIST_DATA,
      payload: poPayload
    });
  };
  React.useEffect(() => {
    if (props.searchText != '' && props.searchText.length >= 3) {
      generatePayload(props.searchText);
    }
  }, [props.searchText]);

  const closeWindow = () => {
    props.onClose();
  };

  const getSearchText = () => {
    return props.searchText;
  };

  const ItemComponent = (row: any) => {
    if (row.itemDescription.length > 28)
      return (
        <Box
          sx={{
            maxWidth: '210px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box'
          }}
        >
          {row.itemDescription}
        </Box>
      );
    else {
      return <Box sx={{maxWidth: '210px'}}>{row.itemDescription}</Box>;
    }
  };

  const {searchProductList, searchLoading} = useAppSelector((state) => state.productListSlice);
  const {vendorList, loading: vendorLoading} = useAppSelector((state) => state.vendorListSlice);
  const {poList, loading: poLoading} = useAppSelector((state) => state.poListSlice);

  const productData = searchProductList || [];
  const vendorData = vendorList || [];
  const poData = poList || [];

  const navigate = useNavigate();
  const CustomBox = styled(Box)({
    boxSizing: 'border-box',
    position: 'absolute',
    right: '60px',
    zIndex: 9999,
    top: '68px',
    '& .MuiCard-root': {
      padding: '32px 24px !important',
      borderRadius: '5px',
      boxShadow: `0 1px 1px 0 ${theme.palette.neutral?.secondary12}`,
      ' & .MuiCardHeader-root': {
        width: 0,
        height: 0,
        marginBottom: 0
      },
      ' & .MuiCardContent-root': {
        height: '100%',
        maxHeight: 'none',
        '& > .MuiBox-root': {
          overflowY: 'initial',
          height: 'auto',
          paddingRight: 0,
          '& .MuiButton-root:hover': {
            backgroundColor: `${theme.palette.grayscale?.[50]}`
          },
          '& .MuiTableHead-root': {
            display: 'none'
          },
          '& .MuiTableRow-root': {
            height: '48px',
            backgroundColor: `${theme.palette.common.white}`,
            '& td': {
              padding: '0 0 0 16px'
            }
          }
        }
      }
    }
  });
  const CustomBoxItem = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: `${theme.palette.grayscale?.[50]}`,
    padding: '0 16px 0 16px',
    height: '48px'
  });
  /**
   * We are creating this to Navigate on Product details Page.
   */
  const LinkComponent = (row: any) => {
    return (
      <Box
        sx={{
          '& a:hover, a:visited, a:focus': {
            color: theme.palette.primary.main
          },
          textAlign: 'center'
        }}
      >
        <Link
          onClick={() => {
            closeWindow();
          }}
          to={{pathname: `/Product/${row.itemId}`}}
          state={row}
        >
          {row.itemId}
        </Link>{' '}
      </Box>
    );
  };
  /**
   * We are creating this to Navigate on Purchase Order details Page.
   */
  const LinkPoComponent = (row: any) => {
    const itemRowName = row?.poId;
    return (
      <>
        <Link
          onClick={() => {
            closeWindow();
          }}
          to={{pathname: `/PO/${row.poId}`}}
          state={row}
        >
          {itemRowName}
        </Link>{' '}
        {};
      </>
    );
  };
  /**
   * We are creating this to Navigate on Vendor Details Page.
   */
  const LinkVendorComponent = (row: any) => {
    return (
      <>
        <>
          <Link
            onClick={() => {
              closeWindow();
            }}
            to={{pathname: `/Vendor/${row?.vendorId}`}}
            state={{venId: row?.vendorId}}
          >
            {row?.vendor}
          </Link>
        </>
      </>
    );
  };
  const columnCell: IColumnCell<IProductListProps>[] = [
    {
      id: 'itemId',
      name: 'itemId',
      textColor: theme.palette.primary.main,
      customCell: LinkComponent,
      sx: {
        width: '170px'
      }
    },
    {
      id: 'upc',
      name: 'upc',
      cellTextAlign: 'center'
    },
    {
      id: 'itemDescription',
      name: 'itemDescription',
      cellTextAlign: 'center',
      customCell: ItemComponent
    }
  ];

  const vendorColumnCell: IColumnCell<IVendorListProps>[] = [
    {
      id: 'vendorId',
      name: 'vendorId',
      displayName: 'vendorId',
      customCell: LinkVendorComponent,
      textColor: theme.palette.primary.main,
      sx: {
        width: '170px'
      }
    },
    {
      id: 'vendorName',
      name: 'vendorName',
      displayName: 'vendorName',
      cellTextAlign: 'center'
    }
  ];

  const poColumnCell: IColumnCell<IPoListProps>[] = [
    {
      id: 'poId',
      name: 'poId',
      textColor: theme.palette.primary.main,
      customCell: LinkPoComponent,
      sx: {
        width: '170px'
      }
    },
    {
      id: 'vendorDescription',
      name: 'vendorDescription',
      cellTextAlign: 'center'
    }
  ];

  const ProductData = () => {
    if (searchLoading) {
      return (
        <Box
          sx={{
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant='subtitle2'
            textAlign='center'
            sx={{fontWeight: 500, letterSpacing: '0.25px'}}
          >
            loading...
          </Typography>
        </Box>
      );
    } else {
      return productData && productData.length > 0 ? (
        <>
          <EnhancedTable<IProductListProps>
            width={'100%'}
            rowData={productData.slice(0, 3)}
            columnCell={columnCell}
            rowKey={'itemId'}
            defaultSortKey={'itemId'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={'searchProductList'}
          />
        </>
      ) : (
        <Box
          sx={{
            height: '144px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant='subtitle2'
            textAlign='center'
            sx={{fontWeight: 500, letterSpacing: '0.25px'}}
          >
            No Data matching the search key word
          </Typography>
        </Box>
      );
    }
  };

  const VendorData = () => {
    if (vendorLoading) {
      return (
        <Box
          sx={{
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant='subtitle2'
            textAlign='center'
            sx={{fontWeight: 500, letterSpacing: '0.25px'}}
          >
            loading...
          </Typography>
        </Box>
      );
    } else {
      return vendorData && vendorData.length > 0 ? (
        <>
          <EnhancedTable<IVendorListProps>
            width={'100%'}
            rowData={vendorData.slice(0, 3)}
            columnCell={vendorColumnCell}
            rowKey={'vendorId'}
            defaultSortKey={'vendorId'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={'searchvendorList'}
          />
        </>
      ) : (
        <Box
          sx={{
            height: '144px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant='subtitle2'
            textAlign='center'
            sx={{fontWeight: 500, letterSpacing: '0.25px'}}
          >
            No Data matching the search key word
          </Typography>
        </Box>
      );
    }
  };

  const PoData = () => {
    if (poLoading) {
      return (
        <Box
          sx={{
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant='subtitle2'
            textAlign='center'
            sx={{fontWeight: 500, letterSpacing: '0.25px'}}
          >
            loading...
          </Typography>
        </Box>
      );
    } else {
      return poData && poData.length > 0 ? (
        <>
          <EnhancedTable<IPoListProps>
            width={'100%'}
            rowData={poData.slice(0, 3)}
            columnCell={poColumnCell}
            rowKey={'poId'}
            defaultSortKey={'poId'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={'searchpoList'}
          />
        </>
      ) : (
        <Box
          sx={{
            height: '144px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant='subtitle2'
            textAlign='center'
            sx={{fontWeight: 500, letterSpacing: '0.25px'}}
          >
            No Data matching the search key word
          </Typography>
        </Box>
      );
    }
  };

  const Content = (props: any) => {
    const th = props.th;
    return (
      <>
        <CustomBoxItem>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <Typography variant='subtitle2' sx={{color: `${th.palette.grayscale?.[600]}`}}>
              Products
            </Typography>
            <Typography
              variant='caption'
              sx={{
                fontSize: '12px',
                color: `${th.palette.grayscale?.[500]}`,
                marginLeft: '8px'
              }}
            >
              {productData.length > 0
                ? `(Showing ${
                    productData.length > 3 ? productData.slice(0, 3).length : productData.length
                  } of ${productData.length})`
                : ''}
            </Typography>
          </Box>
          <CustomButton
            onClick={() => {
              closeWindow();
              const searchText = getSearchText();
              navigate(`/ProductSearch/${searchText}`, {
                state: {
                  searchText: searchText
                }
              });
            }}
            id='searchTile-product-showAll'
          >
            SHOW ALL
          </CustomButton>
        </CustomBoxItem>
        <ProductData />
        <CustomBoxItem sx={{marginTop: '24px'}}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <Typography variant='subtitle2' sx={{color: `${th.palette.grayscale?.[600]}`}}>
              Vendors
            </Typography>
            <Typography
              variant='caption'
              sx={{
                fontSize: '12px',
                color: `${th.palette.grayscale?.[500]}`,
                marginLeft: '8px'
              }}
            >
              {vendorData.length
                ? `(Showing  ${
                    vendorData.length > 3 ? vendorData.slice(0, 3).length : vendorData.length
                  } of ${vendorData.length})`
                : ''}
            </Typography>
          </Box>
          <CustomButton
            onClick={() => {
              closeWindow();
              navigate('/Vendor');
            }}
            id='searchTile-vendor-showAll'
          >
            SHOW ALL
          </CustomButton>
        </CustomBoxItem>
        <VendorData />
        <CustomBoxItem sx={{marginTop: '24px'}}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <Typography variant='subtitle2' sx={{color: `${th.palette.grayscale?.[600]}`}}>
              POs
            </Typography>
            <Typography
              variant='caption'
              sx={{
                fontSize: '12px',
                color: `${th.palette.grayscale?.[500]}`,
                marginLeft: '8px'
              }}
            >
              {poData.length
                ? `(Showing  ${poData.length > 3 ? poData.slice(0, 3).length : poData.length} of ${
                    poData.length
                  })`
                : ''}
            </Typography>
          </Box>
          <CustomButton
            onClick={() => {
              closeWindow();
              navigate('/PO');
            }}
            id='searchTile-po-showAll'
          >
            SHOW ALL
          </CustomButton>
        </CustomBoxItem>
        <PoData />
      </>
    );
  };
  return (
    <CustomBox>
      <CustomCard
        id='searchOverlay'
        header={<></>}
        content={<Content th={theme} />}
        width='600px'
        height='660px'
      />
    </CustomBox>
  );
};

export default SearchOverlay;
