import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  styled,
  SxProps,
  Table,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  TextFieldProps,
  Theme,
  Typography
} from '@mui/material';
import {PickersDay, StaticDatePicker} from '@mui/x-date-pickers';
import React from 'react';
import {ReactComponent as Calender} from 'assets/images/icons/Icons & UI-Calendar-Calendar_Blue.svg';
import CustomSelect, {ISelectOptions} from 'components/baseComponents/customSelect';
import moment from 'moment';

export interface IDateRangeProps {
  id: string;
  value: string; /// MIN | MAX (pipe is the saperatror)
  name: string;
  title: string;
  format?: string;
  sx?: SxProps<Theme>;
  onChange: (event: any) => void;
  options?: ISelectOptions[];
}

enum filterDateTypes {
  SELECT = 'Select',
  CURRENT_YEAR = 'Current year to date',
  CURRENT_MONTH = 'Current month to date',
  CURRENT_WEEK = 'Current week to date'
}

const quaterText = 'Q';

/**
 * Generate the year options to fill dropdown
 */
const getYearOptions = (additionalOptions: ISelectOptions[] = []) => {
  const options: ISelectOptions[] = [] as ISelectOptions[];
  Object.values(filterDateTypes).forEach((value) => {
    options.push({
      label: value,
      value: value
    } as ISelectOptions);
  });
  const dateOptions: ISelectOptions[] = [...options, ...additionalOptions];

  /// Add the Quarter to options based on current date
  for (let i = 1; i <= moment().quarter(); i++) {
    dateOptions.push({
      label: `${quaterText} ${i}`,
      value: `${quaterText}${i}`
    } as ISelectOptions);
  }
  return dateOptions;
};

export const StyledCalenderIcon = styled((props) => <Calender {...props} />)`
  fill: ${(props) => props.theme.palette.grayscale?.[300]};
`;

const CustomDateRange = (props: IDateRangeProps) => {
  const defaultDateFormat = 'MM/DD/YYYY';
  const value = props.value || null;
  const minFilterValue = value?.split('|')[0] || moment().format(props.format || defaultDateFormat);
  const maxFilterValue = value?.split('|')[1] || moment().format(props.format || defaultDateFormat);
  const [dateSelection, setDateSelection] = React.useState<string>(filterDateTypes.CURRENT_WEEK);
  const [buttonDisableStatus, setButtonDisableStatus] = React.useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [minValue, setMinValue] = React.useState<string | null>(
    moment(minFilterValue).format(props.format || defaultDateFormat)
  );
  const [maxValue, setMaxValue] = React.useState<string | null>(
    moment(maxFilterValue).format(props.format || defaultDateFormat)
  );

  const [selectedMinValue, setSelectedMinValue] = React.useState<string | null>(
    moment(minFilterValue, defaultDateFormat).format(props.format || defaultDateFormat)
  );
  const [selectedMaxValue, setSelectedMaxValue] = React.useState<string | null>(
    moment(maxFilterValue, defaultDateFormat).format(props.format || defaultDateFormat)
  );

  React.useEffect(() => {
    try {
      const value = props.value || null;
      const minValue = value?.split('|')[0] || null;
      const maxValue = value?.split('|')[1] || null;
      setMinValue(minValue);
      setMaxValue(maxValue);
      setSelectedMinValue(minValue);
      setSelectedMaxValue(maxValue);
    } catch (e) {
      return e;
    }
  }, [props.value]);
  React.useEffect(() => {
    selectedMinValue === minValue && selectedMaxValue === maxValue
      ? setButtonDisableStatus(true)
      : setButtonDisableStatus(false);
  }, [selectedMinValue, selectedMaxValue]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'customDateRage-popover' : undefined;

  /**
   * On Apply date function
   */
  const handleDateSelection = () => {
    const target = {
      name: props.name,
      value: selectedMinValue + '|' + selectedMaxValue
    };
    props.onChange({target});
    handleClose();
    setButtonDisableStatus(true);
  };

  /**
   * Update the calender based on quick selection
   * @param e selected event
   */
  const onDateQuickSelection = (e: any) => {
    const value: string = e.target.value;
    let startDate, endDate;
    if (value) {
      switch (value) {
        case filterDateTypes.CURRENT_YEAR:
          startDate = moment()
            .startOf('year')
            .format(props.format || defaultDateFormat);
          endDate = moment().format(props.format || defaultDateFormat);
          break;
        case filterDateTypes.CURRENT_MONTH:
          startDate = moment()
            .startOf('month')
            .format(props.format || defaultDateFormat);
          endDate = moment().format(props.format || defaultDateFormat);
          break;
        case filterDateTypes.CURRENT_WEEK:
          startDate = moment()
            .startOf('week')
            .format(props.format || defaultDateFormat);
          endDate = moment().format(props.format || defaultDateFormat);
          break;
        case 'Last 6 months':
          startDate = moment()
            .subtract(6, 'months')
            .startOf('month')
            .format(props.format || defaultDateFormat);
          endDate = moment().format(props.format || defaultDateFormat);
          break;
        case 'Last 4 weeks':
          startDate = moment()
            .subtract(4, 'week')
            .startOf('week')
            .format(props.format || defaultDateFormat);
          endDate = moment().format(props.format || defaultDateFormat);
          break;
        case 'Last quarter':
          startDate = moment()
            .subtract(1, 'quarter')
            .startOf('quarter')
            .format(props.format || defaultDateFormat);
          endDate = moment()
            .subtract(1, 'quarter')
            .endOf('quarter')
            .format(props.format || defaultDateFormat);
          break;
        default:
          ////Check if current quarter is same as option selected
          if (moment().quarter() === Number(value.replace(quaterText, ''))) {
            startDate = moment()
              .quarter(moment().quarter())
              .startOf(quaterText)
              .format(props.format || defaultDateFormat);
            endDate = moment().format(props.format || defaultDateFormat);
          } else {
            startDate = moment()
              .quarter(Number(value.replace(quaterText, '')))
              .startOf(quaterText)
              .format(props.format || defaultDateFormat);
            endDate = moment()
              .quarter(Number(value.replace(quaterText, '')))
              .endOf(quaterText)
              .format(props.format || defaultDateFormat);
          }
          break;
      }
      setSelectedMinValue(startDate);
      setSelectedMaxValue(endDate);
      setDateSelection(value);
    }
  };

  return (
    <>
      <Grid container sx={props.sx} id={`customDateRange-${props.id}`}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: props.title === '' ? 0 : 2}}>
          {props.title && (
            <Typography
              id={`label-text-${props.id}`}
              sx={{
                p: 0,
                m: 0,
                fontWeight: 'bold',
                color: (theme) => theme.palette.grayscale?.[600],
                fontSize: '12px'
              }}
              textAlign='left'
            >
              {props.title}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container sx={{p: '3px 0 5px 0', alignItems: 'end'}}>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{m: 0}}>
              <Typography
                variant='body2'
                sx={{
                  pl: 0,
                  m: 0,
                  color: minValue === null ? 'bpGreyBorder.main' : 'inherit'
                }}
              >
                {minValue === null ? 'mm/dd/yyyy' : minValue} {maxValue !== null && `- ${maxValue}`}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{textAlign: 'center'}}>
              <IconButton onClick={handleClick} sx={{padding: 0}}>
                <StyledCalenderIcon />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <Table
                  sx={{
                    margin: 2,
                    width: '300px',
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: 'none',
                      padding: 1
                    }
                  }}
                >
                  <TableRow>
                    <TableCell colSpan={2}>
                      <CustomSelect
                        id='custom-select-date-range'
                        options={getYearOptions(props?.options || [])}
                        title='Quick selection'
                        onChange={onDateQuickSelection}
                        value={dateSelection}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>FROM</TableCell>
                    <TableCell>TO</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <CustomStaticDatePicker
                        displayStaticWrapperAs='desktop'
                        openTo='day'
                        views={['year', 'month', 'day']}
                        value={selectedMinValue}
                        maxDate={moment()}
                        onChange={(newValue: any) => {
                          setDateSelection('Select');
                          setSelectedMinValue(
                            moment(newValue).format(props.format || defaultDateFormat)
                          );
                        }}
                        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                          <TextField {...params} type='date' />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomStaticDatePicker
                        displayStaticWrapperAs='desktop'
                        openTo='day'
                        views={['year', 'month', 'day']}
                        maxDate={moment()}
                        minDate={moment(selectedMinValue)}
                        value={selectedMaxValue}
                        onChange={(newValue: any) => {
                          setDateSelection('Select');
                          setSelectedMaxValue(
                            moment(newValue).format(props.format || defaultDateFormat)
                          );
                        }}
                        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                          <TextField {...params} type='date' />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                      <Button
                        disableElevation
                        variant={'contained'}
                        id={`date-filter-apply-selection-${props.id}`}
                        onClick={handleDateSelection}
                        disabled={buttonDisableStatus}
                        sx={{
                          bgcolor: (theme) => theme.palette.primary.main,
                          fontFamily: 'Roboto Regular',
                          color: 'common.whirete',
                          '&.Mui-disabled': {
                            fontFamily: 'Roboto Regular',
                            bgcolor: (theme) => theme.palette.grayscale?.[200]
                          }
                        }}
                      >
                        APPLY SELECTION
                      </Button>
                    </TableCell>
                  </TableRow>
                </Table>
              </Popover>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const CustomPickersDay = styled(Box)(({theme}: any) => ({
  ...{
    '.MuiPickersDay-root': {
      borderRadius: 0,
      color: theme.palette.primary.main,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.contrastText
      }
    },
    '.Mui-selected': {
      backgroundColor: theme.palette.primary.contrastText
    },
    '.MuiPickersDay-dayOutsideMonth': {
      color: theme.palette.grayscale[200]
    }
  }
})) as React.ComponentType<any>;

const customDayRender = (day: any, _value: any, DayComponentProps: any) => {
  return (
    <>
      <CustomPickersDay>
        <PickersDay {...DayComponentProps} />
      </CustomPickersDay>
    </>
  );
};

export const CustomStaticDatePicker = (props: any) => {
  return (
    <StaticDatePicker
      autoFocus={true}
      {...props}
      disableHighlightToday
      renderDay={props?.renderDay ? props.renderDay : customDayRender}
    ></StaticDatePicker>
  );
};

export default CustomDateRange;
