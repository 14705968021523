import {Box, Button, Divider, Grid} from '@mui/material';
import React from 'react';
import BarGraphComponent, {IAxis} from 'components/barGraph';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomSelect from 'components/baseComponents/customSelect';
import {styled} from '@mui/material/styles';
import {CustomCard} from 'components/baseComponents/customCard';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {classOTBSagaActions} from 'redux/dashboard/otb/classOTBSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {generatePayload} from 'util/arrayOperations';
import {DropDownWrapper} from 'util/commonStyles';
import {useTheme} from '@emotion/react';

interface IPropType {
  filteredData: any;
  defaultValue: string;
  data?: any;
}

const dropDownContent = [
  {
    label: 'Class OTB',
    value: 'class'
  },
  {
    label: 'Dept OTB',
    value: 'department'
  },
  {
    label: 'Dist OTB',
    value: 'distribution'
  }
];

const dateDropDownData = [
  {
    label: 'Last Week',
    value: 'lastWeek'
  },
  {
    label: 'Last 4 Weeks',
    value: 'last4Weeks'
  },
  {
    label: 'Month-to-Date',
    value: 'monthToDate'
  },
  {
    label: 'Quarter-to-Date',
    value: 'quarterToDate'
  },
  {
    label: 'Year-to-Date',
    value: 'yearToDate'
  }
];

const subclassesOptions = [
  {
    label: 'Snacks & chips',
    value: 'Snacks & chips'
  },
  {
    label: 'Soda & water',
    value: 'Soda & water'
  }
];

const OTBTile: React.FC<IPropType> = (props) => {
  const [otbComp, setOTBComp] = React.useState(props?.defaultValue);
  const dispatch: any = useAppDispatch();
  /**
   * dispatch OTB tile data
   */
  const dispatchData = () => {
    dispatch({
      type: classOTBSagaActions.FETCH_CLASSOTB_DATA,
      payload: {
        type: otbComp,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [props?.filteredData, otbComp]);
  const {loading, error, otbData} = useAppSelector((state) => state.classOTBSlice);
  const [showDivider, setShowDivider] = React.useState(true);
  const totalData = otbData && otbData?.filter((elem: any) => elem.cost > 0);
  const [positiveData, setPositiveData] = React.useState<Array<any>>([]);
  const [negativeData, setNegativeData] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    setPositiveData(otbData && otbData?.filter((elem: any) => elem.cost > 0).slice(0, 3));
    setNegativeData(otbData && otbData?.filter((elem: any) => elem.cost < 0).slice(0, 2));
  }, [otbData]);

  const totalCost = totalData?.reduce((acc: number, curr: any) => {
    acc += curr?.cost;
    return acc;
  }, 0);

  const [dateHistory, setDateHistory] = React.useState('lastWeek');

  const [subClasses, setSubClasses] = React.useState('Snacks & chips');
  const theme: any = useTheme();

  const CustomSpan = styled('span')({
    color: theme.palette?.common.black,
    marginLeft: '8px'
  });

  const handleSelect = (event: any): void => {
    dispatch({
      type: classOTBSagaActions.FETCH_CLASSOTB_DATA,
      payload: {
        ...props?.filteredData,
        type: event?.target?.value
      }
    });
    setOTBComp(event?.target?.value);
  };

  const handleDateSelect = (event: any): void => {
    setDateHistory(event?.target?.value);
  };

  const handleSubClassesSelect = (event: any): void => {
    setSubClasses(event?.target?.value);
  };

  const handlePositive = () => {
    setPositiveData(otbData?.filter((elem: any) => elem.cost > 0));
    setNegativeData([]);
    setShowDivider(false);
  };

  const handleNegative = () => {
    setNegativeData(otbData?.filter((elem: any) => elem.cost < 0));
    setPositiveData([]);
    setShowDivider(false);
  };

  const xAxis: Array<IAxis> = [
    {
      name: 'cost',
      displayTitle: 'Cost',
      color: '#263EB5',
      unit: 'K'
    }
  ];

  const classYAxis = {
    name: 'className',
    displayTitle: 'name',
    unit: 'K'
  };
  const departmentYAxis = {
    name: 'departmentName',
    displayTitle: 'name',
    unit: 'K'
  };
  const DistYAxis = {
    name: 'warehouseName',
    displayTitle: 'name',
    unit: 'K'
  };

  const layout = {
    height: 100,
    width: 340,
    margin: {
      l: 150,
      r: 5,
      b: 5,
      t: 0,
      pad: 1
    }
  } as Plotly.Layout;

  const traceProps = {
    width: 0.5
  };

  const generateContent = () => {
    const distposqueryArgs = {
      selectTabNo: 1
    };
    switch (otbComp) {
      case 'department':
        return (
          <BarGraphComponent
            data={[...negativeData, ...positiveData]}
            traceProps={traceProps}
            layout={layout}
            xAxis={[...xAxis]}
            yAxis={departmentYAxis}
            redirectUrl={'products'}
            id='otbCompTileDeptCompBar'
            isFixedBarSize={true}
          />
        );
      case 'distribution':
        return (
          <BarGraphComponent
            data={[...negativeData, ...positiveData]}
            traceProps={traceProps}
            layout={layout}
            xAxis={[...xAxis]}
            yAxis={DistYAxis}
            redirectUrl={'products'}
            queryData={distposqueryArgs}
            id='otbCompTileDistCompBar'
            isFixedBarSize={true}
          />
        );
      default:
        return (
          <BarGraphComponent
            data={[...negativeData, ...positiveData]}
            traceProps={traceProps}
            layout={layout}
            xAxis={[...xAxis]}
            yAxis={classYAxis}
            redirectUrl={'products'}
            id='otbCompTileClassCompBar'
            isFixedBarSize={true}
          />
        );
    }
  };
  const Header = () => {
    return (
      <>
        <Grid container>
          <DropDownWrapper>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <CustomSelect
                id='otbTile'
                options={dropDownContent}
                value={otbComp}
                onChange={handleSelect}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{mt: -1}}>
              {otbComp == 'class' && (
                <CustomSelect
                  id='otbSubclassesSelect'
                  name={'otbDashboardTileClassesSelect'}
                  options={subclassesOptions}
                  onChange={handleSubClassesSelect}
                  title='View Subclasses Under'
                  value={subClasses}
                />
              )}
              {otbComp !== 'class' && (
                <CustomSelect
                  id='dateTile'
                  name={'otbDashboardTileDateSelect'}
                  options={dateDropDownData}
                  onChange={handleDateSelect}
                  title='Time frame'
                  value={dateHistory}
                />
              )}
            </Grid>
          </DropDownWrapper>
        </Grid>
      </>
    );
  };

  const Content = () => {
    return (
      <Grid container>
        <Box sx={{width: '100%'}}>
          <Grid
            sx={{
              backgroundColor: '#E7F2FF',
              height: '22px',
              lineHeight: '22px',
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#102B61',
              textAlign: 'center'
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            TOTAL <CustomSpan>{`$${totalCost}`}</CustomSpan>
          </Grid>
        </Box>
        <Box
          sx={{
            overflowY: 'scroll',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px'
          }}
        >
          {generateContent()}
        </Box>
        <Box sx={{width: '100%'}}>
          <Box sx={{position: 'relative', bottom: '100px'}}>
            {showDivider && (
              <Divider
                sx={{
                  '&.MuiDivider-root': {
                    '&::before ': {
                      borderTop: 'thin solid',
                      borderColor: '#6E798F',
                      padding: 0
                    },
                    '&::after': {
                      borderTop: 'thin solid',
                      borderColor: '#6E798F',
                      padding: 0
                    },
                    padding: 0,
                    bottom: '20px'
                  }
                }}
                variant='middle'
              >
                <Box sx={{height: '15px'}}>
                  <Button sx={{margin: 0, padding: 0, color: '#6E798F'}} onClick={handleNegative}>
                    <KeyboardArrowUpIcon sx={{margin: 0}} />
                  </Button>
                </Box>
                <Box>
                  <Button sx={{margin: 0, padding: 0, color: '#6E798F'}} onClick={handlePositive}>
                    <KeyboardArrowDownIcon sx={{margin: 0}} />
                  </Button>
                </Box>
              </Divider>
            )}
          </Box>
        </Box>
      </Grid>
    );
  };

  if (otbData && otbData.length > 0 && !error) {
    return (
      <Box
        sx={{
          '& .MuiPaper-root .MuiCardContent-root > div': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: loading || error ? 'center' : 'flex-start',
            height: '270px'
          }
        }}
      >
        <CustomCard
          id='otbTile-data'
          header={<Header />}
          content={loading ? <CustomLoader id='otb-load' /> : <Content />}
          headerSx={{
            height: '64px',
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            boxSizing: 'border-box',
            marginBottom: 0
          }}
        />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          '& .MuiPaper-root .MuiCardContent-root > div': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: loading || error ? 'center' : 'flex-start'
          }
        }}
      >
        <CustomCard
          id='otbTile-withoutData'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='otb-load' />
            ) : (
              <CustomError
                id='otb-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
          headerSx={{
            height: '64px',
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            boxSizing: 'border-box'
          }}
        />
      </Box>
    );
  }
};

export default OTBTile;
