import {createSlice} from '@reduxjs/toolkit';
export interface onOrderRcvgItemState {
  loading: boolean;
  onOrderRcvgItem: Array<any>;
  error: string;
}

const initialState: onOrderRcvgItemState = {
  loading: false,
  onOrderRcvgItem: [],
  error: ''
};

const onOrderRcvgItemSlice = createSlice({
  name: 'onOrderRcvgItem',
  initialState,
  reducers: {
    onOrderRcvgItemLoading: (state) => {
      state.loading = true;
    },
    onOrderRcvgItemSuccess: (state, action) => {
      state.onOrderRcvgItem = action.payload;
      state.loading = false;
    },
    onOrderRcvgItemFailure: (state) => {
      state.loading = false;
      state.onOrderRcvgItem = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default onOrderRcvgItemSlice.reducer;
export const {onOrderRcvgItemLoading, onOrderRcvgItemSuccess, onOrderRcvgItemFailure} =
  onOrderRcvgItemSlice.actions;
