import {takeEvery, put, call} from 'redux-saga/effects';
import {VendorAPIS} from 'redux/vendor/vendorDetails_service';
import {
  vendorListFailure,
  vendorListLoading,
  vendorListSuccess
} from 'redux/vendor/list/vendorListSlice';

export const vendorListSagaActions = {
  FETCH_VENDORLIST_DATA: 'FETCH_VENDORLIST_DATA'
};

export function* fetchVendorListData(action: any): any {
  try {
    yield put(vendorListLoading());
    const {data} = yield call(VendorAPIS.getVendorListData, action.payload);
    yield put(vendorListSuccess(data));
  } catch (error) {
    yield put(vendorListFailure());
  }
}

export default function* watchFetchVendorListData() {
  yield takeEvery(vendorListSagaActions.FETCH_VENDORLIST_DATA, fetchVendorListData);
}
