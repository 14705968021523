import {takeEvery, put, call} from 'redux-saga/effects';
import {PurchaseOrderAPIS} from 'redux/purchaseOrder/purchaseOrder_service';
import {
  poItemsFailure,
  poItemsLoading,
  poItemsSuccess
} from 'redux/purchaseOrder/details/items/poItemsSlice';

export const poItemsSagaActions = {
  FETCH_POITEMS_DATA: 'FETCH_POITEMS_DATA'
};

export function* fetchPoItemsData(action: any): any {
  try {
    yield put(poItemsLoading());
    const {data} = yield call(PurchaseOrderAPIS.getPoItemsData, action.payload);
    yield put(poItemsSuccess(data));
  } catch (error) {
    yield put(poItemsFailure());
  }
}

export default function* watchFetchPoItemsData() {
  yield takeEvery(poItemsSagaActions.FETCH_POITEMS_DATA, fetchPoItemsData);
}
