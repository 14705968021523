import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  Checkbox,
  Box,
  Paper,
  Popover,
  Typography,
  SxProps,
  IconButton,
  useTheme,
  Grid,
  Stack
} from '@mui/material';
import * as React from 'react';
import {TABLE_DEFAULTROWSPERPAGE} from 'config/constants';
import {descendingComparator} from 'util/arrayOperations';
import {StickyCell, StyledTableRow, TableCellHOC} from './tableCell';
import {EnhancedTableHead} from 'components/customTable/tableHead';
import {EnhancedTablePagination} from 'components/customTable/tablePagingation';
import {IColumnCell, IOrder} from 'components/customTable/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FieldProvider from 'util/fieldProvider';
import StyledChip from 'components/baseComponents/customChip';

export type IGridProps<T> = {
  rowData: T[];
  columnCell: IColumnCell<T>[];
  rowKey: keyof T;
  defaultSortKey: keyof T;
  defaultSortOrder?: 'desc' | 'asc';
  order?: IOrder;
  selectable?: boolean;
  hidePagination?: boolean;
  stickyRows?: React.ReactElement;
  showAlertPopup?: boolean;
  showTags?: boolean;
  width?: number | string;
  height?: number | string;
  allowAlternateColor?: boolean;
  dense?: boolean;
  componentType?: 'box' | 'paper';
  rowsPerPage?: number;
  borderless?: boolean;
  getSelectedIds?: (selected: readonly string[]) => void;
  paginationSx?: SxProps;
  isEditable?: boolean;
  id: string;
} & IRowGroupProps;

////For row grouping
type IRowGroupProps =
  | {
      allowRowGrouping?: false;
      rowGroupTemplate?: never;
    }
  | {
      allowRowGrouping: true;
      rowGroupTemplate: React.FC;
    };

export default function EnhancedTable<T>(props: IGridProps<T>) {
  const theme = useTheme();
  const [order, setOrder] = React.useState<IOrder>(props?.defaultSortOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState<keyof T>(props?.defaultSortKey);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.hidePagination
      ? props.rowData.length
      : props.rowsPerPage
      ? props.rowsPerPage
      : TABLE_DEFAULTROWSPERPAGE
  );
  const ColumnLabelStack = (tags: any, classID: string, subClassID: string) => {
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchor(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchor(null);
    };
    const open = Boolean(anchor);
    let updatedTags = [...tags];
    updatedTags = updatedTags.length < 3 ? updatedTags : updatedTags.splice(0, 2);
    return (
      <Stack sx={{flexGrow: 1, maxHeight: 2}} direction='row' spacing={1}>
        <Typography
          variant='caption'
          sx={{fontSize: '13px', opacity: 0.5, fontWeight: 'bold', lineHeight: 1.2}}
          textAlign='center'
        >
          {classID && subClassID ? `${classID}/${subClassID}` : null}
        </Typography>
        {updatedTags.map((name: any) => {
          return (
            <StyledChip
              key={`${name}chip`}
              label={
                <Typography
                  variant='caption'
                  sx={{
                    fontSize: '10px',
                    color: 'primary.main',
                    fontWeight: 'bold'
                  }}
                  textAlign='center'
                >
                  {name}
                </Typography>
              }
            />
          );
        })}
        {tags.length > 2 ? (
          <Typography
            variant='caption'
            sx={{
              fontSize: '10px',
              color: 'primary.main',
              fontWeight: 'bold'
            }}
            textAlign='center'
          >
            <Box
              id='moretagspopover'
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup='true'
              onMouseEnter={(e) => handlePopoverOpen(e)}
              onMouseLeave={handlePopoverClose}
            >
              +{tags.length - 2}
            </Box>
            <Popover
              id='mouse-over-addtionaltags'
              elevation={1}
              sx={{
                boxShadow: 'unset',
                pointerEvents: 'none'
              }}
              open={open}
              anchorEl={anchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus={true}
            >
              <Grid direction='row' sx={{marginLeft: 2}} container>
                <Grid item md={12}>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      fontFamily: 'Roboto Medium',
                      fontWeight: 'bold',
                      fontSize: 10,
                      paddingBottom: 1,
                      marginTop: 2
                    }}
                    textAlign='left'
                  >
                    Additional Tags
                  </Typography>
                </Grid>
                {tags.slice(2).map((name: any) => {
                  return (
                    <Grid key={`${name}chip`} md={12}>
                      <Typography
                        variant='caption'
                        sx={{
                          backgroundColor: theme.palette.primary.contrastText,
                          fontSize: '10px',
                          color: 'primary.main',
                          fontWeight: 'bold',
                          display: 'unset',
                          paddingBottom: 1,
                          padding: '0 12px'
                        }}
                        textAlign='center'
                      >
                        {name}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Popover>
          </Typography>
        ) : null}
      </Stack>
    );
  };
  const AlertBox = (props: any) => {
    const [anchorALert, setAnchorAlert] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpenAlert = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorAlert(event.currentTarget);
    };
    const handlePopoverCloseAlert = () => {
      setAnchorAlert(null);
    };
    const openAlert = Boolean(anchorALert);
    const numOfAlerts = props?.alert?.length;
    return (
      <>
        <Box
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup='true'
          onMouseEnter={(e) => handlePopoverOpenAlert(e)}
          onMouseLeave={handlePopoverCloseAlert}
          sx={{
            width: '4px',
            height: props.dense ? '32px' : '55px',
            backgroundColor: 'error.main',
            verticalAlign: 'middle',
            cursor: 'pointer',
            mr: '6px'
          }}
        >
          &nbsp;
        </Box>
        <Popover
          id={`mouse-over-popo`}
          elevation={1}
          sx={{
            boxShadow: 'unset',
            pointerEvents: 'none'
          }}
          open={openAlert}
          anchorEl={anchorALert}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverCloseAlert}
        >
          <Box sx={{p: 1, width: 'fit-content', maxWidth: '150px'}}>
            <Typography
              variant='body1'
              sx={{
                p: 1,
                fontWeight: 600,
                color: theme.palette?.grayscale?.[600]
              }}
            >
              {numOfAlerts} {numOfAlerts === 1 ? 'Alert' : 'Alerts'}
            </Typography>
            {props?.alert.map((value: any) => {
              return (
                <Typography key='1' variant='body1' sx={{pl: 1}}>
                  {value}
                </Typography>
              );
            })}
          </Box>
        </Popover>
      </>
    );
  };
  const EmptyBox = () => {
    return (
      <>
        <Box
          sx={{
            width: '4px',
            height: props.dense ? '32px' : '55px',
            backgroundColor: 'inherit',
            verticalAlign: 'middle',
            mr: '6px'
          }}
        >
          &nbsp;
        </Box>
      </>
    );
  };
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.rowData.map((n) => n[props.rowKey] as unknown as string);
      props.getSelectedIds && props.getSelectedIds(newSelecteds);
      setSelected(newSelecteds);
      return;
    }
    props.getSelectedIds && props.getSelectedIds([]);
    setSelected([]);
  };

  /**
   * Handle Row checkbox click event
   * @param event Event of checkbox
   * @param name name of the row
   */
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    props.getSelectedIds && props.getSelectedIds(newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  function getComparator<Key extends keyof T>(
    order: IOrder,
    orderBy: Key
  ): (a: {[key in Key]: any}, b: {[key in Key]: any}) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const stickyColumnsCell = props?.columnCell.filter((ele) => (ele.sticky ? true : false));
  const nonStickyColumnsCell = props?.columnCell.filter((ele) => (ele.sticky ? false : true));

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const open = Boolean(anchorEl);

  //////For rowGroup Open/close State for multiple rows expand
  const [rowGroupSelect, setRowGroupSelect] = React.useState<readonly string[]>([]);
  const isRowGroupSelected = (name: string) => rowGroupSelect.indexOf(name) !== -1;
  /**
   * Handle Row checkbox click event
   * @param event Event of checkbox
   * @param name name of the row
   */
  const handleRowGroupClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = rowGroupSelect.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(rowGroupSelect, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(rowGroupSelect.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(rowGroupSelect.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        rowGroupSelect.slice(0, selectedIndex),
        rowGroupSelect.slice(selectedIndex + 1)
      );
    }
    setRowGroupSelect(newSelected);
  };

  /* function to round off decimal number to hundredth place*/
  const valueSanitizer = (data: any) => {
    let sanitizedData: any = data;
    const regex = /(\d)(?=(\d{3})+\.\d\d$)/g;
    const replaceWith = '$1,';
    if (
      typeof sanitizedData === 'number' &&
      sanitizedData.toString().indexOf('.') > 0 &&
      sanitizedData.toString().length - sanitizedData.toString().indexOf('.') > 2
    ) {
      sanitizedData = +sanitizedData.toFixed(2);
      sanitizedData = Number(sanitizedData).toLocaleString();
    } else if (typeof sanitizedData === 'string') {
      if (sanitizedData.indexOf('$') < 0) {
        if (
          // eslint-disable-next-line use-isnan
          !isNaN(parseFloat(sanitizedData)) &&
          sanitizedData.indexOf('.') > 0 &&
          sanitizedData.length - sanitizedData.indexOf('.') > 2
        ) {
          sanitizedData = +Number(sanitizedData).toFixed(2);
          sanitizedData = sanitizedData.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
        } else {
          return Number(sanitizedData)?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
        }
      } else {
        sanitizedData.startsWith('$') &&
        // eslint-disable-next-line use-isnan
        !isNaN(parseFloat(sanitizedData.slice(1))) &&
        sanitizedData.indexOf('.') > 0 &&
        sanitizedData.length - sanitizedData.indexOf('.') > 2
          ? (sanitizedData = `$${Number(sanitizedData.slice(1))
              .toFixed(2)
              .replace(regex, replaceWith)}`)
          : sanitizedData;
      }
    } else {
      return sanitizedData;
    }

    return sanitizedData;
  };

  return (
    <>
      <TableContainer
        component={props.componentType === 'box' ? Box : Paper}
        sx={{
          maxHeight: props.height ? props.height : 'auto',
          '&.MuiPaper-root': {
            borderRadius: 0
          }
        }}
      >
        <Table
          sx={{minWidth: props.width ? props.width : 600}}
          size={props.dense ? 'small' : 'medium'}
          stickyHeader
        >
          <EnhancedTableHead
            id={props?.id}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy as string}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.rowData.length}
            columnCell={props.columnCell}
            selectable={props.selectable}
            denseHeader={props.dense}
            borderless={props.borderless ?? false}
            allowRowGrouping={props?.allowRowGrouping}
          />

          {/* stickyRows */}
          {props.stickyRows ? <>{props.stickyRows}</> : null}

          <TableBody>
            {props.rowData
              .slice()
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index) => {
                const isItemSelected = isSelected(row[props.rowKey] as string);
                const labelId = `enhanced-table-checkbox-${index}`;
                const renderCheckbox = (
                  <Box sx={{display: 'flex'}}>
                    {props.showAlertPopup && row.alert && row.alert.length > 0 ? (
                      <AlertBox alert={row.alert} />
                    ) : (
                      <EmptyBox />
                    )}
                    {props.selectable && (
                      <Checkbox
                        id={`common-check-box-${labelId}`}
                        key={labelId}
                        checked={isItemSelected}
                        sx={{
                          padding: 0,
                          margin: 0,
                          marginRight: 1.5,
                          borderRadius: 0,
                          minWidth: '32px'
                        }}
                        inputProps={{
                          'aria-labelledby': labelId
                        }}
                      />
                    )}
                  </Box>
                );

                ///////Row Group based on row, display arraow icon cell
                const isRowGroupOpen = isRowGroupSelected(row[props.rowKey] as string);

                const RenderRowGroupColumn = (
                  <IconButton
                    id={`arrow-up-down-button-${props.id}`}
                    aria-label='expand row'
                    size='small'
                    onClick={(event) => handleRowGroupClick(event, row[props.rowKey] as string)}
                  >
                    {isRowGroupOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                );

                return (
                  <>
                    <StyledTableRow
                      sx={
                        props.allowAlternateColor
                          ? {
                              height: props.dense ? '32px' : '55px',
                              whiteSpace: 'nowrap',
                              backgroundColor: 'common.white',
                              '&:nth-of-type(even)': {
                                backgroundColor: 'grayscale.50'
                              }
                            }
                          : {
                              height: props.dense ? '32px' : '55px',
                              whiteSpace: 'nowrap',
                              backgroundColor: 'common.white'
                            }
                      }
                      onClick={(event: any) => handleClick(event, row[props.rowKey] as string)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[props.rowKey] as string}
                      selected={isItemSelected}
                    >
                      {stickyColumnsCell.length === 0 && props.selectable ? (
                        <TableCell padding='none'>{renderCheckbox}</TableCell>
                      ) : null}

                      {/* Row grouping icon enable based on props */}
                      {props.allowRowGrouping ? (
                        <TableCell padding='none'>{RenderRowGroupColumn}</TableCell>
                      ) : null}

                      {/* Checkbox with sticky column  */}
                      {stickyColumnsCell.length > 0 ? (
                        <StickyCell>
                          {props.selectable ? (
                            <TableCell padding='none' sx={{borderBottom: 0}}>
                              {renderCheckbox}
                            </TableCell>
                          ) : null}
                          {stickyColumnsCell.map((item: IColumnCell<T>, index: number) => {
                            /////// Check if custom cell exists if not render normal text
                            const renderCell = item.customCell
                              ? item.customCell(row)
                              : `${item.prefixText && row[item.id] ? item.prefixText : ''}${
                                  row[item.id]
                                    ? item.isNumeric
                                      ? valueSanitizer(row[item.id])
                                      : row[item.id]
                                    : ''
                                } ${item.suffixText && row[item.id] ? item.suffixText : ''}`;
                            const showTags =
                              props.showTags && item?.hasTags && row?.tags
                                ? row?.classID && row?.subClassID
                                  ? ColumnLabelStack(row.tags, row.classID, row.subClassID)
                                  : ColumnLabelStack(row.tags, '', '')
                                : null;
                            return (
                              <>
                                <TableCellHOC
                                  sx={[{borderBottom: 0}, item.sx && item.sx]}
                                  isGroupBorderRequire={false}
                                  key={`enhanced-table-cell-${index}`}
                                  align={item.isNumeric ? item?.cellTextAlign || 'right' : 'left'}
                                >
                                  <Typography
                                    variant='body2'
                                    color={item.textColor ?? ''}
                                    sx={
                                      item.hasTags
                                        ? {
                                            mt: row.tags ? '-16px' : 'initial',
                                            pb: row.tags ? '4px' : 'initial'
                                          }
                                        : {}
                                    }
                                  >
                                    {renderCell}
                                  </Typography>
                                  {showTags}
                                </TableCellHOC>
                              </>
                            );
                          })}
                        </StickyCell>
                      ) : null}

                      {nonStickyColumnsCell.map((item: IColumnCell<T>, index: number) => {
                        /////// Check if custom cell exists if not render normal text
                        const renderCell = item.customCell
                          ? item.customCell(row)
                          : `${
                              item.prefixText &&
                              typeof row[item.id] === 'string' &&
                              row[item.id].trim() !== ''
                                ? item.prefixText
                                : ''
                            }${
                              row[item.id]
                                ? item.isNumeric
                                  ? valueSanitizer(row[item.id])
                                  : row[item.id]
                                : ''
                            }${
                              item.suffixText &&
                              typeof row[item.id] === 'string' &&
                              row[item.id].trim() !== ''
                                ? item.suffixText
                                : ''
                            }`;
                        const showTags =
                          props.showTags && item?.hasTags && row?.tags
                            ? row?.classID && row?.subClassID
                              ? ColumnLabelStack(row.tags, row.classID, row.subClassID)
                              : ColumnLabelStack(row.tags, '', '')
                            : null;
                        return (
                          <>
                            {item.isGroupedCell ? (
                              <>
                                {item?.groupedKeys?.map((elem: any, i: number) => {
                                  return (
                                    <>
                                      {item.groupedKeys?.length === i + 1 ? (
                                        <>
                                          <TableCellHOC
                                            id={`enhanced-table-cell-${index}-e-${row[item.id]}`}
                                            isGroupBorderRequire={true}
                                            key={`enhanced-table-cell-${index}-e-${i}`}
                                            align={item.isNumeric ? 'center' : 'left'}
                                            sticky={item?.sticky ? item.sticky : false}
                                            sx={item.sx ? item.sx : {}}
                                          >
                                            <Typography
                                              variant='body2'
                                              color={item.textColor ?? ''}
                                              sx={{
                                                backgroundColor:
                                                  item.id === row[item.id]?.hasError ? 'blue' : ''
                                              }}
                                            >
                                              {`${
                                                item.prefixText && row[elem] ? item.prefixText : ''
                                              } ${
                                                row[elem]
                                                  ? item.isNumeric
                                                    ? valueSanitizer(row[elem])
                                                    : row[elem]
                                                  : ''
                                              } ${
                                                item.suffixText && row[elem] ? item.suffixText : ''
                                              }`}
                                            </Typography>
                                            {showTags}
                                          </TableCellHOC>
                                        </>
                                      ) : (
                                        <TableCellHOC
                                          id={`enhanced-table-cell-${index}-e-${row[item.id]}`}
                                          isGroupBorderRequire={false}
                                          key={`enhanced-table-cell-${index}-e-${i}`}
                                          align={item.isNumeric ? 'center' : 'left'}
                                          sticky={item?.sticky ? item.sticky : false}
                                          sx={item.sx ? item.sx : {}}
                                        >
                                          <Typography variant='body2' color={item.textColor ?? ''}>
                                            {`${
                                              item.prefixText && row[elem] ? item.prefixText : ''
                                            } ${
                                              row[elem]
                                                ? item.isNumeric
                                                  ? valueSanitizer(row[elem])
                                                  : row[elem]
                                                : ''
                                            } ${
                                              item.suffixText && row[elem] ? item.suffixText : ''
                                            }`}
                                          </Typography>
                                          {showTags}
                                        </TableCellHOC>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <TableCellHOC
                                isGroupBorderRequire={item.isGroupBorderRequire}
                                id={`enhanced-table-cell-${index}-e-${row[item.id]}`}
                                key={`enhanced-table-cell-${index}`}
                                align={item.isNumeric ? item?.cellTextAlign || 'right' : 'left'}
                                sx={item.sx ? item.sx : {}}
                              >
                                {props.isEditable && item.editProps ? (
                                  <>
                                    <FieldProvider
                                      value={row[item.id]?.trim()}
                                      row={row}
                                      id={item.name}
                                      isNumeric={item?.isNumeric}
                                      {...item.editProps}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Typography variant='body2' color={item.textColor ?? ''}>
                                      {renderCell}
                                    </Typography>
                                    {showTags}
                                  </>
                                )}
                              </TableCellHOC>
                            )}
                          </>
                        );
                      })}
                    </StyledTableRow>

                    {isRowGroupOpen && props.allowRowGrouping ? (
                      <>{props.rowGroupTemplate(row, props.columnCell)}</>
                    ) : null}
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.hidePagination ? (
        <>
          <EnhancedTablePagination
            id={`enhanced-table-pagination-${props.id}`}
            count={props.rowData.length || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            paginationSx={props.paginationSx ? props.paginationSx : {}}
          />
        </>
      ) : null}
    </>
  );
}
