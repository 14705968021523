import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Stack, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import CustomSelect from 'components/baseComponents/customSelect';
import CustomButton from 'components/baseComponents/customButton';
import {useTheme} from '@mui/material';

interface IPopUpProps {
  title: string;
  open: boolean;
  handleAction: any;
}

const dropdownData = [
  {
    label: 'draft1',
    value: 'Draft 1'
  },
  {
    label: 'draft2',
    value: 'Draft 2'
  }
];

const PopUp: React.FC<IPopUpProps> = (props) => {
  const [selected, setSelected] = React.useState('');
  const theme: any = useTheme();

  const CustomDialog = styled(Dialog)({
    '& .MuiPaper-root': {
      width: '360px',
      borderRadius: '2px 2px 0 0',
      backgroundColor: theme.palette?.common.white,
      padding: '0 0 24px',
      boxSizing: 'border-box',
      '& h2': {
        padding: '24px 24px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& button': {
          padding: 0
        }
      }
    }
  });

  const CustomIconButton = styled(IconButton)({
    color: theme.palette?.grayscale?.[600]
  });

  const CustomFormLabel = styled(FormLabel)({
    fontSize: '12px',
    letterSpacing: '0.4px',
    fontFamily: 'Roboto Medium',
    color: theme.palette?.grayscale?.[600],
    '& .MuiFormLabel-asterisk': {
      color: theme.palette?.error.main
    },
    fontWeight: 500,
    '&.Mui-focused': {
      color: theme.palette?.grayscale?.[600]
    }
  });

  const CustomFormControlLabel = styled(FormControlLabel)({
    '& .MuiRadio-root': {
      padding: '8px',
      color: theme.palette?.grayscale?.[300],
      '&.Mui-checked': {
        color: theme.palette?.primary.main
      }
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      letterSpacing: '0.25px',
      fontFamily: 'Roboto Regular',
      fontWeight: 500,
      color: theme.palette?.common.black
    }
  });
  const CustomDialogActions = styled(DialogActions)({
    display: 'flex',
    justifyContent: 'center',
    height: '36px',
    backgroundColor: theme.palette?.primary.main,
    padding: 0,
    margin: '0 24px',
    '& div': {
      width: '100%'
    },
    '& button': {
      color: theme.palette?.common.white,
      width: '100%'
    },
    boxSizing: 'border-box',
    borderRadius: '4px'
  });

  const CustomStack = styled(Stack)({
    '& .MuiSelect-select': {
      fontSize: '14px',
      paddingLeft: '12px',
      letterSpacing: '.25px',
      fontFamily: 'Roboto Regular',
      border: `1px solid ${theme.palette?.grayscale?.[200]}`,
      borderRadius: '4px',
      '&:focus': {
        backgroundColor: 'transparent'
      }
    },
    '& .MuiFormControl-root': {
      '& label': {
        color: theme.palette?.grayscale?.[400],
        fontFamily: 'Roboto Medium',
        letterSpacing: '0.25px',
        fontSize: '14px'
      }
    }
  });

  const handleChange = (event: any): void => {
    setSelected(event.target.value);
  };
  return (
    <CustomDialog open={props.open} onClose={props.handleAction}>
      <DialogTitle>
        <Typography variant='h6'>{props.title}</Typography>
        <CustomIconButton
          id='popup-custom-icon-button'
          aria-label='close'
          onClick={props.handleAction}
        >
          <CloseIcon />
        </CustomIconButton>
      </DialogTitle>
      <DialogContent dividers sx={{padding: '24px 24px 24px', borderBottom: 0}}>
        <FormControl>
          <CustomFormLabel id='demo-radio-buttons-group-label' required>
            Select Mode
          </CustomFormLabel>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue='newDraft'
            name='radio-buttons-group'
          >
            <CustomFormControlLabel value='newDraft' control={<Radio />} label='New Draft' />
            <CustomFormControlLabel
              value='existingPO'
              control={<Radio />}
              label='Existing Draft/PO'
            />
          </RadioGroup>
        </FormControl>
        <CustomStack>
          <CustomSelect
            options={dropdownData}
            id='popupSelect'
            sx={{width: '310px'}}
            onChange={handleChange}
            value={selected}
            placeholder={'Enter PO/Draft #'}
            disabled={true}
            title={'Select PO Draft'}
          />
        </CustomStack>
      </DialogContent>
      <CustomDialogActions>
        <CustomButton variant='contained' id='product-details-popup'>
          ADD ITEM
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default PopUp;
