import {createSlice} from '@reduxjs/toolkit';
export interface urgentStoreState {
  loading: boolean;
  urgentStore: Array<any>;
  error: string;
}

const initialState: urgentStoreState = {
  loading: false,
  urgentStore: [],
  error: ''
};

const urgentStoreSlice = createSlice({
  name: 'urgentStore',
  initialState,
  reducers: {
    urgentStoreLoading: (state) => {
      state.loading = true;
    },
    urgentStoreSuccess: (state, action) => {
      state.urgentStore = action.payload;
      state.loading = false;
    },
    urgentStoreFailure: (state) => {
      state.loading = false;
      state.urgentStore = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default urgentStoreSlice.reducer;
export const {urgentStoreLoading, urgentStoreSuccess, urgentStoreFailure} =
  urgentStoreSlice.actions;
