import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  wareHouseItemLoading,
  wareHouseItemFailure,
  wareHouseItemSuccess
} from 'redux/product/details/warehouseItem/wareHouseItemSlice';

export const wareHouseItemSagaActions = {
  FETCH_WAREHOUSEITEM_DATA: 'FETCH_WAREHOUSEITEM_DATA'
};

export function* fetchWareHouseItemData(action: any): any {
  try {
    yield put(wareHouseItemLoading());
    const {data} = yield call(ProductAPIS.getWareHouseItemData, action.payload);
    yield put(wareHouseItemSuccess(data));
  } catch (error) {
    yield put(wareHouseItemFailure());
  }
}

export default function* watchFetchWareHouseItemData() {
  yield takeEvery(wareHouseItemSagaActions.FETCH_WAREHOUSEITEM_DATA, fetchWareHouseItemData);
}
