import {createSlice} from '@reduxjs/toolkit';
export interface loadConditionState {
  loading: boolean;
  loadCondition: Array<any>;
  error: string;
}

const initialState: loadConditionState = {
  loading: false,
  loadCondition: [],
  error: ''
};

const loadConditionSlice = createSlice({
  name: 'loadCondition',
  initialState,
  reducers: {
    loadConditionLoading: (state) => {
      state.loading = true;
    },
    loadConditionSuccess: (state, action) => {
      state.loadCondition = action.payload;
      state.loading = false;
    },
    loadConditionFailure: (state) => {
      state.loading = false;
      state.loadCondition = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default loadConditionSlice.reducer;
export const {loadConditionLoading, loadConditionSuccess, loadConditionFailure} =
  loadConditionSlice.actions;
