import {createSlice} from '@reduxjs/toolkit';
export interface receivingTileState {
  loading: {[key: string]: boolean};
  receivingTile: {[key: string]: Array<any>};
  error: string;
}

const initialState: receivingTileState = {
  loading: {},
  receivingTile: {},
  error: ''
};

const receivingTileSlice = createSlice({
  name: 'receivingTile',
  initialState,
  reducers: {
    receivingTileLoading: (state, action) => {
      state.loading[action.payload.type] = true;
    },
    receivingTileSuccess: (state, action) => {
      state.receivingTile[action.payload.type] = action.payload.result;
      state.loading[action.payload.type] = false;
      state.error = '';
    },
    receivingTileFailure: (state, action) => {
      state.loading[action.payload.type] = false;
      state.receivingTile[action.payload.type] = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default receivingTileSlice.reducer;
export const {receivingTileLoading, receivingTileSuccess, receivingTileFailure} =
  receivingTileSlice.actions;
