import React from 'react';
import Typography from '@mui/material/Typography';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import {CustomCard} from 'components/baseComponents/customCard';
import {styled} from '@mui/material/styles';
import {Box, Button, Grid, Popover, useTheme} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {poAlertSagaActions} from 'redux/dashboard/poAlert/poAlertSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {formatString, generatePayload} from 'util/arrayOperations';
import {Link} from 'react-router-dom';

const dropDownContent = [
  {
    label: 'PO Alerts',
    value: 'Po Alerts'
  },
  {
    label: 'Pending Reviews',
    value: 'Pending Reviews'
  },
  {
    label: 'Late ASN',
    value: 'Late ASN'
  },
  {
    label: 'Past Due',
    value: 'Past Due'
  },
  {
    label: 'Over/Short',
    value: 'Over/Short'
  },
  {
    label: 'QC Fail',
    value: 'qc Fail'
  }
];

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root > div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});

interface IPropType {
  filteredData: any;
  defaultValue: string;
}

interface IAlertPrimaryItem {
  count: number;
  title: string;
  alertType: string;
}

interface IAlertSecondaryItem {
  title: string;
  buttonText: string;
  alertType: string;
  prefix?: boolean;
  estdValue?: string;
  actualValue?: string;
}

interface AlertProps {
  count?: number;
}

const POAlert: React.FC<IPropType> = (props) => {
  const [alertType, setAlertType] = React.useState(props?.defaultValue);
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  /**
   * dispatch poAlert Data
   */
  const dispatchData = () => {
    dispatch({
      type: poAlertSagaActions.FETCH_POALERT_DATA,
      payload: {
        type: alertType,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [alertType, props?.filteredData]);

  const {poAlert, loading, error} = useAppSelector((state) => state.poAlertSlice);

  const AlertWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'count',
    name: 'AlertWrapper',
    slot: 'Root'
  })<AlertProps>(({count}) => ({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    height: 60,
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      '& p': {
        fontSize: 14,
        padding: '0px 8px',
        borderRadius: '2px',
        maxHeight: 24,
        ':first-of-type': {
          color: count ? theme.palette.error.main : theme.palette.grayscale?.[600],
          backgroundColor: count
            ? theme.palette.secondary.light
            : theme.palette.primary.contrastText
        }
      }
    }
  }));

  const IWidgets = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette?.grayscale?.[300]}`,
    height: 60,
    '& p': {
      fontSize: 14,
      borderRadius: 2,
      maxHeight: 24
    }
  });

  const POButton = styled(Button)({
    color: theme.palette.primary.main,
    fontWeight: 500,
    letterSpacing: 1.25,
    lineHeight: 16,
    textAlign: 'right',
    '&:hover': {
      backgroundColor: 'unset'
    }
  });

  const AlertPrimaryItem: React.FC<IAlertPrimaryItem> = (props) => {
    return (
      <AlertWrapper count={props?.count}>
        <div>
          <p>
            <Typography variant='subtitle2' textAlign='center'>
              {props?.count}
            </Typography>
          </p>
          <p className='title'>
            <Typography variant='subtitle2' textAlign='center'>
              {props?.title}
            </Typography>
          </p>
        </div>
        <POButton variant='text'>
          <Typography variant='body2' textAlign='center' sx={{fontFamily: 'Roboto Medium'}}>
            <Link to={{pathname: `/PO`}} state={{filter: {alert: props?.title}}}>
              VIEW POS
            </Link>{' '}
          </Typography>
        </POButton>
      </AlertWrapper>
    );
  };

  const AlertSecondaryItem = (props: IAlertSecondaryItem) => {
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event?.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    if (props?.alertType === 'Over/Short') {
      return (
        <IWidgets>
          <POButton variant='text'>
            <Typography variant='subtitle2' textAlign='center'>
              {props?.buttonText}
            </Typography>
          </POButton>
          <Box
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup='true'
            onMouseEnter={(e) => handlePopoverOpen(e)}
            onMouseLeave={handlePopoverClose}
          >
            <div>
              <p className='title'>
                <Typography variant='subtitle2' textAlign='center'>
                  {props?.prefix ? `$${props?.title}` : props?.title}
                </Typography>
              </p>
            </div>
          </Box>
          <Popover
            id='mouse-over-popo'
            elevation={1}
            sx={{
              boxShadow: 'unset',
              pointerEvents: 'none'
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus={true}
          >
            <Grid direction='row' container>
              <Grid item sx={{textTransform: 'uppercase'}} md={7} paddingBottom={1}>
                <Typography
                  variant='subtitle1'
                  sx={{
                    fontFamily: 'Roboto Medium',
                    fontWeight: 100,
                    fontSize: 10,
                    paddingLeft: 1,
                    color: theme.palette.grayscale?.[600]
                  }}
                  textAlign='left'
                >
                  Expected:
                </Typography>
              </Grid>
              <Grid item sx={{textTransform: 'uppercase'}} md={5} paddingBottom={1}>
                <Typography
                  variant='body1'
                  textAlign='left'
                  sx={{
                    fontFamily: 'Roboto Medium',
                    fontWeight: 100,
                    fontSize: 10
                  }}
                >
                  {props?.actualValue}
                </Typography>
              </Grid>
              <Grid item sx={{textTransform: 'uppercase'}} md={7} paddingBottom={1}>
                <Typography
                  variant='subtitle1'
                  sx={{
                    fontFamily: 'Roboto Medium',
                    fontWeight: 100,
                    fontSize: 10,
                    paddingLeft: 1,
                    color: theme.palette.grayscale?.[600]
                  }}
                  textAlign='left'
                >
                  Actual:
                </Typography>
              </Grid>
              <Grid item sx={{textTransform: 'uppercase'}} md={5} paddingBottom={1}>
                <Typography
                  variant='body1'
                  textAlign='left'
                  sx={{
                    fontFamily: 'Roboto Medium',
                    fontWeight: 100,
                    fontSize: 10
                  }}
                >
                  {props?.estdValue}
                </Typography>
              </Grid>
            </Grid>
          </Popover>
        </IWidgets>
      );
    } else {
      return (
        <IWidgets>
          <POButton variant='text'>
            <Typography variant='subtitle2' textAlign='center'>
              {props?.buttonText}
            </Typography>
          </POButton>
          <div>
            <p className='title'>
              <Typography variant='subtitle2' textAlign='center'>
                {props?.prefix ? `$${props?.title}` : props?.title}
              </Typography>
            </p>
          </div>
        </IWidgets>
      );
    }
  };

  const handleSelect = (event: any): void => {
    dispatch({
      type: poAlertSagaActions.FETCH_POALERT_DATA,
      payload: {
        ...props?.filteredData,
        type: formatString(event?.target.value)
      }
    });
    setAlertType(formatString(event?.target.value));
  };

  const generateContent = () => {
    switch (alertType) {
      case 'Pending Reviews':
        return poAlert.map((item, index) => {
          return (
            <AlertSecondaryItem
              key={`${item}-${index}`}
              title={item.amount}
              buttonText={item.name}
              alertType={alertType}
              prefix
            />
          );
        });
      case 'Late ASN':
        return poAlert.map((item, index) => {
          return (
            <AlertSecondaryItem
              key={`${item}-${index}`}
              title={item.date}
              buttonText={item.name}
              alertType={alertType}
            />
          );
        });
      case 'Past Due':
      case 'Over/Short':
        return poAlert.map((item, index) => {
          return (
            <AlertSecondaryItem
              key={`${item}-${index}`}
              title={item.amount}
              buttonText={item.name}
              alertType={alertType}
              estdValue={item.expected}
              actualValue={item.actual}
            />
          );
        });
      case 'qc Fail':
        return poAlert.map((item, index) => {
          return (
            <AlertSecondaryItem
              key={`${item}-${index}`}
              title={item.amount}
              buttonText={item.name}
              alertType={alertType}
            />
          );
        });
      default:
        return poAlert.map((item, index) => {
          return (
            <AlertPrimaryItem
              key={`${item}-${index}`}
              count={item.count}
              title={item.alertType}
              alertType={alertType}
            />
          );
        });
    }
  };

  const Header = () => {
    return (
      <DropDownWrapper sx={{paddingRight: '12px'}}>
        <CustomSelect
          id='poAlertTile'
          onChange={handleSelect}
          options={dropDownContent}
          value={alertType}
        />
        {alertType != 'PoAlerts' && !loading && !error && poAlert.length ? (
          <span>
            <Typography variant='body2' textAlign='center' sx={{fontWeight: 'bold'}}>
              {poAlert.length}
            </Typography>
          </span>
        ) : null}
      </DropDownWrapper>
    );
  };

  const Content = () => {
    return <>{generateContent()}</>;
  };

  if (poAlert && poAlert.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='poAlert-data'
          header={<Header />}
          content={loading ? <CustomLoader id='poAlert-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='poAlert-withoutData'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='poAlert-load' />
            ) : (
              <CustomError
                id='poAlert-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default POAlert;
