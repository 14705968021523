import {createSlice} from '@reduxjs/toolkit';
export interface sizeState {
  loading: boolean;
  size: Array<any>;
  error: string;
}

const initialState: sizeState = {
  loading: false,
  size: [],
  error: ''
};

const sizeSlice = createSlice({
  name: 'size',
  initialState,
  reducers: {
    sizeLoading: (state) => {
      state.loading = true;
    },
    sizeSuccess: (state, action) => {
      state.size = action.payload;
      state.loading = false;
    },
    sizeFailure: (state) => {
      state.loading = false;
      state.size = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default sizeSlice.reducer;
export const {sizeLoading, sizeSuccess, sizeFailure} = sizeSlice.actions;
