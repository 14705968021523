import * as React from 'react';
import {Box} from '@mui/material';
import EnhancedTable from 'components/customTable/enhancedTable';
import {IColumnCell} from 'components/customTable/types';
import {wareHouseItemSagaActions} from 'redux/product/details/warehouseItem/wareHouseItemSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {toFlatPropertyMap} from 'util/objectHelper';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';

//creating interface for productDetails warehouse
interface IProductDetailsWarehouseGridProps {
  wh: string;
  uom: string;
  ohWos: string;
  ohOoWos: string;
  avgCost: string;
  expDate: string;
}
//creating interface and extending productDetails interface
interface IProductWarehouseGrid extends IProductDetailsWarehouseGridProps {
  id: string;
  'onHand.units': string;
  'onHand.dollars': string;
  'onOrder.units': string;
  'onOrder.dollars': string;
  'inQC.units': string;
  'inQC.dollars': string;
}
interface IProductDetailsProps {
  productId: string;
}
//creating interface for innner grid and extend product details warehouse
interface IProductWarehouseInnerGrid extends IProductDetailsWarehouseGridProps {
  onHand: {units: string; dollars: string};
  onOrder: {units: string; dollars: string};
  inQC: {units: string; dollars: string};
}
// columnCell for productDetails warehouse Tab
const columnCell: IColumnCell<IProductWarehouseGrid>[] = [
  {
    id: 'wh',
    name: 'wh',
    displayName: 'WH',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    hasTags: true
  },
  {
    id: 'uom',
    name: 'uom',
    displayName: 'UOM',
    isColumnSortable: false,
    isGroupBorderRequire: true
  },
  {
    id: 'onHand.units',
    name: 'onHand',
    displayName: 'ON HAND',
    isGroupBorderRequire: true,
    isNumeric: true,
    isGroupedCell: true,
    groupedKeys: ['onHand.units', 'onHand.dollars'],
    groupSortKey: 'onHand.dollars',
    groupDisplayKeys: ['units', 'dollars'],
    groupHeaderDisplaySx: [
      {textAlign: 'center', width: '50%'},
      {textAlign: 'center', width: '50%'}
    ],
    headerTextAlign: 'right',
    cellTextAlign: 'right',
    sx: {
      textAlign: 'center'
    }
  },
  {
    id: 'onOrder.units',
    name: 'onOrder',
    displayName: 'on order',
    isNumeric: true,
    isGroupBorderRequire: true,
    isGroupedCell: true,
    groupedKeys: ['onOrder.units', 'onOrder.dollars'],
    groupSortKey: 'onOrder.dollars',
    groupDisplayKeys: ['units', 'dollars'],
    headerTextAlign: 'center',
    groupHeaderDisplaySx: [
      {textAlign: 'center', width: '50%'},
      {textAlign: 'center', width: '50%'}
    ],
    sx: {
      textAlign: 'center'
    }
  },
  {
    id: 'inQC.units',
    name: 'inQC',
    displayName: 'IN QC',
    isGroupBorderRequire: true,
    isNumeric: true,
    isGroupedCell: true,
    groupedKeys: ['inQC.units', 'inQC.dollars'],
    groupSortKey: 'inQC.dollars',
    groupDisplayKeys: ['units', 'dollars'],
    groupHeaderDisplaySx: [
      {textAlign: 'center', width: '50%'},
      {textAlign: 'center', width: '50%'}
    ],
    groupCellTextAlign: 'right',
    sx: {
      textAlign: 'center'
    }
  },
  {
    id: 'ohWos',
    name: 'ohWos',
    displayName: 'OH WOS',
    isNumeric: true,
    isColumnSortable: false,
    headerTextAlign: 'right'
  },
  {
    id: 'ohOoWos',
    name: 'ohOoWos',
    displayName: 'OH+OO WOS',
    isNumeric: true,
    isGroupBorderRequire: true,
    isColumnSortable: false,
    headerTextAlign: 'right',
    cellTextAlign: 'right'
  },
  {
    id: 'avgCost',
    name: 'avgCost',
    displayName: 'avg Cost',
    isNumeric: true,
    isGroupBorderRequire: true,
    headerTextAlign: 'center',
    cellTextAlign: 'right',
    prefixText: '$'
  },
  {
    id: 'expDate',
    name: 'expDate',
    isNumeric: true,
    displayName: 'EXP DATE',
    headerTextAlign: 'center',
    cellTextAlign: 'center'
  }
];

/**
 * main component name warehouse
 */
const Warehouse: React.FC<IProductDetailsProps> = (props) => {
  const dispatch = useAppDispatch();
  const productId = props.productId;
  /**
   * dispatch product details warehouse data
   */
  const dispatchData = () => {
    dispatch({
      type: wareHouseItemSagaActions.FETCH_WAREHOUSEITEM_DATA,
      payload: productId
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, []);
  const {wareHouseItem, loading} = useAppSelector((state) => state.wareHouseItemSlice);

  /**
   * function name formateRowData
   * @param listData
   * @returns gridData
   */
  const formateRowData = (listData: IProductWarehouseInnerGrid[]) => {
    const gridData: IProductWarehouseGrid[] = listData.map((element) => {
      const nestedObject: IProductWarehouseGrid = toFlatPropertyMap(
        element
      ) as unknown as IProductWarehouseGrid;
      nestedObject['onHand.dollars'] = `$${nestedObject['onHand.dollars']}`;
      nestedObject['onOrder.dollars'] = `$${nestedObject['onOrder.dollars']}`;
      nestedObject['inQC.dollars'] = `$${nestedObject['inQC.dollars']}`;
      return nestedObject;
    });
    return gridData;
  };
  const rowData: IProductWarehouseGrid[] =
    wareHouseItem.length > 0 ? formateRowData(wareHouseItem) : [];
  if (wareHouseItem && wareHouseItem.length > 0) {
    return (
      <>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='product-details-warehouse-tab' />
          </Box>
        )}
        <EnhancedTable<IProductWarehouseGrid>
          rowData={rowData}
          columnCell={columnCell}
          rowKey={'wh'}
          defaultSortKey={'wh'}
          rowsPerPage={9}
          showTags={true}
          id='product-details-warehouse'
        />
      </>
    );
  } else {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='product-details-sales-tab' />
          ) : (
            <CustomError
              id='product-details-warehouse-error'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      </>
    );
  }
};
export default Warehouse;
