import {takeEvery, put, call} from 'redux-saga/effects';
import {ReportAPIS} from 'redux/report/report_service';
import {
  demandForecastLoading,
  demandForecastSuccess,
  demandForecastFailure
} from 'redux/report/sales/demandForecast/demandForecastSlice';

export const demandForecastSagaActions = {
  FETCH_DEMANDFORECAST_SAGA: 'FETCH_DEMANDFORECAST_SAGA'
};

export function* fetchDemandForecastData(action: any) {
  try {
    yield put(demandForecastLoading());
    const {data} = yield call(ReportAPIS.getDemandForecastData, action.payload);
    yield put(demandForecastSuccess(data));
  } catch (error) {
    yield put(demandForecastFailure());
  }
}

export default function* watchFetchDemandForecastData() {
  yield takeEvery(demandForecastSagaActions.FETCH_DEMANDFORECAST_SAGA, fetchDemandForecastData);
}
