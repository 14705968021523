import {createSlice} from '@reduxjs/toolkit';
export interface topTenSalesItemsState {
  loading: boolean;
  topTenSalesItems: Array<any>;
  error: string;
}

const initialState: topTenSalesItemsState = {
  loading: false,
  topTenSalesItems: [],
  error: ''
};

const topTenSalesItemsSlice = createSlice({
  name: 'topTenSalesItems',
  initialState,
  reducers: {
    topTenSalesItemsLoading: (state) => {
      state.loading = true;
    },
    topTenSalesItemsSuccess: (state, action) => {
      state.topTenSalesItems = action.payload;
      state.loading = false;
    },
    topTenSalesItemsFailure: (state) => {
      state.loading = false;
      state.topTenSalesItems = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default topTenSalesItemsSlice.reducer;
export const {topTenSalesItemsLoading, topTenSalesItemsSuccess, topTenSalesItemsFailure} =
  topTenSalesItemsSlice.actions;
