/* eslint-disable no-useless-catch */
import instance from 'config/instance';
import {VendorEndPoints} from 'config/endpoints';

export const VendorAPIS = {
  getVendorDetailsData: async (id: any) => {
    try {
      const response = await instance.get(`${VendorEndPoints.vendorDetails}?vendorId=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getVendorListData: async (data: any) => {
    try {
      const response = await instance.post(`${VendorEndPoints.vendorList}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getVendorSalesItemData: async (payload: any) => {
    try {
      const response = await instance.post(
        `${VendorEndPoints.vendorSalesItem}?vendorId=${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProductItemData: async (payload: any) => {
    try {
      const response = await instance.post(
        `${VendorEndPoints.productItem}?vendorId=${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getSupplyChainData: async (payload: any) => {
    try {
      const response = await instance.post(
        `${VendorEndPoints.supplyChain}?vendorId=${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
};
