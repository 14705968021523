import {TILE_WIDTH} from 'config/constants';

/**
 * Return the width as per the tile
 */
export function getTileWidth(blockWidth = 1) {
  let width = '';
  switch (blockWidth) {
    case 2:
      width = TILE_WIDTH.two;
      break;
    case 3:
      width = TILE_WIDTH.three;
      break;
    default:
      width = TILE_WIDTH.one;
      break;
  }
  return width;
}
