import {createSlice} from '@reduxjs/toolkit';
export interface retailZoneState {
  loading: boolean;
  retailZone: Array<any>;
  error: string;
}

const initialState: retailZoneState = {
  loading: false,
  retailZone: [],
  error: ''
};

const retailZoneSlice = createSlice({
  name: 'retailZone',
  initialState,
  reducers: {
    retailZoneLoading: (state) => {
      state.loading = true;
    },
    retailZoneSuccess: (state, action) => {
      state.retailZone = action.payload;
      state.loading = false;
    },
    retailZoneFailure: (state) => {
      state.loading = false;
      state.retailZone = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default retailZoneSlice.reducer;
export const {retailZoneLoading, retailZoneSuccess, retailZoneFailure} = retailZoneSlice.actions;
