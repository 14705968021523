import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {regionLoading, regionFailure, regionSuccess} from 'redux/dashboard/master/regionSlice';

export const regionSagaActions = {
  FETCH_REGION_DATA: 'FETCH_REGION_DATA'
};

export function* fetchRegionData(): any {
  try {
    yield put(regionLoading());
    const {data} = yield call(ProductAPIS.getRegionData);
    yield put(regionSuccess(data));
  } catch (error) {
    yield put(regionFailure());
  }
}

export default function* watchFetchRegionData() {
  yield takeEvery(regionSagaActions.FETCH_REGION_DATA, fetchRegionData);
}
