import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  salesItemLoading,
  salesItemFailure,
  salesItemSuccess
} from 'redux/product/details/salesItem/salesItemSlice';

export const salesItemSagaActions = {
  FETCH_SALESITEM_DATA: 'FETCH_SALESITEM_DATA'
};

export function* fetchSalesItemData(action: any): any {
  try {
    yield put(salesItemLoading());
    const {data} = yield call(ProductAPIS.getSalesItemData, action.payload);
    yield put(salesItemSuccess(data));
  } catch (error) {
    yield put(salesItemFailure());
  }
}

export default function* watchFetchSalesItemData() {
  yield takeEvery(salesItemSagaActions.FETCH_SALESITEM_DATA, fetchSalesItemData);
}
