import {takeEvery, put, call} from 'redux-saga/effects';
import {ReportAPIS} from 'redux/report/report_service';
import {
  inventoryBalanceLoading,
  inventoryBalanceSuccess,
  inventoryBalanceFailure,
  inventoryBalanceRegionSuccess
} from 'redux/report/inventory/inventoryBalances/inventoryBalanceSlice';

export const inventoryBalanceSagaActions = {
  FETCH_INVENTORY_BALANACE_SAGA: 'FETCH_INVENTORY_BALANACE_SAGA',
  FETCH_INVENTORY_BALANACE_REGION_SAGA: 'FETCH_INVENTORY_BALANACE_REGION_SAGA'
};

export function* fetchInventoryBalanceData(action: any): any {
  try {
    yield put(inventoryBalanceLoading({type: action.payload.type}));
    const {data} = yield call(ReportAPIS.getInventoryBalanceData, action.payload);
    yield put(inventoryBalanceSuccess({result: data, type: action.payload.type}));
  } catch (error) {
    yield put(inventoryBalanceFailure({type: action.payload.type}));
  }
}

export default function* watchFetchInventoryBalanceData() {
  yield takeEvery(
    inventoryBalanceSagaActions.FETCH_INVENTORY_BALANACE_SAGA,
    fetchInventoryBalanceData
  );
}

export function* fetchInventoryBalanceRegionData(action: any): any {
  try {
    const {data} = yield call(ReportAPIS.getInventoryBalanceData, action.payload);
    yield put(inventoryBalanceRegionSuccess({result: data, regionId: action.payload.regionId}));
  } catch (error) {
    console.error(error);
  }
}

export function* watchFetchInventoryBalanceRegionData() {
  yield takeEvery(
    inventoryBalanceSagaActions.FETCH_INVENTORY_BALANACE_REGION_SAGA,
    fetchInventoryBalanceRegionData
  );
}
