import {Box, IconButton, Modal, styled, Typography, useTheme} from '@mui/material';
import React, {useState} from 'react';
import {notificationSagaActions} from 'redux/dashboard/globalNotification/globalNotificationSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {CustomCard} from 'components/baseComponents/customCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  IOverShortPOsList,
  IPastDuePOsList,
  IPendingReviewPOsList,
  IQcFailPOsList,
  IRecallList,
  IShortCodeList,
  IUrgentStoreAlertsList
} from 'components/types';
import EnhancedTable from 'components/customTable/enhancedTable';
import {IColumnCell} from 'components/customTable/types';
import {Link} from 'react-router-dom';

interface ITitles {
  [key: string]: string;
}
interface IPropType {
  onClose: () => void;
  open: boolean;
}

const getNotificationTiles: ITitles = {
  urgentStoreAlertsList: 'Urgent Store Alerts',
  qcFailPOsList: 'QC Fail POs',
  pendingReviewPOsList: 'Pending Review POs',
  pastDuePOsList: 'Past Due POs',
  shortCodeList: 'Short Code',
  overShortPOsList: 'Over/Short POs',
  recallList: 'Recall'
};

const getContentTitle = (title: string) => {
  return getNotificationTiles[title];
};

const GlobalNotification: React.FC<IPropType> = (props) => {
  const theme: any = useTheme();
  const dispatch = useAppDispatch();
  const [showContent, setShowContent] = useState(false);
  React.useEffect(() => {
    dispatch({
      type: notificationSagaActions.FETCH_NOTIFICATION_DATA
    });
  }, []);

  const {notification} = useAppSelector((state) => state.globalNotificationSlice);
  const getProperties = Object.entries(notification);
  const getListValues = getProperties.filter((g) => g[0].endsWith('List'));
  const getKeyValuePairList: any = Object.fromEntries(getListValues);

  const handleClick = (id: any) => {
    setShowContent(!showContent);
  };

  const CustomBox = styled(Box)({
    boxSizing: 'border-box',
    position: 'absolute',
    right: '60px',
    zIndex: 9999,
    top: '68px',
    '& .MuiCard-root': {
      padding: '32px 24px !important',
      borderRadius: '5px',
      boxShadow: `0 1px 1px 0 ${theme.palette.neutral?.secondary12}`,
      ' & .MuiCardHeader-root': {
        width: 0,
        height: 0,
        marginBottom: 0
      },
      ' & .MuiCardContent-root': {
        height: '100%',
        maxHeight: 'none',
        '& > .MuiBox-root': {
          overflowY: 'initial',
          height: 'auto',
          paddingRight: 0,
          '& .MuiButton-root:hover': {
            backgroundColor: `${theme.palette.grayscale?.[50]}`
          },
          '& .MuiTableHead-root': {
            display: 'none'
          },
          '& .MuiTableRow-root': {
            height: '48px',
            backgroundColor: `${theme.palette.common.white}`,
            '& td': {
              padding: '0 0 0 16px'
            }
          }
        }
      }
    }
  });
  /**Link Short code to Item Deatils Page */
  const LinkShortCodeComponent = (row: any) => {
    // return (
    //   <Link to={{ pathname: `/Product/${row.itemNumber}` }} state={row}>
    //     {row.itemNumber}
    //   </Link>
    // );
    return (
      <Link to={{pathname: `/TaskManagement`}} state={row}>
        {row.itemNumber}
      </Link>
    );
  };
  /**Link Recall to Item Deatils Page */
  const LinkReacllComponent = (row: any) => {
    // return (
    //   <Link to={{ pathname: `/Product/${row.itemId}` }} state={row}>
    //     {row.itemId}
    //   </Link>
    // );
    return (
      <Link to={{pathname: `/TaskManagement`}} state={row}>
        {row.itemId}
      </Link>
    );
  };
  /**Link Recall to Item Deatils Page */
  const LinkPastduoPoComponent = (row: any) => {
    return (
      <Link to={{pathname: `/Vendor`}} state={row}>
        {row.poNumber}
        {props.onClose}
      </Link>
    );
  };

  const CustomBoxItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: `${theme.palette.grayscale?.[50]}`,
    padding: '0 16px 0 16px',
    height: '48px'
  });

  const columnUrgentStoreCell: IColumnCell<IUrgentStoreAlertsList>[] = [
    {
      id: 'itemNumber',
      name: 'itemNumber',
      textColor: theme.palette.primary.main,
      sx: {
        width: '130px'
      }
    },
    {
      id: 'storeNumber',
      name: 'storeNumber',
      sx: {
        width: '160px'
      }
    },
    {
      id: 'category',
      name: 'category',
      sx: {
        width: '350px'
      }
    },

    {
      id: 'timeLeft',
      name: 'timeLeft'
    }
  ];

  const columnpastDuePOsCell: IColumnCell<IPastDuePOsList>[] = [
    {
      id: 'poNumber',
      name: 'poNumber',
      customCell: LinkPastduoPoComponent,
      textColor: theme.palette.primary.main,
      sx: {
        width: '130px'
      }
    },
    {
      id: 'warehouseNumber',
      name: 'warehouseNumber',
      sx: {
        width: '160px'
      }
    },
    {
      id: 'scheduledDate',
      name: 'scheduledDate'
    }
  ];

  const columnqcFailPOsCell: IColumnCell<IQcFailPOsList>[] = [
    {
      id: 'poNumber',
      name: 'poNumber',
      textColor: theme.palette.primary.main,
      sx: {
        width: '130px'
      }
    },
    {
      id: 'warehouseNumber',
      name: 'warehouseNumber',
      sx: {
        width: '160px'
      }
    },
    {
      id: 'numberOfFailedItems',
      name: 'numberOfFailedItems'
    }
  ];

  const columnpendingReviewPOsCell: IColumnCell<IPendingReviewPOsList>[] = [
    {
      id: 'poNumber',
      name: 'poNumber',
      textColor: theme.palette.primary.main,
      sx: {
        width: '130px'
      }
    },
    {
      id: 'warehouseNumber',
      name: 'warehouseNumber',
      sx: {
        width: '160px'
      }
    },
    {
      id: 'poStatus',
      name: 'poStatus'
    }
  ];

  const columnshortCodeCell: IColumnCell<IShortCodeList>[] = [
    {
      id: 'itemNumber',
      name: 'itemNumber',
      textColor: theme.palette.primary.main,
      customCell: LinkShortCodeComponent,
      sx: {
        width: '130px'
      }
    },
    {
      id: 'warehouseNumber',
      name: 'warehouseNumber',
      sx: {
        width: '160px'
      }
    },
    {
      id: 'itemDescription',
      name: 'itemDescription',
      sx: {
        width: '350px'
      }
    },
    {
      id: 'expiryDate',
      name: 'expiryDate'
    }
  ];

  const columnoverShortPOsCell: IColumnCell<IOverShortPOsList>[] = [
    {
      id: 'poNumber',
      name: 'poNumber',
      textColor: theme.palette.primary.main,
      sx: {
        width: '130px'
      }
    },
    {
      id: 'warehouseNumber',
      name: 'warehouseNumber',
      sx: {
        width: '160px'
      }
    },
    {
      id: 'receivingVariancePercentage',
      name: 'receivingVariancePercentage'
    }
  ];

  const columnrecallCell: IColumnCell<IRecallList>[] = [
    {
      id: 'itemId',
      name: 'itemId',
      customCell: LinkReacllComponent,
      textColor: theme.palette.primary.main,
      sx: {
        width: '130px'
      }
    },
    {
      id: 'upcNumber',
      name: 'upcNumber',
      sx: {
        width: '160px'
      }
    },
    {
      id: 'itemDescription',
      name: 'itemDescription',
      sx: {
        width: '350px'
      }
    },
    {
      id: 'numberOfOhCase',
      name: 'numberOfOhCase'
    }
  ];

  const generateContent = (data: any) => {
    switch (data) {
      case 'urgentStoreAlertsList':
        return (
          <EnhancedTable<IUrgentStoreAlertsList>
            width={'100%'}
            rowData={getKeyValuePairList[data]}
            columnCell={columnUrgentStoreCell}
            rowKey={'itemNumber'}
            defaultSortKey={'itemNumber'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={`notification-${data}`}
          />
        );
      case 'qcFailPOsList':
        return (
          <EnhancedTable<IQcFailPOsList>
            width={'100%'}
            rowData={getKeyValuePairList[data]}
            columnCell={columnqcFailPOsCell}
            rowKey={'poNumber'}
            defaultSortKey={'poNumber'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={`notification-${data}`}
          />
        );
      case 'pendingReviewPOsList':
        return (
          <EnhancedTable<IPendingReviewPOsList>
            width={'100%'}
            rowData={getKeyValuePairList[data]}
            columnCell={columnpendingReviewPOsCell}
            rowKey={'poNumber'}
            defaultSortKey={'poNumber'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={`notification-${data}`}
          />
        );
      case 'pastDuePOsList':
        return (
          <EnhancedTable<IPastDuePOsList>
            width={'100%'}
            rowData={getKeyValuePairList[data]}
            columnCell={columnpastDuePOsCell}
            rowKey={'poNumber'}
            defaultSortKey={'poNumber'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={`notification-${data}`}
          />
        );
      case 'shortCodeList':
        return (
          <EnhancedTable<IShortCodeList>
            width={'100%'}
            rowData={getKeyValuePairList[data]}
            columnCell={columnshortCodeCell}
            rowKey={'itemNumber'}
            defaultSortKey={'itemNumber'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={`notification-${data}`}
          />
        );
      case 'overShortPOsList':
        return (
          <EnhancedTable<IOverShortPOsList>
            width={'100%'}
            rowData={getKeyValuePairList[data]}
            columnCell={columnoverShortPOsCell}
            rowKey={'poNumber'}
            defaultSortKey={'poNumber'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={`notification-${data}`}
          />
        );
      case 'recallList':
        return (
          <EnhancedTable<IRecallList>
            width={'100%'}
            rowData={getKeyValuePairList[data]}
            columnCell={columnrecallCell}
            rowKey={'itemId'}
            defaultSortKey={'itemId'}
            allowAlternateColor={false}
            componentType='box'
            dense={false}
            hidePagination
            id={`notification-${data}`}
          />
        );
    }
  };

  const Content = () => {
    return (
      <>
        {Object.keys(getKeyValuePairList).map((d, index) => {
          return (
            <Box key={`${d}-${index}`}>
              <CustomBoxItem
                sx={{
                  marginTop: index === 0 ? 0 : '24px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    variant='subtitle2'
                    sx={{
                      color: `${theme.palette?.error.main}`,
                      backgroundColor: `${theme.palette?.secondary.light}`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '32px',
                      height: '30px'
                    }}
                  >
                    {getKeyValuePairList[d]?.length}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      color: `${theme.palette.grayscale?.[600]}`,
                      marginLeft: '17px'
                    }}
                  >
                    {getContentTitle(d)}
                  </Typography>
                  <IconButton
                    id={`arrow-up-down-button-notification-${index}`}
                    aria-label='expand row'
                    size='small'
                    onClick={() => handleClick(index)}
                  >
                    {showContent ? (
                      <KeyboardArrowUpIcon style={{color: theme.palette.grayscale?.[600]}} />
                    ) : (
                      <KeyboardArrowDownIcon style={{color: theme.palette.grayscale?.[600]}} />
                    )}
                  </IconButton>
                </Box>
              </CustomBoxItem>
              {showContent && generateContent(d)}
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
    >
      <CustomBox>
        <CustomCard
          id={'notificationCard'}
          header={<></>}
          content={<Content />}
          width='900px'
          height='688px'
        />
      </CustomBox>
    </Modal>
  );
};

export default GlobalNotification;
