import React from 'react';
import normal from './normal';
import {Theme} from '@mui/system';
import {ThemeProvider} from '@mui/material';

const themes = new Map([['normal', normal]]);

interface IThemeProps {
  theme?: string | undefined;
}

/**
 * function to return the theme
 */
const getTheme = (arg: IThemeProps) => {
  return arg.theme && themes.get(arg.theme) ? themes.get(arg.theme) || normal : normal;
};

interface IOwnProps {
  theme?: any;
  children: React.ReactNode;
}
/**
 * HOC for material ui theme provider
 * @param props Theme name
 * @returns component with theme provider
 */
const PortalThemeProvider = (props: IOwnProps) => {
  const {children} = props;
  // Retrieve the theme object by theme name
  const theme: Theme = getTheme(props.theme);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default PortalThemeProvider;
