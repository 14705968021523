import React from 'react';
import {Typography, Stack, Box, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ReactComponent as UpArrow} from 'assets/images/icons/Icons & UI-Arrows-Arrow_Up.svg';
import {ReactComponent as DownArrow} from 'assets/images/icons/Icons & UI-Arrows-Arrow_Down.svg';
import {CustomCard} from 'components/baseComponents/customCard';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {topVendorStoreActions} from 'redux/dashboard/topVendor/topVendorSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import {decimalConverter, formatString, generatePayload} from 'util/arrayOperations';
import {Link} from 'react-router-dom';

interface topVendorItem {
  currentRank: string;
  lwr: string;
  itemName: string;
  amount: string;
  cost: string;
  venId: string;
}

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root > div': {
    justifyContent: 'center'
  }
});

interface IPropType {
  defaultValue: string;
  filteredData: any;
}

const TopVendorsWidget: React.FC<IPropType> = (props) => {
  const [vendorType, setVendorType] = React.useState(props?.defaultValue);
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  /**
   * disptach top-vendor-tile data
   */
  const dispatchData = () => {
    dispatch({
      type: topVendorStoreActions.FETCH_TOPVENDOR_SAGA,
      payload: {
        type: vendorType,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [props?.filteredData, vendorType]);

  const vendorOptions = [
    {
      label: 'Top Vendors',
      value: 'Top Vendor'
    },
    {
      label: 'Top New Vendors',
      value: 'Top New Vendor'
    }
  ];

  /**
   * On Change event for dropdown
   * @param event Dropdown
   */

  const handleSelect = (event: any): void => {
    setVendorType(formatString(event?.target.value));
  };

  const Header = () => {
    return (
      <DropDownWrapper>
        <CustomSelect
          id='topVendorsTile'
          value={vendorType}
          onChange={handleSelect}
          options={vendorOptions}
          sx={{fontWeight: 600, fontSize: '14px'}}
        />
      </DropDownWrapper>
    );
  };

  const TopVendorItem = (props: topVendorItem) => {
    return (
      <>
        <Stack
          direction={'row'}
          sx={{
            pt: 0.875,
            pb: 0.75,
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.grayscale?.[300]}`
          }}
        >
          <Stack direction={'column'} sx={{pr: 2}}>
            <Stack
              direction={'row'}
              sx={{
                alignItems: 'center',
                gap: 0.2
              }}
            >
              <Typography
                variant='h6'
                sx={{fontFamily: 'Roboto Medium', lineHeight: '24px'}}
                textAlign='left'
              >
                {props?.currentRank}
              </Typography>
              {props?.currentRank < props?.lwr ? (
                <UpArrow style={{paddingLeft: 4, paddingBottom: '2px'}} />
              ) : (
                <DownArrow style={{paddingLeft: 4, paddingBottom: '2px'}} />
              )}
            </Stack>
            <Typography
              variant='caption'
              textAlign='left'
              sx={{fontSize: '12px', whiteSpace: 'nowrap'}}
            >
              LW: {props?.lwr}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              variant='body1'
              textAlign='left'
              sx={{
                color: 'primary.main',
                fontFamily: 'Roboto Medium',
                maxWidth: '240px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              <Link to={{pathname: `/Vendor/${props?.venId}`}} state={{vendorId: props?.venId}}>
                {props?.itemName}
              </Link>
            </Typography>
            <Typography
              variant='caption'
              textAlign='left'
              sx={{fontSize: '12px', whiteSpace: 'nowrap'}}
            >
              LW:&nbsp;{Number(props?.amount).toLocaleString()}&nbsp;&#8226;&nbsp;
              <Typography variant='caption' sx={{fontSize: '12px', whiteSpace: 'nowrap'}}>
                COST:&nbsp;+{props?.cost ? decimalConverter(props?.cost) : 0}%
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </>
    );
  };

  const {topVendorStore, loading, error} = useAppSelector((state) => state.topVendorStoreSlice);

  const Content = () => {
    return (
      <Stack>
        {topVendorStore[vendorType].map((item, index) => {
          return (
            <TopVendorItem
              currentRank={item.rank || item.currentRank}
              lwr={item.lw || item.lastWeekRank}
              itemName={item.company || item.vendorDescription}
              amount={item.lwAmount || item.lastWeekSales}
              cost={item.cost || item.costVariance}
              key={`${item}-${index}`}
              venId={item.venId || item.vendorId}
            />
          );
        })}
      </Stack>
    );
  };
  if (topVendorStore[vendorType] && topVendorStore[vendorType].length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='topVendorTile'
          header={<Header />}
          content={loading ? <CustomLoader id='top-vendor-load' /> : <Content />}
          headerSx={{
            height: '32px'
          }}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='topVendorTile-Id'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='top-vendor-load' />
            ) : (
              <CustomError
                id='top-vendor-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
          headerSx={{
            height: '32px'
          }}
        />
      </CustomBox>
    );
  }
};

export default TopVendorsWidget;
