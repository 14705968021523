import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {buyerLoading, buyerFailure, buyerSuccess} from 'redux/dashboard/master/buyerSlice';

export const buyerSagaActions = {
  FETCH_BUYER_DATA: 'FETCH_BUYER_DATA'
};

export function* fetchBuyerData(): any {
  try {
    yield put(buyerLoading());
    const {data} = yield call(ProductAPIS.getBuyerData);
    yield put(buyerSuccess(data));
  } catch (error) {
    yield put(buyerFailure());
  }
}

export default function* watchFetchBuyerData() {
  yield takeEvery(buyerSagaActions.FETCH_BUYER_DATA, fetchBuyerData);
}
