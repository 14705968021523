import {SelectProps, SxProps, Theme} from '@mui/material';
import React from 'react';
import {ISelectOptions} from 'components/baseComponents/customSelect';

export type IOrder = 'asc' | 'desc';
export interface IColumnCell<T> {
  id: keyof T;
  name: string; //If grouped, then name will contain array of object with this key
  displayName?: string;
  prefixText?: string;
  suffixText?: string;
  isDate?: boolean; // default false
  isNumeric?: boolean; // default false
  disablePadding?: boolean;
  customCell?: React.FC;
  isGroupBorderRequire?: boolean; // default false
  isColumnSortable?: boolean; // default true
  headerTextAlign?: 'center' | 'right' | 'left';
  cellTextAlign?: 'center' | 'right' | 'left';
  groupCellTextAlign?: 'center' | 'right' | 'left';
  sticky?: boolean;
  textColor?: string;
  hasTags?: boolean;
  sx?: SxProps<Theme>;
  editProps?: IEditProps;
  consolidateType?: 'SUM' | 'AVG';

  ////Grouped
  isGroupedCell?: boolean; // default false
  groupedKeys?: Array<keyof T>; // array contains the data keys
  groupSortKey?: keyof T; // This key should be same as value within groupedKeys
  groupDisplayKeys?: string[];
  groupHeaderDisplaySx?: SxProps<Theme>[];
}

export interface IEditProps {
  fieldType: filterFieldTypes;
  onChange: any;
  options?: ISelectOptions[];
  fieldSx?: SxProps;
  customCell?: React.FC;
}

export type IDropDownFieldTypes = SelectProps &
  IDefaultFieldTypes & {
    id: string;
    options: ISelectOptions[];
    title: string;
    placeholder?: string;
    allowAllOptions?: boolean;
    dependantFilterName?: string;
  };
type IDateRangeFieldProps = IDefaultFieldTypes & {
  title: string;
  format?: string;
  sx?: SxProps<Theme>;
  options?: ISelectOptions[];
};

type IRangeTexboxFieldProps = IDefaultFieldTypes & {min?: number | null; max?: number | null};

export enum filterFieldTypes {
  DROPDOWN = 'DROPDOWN',
  DATE_RANGE = 'DATERANGE',
  TEXTBOX = 'TEXTBOX',
  CUSTOM = 'CUSTOM',
  RANGE_TEXTBOX = 'RANGETEXTBOX',
  MULTISELECT = 'MULTISELECT',
  AUTOCOMPLETE = 'AUTOCOMPLETE'
}

interface IDefaultFieldTypes {
  type: filterFieldTypes;
  property: string; /// Name/key of the field.
  mode?: 'Primary' | 'Secondary';
  defaultValue?: string | string[];
}

/**
 * Template will contain value which is default
 */
export type IFilterTemplate = IDropDownFieldTypes | IDateRangeFieldProps | IRangeTexboxFieldProps;
