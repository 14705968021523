import {createSlice} from '@reduxjs/toolkit';
export interface vendorListState {
  loading: boolean;
  vendorList: Array<any>;
  error: string;
}

const initialState: vendorListState = {
  loading: false,
  vendorList: [],
  error: ''
};

const vendorListSlice = createSlice({
  name: 'vendorList',
  initialState,
  reducers: {
    vendorListLoading: (state) => {
      state.loading = true;
    },
    vendorListSuccess: (state, action) => {
      state.vendorList = action.payload;
      state.loading = false;
    },
    vendorListFailure: (state) => {
      state.loading = false;
      state.vendorList = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default vendorListSlice.reducer;
export const {vendorListLoading, vendorListSuccess, vendorListFailure} = vendorListSlice.actions;
