import {createSlice} from '@reduxjs/toolkit';
export interface laborState {
  loading: boolean;
  labor: Array<any>;
  error: string;
}

const initialState: laborState = {
  loading: false,
  labor: [],
  error: ''
};

const laborSlice = createSlice({
  name: 'labor',
  initialState,
  reducers: {
    laborLoading: (state) => {
      state.loading = true;
    },
    laborSuccess: (state, action) => {
      state.labor = action.payload;
      state.loading = false;
    },
    laborFailure: (state) => {
      state.loading = false;
      state.labor = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default laborSlice.reducer;
export const {laborLoading, laborSuccess, laborFailure} = laborSlice.actions;
