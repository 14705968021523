import {TableHead, TableRow, Typography, Box} from '@mui/material';
import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {productListSagaActions} from 'redux/product/list/productList/productListSaga';
import {ItemComponent} from 'features/product/details/productUtil';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import moment from 'moment';
import {tagSagaActions} from 'redux/dashboard/master/tagSaga';
import {vendorSagaActions} from 'redux/dashboard/master/vendorSaga';
import {sizeSagaActions} from 'redux/dashboard/master/sizeSaga';
import {packSagaActions} from 'redux/dashboard/master/packSaga';
import {alertSagaActions} from 'redux/dashboard/master/alertSaga';
import {viewInFilterOptions} from 'config/constants';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';
import {useTheme} from '@mui/material';

interface IPropType {
  atpEnabler?: (val: boolean) => void;
}

interface IProductGridProps {
  itemId: string;
  itemName: string;
  pack: string;
  size: string;
  avgCost: string;
  avgRtl: string;
  onHand: string;
  onOrder: string;
  ohWos: string;
  ohOoWos: string;
  sales: string;
  actMgn: string;
  ohMgn: string;
  ooMgn: string;
  plan: string;
  hasAlert?: boolean;
  tags?: Array<string>;
  id: string;
}

const generateFilterTemplate = (
  masterData: Array<any>,
  startDate: any,
  endDate: any,
  tag: any,
  vendor: any,
  size: any,
  pack: any,
  alert: any,
  store: any
) => {
  const [dataOne, dataTwo, dataThree] = masterData;

  const filterTemplate: IFilterTemplate[] = [
    {
      id: 'prduct-overview-filter-dateRange',
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date (Sales, Margin)',
      format: 'MM/DD/YYYY',
      defaultValue: `${startDate}|${endDate}`,
      sx: {
        width: '210px'
      }
    },
    {
      id: 'prduct-overview-filter-viewIn',
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View in (OH, OO)',
      defaultValue: 'Dollars',
      sx: {
        width: '140px'
      },
      options: viewInFilterOptions
    },
    {
      id: 'prduct-overview-filter-departmentName',
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      allowAllOptions: true,
      sx: {
        width: '140px'
      },
      defaultValue: 'All Departments',
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      id: 'prduct-overview-filter-className',
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '140px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      id: 'prduct-overview-filter-subClassName',
      sx: {
        width: '140px'
      },
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      id: 'prduct-overview-filter-all-tags',
      property: 'tagName',
      title: 'Tag',
      defaultValue: 'All Tags',
      placeholder: 'Tags',
      allowAllOptions: true,
      sx: {
        width: '140px'
      },
      options: generateLabelValue(tag, 'tagName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      id: 'prduct-overview-filter-all-vendors',
      property: 'vendorName',
      title: 'Vendor',
      defaultValue: 'All Vendors',
      placeholder: 'Vendors',
      allowAllOptions: true,
      sx: {
        width: '140px'
      },
      options: generateLabelValue(vendor, 'vendorName')
    },
    {
      id: 'prduct-overview-filter-all-alerts',
      type: filterFieldTypes.DROPDOWN,
      property: 'alertName',
      title: 'Alert',
      defaultValue: 'All Alerts',
      placeholder: 'Alerts',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(alert, 'alertName')
    },
    {
      id: 'prduct-overview-filter-all-pack',
      type: filterFieldTypes.DROPDOWN,
      property: 'numberOfPack',
      title: 'Pack',
      defaultValue: 'All Pack',
      placeholder: 'Pack',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(pack, 'numberOfPack')
    },
    {
      id: 'prduct-overview-filter-size',
      type: filterFieldTypes.DROPDOWN,
      property: 'productSize',
      title: 'Size',
      defaultValue: 'All Size',
      placeholder: 'Size',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(size, 'productSize')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeName',
      title: 'Store',
      defaultValue: 'All Stores',
      placeholder: 'Stores',
      allowAllOptions: true,
      mode: 'Secondary',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(store, 'storeName')
    },
    {
      type: filterFieldTypes.RANGE_TEXTBOX,
      id: 'prduct-overview-filter-avgCost',
      property: 'avgCost',
      title: 'Average Cost',
      mode: 'Secondary',
      min: null,
      max: null
    },
    {
      type: filterFieldTypes.RANGE_TEXTBOX,
      id: 'prduct-overview-filter-avgRetail',
      property: 'avgRtl',
      title: 'Average Retail',
      mode: 'Secondary',
      defaultValue: ''
    }
  ];
  return filterTemplate;
};
const ProductList: React.FC<IPropType> = (props) => {
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const theme: any = useTheme();

  const {productList, loading} = useAppSelector((state) => state.productListSlice);

  const {master} = useAppSelector((state) => state.masterSlice);
  const {tag} = useAppSelector((state) => state.tagSlice);
  const {vendor} = useAppSelector((state) => state.vendorSlice);
  const {size} = useAppSelector((state) => state.sizeSlice);
  const {pack} = useAppSelector((state) => state.packSlice);
  const {alert} = useAppSelector((state) => state.alertSlice);
  const {store} = useAppSelector((state) => state.storeSlice);
  const defaultDateFormat = 'MM/DD/YYYY';
  //To-do- Remove this once demo done
  //const startDate = moment().startOf('week').format(defaultDateFormat);
  const startDate = '01/01/2023';
  const endDate = moment().format(defaultDateFormat);

  const defaultData = generateFilterTemplate(
    master,
    startDate,
    endDate,
    tag,
    vendor,
    size,
    pack,
    alert,
    store
  ).reduce((acc: any, obj) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);
  const generatePayload = (values: IFilterValues) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      if (value && value != '' && value.toString().substring(0, 4).toLowerCase() != 'all ') {
        if (key === 'date' || key === 'avgCost' || key === 'avgRtl') {
          temp.push({key: key, value: value, condition: 'BETWEEN'});
        } else {
          temp.push({key: key, value: value, condition: 'EQUAL'});
        }
      }
    }
    return temp;
  };
  /**Data to be display */
  const dispatchData = () => {
    dispatch({
      type: productListSagaActions.FETCH_PRODUCTLIST_DATA,
      payload: generatePayload(filtervalues)
    });
  };

  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);

  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: tagSagaActions.FETCH_TAG_DATA
    });
    dispatch({
      type: vendorSagaActions.FETCH_VENDOR_DATA
    });
    dispatch({
      type: sizeSagaActions.FETCH_SIZE_DATA
    });
    dispatch({
      type: packSagaActions.FETCH_PACK_DATA
    });
    dispatch({
      type: alertSagaActions.FETCH_ALERT_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
  }, []);

  const onFilterApply = (values: IFilterValues) => {
    setFilterValues(values);
  };

  const LinkComponent = (row: any) => {
    return (
      <Box
        sx={{
          '& a:hover, a:visited, a:focus': {
            color: theme.palette.primary.main
          },
          textAlign: 'center'
        }}
      >
        <Link to={{pathname: `/Product/${row.itemId}`}} state={row}>
          {row.itemId}
        </Link>{' '}
      </Box>
    );
  };

  const StickyHeaderCell = (props: any) => {
    const consolidatedData = generateStickyHeaderValues(props.data, props.columnCell);
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'Background',
                zIndex: theme.zIndex?.appBar + 20,
                top: '64px'
              }}
            >
              <TableStickyCell align='right' sx={{top: '64px'}}></TableStickyCell>
              <TableStickyCell
                colSpan={4}
                sx={{
                  color: 'grayscale.400',
                  borderBottom: 0,
                  fontWeight: 'bold'
                }}
              >
                <Typography
                  id='prduct-overview-stats'
                  variant='subtitle2'
                  sx={{
                    height: '20px',
                    width: '496px',
                    color: theme.palette?.grayscale?.[400],
                    fontFamily: 'Roboto Medium',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '0.2px',
                    lineHeight: '20px',
                    paddingLeft: '8px'
                  }}
                >
                  CONSOLIDATED STATS
                </Typography>
              </TableStickyCell>
            </BorderedTableStickyCell>

            <TableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              -
            </TableStickyCell>
            <BorderedTableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              -
            </BorderedTableStickyCell>
            <TableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              <Typography variant='subtitle2'>
                {consolidatedData.onHand ? consolidatedData.onHand : '$0'}
              </Typography>
            </TableStickyCell>
            <TableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              <Typography variant='subtitle2'>{consolidatedData.onOrder}</Typography>
            </TableStickyCell>
            <TableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              <Typography variant='subtitle2'>
                {consolidatedData?.ohWos ? consolidatedData?.ohWos : 0}
              </Typography>
            </TableStickyCell>
            <BorderedTableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              <Typography variant='subtitle2'>{consolidatedData.ohOoWos}</Typography>
            </BorderedTableStickyCell>
            <TableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              <Typography variant='subtitle2'>{consolidatedData.sales}</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              <Typography variant='subtitle2'>{consolidatedData.actMgn}</Typography>
            </BorderedTableStickyCell>
            <TableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              <Typography variant='subtitle2'>{consolidatedData.ohMgn}</Typography>
            </TableStickyCell>
            <TableStickyCell
              align='right'
              sx={{top: '64px', fontWeight: 'bold', textAlign: 'center'}}
            >
              <Typography variant='subtitle2'>{consolidatedData.ooMgn}</Typography>
            </TableStickyCell>
            {/* <TableStickyCell
              align='right'
              sx={{ top: '64px', fontWeight: 'bold', textAlign: 'center' }}
            >
              <Typography variant='subtitle2'>{consolidatedData.plan}</Typography>
            </TableStickyCell> */}
          </TableRow>
        </TableHead>
      </>
    );
  };
  const columnCell: IColumnCell<IProductGridProps>[] = [
    {
      id: 'itemId',
      name: 'Item Id',
      displayName: 'Item Id',
      customCell: LinkComponent,
      sticky: true,
      headerTextAlign: 'left',
      sx: {
        minWidth: '150px',
        paddingLeft: '20px'
      }
    },
    {
      id: 'itemName',
      name: 'Item Name',
      displayName: 'Item',
      sticky: true,
      hasTags: true,
      customCell: ItemComponent,
      headerTextAlign: 'left',
      cellTextAlign: 'left',
      sx: {
        minWidth: '220px'
      }
    },
    {
      id: 'pack',
      name: 'Pack',
      isColumnSortable: false,
      sticky: true,
      headerTextAlign: 'right',
      cellTextAlign: 'right',
      isNumeric: true,
      sx: {
        minWidth: '70px'
      }
    },
    {
      id: 'size',
      name: 'Size',
      isNumeric: true,
      isGroupBorderRequire: true,
      isColumnSortable: false,
      sticky: true,
      headerTextAlign: 'right',
      cellTextAlign: 'right',
      sx: {
        minWidth: '80px'
      }
    },
    {
      id: 'avgCost',
      name: 'Average Cost',
      displayName: 'AVG COST',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$'
    },
    {
      id: 'avgRtl',
      name: 'Average Retail',
      displayName: 'AVG RTL',
      isNumeric: true,
      prefixText: '$',
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      isGroupBorderRequire: true
    },
    {
      id: 'onHand',
      name: 'On Hand',
      displayName: 'OH HAND',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$',
      consolidateType: 'SUM'
    },
    {
      id: 'onOrder',
      name: 'On Order',
      displayName: 'ON ORDER',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$',
      consolidateType: 'SUM'
    },
    {
      id: 'ohWos',
      name: 'OnHand Weeks Of Supply',
      displayName: 'OH WOS',
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      isNumeric: true,
      consolidateType: 'AVG'
    },
    {
      id: 'ohOoWos',
      name: 'OnHand OnOrder Weeks Of Supply',
      displayName: 'OH+OO WOS',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      isGroupBorderRequire: true,
      consolidateType: 'AVG'
    },
    {
      id: 'sales',
      name: 'Sales',
      displayName: 'SALES',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$',
      consolidateType: 'SUM'
    },
    {
      id: 'actMgn',
      name: 'Actual Margin',
      displayName: 'ACT MGN',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$',
      isGroupBorderRequire: true,
      consolidateType: 'SUM'
    },
    {
      id: 'ohMgn',
      name: 'OnHand Margin',
      displayName: 'OH MGN',
      suffixText: '%',
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      isNumeric: true,
      consolidateType: 'AVG'
    },
    {
      id: 'ooMgn',
      name: 'OnOrder Margin',
      displayName: 'OO MGN',
      suffixText: '%',
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      isNumeric: true,
      consolidateType: 'AVG'
    }
    // {
    //   id: 'plan',
    //   name: 'plan',
    //   displayName: 'PLAN',
    //   isNumeric: true,
    //   headerTextAlign: 'center',
    //   cellTextAlign: 'center',
    //   suffixText: '%',
    //   isColumnSortable: false,
    //   consolidateType: 'AVG'
    // }
  ];

  const getRowIds = (ids: readonly string[]) => {
    //type should be same as selected state in enhanced table
    props.atpEnabler && props.atpEnabler(!(ids.length > 0));
  };
  if (productList && productList.length > 0) {
    return (
      <Box>
        <GridFilter
          id='product-overview'
          values={filtervalues}
          filterTemplate={generateFilterTemplate(
            master,
            startDate,
            endDate,
            tag,
            vendor,
            size,
            pack,
            alert,
            store
          )}
          onFilterChange={onFilterApply}
          onReset={() => {
            setFilterValues(defaultData);
          }}
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='product-list-load' />
          </Box>
        )}
        <EnhancedTable<IProductGridProps>
          id={'productListOverview'}
          height={844}
          rowData={productList}
          stickyRows={<StickyHeaderCell data={productList} columnCell={columnCell} />}
          columnCell={columnCell}
          rowKey={'itemId'}
          defaultSortKey={'itemId'}
          showTags={true}
          componentType='paper'
          allowAlternateColor={true}
          getSelectedIds={getRowIds}
          rowsPerPage={25}
          selectable
          showAlertPopup
        />
      </Box>
    );
  } else {
    return (
      <Box>
        <GridFilter
          values={filtervalues}
          filterTemplate={generateFilterTemplate(
            master,
            startDate,
            endDate,
            tag,
            vendor,
            size,
            pack,
            alert,
            store
          )}
          onFilterChange={onFilterApply}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-overview'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='product-list-load' />
          ) : (
            <CustomError
              id='product-list-error'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      </Box>
    );
  }
};

export default ProductList;
