import React from 'react';
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  Typography,
  useTheme,
  Stack
} from '@mui/material';
import {ReactComponent as Close} from 'assets/images/icons/Icons & UI-Close-Close Icon.svg';
import CustomButton from 'components/baseComponents/customButton';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {topTenSalesItemSagaActions} from 'redux/report/sales/categorySupplier/topTenItemSalesSaga';
import {decimalConverter} from '../../../../util/arrayOperations';

interface IPropType {
  closePopup: () => void;
  selectedColumn: string;
  dateRange: any;
}

const TopTenItems: React.FC<IPropType> = (props) => {
  const {dateRange, selectedColumn} = props;
  const {endDate, fromDate} = dateRange;
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const {topTenSalesItems} = useAppSelector((state) => state.topTenSalesItemsSlice);

  React.useEffect(() => {
    dispatch({
      type: topTenSalesItemSagaActions.FETCH_TOPTENITEMS_SAGA,
      payload: {
        type: 'sales',
        fromDate: fromDate,
        toDate: endDate
      }
    });
  }, []);

  return (
    <Box>
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #979797',
          width: '623px', //has been fixed to this width w.r.t. entire popup
          background: theme.palette.common.white,
          position: 'fixed',
          borderTopRightRadius: '2px',
          borderTopLeftRadius: '4px'
        }}
      >
        <Typography variant='subtitle1' sx={{p: 2}}>
          Top 10 Items For {`${selectedColumn}`}
        </Typography>
        <CustomButton
          id='categorySupplierClose'
          onClick={props.closePopup}
          sx={{cursor: 'pointer'}}
          disableRipple
        >
          <Close />
        </CustomButton>
      </Stack>
      <Box
        sx={{
          pt: '64px'
        }}
      >
        <TableContainer component={Paper} sx={{overflowX: 'hidden', overflowY: 'scroll'}}>
          <Table id={'topTenSalesItemstableId'}>
            <TableBody>
              {topTenSalesItems.length > 0 &&
                topTenSalesItems.map((ele: any, index: number) => {
                  return (
                    <TableRow
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        mx: 1,
                        borderBottom: '1px solid rgba(224, 224, 224, 1)'
                      }}
                      key={`popup-${ele.itemId + index}`}
                    >
                      <TableCell sx={{padding: 0.95, border: 'none'}}>
                        <img
                          style={{
                            width: '49px',
                            height: '49px',
                            border: `1px solid ${theme.palette.primary.main}`
                          }}
                          src={ele.img}
                        />
                      </TableCell>
                      <TableCell sx={{border: 'none'}}>
                        <Typography
                          variant='caption'
                          sx={{
                            color: 'grayscale.600',
                            fontSize: '12px',
                            fontFamily: 'Roboto Medium'
                          }}
                        >
                          {ele.itemId}&nbsp;&#x2022;&nbsp;
                          {ele.upcData}&nbsp;&#x2022;&nbsp;
                          {ele.pack}/{ele.size}
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            color: 'common.black',
                            width: '250px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            boxSizing: 'border-box',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {ele.itemName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{border: 'none'}}>
                        <Typography
                          variant='caption'
                          sx={{
                            color: 'grayscale.600',
                            fontSize: '12px',
                            fontFamily: 'Roboto Medium'
                          }}
                        >
                          Retail
                        </Typography>
                        <Typography variant='body2' sx={{color: 'common.black'}}>
                          {decimalConverter(ele.retailData)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{border: 'none'}}>
                        <Typography
                          variant='caption'
                          sx={{
                            color: 'grayscale.600',
                            fontSize: '12px',
                            fontFamily: 'Roboto Medium'
                          }}
                        >
                          Units
                        </Typography>

                        {ele.unitsData === '' ? (
                          <Typography variant='body2' sx={{color: 'common.black'}}></Typography>
                        ) : (
                          <Typography variant='body2' sx={{color: 'common.black'}}>
                            ele.unitsData
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{border: 'none'}}>
                        <Typography
                          variant='caption'
                          sx={{
                            color: 'grayscale.600',
                            fontSize: '12px',
                            fontFamily: 'Roboto Medium'
                          }}
                        >
                          Cases
                        </Typography>
                        <Typography variant='body2' sx={{color: 'common.black'}}>
                          {ele.cases}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TopTenItems;
