export const data = [
  {
    role: 'BUYER',
    feature: [
      {
        name: 'dashboard',
        routePath: '/Dashboard',
        permission: [
          {
            accessItem: 'twsTile',
            access: ['read']
          },
          {
            accessItem: 'adPromos',
            access: ['read']
          },
          {
            accessItem: 'topVendors',
            access: ['read']
          },
          {
            accessItem: 'remainder',
            access: ['read']
          },
          {
            accessItem: 'expiryTile',
            access: ['read']
          },
          {
            accessItem: 'classPerformance',
            access: ['read']
          },
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'margin',
            access: ['read']
          }
        ]
      },
      {
        name: 'product',
        routePath: '/Product',
        permission: [
          {
            accessItem: 'overview',
            access: ['read']
          },
          {
            accessItem: 'warehouse',
            access: ['read']
          },
          {
            accessItem: 'store',
            access: ['read']
          },
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'expiry',
            access: ['read']
          }
        ]
      },
      {
        name: 'vendor',
        routePath: '/Vendor',
        permission: [
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'products',
            access: ['read']
          },
          {
            accessItem: 'supply chain',
            access: ['read']
          }
        ]
      },
      {
        name: 'purchase order',
        routePath: '/PO',
        permission: [
          {
            accessItem: 'self',
            access: ['read']
          }
        ]
      },
      {
        name: 'report',
        routePath: '/Report',
        permission: [
          {
            accessItem: 'sales',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Demand Forecast',
                access: ['read']
              },
              {
                accessItem: 'Category/Supplier Sales',
                access: ['read']
              },
              {
                accessItem: 'Report 3',
                access: ['read']
              },
              {
                accessItem: 'Report 4',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Warehouse Liability',
                access: ['read']
              },
              {
                accessItem: 'Inventory Balances',
                access: ['read']
              },
              {
                accessItem: 'Perpetual Inventory Averages',
                access: ['read']
              },
              {
                accessItem: 'Received PO Items',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          }
        ]
      }
    ]
  },
  {
    role: 'ASST BUYER',
    feature: [
      {
        name: 'dashboard',
        routePath: '/Dashboard',
        permission: [
          {
            accessItem: 'sales', //should match dashboard template widget ID,
            access: []
          },
          {
            accessItem: 'labor', //should match dashboard template widget ID,
            access: []
          },
          {
            accessItem: 'shrink',
            access: []
          },
          {
            accessItem: 'margin',
            access: []
          },
          {
            accessItem: 'inventory',
            access: ['read']
          },
          {
            accessItem: 'remainder',
            access: ['read']
          },
          {
            accessItem: 'poAlert',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: 'overview',
                access: ['read']
              },
              {
                accessItem: 'pending review',
                access: ['read']
              },
              {
                accessItem: 'late asn',
                access: ['read']
              },
              {
                accessItem: 'past due',
                access: ['read']
              },
              {
                accessItem: 'over/short',
                access: ['read']
              },
              {
                accessItem: 'qc fail',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'iCare', //urgent store alert
            access: ['read']
          },
          {
            accessItem: 'otbTile',
            access: []
          },
          {
            accessItem: 'cycleCount',
            access: []
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'twsTile',
            access: []
          },
          {
            accessItem: 'storeAlert',
            access: []
          },
          {
            accessItem: 'topVendors',
            access: []
          },
          {
            accessItem: 'topTwentyVendors',
            access: []
          },
          {
            accessItem: 'adPromos',
            access: []
          },
          {
            accessItem: 'ItemAlertTile',
            access: []
          },
          {
            accessItem: 'newPOs',
            access: ['read']
          },
          {
            accessItem: 'classPerformance',
            access: []
          }
        ]
      },
      {
        name: 'product',
        routePath: '',
        permission: [
          {
            accessItem: 'overview', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'warehouse', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'store', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'sales', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'expiry', //should match dashboard template widget ID,
            access: ['read']
          }
        ]
      },
      {
        name: 'vendor',
        routePath: '/Vendor',
        permission: [
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'products',
            access: ['read']
          },
          {
            accessItem: 'supply chain',
            access: ['read']
          }
        ]
      },
      {
        name: 'purchase order',
        routePath: '/PO',
        permission: [
          {
            accessItem: 'self',
            access: ['read']
          }
        ]
      },
      {
        name: 'report',
        routePath: '/Report',
        permission: [
          {
            accessItem: 'sales',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Demand Forecast',
                access: ['read']
              },
              {
                accessItem: 'Category/Supplier Sales',
                access: ['read']
              },
              {
                accessItem: 'Report 3',
                access: ['read']
              },
              {
                accessItem: 'Report 4',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Warehouse Liability',
                access: ['read']
              },
              {
                accessItem: 'Inventory Balances',
                access: ['read']
              },
              {
                accessItem: 'Perpetual Inventory Averages',
                access: ['read']
              },
              {
                accessItem: 'Received PO Items',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          }
        ]
      }
    ]
  },
  {
    role: 'STORE MANAGER',
    feature: [
      {
        name: 'dashboard',
        routePath: '/Dashboard',
        permission: [
          {
            accessItem: 'sales', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'labor', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'shrink',
            access: []
          },
          {
            accessItem: 'margin',
            access: []
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: 'department inventory',
                access: ['read']
              },
              {
                accessItem: 'store inventory',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'remainder',
            access: ['read']
          },
          {
            accessItem: 'poAlert',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: 'overview',
                access: ['read']
              },
              {
                accessItem: 'pending review',
                access: ['read']
              },
              {
                accessItem: 'late asn',
                access: ['read']
              },
              {
                accessItem: 'past due',
                access: ['read']
              },
              {
                accessItem: 'over/short',
                access: ['read']
              },
              {
                accessItem: 'qc fail',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'iCare', //urgent store alert
            access: ['read']
          },
          {
            accessItem: 'otbTile',
            access: []
          },
          {
            accessItem: 'cycleCount',
            access: []
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'twsTile',
            access: []
          },
          {
            accessItem: 'storeAlert',
            access: ['read']
          },
          {
            accessItem: 'topVendors',
            access: []
          },
          {
            accessItem: 'topTwentyVendors',
            access: []
          },
          {
            accessItem: 'adPromos',
            access: []
          },
          {
            accessItem: 'ItemAlertTile',
            access: ['read']
          },
          {
            accessItem: 'newPOs',
            access: []
          },
          {
            accessItem: 'classPerformance',
            access: []
          }
        ]
      },
      {
        name: 'product',
        routePath: '',
        permission: [
          {
            accessItem: 'overview', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'warehouse', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'store', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'sales', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'expiry',
            access: ['read']
          }
        ]
      },
      {
        name: 'vendor',
        routePath: '/Vendor',
        permission: [
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'products',
            access: ['read']
          },
          {
            accessItem: 'supply chain',
            access: ['read']
          }
        ]
      },
      {
        name: 'report',
        routePath: '/Report',
        permission: [
          {
            accessItem: 'sales',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Demand Forecast',
                access: ['read']
              },
              {
                accessItem: 'Category/Supplier Sales',
                access: ['read']
              },
              {
                accessItem: 'Report 3',
                access: ['read']
              },
              {
                accessItem: 'Report 4',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Warehouse Liability',
                access: ['read']
              },
              {
                accessItem: 'Inventory Balances',
                access: ['read']
              },
              {
                accessItem: 'Perpetual Inventory Averages',
                access: ['read']
              },
              {
                accessItem: 'Received PO Items',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          }
        ]
      }
    ]
  },
  {
    role: 'DISTRICT MANAGER',
    feature: [
      {
        name: 'dashboard',
        routePath: '/Dashboard',
        permission: [
          {
            accessItem: 'sales', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'labor', //should match dashboard template widget ID,
            access: []
          },
          {
            accessItem: 'shrink',
            access: ['read']
          },
          {
            accessItem: 'margin',
            access: []
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: 'short code inventory',
                access: []
              },
              {
                accessItem: "inventory (1000's dollars)",
                access: ['read']
              },
              {
                accessItem: 'department inventory',
                access: []
              },
              {
                accessItem: 'store inventory',
                access: []
              }
            ]
          },
          {
            accessItem: 'remainder',
            access: ['read']
          },
          {
            accessItem: 'poAlert',
            access: [],
            accessSubItem: [
              {
                accessItem: 'overview',
                access: []
              },
              {
                accessItem: 'pending review',
                access: []
              },
              {
                accessItem: 'late asn',
                access: []
              },
              {
                accessItem: 'past due',
                access: []
              },
              {
                accessItem: 'over/short',
                access: []
              },
              {
                accessItem: 'qc fail',
                access: []
              }
            ]
          },
          {
            accessItem: 'iCare', //urgent store alert
            access: []
          },
          {
            accessItem: 'otbTile',
            access: []
          },
          {
            accessItem: 'cycleCount',
            access: [],
            accessSubItem: [
              {
                accessItem: 'item count variance',
                access: ['read']
              },
              {
                accessItem: 'store count variance',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'twsTile',
            access: []
          },
          {
            accessItem: 'storeAlert',
            access: []
          },
          {
            accessItem: 'topVendors',
            access: []
          },
          {
            accessItem: 'topTwentyVendors',
            access: []
          },
          {
            accessItem: 'adPromos',
            access: []
          },
          {
            accessItem: 'ItemAlertTile',
            access: []
          },
          {
            accessItem: 'newPOs',
            access: []
          },
          {
            accessItem: 'classPerformance',
            access: []
          }
        ]
      },
      {
        name: 'product',
        routePath: '',
        permission: [
          {
            accessItem: 'store', //should match dashboard template widget ID,
            access: ['read']
          }
        ]
      },
      {
        name: 'vendor',
        routePath: '/Vendor',
        permission: [
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'products',
            access: ['read']
          },
          {
            accessItem: 'supply chain',
            access: ['read']
          }
        ]
      }
    ]
  },
  {
    role: 'EXECUTIVE',
    feature: [
      {
        name: 'dashboard',
        routePath: '/Dashboard',
        permission: [
          {
            accessItem: 'sales', //should match dashboard template widget ID,
            access: []
          },
          {
            accessItem: 'labor', //should match dashboard template widget ID,
            access: []
          },
          {
            accessItem: 'shrink',
            access: []
          },
          {
            accessItem: 'margin',
            access: []
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: 'short code inventory',
                access: []
              },
              {
                accessItem: "inventory (1000's dollars)",
                access: ['read']
              },
              {
                accessItem: 'department inventory',
                access: []
              },
              {
                accessItem: 'store inventory',
                access: []
              }
            ]
          },
          {
            accessItem: 'remainder',
            access: ['read']
          },
          {
            accessItem: 'poAlert',
            access: [],
            accessSubItem: [
              {
                accessItem: 'overview',
                access: []
              },
              {
                accessItem: 'pending review',
                access: []
              },
              {
                accessItem: 'late asn',
                access: []
              },
              {
                accessItem: 'past due',
                access: []
              },
              {
                accessItem: 'over/short',
                access: []
              },
              {
                accessItem: 'qc fail',
                access: []
              }
            ]
          },
          {
            accessItem: 'iCare', //urgent store alert
            access: []
          },
          {
            accessItem: 'otbTile',
            access: []
          },
          {
            accessItem: 'cycleCount',
            access: []
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'twsTile',
            access: ['read']
          },
          {
            accessItem: 'storeAlert',
            access: []
          },
          {
            accessItem: 'topVendors',
            access: ['read']
          },
          {
            accessItem: 'topTwentyVendors',
            access: ['read']
          },
          {
            accessItem: 'adPromos',
            access: ['read']
          },
          {
            accessItem: 'ItemAlertTile',
            access: []
          },
          {
            accessItem: 'newPOs',
            access: []
          },
          {
            accessItem: 'classPerformance',
            access: ['read']
          }
        ]
      },
      {
        name: 'product',
        routePath: '',
        permission: []
      },
      {
        name: 'vendor',
        routePath: '/Vendor',
        permission: [
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'products',
            access: ['read']
          }
        ]
      },
      {
        name: 'report',
        routePath: '/Report',
        permission: [
          {
            accessItem: 'sales',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Category/Supplier Sales',
                access: ['read']
              },
              {
                accessItem: 'Report 3',
                access: ['read']
              },
              {
                accessItem: 'Report 4',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          }
        ]
      }
    ]
  },
  //----------********Below one is  using for testing purpose **********----------------
  {
    role: 'ASSTBUYERSTOREMANAGER',
    feature: [
      {
        name: 'dashboard',
        routePath: '/Dashboard',
        permission: [
          {
            accessItem: 'sales', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'labor', //should match dashboard template widget ID,
            access: []
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'shrink',
            access: []
          },
          {
            accessItem: 'margin',
            access: ['read']
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: "inventory (1000's dollars)",
                access: ['read']
              },
              {
                accessItem: 'department inventory',
                access: ['read']
              },
              {
                accessItem: 'store inventory',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'remainder',
            access: ['read']
          },
          {
            accessItem: 'poAlert',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: 'overview',
                access: ['read']
              },
              {
                accessItem: 'pending review',
                access: ['read']
              },
              {
                accessItem: 'late asn',
                access: ['read']
              },
              {
                accessItem: 'past due',
                access: ['read']
              },
              {
                accessItem: 'over/short',
                access: ['read']
              },
              {
                accessItem: 'qc fail',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'iCare', //urgent store alert
            access: ['read']
          },
          {
            accessItem: 'otbTile',
            access: ['read']
          },
          {
            accessItem: 'cycleCount',
            access: []
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'twsTile',
            access: []
          },
          {
            accessItem: 'storeAlert',
            access: ['read']
          },
          {
            accessItem: 'topVendors',
            access: []
          },
          {
            accessItem: 'topTwentyVendors',
            access: []
          },
          {
            accessItem: 'adPromos',
            access: []
          },
          {
            accessItem: 'ItemAlertTile',
            access: ['read']
          },
          {
            accessItem: 'newPOs',
            access: ['read']
          },
          {
            accessItem: 'classPerformance',
            access: []
          }
        ]
      },
      {
        name: 'product',
        routePath: '',
        permission: [
          {
            accessItem: 'overview',
            access: ['read']
          },
          {
            accessItem: 'warehouse',
            access: ['read']
          },
          {
            accessItem: 'store',
            access: ['read']
          },
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'expiry',
            access: ['read']
          }
        ]
      },
      {
        name: 'vendor',
        routePath: '/Vendor',
        permission: [
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'products',
            access: ['read']
          },
          {
            accessItem: 'supply chain',
            access: ['read']
          }
        ]
      },
      {
        name: 'purchase order',
        routePath: '/PO',
        permission: [
          {
            accessItem: 'self',
            access: ['read']
          }
        ]
      },
      {
        name: 'report',
        routePath: '/Report',
        permission: [
          {
            accessItem: 'sales',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Demand Forecast',
                access: ['read']
              },
              {
                accessItem: 'Category/Supplier Sales',
                access: ['read']
              },
              {
                accessItem: 'Report 3',
                access: ['read']
              },
              {
                accessItem: 'Report 4',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Warehouse Liability',
                access: ['read']
              },
              {
                accessItem: 'Inventory Balances',
                access: ['read']
              },
              {
                accessItem: 'Perpetual Inventory Averages',
                access: ['read']
              },
              {
                accessItem: 'Received PO Items',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          }
        ]
      }
    ]
  },
  {
    role: 'ASSTBUYERSTOREDISRICTMANAGER',
    feature: [
      {
        name: 'dashboard',
        routePath: '/Dashboard',
        permission: [
          {
            accessItem: 'sales', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'labor', //should match dashboard template widget ID,
            access: []
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'shrink',
            access: ['read']
          },
          {
            accessItem: 'margin',
            access: ['read']
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: "inventory (1000's dollars)",
                access: ['read']
              },
              {
                accessItem: 'department inventory',
                access: ['read']
              },
              {
                accessItem: 'store inventory',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'remainder',
            access: ['read']
          },
          {
            accessItem: 'poAlert',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: 'overview',
                access: ['read']
              },
              {
                accessItem: 'pending review',
                access: ['read']
              },
              {
                accessItem: 'late asn',
                access: ['read']
              },
              {
                accessItem: 'past due',
                access: ['read']
              },
              {
                accessItem: 'over/short',
                access: ['read']
              },
              {
                accessItem: 'qc fail',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'iCare', //urgent store alert
            access: ['read']
          },
          {
            accessItem: 'otbTile',
            access: ['read']
          },
          {
            accessItem: 'cycleCount',
            access: [],
            accessSubItem: [
              {
                accessItem: 'item count variance',
                access: ['read']
              },
              {
                accessItem: 'store count variance',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'twsTile',
            access: []
          },
          {
            accessItem: 'storeAlert',
            access: ['read']
          },
          {
            accessItem: 'topVendors',
            access: []
          },
          {
            accessItem: 'topTwentyVendors',
            access: []
          },
          {
            accessItem: 'adPromos',
            access: []
          },
          {
            accessItem: 'ItemAlertTile',
            access: ['read']
          },
          {
            accessItem: 'newPOs',
            access: ['read']
          },
          {
            accessItem: 'classPerformance',
            access: []
          }
        ]
      },
      {
        name: 'product',
        routePath: '',
        permission: [
          {
            accessItem: 'overview',
            access: ['read']
          },
          {
            accessItem: 'warehouse',
            access: ['read']
          },
          {
            accessItem: 'store',
            access: ['read']
          },
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'expiry',
            access: ['read']
          }
        ]
      },
      {
        name: 'vendor',
        routePath: '/Vendor',
        permission: [
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'products',
            access: ['read']
          },
          {
            accessItem: 'supply chain',
            access: ['read']
          }
        ]
      },
      {
        name: 'purchase order',
        routePath: '/PO',
        permission: [
          {
            accessItem: 'self',
            access: ['read']
          }
        ]
      },
      {
        name: 'report',
        routePath: '/Report',
        permission: [
          {
            accessItem: 'sales',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Demand Forecast',
                access: ['read']
              },
              {
                accessItem: 'Category/Supplier Sales',
                access: ['read']
              },
              {
                accessItem: 'Report 3',
                access: ['read']
              },
              {
                accessItem: 'Report 4',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Warehouse Liability',
                access: ['read']
              },
              {
                accessItem: 'Inventory Balances',
                access: ['read']
              },
              {
                accessItem: 'Perpetual Inventory Averages',
                access: ['read']
              },
              {
                accessItem: 'Received PO Items',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          }
        ]
      }
    ]
  },
  {
    role: 'ASSTBUYERSTOREDISTRICTMANAGEREXECUTIVE',
    feature: [
      {
        name: 'dashboard',
        routePath: '/Dashboard',
        permission: [
          {
            accessItem: 'sales', //should match dashboard template widget ID,
            access: ['read']
          },
          {
            accessItem: 'labor', //should match dashboard template widget ID,
            access: []
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'shrink',
            access: ['read']
          },
          {
            accessItem: 'margin',
            access: ['read']
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: "inventory (1000's dollars)",
                access: ['read']
              },
              {
                accessItem: 'department inventory',
                access: ['read']
              },
              {
                accessItem: 'store inventory',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'remainder',
            access: ['read']
          },
          {
            accessItem: 'poAlert',
            access: ['read'],
            accessSubItem: [
              {
                accessItem: 'overview',
                access: ['read']
              },
              {
                accessItem: 'pending review',
                access: ['read']
              },
              {
                accessItem: 'late asn',
                access: ['read']
              },
              {
                accessItem: 'past due',
                access: ['read']
              },
              {
                accessItem: 'over/short',
                access: ['read']
              },
              {
                accessItem: 'qc fail',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'iCare', //urgent store alert
            access: ['read']
          },
          {
            accessItem: 'otbTile',
            access: ['read']
          },
          {
            accessItem: 'cycleCount',
            access: [],
            accessSubItem: [
              {
                accessItem: 'item count variance',
                access: ['read']
              },
              {
                accessItem: 'store count variance',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'receivingTile',
            access: []
          },
          {
            accessItem: 'twsTile',
            access: ['read']
          },
          {
            accessItem: 'storeAlert',
            access: ['read']
          },
          {
            accessItem: 'topVendors',
            access: ['read']
          },
          {
            accessItem: 'topTwentyVendors',
            access: ['read']
          },
          {
            accessItem: 'adPromos',
            access: ['read']
          },
          {
            accessItem: 'ItemAlertTile',
            access: ['read']
          },
          {
            accessItem: 'newPOs',
            access: ['read']
          },
          {
            accessItem: 'classPerformance',
            access: ['read']
          }
        ]
      },
      {
        name: 'product',
        routePath: '',
        permission: [
          {
            accessItem: 'overview',
            access: ['read']
          },
          {
            accessItem: 'warehouse',
            access: ['read']
          },
          {
            accessItem: 'store',
            access: ['read']
          },
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'expiry',
            access: ['read']
          }
        ]
      },
      {
        name: 'vendor',
        routePath: '/Vendor',
        permission: [
          {
            accessItem: 'sales',
            access: ['read']
          },
          {
            accessItem: 'products',
            access: ['read']
          },
          {
            accessItem: 'supply chain',
            access: ['read']
          }
        ]
      },
      {
        name: 'purchase order',
        routePath: '/PO',
        permission: [
          {
            accessItem: 'self',
            access: ['read']
          }
        ]
      },
      {
        name: 'report',
        routePath: '/Report',
        permission: [
          {
            accessItem: 'sales',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Demand Forecast',
                access: ['read']
              },
              {
                accessItem: 'Category/Supplier Sales',
                access: ['read']
              },
              {
                accessItem: 'Report 3',
                access: ['read']
              },
              {
                accessItem: 'Report 4',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          },
          {
            accessItem: 'inventory',
            access: ['read'],
            accessSubItems: [
              {
                accessItem: 'Warehouse Liability',
                access: ['read']
              },
              {
                accessItem: 'Inventory Balances',
                access: ['read']
              },
              {
                accessItem: 'Perpetual Inventory Averages',
                access: ['read']
              },
              {
                accessItem: 'Received PO Items',
                access: ['read']
              },
              {
                accessItem: 'Report 5',
                access: ['read']
              },
              {
                accessItem: 'Report 6',
                access: ['read']
              },
              {
                accessItem: 'Report 7',
                access: ['read']
              }
            ]
          }
        ]
      }
    ]
  }
];
