import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  productWareHouseListLoading,
  productWareHouseListFailure,
  productWareHouseListSuccess
} from 'redux/product/list/warehouseList/warehouseListSlice';

export const productWareHouseListSagaActions = {
  FETCH_PRODUCTWAREHOUSELIST_DATA: 'FETCH_PRODUCTWAREHOUSELIST_DATA'
};

export function* fetchProductWareHouseListData(action: any): any {
  try {
    yield put(productWareHouseListLoading());
    const {data} = yield call(ProductAPIS.getWareHouseListData, action.payload);
    yield put(productWareHouseListSuccess(data));
  } catch (error) {
    yield put(productWareHouseListFailure());
  }
}

export default function* watchFetchProductWareHouseListData() {
  yield takeEvery(
    productWareHouseListSagaActions.FETCH_PRODUCTWAREHOUSELIST_DATA,
    fetchProductWareHouseListData
  );
}
