import {createSlice} from '@reduxjs/toolkit';
export interface poItemsState {
  loading: boolean;
  poItems: any;
  error: string;
}

const initialState: poItemsState = {
  loading: false,
  poItems: [],
  error: ''
};

const poItemsSlice = createSlice({
  name: 'poItems',
  initialState,
  reducers: {
    poItemsLoading: (state) => {
      state.loading = true;
    },
    poItemsSuccess: (state, action) => {
      state.poItems = action.payload;
      state.loading = false;
    },
    poItemsFailure: (state) => {
      state.loading = false;
      state.poItems = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default poItemsSlice.reducer;
export const {poItemsLoading, poItemsSuccess, poItemsFailure} = poItemsSlice.actions;
