import React from 'react';
import {Navigate} from 'react-router-dom';
import UserContext from 'features/login/authProvider';
import handleRoleMapping from 'util/helperRouterMap';
import {getLocalStorage} from 'util/tokenizer';
import {useLocation} from 'react-router-dom';
import {ROUTES, urlSeperator} from 'config/constants';

interface IPrivateProps {
  children?: JSX.Element | any;
  redirectPath?: string;
  path: string;
}

export const PrivateRoute: React.FC<IPrivateProps> = ({
  redirectPath = '/Login',
  children: Component,
  path
}) => {
  const location = useLocation();
  const userData = React.useContext(UserContext);
  const roleData = handleRoleMapping(path);
  const user_token = getLocalStorage('user_token');

  if (userData.loggedIn && roleData) {
    // authorized so return child components
    if (location?.search) {
      const decoded = decodeURI(location?.search); //get the URL using location API
      if (decoded.indexOf('redirect') > 0) {
        //checking for presence if redirect keyword
        let getRedirect: any;
        const main = decoded.split(urlSeperator); //spliting the string with separator. This separator is declared in constants file
        //to extract destination page find the split string containing 'redirect' keyword
        getRedirect = main.find((e: any) => e.includes('redirect'));
        getRedirect = getRedirect.split('='); //spliting with = to get route constant
        let destination: string = getRedirect[1].toUpperCase();
        destination = ROUTES[destination as keyof {}]; //get the route matching the destination value
        // to extract filter object find the part which includes 'filter' keyword from split string
        let filterData: any = main.find((e) => e.includes('filter'));
        filterData = filterData.split('='); //splitting with = to get filter object
        filterData = JSON.parse(filterData[1]); //since the object was stringified to append to url we have reverse it
        //finally send the filter object as state and proceed with navigation
        return <Navigate to={destination} state={{filter: filterData}} />;
      }
    } else {
      return Component;
    }
  } else if (user_token) {
    // scenario to allow internal navigation when user is logged in but path
    return Component;
  }
  // not logged in so redirect to login page with the return url
  else {
    return <Navigate to={redirectPath} />;
  }
};
