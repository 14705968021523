import {takeEvery, put, call} from 'redux-saga/effects';
import {VendorAPIS} from 'redux/vendor/vendorDetails_service';
import {
  vendorSalesItemFailure,
  vendorSalesItemLoading,
  vendorSalesItemSuccess
} from 'redux/vendor/detailsItemTab/vendorSalesItemSlice';

export const vendorSalesItemSagaActions = {
  FETCH_VENDORSALESITEM_DATA: 'FETCH_VENDORSALESITEM_DATA'
};

export function* fetchVendorSalesItemData(action: any): any {
  try {
    yield put(vendorSalesItemLoading());
    const {data} = yield call(VendorAPIS.getVendorSalesItemData, action.payload);
    yield put(vendorSalesItemSuccess(data));
  } catch (error) {
    yield put(vendorSalesItemFailure());
  }
}

export default function* watchFetchVendorSalesItemData() {
  yield takeEvery(vendorSalesItemSagaActions.FETCH_VENDORSALESITEM_DATA, fetchVendorSalesItemData);
}
