import React from 'react';
import {Typography, Stack, Box} from '@mui/material';
import {styled} from '@mui/system';
import {ReactComponent as UpArrow} from 'assets/images/icons/Icons & UI-Arrows-Arrow_Up.svg';
import {ReactComponent as DownArrow} from 'assets/images/icons/Icons & UI-Arrows-Arrow_Down.svg';
import {CustomCard} from 'components/baseComponents/customCard';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {topWeeklyStoreActions} from 'redux/dashboard/topWeeklySales/topWeeklySalesSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {decimalConverter, generatePayload} from 'util/arrayOperations';
import {useTheme} from '@mui/material/styles';
import {Link} from 'react-router-dom';

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root > div': {
    justifyContent: 'center'
  }
});

const Header = () => {
  return (
    <>
      <Typography variant='subtitle2' sx={{color: 'common.black'}}>
        Top Weekly Sales
      </Typography>
    </>
  );
};

interface IPropType {
  filteredData: any;
}

const TWSWidget: React.FC<IPropType> = (props) => {
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  /**
   * dispatch topWeeklySales Data
   */
  const dispatchData = () => {
    dispatch({
      type: topWeeklyStoreActions.FETCH_TOPWEEKLYSALES_SAGA,
      payload: {
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [props?.filteredData]);

  const {topWeeklySalesStore, loading, error} = useAppSelector(
    (state) => state.topWeeklyStoreSlice
  );

  const Content = () => {
    return (
      <Stack>
        {topWeeklySalesStore.map((item, index) => {
          return (
            <Stack
              key={`${item}-${index}`}
              direction={'row'}
              sx={{
                pt: 0.875,
                pb: 0.75,
                alignItems: 'center',
                borderBottom: `1px solid ${theme.palette.grayscale?.[300]}`
              }}
            >
              <Stack direction={'column'} sx={{pr: 2}}>
                <Stack
                  direction={'row'}
                  sx={{
                    alignItems: 'center',
                    gap: 0.2
                  }}
                >
                  <Typography
                    variant='h6'
                    sx={{fontFamily: 'Roboto Medium', lineHeight: '24px'}}
                    textAlign='left'
                  >
                    {item.currentRank}
                  </Typography>
                  {item.currentRank < item.lastWeekRank ? (
                    <UpArrow style={{paddingLeft: 4, paddingBottom: '2px'}} />
                  ) : (
                    <DownArrow style={{paddingLeft: 4, paddingBottom: '2px'}} />
                  )}
                </Stack>
                <Typography
                  variant='caption'
                  textAlign='left'
                  sx={{fontSize: '12px', whiteSpace: 'nowrap'}}
                >
                  LW: {item.lastWeekRank}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant='body1'
                  textAlign='left'
                  sx={{
                    color: 'primary.main',
                    fontFamily: 'Roboto Medium',
                    maxWidth: '240px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  <Link to={{pathname: `/Product/${item.itemId}`}} state={item}>
                    {item.itemDescription}
                  </Link>{' '}
                </Typography>
                <Typography
                  variant='caption'
                  textAlign='left'
                  sx={{fontSize: '12px', whiteSpace: 'nowrap'}}
                >
                  ${Number(decimalConverter(item?.currentWeekSales)).toLocaleString()}( $
                  {item.avgRetail ? decimalConverter(item.avgRetail) : 0} AVG,{' '}
                  {item.actualMargin ? decimalConverter(item.actualMargin) : 0}%{' '}
                  MGN)&nbsp;&#8226;&nbsp;
                  <Typography
                    variant='caption'
                    sx={{
                      fontSize: '12px',
                      color: parseFloat(item.wos) < 1 ? 'error.main' : 'common.black',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {item.wos} WOS
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  if (topWeeklySalesStore && topWeeklySalesStore.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='topWeeklySalesTile'
          header={<Header />}
          content={loading ? <CustomLoader id='top-weekly-load' /> : <Content />}
          headerSx={{
            height: '32px'
          }}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='topWeeklySalesTile-ID'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='top-weekly-load' />
            ) : (
              <CustomError
                id='top-weekly-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
          headerSx={{
            height: '32px'
          }}
        />
      </CustomBox>
    );
  }
};

export default TWSWidget;
