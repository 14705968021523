import * as React from 'react';
import {Box, Stack, Typography, Grid, Link} from '@mui/material';
import {ReactComponent as DocIcon} from 'assets/images/icons/Document.svg';

interface INotesAttachmentsView {
  files?: string[];
  fileContent?: any;
}

const NotesAttachmentsView: React.FC<INotesAttachmentsView> = (props: INotesAttachmentsView) => {
  const handleDownload = () => {
    // file object
    const file = new Blob([props.fileContent], {type: 'text/plain'});

    // anchor link
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = '100ideas-' + Date.now() + '.txt';

    // simulate link click
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Box
      sx={{
        px: 2,
        py: 4,
        bgcolor: 'grayscale.50',
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.5)'
      }}
    >
      <Stack direction={'row'} sx={{justifyContent: 'space-between', width: '100%'}}>
        <Grid container>
          <Grid sx={{pr: 7}} item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography variant='subtitle1' sx={{pb: 3}}>
              Notes
            </Typography>
            <Typography variant='body2'>No notes added.</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography variant='subtitle1' sx={{pb: 3}}>
              Attachments({props.files && props.files.length})
            </Typography>
            <Stack direction={'row'} sx={{alignItems: 'center'}}>
              {props.files &&
                props.files?.length > 0 &&
                props.files?.map((fileItem, index) => {
                  return (
                    <Stack direction={'row'} sx={{alignItems: 'center'}} key={`file${index}`}>
                      <DocIcon />
                      <Link id={`notes-attach-download-${index}`} onClick={handleDownload}>
                        <Typography
                          variant='body2'
                          sx={{
                            pr: 2,
                            '&:first-of-type': {
                              pl: 0
                            },
                            color: 'primary.main',
                            fontFamily: 'Roboto Medium'
                          }}
                        >
                          {fileItem}
                        </Typography>
                      </Link>
                    </Stack>
                  );
                })}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default NotesAttachmentsView;
