import * as React from 'react';
import {Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import EnhancedTable from 'components/customTable/enhancedTable';
import {TableStickyCell} from 'components/customTable/tableCell';
import {IColumnCell} from 'components/customTable/types';
import {CustomCard} from 'components/baseComponents/customCard';
import {useTheme} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {receivingTileSagaActions} from 'redux/dashboard/receivingTile/receivingTileSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {Box, styled, Tooltip} from '@mui/material';
import {
  dateRangeCalculator,
  formatString,
  generatePayload,
  generateStickyHeaderValues
} from 'util/arrayOperations';

const dateDropDownData = [
  {
    label: 'Yesterday',
    value: 'Yesterday'
  },
  {
    label: 'Week to Date',
    value: 'weekToDate'
  },
  {
    label: 'Last Week',
    value: 'lastWeek'
  },
  {
    label: 'Last 4 Weeks',
    value: 'last4Weeks'
  },
  {
    label: 'Month-to-Date',
    value: 'monthToDate'
  },
  {
    label: 'Quarter-to-Date',
    value: 'quarterToDate'
  },
  {
    label: 'Year-to-Date',
    value: 'yearToDate'
  }
];

interface IReceivingGridProps {
  dept: string;
  cls: number;
  retail: number;
  poCount: number;
  id: string;
}

const StickyHeaderCell = (props: any) => {
  const consolidatedData = generateStickyHeaderValues(props?.data, props?.columnCell);
  return (
    <>
      <TableHead>
        <TableRow>
          <TableStickyCell
            align='left'
            sx={{
              top: '32px',
              color: 'grayscale.400',
              borderBottom: 0,
              paddingLeft: 2
            }}
          >
            <Typography variant='subtitle2'>TOT</Typography>
          </TableStickyCell>
          <TableStickyCell
            align='left'
            sx={{
              top: '32px',
              fontFamily: 'Roboto Medium',
              fontSize: '14px',
              letterSpacing: '0.1px'
            }}
          >
            {consolidatedData?.retail}
          </TableStickyCell>
          <TableStickyCell align='center' sx={{top: '32px'}}>
            {consolidatedData?.poCount}
          </TableStickyCell>
        </TableRow>
      </TableHead>
    </>
  );
};

const CustomBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

interface IPropType {
  defaultValue: string;
  filteredData: any;
}

const ReceivingTile: React.FC<IPropType> = (props) => {
  const [receivingType, setReceivingType] = React.useState(props?.defaultValue);
  const [dateHistory, setDateHistory] = React.useState('lastWeek');

  const th = useTheme();
  const dispatch = useAppDispatch();
  /**
   * dispatch receiving tile Data
   */
  const dispatchData = () => {
    dispatch({
      type: receivingTileSagaActions.FETCH_RECEIVINGTILE_DATA,
      payload: {
        type: receivingType,
        dateHistory,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [props?.filteredData, receivingType, dateHistory]);

  const receivingOptions = [
    {
      label: 'Dept Receiving',
      value: 'Department'
    },
    {
      label: 'Cls Receiving',
      value: 'class'
    }
  ];

  /**
   * On Change event for dropdown
   * @param event Dropdown
   */

  const handleSelect = (event: any): void => {
    setReceivingType(formatString(event?.target.value));
  };

  const handleDateSelect = (event: any): void => {
    setDateHistory(event?.target.value);
  };

  const {receivingTile, loading, error} = useAppSelector((state) => state.receivingTileSlice);
  const LinkComponent = (row: any) => {
    const filter = {
      filter: {
        class: row?.className || '',
        department: row?.departmentName || '',
        issueDate: dateRangeCalculator(dateHistory)
      }
    };
    const itemname = row?.className ? row?.className : row?.departmentName;
    if (itemname?.length > 8) {
      return (
        <Tooltip title={itemname} placement='top'>
          <Link to={{pathname: `/PO`}} state={filter}>
            {itemname.substring(0, 8) + '...'}
          </Link>
        </Tooltip>
      );
    } else {
      return (
        <>
          <Link to={{pathname: `/PO`}} state={filter}>
            {' '}
            {itemname}
          </Link>{' '}
        </>
      );
    }
  };

  const ClassColumnCell: IColumnCell<IReceivingGridProps>[] = [
    {
      id: 'cls',
      name: 'cls',
      displayName: 'CLS',
      customCell: LinkComponent,
      textColor: th.palette.primary.main,
      sx: {
        maxWidth: '40px'
      }
    },
    {
      id: 'retail',
      name: 'retail',
      displayName: 'retail',
      isNumeric: true,
      headerTextAlign: 'center',
      prefixText: '$',
      consolidateType: 'SUM',
      cellTextAlign: 'center'
    },
    {
      id: 'poCount',
      name: 'poCount',
      displayName: 'pocount',
      isNumeric: true,
      headerTextAlign: 'left',
      cellTextAlign: 'center',
      prefixText: '$',
      consolidateType: 'SUM',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    }
  ];
  const departmentColumnCell: IColumnCell<IReceivingGridProps>[] = [
    {
      id: 'dept',
      name: 'dept',
      displayName: 'dept',
      textColor: th.palette.primary.main,
      customCell: LinkComponent,
      sx: {
        maxWidth: '40px'
      }
    },
    {
      id: 'retail',
      name: 'retail',
      displayName: 'retail',
      isNumeric: true,
      headerTextAlign: 'center',
      prefixText: '$',
      consolidateType: 'SUM',
      cellTextAlign: 'center'
    },
    {
      id: 'poCount',
      name: 'poCount',
      displayName: 'pocount',
      isNumeric: true,
      headerTextAlign: 'left',
      cellTextAlign: 'center',
      prefixText: '$',
      consolidateType: 'SUM',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    }
  ];

  const Header = () => {
    return (
      <DropDownWrapper>
        <CustomSelect
          id='receivingTile'
          value={receivingType}
          onChange={handleSelect}
          options={receivingOptions}
        />
        <CustomSelect
          id='receivingTileDate'
          value={dateHistory}
          options={dateDropDownData}
          onChange={handleDateSelect}
          title='Date'
        />
      </DropDownWrapper>
    );
  };

  const Content = () => {
    return (
      <>
        <EnhancedTable<IReceivingGridProps>
          width={'100%'}
          rowData={receivingTile[receivingType]}
          stickyRows={
            <StickyHeaderCell
              data={receivingTile[receivingType]}
              columnCell={receivingType === 'Department' ? departmentColumnCell : ClassColumnCell}
            />
          }
          columnCell={receivingType === 'Department' ? departmentColumnCell : ClassColumnCell}
          rowKey={'cls'}
          defaultSortKey={'cls'}
          allowAlternateColor={false}
          componentType='box'
          dense={true}
          hidePagination
          id='dashboard-receiving'
        />
      </>
    );
  };

  if (receivingTile[receivingType] && receivingTile[receivingType].length > 0 && !error) {
    return (
      <CustomCard
        id='receivingTile-data'
        header={<Header />}
        content={
          loading[receivingType] ? (
            <CustomBox>
              <CustomLoader id='receiving-load' />
            </CustomBox>
          ) : (
            <Content />
          )
        }
      />
    );
  } else {
    return (
      <CustomCard
        id='receivingTile-noData'
        header={<Header />}
        content={
          loading[receivingType] ? (
            <CustomBox>
              <CustomLoader id='receiving-load' />
            </CustomBox>
          ) : (
            <CustomError
              id='receiving-error'
              onReload={() => {
                dispatchData();
              }}
            />
          )
        }
      />
    );
  }
};

export default ReceivingTile;
