import React from 'react';
import {Stack, Box, Grid, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {CustomCard} from 'components/baseComponents/customCard';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import CustomSelect from 'components/baseComponents/customSelect';
import {generatePayload} from 'util/arrayOperations';
import {expirySagaActions} from 'redux/dashboard/expiryTile/expirySaga';
import LineGraphComponent, {IAxis} from 'components/lineGraph';
import moment from 'moment';
import {TILE_WIDTH} from 'config/constants';

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root > div': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
});

interface IPropType {
  defaultValue: string;
  filteredData: any;
}

const ExpiryTile: React.FC<IPropType> = (props) => {
  const [expiryType, setExpiryType] = React.useState(props.defaultValue);
  const dispatch = useAppDispatch();
  const [expiryPage, setExpiryPage] = React.useState<number>(0);
  const defaultDateFormat = 'MM/DD/YYYY';
  const dataLength = 9;

  const expiryDBApiCall = (pageNo: number) => {
    dispatch({
      type: expirySagaActions.FETCH_EXPIRY_DATA,
      payload: {
        type: expiryType,
        date:
          pageNo === 0
            ? moment().format(defaultDateFormat)
            : moment()
                .subtract((pageNo * dataLength - 1) * 7, 'days')
                .format(defaultDateFormat),
        count: pageNo === 0 ? 10 : 11,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    // if we change any filter or aged type, set pageno to 1
    expiryDBApiCall(0);
  }, [props?.filteredData, expiryType]);
  const {expiry, loading, error} = useAppSelector((state) => state.expirySlice);
  const expiryOptions = [
    {
      label: '> 1 Month',
      value: '> 1 Month'
    },
    {
      label: '> 7 Days',
      value: '> 7 Days'
    }
  ];

  /**
   * On Change event for dropdown
   * @param event Dropdown
   */
  const handleSelect = (event: any): void => {
    setExpiryType(event?.target.value);
  };
  /**
   * Fetch new previous set of data
   * @param date Need to fetch data from this Date
   */
  const prevClick = () => {
    let pageNo = expiryPage;
    if (expiryPage === 1) {
      setExpiryPage(0);
      pageNo = 0;
    } else {
      pageNo = expiryPage - 1;
      setExpiryPage(expiryPage - 1);
    }
    expiryDBApiCall(pageNo);
  };
  /**
   * Fetch new previous set of data
   * @param date Need to fetch data from 10 points to this Date
   */
  const nextClick = () => {
    setExpiryPage(expiryPage + 1);
    expiryDBApiCall(expiryPage + 1);
  };

  const Header = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <Typography
              sx={{color: 'common.black', mt: '20px'}}
              variant='subtitle2'
              textAlign='left'
            >
              Expiry(1000s Dollars)
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <CustomSelect
              id='expiryTile'
              value={expiryType}
              onChange={handleSelect}
              options={expiryOptions}
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const xAxis = {
    name: 'duration',
    displayTitle: 'duration'
  };

  ////// Create YAxis dynamically based on API object
  const singleRow = expiry && expiry[0] ? expiry[0] : {};
  const yAxis: Array<IAxis> = [];
  Object.keys(singleRow).forEach((ele) => {
    if (ele != xAxis.name) {
      yAxis.push({
        name: ele,
        displayTitle: ele
      });
    }
  });

  const Content = () => {
    const layout = {
      width: 575,
      height: 200,
      autosize: false,
      showlegend: false,
      paper_bgcolor: 'rgba(0,0,0,0)', // transparent
      plot_bgcolor: 'rgba(0,0,0,0)', // transparent
      font: {
        size: 12
      },
      xaxis: {
        showgrid: false,
        showline: false,
        automargin: false, ///used to display x axis label
        range: [0.5, dataLength + 0.5] /// no change required
      } as unknown as Plotly.LayoutAxis,
      yaxis: {
        showline: false,
        automargin: true
      },
      margin: {
        l: 1,
        r: 2,
        b: 1,
        t: 0,
        pad: 1
      }
    };
    return (
      <Stack sx={{mt: 1.75}}>
        <>
          {
            <LineGraphComponent
              data={expiry}
              xAxis={xAxis}
              layout={layout}
              yAxis={[...yAxis]}
              id='expiryLineGraph'
              prevClick={prevClick}
              pageNo={expiryPage}
              nextClick={nextClick}
            />
          }
        </>
      </Stack>
    );
  };

  if (expiry && expiry.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='dashboard-expiryTile'
          width={TILE_WIDTH.two}
          header={<Header />}
          headerSx={{marginBottom: '8px'}}
          content={loading ? <CustomLoader id='expiryTile-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='dashboard-expiryTile'
          width={TILE_WIDTH.two}
          header={<Header />}
          headerSx={{marginBottom: '8px'}}
          content={
            loading ? (
              <CustomLoader id='expiryTile-load' />
            ) : (
              <CustomError
                id='expiryTile-error'
                onReload={() => {
                  expiryDBApiCall(0);
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default ExpiryTile;
