import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {newPOFailure, newPOLoading, newPOSuccess} from 'redux/dashboard/newPOs/newPOSlice';

export const newPOSagaActions = {
  FETCH_NEW_PO_DATA: 'FETCH_NEW_PO_DATA'
};

export function* fetchNewPOData(action: any): any {
  try {
    yield put(newPOLoading());
    const {data} = yield call(DashboardServiceAPIS.getNewPOData, action.payload, action.payload);
    yield put(newPOSuccess(data));
  } catch (error) {
    yield put(newPOFailure());
  }
}

export default function* watchFetchNewPOData() {
  yield takeEvery(newPOSagaActions.FETCH_NEW_PO_DATA, fetchNewPOData);
}
