import {createSlice} from '@reduxjs/toolkit';
export interface districtState {
  loading: boolean;
  district: Array<any>;
  error: string;
}

const initialState: districtState = {
  loading: false,
  district: [],
  error: ''
};

const districtSlice = createSlice({
  name: 'district',
  initialState,
  reducers: {
    districtLoading: (state) => {
      state.loading = true;
    },
    districtSuccess: (state, action) => {
      state.district = action.payload;
      state.loading = false;
    },
    districtFailure: (state) => {
      state.loading = false;
      state.district = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default districtSlice.reducer;
export const {districtLoading, districtSuccess, districtFailure} = districtSlice.actions;
