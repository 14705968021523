import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  productDemandForeCastItemSuccess,
  productDemandForeCastItemLoading,
  productDemandForeCastItemFailure
} from 'redux/product/details/productDemandForeCastIngItem/productDemandForeCastIngItemSlice';
import {mockData} from 'util/mock';
export const productDemandForeCastItemIngSagaActions = {
  FETCH_PRODUCTDEMANDFORECASTINGITEM_DATA: 'FETCH_PRODUCTDEMANDFORECASTINGITEM_DATA'
};
const addValue = (value: any) => {
  value = JSON.parse(JSON.stringify(value));
  const result = [value];
  for (let i = 0; i < 4; i++) {
    const newItem = {
      store: `00${7 - i}-Redmond`,
      demandForecast: value.demandForecast.map((cur: any) => {
        // cur.value = 5 + Number(cur.value);
        return {
          ...cur,
          value: cur.value === '0' ? '0' : Number(cur.value) + (i + 1) * 5 + ''
        };
      })
    };
    result.push(newItem);
  }
  result.map((cur) => {
    cur.demandForecast.forEach((itm: any) => {
      itm.date = itm.date.replaceAll('-', '/');
    });
  });
  return result;
};
export function* fetchProductDemandForeCastIngItemData(action: any): any {
  try {
    yield put(productDemandForeCastItemLoading());
    const analyze = action.payload.data.filter((cur: any) => cur.key === 'analyzeBy')[0] || {};
    let newData = [];
    if (analyze.value.toLowerCase() === 'sales') {
      const {data} = yield call(ProductAPIS.getProductDemandForeCastIngItemData, action.payload);
      data[0].store = data[0].store_name;
      delete data[0].store_name;
      newData = addValue(data[0]);
      yield put(productDemandForeCastItemSuccess(newData));
    } else {
      const subset = action.payload.data.filter((cur: any) => cur.key === 'subset')[0] || {};
      const key = analyze.value.toLowerCase() + subset.value.toLowerCase();
      const tKey: any = key.replaceAll(' ', '').replaceAll('&', '').replaceAll("'", '');
      // const {data} = yield call(ProductAPIS.getProductDemandForeCastIngItemData, action.payload);
      const data: any = mockData[tKey] || [
        {
          store: '008-Redmond',
          demandForecast: [
            {date: ' 2024', value: '0'},
            {date: ' 2023', value: '0'},
            {date: ' 2022', value: '0'},
            {date: ' 2020', value: '0'},
            {date: ' 2019', value: '0'}
            // {date: '2024/06/01', value: '0'},
            // {date: '2024/05/01', value: '0'},
            // {date: '2024/04/01', value: '0'},
            // {date: '2024/03/01', value: '0'},
            // {date: '2024/02/01', value: '0'},
            // {date: '2024/01/01', value: '0'},
            // {date: '2023/12/01', value: '0'},
            // {date: '2023/11/01', value: '0'},
            // {date: '2023/10/01', value: '0'},
            // {date: '2023/08/01', value: '0'}
          ]
        }
      ];
      let newData = [];
      newData = addValue(data[0]);
      yield put(productDemandForeCastItemSuccess(newData));
    }
  } catch (error) {
    yield put(productDemandForeCastItemFailure());
  }
}

export default function* watchFetchProductDemandForeCastIngItemData() {
  yield takeEvery(
    productDemandForeCastItemIngSagaActions.FETCH_PRODUCTDEMANDFORECASTINGITEM_DATA,
    fetchProductDemandForeCastIngItemData
  );
}
