import {Typography} from '@mui/material';
import CustomInput from 'components/baseComponents/customInput';
import CustomSelect from 'components/baseComponents/customSelect';
import {IEditProps} from 'components/customTable/types';

interface IFieldProviderProps<T> extends IEditProps {
  value: string | boolean | undefined;
  row?: T;
  id: string;
  isNumeric?: boolean; // default false
}

function FieldProvider<T>(props: IFieldProviderProps<T>) {
  const {options, row, onChange, value, id} = props;

  const field = () => {
    switch (props.fieldType) {
      case 'TEXTBOX':
        return (
          <>
            <CustomInput
              sx={{
                ...props?.fieldSx
              }}
              type={props.isNumeric ? 'number' : 'text'}
              value={value}
              name={id}
              onChange={(e) => onChange(e, row)}
            />
            {!value && (
              <Typography sx={{fontSize: '12px', fontWeight: 600, color: 'error.main', pt: 1}}>
                Please Enter value
              </Typography>
            )}
          </>
        );
      case 'DROPDOWN':
        return (
          <>
            <CustomSelect
              id='custom-field-provider'
              name={id}
              sx={{
                pl: 0.5,
                border: (theme) => `1px solid ${theme.palette.grayscale?.[200]}`,
                bgcolor: 'common.white',
                borderRadius: 1,
                ...props?.fieldSx
              }}
              options={options ? options : []}
              onChange={(e) => onChange(e, row)}
              value={value}
            />
          </>
        );
      case 'CUSTOM':
        return <>{props.customCell ? props.customCell(row as unknown as any) : <></>}</>;
      default:
        <></>;
    }
  };

  return <>{field()}</>;
}

export default FieldProvider;
