import {Box, Tooltip} from '@mui/material';

export const ContentComponent = (row: any) => {
  if (row?.store?.length > 13)
    return (
      <Tooltip title={row.store} placement='top'>
        <Box
          sx={{
            maxWidth: '100px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {row.store}
        </Box>
      </Tooltip>
    );
  else {
    return <Box sx={{maxWidth: '100px'}}>{row.store}</Box>;
  }
};

export const ItemComponent = (row: any) => {
  if (row?.itemName?.length > 28)
    return (
      <Tooltip title={row.itemName} placement='top'>
        <Box
          sx={{
            maxWidth: '210px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box'
          }}
        >
          {row.itemName}
        </Box>
      </Tooltip>
    );
  else {
    return <Box sx={{maxWidth: '210px'}}>{row.itemName}</Box>;
  }
};
