import {createSlice} from '@reduxjs/toolkit';
export interface productListState {
  loading: boolean;
  productList: Array<any>;
  error: string;
  searchLoading: boolean;
  searchProductList: Array<any>;
  searchError: string;
  //for product search page
  productSearchLoading: boolean;
  productSearchList: Array<any>;
  productSearchError: string;
}

const initialState: productListState = {
  loading: false,
  productList: [],
  error: '',
  searchLoading: false,
  searchProductList: [],
  searchError: '',
  //for product serach page
  productSearchLoading: false,
  productSearchList: [],
  productSearchError: ''
};

const productListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    productListLoading: (state) => {
      state.loading = true;
    },
    productListSuccess: (state, action) => {
      state.productList = action.payload;
      state.loading = false;
      state.error = '';
    },
    productListFailure: (state) => {
      state.loading = false;
      state.productList = [];
      state.error = 'Request failed with status code 404';
    },
    productSearchLoading: (state) => {
      state.searchLoading = true;
    },
    productSearchSuccess: (state, action) => {
      state.searchProductList = action.payload;
      state.searchLoading = false;
      state.searchError = '';
    },
    productSearchFailure: (state) => {
      state.searchLoading = false;
      state.searchProductList = [];
      state.searchError = 'Request failed with status code 404';
    },
    //for product serach page
    productSearchPageLoading: (state) => {
      state.productSearchLoading = true;
    },
    productSearchPageSuccess: (state, action) => {
      state.productSearchList = action.payload;
      state.productSearchLoading = false;
      state.productSearchError = '';
    },
    productSearchPageFailure: (state) => {
      state.productSearchLoading = false;
      state.productSearchList = [];
      state.productSearchError = 'Request failed with status code 404';
    }
  }
});

export default productListSlice.reducer;
export const {
  productListLoading,
  productListSuccess,
  productListFailure,
  productSearchFailure,
  productSearchLoading,
  productSearchSuccess,
  //for product search page
  productSearchPageLoading,
  productSearchPageSuccess,
  productSearchPageFailure
} = productListSlice.actions;
