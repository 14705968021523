import {createSlice} from '@reduxjs/toolkit';
export interface productStoreListState {
  loading: boolean;
  productStoreList: Array<any>;
  error: string;
}

const initialState: productStoreListState = {
  loading: false,
  productStoreList: [],
  error: ''
};

const productStoreListSlice = createSlice({
  name: 'productStoreList',
  initialState,
  reducers: {
    productStoreListLoading: (state) => {
      state.loading = true;
    },
    productStoreListSuccess: (state, action) => {
      state.productStoreList = action.payload;
      state.loading = false;
    },
    productStoreListFailure: (state) => {
      state.loading = false;
      state.productStoreList = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default productStoreListSlice.reducer;
export const {productStoreListLoading, productStoreListSuccess, productStoreListFailure} =
  productStoreListSlice.actions;
