import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  urgentStoreLoading,
  urgentStoreSuccess,
  urgentStoreFailure
} from 'redux/dashboard/urgentStore/urgentStoreSlice';

export const urgentStoreActions = {
  FETCH_URGENTSTORE_SAGA: 'FETCH_URGENTSTORE_SAGA'
};

export function* fetchUrgentStoreData(action: any): any {
  try {
    yield put(urgentStoreLoading());
    const {data} = yield call(DashboardServiceAPIS.getUrgentStoreData, action.payload.data);
    yield put(urgentStoreSuccess(data));
  } catch (error) {
    yield put(urgentStoreFailure());
  }
}

export default function* watchFetchUrgentStoreData() {
  yield takeEvery(urgentStoreActions.FETCH_URGENTSTORE_SAGA, fetchUrgentStoreData);
}
