import * as React from 'react';
import {styled} from '@mui/material/styles';
import {Button, ButtonProps} from '@mui/material';
import {useTheme} from '@mui/material';

interface IAppButtonProps extends ButtonProps {
  id: string;
  children: string | React.ReactNode;
}

const StyledButton = styled(Button)({
  boxSizing: 'border-box',
  fontFamily: 'Roboto Medium',
  fontSize: '14px',
  letterSpacing: '1.25px',
  textAlign: 'center',
  boxShadow: 'none',
  textTransform: 'uppercase',
  borderRadius: '4px',
  padding: '10px 16px',
  height: '36px',
  '&:hover': {
    boxShadow: 'none'
  }
});

const generateButton = (values: any, theme: any) => {
  const {variant, children, onClick, id, ...rest} = values;
  switch (variant) {
    case 'outlined': {
      return (
        <StyledButton
          variant={'outlined'}
          sx={{
            backgroundColor: theme.palette.neutral?.secondary2,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.neutral?.secondary2}`,
            '&:hover': {
              border: `1px solid ${theme.palette.neutral?.secondary2}`,
              backgroundColor: theme.palette.neutral?.secondary2
            }
          }}
          onClick={onClick}
          id={`outlined-${id}`}
          {...rest}
        >
          {children}
        </StyledButton>
      );
    }
    case 'contained': {
      return (
        <StyledButton
          variant={'contained'}
          onClick={onClick}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
              backgroundColor: theme.palette.primary.main
            }
          }}
          id={`contained-${id}`}
          {...rest}
        >
          {children}
        </StyledButton>
      );
    }
    default:
      return (
        <StyledButton
          variant={'text'}
          sx={{
            color: theme.palette.primary.main
          }}
          onClick={onClick}
          id={`text-${id}`}
          {...rest}
        >
          {children}
        </StyledButton>
      );
  }
};

const CustomButton: React.FC<IAppButtonProps> = (props) => {
  const theme = useTheme();
  return <>{generateButton(props, theme)}</>;
};

export default CustomButton;
