import React, {Fragment} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import POAlert from 'components/widgets/poAlertTile';
import Icare from 'components/widgets/urgentIcareTile';
import SalesTile from 'components/widgets/salesTile';
import LaborTile from 'components/widgets/laborTile';
import ShrinkTile from 'components/widgets/shrinkTile';
import MarginTile from 'components/widgets/marginTile';
import OTBTile from 'components/widgets/otbTile';
import ItemAlertTile from 'components/widgets/itemAlertTile';
import InventoryTable from 'components/widgets/inventoryTable';
import TWSWidget from 'components/widgets/topWeeklySalesTile';
import TopVendorsWidget from 'components/widgets/topVendorsTile';
import TopTwentyVendors from 'components/widgets/topTwentyVendors';
import Typography from '@mui/material/Typography';
import {ReactComponent as Sync} from 'assets/images/icons/Icons & UI-Refresh & Sync-Sync_Active.svg';
import {ReactComponent as Gear} from 'assets/images/icons/Icons & UI-Settings-Settings_Blue.svg';
import {ReactComponent as Close} from 'assets/images/icons/Icons & UI-Close-Close Icon.svg';
import {ReactComponent as GlassIcon} from 'assets/images/icons/Icons & UI-Search-Search_Neutro_Inactive.svg';
import {ReactComponent as Refresh} from 'assets/images/icons/Icons & UI-Refresh & Sync-Today_Passive.svg';
import RemainderTile from 'components/widgets/remainderTile';
import {filterFieldTypes, IDropDownFieldTypes} from 'components/customTable/types';
import {Avatar, Divider, InputBase, Paper, Stack} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {IFilterValues} from 'components/customTable/gridFilter';
import {IDashboardTemplate, IWidgets} from './types';
import CustomDrawer from 'components/baseComponents/customDrawer';
import CustomButton from 'components/baseComponents/customButton';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import {generateLabelValue, reorder} from 'util/arrayOperations';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import ReceivingTile from 'components/widgets/receivingTile';
import CycleCountVariance from 'components/widgets/cycleCountVariance';
import ClassPerformance from 'components/widgets/classPerformance';
import StoreAlertStatus from 'components/widgets/storeAlertStatusTile';
import AdPromosTile from 'components/widgets/adPromos';
import NewPO from 'components/widgets/newPOTile';
//import handleRoleMapping, { handleAccessFilter } from 'util/helperRouterMap';
import AgedTile from 'components/widgets/agedTile';
import ExpiryTile from 'components/widgets/expiryTile';
import {CustomMasonryBlock} from 'components/masonry/customBlock';
import {CustomMasonry} from 'components/masonry/customMasonry';
import FilterMapper from 'components/customTable/gridFilterUtility/filterMapper';
import {ISelectOptions} from 'components/baseComponents/customSelect';

const HeaderDropDown = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  marginBottom: '32px'
});

const CustomWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  '& span': {
    display: 'flex'
  }
});

const SearchIconWrapper = styled('div')({
  width: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
});

//// Dashboard template creation with interface

const generateOptions = (a: any, b: any, c: any): any => {
  const dashboardFilterTemplate = [
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      placeholder: 'Departments',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      title: 'Department',
      defaultValue: 'All Departments',
      targetType: 'value',
      options: generateLabelValue(a, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      title: 'Class',
      defaultValue: 'All Classes',
      dependantFilterName: 'departmentName',
      targetType: 'value', //should match to dependent filter to include similar value referenced in this filter
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(b, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      title: 'Subclass',
      dependantFilterName: 'className',
      targetType: 'value',
      options: generateLabelValue(c, 'subClassName', 'subClassName', 'className')
    }
  ];
  return dashboardFilterTemplate;
};

// interface IRoleType {
//   name: string;
//   permission: Array<any>;
//   routePath: string;
// }

const Dashboard: React.FC = () => {
  const theme = useTheme();

  ////// To-do- Read the template for user from Backend and render this element
  const defaultDashboardTemplate: IDashboardTemplate = {
    widgets: [
      {
        id: 'classPerformance',
        name: 'classPerformance'
      },
      {
        id: 'remainder',
        name: 'RemainderTile'
      },
      {
        id: 'adPromos',
        name: 'Ad/Promos',
        type: 'compact'
      },
      {
        id: 'twsTile',
        name: 'TWSWidget'
      },
      {
        id: 'topVendors',
        name: 'TopVendorsWidget'
      }
    ],
    availableTiles: {
      sales: [
        {
          id: 'sales',
          name: 'Sales (Class)',
          imageUrl: 'ClassSales.svg'
        },
        {
          id: 'sales',
          name: 'Sales (Department)',
          imageUrl: 'DeptSales.svg',
          defaultValue: 'departmentSales'
        },
        {
          id: 'sales',
          name: 'Sales (Store)',
          imageUrl: 'StoreSales.svg',
          defaultValue: 'storeSales'
        }
      ],
      margin: [
        {
          id: 'margin',
          name: 'Margin (Class)',
          imageUrl: 'ClassMargin.svg',
          defaultValue: 'classMargin'
        },
        {
          id: 'margin',
          name: 'Margin (Department)',
          imageUrl: 'DeptMargin.svg',
          defaultValue: 'departmentMargin'
        },
        {
          id: 'margin',
          name: 'Margin (Regional)',
          imageUrl: 'RegMargin.svg',
          defaultValue: 'regionalMargin'
        }
      ],
      receiving: [
        {
          id: 'receivingTile',
          name: 'DepartmentReceivingTile',
          imageUrl: 'DeptReceiving.svg',
          defaultValue: 'Department'
        },
        {
          id: 'receivingTile',
          name: 'classReceivingTile',
          imageUrl: 'ClassReceiving.svg',
          defaultValue: 'class'
        }
      ],
      remainder: [
        {
          id: 'remainder',
          name: 'Remainder',
          imageUrl: 'Reminders.svg'
        }
      ],
      inventory: [
        {
          id: 'inventory',
          name: 'Inventory(Class)',
          imageUrl: 'Inventory.svg',
          defaultValue: 'Inventory'
        },
        {
          id: 'inventory',
          name: 'Inventory(Short Code)',
          imageUrl: 'Inventory.svg',
          defaultValue: 'Short Code'
        },
        {
          id: 'inventory',
          name: 'Inventory(Store)',
          imageUrl: 'Inventory.svg',
          defaultValue: 'Store'
        },
        {
          id: 'inventory',
          name: 'Inventory(Department)',
          imageUrl: 'Inventory.svg',
          defaultValue: 'Inventory'
        }
      ],
      itemAlerts: [
        {
          id: 'ItemAlertTile',
          name: 'ItemAlertTile',
          imageUrl: 'Item Alerts.png'
        }
      ],
      laborTile: [
        {
          id: 'labor',
          name: 'laborTile',
          imageUrl: 'laborTile.png'
        }
      ],
      shrinkTile: [
        {
          id: 'shrink',
          name: 'ShrinkTile',
          imageUrl: 'ShrinkTile.png'
        }
      ],
      topWeeklySales: [
        {
          id: 'twsTile',
          name: 'TWSWidget',
          imageUrl: 'TopSales.svg'
        }
      ],
      cycleCount: [
        {
          id: 'cycleCount',
          name: 'Item Count Variance',
          imageUrl: 'InventoryVariance.svg',
          defaultValue: 'Item'
        },
        {
          id: 'cycleCount',
          name: 'Store Count Variance',
          imageUrl: 'StoreInvVariance.svg',
          defaultValue: 'Store'
        }
      ],
      topVendors: [
        {
          id: 'topVendors',
          name: 'TopVendorsWidget',
          imageUrl: 'Inventory.svg',
          defaultValue: 'Top Vendor'
        },
        {
          id: 'topVendors',
          name: 'TopNewVendorsWidget',
          imageUrl: 'Inventory.svg',
          defaultValue: 'Top New Vendor'
        }
      ],
      topTwentyVendors: [
        {
          id: 'topTwentyVendors',
          name: 'TopTwentyVendorsWidget',
          defaultValue: 'Top20Vendors',
          imageUrl: 'TopSuppliers.svg'
        }
      ],
      classPerformance: [
        {
          id: 'classPerformance',
          name: 'class Performance',
          imageUrl: 'ClassPerformance.svg'
        }
      ],
      adPromos: [
        {
          id: 'adPromos',
          name: 'Ad/Promos',
          imageUrl: 'AD.svg',
          type: 'full'
        },
        {
          id: 'adPromos',
          name: 'Ad/Promos (Compact)',
          imageUrl: 'AD_Compact.svg',
          type: 'compact'
        }
      ],
      newPOs: [
        {
          id: 'newPOs',
          name: 'NewPO',
          imageUrl: 'ClassNewPO.svg'
        }
      ],
      agedTile: [
        {
          id: 'agedTile',
          name: 'agedTile',
          imageUrl: 'Aged.svg',
          defaultValue: '> 1 Month'
        }
      ],
      expiryTile: [
        {
          id: 'expiryTile',
          name: 'expiryTile',
          imageUrl: 'Expiry.svg',
          defaultValue: '> 1 Month'
        }
      ]
    }
  };
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [filterValues, setFilterValues] = React.useState<IFilterValues>({} as IFilterValues);
  const [dashboardTemplate, setDashboardTemplate] = React.useState<IDashboardTemplate>(
    defaultDashboardTemplate as IDashboardTemplate
  );
  const CenterBox = styled('div')({
    position: 'relative',
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'inherit'
    },
    alignItems: 'center',
    height: '36px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignSelf: 'center'
    }
  });

  const Search = styled('div')({
    border: `1px solid ${theme.palette.grayscale?.[300]}`,
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'inherit'
    },
    height: '36px',
    boxSizing: 'border-box',
    marginRight: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: '250px',
      display: 'flex',
      alignSelf: 'center'
    }
  });

  const StyledInputBase = styled(InputBase)({
    width: '200px',
    '& input::placeholder': {
      fontSize: '14px',
      letterSpacing: '.25px'
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: theme.spacing(2),
      transition: theme.transitions.create('width'),
      width: 'inherit'
    }
  });
  const [oriDashboardTemplate, setOriDashboardTemplate] = React.useState<IDashboardTemplate>(
    {} as IDashboardTemplate
  );
  const dispatch = useAppDispatch();
  //const location = useLocation();
  const {master} = useAppSelector((state: any) => state.masterSlice);
  const [dataOne, dataTwo, dataThree] = master;

  const result = generateOptions(dataOne?.data, dataTwo?.data, dataThree?.data);
  const [filterTemplate, setFilterTemplate] = React.useState<any[]>(result);
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
  }, []);
  React.useEffect(() => {
    setFilterTemplate(result);
  }, [JSON.stringify(master)]);

  /**
   * Event to remove the tile info
   * @param tileId Widget id to be removed
   */
  const onTileRemove = (tileId: any) => {
    const widetsDetails = dashboardTemplate.widgets.filter((elem) => elem.id !== tileId);
    setDashboardTemplate({
      ...dashboardTemplate,
      widgets: [...widetsDetails]
    });
  };

  /**
   * Event to add new widget to screen
   * @param element Widget element to add
   */
  const onTileAdd = (element: IWidgets) => {
    const newWidget: IWidgets = {
      ...element
    };
    setDashboardTemplate({
      ...dashboardTemplate,
      widgets: [...dashboardTemplate.widgets, newWidget]
    });
  };

  /**
   * Remove blank attributes from object
   * @param filter Filter Values
   */
  const removeEmptyProperty = (filter: IFilterValues) => {
    const newObj: any = {};
    Object.entries(filter).forEach(([k, v]) => {
      if (v != '' && v.toString().substring(0, 4).toLowerCase() != 'all ') {
        newObj[k] = filter[k];
      }
    });
    return newObj;
  };

  /**
   * When called finds and returns child with updated options
   * @param dependentName  Parent Target name
   * @param dependentFilterValue Parent target value
   * @param filterTemplate original copy of props.filterTemplate
   * @param parent Parent element in filterTemplate with updated options.
   * @returns new child or empty
   */
  function findDependantFilter(
    dependentName: string,
    dependentFilterValue: string,
    filterTemplate: any[],
    parent?: any
  ) {
    const updatedFilterTemplate: any[] = [...filterTemplate];
    const child: IDropDownFieldTypes = updatedFilterTemplate.find(
      // find child of dependent filter
      (e) => e.type === 'DROPDOWN' && e?.dependantFilterName === dependentName
    );

    ///if child exist then update child options
    if (child) {
      let updatedOptions = child?.options;

      let rootParent = parent;
      /// if parent not passed
      if (parent && parent.property) {
        rootParent = parent;
      } else {
        ////Find parent node from template
        const templateParent: IDropDownFieldTypes = updatedFilterTemplate.find(
          // find current element in filter template
          (e) => e.type === 'DROPDOWN' && e?.property === dependentName
        );
        rootParent = templateParent;
      }

      const defaultCheck = `All ${
        rootParent?.placeholder ? rootParent.placeholder : rootParent?.name
      }`;
      if (dependentFilterValue.trim().length > 0 && dependentFilterValue !== defaultCheck) {
        /* 
        If parent selected value exists (i.e. dependentFilterValue != null and not ALL options)
        then update child options and return only options with depends on == dependentFilterValue
         */
        updatedOptions = child?.options?.filter(
          (e: ISelectOptions) => e.dependsOn === dependentFilterValue
        );
      } else {
        /**
         * If parent selected value doesnot exists(i.e select All options of parent)
         * then update child options and return only options that matches depends on of child in All the options of parent.
         */
        updatedOptions = child?.options?.filter((e: ISelectOptions) => {
          return rootParent.options?.find((ele: ISelectOptions) => e.dependsOn === ele.value);
        });
      }

      const updatedChild = {
        ...child,
        options: updatedOptions
      };
      return updatedChild;
    } else {
      return {};
    }
  }

  /**
   * On change of any field update the filter State and call the props.onchange
   * @param event Field property
   */
  const onFieldValueChange = (event: any) => {
    let updatedState: any = {
      ...filterValues,
      [event?.target.name]: event?.target.value
    };
    const updatedFilterTemplate: any[] = [...result];
    let updatedChild: any = findDependantFilter(
      event?.target.name,
      event?.target.value,
      updatedFilterTemplate
    );
    // if updated child is defined then prepare the updated filterTemplate and update the template state
    if (Object.keys(updatedChild).length > 0) {
      const finalTemplate: any = [];
      while (updatedChild.property) {
        // find child filters if any using this loop and update its options
        finalTemplate.push(updatedChild);
        updatedState = {...updatedState, [updatedChild.property]: updatedChild.defaultValue};
        updatedChild = findDependantFilter(
          updatedChild.property,
          updatedChild.defaultValue,
          updatedFilterTemplate,
          updatedChild
        );
      }
      const newfinalTemplate = filterTemplate.map((ele) => {
        const finalChild = finalTemplate.find((e: any) => e.property === ele.property);
        // if filter objects exist with updated options then keep them in final template
        if (finalChild?.property && ele.property === finalChild.property) {
          return finalChild;
        } else {
          return ele;
        }
      });
      setFilterTemplate([...newfinalTemplate]);
    }

    setFilterValues(removeEmptyProperty(updatedState));
  };

  /**
   * Event to cancel customise widgets
   */
  const onCustomiseCancelClick = () => {
    setDashboardTemplate(oriDashboardTemplate);
    setDrawerOpen(false);
  };
  /**
   * Event to finish customise widgets
   */
  const onCustomiseFinishClick = () => {
    ///TO-DO Save the dashboardTemplate template into API
    setDrawerOpen(false);
  };

  /**
   * Event to customise widgets
   */
  const onCustomiseWidgetClick = () => {
    setOriDashboardTemplate(dashboardTemplate);
    setDrawerOpen(true);
  };

  /**
   * On Drag end event
   * @param object Contains dropResult
   * @returns void
   */
  const onDragEnd = ({destination, source}: DropResult) => {
    if (!destination) return;
    const newItems = reorder<IWidgets>(dashboardTemplate.widgets, source.index, destination.index);
    setDashboardTemplate({
      ...dashboardTemplate,
      widgets: [...newItems]
    });
  };

  /**
   * Available items images
   * @param props Component props
   * @returns FC with rendered tile images
   */
  //const temp = getRoleData && handleAccessFilter(getRoleData);
  const AvailableTileItem = (props: any) => {
    return (
      <>
        <Stack
          direction={'row'}
          key={`availableTileItem-${props.tileName}`}
          id={`availableTileItem-${props.tileName}`}
        >
          {props.tiles.map((elem: IWidgets, index: number) => {
            return (
              <>
                <Box
                  sx={{marginRight: 3}}
                  key={`availableTile-${elem.name}${index}`}
                  id={`availableTile-${elem.name}${index}`}
                >
                  <Box>
                    <Box>{elem.name}</Box>
                    <Box
                      sx={{
                        padding: 1,
                        backgroundColor: (theme) => theme.palette.grayscale?.[300],
                        borderWidth: '2px',
                        borderColor: 'primary.main',
                        borderRadius: '2px',
                        '&:hover': {
                          cursor: 'pointer',
                          bgcolor: 'unset'
                        }
                      }}
                      onClick={() => {
                        onTileAdd(elem);
                      }}
                    >
                      <img
                        src={require(`../../assets/images/tiles/${elem.imageUrl}`)}
                        alt={elem.name}
                        width='140'
                        height='140'
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            );
          })}
          <Divider
            orientation={'vertical'}
            sx={{
              mr: 3,
              border: '1px solid',
              borderColor: (theme) => theme.palette.bpGreyFont?.main
            }}
          />
        </Stack>
      </>
    );
  };

  /**
   * masonry Item
   * @param element Component to render
   * @param tileId Component Id
   * @param index index of component
   * @param width width of component
   * @returns FC with rendered tile
   */
  const masonryItem = (element: any, tileId: string, index: number, width?: number) => {
    return (
      <CustomMasonryBlock width={width ? width : 1} key={`${tileId}-${index}`}>
        {drawerOpen ? (
          <Droppable droppableId={tileId}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Draggable draggableId={tileId} index={index}>
                  {(provided: any) => (
                    <Box
                      sx={{position: 'relative', width: 'fixed'}}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Avatar
                        sx={{
                          position: 'absolute',
                          width: 32,
                          height: 32,
                          right: -10,
                          top: -15,
                          backgroundColor: (theme) => theme.palette.primary.main,
                          fill: (theme) => theme.palette.common.white
                        }}
                      >
                        <CustomButton
                          onClick={() => {
                            onTileRemove(tileId);
                          }}
                          id='dashboard-closeIcon'
                        >
                          <Close />
                        </CustomButton>
                      </Avatar>
                      {element}
                    </Box>
                  )}
                </Draggable>
              </div>
            )}
          </Droppable>
        ) : (
          <>{element}</>
        )}
      </CustomMasonryBlock>
    );
  };

  return (
    <Box sx={{mt: '50px'}}>
      <Fragment>
        <HeaderDropDown>
          <Stack direction='row' spacing={3}>
            {filterTemplate.map((filter: any, index: number) => (
              <FilterMapper
                filter={filter}
                id={'dashboardFilter'}
                key={`dashboardFilter-${filter.property}-${index}`}
                onFieldValueChange={onFieldValueChange}
                values={filterValues}
                index={index}
              />
            ))}
          </Stack>
          <CustomWrapper>
            <Box sx={{paddingRight: '10px'}}>
              <Typography variant='caption' textAlign='right'>
                Date as of 10:20 AM, 04/07/23
              </Typography>
            </Box>
            <Box>
              <CustomButton disabled={drawerOpen} id='dashboard-syncData'>
                <Sync />
                SYNC DATA
              </CustomButton>
            </Box>
            <Box sx={{padding: '0px 8px 0px 8px'}}>
              <CustomButton
                onClick={onCustomiseWidgetClick}
                disabled={drawerOpen}
                id='dashboard-cutomize'
              >
                <Gear />
                CUSTOMIZE
              </CustomButton>
            </Box>
          </CustomWrapper>
        </HeaderDropDown>
        <Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <Box sx={{flexGrow: 1}}>
              <CustomMasonry maxColumns={4}>
                {dashboardTemplate?.widgets?.map((widget: IWidgets, index: number) => {
                  //switch (temp?.includes(widget.id) ? widget.id : '') {
                  switch (widget.id) {
                    case 'sales': {
                      const renderWidget: any = (
                        <SalesTile
                          defaultValue={widget.defaultValue || 'department'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'labor': {
                      const renderWidget = (
                        <LaborTile
                          defaultValue={widget.defaultValue || 'department'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'shrink': {
                      const renderWidget = (
                        <ShrinkTile
                          defaultValue={widget.defaultValue || 'department'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'margin': {
                      const renderWidget = (
                        <MarginTile
                          defaultValue={widget.defaultValue || 'department'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }

                    case 'inventory': {
                      const renderWidget = (
                        <InventoryTable
                          defaultValue={widget.defaultValue || 'Inventory'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'remainder': {
                      const renderWidget = <RemainderTile filteredData={filterValues} />;
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'poAlert': {
                      const renderWidget = (
                        <POAlert
                          filteredData={filterValues}
                          defaultValue={widget.defaultValue || 'qc Fail'}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'ItemAlertTile': {
                      const renderWidget = <ItemAlertTile />;
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'iCare': {
                      const renderWidget = <Icare filteredData={filterValues} />;
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'otbTile': {
                      const renderWidget = (
                        <OTBTile
                          filteredData={filterValues}
                          defaultValue={widget.defaultValue || 'class'}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'receivingTile': {
                      const renderWidget = (
                        <ReceivingTile
                          filteredData={filterValues}
                          defaultValue={widget.defaultValue || 'Department'}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'storeAlert': {
                      const renderWidget = <StoreAlertStatus filteredData={filterValues} />;
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'twsTile': {
                      const renderWidget = <TWSWidget filteredData={filterValues} />;
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'classPerformance': {
                      const renderWidget = <ClassPerformance filteredData={filterValues} />;
                      return masonryItem(renderWidget, widget.id, index, 3);
                    }
                    case 'topVendors': {
                      const renderWidget = (
                        <TopVendorsWidget
                          defaultValue={widget.defaultValue || 'Top Vendor'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'newPOs': {
                      const renderWidget = <NewPO filteredData={filterValues} />;
                      return masonryItem(renderWidget, widget.id, index, 2);
                    }
                    case 'adPromos': {
                      const renderWidget = (
                        <AdPromosTile type={widget.type || 'full'} filteredData={filterValues} />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                    case 'topTwentyVendors': {
                      const renderWidget = (
                        <TopTwentyVendors
                          defaultValue={widget.defaultValue || 'Top 20 Vendors'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index, 2);
                    }
                    case 'agedTile': {
                      const renderWidget = (
                        <AgedTile
                          defaultValue={widget.defaultValue || '> 1 Month'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index, 2);
                    }
                    case 'expiryTile': {
                      const renderWidget = (
                        <ExpiryTile
                          defaultValue={widget.defaultValue || '> 1 Month'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index, 2);
                    }
                    case 'cycleCount': {
                      const renderWidget = (
                        <CycleCountVariance
                          defaultValue={widget.defaultValue || 'Store'}
                          filteredData={filterValues}
                        />
                      );
                      return masonryItem(renderWidget, widget.id, index);
                    }
                  }
                })}
              </CustomMasonry>
            </Box>
          </DragDropContext>
        </Box>
        <Box sx={{minHeight: '30px'}}> </Box>
        {drawerOpen ? <Box sx={{minHeight: '348px'}}> adding space </Box> : <></>}
        <CustomDrawer
          id='customDrawer-dashboard'
          open={drawerOpen}
          anchor='bottom'
          onClose={() => {
            setDrawerOpen(false);
          }}
          variant='persistent'
        >
          <>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Stack direction='row' sx={{marginLeft: 5, mt: 2, mb: 2}}>
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: (theme) => theme.palette.grayscale?.[600],
                    mt: '3px',
                    fontWeight: 'bold'
                  }}
                >
                  Click or drag to add tiles
                </Typography>
              </Stack>
              <Stack direction='row' sx={{pr: 2}}>
                <CenterBox sx={{fill: theme.palette.primary.main}}>
                  <Refresh />
                </CenterBox>
                <CenterBox>
                  <Typography
                    variant='button'
                    textAlign='right'
                    onClick={() => {
                      onCustomiseCancelClick();
                    }}
                    sx={{
                      mr: theme.spacing(5),
                      fontWeight: '500',
                      color: 'primary.main',
                      '&:hover': {
                        cursor: 'pointer',
                        bgcolor: 'unset'
                      }
                    }}
                  >
                    RESET TO DEFAULT
                  </Typography>
                </CenterBox>
                <Search>
                  <StyledInputBase
                    placeholder='Search the tile by name'
                    inputProps={{'aria-label': 'search'}}
                  />
                  <SearchIconWrapper sx={{marginRight: '3px'}}>
                    <GlassIcon fill={theme.palette.bpGreyFont?.main} />
                  </SearchIconWrapper>
                </Search>
              </Stack>
            </Paper>
            <Stack
              direction='row'
              sx={{
                margin: 3,
                paddingBottom: 2,
                width: 'auto',
                overflowX: 'scroll',
                overflowY: 'hidden'
              }}
            >
              {drawerOpen && dashboardTemplate && dashboardTemplate?.availableTiles
                ? Object.entries(dashboardTemplate?.availableTiles).map(
                    ([objKey, objValue], index) => {
                      return (
                        <AvailableTileItem
                          tileName={objKey}
                          tiles={objValue}
                          key={`AvailableTile${objKey}${index}`}
                        />
                      );
                    }
                  )
                : null}
            </Stack>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                border: '1px solid',
                borderColor: (theme) => theme.palette.grayscale?.[300],
                width: '100%'
              }}
            >
              <Stack direction='row' sx={{pl: 5, mt: 2, mb: 2}}>
                <CustomButton
                  variant={'outlined'}
                  onClick={() => {
                    onCustomiseCancelClick();
                  }}
                  id='dashboard-cancel'
                >
                  <Typography variant='caption' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                    Cancel
                  </Typography>
                </CustomButton>
              </Stack>
              <Stack direction='row' sx={{pr: 5, mt: 2, mb: 2}}>
                <Typography variant='caption'>
                  <CustomButton
                    variant={'contained'}
                    onClick={() => {
                      onCustomiseFinishClick();
                    }}
                    id='dashboard-finish'
                  >
                    Finish
                  </CustomButton>
                </Typography>
              </Stack>
            </Paper>
          </>
        </CustomDrawer>
      </Fragment>
    </Box>
  );
};

export default Dashboard;
