/* eslint-disable no-useless-catch */
import instance from 'config/instance';
import {ProductEndPoints} from 'config/endpoints';

export const ProductAPIS = {
  getProductListData: async (data: any) => {
    try {
      const response = await instance.post(`${ProductEndPoints.productList}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProductSearchData: async (data: any) => {
    try {
      let queryString = '';
      if (data.itemName) {
        queryString = data.itemName;
      }
      const response = await instance.post(
        `${ProductEndPoints.productSearch}?itemName=${queryString}`,
        data.filter || []
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProductStoreData: async (data: any) => {
    try {
      const response = await instance.post(`${ProductEndPoints.productStoreList}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getRetailZoneData: async (data: any) => {
    try {
      const response = await instance.get(`${ProductEndPoints.retailZone}`, {data});
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProductSalesData: async (data: any) => {
    try {
      const response = await instance.post(`${ProductEndPoints.productSalesList}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getRegionData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterRegion}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getBuyerData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterBuyer}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getDistrubutionCenterData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterDistrubutionCenter}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getVendorData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterVendor}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getTagData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterTag}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getDistrictData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterDistrict}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getChannelData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterChannel}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getStoreData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterStore}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getSizeData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterSize}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getPackData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterPack}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getAlertData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterAlert}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getWarehouseData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.masterWarehouse}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getMasterData: async () => {
    try {
      const response = await Promise.all([
        instance.get(`${ProductEndPoints.masterDepartment}`),
        instance.get(`${ProductEndPoints.masterClass}`),
        instance.get(`${ProductEndPoints.masterSubClass}`)
      ]);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProductDetailsData: async (id: any) => {
    try {
      const response = await instance.get(`${ProductEndPoints.productDetails}?itemId=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getWareHouseListData: async (data: any) => {
    try {
      const response = await instance.post(`${ProductEndPoints.warehouseList}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getStoreItemData: async (payload: any) => {
    try {
      const response = await instance.post(
        `${ProductEndPoints.storeItem}?productID=${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getSalesItemData: async (payload: any) => {
    const data = payload.data;
    try {
      const response = await instance.post(
        `${ProductEndPoints.salesItem}?itemId=${payload.id}`,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getOrderListData: async (payload: any) => {
    try {
      const response = await instance.post(
        `${ProductEndPoints.orderList}?itemId=${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getWareHouseItemData: async (id: any) => {
    try {
      const response = await instance.post(`${ProductEndPoints.warehouseItem}?productID=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getExpiryProductListData: async (data: any) => {
    try {
      const response = await instance.post(`${ProductEndPoints.expiryPoductList}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProductDemandForeCastItemData: async (payload: any) => {
    try {
      const response = await instance.post(
        `${ProductEndPoints.productDemandForeCastItem}?productID=${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProductDemandForeCastIngItemData: async (payload: any) => {
    const analyze = payload.data.filter((cur: any) => cur.key === 'analyzeBy')[0];
    try {
      const response =
        (await analyze.value.toLowerCase()) === 'sales'
          ? instance.get(ProductEndPoints.productDemandForeCastIngItemSales + `/${payload.id}`)
          : instance.post(
              ProductEndPoints.productDemandForeCastIngItem + `?productID=${payload.id}`,
              payload.data
            );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getPurchaseOrderTypeData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.purchaseOrderType}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getSupplierContactData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.supplierContact}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getFreightTermData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.freightTerm}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getLoadConditionData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.loadCondition}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getUnloadConditionData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.unloadCondition}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getDeliveryConditionData: async () => {
    try {
      const response = await instance.get(`${ProductEndPoints.deliveryCondition}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
};
