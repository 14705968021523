import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {salesLoading, salesFailure, salesSuccess} from 'redux/dashboard/sales/salesSlice';

export const sagaActions = {
  FETCH_DATA_SAGA: 'FETCH_DATA_SAGA'
};

export function* fetchSalesData(action: any): any {
  try {
    yield put(salesLoading());
    const {data} = yield call(DashboardServiceAPIS.getSalesData, action.payload);
    yield put(salesSuccess(data));
  } catch (error) {
    yield put(salesFailure());
  }
}

export default function* watchFetchSalesData() {
  yield takeEvery(sagaActions.FETCH_DATA_SAGA, fetchSalesData);
}
