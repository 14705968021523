import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {storeLoading, storeFailure, storeSuccess} from 'redux/dashboard/master/storeSlice';

export const storeSagaActions = {
  FETCH_STORE_DATA: 'FETCH_STORE_DATA'
};

export function* fetchStoreData(): any {
  try {
    yield put(storeLoading());
    const {data} = yield call(ProductAPIS.getStoreData);
    yield put(storeSuccess(data));
  } catch (error) {
    yield put(storeFailure());
  }
}

export default function* watchFetchStoreData() {
  yield takeEvery(storeSagaActions.FETCH_STORE_DATA, fetchStoreData);
}
