import {
  Box,
  Grid,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  styled,
  Paper,
  TableContainer
} from '@mui/material';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import moment from 'moment';
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {channelSagaActions} from 'redux/dashboard/master/channelSaga';
import {categorySupplierSagaActions} from 'redux/report/sales/categorySupplier/categorySupplierSaga';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {filterFieldTypes, IFilterTemplate} from 'components/customTable/types';
import {IAxis} from 'features/report/sales/sections/reportLineGraph';
import LineGraphComponent from 'components/lineGraph';
import {districtSagaActions} from 'redux/dashboard/master/districtSaga';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';
import {vendorSagaActions} from 'redux/dashboard/master/vendorSaga';
import {warehouseSagaActions} from 'redux/dashboard/master/warehouseSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {generateLabelValue, generatePayload} from 'util/arrayOperations';

const viewByOptions = [
  {
    label: 'Week',
    value: 'week'
  },
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Year',
    value: 'year'
  }
];

const compMarginOptions = [
  {
    label: 'Yes',
    value: 'Yes'
  },
  {
    label: 'No',
    value: 'No'
  }
];

const graphOptions = [
  {
    label: 'Margin Dollars',
    value: 'Margin Dollars'
  },
  {
    label: 'Margin %',
    value: 'Margin %'
  }
];

interface ICategorySupplierMargin {
  comp: string;
  compPlan: string;
  duration: string;
  lastYearMargin: string;
  margin: string;
  marginPlan: string;
  yoy: string;
}

const LabelTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-right-style: solid;
    width: 20px;
    font-weight: bold;
    flex-grow: 1;
    border-right-color: ${(props) => props.theme.palette.grayscale?.[300]};
    border-right-width: 2px;
    padding-right: ${(props) => props.theme.spacing(2)};
    background-color: ${(props) => props.theme.palette.grayscale?.[100]};
  }
`;
const ValueTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-right-style: solid;
    border-right-color: ${(props) => props.theme.palette.grayscale?.[300]};
    border-right-width: 2px;
    flex-grow: 1;
    display: flex;
    width: 1022px;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
`;

const TotalTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-weight: bold;
    flex-grow: 1;
    width: 50px;
    background-color: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

const CategorySupplierMargin: React.FC = () => {
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const xAxis = {
    name: 'duration',
    displayTitle: 'duration',
    format: 'MMM YYYY'
  };

  const {master} = useAppSelector((state) => state.masterSlice);
  const {vendor} = useAppSelector((state) => state.vendorSlice);
  const {channel} = useAppSelector((state) => state.channelSlice);
  const {district} = useAppSelector((state) => state.districtSlice);
  const {store} = useAppSelector((state) => state.storeSlice);
  const {region} = useAppSelector((state) => state.regionSlice);
  const {warehouse} = useAppSelector((state) => state.warehouseSlice);

  const [dataOne, dataTwo, dataThree] = master;

  const {categorySupplier, loading, error} = useAppSelector((state) => state.categorySupplierSlice);
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: vendorSagaActions.FETCH_VENDOR_DATA
    });
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
    dispatch({
      type: channelSagaActions.FETCH_CHANNEL_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: warehouseSagaActions.FETCH_WAREHOUSE_DATA
    });
  }, []);

  const defaultDateFormat = 'MM/DD/YYYY';
  const dataLength = 13;
  const dbData: ICategorySupplierMargin[] = categorySupplier['margin'] as ICategorySupplierMargin[];

  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewBy',
      title: 'View By',
      defaultValue: 'month',
      sx: {
        width: '150px'
      },
      options: viewByOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'compMargin',
      title: 'Comp(Margin)',
      defaultValue: 'Yes',
      sx: {
        width: '150px'
      },
      options: compMarginOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'graph',
      title: 'Graph',
      sx: {
        width: '150px'
      },
      options: graphOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      sx: {
        width: '150px'
      },
      placeholder: 'Departments',
      defaultValue: 'All Departments',
      allowAllOptions: true,
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      sx: {
        width: '150px'
      },
      allowAllOptions: true,
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'vendor',
      title: 'Vendor',
      placeholder: 'Vendor',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(vendor, 'vendorName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'channel',
      title: 'Channel',
      defaultValue: 'All Channel',
      placeholder: 'Channel',
      mode: 'Secondary',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(channel, 'channel')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeRegion',
      title: 'Store Region',
      defaultValue: 'All Regions',
      placeholder: 'Regions',
      mode: 'Secondary',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(region, 'storeRegion')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeDistrict',
      title: 'Store District',
      defaultValue: 'All Districts',
      placeholder: 'Districts',
      mode: 'Secondary',
      sx: {
        width: '170'
      },
      options: generateLabelValue(district, 'storeDistrict')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'store',
      title: 'Store',
      defaultValue: 'All Stores',
      placeholder: 'Stores',
      mode: 'Secondary',
      sx: {
        width: '170'
      },
      options: generateLabelValue(store, 'storeName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'warehouse',
      title: 'Warehouse',
      mode: 'Secondary',
      placeholder: 'Warehouse',
      defaultValue: 'All Warehouse',
      sx: {
        width: '170'
      },
      options: generateLabelValue(warehouse, 'warehouseID')
    }
  ];
  const location: any = useLocation();

  const defaultData = filterTemplate.reduce((acc: any, obj) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);
  const [page, setPage] = React.useState<number>(0);

  /**
   * Db method call when page no change
   * @param pageNo Page No
   */
  const marginDBApiCall = (pageNo: number) => {
    const fromDate =
      pageNo === 0
        ? moment().format(defaultDateFormat)
        : moment()
            .subtract(pageNo * dataLength, filtervalues.viewBy || 'months')
            .format(defaultDateFormat);
    const toDate =
      pageNo === 0
        ? moment(fromDate, defaultDateFormat, true)
            .subtract(dataLength, filtervalues.viewBy || 'months')
            .format(defaultDateFormat)
        : moment(fromDate, defaultDateFormat, true)
            .subtract(dataLength + 1, filtervalues.viewBy || 'months')
            .format(defaultDateFormat);
    dispatch({
      type: categorySupplierSagaActions.FETCH_CATEGORYSUPPLIER_SAGA,
      payload: {
        type: 'margin',
        fromDate: fromDate,
        toDate: toDate,
        data: generatePayload(filtervalues)
      }
    });
  };
  /**
   * When there is filter change set the page no to 0
   */
  React.useEffect(() => {
    if (page != 0) {
      setPage(0);
    }
    marginDBApiCall(0);
  }, [filtervalues]);
  /**
   * Fetch new previous set of data
   */
  const prevClick = () => {
    let pageNo = page;
    if (page === 1) {
      setPage(0);
      pageNo = 0;
    } else {
      pageNo = page - 1;
      setPage(page - 1);
    }
    marginDBApiCall(pageNo);
  };
  /**
   * Fetch new previous set of data
   */
  const nextClick = () => {
    setPage(page + 1);
    marginDBApiCall(page + 1);
  };

  ////// Create YAxis dynamically based on API object
  const yAxis: Array<IAxis> = [
    {
      name: 'margin',
      displayTitle: 'Margin'
    },
    {
      name: 'lastYearMargin',
      displayTitle: 'LY Margin'
    },
    {
      name: 'marginPlan',
      displayTitle: 'Margin Plan'
    }
  ];

  /**
   * OnChange of filter
   * @param values Filter values
   */
  const onFilterApply = (values: IFilterValues) => {
    setFilterValues(values);
  };

  const layout = {
    width: 1170,
    height: 326,
    autosize: false,
    showlegend: false,
    paper_bgcolor: 'rgba(0,0,0,0)', // transparent
    plot_bgcolor: 'rgba(0,0,0,0)', // transparent
    font: {
      size: 12
    },
    xaxis: {
      showgrid: false,
      showline: false,
      automargin: false, ///used to display x axis label
      range: [0.5, dataLength + 0.5] /// In Margin, we can only view dataLength=13 records only.
    } as unknown as Plotly.LayoutAxis,
    yaxis: {
      showline: false,
      automargin: true
    },
    margin: {
      l: 1,
      r: 2,
      b: 1,
      t: 0,
      pad: 1
    }
  };

  const RenderRow = (props: any) => {
    const {data, property} = props;
    const pageData = data.slice(0, dataLength + 1);
    return (
      <>
        {pageData &&
          pageData.slice(1, pageData.length)?.map((ele: ICategorySupplierMargin) => {
            const value = ele[property as keyof ICategorySupplierMargin] as string;
            return (
              <Box
                key={`categoryMargin-${property}-${ele.duration}`}
                sx={{
                  flexGrow: 1,
                  textAlign: 'center'
                }}
              >
                {value}
              </Box>
            );
          })}
      </>
    );
  };

  /////// Calculate the sum to display Total column
  const aggrPageData = dbData.slice(0, dataLength + 1);

  const consolidatedData = aggrPageData.reduce(
    (accumulator: any, c: any) => {
      accumulator.margin = accumulator.margin + parseFloat(c['margin']);
      accumulator.marginPlan = accumulator.marginPlan + parseFloat(c['marginPlan']);
      accumulator.marginPercentage =
        accumulator.marginPercentage + parseFloat(c['marginPercentage']);
      accumulator.marginPercentagePlan =
        accumulator.marginPercentagePlan + parseFloat(c['marginPercentagePlan']);
      return accumulator;
    },
    {
      margin: 0,
      marginPlan: 0,
      marginPercentage: 0,
      marginPercentagePlan: 0
    }
  );

  if (dbData && dbData.length > 0 && !error) {
    return (
      <>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <GridFilter
              values={filtervalues}
              filterTemplate={filterTemplate}
              onFilterChange={onFilterApply}
              id='report-category-supplier-margin'
              onReset={() => {
                setFilterValues(defaultData);
              }}
            />
          </Grid>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <CustomLoader id='product-margin-load' />
            </Box>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={(theme) => ({
                lineHeight: theme.spacing(7),
                backgroundColor: theme.palette?.primary?.contrastText
              })}
            >
              <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'}>
                <Box sx={{textAlign: 'center'}}>ON HAND: 9,900 Cases $623,000 37.5% Margin</Box>
                <Box sx={{textAlign: 'center'}}>ON ORDER: 10,500 Cases $509,000 47.4% Margin</Box>
                <Box sx={{textAlign: 'center'}}>ON HAND + ON ORDER: 20,400 Cases $1,132,000</Box>
                <Box sx={{textAlign: 'center'}}>IN QC: 2,000 Cases $97,000</Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <LineGraphComponent //change this to ReportLineGraphComponent to enablem popup functionality
                data={dbData.slice(0, page === 0 ? dataLength + 1 : dataLength + 2)}
                xAxis={xAxis}
                layout={layout}
                yAxis={[...yAxis]}
                id='categorySupplierMargin'
                prevClick={prevClick}
                pageNo={page}
                nextClick={nextClick}
                size={'large'}
              />
            </Paper>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              overflow: 'hidden'
            }}
            xs={12}
            md={12}
            lg={12}
          >
            <TableContainer component={Paper} sx={{width: '99.9%'}}>
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LabelTableCell>Margin</LabelTableCell>
                    <ValueTableCell>
                      <RenderRow
                        data={dbData}
                        property={'margin'}
                        id='totalCategorySuppliersMargin'
                      />
                    </ValueTableCell>
                    <TotalTableCell>${consolidatedData.margin}</TotalTableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LabelTableCell>Margin plan</LabelTableCell>
                    <ValueTableCell>
                      <RenderRow
                        data={dbData}
                        property={'marginPlan'}
                        id='totalCategorySuppliersMarginPlan'
                      />
                    </ValueTableCell>
                    <TotalTableCell>${consolidatedData.marginPlan}</TotalTableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LabelTableCell>Margin %</LabelTableCell>
                    <ValueTableCell>
                      <RenderRow
                        data={dbData}
                        property={'marginPercentage'}
                        id='totalCategorySuppliersMarginPer'
                      />
                    </ValueTableCell>
                    <TotalTableCell>
                      {(consolidatedData.marginPercentage / aggrPageData.length).toFixed(2)}
                    </TotalTableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LabelTableCell>Margin % Plan</LabelTableCell>
                    <ValueTableCell>
                      <RenderRow
                        data={dbData}
                        property={'marginPercentagePlan'}
                        id='totalCategorySuppliersMarginPlanPer'
                      />
                    </ValueTableCell>
                    <TotalTableCell>
                      {(consolidatedData.marginPercentagePlan / aggrPageData.length).toFixed(2)}
                    </TotalTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <GridFilter
            values={filtervalues}
            filterTemplate={filterTemplate}
            onFilterChange={onFilterApply}
            id='report-category-supplier-margin'
            onReset={() => {
              setFilterValues(defaultData);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              maxHeight: '844px'
            }}
          >
            {' '}
            {loading ? (
              <CustomLoader id='report-category-supplier-load' />
            ) : (
              <CustomError
                id='report-category-supplier-load'
                onReload={() => {
                  marginDBApiCall(0);
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
};

export default CategorySupplierMargin;
