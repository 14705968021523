import {createSlice} from '@reduxjs/toolkit';
export interface cycleCountState {
  loading: {[key: string]: boolean};
  cycleCount: {[key: string]: Array<any>};
  error: string;
}

const initialState: cycleCountState = {
  loading: {},
  cycleCount: {},
  error: ''
};

const cycleCountSlice = createSlice({
  name: 'cycleCount',
  initialState,
  reducers: {
    cycleCountLoading: (state, action) => {
      state.loading[action.payload.type] = true;
    },
    cycleCountSuccess: (state, action) => {
      state.cycleCount[action.payload.type] = action.payload.result;
      state.loading[action.payload.type] = false;
      state.error = '';
    },
    cycleCountFailure: (state, action) => {
      state.loading[action.payload.type] = false;
      state.cycleCount[action.payload.type] = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default cycleCountSlice.reducer;
export const {
  cycleCountLoading: cycleCountLoading,
  cycleCountSuccess: cycleCountSuccess,
  cycleCountFailure
} = cycleCountSlice.actions;
