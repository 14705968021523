import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';

import {
  freightTermLoading,
  freightTermFailure,
  freightTermSuccess
} from 'redux/dashboard/master/freightTermSlice';

export const freightTermSagaActions = {
  FETCH_FREIGHT_TERM_DATA: 'FETCH_FREIGHT_TERM_DATA'
};

export function* fetchFreightTermData(): any {
  try {
    yield put(freightTermLoading());
    const {data} = yield call(ProductAPIS.getFreightTermData);
    yield put(freightTermSuccess(data));
  } catch (error) {
    yield put(freightTermFailure());
  }
}

export default function* watchFetchFreightTermData() {
  yield takeEvery(freightTermSagaActions.FETCH_FREIGHT_TERM_DATA, fetchFreightTermData);
}
