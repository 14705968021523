import {takeEvery, put, call} from 'redux-saga/effects';
import {ReportAPIS} from 'redux/report/report_service';
import {
  warehouseLiabilityReportLoading,
  warehouseLiabilityReportSuccess,
  warehouseLiabilityReportFailure
} from 'redux/report/inventory/warehouseLiabilityReport/warehouseLiabilityReportSlice';

export const warehouseLiabilityReportSagaActions = {
  FETCH_WAREHOUSELIABILITYREPORT_SAGA: 'FETCH_WAREHOUSELIABILITYREPORT_SAGA'
};

export function* fetchWarehouseLiabilityReportData(action: any) {
  try {
    yield put(warehouseLiabilityReportLoading());
    const {data} = yield call(ReportAPIS.getWarehouseLiabilityReportData, action.payload);
    yield put(warehouseLiabilityReportSuccess(data));
  } catch (error) {
    yield put(warehouseLiabilityReportFailure());
  }
}

export default function* watchFetchWarehouseLiabilityReportData() {
  yield takeEvery(
    warehouseLiabilityReportSagaActions.FETCH_WAREHOUSELIABILITYREPORT_SAGA,
    fetchWarehouseLiabilityReportData
  );
}
