import jwt_decode from 'jwt-decode';

import sign from 'jwt-encode';

export function jwtToken(userData: any): any {
  const secret = process.env.REACT_APP_DASHBOARD_URL || '';
  const jwt = sign(userData, secret);
  return jwt;
}

export function jwtTokenDecode(token: any): any {
  return jwt_decode(token);
}

export function getLocalStorage(itemName: string) {
  return localStorage.getItem(itemName);
}

export function setLocalStorage(itemName: string, data: any) {
  localStorage.setItem(itemName, data);
}

export function deleteLocalStorage(itemName: string) {
  localStorage.removeItem(itemName);
}

export function clearLocalStorage() {
  localStorage.clear();
}

// Longer duration refresh token (30-60 min)
export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

export function setRefreshToken(token: any) {
  localStorage.setItem('refreshToken', token);
}
