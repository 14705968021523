/* eslint-disable react/prop-types */
import {
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
  Box,
  Tooltip,
  useTheme,
  styled
} from '@mui/material';
import {visuallyHidden} from '@mui/utils';
import Typography from '@mui/material/Typography';
import {
  BorderedGroupTableHeadCell,
  BorderedGroupTableSubHeadCell,
  StickyHeaderCell,
  TableHeadCell,
  TableHeaderHOC
} from 'components/customTable/tableCell';
import {IColumnCell, IOrder} from 'components/customTable/types';
import {ReactComponent as Arrow} from 'assets/images/icons/Icons & UI-Dropdown-Dropdown-CaretUp_Dark.svg';
import {bgcolor} from '@mui/system';

interface IEnhancedTableHeadProps<T> {
  columnCell: IColumnCell<T>[];
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: IOrder;
  orderBy: string;
  rowCount: number;
  selectable?: boolean;
  denseHeader?: boolean;
  borderless?: boolean;
  allowRowGrouping?: boolean;
  id: string;
}

interface IRenderNormalCell<T> {
  headCell: IColumnCell<T>;
  columnSort: boolean;
  sx?: any;
}

export function EnhancedTableHead<T>(props: IEnhancedTableHeadProps<T>) {
  const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, id} = props;
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const theme: any = useTheme();

  const CustomTooltip = styled(Tooltip)({
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  });

  const stickyColumnsCell = props?.columnCell.filter((ele) => (ele.sticky ? true : false));
  const nonStickyColumnsCell = props?.columnCell.filter((ele) => (ele.sticky ? false : true));
  const RenderNormalCell = ({headCell, columnSort, sx}: IRenderNormalCell<T>) => {
    const params: any = {
      align: headCell?.headerTextAlign || 'left'
    };
    const externalStyle = headCell.sx ?? {}; //to append any style added in column declaration in normal cell
    if (sx) {
      params.sx = {...sx};
    }
    if (externalStyle) {
      params.sx = {...params.sx, ...externalStyle};
    }
    return (
      <>
        <TableHeaderHOC
          borderless={props.borderless ? props.borderless : false}
          id={`${id}-column-${String(headCell.id)}`}
          key={`RenderCell${String(headCell.id)}` as string}
          {...params}
          padding={headCell.disablePadding ? 'none' : 'normal'}
          sortDirection={orderBy === headCell.id ? order : false}
          isGroupBorderRequire={headCell.isGroupBorderRequire}
        >
          {columnSort ? (
            <TableSortLabel
              sx={{
                whiteSpace: 'nowrap',
                '& .MuiTypography-root': {
                  pr: '5px'
                },
                '& .MuiTableSortLabel-icon': {
                  mr: 0,
                  opacity: 0.2,
                  '&::selection': {
                    opacity: 1
                  }
                }
              }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={Arrow}
            >
              <Tooltip
                title={`${headCell?.name}`}
                placement='bottom-end'
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      color: `${theme.palette.common.black}`,
                      bgcolor: `${theme.palette.common.white}`
                    }
                  }
                }}
              >
                <Typography
                  variant='caption'
                  sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    fontFamily: 'Roboto Bold'
                  }}
                  textAlign='left'
                  id={`header-${id + String(headCell.id)}`}
                >
                  {headCell?.displayName || headCell.name}
                </Typography>
              </Tooltip>
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            <Typography
              variant='caption'
              sx={{
                fontSize: '12px',
                fontWeight: 600,
                textTransform: 'uppercase',
                fontFamily: 'Roboto Bold'
              }}
              textAlign='left'
              id={`header-2-${id + String(headCell.id)}`}
            >
              {headCell?.displayName || headCell.name}
            </Typography>
          )}
        </TableHeaderHOC>
      </>
    );
  };

  const RenderBorderGroupCell = ({headCell, columnSort}: IRenderNormalCell<T>) => {
    const externalStyle = headCell.sx ?? {}; //to append any style added in column declaration in borderGroupCell
    return (
      <>
        <BorderedGroupTableHeadCell
          id={`${id}-column-${String(headCell.id)}`}
          align='center'
          colSpan={headCell?.groupedKeys?.length}
          key={`RenderBorderGroupCell${String(headCell.id)}`}
        >
          {columnSort ? (
            <TableSortLabel
              id={id}
              active={orderBy === headCell.groupSortKey}
              direction={orderBy === headCell.groupSortKey ? order : 'asc'}
              onClick={createSortHandler(headCell.groupSortKey || headCell.id)}
              IconComponent={Arrow}
              sx={{
                whiteSpace: 'nowrap',
                '& .MuiTypography-root': {
                  pr: '5px'
                },
                '& .MuiTableSortLabel-icon': {
                  mr: 0,
                  opacity: 0.2,
                  '&::selection': {
                    opacity: 1
                  }
                },
                ...externalStyle
              }}
            >
              <Typography
                variant='caption'
                sx={{
                  fontSize: '12px',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  fontFamily: 'Roboto Bold'
                }}
                textAlign='left'
                id={`header-3-${id + String(headCell.id)}`}
              >
                {headCell?.displayName || headCell.name}
              </Typography>
              {orderBy === headCell.groupSortKey ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            <Typography
              variant='caption'
              sx={{
                fontSize: '12px',
                fontWeight: 600,
                textTransform: 'uppercase',
                fontFamily: 'Roboto Bold'
              }}
              id={`header-4-${id + String(headCell.id)}`}
              textAlign='left'
            >
              {headCell?.displayName || headCell.name}
            </Typography>
          )}
          {headCell?.groupedKeys?.map((elem: any, index: number) => {
            const groupExternalStyle = headCell?.groupHeaderDisplaySx && {
              ...headCell?.groupHeaderDisplaySx[index]
            };
            return (
              <BorderedGroupTableSubHeadCell
                id={`${id}-column-${String(headCell.id)}`}
                sx={groupExternalStyle}
                key={`BorderedGroupTableSubHeadCell-${elem}`}
              >
                <Typography
                  variant='caption'
                  sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    fontFamily: 'Roboto Bold',
                    textAlign: 'left',
                    ...(headCell?.groupHeaderDisplaySx && {
                      ...headCell?.groupHeaderDisplaySx[index]
                    })
                  }}
                >
                  {headCell?.groupDisplayKeys && headCell?.groupDisplayKeys[index]
                    ? headCell?.groupDisplayKeys[index]
                    : elem}
                </Typography>
              </BorderedGroupTableSubHeadCell>
            );
          })}
        </BorderedGroupTableHeadCell>
      </>
    );
  };

  const renderCheckbox = (
    <Checkbox
      color='primary'
      indeterminate={numSelected > 0 && numSelected < rowCount}
      checked={rowCount > 0 && numSelected === rowCount}
      onChange={onSelectAllClick}
      inputProps={{
        'aria-label': 'select all desserts'
      }}
    />
  );
  return (
    <TableHead>
      <TableRow sx={{height: props.denseHeader ? '32px' : '64px'}}>
        {/* Checkbox without sticky column  */}
        {stickyColumnsCell.length === 0 && props.selectable ? (
          <>
            <TableHeadCell padding='checkbox'>{renderCheckbox}</TableHeadCell>
          </>
        ) : null}

        {/* Row grouping icon enable based on props */}
        {props.allowRowGrouping === true ? (
          <TableHeaderHOC padding='none'>
            <Typography
              variant='caption'
              sx={{
                fontSize: '12px',
                fontWeight: 600,
                textTransform: 'uppercase',
                fontFamily: 'Roboto Bold'
              }}
              textAlign='left'
            ></Typography>
            {props?.allowRowGrouping}
          </TableHeaderHOC>
        ) : null}

        {/* Checkbox with sticky column  */}
        {stickyColumnsCell.length > 0 ? (
          <StickyHeaderCell>
            {props.selectable ? (
              <TableHeadCell padding='checkbox' sx={{borderBottom: 0}}>
                {renderCheckbox}
              </TableHeadCell>
            ) : null}

            {stickyColumnsCell.map((headCell) => {
              /////Default column sort is true
              const columnSort = headCell?.isColumnSortable !== false ? true : false;
              const sx = {borderBottom: 0};

              ///Dont place border since stikcy header cell has it
              headCell.isGroupBorderRequire = false;
              return <>{RenderNormalCell({headCell, columnSort, sx})}</>;
            })}
          </StickyHeaderCell>
        ) : null}

        {/* Other than sticky  column  */}
        {nonStickyColumnsCell.map((headCell) => {
          /////Default column sort is true
          const columnSort = headCell?.isColumnSortable !== false ? true : false;
          return (
            <>
              {headCell?.isGroupedCell ? (
                <>{RenderBorderGroupCell({headCell, columnSort})}</>
              ) : (
                <>{RenderNormalCell({headCell, columnSort})}</>
              )}
            </>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
