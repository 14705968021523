import {Box, Tab, Tabs, Button, useTheme} from '@mui/material';
import React, {Fragment} from 'react';
import ProductList from 'features/product/list/productList';
import WarehouseList from 'features/product/list/warehouseList';
import SalesList from 'features/product/list/productSalesList';
import StoreList from 'features/product/list/productStoreList';
import ExpiryList from 'features/product/list/expiryList';
import CustomTabPanel from 'components/baseComponents/customTabPanel';
import Typography from '@mui/material/Typography';
import PopUp from 'features/product/details/popup';
import handleRoleMapping, {handleAccessFilter} from 'util/helperRouterMap';
import {useLocation} from 'react-router-dom';

interface IRoleType {
  name: string;
  permission: Array<any>;
  routePath: string;
}

const Products: React.FC = () => {
  const location: any = useLocation();
  const theme: any = useTheme();
  const tabno: number =
    location?.state?.filter && location?.state?.filter['selectTabNo']
      ? location?.state?.filter['selectTabNo']
      : 0;

  const [value, setValue] = React.useState(tabno);
  const [actionOpen, setActionOpen] = React.useState<boolean>(false);
  const [enableATP, setEnableATP] = React.useState<boolean>(true);
  const [getRoleData, setRoleData] = React.useState<IRoleType>();
  const [checkAccess, setAccess] = React.useState<Array<string>>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setEnableATP(true);
  };

  const getATPStatus = (val: boolean) => {
    setEnableATP(val);
  };

  const tabContent = [
    {
      component: <ProductList atpEnabler={getATPStatus} />,
      id: 'product-productList',
      name: 'overview'
    },
    {
      component: <WarehouseList atpEnabler={getATPStatus} />,
      id: 'product-warehouseList',
      name: 'warehouse'
    },
    {
      component: <StoreList atpEnabler={getATPStatus} />,
      id: 'product-storeList',
      name: 'store'
    },
    {
      component: <SalesList atpEnabler={getATPStatus} />,
      id: 'product-salesList',
      name: 'sales'
    },
    {
      component: <ExpiryList atpEnabler={getATPStatus} />,
      id: 'product-expiryList',
      name: 'expiry'
    }
  ];

  const tabNames = [
    {
      id: 'product-overview',
      label: 'OVERVIEW',
      order: 0
    },
    {
      id: 'product-warehouse',
      label: 'WAREHOUSE',
      order: 1
    },
    {
      id: 'product-store',
      label: 'STORE',
      order: 2
    },
    {
      id: 'product-sales',
      label: 'SALES',
      order: 3
    },
    {
      id: 'product-expiry',
      label: 'EXPIRY',
      order: 4
    }
  ];

  const handleAction = () => {
    setActionOpen(!actionOpen);
  };

  const generateTabPanels = () => {
    return tabContent.map((tab, index) => {
      if (checkAccess?.includes(tab.name)) {
        return (
          <CustomTabPanel key={`product-${index}`} value={value} id={tab.id} index={index}>
            {tab.component}
          </CustomTabPanel>
        );
      } else {
        return <></>;
      }
    });
  };

  const generateTabNames = () => {
    return tabNames.map((tab) => {
      if (checkAccess?.includes(tab.label.toLowerCase())) {
        return (
          <Tab
            key={`product-${tab.order}`}
            id={tab.id}
            value={tab.order}
            label={<Typography variant='button'>{tab.label}</Typography>}
          />
        );
      } else {
        return <></>;
      }
    });
  };

  React.useEffect(() => {
    let role: IRoleType = {
      name: '',
      permission: [],
      routePath: ''
    };
    if (location?.pathname) {
      role = handleRoleMapping(location?.pathname);
      role?.permission?.length > 0 && setRoleData(role);
    }
  }, []);

  React.useEffect(() => {
    const temp: any = getRoleData && handleAccessFilter(getRoleData);
    if (temp && temp.length > 0) {
      setAccess(temp);
    }
  }, [getRoleData]);

  const shouldRender = checkAccess && checkAccess.every((e) => e === undefined);
  return (
    <Fragment>
      <Box sx={{ml: 5, mr: 5, mt: 8, pb: 2}}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end'
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              '& .MuiTab-root': {
                color: theme.palette?.grayscale?.[400],
                p: '12px 0',
                mr: 3,
                minWidth: 'fit-content'
              }
            }}
          >
            {checkAccess && shouldRender ? <></> : generateTabNames()}
          </Tabs>
          {checkAccess && shouldRender ? (
            <></>
          ) : (
            <Button
              id='product-button-addPO'
              disableElevation
              onClick={handleAction}
              sx={{
                bgcolor: theme.palette?.primary.main,
                color: 'common.white',
                '&.Mui-disabled': {
                  bgcolor: theme.palette?.grayscale?.[200]
                }
              }}
              variant={'contained'}
              disabled={enableATP}
            >
              <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                ADD TO PO
              </Typography>
            </Button>
          )}
        </Box>
        {actionOpen && <PopUp open={actionOpen} handleAction={handleAction} title='Add To PO' />}
        {checkAccess && shouldRender ? <> </> : generateTabPanels()}
      </Box>
    </Fragment>
  );
};

export default Products;
