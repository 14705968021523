import {takeEvery, put, call} from 'redux-saga/effects';
import {PurchaseOrderAPIS} from 'redux/purchaseOrder/purchaseOrder_service';
import {
  createPOFailure,
  createPOSuccess,
  createPOLoading,
  clearPOLoading,
  clearPoFailure
} from 'redux/purchaseOrder/poList/createPOSlice';

export const createPOSagaActions = {
  FETCH_CREATEPO_DATA: 'FETCH_CREATEPO_DATA',
  FETCH_CLEARPO_DATA: 'FETCH_CLEARPO_DATA'
};

export function* fetchCreatePOData(action: any): any {
  try {
    yield put(createPOLoading());

    const {data} = yield call(PurchaseOrderAPIS.getCreatePOData, action.payload);

    yield put(createPOSuccess(data));
  } catch (error) {
    const errorMessage = error?.response?.data?.errorMessage || error.message;
    yield put(createPOFailure(errorMessage));
  }
}
export default function* watchFetchCreatePOData() {
  yield takeEvery(createPOSagaActions.FETCH_CREATEPO_DATA, fetchCreatePOData);
}
//for clear Po
export function* fetchClearPOData(): any {
  yield put(clearPOLoading());
  yield put(clearPoFailure());
}

export function* watchFetchClearPOData() {
  yield takeEvery(createPOSagaActions.FETCH_CLEARPO_DATA, fetchClearPOData);
}
