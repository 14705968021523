/* eslint-disable no-useless-catch */
import instance from 'config/instance';
import {PurchaseOrderEndPoints} from 'config/endpoints';

export const PurchaseOrderAPIS = {
  getPoItemsData: async (id: any) => {
    try {
      const response = await instance.get(`${PurchaseOrderEndPoints.poItems}?poId=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  getGeneralInfoData: async (id: any) => {
    try {
      const response = await instance.get(`${PurchaseOrderEndPoints.generalInfo}?poId=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getPoListData: async (data: any) => {
    try {
      const response = await instance.post(`${PurchaseOrderEndPoints.poList}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getCreatePOData: async (data: any) => {
    const response = await instance.post(`${PurchaseOrderEndPoints.createPO}`, data);
    return response;
  },
  getUpdatePOData: async (data: any) => {
    const response = await instance.post(`${PurchaseOrderEndPoints.updatePO}`, data);
    return response;
  },
  getFreightSummary: async (id: string) => {
    try {
      const response = await instance.get(`${PurchaseOrderEndPoints.freightSummary}?poId=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  getSupplierContact: async (id: string) => {
    try {
      const response = await instance.get(`${PurchaseOrderEndPoints.supplierContact}?poId=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  getPoNotesAttachmentData: async (payload: any) => {
    try {
      const response = await instance.post(`${PurchaseOrderEndPoints.poNotesAttachment}`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  }
};
