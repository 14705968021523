import {createSlice} from '@reduxjs/toolkit';
export interface remainderState {
  loading: boolean;
  remainder: Array<any>;
  error: string;
}

const initialState: remainderState = {
  loading: false,
  remainder: [],
  error: ''
};

const remainderSlice = createSlice({
  name: 'remainder',
  initialState,
  reducers: {
    remainderLoading: (state) => {
      state.loading = true;
    },
    remainderSuccess: (state, action) => {
      state.remainder = action.payload;
      state.loading = false;
    },
    remainderFailure: (state) => {
      state.loading = false;
      state.remainder = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default remainderSlice.reducer;
export const {remainderLoading, remainderSuccess, remainderFailure} = remainderSlice.actions;
