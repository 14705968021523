import * as React from 'react';
import {Box, Typography, Modal, Stack, Tabs, Tab, IconButton, useTheme} from '@mui/material';
import CustomButton from 'components/baseComponents/customButton';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AccountOverview from 'components/profile/account/accountOverview';
import Preferences from 'components/profile/account/preferences';
import SecurityOverview from 'components/profile/account/security';
import zIndex from '@mui/material/styles/zIndex';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
//below we are creating main component
const Account = (props: any) => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 768,
    width: 832,
    bgcolor: theme.palette.common.white,
    boxShadow: 24
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Modal open={props.openAccountModal} onClose={props.closeAccountModal} sx={{mt: '100px'}}>
        <Box id='main-box-accountSetting' sx={style}>
          {/* header of account-settings */}
          <Box
            id='header-accountSetting'
            sx={{
              height: '68px',
              width: '832px',
              borderBottom: `2px solid ${theme.palette.grayscale?.[200]}`
            }}
          >
            <Stack sx={{justifyContent: 'space-between'}} direction='row'>
              <Box sx={{pt: 2}} flexGrow={1}>
                <Typography
                  className='header-export-element'
                  variant='h6'
                  align='left'
                  sx={{
                    fontSize: '20px',
                    height: '24px',
                    width: '219px',
                    fontFamily: 'Roboto',
                    paddingLeft: '24px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    paddingBottom: '15px',
                    letterSpacing: '0.15px'
                  }}
                >
                  Account Settings
                </Typography>
              </Box>
              <Box id='box-contain-icon'>
                <IconButton
                  id='cross-icon'
                  size='large'
                  aria-label='show Close Icon'
                  color='inherit'
                  onClick={props.closeAccountModal}
                  sx={{
                    paddingBottom: '23px',
                    paddingTop: '23px',
                    paddingRight: '26px'
                  }}
                >
                  <CloseSharpIcon />
                </IconButton>
              </Box>
            </Stack>
          </Box>
          {/* body of account-settings */}
          <Box
            id='body-accountSetting'
            sx={{
              height: '620px',
              paddingTop: '5px',
              paddingLeft: '10px',
              borderBottom: `1px solid ${theme.palette.grayscale?.[300]}`
            }}
          >
            <Box sx={{borderColor: 'divider', marginTop: '20px'}}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label='Profile' />
                <Tab label='Security' />
                <Tab label='Preferences' />
              </Tabs>
            </Box>
            {/* For Account Overview Component */}
            <TabPanel value={value} index={0}>
              <AccountOverview />
            </TabPanel>
            {/* For Security component */}
            <TabPanel value={value} index={1}>
              <SecurityOverview />
            </TabPanel>
            {/* For Preferences Component */}
            <TabPanel value={value} index={2}>
              <Preferences />
            </TabPanel>
          </Box>
          <Box
            id='footer-accountSetting'
            sx={{
              marginTop: '2px',
              paddingLeft: '24px',
              height: '70px'
            }}
          >
            <Stack sx={{justifyContent: 'space-between'}} direction='row'>
              <CustomButton
                id='account-cancel'
                variant={'outlined'}
                onClick={props.closeAccountModal}
                sx={{
                  height: '36px',
                  width: '120px',
                  marginTop: '17px'
                }}
              >
                Cancel
              </CustomButton>
              <CustomButton
                id='account-save'
                variant='contained'
                sx={{
                  height: '36px',
                  width: '120px',
                  marginRight: '24px',
                  marginTop: '17px'
                }}
              >
                Save
              </CustomButton>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default Account;
