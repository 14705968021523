import {createSlice} from '@reduxjs/toolkit';
export interface AgedState {
  loading: boolean;
  aged: Array<any>;
  error: string;
}

const initialState: AgedState = {
  loading: false,
  aged: [],
  error: ''
};

const agedSlice = createSlice({
  name: 'aged',
  initialState,
  reducers: {
    agedLoading: (state) => {
      state.loading = true;
      state.aged = [];
      state.error = '';
    },
    agedSuccess: (state, action) => {
      state.aged = action.payload;
      state.loading = false;
    },
    agedFailure: (state, action) => {
      state.loading = false;
      state.aged = [];
      state.error = action.payload;
    }
  }
});

export default agedSlice.reducer;
export const {agedLoading, agedSuccess, agedFailure} = agedSlice.actions;
