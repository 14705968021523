import * as React from 'react';
import {Link} from 'react-router-dom';
import {Typography, TableHead, TableRow, styled, Box, Grid, Tooltip} from '@mui/material';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import EnhancedTable from 'components/customTable/enhancedTable';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {IColumnCell} from 'components/customTable/types';
import {CustomCard} from 'components/baseComponents/customCard';
import {useTheme} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {classPerformanceSagaActions} from 'redux/dashboard/classPerformance/classPerformanceSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {
  dateRangeCalculator,
  generatePayload,
  generateStickyHeaderValues
} from 'util/arrayOperations';
import {TILE_WIDTH} from 'config/constants';

const dateDropDownData = [
  {
    label: 'Yesterday',
    value: 'Yesterday'
  },
  {
    label: 'Week to Date',
    value: 'weekToDate'
  },
  {
    label: 'Last Week',
    value: 'lastWeek'
  },
  {
    label: 'Last 4 Weeks',
    value: 'last4Weeks'
  },
  {
    label: 'Month-to-Date',
    value: 'monthToDate'
  },
  {
    label: 'Quarter-to-Date',
    value: 'quarterToDate'
  },
  {
    label: 'Year-to-Date',
    value: 'yearToDate'
  }
];

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root .MuiBox-root:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 0
  },
  '& .MuiTableContainer-root': {
    overflowX: 'hidden'
  },
  '& .MuiTable-root': {
    marginRight: '16px'
  }
});

interface IClassPerformanceGridProps {
  id: string;
  className: string;
  sales: string;
  comp: string;
  compPercentage: string;
  // compPercentagePlan: string;
  actMgn: string;
  ohMgn: string;
  ooMgn: string;
  // mgnPlan: string;
}

interface IStickyHeaderProps {
  data: IClassPerformanceGridProps[];
  columnCell: IColumnCell<IClassPerformanceGridProps>[];
}

const StickyHeaderCell: React.FC<IStickyHeaderProps> = (props) => {
  const consolidatedData = generateStickyHeaderValues(props?.data, props?.columnCell);
  return (
    <>
      <TableHead>
        <TableRow>
          <BorderedTableStickyCell align='left' sx={{color: 'grayscale.400', pl: 2, top: '33px'}}>
            <Typography variant='subtitle2'>Class Totals</Typography>
          </BorderedTableStickyCell>
          {/* <TableStickyCell align='center' sx={{ top: '30px' }}></TableStickyCell> */}
          <TableStickyCell sx={{top: '33px', textAlign: 'center'}}>
            {consolidatedData?.sales ? `${consolidatedData?.sales}` : `$0`}
          </TableStickyCell>
          <TableStickyCell sx={{top: '33px', textAlign: 'center'}}>
            {consolidatedData?.comp ? `${consolidatedData?.comp}` : `$0`}
          </TableStickyCell>
          {/* <TableStickyCell align='right'>{consolidatedData?.compPercentage}</TableStickyCell> */}
          <BorderedTableStickyCell sx={{top: '33px', textAlign: 'center'}}>
            {/* {consolidatedData?.compPercentagePlan} */}
            {consolidatedData?.compPercentage ? `${consolidatedData?.compPercentage}` : `0%`}
          </BorderedTableStickyCell>
          <TableStickyCell sx={{top: '33px', textAlign: 'center'}}>
            {consolidatedData?.actMgn ? `${consolidatedData?.actMgn}` : `$0`}
          </TableStickyCell>
          <TableStickyCell sx={{top: '33px', textAlign: 'center'}}>
            {consolidatedData?.ohMgn ? `${consolidatedData?.ohMgn}` : `0%`}
          </TableStickyCell>
          <TableStickyCell sx={{top: '33px', textAlign: 'center'}}>
            {consolidatedData?.ooMgn ? `${consolidatedData?.ooMgn}` : `0%`}
          </TableStickyCell>
          {/* <TableStickyCell align='right'>{consolidatedData?.mgnPlan}</TableStickyCell> */}
        </TableRow>
      </TableHead>
    </>
  );
};

interface IPropType {
  filteredData: any;
}

const ClassPerformance: React.FC<IPropType> = (props) => {
  const [classPerformanceDate, setclassPerformanceDate] = React.useState('weekToDate');
  const theme = useTheme();
  const dispatch = useAppDispatch();

  /**
   * dispatch classPerformance data
   */
  const dispatchData = () => {
    const filterData = generatePayload(props?.filteredData);
    filterData.push({
      key: 'date',
      value: dateRangeCalculator(classPerformanceDate),
      condition: 'BETWEEN'
    });
    dispatch({
      type: classPerformanceSagaActions.FETCH_CLASS_PERFORMANCE_DATA,
      payload: {
        data: filterData
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [classPerformanceDate, props?.filteredData]);

  /**
   *
   * @param event dropdown onChange event
   */
  const handleSelect = (event: any): void => {
    setclassPerformanceDate(event?.target.value);
  };
  const LinkComponent = (row: any) => {
    const filter = {
      filter: {
        date: dateRangeCalculator(classPerformanceDate),
        departmentName: props?.filteredData['departmentName']
          ? props?.filteredData['departmentName']
          : '',
        className: row?.className
      }
    };

    if (row?.className.length > 10) {
      return (
        <Tooltip title={row?.className} placement='top'>
          <Link to={{pathname: `/Product`}} state={filter}>
            {row?.className.substring(0, 10) + '...'}
          </Link>
        </Tooltip>
      );
    } else {
      return (
        <>
          <Link to={{pathname: `/Product`}} state={filter}>
            {' '}
            {row?.className}
          </Link>{' '}
        </>
      );
    }
  };

  const {classPerformance, loading, error} = useAppSelector((state) => state.classPerformanceSlice);

  const columnCell: IColumnCell<IClassPerformanceGridProps>[] = [
    {
      id: 'className',
      name: 'ClassName',
      displayName: 'CLASSES',
      textColor: theme.palette.primary.main,
      isGroupBorderRequire: true,
      sx: {height: '32px'},
      customCell: LinkComponent
    },
    {
      id: 'sales',
      name: 'Sales',
      displayName: 'SALES',
      isNumeric: true,
      prefixText: '$',
      consolidateType: 'SUM',
      headerTextAlign: 'center',
      cellTextAlign: 'center'
    },
    {
      id: 'comp',
      name: 'Comp',
      displayName: 'COMP',
      isNumeric: true,
      prefixText: '$',
      headerTextAlign: 'center',
      consolidateType: 'SUM',
      cellTextAlign: 'center'
    },
    {
      id: 'compPercentage',
      name: 'Comp',
      displayName: 'COMP%',
      isNumeric: true,
      suffixText: '%',
      consolidateType: 'AVG',
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      isGroupBorderRequire: true
    },
    // {
    //   id: 'compPercentagePlan',
    //   name: 'compPercentagePlan',
    //   displayName: 'COMP% PLAN',
    //   isNumeric: true,
    //   suffixText: '%',
    //   consolidateType: 'AVG',
    //   headerTextAlign: 'center',
    //   cellTextAlign: 'right',
    //   isGroupBorderRequire: true
    // },
    {
      id: 'actMgn',
      name: 'Actual Margin',
      displayName: 'ACT MGN',
      isNumeric: true,
      prefixText: '$',
      consolidateType: 'SUM',
      headerTextAlign: 'center',
      cellTextAlign: 'center'
    },
    {
      id: 'ohMgn',
      name: 'On Hand Margin',
      displayName: 'OH MGN',
      isNumeric: true,
      suffixText: '%',
      consolidateType: 'AVG',
      headerTextAlign: 'center',
      cellTextAlign: 'center'
    },
    {
      id: 'ooMgn',
      name: 'On Order margin',
      displayName: 'OO MGN',
      isNumeric: true,
      suffixText: '%',
      consolidateType: 'AVG',
      headerTextAlign: 'center',
      cellTextAlign: 'center'
    }
    // {
    //   id: 'mgnPlan',
    //   name: 'mgnPlan',
    //   displayName: 'MGN PLAN',
    //   isNumeric: true,
    //   suffixText: '%',
    //   consolidateType: 'AVG',
    //   headerTextAlign: 'center',
    //   cellTextAlign: 'right'
    // }
  ];

  const Header = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{margin: 'auto'}}>
            <Typography sx={{color: 'common.black'}} variant='subtitle2' textAlign='left'>
              Class Performance
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <DropDownWrapper>
              <CustomSelect
                id='classPerformanceTile'
                name={'classPerformance_Date'}
                value={classPerformanceDate}
                options={dateDropDownData}
                onChange={handleSelect}
                title={'Date'}
              />
            </DropDownWrapper>
          </Grid>
        </Grid>
      </>
    );
  };

  const Content = () => {
    return (
      <>
        <EnhancedTable<IClassPerformanceGridProps>
          width={'100%'}
          rowData={classPerformance}
          stickyRows={<StickyHeaderCell data={classPerformance} columnCell={columnCell} />}
          columnCell={columnCell}
          rowKey={'sales'}
          defaultSortKey={'sales'}
          allowAlternateColor={false}
          componentType='box'
          dense={true}
          hidePagination
          id='dashboard-classPerformance'
        />
      </>
    );
  };

  if (classPerformance && classPerformance.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='classPerformance-withData'
          width={TILE_WIDTH.three}
          header={<Header />}
          headerSx={{marginBottom: '8px'}}
          content={loading ? <CustomLoader id='classPerformance-loader' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='classPerformance-withoutData'
          width={TILE_WIDTH.three}
          header={<Header />}
          headerSx={{marginBottom: '8px'}}
          content={
            loading ? (
              <CustomLoader id='classPerformance-loader' />
            ) : (
              <CustomError
                id='classPerformance-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default ClassPerformance;
