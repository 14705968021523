import {createSlice} from '@reduxjs/toolkit';

interface ICategorySupplier {
  sales: Array<any>;
  margin: Array<any>;
}
export interface ICategorySupplierState {
  loading: boolean;
  categorySupplier: ICategorySupplier;
  error: string;
}

const initialState: ICategorySupplierState = {
  loading: false,
  categorySupplier: {
    sales: [],
    margin: []
  },
  error: ''
};

const categorySupplierSlice = createSlice({
  name: 'categorySupplier',
  initialState,
  reducers: {
    categorySupplierLoading: (state) => {
      state.loading = true;
    },
    categorySupplierSuccess: (state, action) => {
      state.categorySupplier[action.payload.type as keyof ICategorySupplier] = action.payload.data;
      state.loading = false;
    },
    categorySupplierFailure: (state, action) => {
      state.loading = false;
      state.categorySupplier[action.payload.type as keyof ICategorySupplier] = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default categorySupplierSlice.reducer;
export const {categorySupplierLoading, categorySupplierSuccess, categorySupplierFailure} =
  categorySupplierSlice.actions;
