import {createSlice} from '@reduxjs/toolkit';
export interface demandForecastState {
  loading: boolean;
  demandForecast: Array<any>;
  error: string;
}

const initialState: demandForecastState = {
  loading: false,
  demandForecast: [],
  error: ''
};

const demandForecastSlice = createSlice({
  name: 'demandForecast',
  initialState,
  reducers: {
    demandForecastLoading: (state) => {
      state.loading = true;
    },
    demandForecastSuccess: (state, action) => {
      state.demandForecast = action.payload;
      state.loading = false;
    },
    demandForecastFailure: (state) => {
      state.loading = false;
      state.demandForecast = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default demandForecastSlice.reducer;
export const {demandForecastLoading, demandForecastSuccess, demandForecastFailure} =
  demandForecastSlice.actions;
