import {createSlice} from '@reduxjs/toolkit';
export interface getProfileState {
  loading: boolean;
  getProfile: any;
  error: string;
}

const initialState: getProfileState = {
  loading: false,
  getProfile: {},
  error: ''
};

const getProfileSlice = createSlice({
  name: 'getProfile',
  initialState,
  reducers: {
    getProfileLoading: (state) => {
      state.loading = true;
    },
    getProfileSuccess: (state, action) => {
      state.getProfile = action.payload;
      state.loading = false;
    },
    getProfileFailure: (state) => {
      state.loading = false;
      state.getProfile = {};
      state.error = 'Request failed with status code 404';
    }
  }
});

export default getProfileSlice.reducer;
export const {getProfileLoading, getProfileSuccess, getProfileFailure} = getProfileSlice.actions;
