import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  remainderFailure,
  remainderSuccess,
  remainderLoading
} from 'redux/dashboard/remainder/remainderSlice';

export const remainderSagaActions = {
  FETCH_REMAINDER_DATA: 'FETCH_REMAINDER_DATA'
};

export function* fetchRemainderData(action: any): any {
  try {
    yield put(remainderLoading());
    const {data} = yield call(DashboardServiceAPIS.getRemainderData, action.payload);
    yield put(remainderSuccess(data));
  } catch (error) {
    yield put(remainderFailure());
  }
}

export default function* watchFetchRemainderData() {
  yield takeEvery(remainderSagaActions.FETCH_REMAINDER_DATA, fetchRemainderData);
}
