import {createSlice} from '@reduxjs/toolkit';
export interface tagState {
  loading: boolean;
  tag: Array<any>;
  error: string;
}

const initialState: tagState = {
  loading: false,
  tag: [],
  error: ''
};

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    tagLoading: (state) => {
      state.loading = true;
    },
    tagSuccess: (state, action) => {
      state.tag = action.payload;
      state.loading = false;
    },
    tagFailure: (state) => {
      state.loading = false;
      state.tag = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default tagSlice.reducer;
export const {tagLoading, tagSuccess, tagFailure} = tagSlice.actions;
