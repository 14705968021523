import {styled, TableCell, TableRow} from '@mui/material';

export const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grayscale?.[50]
  }
}));

export const BorderedTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-right-style: solid;
    border-right-color: ${(props) => props.theme.palette.grayscale?.[300]};
    border-right-width: 2px;
    padding-right: ${(props) => props.theme.spacing(2)};
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const BorderedTableStickyCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-right-style: solid;
    border-right-color: ${(props) => props.theme.palette.grayscale?.[300]};
    border-right-width: 2px;
    background-color: ${(props) => props.theme.palette.primary.contrastText};
    padding-top: 0px;
    padding-bottom: 0px;
    white-space: nowrap;
  }
`;

export const BorderedTableHeadCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-right-style: solid;
    border-right-color: ${(props) => props.theme.palette.grayscale?.[300]};
    border-right-width: 2px;
    background-color: ${(props) => props.theme.palette.grayscale?.[100]};
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const TableHeadCell = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: ${(props) => props.theme.palette.grayscale?.[100]};
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const PlainTableHeadCell = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: ${(props) => props.theme.palette.common.white};
    border: unset;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const TableStickyCell = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

export const BorderedGroupTableHeadCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-right-style: solid;
    border-right-color: ${(props) => props.theme.palette.grayscale?.[300]};
    border-right-width: 2px;
    background-color: ${(props) => props.theme.palette.grayscale?.[100]};
    padding: 0px;
  }
`;
export const BorderedGroupTableSubHeadCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-width: 0px;
    background-color: ${(props) => props.theme.palette.grayscale?.[100]};
    color: ${(props) => props.theme.palette.grayscale?.[400]};
    padding-left: ${(props) => props.theme.spacing(4)};
    padding-top: 8px;
    padding-bottom: 8px;
    &:last-child {
      padding-left: ${(props) => props.theme.spacing(5)};
    }
  }
`;

/**
 * This method is for displyaing sticky header and border to the header cell
 * @param props All tabel cell props
 * @returns Table cell
 */
export const StickyHeaderCell = (props: any) => {
  return (
    <BorderedTableHeadCell
      sx={{
        position: 'sticky',
        left: 0,
        padding: 0,
        zIndex: (theme) => theme.zIndex.appBar + 2
      }}
      {...props}
    >
      {props.children}
    </BorderedTableHeadCell>
  );
};

/**
 * This method is for displyaing sticky header and border to the header cell
 * @param props All tabel cell props
 * @returns Table cell
 */
export const StickyCell = (props: any) => {
  return (
    <BorderedTableCell
      sx={{
        position: 'sticky',
        left: 0,
        height: 'inherit',
        backgroundColor: 'inherit',
        padding: 0,
        zIndex: (theme) => theme.zIndex.appBar
      }}
      {...props}
    >
      {props.children}
    </BorderedTableCell>
  );
};

/**
 * This method is for showing border to the header cell
 * @param props All tabel cell props
 * @returns Table cell
 */
export const TableHeaderHOC = (props: any) => {
  return (
    <>
      {props?.isGroupBorderRequire ? (
        <>
          <BorderedTableHeadCell id={props.id} {...props}>
            {props.children}
          </BorderedTableHeadCell>
        </>
      ) : (
        <>
          {props.borderless && props.borderless ? (
            <PlainTableHeadCell id={props.id} {...props}>
              {props.children}
            </PlainTableHeadCell>
          ) : (
            <TableHeadCell id={props.id} {...props}>
              {props.children}
            </TableHeadCell>
          )}
        </>
      )}
    </>
  );
};

/**
 * This method is for showing border to the header cellF
 * @param props All tabel cell props
 * @returns Table cell
 */
export const TableCellHOC = (props: any) => {
  function clickHandler(e: any) {
    e.stopPropagation();
  }

  return (
    <>
      {props?.isGroupBorderRequire ? (
        <>
          <BorderedTableCell onClick={clickHandler} {...props}>
            {props.children}
          </BorderedTableCell>
        </>
      ) : (
        <TableCell onClick={clickHandler} {...props}>
          {props.children}
        </TableCell>
      )}
    </>
  );
};
