import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {laborLoading, laborFailure, laborSuccess} from 'redux/dashboard/labor/laborSlice';

export const laborSagaActions = {
  FETCH_LABOR_DATA_SAGA: 'FETCH_LABOR_DATA_SAGA'
};

export function* fetchLaborData(action: any): any {
  try {
    yield put(laborLoading());
    const {data} = yield call(DashboardServiceAPIS.getLaborData, action.payload);
    yield put(laborSuccess(data));
  } catch (error) {
    yield put(laborFailure());
  }
}

export default function* watchFetchLaborData() {
  yield takeEvery(laborSagaActions.FETCH_LABOR_DATA_SAGA, fetchLaborData);
}
