import {createSlice} from '@reduxjs/toolkit';
export interface productDemandForeCastItemState {
  loading: boolean;
  productDemandForeCastIngItem: any;
  error: string;
}

const initialState: productDemandForeCastItemState = {
  loading: false,
  productDemandForeCastIngItem: {},
  error: ''
};

const productDemandForeCastItemSlice = createSlice({
  name: 'productDemandForeCastIngItem',
  initialState,
  reducers: {
    productDemandForeCastItemLoading: (state) => {
      state.loading = true;
    },
    productDemandForeCastItemSuccess: (state, action) => {
      state.productDemandForeCastIngItem = action.payload;
      state.loading = false;
    },
    productDemandForeCastItemFailure: (state) => {
      state.loading = false;
      state.productDemandForeCastIngItem = {};
      state.error = 'Request failed with status code 404';
    }
  }
});

export default productDemandForeCastItemSlice.reducer;
export const {
  productDemandForeCastItemLoading,
  productDemandForeCastItemSuccess,
  productDemandForeCastItemFailure
} = productDemandForeCastItemSlice.actions;
