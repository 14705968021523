import * as React from 'react';
import {Box, Typography, TableHead, TableRow, styled} from '@mui/material';
import EnhancedTable from 'components/customTable/enhancedTable';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {vendorSalesItemSagaActions} from 'redux/vendor/detailsItemTab/vendorSalesItemSaga';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {buyerSagaActions} from 'redux/dashboard/master/buyerSaga';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {toFlatPropertyMap} from 'util/objectHelper';
import {CustomComponent} from 'features/vendor/vendorUtil';
import {useLocation} from 'react-router-dom';
import moment from 'moment';

interface IVendorSalesItemGridProps extends IVendorGridProps {
  comp: {compSales: string; comp: string /*plan: string*/};
  receipt: {receipt: string /*rcptVar: string */};
  // skuCount: { active: string; discontinue: string; newSale: string };
  sales: {retail: string; margin: string};
}

interface IVendorGridProps {
  departmentName: string;
  onHand: string;
  onOrder: string;
}

interface IVendorSalesItemGrid extends IVendorGridProps {
  id: string;
  'comp.compSale': string;
  'comp.compPercentage': string;
  'comp.plan': string;
  'receipt.receiptSales': string;
  'receipt.receiptVariance': string;
  // 'skuCount.active': string;
  // 'skuCount.discontinue': string;
  // 'skuCount.newSale': string;
  'sales.retail': string;
  'sales.margin': string;
}
interface IPropType {
  vendorId: string;
}
const columnCell: IColumnCell<IVendorSalesItemGrid>[] = [
  {
    id: 'departmentName',
    name: 'Department Name',
    displayName: 'DEPT',
    sticky: true,
    customCell: CustomComponent,
    sx: {
      minWidth: '150px'
    }
  },
  {
    id: 'sales.retail',
    name: 'Sales',
    displayName: 'Sales',
    isGroupBorderRequire: true,
    isNumeric: true,
    isGroupedCell: true,
    isColumnSortable: false,
    consolidateType: 'SUM',
    groupedKeys: ['sales.retail', 'sales.margin'],
    groupDisplayKeys: ['retail', 'margin'],
    groupHeaderDisplaySx: [{width: '100px'}, {width: '100px'}]
  },
  {
    id: 'comp.compSale',
    name: 'comp',
    displayName: 'Comp',
    isGroupBorderRequire: true,
    isNumeric: true,
    isGroupedCell: true,
    isColumnSortable: false,
    consolidateType: 'SUM',
    groupedKeys: ['comp.compSale', 'comp.compPercentage'],
    groupDisplayKeys: ['comp sale', 'comp'],
    groupHeaderDisplaySx: [{width: '100px'}, {width: '100px'}]
  },
  {
    id: 'receipt.receiptSales',
    name: 'Receipt',
    displayName: 'Receipt',
    isGroupBorderRequire: true,
    isNumeric: true,
    isGroupedCell: true,
    isColumnSortable: false,
    prefixText: '$',
    consolidateType: 'SUM',
    groupedKeys: ['receipt.receiptSales' /*'receipt.receiptVariance'*/],
    groupDisplayKeys: ['receipt' /*'rcptVar'*/],
    groupHeaderDisplaySx: [{width: '120px'}]
  },
  // {
  //   id: 'skuCount.active',
  //   name: 'skuCount',
  //   displayName: 'SKU Count',
  //   isGroupBorderRequire: true,
  //   isNumeric: true,
  //   isGroupedCell: true,
  //   isColumnSortable: false,
  //   consolidateType: 'SUM',
  //   groupedKeys: ['skuCount.active', 'skuCount.newSale', 'skuCount.discontinue'],
  //   groupDisplayKeys: ['active', 'newSale', 'discontinue'],
  //   sx: {
  //     textAlign: 'center'
  //   }
  // },

  {
    id: 'onHand',
    name: 'On Hand',
    displayName: 'OH HAND',
    isNumeric: true,
    consolidateType: 'AVG',
    prefixText: '$',
    headerTextAlign: 'left',
    cellTextAlign: 'left',
    sx: {
      textAlign: 'left'
    }
  },
  {
    id: 'onOrder',
    name: 'On Order',
    displayName: 'On Order',
    consolidateType: 'AVG',
    isNumeric: true,
    prefixText: '$',
    sx: {
      textAlign: 'left'
    }
  }
];

const CustomTableStickyCell = styled(TableStickyCell)({
  top: '64px',
  fontFamily: 'Roboto Medium',
  letterSpacing: '0.1px',
  textAlign: 'right'
});

const StickyHeaderCell = (props: any) => {
  const consolidatedData = generateStickyHeaderValues(props.data, props.columnCell);

  return (
    <>
      <TableHead>
        <TableRow>
          <BorderedTableStickyCell
            sx={{
              position: 'sticky',
              left: 0,
              backgroundColor: 'Background',
              zIndex: (theme) => theme.zIndex.appBar + 20,
              top: '64px'
            }}
          >
            <CustomTableStickyCell sx={{color: 'grayscale.500', borderBottom: 0}}>
              <Typography variant='subtitle2'>CONSOLIDATED STATS</Typography>
            </CustomTableStickyCell>
          </BorderedTableStickyCell>
          <CustomTableStickyCell sx={{width: '100px', textAlign: 'left'}}>
            ${consolidatedData['sales.retail'] ? consolidatedData['sales.retail'] : 0}
          </CustomTableStickyCell>
          <BorderedTableStickyCell
            sx={{
              textAlign: 'center',
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px',
              width: '100px'
            }}
          >
            {consolidatedData['sales.margin'] ? consolidatedData['sales.margin'] : 0}%
          </BorderedTableStickyCell>
          <CustomTableStickyCell sx={{width: '100px', align: 'left'}}>
            ${consolidatedData['comp.compSale'] ? consolidatedData['comp.compSale'] : 0}
          </CustomTableStickyCell>
          {/* <CustomTableStickyCell>
            {consolidatedData['comp.compPercentage'] ? consolidatedData['comp.compPercentage'] : 0}%
          </CustomTableStickyCell> */}
          <BorderedTableStickyCell
            sx={{
              textAlign: 'center',
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px',
              width: '100px'
            }}
          >
            {/* {consolidatedData['comp.plan'] ? consolidatedData['comp.plan'] : 0}% */}
            {consolidatedData['comp.compPercentage'] ? consolidatedData['comp.compPercentage'] : 0}%
          </BorderedTableStickyCell>
          {/* <CustomTableStickyCell>
            <Typography variant='subtitle2'>
              $
              {consolidatedData['receipt.receiptSales']
                ? consolidatedData['receipt.receiptSales']
                : 0}
            </Typography>
          </CustomTableStickyCell> */}
          <BorderedTableStickyCell
            align='center'
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px',
              width: '110px'
            }}
          >
            <Typography variant='subtitle2'>
              {/* {consolidatedData['receipt.receiptVariance']
                ? consolidatedData['receipt.receiptVariance']
                : 0}
              % */}

              {consolidatedData['receipt.receiptSales']
                ? consolidatedData['receipt.receiptSales']
                : 0}
            </Typography>
          </BorderedTableStickyCell>
          {/* <CustomTableStickyCell sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle2'>
              {consolidatedData['skuCount.active'] ? consolidatedData['skuCount.active'] : 0}
            </Typography>
          </CustomTableStickyCell>
          <CustomTableStickyCell sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle2'>
              {consolidatedData['skuCount.newSale'] ? consolidatedData['skuCount.newSale'] : 0}
            </Typography>
          </CustomTableStickyCell>
          <BorderedTableStickyCell
            align='center'
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px'
            }}
          >
            <Typography variant='subtitle2'>
              {consolidatedData['skuCount.discontinue']
                ? consolidatedData['skuCount.discontinue']
                : 0}
            </Typography>
          </BorderedTableStickyCell> */}
          <CustomTableStickyCell sx={{width: '200px', textAlign: 'left'}}>
            <Typography variant='subtitle2'>
              {consolidatedData.onHand ? consolidatedData.onHand : 0}
            </Typography>
          </CustomTableStickyCell>
          <BorderedTableStickyCell
            sx={{
              top: '64px',
              fontFamily: 'Roboto Medium',
              letterSpacing: '0.1px'
            }}
          >
            <Typography variant='subtitle2'>
              {consolidatedData['onOrder'] ? consolidatedData['onOrder'] : 0}
            </Typography>
          </BorderedTableStickyCell>
        </TableRow>
      </TableHead>
    </>
  );
};

const SalesItem: React.FC<IPropType> = (props) => {
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const {vendorSalesItem, loading} = useAppSelector((state: any) => state.vendorSalesItemSlice);
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: buyerSagaActions.FETCH_BUYER_DATA
    });
  }, []);

  const {master} = useAppSelector((state) => state.masterSlice);
  const {buyer} = useAppSelector((state) => state.buyerSlice);

  const [dataOne, dataTwo, dataThree] = master;

  const generatePayload = (values: IFilterValues) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      if (value && value != '' && value.toString().substring(0, 4).toLowerCase() != 'all ') {
        if (key === 'date') {
          temp.push({key: key, value: value, condition: 'BETWEEN'});
        } else {
          temp.push({key: key, value: value, condition: 'EQUAL'});
        }
      }
    }
    return temp;
  };
  const defaultDateFormat = 'MM/DD/YYYY';
  //To-do- Remove this once demo done
  //const startDate = moment().startOf('week').format(defaultDateFormat);
  const startDate = '01/01/2023';
  const endDate = moment().format(defaultDateFormat);
  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date (Sales, Comp, Receipt)',
      format: defaultDateFormat,
      defaultValue: `${startDate}|${endDate}`,
      sx: {
        width: '210px'
      }
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      defaultValue: 'All Departments',
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName',
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'buyerId',
      title: 'Buyer',
      defaultValue: 'All Buyers',
      placeholder: 'Buyers',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      options: generateLabelValue(buyer, 'buyerName')
    }
  ];
  const defaultData = filterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = React.useState(defaultData);
  /**
   * dispatch vendor details sales item
   */
  const dispatchData = () => {
    dispatch({
      type: vendorSalesItemSagaActions.FETCH_VENDORSALESITEM_DATA,
      payload: {
        id: props.vendorId,
        data: generatePayload(filtervalues)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);
  const formateRowData = (vendorData: IVendorSalesItemGridProps[]) => {
    const data = vendorData.map((element: any) => {
      return {
        ...element,
        comp: element.comp,
        sales: element.sales,
        skuCount: element.skuCount,
        receipt: element.receipt
      };
    });
    const gridData: IVendorSalesItemGrid[] = data.map((element) => {
      const nestedObject: IVendorSalesItemGrid = toFlatPropertyMap(
        element
      ) as unknown as IVendorSalesItemGrid;
      nestedObject['sales.retail'] = `${
        nestedObject['sales.retail'] !== null ? `$${nestedObject['sales.retail']}` : ''
      }`;
      nestedObject['sales.margin'] = `${
        nestedObject['sales.margin'] !== null ? `${nestedObject['sales.margin']}` : ''
      }`;
      nestedObject['comp.compSale'] = `${
        nestedObject['comp.compSale'] !== null ? `$${nestedObject['comp.compSale']}` : ''
      }`;
      nestedObject['comp.compPercentage'] = `${
        nestedObject['comp.compPercentage'] !== null ? `${nestedObject['comp.compPercentage']}` : ''
      }`;
      return nestedObject;
    });
    return gridData;
  };

  const rowData: IVendorSalesItemGrid[] =
    vendorSalesItem.length > 0 ? formateRowData(vendorSalesItem) : [];

  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };

  return (
    <Box sx={{marginTop: '16px'}}>
      <GridFilter
        values={filtervalues}
        filterTemplate={filterTemplate}
        onFilterChange={onFilterApply}
        hideMoreFilter={true}
        onReset={() => {
          setFilterValues(defaultData);
        }}
        id='vendor-details-salesItem'
      />
      {vendorSalesItem && vendorSalesItem.length > 0 ? (
        <>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <CustomLoader id='sales-item-load' />
            </Box>
          )}
          <EnhancedTable<IVendorSalesItemGrid>
            rowData={rowData}
            stickyRows={<StickyHeaderCell data={rowData} columnCell={columnCell} />}
            columnCell={columnCell}
            rowKey={'departmentName'}
            defaultSortKey={'departmentName'}
            allowAlternateColor={true}
            rowsPerPage={10}
            id='vendor-details-sales'
          />
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='sales-item-load' />
          ) : (
            <CustomError
              id='sales-item-error'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default SalesItem;
