import {Box, styled, Tab, Tabs, Typography} from '@mui/material';
import React, {useState} from 'react';
import CustomButton from '../../../components/baseComponents/customButton';
import CustomTabPanel from '../../../components/baseComponents/customTabPanel';
import ExportModal from '../../../components/baseComponents/exportOption';
import {BreadcrumbNav as SupplierSalesBreadcrumbNav} from '../reportBreadCrumbs';
import CategorySupplierMargin from './sections/categorySupplierMargin';
import CategorySupplierSales from './sections/categorySupplierSales';

interface IPropType {
  defaultValue?: number;
}
const CustomTabs = styled(Tabs)(({theme}) => ({
  '& .MuiTab-root': {
    color: theme.palette.grayscale?.[400],
    padding: '12px 0',
    mr: 3,
    minWidth: 'fit-content'
  }
}));

const CustomTab = styled(Tab)(({theme}) => ({
  textTransform: 'uppercase',
  color: theme.palette.grayscale?.[400],
  padding: '12px 0',
  minWidth: 0,
  marginRight: '24px',
  '&.Mui-disabled': {
    color: theme.palette.grayscale?.[400]
  }
}));

//Below is Main components
const SupplierSales: React.FC<IPropType> = (props) => {
  const [value, setValue] = React.useState(props.defaultValue || 0);
  const [showExpModal, setShowExpModal] = React.useState(false);
  //handle-click function
  const handleClick = () => {
    setShowExpModal(true);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{mx: 5, mt: 5}}>
        <SupplierSalesBreadcrumbNav />
        {/* For Making the two elements in same div left and right */}
        <Box sx={{pl: 0, pt: 1, pb: 1}} flexGrow={1}>
          <Typography variant='h4' align='left'>
            Category/Supplier Sales
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end'
          }}
        >
          <CustomTabs value={value} onChange={handleChange}>
            <CustomTab label='SALES' value={0} />
            <CustomTab value={1} label='MARGIN' />
          </CustomTabs>
          <CustomButton
            disableElevation
            id='supplierSalesExport'
            onClick={handleClick}
            sx={{
              bgcolor: 'primary.main',
              color: 'common.white',
              '&.Mui-disabled': {
                bgcolor: 'grayscale.200'
              }
            }}
            variant={'contained'}
          >
            <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
              Export
            </Typography>
          </CustomButton>
        </Box>
        <CustomTabPanel value={value} index={0} id='report-supplierSales-sales'>
          <Box>
            <CategorySupplierSales />
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1} id='report-supplierSales-margin'>
          <Box>
            <CategorySupplierMargin />
          </Box>
        </CustomTabPanel>
        {showExpModal && (
          <ExportModal
            id='supplierSalesBalance'
            open={showExpModal}
            handleClose={() => setShowExpModal(false)}
          />
        )}
      </Box>
    </>
  );
};

export default SupplierSales;
