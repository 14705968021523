import {createSlice} from '@reduxjs/toolkit';
export interface supplyChainState {
  loading: boolean;
  supplyChain: Array<any>;
  error: string;
}

const initialState: supplyChainState = {
  loading: false,
  supplyChain: [],
  error: ''
};

const supplyChainSlice = createSlice({
  name: 'supplyChain',
  initialState,
  reducers: {
    supplyChainLoading: (state) => {
      state.loading = true;
    },
    supplyChainSuccess: (state, action) => {
      state.supplyChain = action.payload;
      state.loading = false;
    },
    supplyChainFailure: (state) => {
      state.loading = false;
      state.supplyChain = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default supplyChainSlice.reducer;
export const {supplyChainLoading, supplyChainSuccess, supplyChainFailure} =
  supplyChainSlice.actions;
