import {createTheme} from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: '0 auto'
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0
        },
        '& input[type=number]': {
          MozAppearance: 'textfield'
        },
        '*,*:before, *:after': {
          boxSizing: 'initial'
        },
        '& a:-webkit-any-link': {
          color: '-webkit-link',
          cursor: 'pointer',
          textDecoration: 'none'
        },
        '& a:visited, a:hover, a:active': {
          color: '-webkit-link'
        },
        '&::-webkit-scrollbar': {
          width: 4,
          height: 4
        },
        /* Track */
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 5px #EFF1F6',
          borderRadius: '3px'
        },
        /* Handle */
        '&::-webkit-scrollbar-thumb': {
          background: '#919AAA',
          borderRadius: '3px'
        },
        /* svg */
        '& svg.MuiSvgIcon-root.MuiSelect-iconStandard': {
          color: '#CBCFD8',
          fontSize: '1.8rem'
        }
      }
    }
  },
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#0B0B0B'
    },
    primary: {
      main: '#4664FB',
      dark: '#263EB5',
      light: '#C4CFFD',
      contrastText: '#E7F2FF'
    },
    secondary: {
      light: '#FFE0E0',
      main: '#FE5144',
      dark: '#E8111C',
      contrastText: '#000'
    },
    error: {
      light: '#EF5350',
      main: '#ED0B07',
      dark: '#C62828'
    },
    neutral: {
      main: '',
      secondary1: '#1CE8C2',
      secondary2: '#E8EDFF',
      secondary4: '#FFDBBE',
      secondary5: '#FDBB64',
      secondary6: '#EF6C00',
      secondary7: '#CDF9D9',
      secondary8: '#4CC2C2',
      secondary9: '#009494',
      secondary10: '#D8D8D8',
      secondary11: '#008484',
      secondary12: '#00000080'
    },
    bpGreyFont: {
      main: '#919AAA'
    },
    bpGreyBorder: {
      main: '#6E798F'
    },
    grayscale: {
      main: '',
      50: '#FAFAFB',
      100: '#EFF1F6',
      200: '#E4E7ED',
      300: '#CBCFD8',
      400: '#6E798F',
      500: '#6E798F',
      600: '#102B61'
    }
  },
  typography: {
    fontFamily: ['Roboto Regular', 'Roboto Medium', 'Roboto Light', 'Roboto Bold'].join(','),
    h1: {
      fontSize: '96px',
      letterSpacing: '-1.5px',
      fontFamily: 'Roboto Light'
    },
    h2: {
      fontSize: '60px',
      letterSpacing: '-0.5px',
      fontFamily: 'Roboto Light'
    },
    h3: {
      fontSize: '48px',
      letterSpacing: '0px',
      fontFamily: 'Roboto Regular'
    },
    h4: {
      fontSize: '34px',
      letterSpacing: '0.25px',
      fontFamily: 'Roboto Regular'
    },
    h5: {
      fontSize: '24px',
      letterSpacing: '0px',
      fontFamily: 'Roboto Regular'
    },
    h6: {
      fontSize: '20px',
      letterSpacing: '0.15px',
      fontFamily: 'Roboto Medium'
    },
    subtitle1: {
      fontSize: '16px',
      letterSpacing: '0.15px',
      fontFamily: 'Roboto Regular'
    },
    subtitle2: {
      fontSize: '14px',
      letterSpacing: '0.1px',
      fontFamily: 'Roboto Medium'
    },
    button: {
      fontFamily: 'Roboto Medium',
      fontSize: '14px',
      letterSpacing: '1.25px'
    },
    body1: {
      fontSize: '16px',
      letterSpacing: '0.5px',
      fontFamily: 'Roboto Regular'
    },
    body2: {
      fontSize: '14px',
      letterSpacing: '0.25px',
      fontFamily: 'Roboto Regular'
    },
    caption: {
      fontSize: '14px',
      letterSpacing: '0.4px',
      fontFamily: 'Roboto Regular'
    },
    overline: {
      fontSize: '10px',
      letterSpacing: '1.5px',
      fontFamily: 'Roboto Regular'
    }
  }
});

export default theme;
