import {
  Box,
  Typography,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  IconButton
} from '@mui/material';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CustomButton from 'components/baseComponents/customButton';
import {useState} from 'react';
//This will be use to styling the box
const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  height: 286,
  bgcolor: '#FFFFFF',
  boxShadow: 24
};
//We are creating object for export Modal
const exportType = {pdf: 'PDF', excel: 'Excel', email: 'Email'};
//Here we are creating the main-component
const ExportModal = (props: any) => {
  const [selectedExportType, setSelectedExportType] = useState({
    [exportType.pdf]: false,
    [exportType.excel]: false,
    [exportType.email]: false
  });
  const onCheckHandle = (event: any) => {
    const eventName = event?.target?.name;
    if (event?.target?.checked) {
      setSelectedExportType({...selectedExportType, [eventName]: true});
    } else {
      setSelectedExportType({...selectedExportType, [eventName]: false});
    }
  };
  return (
    <>
      <Modal id={`Modal-${props.id}`} open={props.open} onClose={props.handleClose}>
        {/* we are  creating the main Box which will contain nested Box */}
        <Box id='main-box' sx={style}>
          {/* Below one is header box having Icon and header-title */}
          <Box sx={{borderBottom: '1px solid gray'}}>
            <Stack sx={{justifyContent: 'space-between'}} direction='row'>
              <Box sx={{pt: 2}} flexGrow={1}>
                <Typography
                  className='header-export-element'
                  variant='h6'
                  align='left'
                  sx={{
                    fontSize: '20px',
                    font: 'Roboto Medium',
                    paddingLeft: '24px',
                    paddingBottom: '21px',
                    letterSpacing: '0.15px'
                  }}
                >
                  Export Options
                </Typography>
              </Box>
              <Box id='box-contain-icon'>
                <IconButton
                  id='cross-icon'
                  size='large'
                  aria-label='show Close Icon'
                  color='inherit'
                  onClick={props.handleClose}
                  sx={{
                    paddingBottom: '23px',
                    paddingTop: '23px',
                    paddingRight: '26px'
                  }}
                >
                  <CloseSharpIcon />
                </IconButton>
              </Box>
            </Stack>
          </Box>
          <>
            <Typography
              variant='subtitle2'
              sx={{
                fontSize: 12,
                fontWeight: 'bold',
                pt: '15px',
                pl: '24px',
                color: '#102B61',
                fontFamily: 'Roboto'
              }}
            >
              Select Method
            </Typography>
            <FormGroup id='export-model-form'>
              <FormControlLabel
                id='open-PDF'
                control={
                  <Checkbox
                    onChange={(event) => onCheckHandle(event)}
                    name={exportType.pdf}
                    checked={selectedExportType[exportType.pdf] as boolean}
                  />
                }
                sx={{paddingLeft: '26px'}}
                label={
                  <Typography variant='body2' sx={{height: '20px', width: '162px'}}>
                    Open PDF in Browser
                  </Typography>
                }
              />
              <FormControlLabel
                id='download-excel-file'
                name={exportType.excel}
                control={
                  <Checkbox
                    onChange={(event) => onCheckHandle(event)}
                    name={exportType.excel}
                    checked={selectedExportType[exportType.excel] as boolean}
                  />
                }
                sx={{paddingLeft: '26px'}}
                label={<Typography variant='body2'>Download Excel file</Typography>}
              />
              <FormControlLabel
                id='send-email'
                name={exportType.email}
                control={
                  <Checkbox
                    onChange={(event) => onCheckHandle(event)}
                    name={exportType.email}
                    checked={selectedExportType[exportType.email] as boolean}
                  />
                }
                sx={{paddingLeft: '26px'}}
                label={
                  <Typography variant='body2' sx={{pb: '5px'}}>
                    Send to Email
                  </Typography>
                }
              />
              <CustomButton
                id='custom-submit-button'
                variant='contained'
                disabled={
                  !selectedExportType[exportType.pdf] &&
                  !selectedExportType[exportType.email] &&
                  !selectedExportType[exportType.excel]
                }
                onClick={() => undefined}
                sx={{height: 36, width: 312, marginLeft: '24px'}}
              >
                Submit
              </CustomButton>
            </FormGroup>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default ExportModal;
