import Chip from '@mui/material/Chip';
import {styled} from '@mui/material/styles';

const StyledChip = styled(Chip)(({theme}) => ({
  borderRadius: '2px',
  backgroundColor: theme.palette.primary.contrastText,
  height: '16px',
  'span.MuiChip-label': {
    backgroundColor: theme.palette.primary.contrastText,
    padding: '0 12px'
  }
}));

export default StyledChip;
