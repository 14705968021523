import {takeEvery, put, call} from 'redux-saga/effects';
import {PurchaseOrderAPIS} from 'redux/purchaseOrder/purchaseOrder_service';
import {
  freightSummaryFailure,
  freightSummaryLoading,
  freightSummarySuccess
} from 'redux/purchaseOrder/details/freightSummary/freightSummarySlice';

export const freightSummarySagaActions = {
  FETCH_FREIGHTSUMMARY_DATA: 'FETCH_FREIGHTSUMMARY_DATA'
};

export function* fetchFreightSummaryData(action: any): any {
  try {
    yield put(freightSummaryLoading());
    const {data} = yield call(PurchaseOrderAPIS.getFreightSummary, action.payload);
    yield put(freightSummarySuccess(data));
  } catch (error) {
    yield put(freightSummaryFailure());
  }
}

export default function* watchFetchFreightSummaryData() {
  yield takeEvery(freightSummarySagaActions.FETCH_FREIGHTSUMMARY_DATA, fetchFreightSummaryData);
}
