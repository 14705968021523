import * as React from 'react';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter from 'components/customTable/gridFilter';
import {IFilterValues} from 'components/customTable/gridFilter';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {TableHead, TableRow, Typography, Box, useTheme} from '@mui/material';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {ContentComponent} from './productUtil';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {productDemandForeCastItemIngSagaActions} from 'redux/product/details/productDemandForeCastIngItem/productDemandForeCastIngItemSaga';
import {useState} from 'react';
import {CustomLoader} from 'components/baseComponents/customMessage';
import {districtSagaActions} from 'redux/dashboard/master/districtSaga';
import {generateLabelValue} from 'util/arrayOperations';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {distrubutionCenterSagaActions} from 'redux/dashboard/master/distrubutionCenterSaga';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';
import {viewInFilterOptions} from 'config/constants';
interface IDemandForecast {
  date: string;
  value: string;
}
interface ISalesDemandForecast {
  store: string;
  [key: string]: any;
  id: string;
}
interface IReportSalesDemandForecast {
  store: string;
  demandForecast: [IDemandForecast];
  id: string;
}

interface IDemandListFilterValues {
  viewIn: string;
  viewBy: string;
  analyzeBy: string;
  subset: string;
}

const demandfilterTemplate: IFilterTemplate[] = [
  {
    type: filterFieldTypes.DROPDOWN,
    property: 'viewIn',
    title: 'View In',
    defaultValue: 'Units',
    sx: {
      width: '178px'
    },
    options: [
      {
        label: 'Units',
        value: 'Units'
      }
    ]
  },
  {
    type: filterFieldTypes.DROPDOWN,
    property: 'viewBy',
    title: 'View By',
    allowAllOptions: true,
    sx: {
      width: '178px'
    },
    options: [
      {
        label: 'Store',
        value: 'Store'
      }
    ]
  },
  {
    type: filterFieldTypes.DROPDOWN,
    property: 'analyzeBy',
    title: 'Analyze By',
    defaultValue: 'Sales',
    sx: {
      width: '178px'
    },
    options: [
      {
        label: 'Seasonality Trend',
        value: 'Seasonality Trend'
      },
      {
        label: 'Weather',
        value: 'Weather'
      },
      // {
      //   label: 'Special Events',
      //   value: 'Special Events'
      // },
      {
        label: 'Sales',
        value: 'Sales'
      }
    ]
  },
  {
    type: filterFieldTypes.DROPDOWN,
    property: 'subset',
    title: 'Subset',
    sx: {
      width: '200px'
    },
    options: []
  }
];
const defaultData = demandfilterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
  if (!acc[obj.property]) {
    acc[obj['property']] = null;
  }
  // Add value to list for given key's value
  acc[obj['property']] = obj.defaultValue || '';
  return acc;
}, {});

let columnCell: IColumnCell<ISalesDemandForecast>[] = [
  {
    id: 'store',
    name: 'store',
    displayName: 'STORE',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    isColumnSortable: false,
    customCell: ContentComponent
  }
];
let cacheValue = [] as any;
const addcolumnCellData = (rowData: ISalesDemandForecast[]) => {
  columnCell = [];
  Object.keys(rowData[0] || {}).forEach((element: string) => {
    if (!columnCell.find((e) => e.id == element)) {
      columnCell.push({
        id: element,
        name: element,
        displayName: element,
        headerTextAlign: 'left',
        isGroupBorderRequire: false,
        isColumnSortable: false,
        sx: {
          textAlign: 'right'
        }
      });
    }
  });
  return columnCell;
};

interface IDemandListGrid {
  productId: string;
}

const DemandListGrid: React.FC<IDemandListGrid> = (props) => {
  const theme: any = useTheme();
  const [filtervalues, setFilterValues] = useState<IDemandListFilterValues>(
    defaultData as IDemandListFilterValues
  );
  const StickyDemandHeaderCell = (props: any) => {
    if (props.data.length > 0) {
      const keys = Object.keys(props.data[0]);
      const dateKeys = keys.filter((e) => e != 'store');
      const aggregateDict = dateKeys.map((prop: any) => {
        const handTotal = props.data.reduce(
          (accumulator: any, c: any) => accumulator + Number(c[prop]),
          0
        );
        return {key: prop, value: handTotal};
      });
      return (
        <>
          <TableHead>
            <TableRow>
              <BorderedTableStickyCell
                sx={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'Background',
                  zIndex: theme.zIndex.appBar + 1,
                  paddingRight: 0
                }}
              >
                <Typography variant='subtitle2' sx={{color: 'grayscale.400', borderBottom: 0}}>
                  CONSOLIDATED STATS
                </Typography>
              </BorderedTableStickyCell>
              {aggregateDict.map((ele, index) => {
                return (
                  <TableStickyCell key={`${ele}-${index}`} align='right'>
                    <Typography variant='subtitle2'>{ele.value}</Typography>
                  </TableStickyCell>
                );
              })}
            </TableRow>
          </TableHead>
        </>
      );
    } else {
      return (
        <>
          <TableHead>
            <TableRow>
              <BorderedTableStickyCell
                colSpan={2}
                sx={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'Background',
                  zIndex: theme.zIndex.appBar + 1,
                  paddingRight: 0
                }}
              >
                <Typography variant='subtitle2' sx={{color: 'grayscale.400', borderBottom: 0}}>
                  CONSOLIDATED STATS
                </Typography>
              </BorderedTableStickyCell>
            </TableRow>
          </TableHead>
        </>
      );
    }
  };

  //For API Data Fetching
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch({
      type: productDemandForeCastItemIngSagaActions.FETCH_PRODUCTDEMANDFORECASTINGITEM_DATA,
      payload: {
        id: props.productId,
        data: generatePayload(filtervalues)
      }
    });
  }, [filtervalues]);
  const {productDemandForeCastIngItem, error, loading} = useAppSelector(
    (state) => state.productDemandForeCastItemIngSlice
  );
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      temp.push({key: key, value: value, condition: true});
    }
    return temp;
  };
  const onFilterApply = (values: IFilterValues) => {
    if (!!cacheValue.analyzeBy && cacheValue.analyzeBy !== values.analyzeBy) {
      values.subset = '';
    }
    const filterValues = values as unknown as IDemandListFilterValues;
    const demandFilter = {
      viewin: filterValues?.viewIn,
      [filterValues?.viewBy]: filterValues?.subset
    };
    generatePayload(demandFilter);
    setFilterValues(values as unknown as IDemandListFilterValues);
    cacheValue = values;
  };

  React.useEffect(() => {
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: distrubutionCenterSagaActions.FETCH_DISTRUBUTION_CENTER_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: distrubutionCenterSagaActions.FETCH_DISTRUBUTION_CENTER_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
  }, []);
  const {district} = useAppSelector((state) => state.districtSlice);
  const {region} = useAppSelector((state) => state.regionSlice);
  const {distrubutionCenter} = useAppSelector((state) => state.distrubutionCenterSlice);
  const {store} = useAppSelector((state) => state.storeSlice);
  const updateSubset = (filterTemplate: IFilterTemplate[]) => {
    const updatedFilterTemplate: IFilterTemplate[] = filterTemplate.map((ele: IFilterTemplate) => {
      if (ele.property === 'subset') {
        if (filtervalues?.analyzeBy === 'Seasonality Trend') {
          return {
            ...ele,
            // options: generateLabelValue(district, 'storeDistrict'),
            options: [
              {label: 'Back To school', value: 'Back To school'},
              {label: 'BBQ & Grilling Season', value: 'BBQ & Grilling Season'},
              // {label: 'Canned Goods', value: 'Canned Goods'}
              // {label: 'Labor day', value: 'Labor day'},
              // {label: 'Memorial Day', value: 'Memorial Day'},
              {label: 'Thanks Giving', value: 'Thanks Giving'},
              {label: 'Christmas', value: 'Christmas'},
              {label: 'Easter', value: 'Easter'},
              {label: "Valentine's Day", value: "Valentine's Day"},
              {label: 'New Year', value: 'New Year'}
            ],
            placeholder: 'Seasonality Trend',
            defaultValue: 'All Seasonality Trend'
          } as IFilterTemplate;
        } else if (filtervalues?.analyzeBy === 'Weather') {
          return {
            ...ele,
            // options: generateLabelValue(region, 'storeRegion'),
            options: [
              {label: 'Summer', value: 'Summer'},
              {label: 'Fall', value: 'Fall'},
              {label: 'Winter', value: 'Winter'},
              {label: 'Spring', value: 'Spring'}
            ],
            placeholder: 'Weather',
            defaultValue: 'Summer'
          } as IFilterTemplate;
        }
        // else if (filtervalues?.analyzeBy === 'Special Events') {
        //   return {
        //     ...ele,
        //     // options: generateLabelValue(distrubutionCenter, 'distributionCenter'),
        //     options: [
        //       // {label: 'Labor day', value: 'Labor day'},
        //       // {label: 'Memorial Day', value: 'Memorial Day'},
        //       {label: 'Thanks Giving', value: 'Thanks Giving'},
        //       {label: 'Christmas', value: 'Christmas'},
        //       {label: 'Easter', value: 'Easter'},
        //       {label: "Valentine's Day", value: "Valentine's Day"},
        //       {label: 'New Year', value: 'New Year'}
        //     ],
        //     placeholder: 'Special Events',
        //     defaultValue: 'All Special Events'
        //   } as IFilterTemplate;
        // }
        return {...ele} as IFilterTemplate;
      } else {
        return {...ele} as IFilterTemplate;
      }
    });
    return updatedFilterTemplate || [];
  };
  //Here we are creating Function to formate the array of object
  const formateRowData = (listData: IReportSalesDemandForecast[]) => {
    const gridData: ISalesDemandForecast[] = listData.map((ele: IReportSalesDemandForecast) => {
      const reportItem: ISalesDemandForecast = {...ele};
      const demandForecastItem: IDemandForecast[] = ele.demandForecast;
      demandForecastItem.forEach((item: IDemandForecast) => {
        reportItem[item.date] = item.value;
      });

      delete reportItem.demandForecast;
      return reportItem;
    });

    return gridData;
  };
  const rowData: ISalesDemandForecast[] =
    productDemandForeCastIngItem && productDemandForeCastIngItem.length > 0
      ? formateRowData(productDemandForeCastIngItem)
      : [];
  if (productDemandForeCastIngItem && productDemandForeCastIngItem.length > 0 && !error) {
    console.log('444444', rowData);
    return (
      <>
        <GridFilter
          values={filtervalues as unknown as IFilterValues}
          onFilterChange={onFilterApply}
          filterTemplate={updateSubset(demandfilterTemplate)}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-details-demandList'
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='demand-list-load-data' />
          </Box>
        )}
        <EnhancedTable<ISalesDemandForecast>
          rowData={rowData}
          columnCell={addcolumnCellData(rowData)}
          rowKey={'store'}
          defaultSortKey={'store'}
          stickyRows={<StickyDemandHeaderCell data={rowData} />}
          rowsPerPage={9}
          id='product-details-salesDemand'
        />
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          values={filtervalues as unknown as IFilterValues}
          filterTemplate={demandfilterTemplate}
          onFilterChange={onFilterApply}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-details-demandList'
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              maxHeight: '844px'
            }}
          >
            {' '}
            <CustomLoader id='demand-list-load' />
          </Box>
        )}
      </>
    );
  }
};

export default DemandListGrid;
