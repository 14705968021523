import {createSlice} from '@reduxjs/toolkit';
export interface productItemStoreState {
  loading: boolean;
  productItemStore: Array<any>;
  error: string;
}

const initialState: productItemStoreState = {
  loading: false,
  productItemStore: [],
  error: ''
};

const productItemStoreSlice = createSlice({
  name: 'productItemStore',
  initialState,
  reducers: {
    productItemStoreLoading: (state) => {
      state.loading = true;
    },
    productItemStoreSuccess: (state, action) => {
      state.productItemStore = action.payload;
      state.loading = false;
    },
    productItemStoreFailure: (state) => {
      state.loading = false;
      state.productItemStore = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default productItemStoreSlice.reducer;
export const {productItemStoreLoading, productItemStoreSuccess, productItemStoreFailure} =
  productItemStoreSlice.actions;
