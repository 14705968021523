import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  storeAlertLoading,
  storeAlertSuccess,
  storeAlertFailure
} from 'redux/dashboard/storeAlert/storeAlertSlice';

export const storeAlertActions = {
  FETCH_STOREALERT_SAGA: 'FETCH_STOREALERT_SAGA'
};

export function* fetchStoreAlertData(action: any): any {
  try {
    yield put(storeAlertLoading());
    const {data} = yield call(DashboardServiceAPIS.getStoreAlertData, action.payload.data);
    yield put(storeAlertSuccess(data));
  } catch (error) {
    yield put(storeAlertFailure());
  }
}

export default function* watchFetchStoreAlertData() {
  yield takeEvery(storeAlertActions.FETCH_STOREALERT_SAGA, fetchStoreAlertData);
}
