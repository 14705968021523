import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {styled, TextField} from '@mui/material';
import {StyledCalenderIcon} from './customDateRange';

export const CalenderComponent = {
  OpenPickerIcon: StyledCalenderIcon
};
export const DatePickerInput = styled(TextField)(({theme}) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${theme.palette.grayscale?.[200]} !important`
    },
    '&:hover fieldset': {
      borderColor: '#0ab093'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    },
    height: '32px',
    width: '300px',
    backgroundColor: 'common.white'
  }
}));
const CustomDatePicker = styled(DatePicker)(({theme}) => ({
  'label + &': {
    marginTop: 0
  }
}));
export default CustomDatePicker;
