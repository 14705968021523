import * as React from 'react';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material';

interface IToggleOptions {
  value: string;
  label: string;
}

interface IToggleProps {
  id: string;
  value: string;
  options: Array<IToggleOptions>;
  handleChange: (event: React.MouseEvent<HTMLElement>, newValue: string) => void;
}

export default function CustomToggle(props: IToggleProps) {
  const [alignment, setAlignment] = React.useState(props?.value);
  const theme = useTheme();

  const StyledToggleButton = styled(ToggleButton)({
    '&.MuiToggleButton-root': {
      height: '36px',
      width: '94px',
      fontSize: '14px',
      borderColor: theme.palette.bpGreyFont?.main,
      color: theme.palette.bpGreyFont?.main,
      '&.Mui-selected': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.neutral?.secondary2
      }
    }
  });

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      props?.handleChange(event, newAlignment);
    }
  };

  return (
    <Stack direction='row' spacing={4}>
      <ToggleButtonGroup
        id={props?.id}
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label='text alignment'
      >
        {props?.options.map((item) => {
          return (
            <StyledToggleButton value={item.value} key={item.label} aria-label='left aligned'>
              <Typography variant='button'>{item.label}</Typography>
            </StyledToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Stack>
  );
}
