/* eslint-disable react/prop-types */
import {Box, Button, styled, Typography} from '@mui/material';
import {ReactComponent as Loader} from 'assets/images/icons/Icons & UI-Refresh & Sync-Sync_Loader.svg';
import {ReactComponent as ErrorIcon} from 'assets/images/icons/Icons & UI-Error-Error_Inactive.svg';
import {useTheme} from '@mui/system';
const CustomBox = styled(Box)({
  display: 'flex',
  justContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});
interface ICustomLoader {
  id: string;
}
interface ICustomError {
  id: string;
  onReload?: () => void;
}

export const CustomLoader: React.FC<ICustomLoader> = (props) => {
  const theme = useTheme();
  return (
    <CustomBox id={props?.id}>
      <Loader
        fill={`
            ${theme.palette.primary?.main}
        }`}
      />
      <Typography variant='body2' sx={{color: theme.palette.common?.black}}>
        Loading...
      </Typography>
    </CustomBox>
  );
};

export const CustomError: React.FC<ICustomError> = (props) => {
  const theme = useTheme();
  return (
    <CustomBox id={props?.id}>
      <ErrorIcon
        fill={`
            ${theme.palette?.primary?.main}
        }`}
      />
      <Typography variant='caption' sx={{letterSpacing: '0.25px'}}>
        No Data Available
      </Typography>
      <Button
        sx={{
          '&:hover': {
            backgroundColor: 'white !important'
          },
          '@media (hover: none)': {
            '&:hover': {
              backgroundColor: 'white !important'
            }
          }
        }}
        onClick={() => {
          props.onReload && props.onReload();
        }}
      >
        <Typography
          variant='button'
          sx={{
            color: theme.palette?.primary?.main,
            marginTop: '60px'
          }}
        >
          Reload Data
        </Typography>
      </Button>
    </CustomBox>
  );
};
