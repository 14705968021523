import React from 'react';
import {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Box, Stack, Typography, Button, Popover, TableCell} from '@mui/material';
import EnhancedTable from 'components/customTable/enhancedTable';
import {BreadcrumbNav as ReportBreadCrumbs} from '../reportBreadCrumbs';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {ReactComponent as Actions} from 'assets/images/icons/Icons & UI-Processing-Processing-Dots.svg';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import CustomButton from 'components/baseComponents/customButton';
import {StyledTableRow, TableCellHOC} from 'components/customTable/tableCell';
import {warehouseLiabilityReportSagaActions} from 'redux/report/inventory/warehouseLiabilityReport/warehouseLiabilityReportSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {generateLabelValue} from 'util/arrayOperations';
import ExportModal from 'components/baseComponents/exportOption';
import {warehouseSagaActions} from 'redux/dashboard/master/warehouseSaga';

interface IPropType {
  atpEnabler?: (val: boolean) => void;
}
interface IProductGridProps {
  wh: string;
  itemID: string;
  itemName: string;
  pack: string;
  size: string;
  expiration: string;
  expiryOh: string;
  itemDetails: IProductExpiryDetails[];
  aur: string;
  storeScan: string;
  liability: string;
  suggestedAction: string;
  suggestedRetail: string;
  action: null | string;
  id: string;
}
interface IProductExpiryDetails {
  expiration: string;
  expiryOh: string;
  aur: string;
  storeScan: string;
  liability: string;
  suggestedAction: string;
  suggestedRetail: string;
}
const WarehouseLiability: React.FC<IPropType> = () => {
  const location: any = useLocation();
  const [showExpModal, setShowExpModal] = useState(false);
  //handle-click function
  const handleClick = () => {
    setShowExpModal(true);
  };
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const {receivedWarehouseLiabilityReport, error, loading} = useAppSelector(
    (state) => state.warehouseLiabilityReportSlice
  );
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      if (key === 'wareHouse') {
        temp.push({key: key, value: JSON.stringify(value), condition: true});
      } else {
        temp.push({key: key, value: value, condition: true});
      }
    }
    return temp;
  };
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };

  const rowData: IProductGridProps[] =
    receivedWarehouseLiabilityReport && receivedWarehouseLiabilityReport.length > 0
      ? (receivedWarehouseLiabilityReport as unknown as IProductGridProps[])
      : [];

  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: warehouseSagaActions.FETCH_WAREHOUSE_DATA
    });
  }, []);

  const {master} = useAppSelector((state) => state.masterSlice);
  const {warehouse} = useAppSelector((state) => state.warehouseSlice);
  const [dataOne] = master;

  const LinkComponent = (row: any) => {
    return (
      <>
        <Link to={{pathname: `/Product/${row.itemID}`}} state={row}>
          <Typography variant={'body2'} sx={{color: 'primary.main', fontFamily: 'Roboto Medium'}}>
            {row.itemID}
          </Typography>
        </Link>
      </>
    );
  };

  const ActionComponent: React.FC = (row: any) => {
    const divRef = React.useRef();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      divRef.current && setAnchorEl(divRef.current);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? `simple-popover` : undefined;

    return (
      <Box id='actionCell' ref={divRef}>
        <Button
          onClick={handleClick}
          sx={{
            p: 0,
            '&:hover': {
              bgcolor: 'unset'
            }
          }}
        >
          {/* <Actions aria-describedby={id} transform='rotate(90)' /> */}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Stack direction={'column'} sx={{p: 2}}>
            <Typography
              variant={'body2'}
              sx={{
                color: 'primary.main',
                fontFamily: 'Roboto Medium',
                pb: '18.05px'
              }}
            >
              Change price
            </Typography>
            <Typography
              variant={'body2'}
              sx={{
                color: 'primary.main',
                fontFamily: 'Roboto Medium',
                pb: '18.05px'
              }}
            >
              Action 2
            </Typography>
            <Typography
              variant={'body2'}
              sx={{
                color: 'primary.main',
                fontFamily: 'Roboto Medium',
                pb: '18.05px'
              }}
            >
              Action 3
            </Typography>
          </Stack>
        </Popover>
      </Box>
    );
  };

  const columnCell: IColumnCell<IProductGridProps>[] = [
    {
      id: 'wh',
      name: 'wh',
      isNumeric: true
    },
    {
      id: 'itemID',
      name: 'itemID',
      displayName: 'Item Id',
      customCell: LinkComponent
    },
    {
      id: 'itemName',
      name: 'itemName',
      displayName: 'Item',
      hasTags: true,
      sx: {
        minWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    {
      id: 'pack',
      name: 'pack',
      isColumnSortable: false
    },
    {
      id: 'size',
      name: 'size',
      isNumeric: true,
      isGroupBorderRequire: true,
      isColumnSortable: false
    },
    {
      id: 'expiration',
      name: 'expiration',
      displayName: 'Expiration',
      isNumeric: true
    },
    {
      id: 'expiryOh',
      name: 'expiryOh',
      displayName: 'Expiry OH',
      isNumeric: true
    },
    {
      id: 'aur',
      name: 'aur',
      displayName: 'aur',
      isNumeric: true
    },
    {
      id: 'storeScan',
      name: 'storeScan',
      displayName: 'Store Scan',
      isNumeric: true
    },
    {
      id: 'liability',
      name: 'liability',
      displayName: 'Liability',
      isNumeric: true,
      isGroupBorderRequire: true
    },
    {
      id: 'suggestedAction',
      name: 'suggestedAction',
      displayName: 'Suggested Action'
    },
    {
      id: 'suggestedRetail',
      name: 'suggestedRetail',
      displayName: 'Suggested Retail',
      sx: {
        textAlign: 'right'
      },
      isGroupBorderRequire: true
    },
    {
      id: 'action',
      name: 'action',
      displayName: 'action',
      isColumnSortable: false,
      sx: {
        pr: 1
      }
    }
  ];

  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.MULTISELECT,
      property: 'wareHouse',
      title: 'Warehouse',
      defaultValue: ['95', '96', '97', '98'],
      sx: {
        width: '178px'
      },
      options: generateLabelValue(warehouse, 'warehouseID')
    },

    {
      type: filterFieldTypes.DROPDOWN,
      property: 'department',
      title: 'Department',
      placeholder: 'Departments',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      options: generateLabelValue(dataOne?.data, 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'range',
      title: 'range',
      defaultValue: '<15',
      sx: {
        width: '178px'
      },
      options: [
        {
          label: '<15',
          value: '<15'
        },
        {
          label: '<7',
          value: '<7'
        }
      ]
    }
  ];

  const defaultData = filterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});
  const [filtervalues, setFilterValues] = useState(defaultData);
  React.useEffect(() => {
    dispatch({
      type: warehouseLiabilityReportSagaActions.FETCH_WAREHOUSELIABILITYREPORT_SAGA,
      payload: generatePayload(filtervalues)
    });
  }, [filtervalues]);

  const RowContainerComponent = (row: any, cellColumn: IColumnCell<IProductGridProps>[]) => {
    return <RowContentComponent row={row} cellColumn={cellColumn} />;
  };

  const RowContentComponent = (row: any, cellColumn: IColumnCell<IProductGridProps>[]) => {
    const rowData2: IProductGridProps = row as IProductGridProps;
    return (
      <>
        {rowData2.itemDetails?.map((ele: IProductExpiryDetails) => {
          return (
            <StyledTableRow
              sx={{
                height: '55px',
                whiteSpace: 'nowrap',
                backgroundColor: 'common.white',
                '&:nth-of-type(even)': {
                  backgroundColor: 'grayscale.50'
                }
              }}
              key={`gridGroupRow-${row['itemID']}}`}
            >
              {/* An empty cell for group icon */}
              <TableCell padding='none'></TableCell>
              <TableCell padding='none'></TableCell>
              <TableCell padding='none'></TableCell>
              <TableCell padding='none'></TableCell>
              <TableCell padding='none'></TableCell>
              <TableCellHOC padding='none' isGroupBorderRequire={true}></TableCellHOC>
              <TableCellHOC align={'right'} isGroupBorderRequire={false}>
                <Typography variant='body2'>{ele.expiration}</Typography>
              </TableCellHOC>
              <TableCellHOC align={'right'} isGroupBorderRequire={false}>
                <Typography variant='body2'>{ele.expiryOh}</Typography>
              </TableCellHOC>
              <TableCellHOC align={'right'} isGroupBorderRequire={false}>
                <Typography variant='body2'>{ele.aur}</Typography>
              </TableCellHOC>
              <TableCellHOC align={'right'} isGroupBorderRequire={false}>
                <Typography variant='body2'>{ele.storeScan}</Typography>
              </TableCellHOC>
              <TableCellHOC align={'right'} isGroupBorderRequire={true}>
                <Typography variant='body2'>{ele.storeScan}</Typography>
              </TableCellHOC>
              <TableCellHOC align={'left'} isGroupBorderRequire={false}>
                <Typography variant='body2'>{ele.suggestedAction}</Typography>
              </TableCellHOC>
              <TableCellHOC align={'right'} isGroupBorderRequire={true}>
                <Typography variant='body2'>{ele.suggestedRetail}</Typography>
              </TableCellHOC>
              <TableCellHOC
                align={'right'}
                isGroupBorderRequire={true}
                id={`warehouse-liability-ActionComponent-${row['itemID']}}`}
              >
                <Typography variant='body2'>
                  <ActionComponent key={`warehouse-liability-ActionComponent-${row['itemID']}}`} />
                </Typography>
              </TableCellHOC>
            </StyledTableRow>
          );
        })}
      </>
    );
  };
  if (receivedWarehouseLiabilityReport && receivedWarehouseLiabilityReport.length > 0 && !error) {
    return (
      <Box sx={{mt: 5}}>
        <ReportBreadCrumbs />
        <Box>
          <Stack sx={{justifyContent: 'space-between'}} direction='row'>
            <Box sx={{pl: 0, pt: 1, pb: 1}} flexGrow={1}>
              <Typography variant='h4' align='left'>
                Warehouse Liability
              </Typography>
            </Box>
            <Box sx={{pl: 0, pt: 1, pb: 1}}>
              <CustomButton
                variant='contained'
                onClick={handleClick}
                sx={{height: 40}}
                id='report-inventory-warehourse-export'
              >
                Export
              </CustomButton>
            </Box>
          </Stack>
          <GridFilter
            values={filtervalues}
            filterTemplate={filterTemplate}
            onFilterChange={onFilterApply}
            hideMoreFilter={true}
            onReset={() => {
              setFilterValues(defaultData);
            }}
            id='report-inventory-warehourse-filter'
          />
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <CustomLoader id='warehouse-liability-load' />
            </Box>
          )}
          <EnhancedTable<IProductGridProps>
            height={844}
            rowData={rowData}
            columnCell={columnCell}
            rowKey={'itemID'}
            defaultSortKey={'itemID'}
            allowAlternateColor={true}
            rowsPerPage={25}
            selectable={false}
            allowRowGrouping={true}
            rowGroupTemplate={RowContainerComponent}
            id='warehouse-liability-table'
          />
        </Box>
        {showExpModal && (
          <ExportModal
            id='warehouseLiablity'
            open={showExpModal}
            handleClose={() => setShowExpModal(false)}
          />
        )}
      </Box>
    );
  } else {
    return (
      <Box sx={{mt: 5}}>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='warehouse-liability-filter'
        />
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              maxHeight: '844px'
            }}
          >
            {' '}
            <CustomLoader id='warehouse-liability-load' />
          </Box>
        ) : (
          <CustomError id='warehouse-liability-error' />
        )}
      </Box>
    );
  }
};
export default WarehouseLiability;
