import {createSlice} from '@reduxjs/toolkit';
export interface topWeeklySalesState {
  loading: boolean;
  topWeeklySalesStore: Array<any>;
  error: string;
}

const initialState: topWeeklySalesState = {
  loading: false,
  topWeeklySalesStore: [],
  error: ''
};

const topWeeklyStoreSlice = createSlice({
  name: 'topWeeklySalesStore',
  initialState,
  reducers: {
    topWeeklyStoreLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    topWeeklyStoreSuccess: (state, action) => {
      state.topWeeklySalesStore = action.payload;
      state.loading = false;
    },
    topWeeklyStoreFailure: (state) => {
      state.loading = false;
      state.topWeeklySalesStore = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default topWeeklyStoreSlice.reducer;
export const {topWeeklyStoreLoading, topWeeklyStoreSuccess, topWeeklyStoreFailure} =
  topWeeklyStoreSlice.actions;
