import * as React from 'react';
import {styled} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {ReactComponent as GlassIcon} from 'assets/images/icons/Icons & UI-Search-Search_Neutro_Inactive.svg';
import {ReactComponent as Dashboard} from 'assets/images/icons/Icons & UI-Dashboard-Dashboard_Active.svg';
import {ReactComponent as CrateBox} from 'assets/images/icons/Box.svg';
import {ReactComponent as Bell} from 'assets/images/icons/Icons & UI-Bell-Bell_White.svg';
import {ReactComponent as Cart} from 'assets/images/icons/Icons & UI-Cart-Orders_Active.svg';
import {ReactComponent as Reports} from 'assets/images/icons/Icons & UI-Reports-Reports_Blue.svg';
import {ReactComponent as Truck} from 'assets/images/icons/Icons & UI-Truck-Perishables_Active.svg';
import {colorList} from 'util/colors';
import {useTheme} from '@mui/material/styles';
import {DebounceInput} from 'react-debounce-input';
import ProfileOverlay from 'components/profile/profileOverlay';
import Account from 'components/profile/account/account';
import handleRoleMapping, {handleAccessFilter, getRoleFeatureList} from 'util/helperRouterMap';
import {clearLocalStorage} from 'util/tokenizer';
import SearchOverlay from 'components/searchOverlay';
import GlobalNotification from 'components/globalNotification';

const getLocationPath = (path: string) => {
  const temp = path.slice(1).toUpperCase();
  const res = temp.split('/');
  switch (res[0]) {
    case 'PRODUCT':
    case 'VENDOR':
    case 'REPORT':
      return res[0];

    default:
      return temp;
  }
};

const headerNavs = [
  {
    name: 'DASHBOARD',
    link: '/Dashboard',
    icon: Dashboard
  },
  {
    name: 'PRODUCT',
    link: '/Product',
    icon: CrateBox
  },
  {
    name: 'VENDOR',
    link: '/Vendor',
    icon: Truck
  },
  {
    name: 'PO',
    link: '/PO',
    icon: Cart
  },
  {
    name: 'REPORT',
    link: '/Report',
    icon: Reports
  }
];

interface IHeaderNavItem {
  name: string;
  link: string;
  icon: React.ReactElement;
}

interface IRoleType {
  name: string;
  permission: Array<any>;
  routePath: string;
}

interface IIconGenerator {
  component: any;
  fill: string;
}

export default function Header() {
  const theme = useTheme();
  const Search = styled('div')({
    border: `2px solid ${theme.palette.grayscale?.[500]}`,
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'inherit'
    },
    height: '36px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      display: 'flex',
      alignSelf: 'center'
    }
  });

  const LetterAvatars = () => {
    return (
      <Stack direction='row' spacing={2}>
        <Avatar sx={{bgcolor: theme.palette.primary.dark}}>J</Avatar>
      </Stack>
    );
  };

  const SearchIconWrapper = styled('div')({
    width: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  });

  const CustomMenuItem = styled(MenuItem)({
    '&.Mui-selected': {
      color: `${theme.palette.common.white} !important`,
      ':hover': {
        backgroundColor: 'transparent !important'
      }
    },
    '&.MuiMenuItem-root': {
      padding: '18px 12px 18px 0',
      marginRight: '12px',
      color: theme.palette.bpGreyFont?.main,
      backgroundColor: 'transparent'
    }
  });
  const location: any = useLocation();
  const [activeTab, setActiveTab] = React.useState<string>(getLocationPath(location.pathname));

  const [searchText, setSearchText] = React.useState('');
  const [showSearchModel, setShowSearchModel] = React.useState(false);
  const [headerLinks, setHeaderLinks] = React.useState<any>(headerNavs);
  const [getRoleData, setRoleData] = React.useState<IRoleType>();
  const [checkAccess, setAccess] = React.useState<Array<string>>([]);
  const [showNotification, setShowNotification] = React.useState(false);
  React.useEffect(() => {
    const checkActive = getLocationPath(location.pathname);
    if (checkActive != activeTab) {
      setActiveTab(checkActive);
    }
  });

  React.useEffect(() => {
    setRoleData(handleRoleMapping(location?.pathname));
  }, []);

  React.useEffect(() => {
    const access: any = getRoleData && handleAccessFilter(getRoleData);
    setAccess(access);
  }, [getRoleData]);

  React.useEffect(() => {
    const currentRoleFeature: any = getRoleFeatureList();
    const updatedHeaderNav = headerNavs.filter((e) => {
      return (
        currentRoleFeature.feature.filter(
          (i: any) => i.routePath === e.link && i.permission.length > 0
        ).length > 0
      );
    });
    setHeaderLinks(updatedHeaderNav);
  }, [checkAccess]);

  React.useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setShowSearchModel(false);
        setSearchText('');
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const navigate = useNavigate();

  const closeModal = () => {
    setShowSearchModel(false);
    setSearchText('');
  };

  const handleSearchChange = (value: any) => {
    if (value != '') {
      setShowSearchModel(true);
    } else {
      setShowSearchModel(false);
    }

    setSearchText(value);
  };
  //creating state for logged-in-modal
  const [showProfileOverlay, setShowProfileOverlay] = React.useState(false);
  const [showAccountModal, setAccountModal] = React.useState(false);
  /** profile-overlay-open*/
  const handleProfileMenuOpen = () => {
    if (showSearchModel) {
      setShowSearchModel(false);
      setSearchText('');
    }
    setShowProfileOverlay(!showProfileOverlay);
  };
  /**profile-overlay-close */
  const handleProfileMenuClose = () => {
    setShowProfileOverlay(false);
  };
  /**Handle account Modal */
  const handleAccountModal = () => {
    setShowProfileOverlay(false);
    setAccountModal(!showAccountModal);
  };
  const handleTab = (event: React.MouseEvent<HTMLElement>) => {
    const input = event?.target as HTMLElement;
    setActiveTab(input.innerText);
  };

  const logout = () => {
    clearLocalStorage();
    navigate('/login');
  };

  const handleNotificationClick = () => {
    if (showSearchModel) {
      setShowSearchModel(false);
      setSearchText('');
    }
    setShowNotification((prev) => !prev);
  };
  const handleClickAwayGlobalNotification = () => {
    setShowNotification(false);
  };

  const IconGenerator: React.FC<IIconGenerator> = ({component: Component, ...rest}) => {
    return <Component {...rest} />;
  };

  return (
    <Box sx={{flexGrow: 1, width: '100%', zIndex: 1200}}>
      <AppBar position='fixed' elevation={0} sx={{height: '60px'}}>
        <Toolbar
          sx={(theme) => ({
            backgroundColor: `${colorList.primaryDark}`,
            [theme.breakpoints.up('sm')]: {
              padding: '0 40px',
              maxHeight: '60px',
              height: '100%',
              minHeight: 0
            }
          })}
        >
          {headerLinks.map((e: IHeaderNavItem, index: any) => {
            return (
              <Link to={`${e.link}`} key={`${e.name + index}`}>
                <CustomMenuItem
                  selected={activeTab === `${e.name}` ? true : false}
                  onClick={handleTab}
                >
                  <SearchIconWrapper sx={`${e.name}` === 'REPORT' ? {marginRight: '8px'} : {}}>
                    <IconGenerator
                      component={e.icon}
                      fill={`${
                        activeTab === `${e.name}`
                          ? theme.palette.common.white
                          : theme.palette.bpGreyFont?.main
                      }`}
                    />
                  </SearchIconWrapper>
                  <Typography
                    variant='subtitle2'
                    textAlign='center'
                    sx={{letterSpacing: '1.25px'}}
                    id={`dashboard-header-${e.name + index}`}
                  >
                    {`${e.name}`}
                  </Typography>
                </CustomMenuItem>
              </Link>
            );
          })}
          <Box sx={{flexGrow: 1}} />
          <Box sx={{display: {xs: 'none', md: 'flex'}}}>
            <Search
              sx={{
                '& input': {
                  color: theme.palette.common.white
                },
                '& input::placeholder': {
                  color: theme.palette.grayscale?.[400],
                  fontSize: '14px',
                  letterSpacing: '.25px',
                  fontFamily: 'Roboto Regular'
                }
              }}
            >
              <DebounceInput
                minLength={2}
                value={searchText}
                placeholder='Search by keyword, Item/Vendor ID, or UPC'
                debounceTimeout={500}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  outline: 'none',
                  width: '355px',
                  padding: '8px 0 8px 16px',
                  boxSizing: 'border-box'
                }}
                onChange={(e: any) => handleSearchChange(e.target.value)}
              />

              <SearchIconWrapper sx={{marginRight: '3px', width: 'auto'}}>
                <GlassIcon fill={theme.palette.bpGreyFont?.main} />
              </SearchIconWrapper>
            </Search>
            <IconButton
              size='large'
              aria-label='show 17 new notifications'
              color='inherit'
              onClick={handleNotificationClick}
            >
              <Badge color='error'>
                <SearchIconWrapper sx={{marginLeft: 2}}>
                  <Bell fill={theme.palette.grayscale?.[300]} />
                </SearchIconWrapper>
              </Badge>
            </IconButton>
            <IconButton
              size='large'
              edge='end'
              aria-label='account of current user'
              aria-controls={'Avatar-Icon'}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'
            >
              <LetterAvatars />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {showSearchModel && (
        <SearchOverlay open={showSearchModel} searchText={searchText} onClose={closeModal} />
      )}
      {showProfileOverlay && (
        <ProfileOverlay
          open={showProfileOverlay}
          handleClose={handleProfileMenuClose}
          handleAccountModal={handleAccountModal}
          handleLogout={logout}
        />
      )}
      {showAccountModal && (
        <Account
          openAccountModal={showAccountModal}
          closeAccountModal={() => {
            setAccountModal(false);
          }}
        />
      )}
      {showNotification && (
        <GlobalNotification open={showNotification} onClose={handleClickAwayGlobalNotification} />
      )}
    </Box>
  );
}
