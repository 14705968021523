import {
  Box,
  Grid,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  styled,
  Paper,
  TableContainer,
  Typography,
  useTheme
} from '@mui/material';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import moment from 'moment';
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {perpetualInventorySagaActions} from 'redux/report/inventory/perpetualInventoryAvg/perpetualInventorySaga';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {filterFieldTypes, IFilterTemplate} from 'components/customTable/types';
import PerpetualLineGraphComponent, {IAxis} from 'features/report/inventory/perpetualLineGraph';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {generateLabelValue} from 'util/arrayOperations';
import CustomButton from 'components/baseComponents/customButton';

const viewByOptions = [
  {
    label: 'Week',
    value: 'week'
  },
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Year',
    value: 'year'
  }
];

const metricsOptions = [
  {
    label: 'All Metrics',
    value: 'allMetrics'
  },
  {
    label: 'Inventory',
    value: 'inventory'
  },
  {
    label: 'LY Inventory',
    value: 'inventoryLy'
  },
  {
    label: 'Sales',
    value: 'sales'
  },
  {
    label: 'Shrink',
    value: 'shrink'
  }
];

const LabelTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-right-style: solid;
    width: 20px;
    font-weight: bold;
    flex-grow: 1;
    border-right-color: ${(props) => props.theme.palette.grayscale?.[300]};
    border-right-width: 2px;
    padding-right: ${(props) => props.theme.spacing(2)};
    background-color: ${(props) => props.theme.palette.grayscale?.[100]};
  }
`;
const ValueTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-tight: none,
    flex-grow: 1;
    display: flex;
    width: 1144px;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
`;

const PerpetualInventoryGraph: React.FC = () => {
  const theme = useTheme();
  //For API Data Fetching
  const dispatch: any = useAppDispatch();
  const xAxis = {
    name: 'date',
    displayTitle: 'date',
    format: 'MM/DD/YY'
  };

  const {master} = useAppSelector((state) => state.masterSlice);
  const {store} = useAppSelector((state) => state.storeSlice);
  const {region} = useAppSelector((state) => state.regionSlice);

  const [dataOne, dataTwo, dataThree] = master;

  const {perpetualInventory, loading, error} = useAppSelector(
    (state) => state.perpetualInventorySlice
  );

  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
  }, []);

  const defaultDateFormat = 'MM/DD/YYYY';
  const dataLength = 13;
  const dbData: any = perpetualInventory;

  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'metrics',
      title: 'Show Metric',
      defaultValue: 'allMetrics',
      sx: {
        minWidth: '150px'
      },
      options: metricsOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewBy',
      title: 'View By',
      defaultValue: 'week',
      sx: {
        minWidth: '150px'
      },
      options: viewByOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeRegion',
      title: 'Store Region',
      defaultValue: 'All Regions',
      placeholder: 'Regions',
      sx: {
        minWidth: '150px'
      },
      options: generateLabelValue(region, 'storeRegion')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'store',
      title: 'Store',
      defaultValue: 'All Stores',
      placeholder: 'Stores',
      sx: {
        minWidth: '150px'
      },
      options: generateLabelValue(store, 'storeName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      sx: {
        minWidth: '150px'
      },
      placeholder: 'Departments',
      defaultValue: 'All Departments',
      allowAllOptions: true,
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentId') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        minWidth: '150px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'classId', 'departmentId')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassId', 'classId')
    }
  ];
  const location: any = useLocation();

  const defaultData = filterTemplate.reduce((acc: any, obj) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);
  const [page, setPage] = React.useState<number>(0);

  /**
   * Db method call when page no change
   * @param pageNo Page No
   */
  const marginDBApiCall = (pageNo: number) => {
    const fromDate =
      pageNo === 0
        ? moment().format(defaultDateFormat)
        : moment()
            .subtract(pageNo * dataLength - 1, filtervalues.viewBy || 'months')
            .format(defaultDateFormat);
    const toDate =
      pageNo === 0
        ? moment(fromDate, defaultDateFormat, true)
            .subtract(dataLength + 1, filtervalues.viewBy || 'months')
            .format(defaultDateFormat)
        : moment(fromDate, defaultDateFormat, true)
            .subtract(dataLength + 2, filtervalues.viewBy || 'months')
            .format(defaultDateFormat);
    dispatch({
      type: perpetualInventorySagaActions.FETCH_PERPETUAL_SAGA
    });
  };
  /**
   * When there is filter change set the page no to 0
   */
  React.useEffect(() => {
    if (page != 0) {
      setPage(0);
    }
    marginDBApiCall(0);
  }, [filtervalues]);
  /**
   * Fetch new previous set of data
   */
  const prevClick = () => {
    let pageNo = page;
    if (page === 1) {
      pageNo = 0;
      setPage(0);
    } else {
      pageNo = page - 1;
      setPage(pageNo < 0 ? 0 : page - 1);
    }
    marginDBApiCall(pageNo);
  };
  /**
   * Fetch new previous set of data
   */
  const nextClick = () => {
    setPage(page + 1);
    marginDBApiCall(page + 1);
  };

  ////// Create YAxis dynamically based on API object
  const yAxis: Array<IAxis> = [
    {
      name: 'inventory',
      displayTitle: 'Inventory'
    },
    {
      name: 'inventoryLY',
      displayTitle: 'LY Inventory'
    },
    {
      name: 'sales',
      displayTitle: 'Sales'
    },
    {
      name: 'shrink',
      displayTitle: 'Shrink'
    }
  ];

  /**
   * OnChange of filter
   * @param values Filter values
   */
  const onFilterApply = (values: IFilterValues) => {
    setFilterValues(values);
  };

  const layout = {
    width: 1170,
    height: 326,
    autosize: false,
    showlegend: false,
    paper_bgcolor: 'rgba(0,0,0,0)', // transparent
    plot_bgcolor: 'rgba(0,0,0,0)', // transparent
    font: {
      size: 12
    },
    xaxis: {
      visible: false,
      range: [0.5, dataLength - 3.5] /// In Margin, we can only view dataLength=13 records only.
    } as unknown as Plotly.LayoutAxis,
    yaxis: {
      showline: false,
      automargin: true
    },
    margin: {
      l: 1,
      r: 2,
      b: 1,
      t: 0,
      pad: 1
    }
  };

  const RenderRow = (props: any) => {
    const {data, property} = props;
    const pageData = data.slice(0, dataLength + 1);

    return (
      <>
        {pageData &&
          pageData.slice(1, pageData.length)?.map((ele: any) => {
            const value = ele[property] as string;
            return (
              <Box
                key={`categoryMargin-${property}-${ele}`}
                sx={{
                  flexGrow: 1,
                  width: 5,
                  textAlign: 'center'
                }}
              >
                {value}
              </Box>
            );
          })}
      </>
    );
  };

  const JumpToDate: React.FC = () => (
    <Box
      sx={{
        alignSelf: 'center',
        borderLeftWidth: '2px',
        borderLeftStyle: 'solid',
        borderLeftColor: theme.palette?.neutral?.secondary10,
        ml: 1,
        pt: 1,
        pl: 3,
        height: '48px'
      }}
    >
      <CustomButton variant={'outlined'} id='outlined-gridFilter-jumpToDate'>
        <Typography
          variant='button'
          sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}
          id={`PIA-gridFilter-jumpToDate`}
        >
          JUMP TO DATE
        </Typography>
      </CustomButton>
    </Box>
  );

  if (dbData && dbData.length > 0 && !error) {
    return (
      <>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <GridFilter
              values={filtervalues}
              filterTemplate={filterTemplate}
              onFilterChange={onFilterApply}
              hideMoreFilter={true}
              id='report-category-supplier-margin'
              onReset={() => {
                setFilterValues(defaultData);
              }}
              customButton={<JumpToDate />}
            />
          </Grid>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <CustomLoader id='product-margin-load' />
            </Box>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={(theme) => ({
                lineHeight: theme.spacing(7),
                backgroundColor: theme.palette?.primary?.contrastText
              })}
            >
              <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'}>
                <Box
                  sx={{
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 2
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{fontFamily: 'Roboto Medium', color: 'grayscale.600'}}
                  >
                    AVG %Variance:{' '}
                  </Typography>
                  <Typography variant='body1'>14.1%</Typography>
                </Box>
                <Box
                  sx={{
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 2
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{fontFamily: 'Roboto Medium', color: 'grayscale.600'}}
                  >
                    AVG Inventory:{' '}
                  </Typography>
                  <Typography variant='body1'>$250,000</Typography>
                </Box>
                <Box
                  sx={{
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 2
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{fontFamily: 'Roboto Medium', color: 'grayscale.600'}}
                  >
                    AVG Last Year:{' '}
                  </Typography>
                  <Typography variant='body1'>$250,000</Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper sx={{borderRadius: 0}}>
              <PerpetualLineGraphComponent
                data={dbData.slice(0, page === 0 ? dataLength + 1 : dataLength + 2)}
                xAxis={xAxis}
                layout={layout}
                yAxis={[...yAxis]}
                id='perpetualInventory'
                prevClick={prevClick}
                pageNo={page}
                nextClick={nextClick}
                size={'large'}
              />
            </Paper>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              overflow: 'hidden'
            }}
            xs={12}
            md={12}
            lg={12}
          >
            <Paper
              sx={{
                width: '100%',
                display: 'flex',
                borderRightColor: '#CBCFD8',
                borderRightWidth: '2px',
                borderRadius: 0
              }}
            >
              <TableContainer component={Paper}>
                <Table
                  sx={{boxShadow: 'inset -10px 0px 10px -5px rgb(0 0 0 / 10%)', borderRadius: 0}}
                >
                  <TableBody>
                    <TableRow
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <LabelTableCell>INVENTORY</LabelTableCell>
                      <ValueTableCell>
                        <RenderRow
                          data={dbData}
                          property={'inventory'}
                          id='totalPerpetualAvgInventory'
                        />
                      </ValueTableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <LabelTableCell>%VARIANCE VS LW</LabelTableCell>
                      <ValueTableCell>
                        <RenderRow data={dbData} property={'varianceLW'} id='varianceVsLw' />
                      </ValueTableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <LabelTableCell>LY INVENTORY</LabelTableCell>
                      <ValueTableCell>
                        <RenderRow data={dbData} property={'inventoryLY'} id='lyInventory' />
                      </ValueTableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <LabelTableCell>SALES</LabelTableCell>
                      <ValueTableCell>
                        <RenderRow data={dbData} property={'sales'} id='perpetualAvgSales' />
                      </ValueTableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <LabelTableCell>SHRINK</LabelTableCell>
                      <ValueTableCell>
                        <RenderRow data={dbData} property={'shrink'} id='perpetualAvgShrink' />
                      </ValueTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Paper
                sx={{
                  width: '246px',
                  borderRightColor: '#CBCFD8',
                  borderRightWidth: '2px',
                  borderRadius: 0
                }}
              ></Paper>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <GridFilter
            values={filtervalues}
            filterTemplate={filterTemplate}
            onFilterChange={onFilterApply}
            id='report-category-supplier-margin'
            onReset={() => {
              setFilterValues(defaultData);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              maxHeight: '844px'
            }}
          >
            {' '}
            {loading ? (
              <CustomLoader id='report-perpetual-inventory-load' />
            ) : (
              <CustomError id='report-perpetual-inventory-load' />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
};

export default PerpetualInventoryGraph;
