import {takeEvery, put, call} from 'redux-saga/effects';
import {VendorAPIS} from 'redux/vendor/vendorDetails_service';
import {
  vendorDetailsFailure,
  vendorDetailsLoading,
  vendorDetailsSuccess
} from 'redux/vendor/details/vendorDetailsSlice';

export const vendorDetailsSagaActions = {
  FETCH_VENDORDETAILS_DATA: 'FETCH_VENDORDETAILS_DATA'
};

export function* fetchVendorDetailsData(action: any): any {
  try {
    yield put(vendorDetailsLoading());
    const {data} = yield call(VendorAPIS.getVendorDetailsData, action.payload);
    yield put(vendorDetailsSuccess(data));
  } catch (error) {
    yield put(vendorDetailsFailure());
  }
}

export default function* watchFetchVendorDetailsData() {
  yield takeEvery(vendorDetailsSagaActions.FETCH_VENDORDETAILS_DATA, fetchVendorDetailsData);
}
