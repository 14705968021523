import {Box} from '@mui/material';
import React, {Fragment} from 'react';
import VendorList from 'features/vendor/vendorList';

const Vendor: React.FC = () => {
  return (
    <Fragment>
      <Box sx={{ml: 3, mr: 3, pb: 3}}>
        <VendorList />
      </Box>
    </Fragment>
  );
};

export default Vendor;
