import {createSlice} from '@reduxjs/toolkit';
export interface inventoryBalanceState {
  loading: {[key: string]: boolean};
  inventoryBalance: {[key: string]: Array<any>};
  inventoryBalanceRegion: {[key: string]: Array<any>};
  error: string;
}

const initialState: inventoryBalanceState = {
  loading: {},
  inventoryBalance: {},
  inventoryBalanceRegion: {},
  error: ''
};

const inventoryBalanceSlice = createSlice({
  name: 'inventoryBalance',
  initialState,
  reducers: {
    inventoryBalanceLoading: (state, action) => {
      state.loading[action.payload.type] = true;
    },
    inventoryBalanceSuccess: (state, action) => {
      state.inventoryBalance[action.payload.type] = action.payload.result;
      state.loading[action.payload.type] = false;
    },
    inventoryBalanceFailure: (state, action) => {
      state.loading[action.payload.type] = false;
      state.inventoryBalance[action.payload.type] = [];
      state.error = 'Request failed with status code 404';
    },
    inventoryBalanceRegionSuccess: (state, action) => {
      state.inventoryBalanceRegion[action.payload.regionId] = action.payload.result;
    }
  }
});

export default inventoryBalanceSlice.reducer;
export const {
  inventoryBalanceLoading,
  inventoryBalanceSuccess,
  inventoryBalanceFailure,
  inventoryBalanceRegionSuccess
} = inventoryBalanceSlice.actions;
