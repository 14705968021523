import {TableHead, TableRow, Typography, Box, Stack, Tooltip} from '@mui/material';
import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import StyledChip from 'components/baseComponents/customChip';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {productSalesListSagaActions} from 'redux/product/list/salesList/productSalesListSaga';
import {toFlatPropertyMap} from 'util/objectHelper';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {tagSagaActions} from 'redux/dashboard/master/tagSaga';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import {districtSagaActions} from 'redux/dashboard/master/districtSaga';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {sizeSagaActions} from 'redux/dashboard/master/sizeSaga';
import {packSagaActions} from 'redux/dashboard/master/packSaga';
import {alertSagaActions} from 'redux/dashboard/master/alertSaga';
import moment from 'moment';
import {vendorSagaActions} from 'redux/dashboard/master/vendorSaga';
import {useTheme} from '@mui/material';
interface IPropType {
  atpEnabler?: (val: boolean) => void;
}
interface IProductSalesGridProps extends IProductSalesData {
  fcstSales: {unitsFS: string; dollarsFS: string};
  totalSales: {unitsTS: string; dollarsTS: string};
}
interface IProductSalesData {
  itemId: string;
  itemName: string;
  pack: string;
  size: string;
  avgCost: string;
  avgRtl: string;
  aws: string;
  actMng: string;
  avgMng: string;
  stores: string;
  hasAlert?: boolean;
  tags?: Array<string>;
  alert?: Array<string>;
}
interface IProductSalesGrid extends IProductSalesData {
  'fcstSales.unitsFS': string;
  'fcstSales.dollarsFS': string;
  'totalSales.unitsTS': string;
  'totalSales.dollarsTS': string;
  id: string;
}
interface IStaticGridRows {
  data: IProductSalesData[];
  salesList: IProductSalesGrid[];
}
//This Link will use to navigate on another Page
const LinkComponent = (row: any) => {
  return (
    <>
      <Link to={{pathname: `/Product/${row.itemId}`}} state={row}>
        {row.itemId}
      </Link>{' '}
    </>
  );
};

const ContentComponent = (row: any) => {
  if (row.itemName.length > 20)
    return (
      <Tooltip title={row.itemName} placement='top'>
        <Box
          sx={{
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box'
          }}
        >
          {row.itemName}
        </Box>
      </Tooltip>
    );
  else {
    return <Box sx={{maxWidth: '100px'}}>{row.itemName}</Box>;
  }
};
const columnCell: IColumnCell<IProductSalesGrid>[] = [
  {
    id: 'itemId',
    name: 'itemId',
    displayName: 'Item Id',
    customCell: LinkComponent,
    headerTextAlign: 'center',
    sticky: true,
    sx: {
      minWidth: '80px'
    }
  },
  {
    id: 'itemName',
    name: 'itemName',
    displayName: 'Item',
    sticky: true,
    hasTags: true,
    customCell: ContentComponent,
    headerTextAlign: 'left',
    sx: {
      minWidth: '200px'
    }
  },
  {
    id: 'pack',
    name: 'pack',
    isColumnSortable: false,
    sticky: true,
    headerTextAlign: 'left',
    cellTextAlign: 'right',
    sx: {
      minWidth: '50px'
    }
  },
  {
    id: 'size',
    name: 'size',
    isNumeric: true,
    isGroupBorderRequire: true,
    isColumnSortable: false,
    sticky: true,
    headerTextAlign: 'right',
    cellTextAlign: 'right',
    sx: {
      minWidth: '60px'
    }
  },
  {
    id: 'avgCost',
    name: 'avgCost',
    displayName: 'AVG COST',
    isNumeric: true,
    prefixText: '$',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'avgRtl',
    name: 'avgRtl',
    displayName: 'AVG RTL',
    isNumeric: true,
    prefixText: '$',
    isGroupBorderRequire: true,
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'aws',
    name: 'aws',
    displayName: 'aws',
    isNumeric: true,
    prefixText: '$',
    isGroupBorderRequire: true,
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'fcstSales.unitsFS',
    name: 'fcstSales',
    displayName: 'fcst Sales',
    isGroupBorderRequire: true,
    isNumeric: true,
    isGroupedCell: true,
    groupedKeys: ['fcstSales.unitsFS', 'fcstSales.dollarsFS'],
    groupSortKey: 'fcstSales.dollarsFS',
    groupDisplayKeys: ['units', 'dollars'],
    consolidateType: 'SUM',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'totalSales.unitsTS',
    name: 'totalSales',
    displayName: 'total Sales',
    isGroupBorderRequire: true,
    isNumeric: true,
    isGroupedCell: true,
    groupedKeys: ['totalSales.unitsTS', 'totalSales.dollarsTS'],
    groupSortKey: 'totalSales.dollarsTS',
    groupDisplayKeys: ['units', 'dollars'],
    consolidateType: 'SUM',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'actMng',
    name: 'actMng',
    displayName: 'Act MGN',
    isNumeric: true,
    prefixText: '$',
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'avgMng',
    name: 'avgMng',
    displayName: 'AVG MGN',
    isNumeric: true,
    suffixText: '%',
    isGroupBorderRequire: true,
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'stores',
    name: 'stores',
    displayName: 'stores',
    isNumeric: true,
    isGroupBorderRequire: true,
    consolidateType: 'AVG',
    sx: {
      textAlign: 'right'
    }
  }
];
//const rowData: IProductSalesGridProps[] = data;

const SalesList: React.FC<IPropType> = (props) => {
  //For Filter Parts
  const location: any = useLocation();
  const theme: any = useTheme();
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const {productSalesList, loading, error} = useAppSelector((state) => state.productSalesListSlice);

  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: tagSagaActions.FETCH_TAG_DATA
    });
    dispatch({
      type: vendorSagaActions.FETCH_VENDOR_DATA
    });
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: sizeSagaActions.FETCH_SIZE_DATA
    });
    dispatch({
      type: packSagaActions.FETCH_PACK_DATA
    });
    dispatch({
      type: alertSagaActions.FETCH_ALERT_DATA
    });
  }, []);

  const {master} = useAppSelector((state) => state.masterSlice);
  const [dataOne, dataTwo, dataThree] = master;
  const {vendor} = useAppSelector((state) => state.vendorSlice);
  const {district} = useAppSelector((state) => state.districtSlice);
  const {store} = useAppSelector((state) => state.storeSlice);
  const {size} = useAppSelector((state) => state.sizeSlice);
  const {pack} = useAppSelector((state) => state.packSlice);
  const {region} = useAppSelector((state) => state.regionSlice);
  const {alert} = useAppSelector((state) => state.alertSlice);

  const {tag} = useAppSelector((state) => state.tagSlice);
  //For Filter change and generate Payload data
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      temp.push({key: key, value: value, condition: 'equal'});
    }
    return temp;
  };
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };
  const defaultDateFormat = 'MM/DD/YYYY';
  const startDate = moment().startOf('week').format(defaultDateFormat);
  const endDate = moment().format(defaultDateFormat);

  const StickyHeaderCell = (props: any) => {
    const consolidatedData = generateStickyHeaderValues(props.data, props.columnCell);
    const aws = Number(consolidatedData?.aws?.slice(1));
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'Background',
                zIndex: theme.zIndex?.appBar + 20,
                top: '64px'
              }}
            >
              <TableStickyCell align='right' sx={{top: '64px'}}></TableStickyCell>
              <TableStickyCell colSpan={4}>
                <Typography
                  variant='subtitle2'
                  sx={{
                    height: '20px',
                    width: '496px',
                    color: theme.palette?.grayscale?.[400],
                    fontFamily: 'Roboto Medium',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '0.2px',
                    paddingLeft: '8px'
                  }}
                >
                  CONSOLIDATED STATS
                </Typography>
              </TableStickyCell>
            </BorderedTableStickyCell>
            <TableStickyCell colSpan={1} sx={{top: '64px'}} align='right'>
              <Typography variant='subtitle2'>-</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell colSpan={1} sx={{top: '64px'}} align='right'>
              <Typography variant='subtitle2'>-</Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell colSpan={1} sx={{top: '64px'}} align='right'>
              <Typography variant='subtitle2'>{aws.toFixed(1)}</Typography>
            </BorderedTableStickyCell>
            <TableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>-</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>
                ${consolidatedData['fcstSales.dollarsFS']}
              </Typography>
            </BorderedTableStickyCell>
            <TableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>-</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>
                ${consolidatedData['totalSales.dollarsTS']}
              </Typography>
            </BorderedTableStickyCell>
            <TableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>{consolidatedData.actMng}</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>{consolidatedData.avgMng}</Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>{consolidatedData?.stores}</Typography>
            </BorderedTableStickyCell>
          </TableRow>
        </TableHead>
      </>
    );
  };

  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date',
      format: defaultDateFormat,
      defaultValue: `${startDate}|${endDate}`,
      sx: {
        width: '210px'
      }
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      defaultValue: 'All Departments',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'tag',
      title: 'tag',
      defaultValue: 'All Tags',
      placeholder: 'Tags',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(tag, 'tag')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'vendor',
      title: 'Vendor',
      defaultValue: 'All Vendors',
      placeholder: 'Vendors',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(vendor, 'vendorName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'alert',
      title: 'Alert',
      defaultValue: 'All Alerts',
      placeholder: 'Alerts',
      sx: {
        width: '170px'
      },
      options: generateLabelValue(alert, 'alert')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'pack',
      title: 'Pack',
      defaultValue: 'All Pack',
      placeholder: 'Pack',
      sx: {
        width: '170'
      },
      mode: 'Secondary',
      options: generateLabelValue(pack, 'pack')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'size',
      title: 'Size',
      defaultValue: 'All Size',
      placeholder: 'Size',
      sx: {
        width: '170'
      },
      mode: 'Secondary',
      options: generateLabelValue(size, 'size')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeRegion',
      title: 'Store Region',
      defaultValue: 'All Regions',
      placeholder: 'Regions',
      mode: 'Secondary',
      sx: {
        width: '170'
      },
      options: generateLabelValue(region, 'storeRegion')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeDistrict',
      title: 'Store District',
      defaultValue: 'All Districts',
      placeholder: 'Districts',
      mode: 'Secondary',
      sx: {
        width: '170'
      },
      options: generateLabelValue(district, 'storeDistrict')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'store',
      title: 'Store',
      defaultValue: 'All Stores',
      placeholder: 'Stores',
      mode: 'Secondary',
      sx: {
        width: '170'
      },
      options: generateLabelValue(store, 'store')
    }
  ];

  const defaultData = filterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});
  const [filtervalues, setFilterValues] = useState(defaultData);
  /**
   * dispatch productSalesList data
   */
  const dispatchData = () => {
    dispatch({
      type: productSalesListSagaActions.FETCH_PRODUCTSALESLIST_DATA,
      payload: generatePayload(filtervalues)
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);
  const formateRowData = (listData: IProductSalesGridProps[]) => {
    const gridData: IProductSalesGrid[] = listData.map((element) => {
      const nestedObject: IProductSalesGrid = toFlatPropertyMap(
        element
      ) as unknown as IProductSalesGrid;
      nestedObject['fcstSales.dollarsFS'] = `$${nestedObject['fcstSales.dollarsFS']}`;
      nestedObject['totalSales.dollarsTS'] = `$${nestedObject['totalSales.dollarsTS']}`;
      nestedObject['tags'] = element?.tags;
      nestedObject['alert'] = element?.alert;
      return nestedObject;
    });
    return gridData;
  };
  const rowData: IProductSalesGrid[] =
    productSalesList.length > 0 ? formateRowData(productSalesList) : [];

  const getRowIds = (ids: readonly string[]) => {
    //type should be same as selected state in enhanced table
    props.atpEnabler && props.atpEnabler(!(ids.length > 0));
  };
  if (productSalesList && productSalesList.length > 0 && !error) {
    return (
      <>
        <GridFilter
          id='product-salesList'
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          onReset={() => {
            setFilterValues(defaultData);
          }}
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='product-sales-load' />
          </Box>
        )}
        <EnhancedTable<IProductSalesGrid>
          height={844}
          rowData={rowData}
          stickyRows={<StickyHeaderCell data={rowData} columnCell={columnCell} />}
          columnCell={columnCell}
          rowKey={'itemId'}
          defaultSortKey={'itemId'}
          showTags={true}
          componentType='paper'
          allowAlternateColor={true}
          getSelectedIds={getRowIds}
          rowsPerPage={25}
          selectable
          showAlertPopup
          id='productList-salesList'
        />
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          id='product-salesList'
          onReset={() => {
            setFilterValues(defaultData);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='product-sales-load' />
          ) : (
            <CustomError
              id='product-sales-load'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      </>
    );
  }
};

export default SalesList;
