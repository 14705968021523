import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from '../dashboard_service';
import {
  receivingTileFailure,
  receivingTileSuccess,
  receivingTileLoading
} from './receivingTileSlice';

export const receivingTileSagaActions = {
  FETCH_RECEIVINGTILE_DATA: 'FETCH_RECEIVINGTILE_DATA'
};

export function* fetchReceivingTileData(action: any): any {
  try {
    yield put(receivingTileLoading({type: action.payload}));
    const {data} = yield call(DashboardServiceAPIS.getReceivingTileData, action.payload);
    yield put(receivingTileSuccess({result: data, type: action.payload.type}));
  } catch (error) {
    yield put(receivingTileFailure(action));
  }
}

export default function* watchFetchReceivingTileData() {
  yield takeEvery(receivingTileSagaActions.FETCH_RECEIVINGTILE_DATA, fetchReceivingTileData);
}
