import React from 'react';
import Typography from '@mui/material/Typography';
import {CustomCard} from 'components/baseComponents/customCard';
import {styled} from '@mui/system';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {storeAlertActions} from 'redux/dashboard/storeAlert/storeAlertSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {Box, useTheme} from '@mui/material';
import {generatePayload} from 'util/arrayOperations';
import {StoreAlertTicketingSystemUrl} from 'config/constants';

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root > div': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
});
interface storeAlertProps {
  status?: string;
}

interface StoreAlertStatusItem {
  ticketID: string;
  catagory: string;
  status: string;
}
interface IPropType {
  filteredData: any;
}

const StoreAlertStatus: React.FC<IPropType> = (props) => {
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  /**
   * dispatch storeAlert Data
   */
  const dispatchData = () => {
    dispatch({
      type: storeAlertActions.FETCH_STOREALERT_SAGA,
      payload: {
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [props?.filteredData]);

  const {storeAlert, loading, error} = useAppSelector((state: any) => state.storeAlertSlice);

  const StoreAlertStatusWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'status',
    name: 'AlertWrapper',
    slot: 'Root'
  })<storeAlertProps>(({status}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grayscale?.[300]}`,
    height: 60,
    '& p': {
      fontSize: 14,
      borderRadius: 2,
      maxHeight: 24,
      color: theme.palette.primary.main,
      '&:last-child': {
        padding: '2px 8px',
        backgroundColor:
          status === 'Resolved'
            ? theme.palette.primary.contrastText
            : theme.palette.secondary.light,
        color: status === 'Resolved' ? theme.palette.grayscale?.[600] : theme.palette.error.main,
        fontWeight: 'bold'
      }
    }
  }));

  const StoreAlertStatusItem: React.FC<StoreAlertStatusItem> = (props) => {
    return (
      <StoreAlertStatusWrapper>
        <p>
          <Typography
            variant='subtitle2'
            textAlign='center'
            sx={{fontWeight: 500, letterSpacing: '0.25px'}}
          >
            <a
              target='_blank'
              href={`${StoreAlertTicketingSystemUrl}?${props?.ticketID}`}
              rel='noreferrer'
            >
              #{props?.ticketID}.{props?.catagory}
            </a>
          </Typography>
        </p>
        <p>
          <Typography variant='body2' textAlign='center' sx={{fontFamily: 'Roboto Medium'}}>
            {props?.status}
          </Typography>
        </p>
      </StoreAlertStatusWrapper>
    );
  };

  const AlertDropDownWrapper = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    '& span': {
      padding: '2px 5px',
      borderRadius: 3,
      maxHeight: 24,
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.error.main,
      '& p': {
        fontWeight: 500
      }
    }
  });

  const Header = () => {
    return (
      <AlertDropDownWrapper sx={{paddingRight: '16px'}}>
        <Typography sx={{color: 'common.black'}} variant='subtitle2' textAlign='center'>
          Store Alert Status
        </Typography>
      </AlertDropDownWrapper>
    );
  };

  const Content = () => {
    return (
      <>
        {storeAlert.map((item: any, index: number) => {
          return (
            <StoreAlertStatusItem
              key={`${item}-${index}`}
              status={item.ticketStatus}
              ticketID={item.ticketID}
              catagory={item.ticketCategory}
            />
          );
        })}
      </>
    );
  };

  if (storeAlert && storeAlert.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='storeAlertStatusTile'
          header={<Header />}
          content={loading ? <CustomLoader id='storeAlert-load' /> : <Content />}
          headerSx={{
            height: '32px'
          }}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='storeAlertStatusTile-Id'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='storeAlert-load' />
            ) : (
              <CustomError
                id='storeAlert-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
          headerSx={{
            height: '32px'
          }}
        />
      </CustomBox>
    );
  }
};

export default StoreAlertStatus;
