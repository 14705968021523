export const DashBoardEndPoints = {
  sales: `${process.env.REACT_APP_DASHBOARD_URL}/compsales`,
  margin: `${process.env.REACT_APP_DASHBOARD_URL}/margin`,
  inventory: `${process.env.REACT_APP_DASHBOARD_URL}/inventory`,
  poAlert: `${process.env.REACT_APP_DASHBOARD_URL}/poalerts`,
  urgentStore: `${process.env.REACT_APP_DASHBOARD_URL}/urgentstore`,
  classOTB: `${process.env.REACT_APP_DASHBOARD_URL}/otb`,
  remainder: `${process.env.REACT_APP_DASHBOARD_URL}/reminders`,
  receivingTile: `${process.env.REACT_APP_DASHBOARD_URL}/receiving`,
  cycleCount: `${process.env.REACT_APP_DASHBOARD_URL}/cyclecountvariance`,
  topWeeklyStore: `${process.env.REACT_APP_DASHBOARD_URL}/topweeklysales`,
  classPerformance: `${process.env.REACT_APP_DASHBOARD_URL}/classperformance`,
  newPO: `${process.env.REACT_APP_DASHBOARD_URL}/po`,
  storeAlert: `${process.env.REACT_APP_DASHBOARD_URL}/store`,
  topVendorTile: `${process.env.REACT_APP_DASHBOARD_URL}/topvendor`,
  adPromosTile: `${process.env.REACT_APP_DASHBOARD_URL}/ads`,
  itemAlertTile: `${process.env.REACT_APP_DASHBOARD_URL}/item`,
  laborTile: `${process.env.REACT_APP_DASHBOARD_URL}/labor`,
  shrinkTile: `${process.env.REACT_APP_DASHBOARD_URL}/shrink`,
  getProfile: `${process.env.REACT_APP_DASHBOARD_URL}/profile`,
  topTwentyVendors: `${process.env.REACT_APP_DASHBOARD_URL}/top20vendor`,
  agedDetails: `${process.env.REACT_APP_DASHBOARD_URL}/aged`,
  expiryDetails: `${process.env.REACT_APP_DASHBOARD_URL}/expiry`,
  notification: `${process.env.REACT_APP_DASHBOARD_URL}/globalnotification`
};

export const ProductEndPoints = {
  masterDepartment: `${process.env.REACT_APP_PRODUCT_URL}/department`,
  masterClass: `${process.env.REACT_APP_PRODUCT_URL}/class`,
  masterSubClass: `${process.env.REACT_APP_PRODUCT_URL}/subclass`,
  masterTag: `${process.env.REACT_APP_PRODUCT_URL}/tag`,
  masterDistrict: `${process.env.REACT_APP_PRODUCT_URL}/district`,
  masterChannel: `${process.env.REACT_APP_PRODUCT_URL}/channel`,
  masterStore: `${process.env.REACT_APP_PRODUCT_URL}/store`,
  masterSize: `${process.env.REACT_APP_PRODUCT_URL}/size`,
  masterPack: `${process.env.REACT_APP_PRODUCT_URL}/pack`,
  masterBuyer: `${process.env.REACT_APP_PRODUCT_URL}/buyer`,
  masterRegion: `${process.env.REACT_APP_PRODUCT_URL}/region`,
  masterDistrubutionCenter: `${process.env.REACT_APP_PRODUCT_URL}/distributioncenter`,
  productList: `${process.env.REACT_APP_PRODUCT_URL}/productlist`,
  productSearch: `${process.env.REACT_APP_PRODUCT_URL}/product-search`,
  productStoreList: `${process.env.REACT_APP_PRODUCT_URL}/storelist`,
  masterAlert: `${process.env.REACT_APP_PRODUCT_URL}/alert`,
  masterVendor: `${process.env.REACT_APP_PRODUCT_URL}/vendor`,
  masterWarehouse: `${process.env.REACT_APP_PRODUCT_URL}/warehouse`,
  productDetails: `${process.env.REACT_APP_PRODUCT_URL}/productdetails`,
  warehouseList: `${process.env.REACT_APP_PRODUCT_URL}/warehouselist`,
  salesItem: `${process.env.REACT_APP_PRODUCT_URL}/saledetails`,
  storeItem: `${process.env.REACT_APP_PRODUCT_URL}/storedetails`,
  warehouseItem: `${process.env.REACT_APP_PRODUCT_URL}/warehousedetails`,
  productSalesList: `${process.env.REACT_APP_PRODUCT_URL}/saleslist`,
  expiryPoductList: `${process.env.REACT_APP_PRODUCT_URL}/expirylist`,
  productDemandForeCastItem: `${process.env.REACT_APP_PRODUCT_URL}/demandforecast`,
  productDemandForeCastIngItem: `${process.env.REACT_APP_PRODUCT_URL}/demandforecast`,
  productDemandForeCastIngItemSales: `${process.env.REACT_APP_SALES_URL}/getdata`,
  orderList: `${process.env.REACT_APP_PRODUCT_URL}/onorderdetails`,
  retailZone: `${process.env.REACT_APP_PRODUCT_URL}/retailzone`,
  purchaseOrderType: `${process.env.REACT_APP_PRODUCT_URL}/potype`,
  supplierContact: `${process.env.REACT_APP_PRODUCT_URL}/suppliercontact`,
  freightTerm: `${process.env.REACT_APP_PRODUCT_URL}/freightterms`,
  loadCondition: `${process.env.REACT_APP_PRODUCT_URL}/loadcondition`,
  unloadCondition: `${process.env.REACT_APP_PRODUCT_URL}/`,
  deliveryCondition: `${process.env.REACT_APP_PRODUCT_URL}/deliverycondition`
};

export const VendorEndPoints = {
  vendorDetails: `${process.env.REACT_APP_VENDOR_URL}/vendordetails`,
  vendorList: `${process.env.REACT_APP_VENDOR_URL}/vendorlist`,
  vendorSalesItem: `${process.env.REACT_APP_VENDOR_URL}/sales`,
  productItem: `${process.env.REACT_APP_VENDOR_URL}/product`,
  supplyChain: `${process.env.REACT_APP_VENDOR_URL}/supply`
};

export const ReportEndPoints = {
  receivedPoItem: `${process.env.REACT_APP_REPORT_URL}/inventoryreceived`,
  demandForecast: `${process.env.REACT_APP_REPORT_URL}/demandforecast`,
  warehouseLiabilityReport: `${process.env.REACT_APP_REPORT_URL}/warehouseliability`,
  inventoryBalance: `${process.env.REACT_APP_REPORT_URL}/inventorybalance`,
  categorysupplier: `${process.env.REACT_APP_REPORT_URL}/categorysupplier`,
  topTenItems: `${process.env.REACT_APP_REPORT_URL}/categorysupplier/hover`,
  perpetualInventory: `${process.env.REACT_APP_REPORT_URL}/prepetualinventory`
};

export const PurchaseOrderEndPoints = {
  poItems: `${process.env.REACT_APP_PO_URL}/items`,
  generalInfo: `${process.env.REACT_APP_PO_URL}/generalinfo`,
  poList: `${process.env.REACT_APP_PO_URL}/polist`,
  createPO: `${process.env.REACT_APP_PO_URL}/addpo`,
  updatePO: `${process.env.REACT_APP_PO_URL}/updatepo`,
  freightSummary: `${process.env.REACT_APP_PO_URL}/freightsummary`,
  supplierContact: `${process.env.REACT_APP_PO_URL}/suppliercontact`,
  poNotesAttachment: `${process.env.REACT_APP_PO_URL}/attachment`
};
