import {TableHead, TableRow, Typography, Box, Stack, Tooltip, useTheme} from '@mui/material';
import CustomButton from 'components/baseComponents/customButton';
import React, {useState} from 'react';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import StyledChip from 'components/baseComponents/customChip';
import {BreadcrumbNav as DemandForecastBreadcrumbNav} from 'features/report/reportBreadCrumbs';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {demandForecastSagaActions} from 'redux/report/sales/demandForecast/demandForecastSaga';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {tagSagaActions} from 'redux/dashboard/master/tagSaga';
import {generateLabelValue} from 'util/arrayOperations';
import ExportModel from 'components/baseComponents/exportOption';
import {viewInFilterOptions} from 'config/constants';
import {districtSagaActions} from 'redux/dashboard/master/districtSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {distrubutionCenterSagaActions} from 'redux/dashboard/master/distrubutionCenterSaga';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';
import {data} from '../../login/authRoles';
import {useLocation} from 'react-router-dom';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
interface IPropType {
  atpEnabler?: (val: boolean) => void;
}
//Here For Frontend side we are using three interface because data is coming in nested formate.
interface IDemandForecast {
  date: string;
  value: string;
}
interface ISalesDemandForecast {
  itemId: string;
  itemName: string;
  pack: string;
  size: string;
  [key: string]: any;
  hasAlert?: boolean;
  tags?: Array<string>;
  id: string;
}
interface IReportSalesDemandForecast {
  itemId: string;
  itemName: string;
  pack: string;
  size: string;
  demandForecast: [IDemandForecast];
  hasAlert?: boolean;
  tags?: Array<string>;
  id: string;
}
interface IDemandListFilterValues {
  viewIn: string;
  viewBy: string;
  subset: string;
  departmentName: string;
  className: string;
  subClassName: string;
  tag: string;
}
const StickyDemandHeaderCell = (props: any) => {
  if (props.data.length > 0) {
    const keys = Object.keys(props.data[0]);
    const dateKeys = keys.filter(
      (e) => e != 'itemId' && e != 'itemName' && e != 'pack' && e != 'size'
    );
    const aggregateDict = dateKeys.map((prop: any) => {
      const handTotal = props.data.reduce(
        (accumulator: any, c: any) => accumulator + Number(c[prop]),
        0
      );
      return {key: prop, value: handTotal};
    });
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'Background',
                zIndex: (theme) => theme.zIndex.appBar + 1,
                paddingRight: 0
              }}
            >
              <Typography variant='subtitle2' sx={{color: 'grayscale.400', borderBottom: 0}}>
                CONSOLIDATED STATS
              </Typography>
            </BorderedTableStickyCell>
            {aggregateDict.map((ele, index) => {
              return (
                <TableStickyCell key={`${ele}-${index}`} align='right'>
                  <Typography variant='subtitle2'>{ele.value}</Typography>
                </TableStickyCell>
              );
            })}
          </TableRow>
        </TableHead>
      </>
    );
  } else {
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              colSpan={2}
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'Background',
                zIndex: (theme) => theme.zIndex.appBar + 1,
                paddingRight: 0
              }}
            >
              <Typography variant='subtitle2' sx={{color: 'grayscale.400', borderBottom: 0}}>
                CONSOLIDATED STATS
              </Typography>
            </BorderedTableStickyCell>
          </TableRow>
        </TableHead>
      </>
    );
  }
};
//For item name with not more than 30 character/Ellipsis
const CustomComponent = (row: any) => {
  if (row?.itemName?.length > 30)
    return (
      <Tooltip title={row.itemName} placement='top'>
        <Box
          sx={{
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {row.itemName}
        </Box>
      </Tooltip>
    );
  else {
    return <Box sx={{maxWidth: '100px'}}>{row.itemName}</Box>;
  }
};

//Below is Main components
const DemandForecast: React.FC<IPropType> = () => {
  const location: any = useLocation();
  const [showExpModal, setShowExpModal] = useState(false);
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const {demandForecast, loading} = useAppSelector((state) => state.demandForecastSlice);

  //For Binding the MasterData
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: tagSagaActions.FETCH_TAG_DATA
    });
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: distrubutionCenterSagaActions.FETCH_DISTRUBUTION_CENTER_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: distrubutionCenterSagaActions.FETCH_DISTRUBUTION_CENTER_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
  }, []);
  const {master} = useAppSelector((state) => state.masterSlice);
  const [dataOne, dataTwo, dataThree] = master;
  const {tag} = useAppSelector((state) => state.tagSlice);
  const {district} = useAppSelector((state) => state.districtSlice);
  const {region} = useAppSelector((state) => state.regionSlice);
  const {distrubutionCenter} = useAppSelector((state) => state.distrubutionCenterSlice);
  const {store} = useAppSelector((state) => state.storeSlice);

  const updateSubset = (newFilterTemplate: IFilterTemplate[]) => {
    const updatedFilterTemplate: IFilterTemplate[] = newFilterTemplate.map(
      (ele: IFilterTemplate) => {
        if (ele.property === 'subset') {
          if (filtervalues?.viewBy === 'District') {
            return {
              ...ele,
              options: generateLabelValue(district, 'districtName'),
              placeholder: 'Districts',
              defaultValue: 'All Districts'
            } as IFilterTemplate;
          } else if (filtervalues?.viewBy === 'Region') {
            return {
              ...ele,
              options: generateLabelValue(region, 'regionName'),
              placeholder: 'storeRegion',
              defaultValue: 'All Regions'
            } as IFilterTemplate;
          } else if (filtervalues?.viewBy === 'Distribution Center') {
            return {
              ...ele,
              options: generateLabelValue(distrubutionCenter, 'distributionCenterName'),
              placeholder: 'Distribution Center',
              defaultValue: 'All Distribution Center'
            } as IFilterTemplate;
          } else if (filtervalues?.viewBy === 'Store') {
            return {
              ...ele,
              options: generateLabelValue(store, 'storeName'),
              placeholder: 'Stores',
              defaultValue: 'Store'
            } as IFilterTemplate;
          }
          return {...ele} as IFilterTemplate;
        } else {
          return {...ele} as IFilterTemplate;
        }
      }
    );
    return updatedFilterTemplate || [];
  };
  const formateRowData = (listData: IReportSalesDemandForecast[]) => {
    const gridData: ISalesDemandForecast[] = listData.map((ele: IReportSalesDemandForecast) => {
      const reportItem: ISalesDemandForecast = {...ele};
      const demandForecastItem: IDemandForecast[] = ele.demandForecast;
      demandForecastItem.forEach((item: IDemandForecast) => {
        reportItem[item.date] = item.value;
      });

      delete reportItem.demandForecast;
      return reportItem;
    });

    return gridData;
  };
  const rowData: ISalesDemandForecast[] =
    demandForecast.length > 0 ? formateRowData(demandForecast) : [];
  //For Filter change and generate Payload data
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      temp.push({key: key, value: value, condition: 'equal'});
    }
    return temp;
  };
  const onFilterApply = (values: IFilterValues) => {
    const filterValues = values as unknown as IDemandListFilterValues;
    const demandFilter = {
      viewin: filterValues.viewIn,
      departmentName: filterValues.departmentName,
      className: filterValues.className,
      subClassName: filtervalues?.subClassName,
      tag: filterValues.tag,
      [filterValues.viewBy]: filterValues.subset
    };
    generatePayload(demandFilter);
    setFilterValues(values as unknown as IDemandListFilterValues);
  };
  //handle-click function
  const handleClick = () => {
    setShowExpModal(true);
  };
  const columnCell: IColumnCell<ISalesDemandForecast>[] = [
    {
      id: 'itemId',
      name: 'itemId',
      displayName: 'ITEM ID',
      sticky: true,
      sx: {
        width: '150px'
      }
    },
    {
      id: 'itemName',
      name: 'itemName',
      displayName: 'ITEM',
      sticky: true,
      hasTags: true,
      customCell: CustomComponent,
      sx: {
        minWidth: '170px'
      }
    },
    {
      id: 'pack',
      name: 'pack',
      displayName: 'PACK',
      isNumeric: true,
      isColumnSortable: false,
      sticky: true,
      sx: {
        textAlign: 'right',
        width: '96px'
      }
    },
    {
      id: 'size',
      name: 'size',
      displayName: 'SIZE',
      isColumnSortable: false,
      isNumeric: true,
      sticky: true,
      isGroupBorderRequire: false,
      sx: {
        textAlign: 'right',
        width: '96px'
      }
    }
  ];
  //here creating function to add column dynamically to table header
  const addcolumnCellData = (rowData: ISalesDemandForecast[]) => {
    Object.keys(rowData[0] || {}).forEach((element: string) => {
      if (!columnCell.find((e) => e.id == element)) {
        columnCell.push({
          id: element,
          name: element,
          displayName: element,
          headerTextAlign: 'left',
          isGroupBorderRequire: false,
          isColumnSortable: false,
          sx: {
            textAlign: 'right'
          }
        });
      }
    });
    return columnCell;
  };
  //Below is using filterTemplate
  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View in',
      defaultValue: 'Units',
      sx: {
        width: '150px'
      },
      options: viewInFilterOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewBy',
      title: 'View By',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      options: [
        {
          label: 'District',
          value: 'District'
        },
        {
          label: 'Region',
          value: 'Region'
        },
        {
          label: 'Distribution Center',
          value: 'Distribution Center'
        },
        {
          label: 'Store',
          value: 'Store'
        }
      ]
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subset',
      title: 'Subset',
      sx: {
        width: '182px'
      },
      options: []
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      defaultValue: 'All Departments',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'tag',
      title: 'Tag',
      defaultValue: 'All Tags',
      placeholder: 'Tags',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(tag, 'tag')
    }
  ];
  const defaultData = filterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState<IDemandListFilterValues>(
    defaultData as IDemandListFilterValues
  );
  /**Data to be display */
  const dispatchData = () => {
    dispatch({
      type: demandForecastSagaActions.FETCH_DEMANDFORECAST_SAGA,
      payload: generatePayload(filtervalues)
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);
  if (demandForecast && demandForecast?.length > 0) {
    return (
      <>
        <Box sx={{ml: 5, mr: 5, pb: 3, mt: 5}}>
          <DemandForecastBreadcrumbNav />
          {/* For Making the two elements in same div left and right */}
          <Stack sx={{justifyContent: 'space-between'}} direction='row'>
            <Box sx={{pl: 0, pt: 1, pb: 1}} flexGrow={1}>
              <Typography variant='h4' align='left' id='report-sales-demandForecast-header'>
                Demand Forecast
              </Typography>
            </Box>
            <Box sx={{pl: 0, pt: 1, pb: 1}}>
              <CustomButton
                variant='contained'
                onClick={handleClick}
                sx={{height: 40}}
                id='report-sales-demand-export'
              >
                Export
              </CustomButton>
            </Box>
          </Stack>
          <GridFilter
            values={filtervalues as unknown as IFilterValues}
            filterTemplate={updateSubset(filterTemplate)}
            onFilterChange={onFilterApply}
            hideMoreFilter={true}
            onReset={() => {
              setFilterValues(defaultData);
            }}
            id='report-sales-demandForecast-filter'
          />
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <CustomLoader id='report-demand-forcast' />
            </Box>
          )}
          <EnhancedTable<ISalesDemandForecast>
            height={844}
            rowData={rowData}
            stickyRows={<StickyDemandHeaderCell data={rowData} />}
            columnCell={addcolumnCellData(rowData)}
            rowKey={'itemId'}
            defaultSortKey={'itemId'}
            showTags={true}
            componentType='paper'
            allowAlternateColor={true}
            rowsPerPage={25}
            id='report-sales-demandForecast-table'
          />
          {showExpModal && (
            <ExportModel
              id='sales-demandForecast'
              open={showExpModal}
              handleClose={() => setShowExpModal(false)}
            />
          )}
        </Box>
      </>
    );
  } else {
    return (
      <Box>
        <GridFilter
          values={filtervalues as unknown as IFilterValues}
          filterTemplate={updateSubset(filterTemplate)}
          onFilterChange={onFilterApply}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='report-sales-demandForecast-filter'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='product-list-load' />
          ) : (
            <CustomError
              id='product-list-error'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      </Box>
    );
  }
};
export default DemandForecast;
