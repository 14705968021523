import * as React from 'react';
import {Box, Stack, Typography, TextField, Button} from '@mui/material';
import FileUpload, {FileUploadProps} from 'components/baseComponents/customFileUpload';
import {ReactComponent as Plus} from 'assets/images/icons/Icons & UI-Add & Subtract-Add_Blue.svg';
import {useTheme} from '@mui/material/styles';
import {useDispatch} from 'react-redux';
import {poNotesAttachmentSagaActions} from '../../../../redux/purchaseOrder/details/notes/poNotesAttachmentSaga';
import {useLocation} from 'react-router-dom';

const NotesAttachmentsEdit: React.FC = () => {
  const theme = useTheme();
  const [characterCount, setCharacterCount] = React.useState<string>('');
  const [selectedFile, setSelectedFile] = React.useState<any>([]);
  const [fileLength, setFileLength] = React.useState<number>(0);
  const dispatch = useDispatch();
  const [base64URL, setBase64URL] = React.useState<any>('');
  const location: any = useLocation();

  const textAreahandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCharacterCount(event.target.value);
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: any = '';
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  interface IFileType {
    name: string;
    base64: string;
  }

  interface IPayloadType {
    poId: string;
    file: IFileType[];
  }

  const handleFileInputChange = (event: any) => {
    const temp: any = event.target.files && event.target.files[0];
    let payload: IPayloadType;
    getBase64(temp)
      .then((result) => {
        temp['base64'] = result;
        console.log('File Is', temp);
        setSelectedFile(temp);
        setBase64URL(result);
        payload = {
          poId: location && location.state.poId,
          file: [
            {
              name: temp?.name,
              base64: temp?.base64
            }
          ]
        };
        dispatch({
          type: poNotesAttachmentSagaActions.FETCH_PONOTESATTACHMENT_DATA,
          payload
        });
      })
      .catch((err: any) => {
        console.log(err);
      });

    setFileLength(() => {
      return fileLength + event.target.files.length;
    });
    setSelectedFile(event.target.files && event.target.files[0]);
  };

  const fileUploadProp: FileUploadProps = {
    accept: '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf',
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        console.log(`Saving ${event.target.value}`);
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      console.log(`Drop ${event.dataTransfer.files[0].name}`);
    },
    width: '597px',
    height: '176px',
    sx: {
      bgcolor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grayscale?.[200]}`,
      borderRadius: 1
    },
    id: 'note&attachmentsEdit'
  };

  return (
    <Box
      sx={{
        px: 2,
        py: 4,
        bgcolor: 'grayscale.50',
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.5)'
      }}
    >
      <Stack direction={'row'} sx={{width: '100%'}}>
        <Box sx={{pr: 7}}>
          <Typography variant='subtitle1' sx={{pb: 3}}>
            Notes
          </Typography>
          <Box
            sx={{
              backgroundColor: 'common.white',
              border: (theme) => `1px solid ${theme.palette.grayscale?.[200]}`,
              borderRadius: 1,
              height: '176px'
            }}
          >
            <TextField
              inputProps={{maxLength: 200}}
              multiline
              rows={4}
              onChange={textAreahandler}
              sx={{
                '& .MuiOutlinedInput-root': {
                  border: '0px',
                  fieldset: {
                    overflow: 'hidden',
                    border: '0px'
                  }
                },
                width: '597px',
                backgroundColor: 'common.white'
              }}
            />
            <Typography
              variant='body2'
              sx={{
                textAlign: 'end',
                pt: 2,
                pr: 2,
                pb: 2,
                color: 'neutral.secondary11'
              }}
            >
              {characterCount.length}/200
            </Typography>
          </Box>
        </Box>
        <Box>
          <Stack
            direction={'row'}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              "& input[type='file']": {
                display: 'none',
                padding: 0,
                margin: 0,
                outline: 'none',
                cursor: 'pointer',
                border: 0
              },
              '& fieldset': {
                display: 'none'
              }
            }}
          >
            <Typography variant='subtitle1' sx={{pb: 3}}>
              Attachments({fileLength})
            </Typography>
            <label
              style={{
                display: 'flex',
                cursor: 'pointer',
                fontFamily: 'Roboto Medium',
                fontSize: '14px',
                color: theme.palette.primary.main,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Plus />
              <TextField
                type='file'
                name='file'
                placeholder='ADD FILE'
                onChange={handleFileInputChange}
              />
              Add File
            </label>
          </Stack>
          <FileUpload {...fileUploadProp} />
        </Box>
      </Stack>
    </Box>
  );
};

export default NotesAttachmentsEdit;
