import {TILE_DEFULT_HEIGHT, TILE_SPACING} from 'config/constants';
import React from 'react';
import {getTileWidth} from './tileUtil';
interface ICustomMasonry {
  maxColumns: number;
  children: any;
}

interface ICustomMasonryState {
  blocks: any;
  containerHeight: number;
  columns: number;
  containerWidth: number;
}

const debounce = (f: any, rate = 45) => {
  let timeout: any = 0;
  return () => {
    if (timeout !== 0) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = 0;
      f();
    }, rate);
  };
};

export class CustomMasonry extends React.Component<ICustomMasonry, ICustomMasonryState> {
  state = {
    blocks: {},
    containerHeight: 0,
    columns: 1,
    containerWidth: 0
  };

  DefaultSettings = {
    targetBlockWidth: 390
  };

  // Memorize past children to perform updates on children deletion
  pastChildren = 0;

  // These properties are just a sync representation of some state properties.
  columns: number = this.state.columns;
  blocks: any | {[key: string]: any} = this.state.blocks;

  /**
   * De-bouncing properties used to prevent size recalculations being called very often.
   * @type {function}
   * @private
   */
  debouncedResize = debounce(this.updateContainerWidth.bind(this));
  /**
   * This property assigns the fixed height to Masonry container. The purpose of this is to
   * prevent masonry layout from updating infinitely. For example, when the elements get measured
   * and placed first time, the scroll bar may appear. Because of the width change XMasonry will
   * go to recalculate sizes once again, appearing at the initial state again because elements to
   * calculate got detached from the DOM. This creates an infinite loop. The solution for this is
   * to fix the container's previously calculated height until all the elements will be measured.
   */
  fixedHeight = 0;

  /**
   * Masonry layout container reference.
   */
  container: HTMLDivElement | any = null;

  /**
   * The width of Masonry block in pixels. Is assigned dynamically, and must be in sync with the
   * state property.
   */
  containerWidth = this.state.containerWidth;

  constructor(props: ICustomMasonry) {
    super(props);
    this.pastChildren = props.children;
  }

  /**
   * Get number of columns by the given width in pixels.
   */
  getColumnsNumber(width: number) {
    return Math.max(1, Math.round(width / this.DefaultSettings.targetBlockWidth));
  }

  /**
   * Update container width
   */
  updateContainerWidth() {
    const newWidth = this.container ? this.container.clientWidth : 0;
    if (
      newWidth === this.containerWidth
      // This condition is required to prevent Masonry from infinitely looping in some cases
      // when scrollbar appears. This works in case of dynamic content, when scrollbar appear
      // causes content to change, and because this change scrollbar disappears, and so on:
      // this repeats infinitely.
    ) {
      return false;
    }
    this.setState({
      columns: (this.columns = this.getColumnsNumber(newWidth)),
      containerWidth: (this.containerWidth = newWidth),
      blocks: (this.blocks = {})
    });
    return true;
  }

  /**
   * Measure non-measured blocks and update measured ones.
   */
  measureChildren() {
    if (!this.container) return false;

    const blocks: {[key: string]: any} = {};
    let update = false;

    for (let i = 0; i < this.container.children.length; i++) {
      const child = this.container.children[i],
        hasXKey = child.hasAttribute('data-xkey'),
        key = child.getAttribute('data-key'),
        width = +(child.getAttribute('data-width') || 1);
      if (
        !hasXKey &&
        (this.blocks[key || 'null'] || {}).height === child.clientHeight &&
        (this.blocks[key || 'null'] || {}).width === width
      )
        continue;
      blocks[key || 'null'] = {
        height: child.clientHeight
      };
      if (!update) update = true;
    }

    if (update) this.reCalculatePositions(blocks);

    return update;
  }
  /**
   * Get the best fit column
   */
  getBestFitColumn(heights: Array<number>, width = 1) {
    const actualCols = Math.min(heights.length - width + 1, this.props.maxColumns - width + 1);
    let minIndex = 0,
      minHeight = Infinity;
    for (let i = 0; i < actualCols; ++i) {
      const currentMinHeight = Math.max.apply(null, heights.slice(i, i + width));
      if (currentMinHeight < minHeight) {
        minHeight = currentMinHeight;
        minIndex = i;
      }
    }
    return {col: minIndex, height: minHeight};
  }

  /**
   * Re calucalte the position
   * @param {object} newBlocks
   * @param {object} deletedBlocks
   * @private
   */
  reCalculatePositions(newBlocks: any = null, deletedBlocks: any = null) {
    let blocks: any;
    const heights = [];

    for (let c = 0; c < this.columns; ++c) heights.push(0);

    for (const key in this.blocks) {
      if (
        Object.prototype.hasOwnProperty.call(this.blocks, key) &&
        typeof this.blocks[key] === 'undefined'
      ) {
        if (deletedBlocks === null) deletedBlocks = {};
        deletedBlocks[key] = {};
      }
    }
    if (deletedBlocks) {
      blocks = {};
      for (const key in this.blocks)
        if (
          Object.prototype.hasOwnProperty.call(this.blocks, key) &&
          !Object.prototype.hasOwnProperty.call(deletedBlocks, key)
        )
          blocks[key] = this.blocks[key];
      for (const key in newBlocks)
        if (
          Object.prototype.hasOwnProperty.call(newBlocks, key) &&
          !Object.prototype.hasOwnProperty.call(deletedBlocks, key)
        )
          blocks[key] = newBlocks[key];
    } else {
      blocks = {
        ...this.blocks,
        ...newBlocks
      };
    }

    for (let i = 0; i < this.container.children.length; i++) {
      const child = this.container.children[i],
        key = child.getAttribute('data-key');
      if (!Object.prototype.hasOwnProperty.call(blocks, key)) continue;
      if (deletedBlocks && Object.prototype.hasOwnProperty.call(deletedBlocks, key)) continue;
      const blockWidth = +child.getAttribute('data-width') || 1,
        {col, height} = this.getBestFitColumn(heights, blockWidth),
        // tileDefaultHeight = Number(TILE_DEFULT_HEIGHT.replace('px', '')),
        newHeight = (height == 0 ? height : height + TILE_SPACING) + blocks[key].height;

      let customleft = 0;
      let customTop = 0;
      //console.log(heights, blockWidth, blocks[key].height, 'width', height, key, newHeight);
      /////Original logic
      //blocks[key].left = (this.containerWidth * col) / this.columns;
      //blocks[key].top = height;
      try {
        ////Get previous child width if current div has different width

        customleft = (this.containerWidth * col) / this.columns;
        blocks[key].left = customleft;

        //// if current div has different height because of parent height
        if ((height / Number(TILE_DEFULT_HEIGHT.replace('px', ''))) % 1 == 0) {
          customTop =
            height +
            TILE_SPACING * Math.round(height / Number(TILE_DEFULT_HEIGHT.replace('px', '')));
        } else {
          customTop =
            blocks[key].height * Math.round(height / Number(TILE_DEFULT_HEIGHT.replace('px', ''))) +
            TILE_SPACING * Math.round(height / Number(TILE_DEFULT_HEIGHT.replace('px', '')));
        }
        blocks[key].top = customTop;
      } catch {
        customTop = height;
        customleft = (this.containerWidth * col) / this.columns;

        blocks[key].left = customleft;
        blocks[key].top = customTop;
      }

      blocks[key].width = Math.min(blockWidth, this.columns);
      for (let i = 0; i < blockWidth; ++i) heights[col + i] = newHeight;
    }

    if (heights[heights.length - 1] === 0) {
      let emptyColumns = 1;
      for (; heights[heights.length - 1 - emptyColumns] === 0; ++emptyColumns);
      const leftMargin = (this.containerWidth * emptyColumns) / this.columns / 2;
      for (const key in blocks)
        if (Object.prototype.hasOwnProperty.call(blocks, key)) blocks[key].left += leftMargin;
    }

    this.setState({
      blocks: (this.blocks = blocks),
      containerHeight: Math.max.apply(null, heights)
    });
  }

  /**
   * Whether the components were updated.
   */
  updateInternal() {
    if (!this.updateContainerWidth()) return this.measureChildren();
    return false;
  }

  /**
   * Component did mount event.
   */
  componentDidMount() {
    this.updateInternal();
  }
  /**
   * Component did update event.
   */
  componentDidUpdate() {
    // Other conditions are already covered, except of removing children without adding new ones
    if (React.Children.count(this.props.children) < React.Children.count(this.pastChildren)) {
      const newKeys = new Set(),
        deleted: any = {};
      React.Children.forEach(
        this.pastChildren,
        (child: any, i: number) => child && newKeys.add(child?.key === null ? i : child?.key)
      );
      React.Children.forEach(this.props.children, (child, i) => {
        if (!child) {
          return;
        }
        const key = child.key === null ? i : child.key;
        if (!newKeys.has(key)) deleted[key] = {};
      });
      this.reCalculatePositions(null, deleted);
    }
    this.pastChildren = this.props.children;
    if (!this.updateInternal()) return;
  }

  /**
   * Render event
   */
  render() {
    const allKeys: any = {};
    let toMeasure = 0;
    const elements = this.containerWidth === 0 ? [] : this.props.children || [];
    const children = React.Children.map(elements, (element, i) => {
      if (!element) {
        return null;
      }
      const key = element.key === null ? i : element.key;
      const measured = this.blocks[key]; // || undefined
      if (!measured) ++toMeasure;
      allKeys[key] = null;
      return measured
        ? React.cloneElement(element, {
            'data-key': key,
            key: key,
            style: {
              left: Math.floor(measured.left),
              top: measured.top
            },
            measured: true,
            height: measured.height,
            parent: this
          })
        : React.cloneElement(element, {
            'data-key': key,
            'data-xkey': key,
            key: key,
            style: {
              visibility: 'hidden'
            },
            height: 0,
            parent: this
          });
    });

    const actualHeight =
      children.length - toMeasure > 0 || children.length === 0
        ? (this.fixedHeight = this.state.containerHeight)
        : this.fixedHeight;

    return (
      <div
        className={`masonry`}
        style={{
          position: `relative`,
          height: actualHeight
        }}
        ref={(c) => (this.container = c)}
      >
        {children}
      </div>
    );
  }
}
