import {createSlice} from '@reduxjs/toolkit';
export interface poNotesAttachmentState {
  loading: boolean;
  poNotesAttachment: any;
  error: string;
}

const initialState: poNotesAttachmentState = {
  loading: false,
  poNotesAttachment: {},
  error: ''
};

const poNotesAttachmentSlice = createSlice({
  name: 'poNotesAttachment',
  initialState,
  reducers: {
    poNotesAttachmentLoading: (state) => {
      state.loading = true;
    },
    poNotesAttachmentSuccess: (state, action) => {
      state.poNotesAttachment = action.payload;
      state.loading = false;
    },
    poNotesAttachmentFailure: (state) => {
      state.loading = false;
      state.poNotesAttachment = {};
      state.error = 'Request failed with status code 404';
    }
  }
});

export default poNotesAttachmentSlice.reducer;
export const {poNotesAttachmentLoading, poNotesAttachmentSuccess, poNotesAttachmentFailure} =
  poNotesAttachmentSlice.actions;
