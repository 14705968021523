import * as React from 'react';
import {useRef} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import Link from '@mui/material/Link';
import {Tabs, Tab, Box, Stack, Typography, Breadcrumbs, Modal} from '@mui/material';
import Grid from '@mui/material/Grid';
import {ReactComponent as Eye} from 'assets/images/icons/Icons & UI-View-View_Blue.svg';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as Checkmark} from 'assets/images/icons/Icons & UI-Checkmark-Checkmark_Blue.svg';
import {ReactComponent as Edit} from 'assets/images/icons/Icons & UI-Edit-Edit_Active.svg';
import {ReactComponent as Cancel} from 'assets/images/icons/Icons & UI-Refresh & Sync-Today_Passive.svg';
import {ReactComponent as Print} from 'assets/images/icons/Print.svg';
import {ReactComponent as Copy} from 'assets/images/icons/Copy.svg';
import GeneralInfo from 'features/purchaseOrder/sections/generalInfo/generalInfo';
import SupplierContact from 'features/purchaseOrder/sections/generalInfo/supplierContact';
import NotesAttachMents from 'features/purchaseOrder/sections/generalInfo/notesAttachment';
import PoItems from 'features/purchaseOrder/sections/generalInfo/poItems';
import {
  POStatus,
  Mode,
  IGeneralInfoState,
  ISupllierContact,
  IPOItems
} from 'features/purchaseOrder/types';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {generalInfoSagaActions} from 'redux/purchaseOrder/details/generalInfo/generalInfoSaga';
import CustomButton from 'components/baseComponents/customButton';
import moment from 'moment';
import FreightSummary from './sections/generalInfo/freightSummary';
import {useParams} from 'react-router-dom';
import {poItemsSagaActions} from 'redux/purchaseOrder/details/items/poItemsSaga';
import {supplierContactSagaActions} from 'redux/dashboard/master/supplierContactSaga';

import {createPOSagaActions} from 'redux/purchaseOrder/poList/createPOSaga';

import SuccessAlert from 'components/baseComponents/successAlert';
import {updatePOSagaActions} from 'redux/purchaseOrder/poList/updatePOSaga';
import ErrorAlert from 'components/baseComponents/errorAlert';
import {style} from './purchaseOrder';

// import FreightSummary from 'features/purchaseOrder/sections/generalInfo/freightSummary';

interface IBreadCrumb {
  name: '';
}

export const BreadcrumbNav: React.FC<IBreadCrumb> = (props) => {
  const navigate = useNavigate();
  const location: any = useLocation();

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    navigate('/PO', {state: location.state});
  }
  const breadcrumbs = [
    <Link underline='hover' key='1' href='/' onClick={handleClick}>
      <Typography variant='body1' sx={{color: 'primary.main'}} textAlign='left'>
        PO
      </Typography>
    </Link>,
    <Typography key='4' variant='body1' sx={{color: 'common.black'}} textAlign='left'>
      {location?.state?.poId}{' '}
      {location?.state?.status?.toUpperCase() === POStatus.DRAFT
        ? 'Draft'
        : `${location?.state?.vendorDescription}`}{' '}
    </Typography>
  ];

  return (
    <Breadcrumbs
      separator='>'
      sx={{
        fontSize: '12px',
        color: 'bpGreyFont.main'
      }}
      aria-label='breadcrumb'
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

interface IPOStat {
  status: string;
  data: any;
}

export const POStats: React.FC<IPOStat> = (props: IPOStat) => {
  const {data} = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        pb: '47px'
      }}
    >
      {props?.status?.toUpperCase() === POStatus.DRAFT ? (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          sx={{paddingLeft: '200px'}}
        >
          <Grid item xs={4}>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600],
                lineHeight: '30px'
              }}
              textAlign='left'
            >
              ISSUE DATE:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1' sx={{lineHeight: '30px'}} textAlign='left'>
              TBD
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600],
                lineHeight: '30px'
              }}
              textAlign='left'
            >
              NET VALUE:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1' sx={{lineHeight: '30px'}} textAlign='left'>
              {`USD`}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{xs: 1, sm: 2, md: 3}}
          sx={{paddingLeft: '200px'}}
        >
          <Grid item xs={4}>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600],
                lineHeight: '30px'
              }}
              textAlign='left'
            >
              ISSUE DATE:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1' sx={{lineHeight: '30px'}} textAlign='left'>
              {data.issueDate}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600],
                lineHeight: '30px'
              }}
              textAlign='left'
            >
              PLAN DLVR:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1' sx={{lineHeight: '30px'}} textAlign='left'>
              {data.issueDate
                ? moment(data.issueDate, 'YYYY-MM-DD').add(3, 'd').format('YYYY-MM-DD')
                : data.plannedDeliveryDate}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600],
                lineHeight: '30px'
              }}
              textAlign='left'
            >
              NET VALUE:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1' sx={{lineHeight: '30px'}} textAlign='left'>
              {`$${data?.netValue} USD`}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600],
                lineHeight: '30px'
              }}
              textAlign='left'
            >
              STATUS:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1' sx={{lineHeight: '30px'}} textAlign='left'>
              {data.status}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: 'Roboto Medium',
                fontWeight: 500,
                color: theme.palette.grayscale?.[600],
                lineHeight: '30px'
              }}
              textAlign='left'
            >
              APPROVER:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1' sx={{lineHeight: '30px'}} textAlign='left'>
              {data.approver}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const scrollTo = (ele: any) => {
  ele.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center'
  });
};

const PODetail: React.FC = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const [value, setValue] = React.useState(0);
  const [formSubmit, setFormSubmit] = React.useState(false);
  const [poSupplierContact, setPOSupplierContact] = React.useState({} as ISupllierContact);
  const [alert, setAlert] = React.useState(false);
  const [mode, setMode] = React.useState<string>(Mode.VIEW);
  const [generalInfoValue, setGeneralInfoValue] = React.useState({} as IGeneralInfoState);
  const [poStatus, setPOStatus] = React.useState(
    location.state ? location?.state?.status?.toUpperCase() : POStatus.ON_ORDER
  );

  const {generalInfo} = useAppSelector((state) => state.generalInfoSlice);
  //For Destructring the PO Item Field data
  const {poItems} = useAppSelector((state) => state.poItemsSlice);
  React.useEffect(() => {
    if (Mode.EDIT === 'EDIT') {
      const bindGeneralInfo = {
        ...generalInfo,
        warehouseStore: generalInfo.warehouseId,
        buyerName: generalInfo.buyer
      };
      setGeneralInfoValue(bindGeneralInfo);
    }
  }, [generalInfo]);
  //Destructuring the master data Specially GeneralInfo
  const {supplierContact} = useAppSelector((state) => state.supplierContactMasterSlice);
  //Destructuring the The Response after successful submit

  const {createPO, error} = useAppSelector((state) => state.createPOSlice);

  //Destructuring the response after successful update purchase order
  const {updatePO, updateError} = useAppSelector((state) => state.updatePOSlice);
  // We are rendering alert based on createPO Message or Update Po Message we get
  React.useEffect(() => {
    if (createPO?.message) {
      setAlert(true);
      // when the component is mounted, the success alert is displayed for 5 seconds
      setTimeout(() => {
        dispatch({type: createPOSagaActions.FETCH_CLEARPO_DATA});
      }, 5000);
      setTimeout(() => {
        navigate('/PO', {state: {...createPO, openPopup: true}});
      }, 5000);
    } else if (error) {
      setAlert(true);
      // when the component is mounted, the error alert is displayed for 5 seconds
      setTimeout(() => {
        dispatch({type: createPOSagaActions.FETCH_CLEARPO_DATA});
      }, 5000);
    } else if (updateError) {
      setAlert(true);
      // when the component is mounted, the error alert is displayed for 5 seconds
      setTimeout(() => {
        dispatch({type: updatePOSagaActions.FETCH_CLEARUPADTEPO_DATA});
      }, 5000);
    }
    //This Else-If condition is for Update PO
    else if (updatePO?.message) {
      setAlert(true);
      setTimeout(() => {
        dispatch({type: updatePOSagaActions.FETCH_CLEARUPADTEPO_DATA});
      }, 5000);
      setTimeout(() => {
        setMode(Mode.VIEW);
        navigate(0);
      }, 5000);
      // setMode(Mode.VIEW);
    } else {
      setAlert(false);
    }
  }, [createPO, updatePO]);
  /**
   *We are setting the value based on Event happen in DropDown, textField and other
   * @param event
   */
  const onPOElementChange = (event: any) => {
    if (event.target.name === 'supplierName') {
      const selectedPoContact = supplierContact.find((element: any) => {
        return element.supplierName === event.target.value;
      });

      setPOSupplierContact(selectedPoContact);
    }

    setGeneralInfoValue({
      ...generalInfoValue,
      poStatus: 'update',
      [event.target.name]: event.target.value
    });
  };

  //Supplier master data
  React.useEffect(() => {
    dispatch({type: supplierContactSagaActions.FETCH_SUPPLIERCONTACT_TYPE_DATA});
  }, []);

  /**
   * Handle Form Submit
   * we are also generating payload after checkinhg the Mandotory field from UI.
   */

  const handleFormSubmit = () => {
    const poOrderItems = availableItems.map((element: IPOItems) => {
      return {
        Plant: generalInfoValue.warehouseStore,
        Material: parseInt(element.itemId).toString(),
        OrderQuantity: element.quantity,
        NetPriceAmount: element.vendCost,
        to_PurchaseOrderPricingElement: {
          results: [
            {ConditionType: 'FRB1', ConditionRateValue: element.estimatedFreight.toString()}
          ]
        }
      };
    });
    setFormSubmit(true);
    if (
      Object.keys.length > 0 &&
      generalInfoValue['purchaseOrder'] &&
      generalInfoValue['issueDate'] &&
      generalInfoValue['supplierName'] &&
      generalInfoValue['buyerId'] &&
      generalInfoValue['buyerName'] &&
      generalInfoValue['warehouseStore'] &&
      generalInfoValue['freightTermDesc'] &&
      generalInfoValue['fobOrigin'] &&
      generalInfoValue['loadCondDesc'] &&
      generalInfoValue['unloading'] &&
      generalInfoValue['mustArriveBy'] &&
      generalInfoValue['paymentTerms'] &&
      generalInfoValue['invoicingParty'] &&
      generalInfoValue['currency'] &&
      generalInfoValue['fixedExchangeRate']
    ) {
      setFormSubmit(false);

      const Paylaod = {
        Language: 'EN',
        CompanyCode: '1000',
        ExchangeRate: '1.00000',
        PurchasingOrganization: 'P100',
        IncotermsClassification: generalInfoValue?.fobOrigin,
        PurchaseOrderType: generalInfoValue.purchaseOrder,
        Supplier: poSupplierContact.supplierId,
        SupplierName: poSupplierContact.supplierName,
        PurchasingGroup: generalInfoValue.buyerId,
        CreatedByUser: generalInfoValue.buyerName,
        PaymentTerms: generalInfoValue.paymentTerms,
        DocumentCurrency: generalInfoValue.currency,
        InvoicingParty: generalInfoValue.invoicingParty,
        ExchangeRateIsFixed: JSON.parse(generalInfoValue.fixedExchangeRate),
        // ShippingInstruction: generalInfoValue.deliveryCondDesc,
        // UnloadingPointName: generalInfoValue.unloading,
        to_PurchaseOrderItem: {
          results: poOrderItems
        }
      };
      dispatch({
        type: createPOSagaActions.FETCH_CREATEPO_DATA,
        payload: Paylaod
      });

      // navigate('/PO/:id', { state: null });
      // setGeneralInfoValue({} as IGeneralInfoState);
    } else {
      return {};
    }
  };
  /**
   * We are creating Function to handle the Update PO Data
   * @returns
   */
  const handleUpdatePO = () => {
    const poOrderItems = availableItems.map((element: IPOItems) => {
      if (
        element.poStatus === 'Update' ||
        element.poStatus === 'Delete' ||
        element.poStatus === 'old'
      ) {
        return {
          Plant: generalInfoValue.warehouseStore,
          Material: parseInt(element.itemId).toString(),
          OrderQuantity: element.quantity,
          NetPriceAmount: element.vendCost,
          PurchaseOrderItem: element.lineNo,
          PoStatus: element.poStatus,
          to_PurchaseOrderPricingElement: {
            results: [
              {ConditionType: 'FRB1', ConditionRateValue: element.estimatedFreight.toString()}
            ]
          }
        };
      } else {
        return {
          Plant: generalInfoValue.warehouseStore,
          Material: parseInt(element.itemId).toString(),
          OrderQuantity: element.quantity,
          NetPriceAmount: element.vendCost,
          PoStatus: element.poStatus,
          to_PurchaseOrderPricingElement: {
            results: [
              {ConditionType: 'FRB1', ConditionRateValue: element.estimatedFreight.toString()}
            ]
          }
        };
      }
    });

    setFormSubmit(true);

    if (
      Object.keys.length > 0 &&
      generalInfoValue['buyerId'] &&
      generalInfoValue['buyerName'] &&
      generalInfoValue['warehouseStore'] &&
      generalInfoValue['freightTermDesc'] &&
      generalInfoValue['fobOrigin'] &&
      generalInfoValue['loadCondDesc'] &&
      generalInfoValue['unloading'] &&
      generalInfoValue['mustArriveBy'] &&
      generalInfoValue['paymentTerms'] &&
      generalInfoValue['invoicingParty'] &&
      generalInfoValue['currency'] &&
      generalInfoValue['fixedExchangeRate']
    ) {
      setFormSubmit(false);

      const Paylaod = {
        Language: 'EN',
        CompanyCode: '1000',
        ExchangeRate: '1.00000',
        PurchasingOrganization: 'P100',
        PurchaseOrderType: 'NB',
        PoStatus: generalInfo.poStatus ? generalInfo.poStatus : 'old',
        PurchasingGroup: generalInfoValue.buyerId,
        CreatedByUser: generalInfoValue.buyerName,
        PaymentTerms: generalInfoValue.paymentTerms,
        DocumentCurrency: generalInfoValue.currency,
        InvoicingParty: generalInfoValue.invoicingParty,
        PurchaseOrder: location.state?.poId,
        ExchangeRateIsFixed: JSON.parse(generalInfoValue.fixedExchangeRate),
        IncotermsClassification: generalInfoValue?.fobOrigin,
        // ShippingInstruction: generalInfoValue.deliveryCondDesc,
        // UnloadingPointName: generalInfoValue.unloading,
        to_PurchaseOrderItem: {
          results: poOrderItems
        }
      };
      dispatch({
        type: updatePOSagaActions.FETCH_UPDATEPO_DATA,
        payload: Paylaod
      });

      // navigate('/PO/:id', { state: null });
      // setGeneralInfoValue({} as IGeneralInfoState);
    } else {
      return {};
    }
  };

  const [availableItems, setAvailableItems] = React.useState<IPOItems[]>([]);

  React.useEffect(() => {
    const oldProducts =
      poItems?.map((ele: IPOItems) => {
        return {
          ...ele,
          poStatus: 'old'
        };
      }) || [];
    setAvailableItems(oldProducts.length ? oldProducts : []);
  }, [poItems]);

  const updateProductItems = (newItems: IPOItems[]) => {
    setAvailableItems([...newItems]);
  };

  React.useEffect(() => {
    if (params.id) {
      dispatch({
        type: generalInfoSagaActions.FETCH_GENERALINFO_DATA,
        payload: location.state?.poId
      });
      if (location.state.status && location.state.status === Mode.EDIT) {
        setMode(Mode.EDIT);
      }

      dispatch({
        type: poItemsSagaActions.FETCH_POITEMS_DATA,
        payload: params.id
      });
    }
  }, []);

  const headerRef = useRef(null);
  const generalInfoRef = useRef(null);
  const itemRef = useRef(null);
  const freightRef = useRef(null);
  const notesRef = useRef(null);
  const supplierRef = useRef(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function editHandleClick(event: any) {
    event.preventDefault();
    setMode(Mode.EDIT);
  }
  const handleAlertClose = () => {
    setAlert(false);
  };

  function deleteHandler(event: any) {
    event.preventDefault();
    navigate('/PO', {state: null});
  }

  function cancelHandler(event: any) {
    event.preventDefault();
    setMode(Mode.VIEW);
  }

  return (
    <>
      {location.state.status === POStatus.DRAFT ? (
        <Box>
          <>
            <Box
              sx={{
                px: '204px',
                bgcolor: 'common.white',
                position: 'fixed',
                boxShadow: '0 1px 2px 0 rgb(0 0 0 / 50%)',
                top: 60,
                left: 0,
                right: 0,
                height: '180px',
                zIndex: 1000
              }}
            >
              <Stack
                direction={'row'}
                sx={{
                  height: '38px',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <BreadcrumbNav name={generalInfo.supplier} />
                <Stack direction={'row'} sx={{justifyContent: 'end', alignItems: 'center'}}>
                  <CustomButton
                    id='po-details-print-button'
                    sx={{
                      '&:hover': {
                        bgcolor: 'unset'
                      }
                    }}
                    onClick={() => window.print()}
                  >
                    <Print />
                    <Typography
                      variant='button'
                      sx={{pl: 1.25, fontWeight: 'bold', whiteSpace: 'nowrap'}}
                    >
                      PRINT
                    </Typography>
                  </CustomButton>

                  <CustomButton
                    sx={{
                      '&:hover': {
                        bgcolor: 'unset'
                      }
                    }}
                    id={'poDetails-view-in-sap'}
                    onClick={() => window.open('https://www.google.com')}
                  >
                    <Eye />
                    <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                      VIEW IN SAP
                    </Typography>
                  </CustomButton>
                </Stack>
              </Stack>
              <Box sx={{flexGrow: 1, height: '95px'}}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Stack direction={'row'}>
                      <Typography variant='h6' sx={{color: 'grayscale.600'}}>
                        #{location?.state?.poId}
                      </Typography>
                    </Stack>
                    <Typography
                      variant='h4'
                      sx={{
                        pb: 5
                      }}
                    >
                      Draft PO
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <POStats status={location?.state?.status} data={generalInfo} />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  bgcolor: 'transparent'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    bgcolor: 'common.white'
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                      '& .MuiTab-root': {
                        color: theme.palette.grayscale?.[400],
                        p: 0,
                        mr: 3,
                        minWidth: 'fit-content'
                      }
                    }}
                    ref={headerRef}
                  >
                    <Tab
                      id='purchaseOrder-details-general-info'
                      value={0}
                      label={<Typography variant='button'>GENERAL INFO</Typography>}
                      onClick={() => {
                        scrollTo(generalInfoRef.current);
                      }}
                      onScroll={() => {
                        scrollTo(generalInfoRef.current);
                      }}
                    />
                    <Tab
                      id='purchaseOrder-details-items'
                      value={1}
                      label={<Typography variant='button'>ITEMS</Typography>}
                      onClick={() => {
                        scrollTo(itemRef.current);
                      }}
                      onScroll={() => {
                        scrollTo(generalInfoRef.current);
                      }}
                    />
                    <Tab
                      id='purchaseOrder-details-freight-summary'
                      value={2}
                      label={<Typography variant='button'>FREIGHT SUMMARY</Typography>}
                      onClick={() => {
                        scrollTo(freightRef.current);
                      }}
                    />
                    <Tab
                      id='purchaseOrder-details-notes-attachments'
                      value={3}
                      label={<Typography variant='button'>NOTES & ATTACHMENTS</Typography>}
                      onClick={() => {
                        scrollTo(notesRef.current);
                      }}
                    />
                    <Tab
                      id='purchaseOrder-details-supplier-contact'
                      value={4}
                      label={<Typography variant='button'>SUPPLIER CONTACT</Typography>}
                      onClick={() => {
                        scrollTo(supplierRef.current);
                      }}
                    />
                  </Tabs>
                  {/* <Typography
                    variant='caption'
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Stack direction={'row'} sx={{ alignItems: 'center', gap: '9.34px' }}>
                      <Checkmark fill={theme.palette.neutral?.secondary11} /> Auto Saved at 10:20 AM
                    </Stack>
                  </Typography> */}
                </Box>
              </Box>
            </Box>
            <Box sx={{pt: '40px'}}>
              <Box id='generalInfo' ref={generalInfoRef}>
                <GeneralInfo
                  poStatus={poStatus}
                  mode={mode}
                  submitStatus={formSubmit}
                  supplierData={supplierContact}
                  onValueChange={(event: any) => {
                    onPOElementChange(event);
                  }}
                  data={generalInfoValue}
                  dependentData={poSupplierContact}
                />
              </Box>
              <Box id='items' ref={itemRef}>
                <PoItems
                  poStatus={poStatus}
                  mode={mode}
                  poItems={availableItems}
                  updateProductItems={updateProductItems}
                />
              </Box>
              <Box id='freight_summary' ref={freightRef}>
                <FreightSummary />
              </Box>
              <Box id='notes_attachments' ref={notesRef}>
                <NotesAttachMents poStatus={poStatus} mode={mode} />
              </Box>
              <Box id='supplier' ref={supplierRef}>
                <SupplierContact poStatus={poStatus} mode={mode} supplierData={poSupplierContact} />
              </Box>
            </Box>
            <Box sx={{height: 100}}></Box>
          </>

          <Box sx={{width: '100%', position: 'fixed', bottom: 0, zIndex: 2, left: 0}}>
            {alert && createPO.message && (
              <Box sx={{height: 120}}>
                <SuccessAlert successMessage={createPO} handleClose={handleAlertClose} />
              </Box>
            )}
            {alert && error && (
              <Box sx={{height: 120}}>
                <ErrorAlert successError={error} handleClose={handleAlertClose} />
              </Box>
            )}
            <Stack
              direction={'row'}
              sx={{
                bottom: 0,
                top: 60,
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'sticky',
                bgcolor: 'common.white',
                zIndex: 1500,
                boxShadow: '2px 1px 2px 2px rgb(0 0 0 / 50%)',
                width: '100%'
              }}
            >
              <CustomButton
                id={'poDetails-delete-button'}
                variant={'outlined'}
                onClick={deleteHandler}
                sx={{
                  my: 2,
                  ml: 20,
                  backgroundColor: theme.palette.neutral?.secondary2,
                  border: `1px solid ${theme.palette.neutral?.secondary2}`,
                  color: theme.palette.primary.main,
                  '&:hover': {
                    border: `1px solid ${theme.palette.neutral?.secondary2}`,
                    backgroundColor: theme.palette.neutral?.secondary2
                  }
                }}
              >
                <Typography variant='caption' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                  DELETE
                </Typography>
              </CustomButton>
              <CustomButton
                variant={'contained'}
                onClick={handleFormSubmit}
                id={'poDetails-submit-button'}
                sx={{my: 2, mr: '204px'}}
                disabled={!availableItems.length}
              >
                <Typography
                  variant='caption'
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap'
                  }}
                >
                  SUBMIT PO
                </Typography>
              </CustomButton>
            </Stack>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              px: '204px',
              bgcolor: 'common.white',
              position: 'fixed',
              boxShadow: '0 1px 2px 0 rgb(0 0 0 / 50%)',
              top: 60,
              left: 0,
              right: 0,
              height: '280px',
              zIndex: 1002
            }}
          >
            <Stack
              direction={'row'}
              sx={{
                height: '40px',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <BreadcrumbNav name={generalInfo.supplier} />
              <Stack direction={'row'} sx={{justifyContent: 'end', alignItems: 'center'}}>
                {mode !== Mode.EDIT && (
                  <>
                    <CustomButton
                      id='po-details-print-button'
                      sx={{
                        '&:hover': {
                          bgcolor: 'unset'
                        }
                      }}
                      onClick={() => window.print()}
                    >
                      <Print />
                      <Typography
                        variant='button'
                        sx={{pl: 1.25, fontWeight: 'bold', whiteSpace: 'nowrap'}}
                      >
                        PRINT
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      id={'poDetails-edit-button'}
                      onClick={editHandleClick}
                      sx={{
                        '&:hover': {
                          bgcolor: 'unset'
                        }
                      }}
                    >
                      <Edit />
                      <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                        EDIT
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      sx={{
                        '&:hover': {
                          bgcolor: 'unset'
                        }
                      }}
                      id={'poDetails-copy-button'}
                    >
                      <Copy />
                      <Typography
                        variant='button'
                        sx={{pl: 0.5, fontWeight: 'bold', whiteSpace: 'nowrap'}}
                      >
                        COPY
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      id={'poDetails-cancel-po-button'}
                      sx={{
                        '&:hover': {
                          bgcolor: 'unset'
                        }
                      }}
                    >
                      <Cancel />
                      <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                        CANCEL PO
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      id={'poDetails-view-in-sap'}
                      sx={{
                        '&:hover': {
                          bgcolor: 'unset'
                        }
                      }}
                      onClick={() => window.open('https://www.google.com')}
                    >
                      <Eye />
                      <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                        VIEW IN SAP
                      </Typography>
                    </CustomButton>
                  </>
                )}
              </Stack>
            </Stack>
            <Box sx={{flexGrow: 1, mt: '10px', height: '184px'}}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Stack direction={'row'}>
                    <Typography variant='h6' sx={{color: 'grayscale.600'}}>
                      #{location?.state?.poId}
                    </Typography>
                  </Stack>
                  <Typography
                    variant='h4'
                    sx={{
                      pb: 2
                    }}
                  >
                    {generalInfo?.vendorDescription}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <POStats status={location?.state?.status} data={generalInfo} />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                bgcolor: 'transparent'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  bgcolor: 'common.white',
                  mt: 0
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  sx={{
                    '& .MuiTab-root': {
                      color: theme.palette.grayscale?.[400],
                      p: 0,
                      mr: 3,
                      minWidth: 'fit-content'
                    }
                  }}
                  ref={headerRef}
                >
                  <Tab
                    id='purchaseOrder-details-general-info'
                    value={0}
                    label={<Typography variant='button'>GENERAL INFO</Typography>}
                    onClick={() => {
                      scrollTo(generalInfoRef.current);
                    }}
                  />
                  <Tab
                    id='purchaseOrder-details-items'
                    value={1}
                    label={<Typography variant='button'>ITEMS</Typography>}
                    onClick={() => {
                      scrollTo(itemRef.current);
                    }}
                  />
                  <Tab
                    id='purchaseOrder-details-freight-summary'
                    value={2}
                    label={<Typography variant='button'>FREIGHT SUMMARY</Typography>}
                    onClick={() => {
                      scrollTo(freightRef.current);
                    }}
                  />
                  <Tab
                    id='purchaseOrder-details-notes-attachments'
                    value={3}
                    label={<Typography variant='button'>NOTES & ATTACHMENTS</Typography>}
                    onClick={() => {
                      scrollTo(notesRef.current);
                    }}
                  />
                </Tabs>
                {location?.state?.status?.toUpperCase() === POStatus.DRAFT && (
                  <Typography
                    variant='caption'
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Stack direction={'row'} sx={{alignItems: 'center', gap: '9.34px'}}>
                      <Checkmark fill={theme.palette.neutral?.secondary11} /> Auto Saved at 10:20 AM
                    </Stack>
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{pt: '140px'}}>
            <Box id='generalInfo' ref={generalInfoRef}>
              <GeneralInfo
                poStatus={poStatus}
                mode={mode}
                submitStatus={formSubmit}
                supplierData={supplierContact}
                onValueChange={(event: any) => {
                  onPOElementChange(event);
                }}
                data={generalInfoValue}
              />
            </Box>
            <Box id='items' ref={itemRef}>
              <PoItems
                poStatus={poStatus}
                mode={mode}
                poItems={availableItems}
                updateProductItems={updateProductItems}
              />
            </Box>
            <Box id='freight_summary' ref={freightRef}>
              <FreightSummary />
            </Box>
            <Box id='notes_attachments' ref={notesRef}>
              <NotesAttachMents poStatus={poStatus} mode={mode} />
            </Box>
            <Box id='supplier' ref={supplierRef}>
              <SupplierContact poStatus={poStatus} mode={mode} supplierData={poSupplierContact} />
            </Box>
            <Box sx={{height: 100}}></Box>
          </Box>

          {mode === Mode.EDIT && (
            <Box sx={{width: '100%', position: 'fixed', bottom: 0, zIndex: 2, left: 0}}>
              {alert && updatePO.message && (
                <Modal open={alert} onClose={handleAlertClose}>
                  <Box sx={style}>
                    <Typography
                      id='modal-modal-title'
                      variant='h6'
                      component='h2'
                      sx={{color: '#30d5c8 '}}
                    >
                      PO Updated Successful !
                    </Typography>
                    <Typography
                      id='modal-modal-description'
                      sx={{
                        color: 'grayscale.600',
                        fontFamily: 'Roboto Medium',
                        fontSize: '12px',
                        mt: 1.5
                      }}
                    >
                      {`Updated PO ID is : ${updatePO.purchaseOrderId}`}
                    </Typography>
                    <Typography
                      id='modal-modal-description'
                      sx={{
                        color: 'grayscale.600',
                        fontFamily: 'Roboto Medium',
                        fontSize: '12px',
                        mt: 1.5
                      }}
                    >
                      PO Updated and changes will be available in 5 minutes.
                    </Typography>
                  </Box>
                </Modal>
              )}
              {alert && updateError && (
                <Box sx={{height: 120}}>
                  <ErrorAlert updateError={updateError} handleClose={handleAlertClose} />
                </Box>
              )}
              <Stack
                direction={'row'}
                sx={{
                  bottom: 0,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'sticky',
                  bgcolor: 'common.white',
                  boxShadow: '2px 1px 2px 2px rgb(0 0 0 / 50%)'
                  // zIndex: 1200
                }}
              >
                <CustomButton
                  id={'poDetails-cancel-button'}
                  variant={'outlined'}
                  onClick={cancelHandler}
                  sx={{
                    my: 2,
                    ml: 25,
                    backgroundColor: theme.palette.neutral?.secondary2,
                    border: `1px solid ${theme.palette.neutral?.secondary2}`,
                    color: theme.palette.primary.main,
                    '&:hover': {
                      border: `1px solid ${theme.palette.neutral?.secondary2}`,
                      backgroundColor: theme.palette.neutral?.secondary2
                    }
                  }}
                >
                  <Typography variant='caption' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                    CANCEL
                  </Typography>
                </CustomButton>
                <CustomButton
                  id={'poDetails-save&send-update-button'}
                  variant={'contained'}
                  sx={{my: 2, mr: '204px'}}
                  onClick={handleUpdatePO}
                >
                  <Typography
                    variant='caption'
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Save & Send Update
                  </Typography>
                </CustomButton>
              </Stack>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default PODetail;
