import {createSlice} from '@reduxjs/toolkit';
export interface packState {
  loading: boolean;
  pack: Array<any>;
  error: string;
}

const initialState: packState = {
  loading: false,
  pack: [],
  error: ''
};

const packSlice = createSlice({
  name: 'pack',
  initialState,
  reducers: {
    packLoading: (state) => {
      state.loading = true;
    },
    packSuccess: (state, action) => {
      state.pack = action.payload;
      state.loading = false;
    },
    packFailure: (state) => {
      state.loading = false;
      state.pack = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default packSlice.reducer;
export const {packLoading, packSuccess, packFailure} = packSlice.actions;
