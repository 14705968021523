import {takeEvery, put, call} from 'redux-saga/effects';
import {PurchaseOrderAPIS} from 'redux/purchaseOrder/purchaseOrder_service';
import {
  generalInfoFailure,
  generalInfoLoading,
  generalInfoSuccess
} from 'redux/purchaseOrder/details/generalInfo/generalInfoSlice';

export const generalInfoSagaActions = {
  FETCH_GENERALINFO_DATA: 'FETCH_GENERALINFO_DATA'
};

export function* fetchGeneralInfoData(action: any): any {
  try {
    yield put(generalInfoLoading());
    const {data} = yield call(PurchaseOrderAPIS.getGeneralInfoData, action.payload);
    yield put(generalInfoSuccess(data));
  } catch (error) {
    yield put(generalInfoFailure());
  }
}

export default function* watchFetchGeneralInfoData() {
  yield takeEvery(generalInfoSagaActions.FETCH_GENERALINFO_DATA, fetchGeneralInfoData);
}
