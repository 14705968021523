import * as React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {generalInfoSagaActions} from 'redux/purchaseOrder/details/generalInfo/generalInfoSaga';
import moment from 'moment';
import {IGeneralInfo} from 'features/purchaseOrder/types';
import {userOption} from './generalInfoEdit';

// interface IGeneralInfoView {
//   poStatus: string;
//   supplierData?: Array<any>;
//   onValueChange?: Array<any>;
// }

const GeneralInfoView: React.FC<IGeneralInfo> = (props: IGeneralInfo) => {
  const mandatory = true;
  const location: any = useLocation();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch({
      type: generalInfoSagaActions.FETCH_GENERALINFO_DATA,
      payload: location.state?.poId
    });
  }, []);
  const {generalInfo} = useAppSelector((state) => state.generalInfoSlice);

  return (
    <Box
      sx={{
        px: 2,
        py: 3,
        bgcolor: 'grayscale.50',
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.5)'
      }}
    >
      <Stack sx={{pb: 3}}>
        <Typography variant='subtitle1' sx={{pb: 3}}>
          Basic Data
        </Typography>
        <Stack direction={'row'} sx={{gap: '1.5%'}}>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Purchasing Group (Buyer ID)
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            <Typography variant='body2'>{generalInfo?.buyerId}</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Created By (Buyer)
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            {/* <Typography variant='body2'>{generalInfo?.buyer}</Typography> */}
            <Typography variant='body2'>{userOption.map((element) => element.value)}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{pb: 3}}>
        <Typography variant='subtitle1' sx={{pb: 3}}>
          Delivery
        </Typography>
        <Stack direction={'row'} sx={{gap: '1.5%'}}>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Site (Warehouse/Store)
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            <Typography variant='body2'>{generalInfo?.warehouseId}</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Warehouse Section
            </Typography>
            <Typography variant='body2'>{/*generalInfo?.warehouseSection*/}F</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Freight Terms
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            {/* <Typography variant='body2'>{generalInfo?.freightTerm}</Typography> */}
            <Typography variant='body2'>Pickup</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              FOB/Orign
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            {/* <Typography variant='body2'>{generalInfo?.origin}</Typography> */}
            <Typography variant='body2'>Stockton CA 21</Typography>
          </Stack>
        </Stack>
        {/* <Stack direction={'row'} sx={{ pt: 2, gap: '1.5%' }}>
          <Stack sx={{ width: '300px' }}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Load Condition
              <Typography
                variant='caption'
                sx={{ fontSize: '12px', pl: 0.4, color: 'error.main' }}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            <Typography variant='body2'>{generalInfo?.loadCondition}</Typography>
          </Stack>
          <Stack sx={{ width: '300px' }}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Unloading
              <Typography
                variant='caption'
                sx={{ fontSize: '12px', pl: 0.4, color: 'error.main' }}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            <Typography variant='body2'>{generalInfo?.unloading}</Typography>
          </Stack>
          <Stack sx={{ width: '300px' }}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Delivery Condition
            </Typography>
            <Typography variant='body2'>{generalInfo?.deliveryCondition}</Typography>
          </Stack>
        </Stack> */}
        <Stack direction={'row'} sx={{pt: 2, gap: '1.5%'}}>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Must Arrive By
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            <Typography variant='body2'>
              {generalInfo.mustArriveBy}
              {/* {generalInfo.mustArriveBy
                ? moment(generalInfo.mustArriveBy, 'YYYY-MM-DD').add(3, 'd').format('YYYY-MM-DD')
                : generalInfo.mustArriveBy} */}
            </Typography>
          </Stack>
          {/* <Stack sx={{ width: '300px' }}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Appointment Date
            </Typography>
            <Typography variant='body2'>{generalInfo?.appointmentDate}</Typography>
          </Stack>
          <Stack sx={{ width: '300px' }}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Appointment Time
            </Typography>
            <Typography variant='body2'>{generalInfo?.appointmentTime}</Typography>
          </Stack> */}
          {/* <Stack sx={{ width: '300px' }}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              ASN Date
            </Typography>
            <Typography variant='body2'>{generalInfo?.asnDate}</Typography>
          </Stack> */}
        </Stack>
      </Stack>
      <Stack sx={{pb: 3}}>
        <Typography variant='subtitle1' sx={{pb: 3}}>
          Invoice
        </Typography>
        <Stack direction={'row'} sx={{gap: '1.5%'}}>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Payment Terms
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            {/* <Typography variant='body2'>{generalInfo?.paymentTerms}</Typography> */}
            <Typography variant='body2'>NET 30</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Invoicing Party
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            <Typography variant='body2'>{generalInfo?.invoicingParty}</Typography>
            <Typography variant='body2'>0000051346</Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} sx={{pt: 2, gap: '1.5%'}}>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Currency
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            <Typography variant='body2'>{generalInfo?.currency}</Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Fixed Exchange Rate
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            <Typography variant='body2'>
              {Number(generalInfo?.fixedExchangeRate)?.toFixed(2)}
            </Typography>
          </Stack>
          <Stack sx={{width: '300px'}}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Exchange Rate
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory && '*'}`}</Typography>
            </Typography>
            <Typography variant='body2'>{Number(generalInfo?.exchangeRate)?.toFixed(2)}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default GeneralInfoView;
