import * as React from 'react';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {Box, TableHead, TableRow, Typography} from '@mui/material';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {salesItemSagaActions} from 'redux/product/details/salesItem/salesItemSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {toFlatPropertyMap} from 'util/objectHelper';
import {districtSagaActions} from 'redux/dashboard/master/districtSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import {useLocation} from 'react-router-dom';
import {useTheme} from '@mui/material';
import moment from 'moment';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';

interface IProductSalesInnerGridProps extends IProductSalesGridProps {
  totalSales: {units: string; dollars: string};
}
interface IProductSalesGridProps {
  weekBeginning: string;
  uom: string;
  averageRetail: string;
  averageCost: string;
  averageWeeklySales: string;
  actualMargin: string;
  averageMargin: string;
  actualMarginPerStore: string;
  averageSoldPerStore: string;
  stores: string;
}

interface IProductSalesGrid extends IProductSalesGridProps {
  'totalSales.units': string;
  'totalSales.dollars': string;
  id: string;
}

const salesColumnCell: IColumnCell<IProductSalesGrid>[] = [
  {
    id: 'weekBeginning',
    name: 'weekBeginning',
    displayName: 'WEEK BEGINNING',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    cellTextAlign: 'center'
  },
  {
    id: 'uom',
    name: 'uom',
    displayName: 'UOM',
    isColumnSortable: false,
    isGroupBorderRequire: true
  },
  {
    id: 'averageCost',
    name: 'Average Cost',
    displayName: 'AVG COST',
    isGroupBorderRequire: false,
    prefixText: '$',
    consolidateType: 'SUM',
    isNumeric: true,
    cellTextAlign: 'center'
  },
  {
    id: 'averageRetail',
    name: 'Average Retail',
    displayName: 'AVG RETAIL',
    isNumeric: true,
    prefixText: '$',
    consolidateType: 'AVG',
    isGroupBorderRequire: false,
    cellTextAlign: 'center'
  },
  {
    id: 'averageMargin',
    name: 'Average Margin',
    displayName: 'AVG MGN',
    suffixText: '%',
    consolidateType: 'AVG',
    isGroupBorderRequire: false,
    isNumeric: true,
    cellTextAlign: 'center'
  },
  {
    id: 'averageWeeklySales',
    name: 'Average Weekly Sales',
    displayName: 'AWS',
    prefixText: '$',
    consolidateType: 'AVG',
    isNumeric: true,
    isGroupBorderRequire: true,
    cellTextAlign: 'center'
  },
  {
    id: 'totalSales.units',
    name: 'TotalSales',
    displayName: 'total Sales',
    isGroupBorderRequire: true,
    // prefixText: '$',
    isNumeric: true,
    isGroupedCell: true,
    consolidateType: 'SUM',
    groupedKeys: ['totalSales.units', 'totalSales.dollars'],
    groupSortKey: 'totalSales.dollars',
    groupDisplayKeys: ['units', 'dollars'],
    sx: {
      textAlign: 'center'
    }
  },
  {
    id: 'actualMargin',
    name: 'Actual Margin',
    isNumeric: true,
    displayName: 'ACT MGN',
    headerTextAlign: 'center',
    cellTextAlign: 'center',
    suffixText: '%',
    consolidateType: 'AVG',
    isGroupBorderRequire: true
  },
  {
    id: 'actualMarginPerStore',
    name: 'Actual Margin Per Store',
    isNumeric: true,
    displayName: 'ACT MGN/STR',
    headerTextAlign: 'center',
    prefixText: '$',
    consolidateType: 'AVG',
    cellTextAlign: 'center'
  },
  {
    id: 'averageSoldPerStore',
    name: 'Average Sold Per Store',
    isNumeric: true,
    displayName: 'AVG SOLD/STR',
    headerTextAlign: 'center',
    consolidateType: 'AVG',
    cellTextAlign: 'center'
  },
  {
    id: 'stores',
    name: 'Stores',
    isNumeric: true,
    displayName: 'STORES',
    headerTextAlign: 'center',
    consolidateType: 'AVG',
    cellTextAlign: 'center'
  }
];

interface ISalesListGridProps {
  productID: string;
  atpEnabler?: (val: boolean) => void;
}

const SalesListGrid: React.FC<ISalesListGridProps> = (props) => {
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const theme: any = useTheme();

  const StickySalesHeaderCell = (props: any) => {
    const consolidatedData = generateStickyHeaderValues(props.data, props.salesColumnCell);
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'Background',
                zIndex: theme.zIndex?.appBar + 1,
                paddingRight: 0
              }}
            >
              <Typography variant='subtitle2' sx={{color: 'grayscale.400', borderBottom: 0}}>
                CONSOLIDATED STATS
              </Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell align='center'></BorderedTableStickyCell>
            <TableStickyCell align='center'>
              <Typography variant='subtitle2'>{consolidatedData.averageCost}</Typography>
            </TableStickyCell>
            <TableStickyCell align='center'>
              <Typography variant='subtitle2'>{consolidatedData.averageRetail}</Typography>
            </TableStickyCell>
            <TableStickyCell align='center'>
              <Typography variant='subtitle2'>{consolidatedData.averageMargin}</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='center'>
              <Typography variant='subtitle2'>{consolidatedData.averageWeeklySales}</Typography>
            </BorderedTableStickyCell>
            <TableStickyCell align='center'>
              <Typography variant='subtitle2'>
                {consolidatedData['totalSales.units'] ? consolidatedData['totalSales.units'] : ''}
              </Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='center'>
              <Typography variant='subtitle2'>
                $
                {consolidatedData['totalSales.dollars']
                  ? consolidatedData['totalSales.dollars']
                  : '0'}
              </Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell align='center'>
              <Typography variant='subtitle2'>{consolidatedData.actualMargin}</Typography>
            </BorderedTableStickyCell>
            <TableStickyCell align='center'>
              <Typography variant='subtitle2'>{consolidatedData.actualMarginPerStore}</Typography>
            </TableStickyCell>
            <TableStickyCell align='center'>
              <Typography variant='subtitle2'>{consolidatedData.averageSoldPerStore}</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='center'>
              <Typography variant='subtitle2'>
                {consolidatedData.stores ? consolidatedData.stores : 0}
              </Typography>
            </BorderedTableStickyCell>
          </TableRow>
        </TableHead>
      </>
    );
  };

  const {salesItem, loading} = useAppSelector((state) => state.salesItemSlice);
  React.useEffect(() => {
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
  }, []);
  const {district} = useAppSelector((state) => state.districtSlice);
  const {region} = useAppSelector((state) => state.regionSlice);
  const {store} = useAppSelector((state) => state.storeSlice);
  const [listData, setListData] = React.useState(salesItem);
  const defaultDateFormat = 'MM/DD/YYYY';

  //To-do- Remove this once demo done
  //const startDate = moment().startOf('week').format(defaultDateFormat);
  const startDate = '01/01/2023';
  const endDate = moment().format(defaultDateFormat);
  React.useEffect(() => {
    setListData(salesItem);
  }, [salesItem]);
  const generatePayload = (values: IFilterValues) => {
    const temp: any = [];
    for (const [key, value] of Object.entries(values)) {
      if (value && value != '' && value.toString().substring(0, 4).toLowerCase() != 'all ') {
        if (key === 'date') {
          temp.push({key: key, value: value, condition: 'BETWEEN'});
        } else {
          temp.push({key: key, value: value, condition: 'EQUAL'});
        }
      }
    }
    return temp;
  };

  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };

  const formateRowData = (listData: IProductSalesInnerGridProps[]) => {
    const gridData: IProductSalesGrid[] = listData.map((element) => {
      const nestedObject: IProductSalesGrid = toFlatPropertyMap(
        element
      ) as unknown as IProductSalesGrid;
      nestedObject['totalSales.dollars'] = `${
        nestedObject['totalSales.dollars'] !== null ? `$${nestedObject['totalSales.dollars']}` : ''
      }`;
      return nestedObject;
    });
    return gridData;
  };
  const rowData: IProductSalesGrid[] = listData.length > 0 ? formateRowData(listData) : [];
  const salesfilterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date',
      format: 'MM/DD/YYYY',
      defaultValue: `${startDate}|${endDate}`,
      sx: {
        width: '210px'
      },
      options: [
        {
          label: 'Last 6 months',
          value: 'Last 6 months'
        },
        {
          label: 'Last 4 weeks',
          value: 'Last 4 weeks'
        },
        {
          label: 'Last quarter',
          value: 'Last quarter'
        }
      ]
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'regionName',
      title: 'Region',
      defaultValue: 'All Regions',
      placeholder: 'Regions',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      options: generateLabelValue(region, 'regionName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'districtName',
      title: 'Districts',
      defaultValue: 'All Districts',
      placeholder: 'Districts',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      options: generateLabelValue(district, 'districtName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeName',
      title: 'Store',
      defaultValue: 'All Stores',
      placeholder: 'Stores',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      options: generateLabelValue(store, 'storeName')
    }
  ];

  const defaultData = salesfilterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = React.useState(defaultData);
  /**
   * dispatch product details sales item data
   */
  const dispatchData = () => {
    dispatch({
      type: salesItemSagaActions.FETCH_SALESITEM_DATA,
      payload: {
        id: props.productID,
        data: generatePayload(filtervalues)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);
  if (salesItem && salesItem.length > 0) {
    return (
      <>
        <GridFilter
          values={filtervalues}
          onFilterChange={onFilterApply}
          filterTemplate={salesfilterTemplate}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-details-salesList'
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='product-details-sales-tab' />
          </Box>
        )}
        <EnhancedTable<IProductSalesGrid>
          rowData={rowData}
          columnCell={salesColumnCell}
          rowKey={'weekBeginning'}
          defaultSortKey={'weekBeginning'}
          stickyRows={<StickySalesHeaderCell data={rowData} salesColumnCell={salesColumnCell} />}
          rowsPerPage={9}
          id='product-details-salesList'
        />
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          values={filtervalues}
          onFilterChange={onFilterApply}
          filterTemplate={salesfilterTemplate}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-details-salesList'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='product-details-sales-tab' />
          ) : (
            <CustomError
              id='product-list-error'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      </>
    );
  }
};

export default SalesListGrid;
