import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {packLoading, packFailure, packSuccess} from 'redux/dashboard/master/packSlice';

export const packSagaActions = {
  FETCH_PACK_DATA: 'FETCH_PACK_DATA'
};

export function* fetchPackData(): any {
  try {
    yield put(packLoading());
    const {data} = yield call(ProductAPIS.getPackData);
    yield put(packSuccess(data));
  } catch (error) {
    yield put(packFailure());
  }
}

export default function* watchFetchPackData() {
  yield takeEvery(packSagaActions.FETCH_PACK_DATA, fetchPackData);
}
