import {TableHead, TableRow, Typography, Box, Tooltip} from '@mui/material';
import React from 'react';
import {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {expiryProductListSagaActions} from 'redux/product/list/expiryList/expiryProductListSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import {tagSagaActions} from 'redux/dashboard/master/tagSaga';
import {districtSagaActions} from 'redux/dashboard/master/districtSaga';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {sizeSagaActions} from 'redux/dashboard/master/sizeSaga';
import {packSagaActions} from 'redux/dashboard/master/packSaga';
import {alertSagaActions} from 'redux/dashboard/master/alertSaga';
import {viewInFilterOptions} from 'config/constants';
import {vendorSagaActions} from 'redux/dashboard/master/vendorSaga';
import {warehouseSagaActions} from 'redux/dashboard/master/warehouseSaga';
import {useTheme} from '@mui/material';
interface IPropType {
  atpEnabler?: (val: boolean) => void;
}

interface IProductExpiryGridProps {
  itemId: string;
  itemName: string;
  pack: string;
  size: string;
  wh: string;
  lessThan7: string;
  lessThan15: string;
  between15to30: string;
  between31to45: string;
  between46to60: string;
  hasAlert?: boolean;
  tags?: Array<string>;
  id: string;
}

const LinkComponent = (row: any) => {
  return (
    <>
      <Link to={{pathname: `/Product/${row.itemId}`}} state={row}>
        {row.itemId}
      </Link>{' '}
    </>
  );
};

const ContentComponent = (row: any) => {
  if (row.itemName.length > 20)
    return (
      <Tooltip title={row.itemName} placement='top'>
        <Box
          sx={{
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box'
          }}
        >
          {row.itemName}
        </Box>
      </Tooltip>
    );
  else {
    return <Box sx={{maxWidth: '100px'}}>{row.itemName}</Box>;
  }
};

const columnCell: IColumnCell<IProductExpiryGridProps>[] = [
  {
    id: 'itemId',
    name: 'itemId',
    displayName: 'Item Id',
    customCell: LinkComponent,
    headerTextAlign: 'center',
    sticky: true,
    sx: {
      minWidth: '80px'
    }
  },
  {
    id: 'itemName',
    name: 'itemName',
    displayName: 'Item',
    sticky: true,
    hasTags: true,
    customCell: ContentComponent,
    headerTextAlign: 'left',
    cellTextAlign: 'left',
    sx: {
      minWidth: '200px'
    }
  },
  {
    id: 'pack',
    name: 'pack',
    isColumnSortable: false,
    sticky: true,
    headerTextAlign: 'left',
    cellTextAlign: 'right',
    sx: {
      minWidth: '50px'
    }
  },
  {
    id: 'size',
    name: 'size',
    isNumeric: true,
    isGroupBorderRequire: true,
    isColumnSortable: false,
    sticky: true,
    headerTextAlign: 'right',
    cellTextAlign: 'right',
    sx: {
      minWidth: '60px'
    }
  },
  {
    id: 'wh',
    name: 'wh',
    displayName: 'WH',
    isNumeric: true,
    isGroupBorderRequire: true,
    sx: {
      minWidth: '70px',
      textAlign: 'right'
    }
  },
  {
    id: 'lessThan7',
    name: 'lessThan7',
    displayName: '<7 day expiry',
    isNumeric: true,
    consolidateType: 'SUM',
    prefixText: '$',
    sx: {
      minWidth: '140px',
      textAlign: 'right'
    }
  },
  {
    id: 'lessThan15',
    name: 'lessThan15',
    displayName: '<15 day expiry',
    isNumeric: true,
    consolidateType: 'SUM',
    prefixText: '$',
    sx: {
      minWidth: '140px',
      textAlign: 'right'
    }
  },
  {
    id: 'between15to30',
    name: 'between15to30',
    displayName: '15 - 30 day expiry',
    isNumeric: true,
    consolidateType: 'SUM',
    prefixText: '$',
    sx: {
      minWidth: '140px',
      textAlign: 'right'
    }
  },
  {
    id: 'between31to45',
    name: 'between31to45',
    displayName: '31 - 45 day expiry',
    isNumeric: true,
    consolidateType: 'SUM',
    prefixText: '$',
    sx: {
      minWidth: '140px',
      textAlign: 'right'
    }
  },
  {
    id: 'between46to60',
    name: 'between46to60',
    displayName: '46 - 60 day expiry',
    isNumeric: true,
    isGroupBorderRequire: true,
    consolidateType: 'SUM',
    prefixText: '$',
    sx: {
      minWidth: '140px',
      textAlign: 'right'
    }
  }
];

const ExpiryList: React.FC<IPropType> = (props) => {
  const location: any = useLocation();
  const theme: any = useTheme();

  const StickyHeaderCell = (props: any) => {
    const consolidatedData = generateStickyHeaderValues(props.data, columnCell);
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'Background',
                zIndex: theme.zIndex?.appBar + 20,
                top: '64px'
              }}
            >
              <TableStickyCell align='right' sx={{top: '64px'}}></TableStickyCell>
              <TableStickyCell
                colSpan={4}
                sx={{
                  color: 'grayscale.400',
                  borderBottom: 0,
                  fontWeight: 'bold'
                }}
              >
                <Typography variant='subtitle2' sx={{paddingLeft: '8px'}}>
                  CONSOLIDATED STATS
                </Typography>
              </TableStickyCell>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell colSpan={1} sx={{top: '64px'}} align='right'>
              <Typography variant='subtitle2'>-</Typography>
            </BorderedTableStickyCell>
            <TableStickyCell colSpan={1} sx={{top: '64px'}} align='right'>
              <Typography variant='subtitle2'>{consolidatedData.lessThan7}</Typography>
            </TableStickyCell>
            <TableStickyCell colSpan={1} sx={{top: '64px'}} align='right'>
              <Typography variant='subtitle2'>{consolidatedData.lessThan15}</Typography>
            </TableStickyCell>
            <TableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>{consolidatedData.between15to30}</Typography>
            </TableStickyCell>
            <TableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>{consolidatedData.between31to45}</Typography>
            </TableStickyCell>
            <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'>{consolidatedData.between46to60}</Typography>
            </BorderedTableStickyCell>
          </TableRow>
        </TableHead>
      </>
    );
  };
  const getRowIds = (ids: readonly string[]) => {
    //type should be same as selected state in enhanced table
    props.atpEnabler && props.atpEnabler(!(ids.length > 0));
  };
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const {expiryProductList, error, loading} = useAppSelector(
    (state) => state.expiryProductListSlice
  );
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      if (key === 'wareHouse') {
        temp.push({key: key, value: JSON.stringify(value), condition: true});
      } else {
        temp.push({key: key, value: value, condition: true});
      }
    }
    return temp;
  };
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };
  const rowData: IProductExpiryGridProps[] = expiryProductList.length > 0 ? expiryProductList : [];
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: tagSagaActions.FETCH_TAG_DATA
    });
    dispatch({
      type: vendorSagaActions.FETCH_VENDOR_DATA
    });
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: sizeSagaActions.FETCH_SIZE_DATA
    });
    dispatch({
      type: packSagaActions.FETCH_PACK_DATA
    });
    dispatch({
      type: alertSagaActions.FETCH_ALERT_DATA
    });
    dispatch({
      type: warehouseSagaActions.FETCH_WAREHOUSE_DATA
    });
  }, []);
  const {master} = useAppSelector((state) => state.masterSlice);

  const [dataOne, dataTwo, dataThree] = master;
  const {vendor} = useAppSelector((state) => state.vendorSlice);
  const {size} = useAppSelector((state) => state.sizeSlice);
  const {pack} = useAppSelector((state) => state.packSlice);
  const {alert} = useAppSelector((state) => state.alertSlice);
  const {warehouse} = useAppSelector((state) => state.warehouseSlice);
  //For Binding the MasterTag data
  const {tag} = useAppSelector((state) => state.tagSlice);

  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.MULTISELECT,
      property: 'wareHouse',
      title: 'Warehouse',
      defaultValue: [],
      sx: {
        width: '150px'
      },
      options: generateLabelValue(warehouse, 'warehouseID')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View In (Expiry Levels)',
      defaultValue: 'Units',
      sx: {
        width: '150px'
      },
      options: viewInFilterOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      defaultValue: 'All Departments',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'departmentName',
      //should match to dependent filter to include similar value referenced in this filter
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'expiryLevel',
      title: 'Has Expiry Level',
      defaultValue: 'All Levels',
      placeholder: 'Levels',
      sx: {
        width: '150px'
      },
      options: [
        {
          label: '<7 Day Expiry',
          value: '<7 Day Expiry'
        },
        {
          label: '<15 Day Expiry',
          value: '<15 Day Expiry'
        },
        {
          label: '15 - 30 Day Expiry',
          value: '15 - 30 Day Expiry'
        },
        {
          label: '31 - 45 Day Expiry',
          value: '31 - 45 Day Expiry'
        },
        {
          label: '46 - 60 Day Expiry',
          value: '46 - 60 Day Expiry'
        },
        {
          label: 'All Levels',
          value: 'All Levels'
        }
      ]
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'tag',
      title: 'Tag',
      defaultValue: 'All Tags',
      placeholder: 'Tags',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(tag, 'tag')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'vendor',
      title: 'Vendor',
      defaultValue: 'All Vendors',
      placeholder: 'Vendors',
      mode: 'Secondary',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(vendor, 'vendorName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'alert',
      title: 'Alert',
      defaultValue: 'All Alerts',
      placeholder: 'Alerts',
      mode: 'Secondary',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(alert, 'alert')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'pack',
      title: 'Pack',
      defaultValue: 'All Pack',
      placeholder: 'Pack',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(pack, 'pack')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'size',
      title: 'Size',
      defaultValue: 'All Size',
      placeholder: 'Size',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(size, 'size')
    }
  ];

  const defaultData: any = filterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);
  /**
   * dispatch productList Expiry data
   */
  const dispatchData = () => {
    dispatch({
      type: expiryProductListSagaActions.FETCH_EXPIRYPRODUCTLIST_DATA,
      payload: generatePayload(filtervalues)
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);

  if (expiryProductList && expiryProductList.length > 0 && !error) {
    return (
      <>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-expiryList'
        />
        <EnhancedTable<IProductExpiryGridProps>
          height={844}
          rowData={rowData}
          stickyRows={<StickyHeaderCell data={rowData} columnCell={columnCell} />}
          columnCell={columnCell}
          rowKey={'itemId'}
          defaultSortKey={'itemId'}
          showTags={true}
          componentType='paper'
          allowAlternateColor={true}
          getSelectedIds={getRowIds}
          rowsPerPage={25}
          selectable
          showAlertPopup
          id='productList-expiryTab'
        />
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-expiryList'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='expiry-list-load' />
          ) : (
            <CustomError
              id='product-expiry-load'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      </>
    );
  }
};

export default ExpiryList;
