import {Box, Grid, styled} from '@mui/material';
import React from 'react';
import BarGraphComponent, {IAxis} from 'components/barGraph';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import {CustomCard} from 'components/baseComponents/customCard';
import {useAppDispatch, useAppSelector} from '../../store/configureStore';
import {laborSagaActions} from 'redux/dashboard/labor/laborSaga';
import {useTheme} from '@mui/material/styles';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {generatePayload} from 'util/arrayOperations';
import {DepartmentLaborPageUrl, StoreLaborPageUrl} from 'config/constants';

const dateDropDownData = [
  {
    label: 'Yesterday',
    value: 'Yesterday'
  },
  {
    label: 'Week to Date',
    value: 'weekToDate'
  },
  {
    label: 'Last Week',
    value: 'lastWeek'
  },
  {
    label: 'Last 4 Weeks',
    value: 'last4Weeks'
  },
  {
    label: 'Month-to-Date',
    value: 'monthToDate'
  },
  {
    label: 'Quarter-to-Date',
    value: 'quarterToDate'
  },
  {
    label: 'Year-to-Date',
    value: 'yearToDate'
  }
];

const CustomBox = styled(Box)({
  '& div.user-select-none': {
    left: '-2px'
  },
  '& .MuiPaper-root .MuiCardContent-root .MuiBox-root:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

interface IPropType {
  defaultValue: string;
  filteredData: any;
}

const dropdownData = [
  {
    label: 'Department Labor',
    value: 'department'
  },
  {
    label: 'Store Labor',
    value: 'store'
  }
];

const LaborTile: React.FC<IPropType> = (props) => {
  const [laborDate, setLaborDate] = React.useState('weekToDate');
  const [laborType, setLaborType] = React.useState(props.defaultValue);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  /**
   * dispatch labore tile data
   */
  const dispatchData = () => {
    dispatch({
      type: laborSagaActions.FETCH_LABOR_DATA_SAGA,
      payload: {
        type: laborType,
        laborDate,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [laborDate, laborType, props?.filteredData]);
  const {labor, loading, error} = useAppSelector((state) => state.laborSlice);

  const handleSelect = (event: any): void => {
    dispatch({
      type: laborSagaActions.FETCH_LABOR_DATA_SAGA,
      payload: {
        ...props?.filteredData,
        type: event?.target.value
      }
    });
    setLaborType(event?.target.value);
  };
  const handleDateSelect = (event: any): void => {
    setLaborDate(event?.target.value);
  };

  const generateContent = () => {
    switch (laborType) {
      case 'department':
        return (
          <BarGraphComponent
            data={labor}
            xAxis={[...xAxis]}
            yAxis={yAxis}
            showPlan={true}
            plan={planAxis}
            id='departmentLaborCompBar'
            redirectUrl={DepartmentLaborPageUrl}
            externalUrl={true}
          />
        );
      case 'store':
        return (
          <BarGraphComponent
            data={labor}
            xAxis={[...xAxis]}
            yAxis={yAxis}
            showPlan={true}
            plan={planAxis}
            id='storeLaborCompBar'
            redirectUrl={StoreLaborPageUrl}
            externalUrl={true}
          />
        );
      default:
        return (
          <BarGraphComponent
            data={labor}
            xAxis={[...xAxis]}
            yAxis={yAxis}
            showPlan={true}
            plan={planAxis}
            id='departmentLaborCompBar'
            redirectUrl={DepartmentLaborPageUrl}
            externalUrl={true}
          />
        );
    }
  };
  const xAxis: Array<IAxis> = [
    {
      name: 'actual',
      displayTitle: 'actual',
      color: theme.palette.primary.dark,
      unit: 'K',
      prefix: '$'
    },
    {
      name: 'scheduled',
      displayTitle: 'scheduled',
      color: theme.palette.neutral?.secondary2,
      unit: 'K',
      prefix: '$'
    }
  ];

  const yAxis: IAxis = {
    name: laborType === 'department' ? 'departmentName' : 'storeName',
    displayTitle: 'name',
    unit: 'K',
    prefix: '$'
  };

  const planAxis: IAxis = {
    name: 'budget',
    displayTitle: 'budget',
    color: theme.palette.neutral?.secondary1,
    unit: 'K',
    prefix: '$'
  };

  const Header = () => {
    return (
      <Grid container>
        <DropDownWrapper>
          <CustomSelect
            id='departmentCompTile'
            name={'departmentCompTileSelect'}
            options={dropdownData}
            onChange={handleSelect}
            value={laborType}
          />
          <CustomSelect
            id='departmentDateCompTile'
            name={'departmentDateCompTileSelect'}
            options={dateDropDownData}
            onChange={handleDateSelect}
            value={laborDate}
            title={'Date Range'}
          />
        </DropDownWrapper>
      </Grid>
    );
  };

  const Content = () => {
    return <CustomBox>{generateContent()}</CustomBox>;
  };

  if (labor && labor.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='labor-cardData'
          header={<Header />}
          content={loading ? <CustomLoader id='labor-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='labor-card-withoutData'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='labor-load' />
            ) : (
              <CustomError
                id='labor-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default LaborTile;
