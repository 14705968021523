import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Box, Card, CardContent, Stack, styled, Button} from '@mui/material';
import CustomInput from 'components/baseComponents/customInput';
import {jwtToken, setLocalStorage} from 'util/tokenizer';
import {useNavigate} from 'react-router-dom';
import {ROLE} from 'config/constants';
import moment from 'moment';

export const CustomCardContent = styled(CardContent)({
  padding: 0,
  maxHeight: '264px',
  display: 'flex',
  justifyContent: 'flex-start'
});
interface IUserData {
  username: string;
  password: string;
}
export default function Login() {
  const [userData, setUserData] = React.useState<IUserData>({} as IUserData);
  const [message, setMessage] = React.useState<string>('');
  const navigate = useNavigate();

  const handleSubmit = (e: React.MouseEvent<unknown>) => {
    e?.preventDefault();
    const d = moment.now();
    let userObj;
    if (userData.username && userData.password) {
      switch (userData.username.toLowerCase()) {
        case 'buyer@pwc.com':
          userObj = {
            dateCreated: d,
            role: ROLE.BUYER,
            username: 'Joseph Smith',
            email: userData.username
          };
          break;
        case 'districtmanager@pwc.com':
          userObj = {
            dateCreated: d,
            role: ROLE.DISTRICTMANAGER,
            username: 'Amiya',
            email: userData.username
          };
          break;
        case 'asstbuyer@pwc.com':
          userObj = {
            dateCreated: d,
            role: ROLE.ASSTBUYER,
            username: 'Rajkumar',
            email: userData.username
          };
          break;
        case 'storemanager@pwc.com':
          userObj = {
            dateCreated: d,
            role: ROLE.STOREMANAGER,
            username: 'Mohan',
            email: userData.username
          };
          break;
        case 'executive@pwc.com':
          userObj = {
            dateCreated: d,
            role: ROLE.EXECUTIVE,
            username: 'Erik',
            email: userData.username
          };
          break;
        case 'buyerasstbuyerstoremanager@pwc.com':
          userObj = {
            dateCreated: d,
            role: ROLE.ASSTBUYERSTOREMANAGER,
            username: 'Erik',
            email: userData.username
          };
          break;
        case 'buyerasstbuyerstoredistrictmanager@pwc.com':
          userObj = {
            dateCreated: d,
            role: ROLE.ASSTBUYERSTOREDISRICTMANAGER,
            username: 'Erik',
            email: userData.username
          };
          break;
        case 'buyerasstbuyerstoredistrictmanagerexecutive@pwc.com':
          userObj = {
            dateCreated: d,
            role: ROLE.ASSTBUYERSTOREDISTRICTMANAGEREXECUTIVE,
            username: 'Erik',
            email: userData.username
          };
          break;
      }
      if (userObj?.role) {
        const token = jwtToken(userObj);
        if (token) {
          setLocalStorage('user_token', token);
          setLocalStorage('isLoggedIn', true);
          localStorage.setItem('role', userObj.role);
          navigate('/Dashboard', {state: token});
        } else {
          invalidUser();
        }
      } else {
        invalidUser();
      }
    } else {
      invalidUser();
    }
  };

  const invalidUser = () => {
    setUserData({username: '', password: ''});
    setMessage('User not exists');
  };

  const handleUserData = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (message.length) {
      setMessage('');
    }
    setUserData({...userData, [event?.target.name]: event?.target.value});
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30vh',
        height: '100vh'
      }}
    >
      <Card
        sx={{
          width: '360px',
          height: '220px',
          p: 3
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 0,
            pl: 1
          }}
        >
          <Stack direction={'column'} mb={3}>
            <Typography variant='subtitle2'>User Name</Typography>
            <CustomInput
              id='login-userName'
              sx={{width: '350px'}}
              name='username'
              autoComplete='off'
              value={userData.username}
              type='text'
              onChange={handleUserData}
              required
            />
          </Stack>
          <Stack direction={'column'} mb={3}>
            <Typography variant='subtitle2'>Password</Typography>
            <CustomInput
              id='login-password'
              sx={{width: '350px'}}
              name='password'
              value={userData.password}
              type='password'
              onChange={handleUserData}
              required
            />
          </Stack>
          <Button
            onClick={handleSubmit}
            variant='contained'
            sx={{
              width: '350px',
              textTransform: 'uppercase'
            }}
            disabled={userData.username && userData.password ? false : true}
          >
            Sign In
          </Button>
        </CardContent>

        {message}
      </Card>
    </Box>
  );
}
