/* eslint-disable react/prop-types */
import {Box, SxProps, Theme} from '@mui/material';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index?: number;
  value: number;
  customSx?: SxProps<Theme>;
  id?: string;
  ref?: any;
}

const CustomTabPanel: React.FC<TabPanelProps> = (props) => {
  const {children, value, index, customSx, id, ref, ...other} = props;

  return (
    <>
      {value === index && (
        <div
          role='tabpanel'
          hidden={value !== index}
          id={id ? id : `simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          ref={ref}
          {...other}
        >
          <Box sx={{px: 0, py: 2, ...customSx}}>
            <Typography>{children}</Typography>
          </Box>
        </div>
      )}
    </>
  );
};

export default CustomTabPanel;
