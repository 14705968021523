import {Box, Button, Stack, Typography} from '@mui/material';
import * as React from 'react';
import CustomInput from 'components/baseComponents/customInput';
import {useTheme} from '@mui/material';

interface IPropType {
  id: string;
  title: string;
  name: string;
  value: string;
  onChange: (event: any) => void;
  min?: number;
  max?: number;
}

const CustomRangeTextBox: React.FC<IPropType> = (props) => {
  const [minValue, setMinValue] = React.useState<number | null>(props.min ? props.min : null);
  const [maxValue, setMaxValue] = React.useState<number | null>(props.max ? props.max : null);
  const [submittedMinValue, setSubmittedMinValue] = React.useState<number | null>(
    props.min ? props.min : null
  );
  const [submittedMaxValue, setSubmittedMaxValue] = React.useState<number | null>(
    props.max ? props.max : null
  );
  const theme = useTheme();
  React.useEffect(() => {
    try {
      const value = props.value || null;
      const minValue = value?.split('|')[0] || null;
      const maxValue = value?.split('|')[1] || null;
      if (minValue != null && maxValue != null) {
        setMinValue(Number(minValue));
        setMaxValue(Number(maxValue));
        setSubmittedMinValue(Number(minValue));
        setSubmittedMaxValue(Number(maxValue));
      } else {
        setMinValue(null);
        setMaxValue(null);
        setSubmittedMinValue(null);
        setSubmittedMaxValue(null);
      }
    } catch (e) {
      return e;
    }
  }, [props.value]);

  const onSubmit = () => {
    if (minValue != null && maxValue != null) {
      const target = {
        name: props.name,
        value: minValue.toString() + '|' + maxValue.toString()
      };
      props.onChange({target});
    }
  };
  const onClear = () => {
    const target = {
      name: props.name,
      value: ''
    };
    props.onChange({target});
  };
  return (
    <Box>
      {props.title && (
        <Typography
          sx={{
            p: 0,
            m: 0,
            fontWeight: 'bold',
            color: theme.palette.grayscale?.[600],
            fontSize: '12px'
          }}
          textAlign='left'
          id={`customRange-label-text${props.id}`}
        >
          {props.title}
        </Typography>
      )}
      <Stack direction={'row'}>
        <Box>
          <CustomInput
            id={`custom-input-${props.id}-min`}
            sx={{width: '76px'}}
            name='Min'
            value={minValue != null ? minValue : ''}
            type='number'
            onChange={(e) => {
              e.preventDefault();
              setMinValue(e?.target.value ? Number(e?.target.value) : null);
            }}
            placeholder=' $ Min'
          />
        </Box>
        <Box sx={{alignSelf: 'center'}}>
          <Typography variant='body2' px={1}>
            to
          </Typography>
        </Box>
        <Box>
          <CustomInput
            id={`custom-input-${props.id}-max`}
            key={`custom-input-${props.id}-max`}
            sx={{width: '76px'}}
            name='Max'
            value={maxValue != null ? maxValue : ''}
            type='number'
            onChange={(e) => setMaxValue(e?.target.value ? Number(e?.target.value) : null)}
            placeholder=' $ Max'
          />
        </Box>
        <Box>
          {submittedMinValue === minValue &&
          submittedMaxValue === maxValue &&
          minValue != null &&
          maxValue != null ? (
            <Button id={`product-overview-button-clear${props.id}`} onClick={onClear}>
              Clear
            </Button>
          ) : (
            <Button
              id={`product-overview-button-${props.id}`}
              onClick={onSubmit}
              disabled={!(minValue != null && maxValue != null && minValue <= maxValue)}
            >
              Go
            </Button>
          )}
        </Box>
      </Stack>
      {minValue != null && maxValue != null && minValue > maxValue ? (
        <Box sx={{color: 'red', fontSize: '12px'}}> Min value shoud be smaller than Max value </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
};
export default CustomRangeTextBox;
