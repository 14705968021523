// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Roboto/Roboto-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Roboto/Roboto-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Roboto/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Roboto/Roboto-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Roboto Light";
  src: local("RobotoLight"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Roboto Medium";
  src: local("RobotoMedium"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Roboto Regular";
  src: local("RobotoRegular"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Roboto Bold";
  src: local("RobotoBold"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
.mblock {
  margin: 0px;
}

.masonry {
  width: 1536px;
}

.MuiTable-root tr,
.MuiTable-root th,
.MuiTable-root td {
  text-align: left !important;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,qFAAA;AACF;AAEA;EACE,4BAAA;EACA,sFAAA;AAAF;AAGA;EACE,6BAAA;EACA,uFAAA;AADF;AAIA;EACE,0BAAA;EACA,oFAAA;AAFF;AAKA;EACE,WAAA;AAHF;;AAKA;EACE,aAAA;AAFF;;AAME;;;EAGE,2BAAA;AAHJ","sourcesContent":["@font-face {\r\n  font-family: 'Roboto Light';\r\n  src: local('RobotoLight'), url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Roboto Medium';\r\n  src: local('RobotoMedium'), url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Roboto Regular';\r\n  src: local('RobotoRegular'), url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Roboto Bold';\r\n  src: local('RobotoBold'), url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');\r\n}\r\n\r\n.mblock {\r\n  margin: 0px;\r\n}\r\n.masonry {\r\n  width: 1536px;\r\n}\r\n\r\n.MuiTable-root {\r\n  tr,\r\n  th,\r\n  td {\r\n    text-align: left !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
