import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {masterLoading, masterFailure, masterSuccess} from 'redux/dashboard/master/masterSlice';

export const masterSagaActions = {
  FETCH_MASTER_DATA: 'FETCH_MASTER_DATA'
};

export function* fetchMasterData(): any {
  try {
    yield put(masterLoading());
    const [...data] = yield call(ProductAPIS.getMasterData);
    yield put(masterSuccess(data));
  } catch (error) {
    yield put(masterFailure());
  }
}

export default function* watchFetchMasterData() {
  yield takeEvery(masterSagaActions.FETCH_MASTER_DATA, fetchMasterData);
}
