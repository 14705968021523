import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';

import {
  supplierContactLoading,
  supplierContactFailure,
  supplierContactSuccess
} from 'redux/dashboard/master/supllierContactSlice';

export const supplierContactSagaActions = {
  FETCH_SUPPLIERCONTACT_TYPE_DATA: 'FETCH_SUPPLIERCONTACT_TYPE_DATA'
};

export function* fetchSupplierContactData(): any {
  try {
    yield put(supplierContactLoading());
    const {data} = yield call(ProductAPIS.getSupplierContactData);

    yield put(supplierContactSuccess(data));
  } catch (error) {
    yield put(supplierContactFailure());
  }
}

export default function* watchFetchMasterSupplierContactData() {
  yield takeEvery(
    supplierContactSagaActions.FETCH_SUPPLIERCONTACT_TYPE_DATA,
    fetchSupplierContactData
  );
}
