import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';

import {
  deliveryConditionLoading,
  deliveryConditionFailure,
  deliveryConditionSuccess
} from 'redux/dashboard/master/deliveryConditionSlice';

export const deliveryConditionSagaActions = {
  FETCH_DELIVERYCONDITION_DATA: 'FETCH_DELIVERYCONDITION_DATA'
};

export function* fetchDeliveryConditionData(): any {
  try {
    yield put(deliveryConditionLoading());
    const {data} = yield call(ProductAPIS.getDeliveryConditionData);
    yield put(deliveryConditionSuccess(data));
  } catch (error) {
    yield put(deliveryConditionFailure());
  }
}

export default function* watchFetchDeliveryConditionData() {
  yield takeEvery(
    deliveryConditionSagaActions.FETCH_DELIVERYCONDITION_DATA,
    fetchDeliveryConditionData
  );
}
