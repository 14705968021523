import {takeEvery, put, call} from 'redux-saga/effects';
import {PurchaseOrderAPIS} from 'redux/purchaseOrder/purchaseOrder_service';
import {poListFailure, poListSuccess, poListLoading} from 'redux/purchaseOrder/poList/poListSlice';

export const poListSagaActions = {
  FETCH_POLIST_DATA: 'FETCH_POLIST_DATA'
};

export function* fetchPoListData(action: any): any {
  try {
    yield put(poListLoading());
    const {data} = yield call(PurchaseOrderAPIS.getPoListData, action.payload);
    yield put(poListSuccess(data));
  } catch (error) {
    yield put(poListFailure());
  }
}

export default function* watchFetchPoListData() {
  yield takeEvery(poListSagaActions.FETCH_POLIST_DATA, fetchPoListData);
}
