import {TableHead, TableRow, Typography, Box, Tooltip} from '@mui/material';
import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {productWareHouseListSagaActions} from 'redux/product/list/warehouseList/warehouseListSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {generateLabelValue} from 'util/arrayOperations';
import {alertSagaActions} from 'redux/dashboard/master/alertSaga';
import {tagSagaActions} from 'redux/dashboard/master/tagSaga';
import {vendorSagaActions} from 'redux/dashboard/master/vendorSaga';
import {sizeSagaActions} from 'redux/dashboard/master/sizeSaga';
import {packSagaActions} from 'redux/dashboard/master/packSaga';
import {viewInFilterOptions} from 'config/constants';
import {warehouseSagaActions} from 'redux/dashboard/master/warehouseSaga';
import {useTheme} from '@mui/material';

interface IPropType {
  atpEnabler?: (val: boolean) => void;
}

interface IProductWarehouse {
  warehouseNo: string;
  ohWos: string;
  onHand: string;
  onOrder: string;
  ohOoWos: string;
}

interface IProductProps {
  itemId: string;
  item: string;
  pack: string;
  size: string;
  [key: string]: any;
  id: string;
}

interface IProductGridProps {
  itemId: string;
  item: string;
  pack: string;
  size: string;
  warehouse: [IProductWarehouse];
  id: string;
}
interface IStaticGridRows {
  data: IProductProps[];
  storeData: IProductGridProps[];
}

const ContentComponent = (row: any) => {
  if (row.itemName.length > 20)
    return (
      <Tooltip title={row.itemName} placement='top'>
        <Box
          sx={{
            maxWidth: '140px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box'
          }}
        >
          {row.itemName}
        </Box>
      </Tooltip>
    );
  else {
    return <Box sx={{maxWidth: '100px'}}>{row.itemName}</Box>;
  }
};

const WarehouseList: React.FC<IPropType> = (props) => {
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const {productWareHouseList, loading} = useAppSelector(
    (state: any) => state.productWareHouseListSlice
  );

  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: alertSagaActions.FETCH_ALERT_DATA
    });
    dispatch({
      type: tagSagaActions.FETCH_TAG_DATA
    });
    dispatch({
      type: vendorSagaActions.FETCH_VENDOR_DATA
    });
    dispatch({
      type: sizeSagaActions.FETCH_SIZE_DATA
    });
    dispatch({
      type: packSagaActions.FETCH_PACK_DATA
    });
    dispatch({
      type: warehouseSagaActions.FETCH_WAREHOUSE_DATA
    });
    // eslint-disable-next-line
  }, []);

  const {master} = useAppSelector((state) => state.masterSlice);
  const {alert} = useAppSelector((state) => state.alertSlice);
  const {vendor} = useAppSelector((state) => state.vendorSlice);
  const {size} = useAppSelector((state) => state.sizeSlice);
  const {pack} = useAppSelector((state) => state.packSlice);
  const {tag} = useAppSelector((state) => state.tagSlice);
  const {warehouse} = useAppSelector((state) => state.warehouseSlice);
  const [dataOne, dataTwo, dataThree] = master;

  const generatePayload = (values: IFilterValues) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      if (key === 'wareHouse') {
        temp.push({key: key, value: JSON.stringify(value), condition: true});
      } else {
        temp.push({key: key, value: value, condition: true});
      }
    }
    return temp;
  };

  const LinkComponent = (row: any) => {
    return (
      <Box
        sx={{
          '& a:hover, a:visited, a:focus': {
            color: theme.palette?.primary.main
          }
        }}
      >
        <Link to={{pathname: `/Product/${row.itemId}`}} state={row}>
          {row.itemId}
        </Link>{' '}
      </Box>
    );
  };

  const columnCell: IColumnCell<IProductProps>[] = [
    {
      id: 'itemId',
      name: 'itemId',
      displayName: 'Item Id',
      customCell: LinkComponent,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      sticky: true,
      sx: {
        minWidth: '80px'
      }
    },
    {
      id: 'item',
      name: 'item',
      displayName: 'Item',
      sticky: true,
      hasTags: true,
      customCell: ContentComponent,
      headerTextAlign: 'right',
      cellTextAlign: 'left',
      sx: {
        minWidth: '200px'
      }
    },
    {
      id: 'pack',
      name: 'pack',
      isColumnSortable: false,
      sticky: true,
      headerTextAlign: 'left',
      cellTextAlign: 'right',
      sx: {
        minWidth: '50px'
      }
    },
    {
      id: 'size',
      name: 'size',
      isNumeric: true,
      isGroupBorderRequire: true,
      isColumnSortable: false,
      sticky: true,
      headerTextAlign: 'right',
      cellTextAlign: 'right',
      sx: {
        minWidth: '60px'
      }
    }
  ];

  const StickyHeaderCell = (props: IStaticGridRows) => {
    const singleRowElement: IProductGridProps =
      props.storeData && props.storeData[0] ? props.storeData[0] : ({} as IProductGridProps);

    const rowCellsData = singleRowElement?.warehouse?.map((storeItem: IProductWarehouse) => {
      const element = storeItem.warehouseNo;
      const options = ['onHand', 'onOrder', 'ohWos', 'ohOoWos'];
      const consolidatedData = props.data.reduce((accumulator: any, c: any) => {
        options.forEach((ele) => {
          let value = c[`${element}.${ele}`];
          value = value[0] === '$' ? value.substring(1) : value;
          if (accumulator[ele]) {
            accumulator[ele] = accumulator[ele] + parseFloat(value);
          } else {
            accumulator[ele] = parseFloat(value);
          }
        });
        return accumulator;
      }, {});
      return (
        <>
          <TableStickyCell sx={{top: '64px'}} colSpan={1} align='right'>
            <Typography variant='subtitle2'>${consolidatedData.onHand}</Typography>
          </TableStickyCell>
          <TableStickyCell sx={{top: '64px'}} colSpan={1} align='right'>
            <Typography variant='subtitle2'>${consolidatedData.onOrder}</Typography>
          </TableStickyCell>
          <TableStickyCell sx={{top: '64px'}} colSpan={1} align='right'>
            <Typography variant='subtitle2'>
              {(consolidatedData.ohWos / props.data.length).toFixed(1)}
            </Typography>
          </TableStickyCell>
          <BorderedTableStickyCell sx={{top: '64px'}} align='right'>
            <Typography variant='subtitle2'>
              {(consolidatedData.ohOoWos / props.data.length).toFixed(1)}
            </Typography>
          </BorderedTableStickyCell>
        </>
      );
    });
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'Background',
                zIndex: theme.zIndex?.appBar + 20,
                top: '64px'
              }}
            >
              <TableStickyCell align='right' sx={{top: '64px'}}></TableStickyCell>
              <TableStickyCell
                colSpan={4}
                sx={{
                  color: 'grayscale.400',
                  borderBottom: 0,
                  fontWeight: 'bold'
                }}
              >
                <Typography
                  variant='subtitle2'
                  sx={{
                    height: '20px',
                    width: '496px',
                    color: theme.palette?.grayscale?.[400],
                    fontFamily: 'Roboto Medium',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '0.2px',
                    paddingLeft: '8px'
                  }}
                >
                  CONSOLIDATED STATS
                </Typography>
              </TableStickyCell>
            </BorderedTableStickyCell>
            {rowCellsData}
          </TableRow>
        </TableHead>
      </>
    );
  };

  const formateRowData = (listState: IProductGridProps[]) => {
    const gridData: IProductProps[] = listState.map((ele: IProductGridProps) => {
      const warehouseItem: IProductProps = {...ele};
      const warehouseNoItem: IProductWarehouse[] = ele.warehouse;
      warehouseNoItem.forEach((item: IProductWarehouse) => {
        const {ohOoWos, ohWos, onHand, onOrder} = item;
        warehouseItem[item.warehouseNo] = item.warehouseNo;
        warehouseItem[`${item.warehouseNo}.ohOoWos`] = ohOoWos;
        warehouseItem[`${item.warehouseNo}.ohWos`] = ohWos;
        warehouseItem[`${item.warehouseNo}.onHand`] = `$${onHand}`;
        warehouseItem[`${item.warehouseNo}.onOrder`] = `$${onOrder}`;
      });

      delete warehouseItem.warehouse;
      return warehouseItem;
    });

    return gridData;
  };
  const rowData: IProductProps[] =
    productWareHouseList.length > 0 ? formateRowData(productWareHouseList) : [];

  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };

  const getRowIds = (ids: readonly string[]) => {
    //type should be same as selected state in enhanced table
    props.atpEnabler && props.atpEnabler(!(ids.length > 0));
  };
  const addcolumnCellData = (listState: IProductGridProps[]) => {
    listState[0]?.warehouse.forEach((item: any) => {
      const element = item.warehouseNo;
      if (!columnCell.find((e) => e.id === `${element}.ohWos`)) {
        columnCell.push({
          id: `${element}.ohWos`,
          name: `${element}.ohWos`,
          displayName: element,
          headerTextAlign: 'left',
          isGroupBorderRequire: false,
          isColumnSortable: false,
          isGroupedCell: true,
          groupedKeys: [
            `${element}.onHand`,
            `${element}.onOrder`,
            `${element}.ohWos`,
            `${element}.ohOoWos`
          ],
          groupDisplayKeys: ['on Hand', 'on Order', 'oh Wos', 'oh + Oo Wos'],
          sx: {
            textAlign: 'left',
            minWidth: '80px',
            whiteSpace: 'nowrap'
          }
        });
      }
    });

    return columnCell;
  };

  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.MULTISELECT,
      property: 'wareHouse',
      title: 'Warehouse',
      defaultValue: ['95', '96', '97', '98', '99'],
      sx: {
        width: '150px'
      },
      options: generateLabelValue(warehouse, 'warehouseID')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      defaultValue: 'All Departments',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'tag',
      title: 'Tag',
      defaultValue: 'All Tags',
      placeholder: 'Tags',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(tag, 'tag')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View in (OH, OO)',
      defaultValue: 'Units',
      sx: {
        width: '150px'
      },
      options: viewInFilterOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'vendor',
      title: 'Vendor',
      defaultValue: 'All Vendors',
      placeholder: 'Vendors',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(vendor, 'vendorName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'alert',
      title: 'Alert',
      defaultValue: 'All Alerts',
      placeholder: 'Alerts',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(alert, 'alert')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'pack',
      title: 'Pack',
      defaultValue: 'All Pack',
      placeholder: 'Pack',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(pack, 'pack')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'size',
      title: 'Size',
      defaultValue: 'All Size',
      placeholder: 'Size',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(size, 'size')
    }
  ];
  if (location.state?.filter && location?.state?.filter['warehouseID']) {
    const list = [];
    list.push(location.state.filter['warehouseID']);
    location.state.filter['wareHouse'] = list;
  }
  const defaultData = filterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});
  const [filtervalues, setFilterValues] = useState(defaultData);
  const dispatchData = () => {
    dispatch({
      type: productWareHouseListSagaActions.FETCH_PRODUCTWAREHOUSELIST_DATA,
      payload: generatePayload(filtervalues)
    }); // eslint-disable-next-line
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);
  if (productWareHouseList && productWareHouseList.length > 0) {
    return (
      <>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-warehouseList'
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='warehouse-list-load' />
          </Box>
        )}
        <EnhancedTable<IProductProps>
          height={844}
          rowData={rowData}
          stickyRows={<StickyHeaderCell data={rowData} storeData={productWareHouseList} />}
          columnCell={addcolumnCellData(productWareHouseList)}
          rowKey={'itemId'}
          defaultSortKey={'itemId'}
          showTags={true}
          componentType='paper'
          allowAlternateColor={true}
          getSelectedIds={getRowIds}
          rowsPerPage={25}
          selectable
          showAlertPopup
          id='productList-warehouseList'
        />
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          id='product-warehouseList'
          onReset={() => {
            setFilterValues(defaultData);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='warehouse-list-load' />
          ) : (
            <CustomError
              id='warehouse-list-error'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      </>
    );
  }
};

export default WarehouseList;
