import {TableHead, TableRow, Typography, Box, Stack, Button, Popover, Modal} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {ReactComponent as Actions} from 'assets/images/icons/Icons & UI-Processing-Processing-Dots.svg';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {poListSagaActions} from 'redux/purchaseOrder/poList/poListSaga';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {vendorSagaActions} from 'redux/dashboard/master/vendorSaga';
import {generateLabelValue, generateStickyHeaderValues} from 'util/arrayOperations';
import {alertSagaActions} from 'redux/dashboard/master/alertSaga';
import {warehouseSagaActions} from 'redux/dashboard/master/warehouseSaga';
import {buyerSagaActions} from 'redux/dashboard/master/buyerSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import moment from 'moment';
import handleRoleMapping, {handleAccessFilter} from 'util/helperRouterMap';
import {Mode, POStatus} from 'features/purchaseOrder/types';
import CustomButton from 'components/baseComponents/customButton';

interface IPoGridProps {
  poId: string;
  vendorDescription: string;
  status: string;
  warehouseId: string;
  cases: string;
  cost: string;
  estimatedFreight: string;
  actualFreight: string;
  additionalCost: string;
  issueDate: string;
  plannedDeliveryDate: string;
  asnReceivedDate: string;
  receiptDate: string;
  receivedPercentage: string;
  buyerId: string;
  action: string;
  hasAlert?: boolean;
  id: string;
}

const StickyHeaderCell = (props: any) => {
  const consolidatedData = generateStickyHeaderValues(props?.data, props?.columnCell);
  return (
    <>
      <TableHead>
        <TableRow>
          <BorderedTableStickyCell
            colSpan={4}
            sx={{top: '64px', color: 'grayscale.400', borderBottom: 0}}
          >
            <Typography variant='subtitle2'>CONSOLIDATED STATS</Typography>
          </BorderedTableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            <Typography variant='subtitle2'> {consolidatedData?.cases}</Typography>
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            <Typography variant='subtitle2'>{consolidatedData?.cost}</Typography>
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            <Typography variant='subtitle2'>{consolidatedData?.estimatedFreight}</Typography>
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px'}}>
            <Typography variant='subtitle2'>{consolidatedData?.actualFreight}</Typography>
          </TableStickyCell>
          <BorderedTableStickyCell align='right' sx={{top: '64px', textAlign: 'right'}}>
            <Typography variant='subtitle2'>${/*consolidatedData?.additionalCost*/ 0}</Typography>
          </BorderedTableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px', textAlign: 'center'}}>
            <Typography variant='subtitle2'>-</Typography>
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px', textAlign: 'center'}}>
            <Typography variant='subtitle2'>-</Typography>
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px', textAlign: 'center'}}>
            <Typography variant='subtitle2'>-</Typography>
          </TableStickyCell>
          <TableStickyCell align='right' sx={{top: '64px', textAlign: 'center'}}>
            <Typography variant='subtitle2'>-</Typography>
          </TableStickyCell>
          <BorderedTableStickyCell align='right' sx={{top: '64px', textAlign: 'center'}}>
            <Typography variant='subtitle2'>{consolidatedData?.receivedPercentage}</Typography>
          </BorderedTableStickyCell>
          <BorderedTableStickyCell align='right' sx={{top: '64px', textAlign: 'center'}}>
            <Typography variant='subtitle2'>-</Typography>
          </BorderedTableStickyCell>
          <TableStickyCell align='center' sx={{top: '64px'}}>
            <Typography variant='subtitle2'>-</Typography>
          </TableStickyCell>
        </TableRow>
      </TableHead>
    </>
  );
};

const LinkComponent = (row: any) => {
  return (
    <Box
      sx={{
        '& a:visited': {
          color: 'red'
        }
      }}
    >
      <Link to={{pathname: `/PO/${row?.poId}`}} state={row}>
        <Typography variant={'body2'} sx={{color: 'primary.main', fontFamily: 'Roboto Medium'}}>
          {row?.poId}
        </Typography>
      </Link>{' '}
    </Box>
  );
};

const StyleComponent = (row: any) => {
  return (
    <>
      {/* <Link to={{ pathname: `/PO/${row.po}` }} state={row}> */}
      <Typography variant={'body2'} sx={{color: 'primary.main', fontFamily: 'Roboto Medium'}}>
        {row?.vendorDescription}
      </Typography>
      {/* </Link> */}{' '}
    </>
  );
};
export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 30,
  p: 4
};
interface IRoleType {
  name: string;
  permission: Array<any>;
  routePath: string;
}

const PurchaseOrder: React.FC = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [getRoleData, setRoleData] = useState<IRoleType>(
    handleRoleMapping(location && location?.pathname)
  );
  const [createPOPopup, setCreatePOPopup] = React.useState(location?.state?.openPopup);
  const [temp, setTemp] = useState(getRoleData && handleAccessFilter(getRoleData));
  const checkAccess: Array<string> = temp;
  const defaultDateFormat = 'MM/DD/YYYY';
  const startDate = moment().startOf('week').format(defaultDateFormat);
  const endDate = moment().format(defaultDateFormat);
  ///
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const divRef = React.useRef();
  const open = Boolean(anchorEl);
  const id = open ? `simple-popover` : undefined;
  // we are setting the state for selected value on click of three dots
  const [selectedActionRow, setSelectedActionRow] = React.useState({} as IPoGridProps);
  //Below handle click is for Action or Three Dot
  const handleClick = (row: any) => {
    divRef.current && setAnchorEl(divRef.current);
    setSelectedActionRow(row);
  };
  //Handle Popover Element Button
  /**
   * Below we are handling the Popover Print Button action
   */
  const handlePopoverPrint = () => {
    navigate(`/PO/${selectedActionRow.poId}`, {state: selectedActionRow});
    setTimeout(() => {
      window.print();
    }, 2000);
  };
  /**
   * Below we are handling the Popover Edit Button Action
   */
  const handlePopoverEdit = () => {
    navigate(`/PO/${selectedActionRow.poId}`, {
      state: {...selectedActionRow, status: Mode.EDIT}
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  /**
   * This Handle Popup close is for newly created PO.
   * This function will close the popup which will inform user to wait for 5 minutes.
   */
  const handlePopupClose = () => {
    setCreatePOPopup(!location.state.openPopup);
    navigate('/PO', {state: false});
  };
  /**
   *
   * @param event
   * It will navigate to PO/Create with state value
   */
  function handleCreate(event: any) {
    // event.preventDefault();
    navigate('/PO/Create', {state: {status: POStatus.DRAFT}});
    navigate(0);
  }
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const {master} = useAppSelector((state) => state.masterSlice);
  const {vendor} = useAppSelector((state) => state.vendorSlice);
  const {alert} = useAppSelector((state) => state.alertSlice);
  const {warehouse} = useAppSelector((state) => state.warehouseSlice);
  const {buyer} = useAppSelector((state) => state.buyerSlice);

  const generateFilterTemplate = (
    masterData: Array<any>,
    startDate: any,
    endDate: any,
    buyerData: any,
    vendor: any,
    alertData: any,
    warehouseData: any
  ) => {
    const [dataOne, dataTwo, dataThree] = masterData;
    const filterTemplate: IFilterTemplate[] = [
      {
        type: filterFieldTypes.MULTISELECT,
        property: 'warehouseId',
        title: 'warehouseId',
        defaultValue: [],
        sx: {
          width: '178px'
        },
        options: generateLabelValue(warehouseData, 'warehouseId')
      },
      {
        type: filterFieldTypes.DROPDOWN,
        property: 'vendorDescription',
        title: 'Vendor',
        defaultValue: 'All Vendors',
        placeholder: 'Vendors',
        allowAllOptions: true,
        sx: {
          width: '178px'
        },
        options: generateLabelValue(vendor, 'vendorName')
      },
      {
        type: filterFieldTypes.DROPDOWN,
        property: 'departmentName',
        title: 'Department',
        placeholder: 'Departments',
        allowAllOptions: true,
        sx: {
          width: '178px'
        },
        defaultValue: 'All Departments',
        options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
      },
      {
        type: filterFieldTypes.DROPDOWN,
        property: 'className',
        title: 'Class',
        defaultValue: 'All Classes',
        placeholder: 'Classes',
        allowAllOptions: true,
        sx: {
          width: '178px'
        },
        dependantFilterName: 'departmentName',
        //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
        // when targetType = 'value' last param in below function should be id or else it should be label string
        options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
      },
      {
        type: filterFieldTypes.DROPDOWN,
        sx: {
          width: '178px'
        },
        property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
        title: 'Subclass',
        defaultValue: 'All Subclasses',
        placeholder: 'Subclasses',
        allowAllOptions: true,
        dependantFilterName: 'className',
        options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
      },
      {
        type: filterFieldTypes.DROPDOWN,
        property: 'status',
        title: 'Status',
        defaultValue: 'All Statuses',
        placeholder: 'Statuses',
        mode: 'Secondary',
        sx: {
          width: '200px'
        },
        options: [
          {
            label: 'All Statuses',
            value: 'All Statuses'
          }
        ]
      },
      {
        type: filterFieldTypes.DATE_RANGE,
        property: 'issueDate',
        title: 'Issue Date',
        format: 'MM/DD/YYYY',
        mode: 'Secondary',
        defaultValue: '',
        sx: {
          width: '210px'
        }
      },
      {
        type: filterFieldTypes.DATE_RANGE,
        property: 'plannedDeliveryDate',
        title: 'Planned Delivery Date',
        format: 'MM/DD/YYYY',
        mode: 'Secondary',
        defaultValue: '',
        sx: {
          width: '210px'
        }
      },
      // {
      //   type: filterFieldTypes.DATE_RANGE,
      //   property: 'asnReceivedDate',
      //   title: 'ASN Received Date',
      //   format: 'MM/DD/YYYY',
      //   mode: 'Secondary',
      //   defaultValue: '',
      //   sx: {
      //     width: '210px'
      //   }
      // },
      {
        type: filterFieldTypes.DATE_RANGE,
        property: 'receiptDate',
        title: 'Receipt Date',
        format: 'MM/DD/YYYY',
        mode: 'Secondary',
        defaultValue: '',
        sx: {
          width: '210px'
        }
      },
      {
        type: filterFieldTypes.DROPDOWN,
        property: 'alertName',
        title: 'Alert',
        defaultValue: 'All Alerts',
        placeholder: 'Alerts',
        allowAllOptions: true,
        sx: {
          width: '200px'
        },
        mode: 'Secondary',
        options: generateLabelValue(alertData, 'alertName')
      },
      {
        type: filterFieldTypes.DROPDOWN,
        property: 'buyerId',
        title: 'Buyer',
        defaultValue: 'All Buyers',
        placeholder: 'Buyers',
        allowAllOptions: true,
        sx: {
          width: '200px'
        },
        mode: 'Secondary',
        options: generateLabelValue(buyerData, 'buyerName')
      }
    ];
    return filterTemplate;
  };

  const defaultData = generateFilterTemplate(
    master,
    startDate,
    endDate,
    buyer,
    vendor,
    alert,
    warehouse
  ).reduce((acc: any, obj) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});
  const [filtervalues, setFilterValues] = useState(defaultData);

  const generatePayload = (values: IFilterValues) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      if (value && value != '' && value.toString().substring(0, 4).toLowerCase() != 'all ') {
        if (key === 'issueDate' || key === 'plannedDeliveryDate') {
          temp.push({key: key, value: value, condition: 'BETWEEN'});
        } else if (key === 'warehouseId') {
          temp.push({key: key, value: JSON.stringify(value), condition: 'IN'});
        } else if (key === 'asnReceivedDate' || key === 'receiptDate') {
          // temp.push({ key: key, value: JSON.stringify(value), condition: 'IN' })
        } else {
          temp.push({key: key, value: value, condition: 'EQUAL'});
        }
      }
    }
    return temp;
  };
  /** for reload purchase order list*/
  const dispatchData = () => {
    dispatch({
      type: poListSagaActions.FETCH_POLIST_DATA,
      payload: generatePayload(filtervalues)
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);
  const {poList, loading} = useAppSelector((state) => state.poListSlice);
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: vendorSagaActions.FETCH_VENDOR_DATA
    });
    dispatch({
      type: alertSagaActions.FETCH_ALERT_DATA
    });
    dispatch({
      type: warehouseSagaActions.FETCH_WAREHOUSE_DATA
    });
    dispatch({
      type: buyerSagaActions.FETCH_BUYER_DATA
    });
  }, []);

  const actionComponent = (row: any) => {
    return (
      <Box id={`Popover-PO-${row.id}`} ref={divRef}>
        <Button
          onClick={() => {
            handleClick(row);
          }}
          sx={{
            p: 0,
            '&:hover': {
              bgcolor: 'unset'
            }
          }}
        >
          <Actions aria-describedby={id} transform='rotate(90)' />
        </Button>
      </Box>
    );
  };

  const columnCell: IColumnCell<IPoGridProps>[] = [
    {
      id: 'poId',
      name: 'PurchaseOrder Id',
      displayName: 'PO #',
      customCell: LinkComponent
    },
    {
      id: 'vendorDescription',
      name: 'Vendor Description',
      displayName: 'vendor',
      customCell: StyleComponent,
      sx: {
        minWidth: '100px'
      }
    },
    {
      id: 'status',
      name: 'status',
      displayName: 'status',
      sx: {
        textAlign: 'left'
      }
    },
    {
      id: 'warehouseId',
      name: 'Warehouse Id',
      isNumeric: true,
      isGroupBorderRequire: true
    },
    {
      id: 'cases',
      name: 'Cases',
      displayName: 'cases',
      consolidateType: 'SUM',
      isNumeric: true,
      cellTextAlign: 'center'
    },
    {
      id: 'cost',
      name: 'Cost',
      displayName: 'cost',
      prefixText: '$',
      consolidateType: 'SUM',
      isNumeric: true,
      cellTextAlign: 'center'
    },
    {
      id: 'estimatedFreight',
      name: 'Estimated Freight',
      displayName: 'est Frt',
      prefixText: '$',
      consolidateType: 'SUM',
      isNumeric: true,
      cellTextAlign: 'center'
    },
    {
      id: 'actualFreight',
      name: 'Actual Freight',
      displayName: 'actl Frt',
      prefixText: '$',
      consolidateType: 'SUM',
      isNumeric: true,
      cellTextAlign: 'center'
      // isGroupBorderRequire: true
    },
    {
      id: 'additionalCost',
      name: 'Additional Cost',
      displayName: 'add Cost',
      prefixText: '$',
      consolidateType: 'SUM',
      isNumeric: true,
      isGroupBorderRequire: true
    },
    {
      id: 'issueDate',
      name: 'Issue Date',
      displayName: 'issue'
    },
    {
      id: 'plannedDeliveryDate',
      name: 'Planned Delivery Date',
      displayName: 'plan Dlvr',
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'asnReceivedDate',
      name: 'Advance Shipping Notice Received Date',
      displayName: 'asn Rcvd',
      isNumeric: true,
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'receiptDate',
      name: 'Receipt Date',
      displayName: 'receipt',
      sx: {
        textAlign: 'right'
      }
    },
    {
      id: 'receivedPercentage',
      name: 'Received Percentage',
      displayName: '%rcvd',
      suffixText: '%',
      consolidateType: 'AVG',
      isNumeric: true,
      isGroupBorderRequire: true
    },

    {
      id: 'buyerId',
      name: 'Buyer Id',
      displayName: 'buyer',
      isGroupBorderRequire: true,
      sx: {
        textAlign: 'center'
      }
    },
    {
      id: 'action',
      name: 'Action',
      displayName: 'action',
      isColumnSortable: false,
      customCell: actionComponent,
      sx: {
        pr: 1
      }
    }
  ];

  const shouldRender = checkAccess && checkAccess.every((e) => e === 'self');

  if (shouldRender) {
    if (poList && poList.length > 0) {
      return (
        <Box sx={{ml: 4, mt: 10, pb: 2}}>
          <GridFilter
            id='purchaseOrder'
            values={filtervalues}
            filterTemplate={generateFilterTemplate(
              master,
              startDate,
              endDate,
              buyer,
              vendor,
              alert,
              warehouse
            )}
            onFilterChange={onFilterApply}
            onReset={() => {
              setFilterValues(defaultData);
            }}
            customButton={
              <Box
                sx={{
                  alignSelf: 'center',
                  borderLeftWidth: '2px',
                  borderLeftStyle: 'solid',
                  borderLeftColor: theme.palette.grayscale?.[200],
                  ml: 1,
                  pt: 1,
                  pl: 3,
                  height: '48px'
                }}
              >
                <Button variant='contained' onClick={handleCreate}>
                  <Typography
                    variant='button'
                    sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}
                    id={`gridFilter-createPO`}
                  >
                    CREATE PO
                  </Typography>
                </Button>
              </Box>
            }
          />
          {
            <Modal open={createPOPopup} onClose={handlePopupClose}>
              <Box sx={style}>
                <Typography
                  id='modal-modal-title'
                  variant='h6'
                  component='h2'
                  sx={{color: '#30d5c8 '}}
                >
                  PO Created Successful !
                </Typography>
                <Typography
                  id='modal-modal-description'
                  sx={{
                    color: 'grayscale.600',
                    fontFamily: 'Roboto Medium',
                    fontSize: '12px',
                    mt: 1.5
                  }}
                >
                  {`New PO ID is : ${location?.state?.purchaseOrderId}`}
                </Typography>
                <Typography
                  id='modal-modal-description'
                  sx={{
                    color: 'grayscale.600',
                    fontFamily: 'Roboto Medium',
                    fontSize: '12px',
                    mt: 1.5
                  }}
                >
                  New PO ordered and changes will be available in 5 minutes.
                </Typography>
              </Box>
            </Modal>
          }
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <CustomLoader id='purchase-order-load' />
            </Box>
          )}
          <EnhancedTable<IPoGridProps>
            height={844}
            rowData={poList}
            stickyRows={<StickyHeaderCell data={poList} columnCell={columnCell} />}
            columnCell={columnCell}
            rowKey={'poId'}
            defaultSortKey={'poId'}
            componentType='paper'
            allowAlternateColor={true}
            rowsPerPage={25}
            selectable={false}
            id='purchaseOrder'
            showAlertPopup
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            sx={{
              maxHeight: '400px',
              width: '100%',
              borderRadius: 0,
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              '&.css-3bmhjh-MuiPaper-root-MuiPopover-paper': {
                boxShadow:
                  '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
              }
            }}
          >
            <Box>
              <Stack direction={'column'} sx={{p: 2, boxShadow: 'unset'}}>
                <CustomButton
                  id='Popup-print-PO'
                  onClick={handlePopoverPrint}
                  // sx={{ textAlign: 'left' }}
                  style={{justifyContent: 'flex-start'}}
                >
                  <Typography
                    variant={'body2'}
                    sx={{
                      color: 'primary.main',
                      fontFamily: 'Roboto Medium'
                    }}
                  >
                    Print PO
                  </Typography>
                </CustomButton>
                <CustomButton
                  id='Popup-Edit-PO'
                  style={{justifyContent: 'flex-start'}}
                  onClick={() => undefined}
                >
                  <Typography
                    variant={'button'}
                    sx={{
                      color: 'primary.main',
                      fontFamily: 'Roboto Medium'
                    }}
                    onClick={handlePopoverEdit}
                  >
                    Edit PO
                  </Typography>
                </CustomButton>
                <CustomButton
                  id='Popup-Copy-PO'
                  style={{justifyContent: 'flex-start'}}
                  onClick={() => undefined}
                >
                  <Typography
                    variant={'button'}
                    sx={{
                      color: 'primary.main',
                      fontFamily: 'Roboto Medium'
                    }}
                    onClick={() => undefined}
                  >
                    Copy PO
                  </Typography>
                </CustomButton>
                <CustomButton id='Popup-Cancel-PO' onClick={() => undefined}>
                  <Typography
                    variant={'button'}
                    sx={{
                      color: 'primary.main',
                      fontFamily: 'Roboto Medium'
                    }}
                    onClick={() => undefined}
                  >
                    Cancel PO
                  </Typography>
                </CustomButton>
                <CustomButton id='Popup-View-In-SAP-PO' onClick={() => undefined}>
                  <Typography
                    variant={'body2'}
                    sx={{color: 'primary.main', fontFamily: 'Roboto Medium'}}
                    onClick={() => window.open('https://www.google.com')}
                  >
                    View in SAP
                  </Typography>
                </CustomButton>
              </Stack>
            </Box>
          </Popover>
        </Box>
      );
    } else {
      return (
        <Box sx={{mt: 7}}>
          <GridFilter
            values={filtervalues}
            filterTemplate={generateFilterTemplate(
              master,
              startDate,
              endDate,
              buyer,
              vendor,
              alert,
              warehouse
            )}
            onFilterChange={onFilterApply}
            onReset={() => {
              setFilterValues(defaultData);
            }}
            id='purchaseOrder'
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              maxHeight: '844px'
            }}
          >
            {' '}
            {loading ? (
              <CustomLoader id='purchase-order-load' />
            ) : (
              <CustomError
                id='purchase-order-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )}
          </Box>
        </Box>
      );
    }
  } else {
    return <>Your are not authorized to view this page!!!</>;
  }
};

export default PurchaseOrder;
