import {createSlice} from '@reduxjs/toolkit';
export interface storeAlertState {
  loading: boolean;
  storeAlert: Array<any>;
  error: string;
}

const initialState: storeAlertState = {
  loading: false,
  storeAlert: [],
  error: ''
};

const storeAlertSlice = createSlice({
  name: 'storeAlert',
  initialState,
  reducers: {
    storeAlertLoading: (state) => {
      state.loading = true;
    },
    storeAlertSuccess: (state, action) => {
      state.storeAlert = action.payload;
      state.loading = false;
    },
    storeAlertFailure: (state) => {
      state.loading = false;
      state.storeAlert = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default storeAlertSlice.reducer;
export const {storeAlertLoading, storeAlertSuccess, storeAlertFailure} = storeAlertSlice.actions;
