import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {poAlertFailure, poAlertSuccess, poAlertLoading} from 'redux/dashboard/poAlert/poAlertSlice';

export const poAlertSagaActions = {
  FETCH_POALERT_DATA: 'FETCH_POALERT_DATA'
};

export function* fetchpoAlertData(action: any): any {
  try {
    yield put(poAlertLoading());
    const {data} = yield call(DashboardServiceAPIS.getPoAlertData, action.payload);
    yield put(poAlertSuccess(data));
  } catch (error) {
    yield put(poAlertFailure());
  }
}

export default function* watchFetchPoAlertData() {
  yield takeEvery(poAlertSagaActions.FETCH_POALERT_DATA, fetchpoAlertData);
}
