import {createSlice} from '@reduxjs/toolkit';
export interface updatePOState {
  loading: boolean;
  updatePO: any;
  updateError: string;
}

const initialState: updatePOState = {
  loading: false,
  updatePO: {},
  updateError: ''
};

const updatePOSlice = createSlice({
  name: 'updatePO',
  initialState,
  reducers: {
    updatePOLoading: (state) => {
      state.loading = true;
      state.updatePO = {};
    },
    updatePOSuccess: (state, action) => {
      state.updatePO = action.payload;
      state.loading = false;
    },
    updatePOFailure: (state, action) => {
      state.loading = false;
      state.updatePO = {};
      state.updateError = action.payload;
    },
    //for clear Update PO
    clearUpdatePOLoading: (state) => {
      state.loading = false;
      state.updatePO = {};
      state.updateError = '';
    },
    clearUpdatePOFailure: (state) => {
      state.loading = false;
      state.updatePO = {};
      state.updateError = '';
    }
  }
});

export default updatePOSlice.reducer;
export const {
  updatePOLoading,
  updatePOSuccess,
  updatePOFailure,
  clearUpdatePOLoading,
  clearUpdatePOFailure
} = updatePOSlice.actions;
