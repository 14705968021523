import * as React from 'react';
import {Typography, TableHead, TableRow, styled, Box} from '@mui/material';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import EnhancedTable from 'components/customTable/enhancedTable';
import {TableStickyCell} from 'components/customTable/tableCell';
import {IColumnCell} from 'components/customTable/types';
import {CustomCard} from 'components/baseComponents/customCard';
import {useTheme} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {inventorySagaActions} from 'redux/dashboard/inventory/inventorySaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {formatString, generatePayload, generateStickyHeaderValues} from 'util/arrayOperations';
import {Link} from 'react-router-dom';
import BarGraphComponent, {IAxis} from 'components/barGraph';

const dateDropDownData = [
  {
    label: "Inventory(1000's Dollars)",
    value: 'Inventory'
  },
  {
    label: 'Short Code Inventory',
    value: 'Short Code'
  },
  {
    label: 'Store Inventory',
    value: 'Store'
  },
  {
    label: 'Department Inventory',
    value: 'Department'
  }
];

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root .MuiBox-root:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 0
  },
  '& .MuiTableContainer-root': {
    overflowX: 'hidden'
  },
  '& .MuiTable-root': {
    marginRight: '16px'
  }
});

interface IPropType {
  filteredData: any;
  defaultValue: string;
}
interface IInventoryGridProps {
  name: string;
  oh: string;
  oo: string;
  id: string;
}
interface IShortCodeGridProps {
  itemId: string;
  expDate: string;
  quantity: string;
}
interface IStickyHeaderProps {
  data: any;
  columnCell: IColumnCell<IInventoryGridProps>[];
}

const StickyHeaderCell: React.FC<IStickyHeaderProps> = (props: any) => {
  const consolidatedData = generateStickyHeaderValues(props?.data, props?.columnCell);
  return (
    <>
      <TableHead>
        <TableRow>
          <TableStickyCell align='left' sx={{top: '32px', color: 'grayscale.400', pl: 2}}>
            <Typography variant='subtitle2'>TOTAL</Typography>
          </TableStickyCell>
          <TableStickyCell align='center' sx={{top: '32px'}}>
            {consolidatedData?.oh}
          </TableStickyCell>
          <TableStickyCell align='center' sx={{top: '32px'}}>
            {consolidatedData?.oo}
          </TableStickyCell>
        </TableRow>
      </TableHead>
    </>
  );
};

const InventoryTable: React.FC<IPropType> = (props) => {
  const [inventoryType, setInventoryType] = React.useState(props.defaultValue);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  /**
   * dispatch inventory Data
   */
  const dispatchData = () => {
    dispatch({
      type: inventorySagaActions.FETCH_INVENTORY_DATA,
      payload: {
        type: inventoryType,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [inventoryType, props?.filteredData]);

  const {inventory, loading, error} = useAppSelector((state) => state.inventorySlice);
  const LinkComponentClass = (row: any) => {
    return (
      <>
        <Link
          to={{pathname: ``}}
          state={{
            filter: {
              className: row.name || ''
            }
          }}
        >
          {row.name}
        </Link>{' '}
      </>
    );
  };
  const LinkComponentItem = (row: any) => {
    return (
      <>
        <Link to={{pathname: `/Product/${row.itemId}`}} state={row}>
          {row.itemId}
        </Link>{' '}
      </>
    );
  };
  const inventoryColumnCell: IColumnCell<IInventoryGridProps>[] = [
    {
      id: 'name',
      name: 'name',
      displayName: 'CLASS',
      textColor: theme.palette.primary.main,
      customCell: LinkComponentClass,
      sx: {height: '32px', padding: '4px 16px'}
    },
    {
      id: 'oh',
      name: 'oh',
      displayName: 'OH',
      isNumeric: true,
      prefixText: '$',
      consolidateType: 'SUM',
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    },
    {
      id: 'oo',
      name: 'oo',
      displayName: 'OO',
      isNumeric: true,
      prefixText: '$',
      consolidateType: 'SUM',
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    }
  ];
  const ShortCodeColumnCell: IColumnCell<IShortCodeGridProps>[] = [
    {
      id: 'itemId',
      name: 'itemId',
      displayName: 'ITEM ID',
      textColor: theme.palette.primary.main,
      customCell: LinkComponentItem,
      sx: {height: '32px', padding: '4px 16px'}
    },
    {
      id: 'quantity',
      name: 'quantity',
      displayName: 'QTY',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    },
    {
      id: 'expDate',
      name: 'expDate',
      displayName: 'EXP DATE',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    }
  ];
  const xAxis: Array<IAxis> = [
    {
      name: 'onHand',
      displayTitle: 'onHand',
      color: theme.palette.primary.dark,
      unit: 'K'
    }
  ];
  const yAxisStore: IAxis = {
    name: 'storeName',
    displayTitle: 'name',
    unit: 'K'
  };
  const yAxisDepartment: IAxis = {
    name: 'departmentName',
    displayTitle: 'name',
    unit: 'K'
  };
  const planAxis: IAxis = {
    name: 'plan',
    displayTitle: 'plan',
    color: theme.palette.neutral?.secondary1,
    unit: 'K'
  };
  const handleSelect = (event: any): void => {
    setInventoryType(formatString(event?.target.value));
  };
  const Header = () => {
    return (
      <DropDownWrapper>
        <CustomSelect
          id='inventoryTile'
          name={'inventory_tile'}
          value={inventoryType}
          onChange={handleSelect}
          options={dateDropDownData}
        />
      </DropDownWrapper>
    );
  };

  const Content: any = () => {
    switch (inventoryType) {
      case 'Inventory':
        return (
          <>
            <EnhancedTable<IInventoryGridProps>
              width={'100%'}
              rowData={inventory[inventoryType]}
              stickyRows={
                <StickyHeaderCell
                  data={inventory[inventoryType]}
                  columnCell={inventoryColumnCell}
                />
              }
              columnCell={inventoryColumnCell}
              rowKey={'name'}
              defaultSortKey={'name'}
              allowAlternateColor={false}
              componentType='box'
              dense={true}
              hidePagination
              id='dashboard-inventory'
            />
          </>
        );
      case 'Short Code':
        return (
          <>
            <EnhancedTable<IShortCodeGridProps>
              width={'100%'}
              rowData={inventory[inventoryType]}
              columnCell={ShortCodeColumnCell}
              rowKey={'itemId'}
              defaultSortKey={'itemId'}
              allowAlternateColor={false}
              componentType='box'
              dense={true}
              hidePagination
              id='dashboard-Short Code'
            />
          </>
        );
      case 'Store':
        return (
          <BarGraphComponent
            data={inventory[inventoryType]}
            xAxis={[...xAxis]}
            yAxis={yAxisStore}
            showPlan={true}
            plan={planAxis}
            redirectUrl={'products'}
            id='storeInventoryCompBar'
          />
        );
      case 'Department':
        return (
          <BarGraphComponent
            data={inventory[inventoryType]}
            xAxis={[...xAxis]}
            yAxis={yAxisDepartment}
            showPlan={true}
            plan={planAxis}
            redirectUrl={'products'}
            id='departmentInventoryCompBar'
          />
        );
    }
  };

  if (inventory[inventoryType] && inventory[inventoryType].length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='inventoryTable-withData'
          header={<Header />}
          content={
            loading[inventoryType] ? <CustomLoader id='inventory-table-load' /> : <Content />
          }
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='inventoryTable-withoutData'
          header={<Header />}
          content={
            loading[inventoryType] ? (
              <CustomLoader id='inventory-table-load' />
            ) : (
              <CustomError
                id='inventory-table-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default InventoryTable;
