import {createSlice} from '@reduxjs/toolkit';
export interface freightTermState {
  loading: boolean;
  freightTerm: Array<any>;
  error: string;
}

const initialState: freightTermState = {
  loading: false,
  freightTerm: [],
  error: ''
};

const freightTermSlice = createSlice({
  name: 'freightTerm',
  initialState,
  reducers: {
    freightTermLoading: (state) => {
      state.loading = true;
    },
    freightTermSuccess: (state, action) => {
      state.freightTerm = action.payload;
      state.loading = false;
    },
    freightTermFailure: (state) => {
      state.loading = false;
      state.freightTerm = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default freightTermSlice.reducer;
export const {freightTermLoading, freightTermSuccess, freightTermFailure} =
  freightTermSlice.actions;
