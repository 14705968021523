import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import moment from 'moment';
import React from 'react';
import {CustomCardContent, CustomCardHeader} from 'components/baseComponents/customCard';
import {
  CustomPickersDay,
  CustomStaticDatePicker,
  StyledCalenderIcon
} from 'components/baseComponents/customDateRange';
import {ReactComponent as Thumbsup} from 'assets/images/icons/Icons & UI-Thumbup-Thumbs-up_Blue.svg';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {remainderSagaActions} from 'redux/dashboard/remainder/remainderSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {generatePayload} from 'util/arrayOperations';

interface IRemainderProps {
  filteredData: any;
}

interface IDataProps {
  date: string;
  title: string;
  generatedBy: string;
  description: string;
}

const RemainderTile: React.FC<IRemainderProps> = (props) => {
  const defaultDateFormat = 'MM/DD/YYYY';
  const intialDate = moment().format(defaultDateFormat);
  const [selectedValue, setSelectedValue] = React.useState<string | null>(intialDate);
  const [updateAPI, setUpdateAPI] = React.useState<boolean>(true);
  const theme: any = useTheme();

  const dispatch: any = useAppDispatch();
  /**
   * dispatch remainder Tile Data
   */
  const dispatchData = () => {
    dispatch({
      type: remainderSagaActions.FETCH_REMAINDER_DATA,
      payload: {
        data: generatePayload(props?.filteredData),
        date: selectedValue
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [props?.filteredData, updateAPI]);

  const filterRemainder = (filterDate: string) => {
    const newData = remainder.filter((obj: IDataProps) => {
      if (obj.date && filterDate) {
        return moment(filterDate).isSame(obj.date, 'day');
      }
      return false;
    });
    return newData;
  };

  const {remainder, loading, error} = useAppSelector((state) => state.remainderSlice);
  const [eventData, setEventData] = React.useState<IDataProps[]>(filterRemainder(intialDate));

  const ColorCircle = () => {
    return (
      <Box
        sx={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          margin: '0 auto',
          display: 'inlineBlock',
          backgroundColor: theme.palette.primary.dark
        }}
      ></Box>
    );
  };

  const RemainderHeaderWrapper = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    '& span': {
      padding: '2px 5px',
      borderRadius: 3,
      backgroundColor: theme.palette.primary.contrastText,
      '& p': {
        color: theme.palette.grayscale?.[600],
        fontWeight: 500,
        minHeight: '32px',
        lineHeight: '32px',
        minWidth: '32px',
        textAlign: 'Center'
      }
    }
  });

  const selectedDateChange = (newValue: string) => {
    const filterData = filterRemainder(newValue);
    setEventData(filterData);
    const start = moment(newValue, 'MM/DD/YYYY');
    const end = moment(selectedValue, 'MM/DD/YYYY');
    const diff = Math.abs(moment.duration(start.diff(end)).asDays());
    if (diff > 30) {
      setUpdateAPI(!updateAPI);
    }
    setSelectedValue(moment(newValue).format(defaultDateFormat));
  };

  const Header = () => {
    return (
      <>
        <Typography variant='subtitle2' sx={{color: theme.palette.common.black}}>
          Reminders
        </Typography>
      </>
    );
  };

  type IRemainderItem = IDataProps;

  const EventName = styled(Box)({
    ...theme.typography.subtitle2
  });

  const RemainderItem = (props: IRemainderItem) => {
    return (
      <Grid
        container
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={0}
        marginTop={1}
        marginBottom={1}
      >
        <Grid item xs={1} sx={{paddingTop: 1}}>
          <IconButton sx={{padding: 0}}>
            <StyledCalenderIcon />
          </IconButton>
        </Grid>
        <Grid item xs={9} justifyContent={'flex-start'}>
          <Grid container sx={{padding: '0px 8px'}}>
            <Grid item xs={12}>
              <EventName>{props.title}</EventName>
            </Grid>
            <Grid item xs={12} sx={{fontSize: '14px'}}>
              {props?.generatedBy}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{padding: '8px 0px'}}>
          <Typography variant='body2'>{props?.description}</Typography>
        </Grid>
      </Grid>
    );
  };

  const NoRemainderTile = () => {
    return (
      <Grid container width={'100%'} mt={3}>
        <Grid
          item
          xs={12}
          height={'100px'}
          sx={{
            textAlign: 'center',
            fill: theme.palette.primary.dark
          }}
        >
          <Thumbsup height={'100px'} width={'100px'} />
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Box>No Reminders Today</Box>
        </Grid>
      </Grid>
    );
  };

  const customDayRender = (day: any, _value: any, DayComponentProps: any) => {
    const dateFind = remainder.find((ele: IDataProps) =>
      moment(ele.date, defaultDateFormat).isSame(day)
    );
    return (
      <>
        <CustomPickersDay>
          <PickersDay {...DayComponentProps}>
            <Stack>
              <Box> {moment(DayComponentProps.key).format('D')}</Box>
              {dateFind ? <ColorCircle /> : null}
            </Stack>
          </PickersDay>
        </CustomPickersDay>
      </>
    );
  };

  if (loading) {
    return (
      <Card
        sx={{
          width: 360,
          padding: '32px 0px 0px 0px',
          height: '720px',
          boxSizing: 'border-box',
          display: 'flex'
        }}
      >
        <CustomCardHeader sx={{padding: '0px 32px'}} subheader={<Header />}></CustomCardHeader>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CustomLoader id='remainder-load' />
        </CardContent>
      </Card>
    );
  }

  if (remainder && remainder?.length > 0 && !error) {
    return (
      <>
        <Card
          sx={{
            width: 360,
            padding: '32px 0px 0px 0px',
            height: '720px',
            boxSizing: 'border-box'
          }}
        >
          <CustomCardHeader sx={{padding: '0px 32px'}} subheader={<Header />}></CustomCardHeader>
          <CustomCardContent>
            <Stack>
              <CustomStaticDatePicker
                displayStaticWrapperAs='desktop'
                showDaysOutsideCurrentMonth
                openTo='day'
                value={selectedValue}
                onChange={(newValue: any) => {
                  selectedDateChange(newValue);
                }}
                renderDay={customDayRender}
                renderInput={(params: any) => <TextField {...params} type='date' />}
              />
              <Grid container sx={{paddingLeft: '32px', paddingRight: '16px'}}>
                <Grid item xs={12}>
                  <RemainderHeaderWrapper sx={{paddingRight: '16px', height: '50px'}}>
                    {moment(selectedValue).format('MMM DD, YYYY')}
                    <span>
                      <Typography variant='body2' textAlign='center' sx={{fontWeight: 'bold'}}>
                        {eventData?.length}
                      </Typography>
                    </span>
                  </RemainderHeaderWrapper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    overflowY: 'scroll',
                    height: '312px',
                    paddingRight: '16px'
                  }}
                >
                  <Grid container>
                    <Grid item width={'100%'}>
                      {eventData.length > 0 ? (
                        eventData.map((element) => {
                          return (
                            <>
                              <RemainderItem {...element} />
                              <Divider></Divider>
                            </>
                          );
                        })
                      ) : (
                        <NoRemainderTile></NoRemainderTile>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </CustomCardContent>
        </Card>
      </>
    );
  } else {
    return (
      <Card
        sx={{
          width: 360,
          padding: '32px 0px 0px 0px',
          height: '720px',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CustomCardContent>
          <CustomError
            id='remainder-error'
            onReload={() => {
              dispatchData();
            }}
          />
        </CustomCardContent>
      </Card>
    );
  }
};

export default RemainderTile;
