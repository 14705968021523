import {createSlice} from '@reduxjs/toolkit';
export interface itemAlertState {
  loading: boolean;
  itemAlert: Array<any>;
  error: string;
}

const initialState: itemAlertState = {
  loading: false,
  itemAlert: [],
  error: ''
};

const itemAlertSlice = createSlice({
  name: 'itemAlert',
  initialState,
  reducers: {
    itemAlertLoading: (state) => {
      state.loading = true;
    },
    itemAlertSuccess: (state, action) => {
      state.itemAlert = action.payload;
      state.loading = false;
    },
    itemAlertFailure: (state) => {
      state.loading = false;
      state.itemAlert = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default itemAlertSlice.reducer;
export const {itemAlertLoading, itemAlertSuccess, itemAlertFailure} = itemAlertSlice.actions;
