import * as React from 'react';
import {Typography, TableHead, TableRow, styled, Box, Grid} from '@mui/material';
import EnhancedTable from 'components/customTable/enhancedTable';
import {TableStickyCell} from 'components/customTable/tableCell';
import {IColumnCell} from 'components/customTable/types';
import {CustomCard} from 'components/baseComponents/customCard';
import {useTheme} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {newPOSagaActions} from 'redux/dashboard/newPOs/newPOSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {generateStickyHeaderValues} from 'util/arrayOperations';
import CustomDateRange from 'components/baseComponents/customDateRange';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {TILE_WIDTH} from 'config/constants';

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root .MuiBox-root:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 0
  },
  '& .MuiTableContainer-root': {
    overflowX: 'hidden'
  },
  '& .MuiTable-root': {
    marginRight: '16px'
  }
});

interface INewPOsTileGridPops {
  itemName: string;
  retail: string;
  units: string;
  poCount: string;
  margin: string;
  id: string;
}

interface IStickyHeaderProps {
  data: INewPOsTileGridPops[];
  columnCell: IColumnCell<INewPOsTileGridPops>[];
}

const StickyHeaderCell: React.FC<IStickyHeaderProps> = (props) => {
  const consolidatedData = generateStickyHeaderValues(props?.data, props?.columnCell);
  return (
    <>
      <TableHead>
        <TableRow>
          <TableStickyCell align='left' sx={{top: '33px'}}>
            <Typography variant='subtitle2'>Total</Typography>
          </TableStickyCell>
          <TableStickyCell align='center' sx={{top: '33px'}}>
            {consolidatedData?.retail}
          </TableStickyCell>
          <TableStickyCell align='center' sx={{top: '33px'}}>
            {consolidatedData?.units}
          </TableStickyCell>
          <TableStickyCell align='center' sx={{top: '33px'}}>
            {consolidatedData?.poCount}
          </TableStickyCell>
          <TableStickyCell align='center' sx={{top: '33px'}}>
            {consolidatedData?.margin}
          </TableStickyCell>
        </TableRow>
      </TableHead>
    </>
  );
};
interface IPropType {
  filteredData: any;
}
export default function NewPO(props: IPropType) {
  //For API Data Fetching
  const theme = useTheme();
  const defaultDateFormat = 'MM/DD/YY';
  const startDate = moment().startOf('week').format(defaultDateFormat);
  const endDate = moment().format(defaultDateFormat);
  const [date, setDate] = React.useState(`${startDate}|${endDate}`);

  const dispatch = useAppDispatch();
  /**
   * dispatch NewPo Tile data
   */
  const dispatchData = () => {
    dispatch({
      type: newPOSagaActions.FETCH_NEW_PO_DATA,
      payload: date
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [date, props?.filteredData]);

  const {newPO, loading, error} = useAppSelector((state) => state.newPOSlice);

  /**
   *
   * @param event dropdown onChange event
   */
  //It will be use for link the PO Screen.
  const LinkComponent = (row: any) => {
    const itemRowName = row?.itemName;
    return (
      <>
        <Link
          to={{pathname: `/PO`}}
          state={{
            filter: {
              class: itemRowName || '',
              issueDate: date
            }
          }}
        >
          {itemRowName}
        </Link>{' '}
      </>
    );
  };
  const columnCell: IColumnCell<INewPOsTileGridPops>[] = [
    {
      id: 'itemName',
      name: 'itemName',
      displayName: 'CLASS',
      textColor: theme.palette.primary.main,
      isColumnSortable: false,
      customCell: LinkComponent
    },
    {
      id: 'retail',
      name: 'retail',
      displayName: 'RETAIL',
      prefixText: '$',
      consolidateType: 'SUM',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    },
    {
      id: 'units',
      name: 'units',
      displayName: 'UNITS',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      consolidateType: 'SUM',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    },
    {
      id: 'poCount',
      name: 'poCount',
      displayName: 'PO COUNT',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      consolidateType: 'SUM',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    },
    {
      id: 'margin',
      name: 'margin',
      displayName: 'MARGIN',
      suffixText: '%',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      consolidateType: 'AVG',
      sx: {
        fontFamily: 'Roboto Bold',
        fontSize: '12px',
        letterSpacing: '0.4px'
      }
    }
  ];

  const Header = () => {
    const onFieldValueChange = (event: any) => {
      setDate(event?.target.value);
    };
    return (
      <>
        <Grid container>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <Typography
              sx={{color: 'common.black', mt: '20px'}}
              variant='subtitle2'
              textAlign='left'
            >
              New PO
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <CustomDateRange
              id='date-range-newPoTile'
              value={date}
              name={'poDate'}
              title={'Issue Date'}
              format={defaultDateFormat}
              onChange={onFieldValueChange}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const Content = () => {
    return (
      <>
        <EnhancedTable<INewPOsTileGridPops>
          width={'100%'}
          rowData={newPO}
          stickyRows={<StickyHeaderCell data={newPO} columnCell={columnCell} />}
          columnCell={columnCell}
          rowKey={'retail'}
          defaultSortKey={'retail'}
          allowAlternateColor={false}
          componentType='box'
          dense={true}
          hidePagination
          id='dashboard-newPoTile'
        />
      </>
    );
  };

  if (newPO && newPO.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='newPo-card-data'
          width={TILE_WIDTH.two}
          header={<Header />}
          headerSx={{marginBottom: '8px'}}
          content={loading ? <CustomLoader id='newPO-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='newPo-card-noData'
          width={TILE_WIDTH.two}
          header={<Header />}
          headerSx={{marginBottom: '8px'}}
          content={
            loading ? (
              <CustomLoader id='newPO-load' />
            ) : (
              <CustomError
                id='newPO-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
}
