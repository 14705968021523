import {Grid, Box, Typography, Stack, Paper, styled, useTheme} from '@mui/material';
import React from 'react';
import CustomInput from 'components/baseComponents/customInput';
const initialState = {
  currentPassword: '',
  newPassoword: '',
  confirmPassword: ''
};
//creating main-component

interface IPropType {
  atpEnabler?: (val: boolean) => void;
}
const SecurityOverview: React.FC<IPropType> = (props: any) => {
  const [securityState, setSecurityState] = React.useState(initialState);
  const theme = useTheme();
  const Item = styled(Paper)({
    backgroundColor: theme.palette.grayscale?.[50],
    borderRadius: '2px',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary
  });
  /**change function */
  const handleChange = (event: any) => {
    const {name, value} = event.target;

    setSecurityState({
      ...securityState,
      [name]: value
    });
  };
  return (
    <>
      <Box id='profile-body-security' sx={{paddingTop: '20px'}}>
        <Stack sx={{marginBottom: '20px', paddingTop: '10px'}}>
          <Typography
            sx={{
              height: '22px',
              width: '132px',
              color: theme.palette.common.black,
              fontFamily: 'Roboto Regular',
              fontSize: '16px',
              letterSpacing: '0.15px',
              lineHeight: '22px'
            }}
          >
            Change Password
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack id='security-current-password'>
              <Typography
                variant='caption'
                sx={{
                  color: 'grayscale.600',
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  display: 'flex'
                }}
              >
                Current Password
              </Typography>
              <CustomInput
                id='security-sate-current-password'
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  borderRadius: 1
                }}
                name='currentPassword'
                value={securityState.currentPassword}
                type='text'
                onChange={handleChange}
              />
            </Stack>
            <Stack id='security-new-password' sx={{paddingTop: '20px'}}>
              <Typography
                variant='caption'
                sx={{
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  display: 'flex'
                }}
              >
                New Password
              </Typography>
              <CustomInput
                id='securityState-newPassowrd'
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  borderRadius: 1
                }}
                name='newPassoword'
                value={securityState.newPassoword}
                type='text'
                onChange={handleChange}
              />
            </Stack>
            <Stack id='security-confirm-new-password' sx={{paddingTop: '20px'}}>
              <Typography
                variant='caption'
                sx={{
                  color: theme.palette.grayscale?.[600],
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  display: 'flex'
                }}
              >
                Confirm New Password
              </Typography>
              <CustomInput
                id='security-state-confirm-password'
                sx={{
                  backgroundColor: 'common.white',
                  width: '380px',
                  borderRadius: 1
                }}
                name='confirmPassword'
                value={securityState.confirmPassword}
                type='text'
                onChange={handleChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Stack>
                <Typography
                  sx={{
                    height: '16px',
                    width: '190px',
                    color: theme.palette.common.black,
                    fontFamily: 'Roboto Medium',
                    fontSize: '12px',
                    letterSpacing: '0.4px',
                    lineHeight: '16px'
                  }}
                >
                  Your Password Must be 8
                </Typography>
              </Stack>
              <Stack
                sx={{
                  color: theme.palette.bpGreyBorder?.main,
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  letterSpacing: '0.4px',
                  lineHeight: '16px'
                }}
              >
                <ul>
                  <li>8 Character</li>
                  <li>1 Lower case</li>
                  <li>1 Upper Case</li>
                  <li>1 Number</li>
                  <li>1 Special Character</li>
                </ul>
              </Stack>
            </Item>{' '}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default SecurityOverview;
