import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {Box} from '@mui/material';

interface IErrorMessage {
  successError?: any;
  updateError?: any;
  handleClose: () => void;
}
const ErrorAlert = (props: IErrorMessage) => {
  const submissionFailed = 'Submission Failed';
  const updateFailed = 'Update Failed';
  return (
    <Box
      sx={{
        height: 20,
        width: '20%',
        position: 'absolute',
        right: 0
      }}
    >
      <Stack spacing={2}>
        <Alert
          severity='error'
          onClose={props.handleClose}
          sx={{
            boxShadow: '2px',
            border: '0.1px solid lightGray',
            backgroundColor: 'grayscale.50'
          }}
        >
          <Stack direction='column' spacing={2}>
            <Stack
              id='Create-PO-Error-Label'
              sx={{color: '#dc143c ', fontSize: '14px', fontFamily: 'Roboto Medium'}}
            >
              {props.successError ? submissionFailed : updateFailed}
            </Stack>
            <Stack id='Create-PO-Error-Label'>
              {props.successError ? props.successError : props.updateError}
            </Stack>
          </Stack>
        </Alert>
      </Stack>
    </Box>
  );
};
export default ErrorAlert;
