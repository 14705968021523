import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {agedFailure, agedLoading, agedSuccess} from 'redux/dashboard/agedTile/agedSlice';

export const agedSagaActions = {
  FETCH_AGED_DATA: 'FETCH_AGED_DATA'
};

export function* fetchAgedData(action: any) {
  try {
    yield put(agedLoading());
    const {data} = yield call(DashboardServiceAPIS.getAgedData, action.payload);
    yield put(agedSuccess(data));
  } catch (error) {
    yield put(agedFailure('error occured'));
  }
}

export default function* watchFetchAgedData() {
  yield takeEvery(agedSagaActions.FETCH_AGED_DATA, fetchAgedData);
}
