import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {marginLoading, marginFailure, marginSuccess} from 'redux/dashboard/margin/marginSlice';

export const marginSagaActions = {
  FETCH_MARGIN_DATA: 'FETCH_MARGIN_DATA'
};

export function* fetchMarginData(action: any): any {
  try {
    yield put(marginLoading({type: action.payload}));
    const {data} = yield call(DashboardServiceAPIS.getMarginData, action.payload);
    yield put(marginSuccess({result: data, type: action.payload.type}));
  } catch (error) {
    yield put(marginFailure(action));
  }
}

export default function* watchFetchMarginData() {
  yield takeEvery(marginSagaActions.FETCH_MARGIN_DATA, fetchMarginData);
}
