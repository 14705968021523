import * as React from 'react';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {Box, Tooltip} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {onOrderRcvgItemSagaActions} from 'redux/product/details/onOrderRcvgItem/onOrderRcvgItemSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {warehouseSagaActions} from 'redux/dashboard/master/warehouseSaga';
import {generateLabelValue} from 'util/arrayOperations';
import {Link, useLocation} from 'react-router-dom';
import moment from 'moment';

const vendorContent = (row: any) => {
  const venId = row.vendor.split('-')[0];
  return (
    <Tooltip title={row.vendor} placement='top'>
      <Box
        sx={{
          maxWidth: '140px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <Link to={{pathname: `/Vendor/${venId}`}} state={{venId: venId}}>
          {row.vendor}
        </Link>
      </Box>
    </Tooltip>
  );
};

const LinkComponent = (row: any) => {
  return (
    <Link to={{pathname: `/PO/${row.poId}`}} state={{poId: row.poId, status: row.status}}>
      {row.poId}
    </Link>
  );
};
interface IPropType {
  atpEnabler?: (val: boolean) => void;
  productId: string;
}
//Below interface will provide strucure for API Upcoming data
interface IProductOrderRCVGGridProps {
  poId: string;
  status: string;
  warehouse: string;
  vendor: string;
  planDelivery: string;
  unitOfMeasure: string;
  units: string;
  netValue: string;
  estimatedFreight: string;
  otherCost: string;
  averageRetail: string;
  estimatedMargin: string;
  wos: string;
  buyerId: string;
  id: string;
}
//Below orderColumnCell add Coulmn Header on table
const orderColumnCell: IColumnCell<IProductOrderRCVGGridProps>[] = [
  {
    id: 'poId',
    name: 'poId',
    displayName: 'PO#',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    customCell: LinkComponent,
    sx: {
      minWidth: '150px'
    }
  },
  {
    id: 'status',
    name: 'status',
    displayName: 'STATUS',
    headerTextAlign: 'left',
    isGroupBorderRequire: false
  },
  {
    id: 'warehouse',
    name: 'warehouse',
    displayName: 'WH/STR',
    headerTextAlign: 'left',
    isGroupBorderRequire: false
  },
  {
    id: 'vendor',
    name: 'vendor',
    displayName: 'VENDOR',
    headerTextAlign: 'left',
    isGroupBorderRequire: false,
    customCell: vendorContent
  },
  {
    id: 'planDelivery',
    name: 'planDelivery',
    displayName: 'PLAN Dlvr',
    isGroupBorderRequire: true
  },
  {
    id: 'unitOfMeasure',
    name: 'unitOfMeasure',
    displayName: 'UOM',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    sx: {
      textAlign: 'center'
    }
  },
  {
    id: 'units',
    name: 'units',
    displayName: 'UNITS',
    isGroupBorderRequire: false,
    isNumeric: true,
    cellTextAlign: 'left'
  },
  {
    id: 'netValue',
    name: 'netValue',
    displayName: 'NET VALUE',
    isGroupBorderRequire: false,
    prefixText: '$',
    isNumeric: true,
    cellTextAlign: 'left'
  },
  {
    id: 'estimatedFreight',
    name: 'estimatedFreight',
    displayName: 'EST Ftr',
    headerTextAlign: 'left',
    isGroupBorderRequire: false,
    prefixText: '$',
    isNumeric: true,
    cellTextAlign: 'left'
  },
  {
    id: 'otherCost',
    name: 'otherCost',
    displayName: 'OTHER COST',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    isNumeric: true,
    prefixText: '$',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'averageRetail',
    name: 'averageRetail',
    displayName: 'AVG Rtl',
    headerTextAlign: 'left',
    isGroupBorderRequire: false,
    prefixText: '$',
    isNumeric: true,
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'estimatedMargin',
    name: 'estimatedMargin',
    displayName: 'EST Mgn',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    suffixText: '%',
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'wos',
    name: 'wos',
    displayName: 'WOS',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    sx: {
      textAlign: 'right'
    }
  },
  {
    id: 'buyerId',
    name: 'buyerId',
    displayName: 'BUYER',
    headerTextAlign: 'left',
    isGroupBorderRequire: true,
    cellTextAlign: 'left'
  }
];
//This is the Main function name OrderListGrid which will defult exportas component.
const OrderListGrid: React.FC<IPropType> = (props) => {
  const location: any = useLocation();
  const defaultDateFormat = 'MM/DD/YYYY';
  //const startDate = moment().startOf('week').format(defaultDateFormat);
  const startDate = '01/01/2023';
  const endDate = moment().format(defaultDateFormat);

  //For API Data Fetching
  const dispatch = useAppDispatch();
  const {onOrderRcvgItem, loading, error} = useAppSelector((state) => state.onOrderRcvgItemSlice);
  //For Binding the MasterWarehouseData
  React.useEffect(() => {
    dispatch({
      type: warehouseSagaActions.FETCH_WAREHOUSE_DATA
    });
  }, []);
  const {warehouse} = useAppSelector((state) => state.warehouseSlice);
  //Receiving the data in proper Formate
  const rowData: IProductOrderRCVGGridProps[] = onOrderRcvgItem.length > 0 ? onOrderRcvgItem : [];

  //For Filter change and generate Payload data
  const generatePayload = (values: IFilterValues) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      if (value && value != '' && value.toString().substring(0, 4).toLowerCase() != 'all ') {
        if (key === 'date') {
          temp.push({key: key, value: value, condition: 'BETWEEN'});
        } else {
          temp.push({key: key, value: value, condition: 'equal'});
        }
      }
    }
    return temp;
  };
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };
  //Below orderFilterTemplate add Filter functionality
  const orderfilterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DATE_RANGE,
      property: 'date',
      title: 'Date',
      format: 'MM/DD/YY',
      defaultValue: `${startDate}|${endDate}`,
      sx: {
        width: '210px'
      }
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'Status',
      title: 'Status',
      defaultValue: 'All Statuses',
      sx: {
        width: '178px'
      },
      options: [
        {
          label: 'All Statuses',
          value: 'All Statuses'
        },
        {
          label: 'In Transit',
          value: 'In Transit'
        },
        {
          label: 'On Order',
          value: 'On Order'
        },
        {
          label: 'Received',
          value: 'Received'
        }
      ]
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'warehouse',
      title: 'Warehouse',
      // defaultValue: 'All Warehouses',
      allowAllOptions: true,
      sx: {
        width: '178px'
      },
      options: generateLabelValue(warehouse, 'warehouseName')
    }
  ];

  const defaultData = orderfilterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});
  const [filtervalues, setFilterValues] = React.useState(defaultData);
  React.useEffect(() => {
    dispatch({
      type: onOrderRcvgItemSagaActions.FETCH_ONORDERRCVGITEM_DATA,
      payload: {
        id: props.productId,
        data: generatePayload(filtervalues)
      }
    });
  }, [filtervalues]);
  //Here we are returning GridFilter and Enhanced Table based on IF-ELSE condition
  if (onOrderRcvgItem && onOrderRcvgItem.length > 0 && !error) {
    return (
      <>
        <GridFilter
          id='product-details-orderList'
          values={filtervalues}
          onFilterChange={onFilterApply}
          filterTemplate={orderfilterTemplate}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='order-list-load' />
          </Box>
        )}
        <EnhancedTable<IProductOrderRCVGGridProps>
          id='product-details-order'
          rowData={rowData}
          columnCell={orderColumnCell}
          rowKey={'poId'}
          defaultSortKey={'poId'}
          rowsPerPage={9}
        />
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          id='product-details-orderList'
          values={filtervalues}
          onFilterChange={onFilterApply}
          filterTemplate={orderfilterTemplate}
          hideMoreFilter={true}
          onReset={() => {
            setFilterValues(defaultData);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? <CustomLoader id='order-list-load' /> : <CustomError id='order-list-error' />}
        </Box>
      </>
    );
  }
};

export default OrderListGrid;
