import {createSlice} from '@reduxjs/toolkit';
export interface regionState {
  loading: boolean;
  region: Array<any>;
  error: string;
}

const initialState: regionState = {
  loading: false,
  region: [],
  error: ''
};

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    regionLoading: (state) => {
      state.loading = true;
    },
    regionSuccess: (state, action) => {
      state.region = action.payload;
      state.loading = false;
    },
    regionFailure: (state) => {
      state.loading = false;
      state.region = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default regionSlice.reducer;
export const {regionLoading, regionSuccess, regionFailure} = regionSlice.actions;
