/* eslint-disable no-useless-catch */
import instance from 'config/instance';
import {DashBoardEndPoints} from 'config/endpoints';
import moment from 'moment';

export const DashboardServiceAPIS = {
  getSalesData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.sales}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getClassPerformanceData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.classPerformance}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getAdPromosData: async (types: string) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.adPromosTile}?type=${types}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getNewPOData: async (payload: any, date: string) => {
    try {
      const dateFormat = 'MM/DD/YYYY';
      const dateRange = date.split('|');
      const from = dateRange[0]
        ? moment(dateRange[0]).format(dateFormat)
        : moment().format(dateFormat);
      const to = dateRange[1]
        ? moment(dateRange[1]).format(dateFormat)
        : moment().format(dateFormat);
      const response = await instance.get(`${DashBoardEndPoints.newPO}?from=${from}&to=${to}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getMarginData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.margin}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getCycleCountData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.cycleCount}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getInventoryData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.inventory}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getPoAlertData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.poAlert}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getUrgentStoreData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.urgentStore}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getTopWeeklyStoreData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.topWeeklyStore}`, {
        params: {
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getClassOTBData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.classOTB}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getRemainderData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.remainder}?date=${payload.date}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getReceivingTileData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.receivingTile}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getStoreAlertData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.storeAlert}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getTopVendorStoreData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.topVendorTile}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getTopTwentyVendorsData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.topTwentyVendors}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getItemAlertData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.itemAlertTile}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getLaborData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.laborTile}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getAgedData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.agedDetails}`, {
        params: {
          agedLevel: payload?.type,
          count: payload?.count,
          date: payload.date,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getExpiryData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.expiryDetails}`, {
        params: {expiryLevel: payload?.type, count: payload?.count, date: payload.date},
        data: payload?.data,
        ...(payload.data && payload.data?.length
          ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
          : {})
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getNotificationData: async () => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.notification}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getShrinkData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.shrinkTile}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProfileData: async (payload: any) => {
    try {
      const response = await instance.get(`${DashBoardEndPoints.getProfile}`, {
        params: {
          type: payload?.type,
          ...(payload.data && payload.data?.length
            ? {filter: encodeURIComponent(JSON.stringify(payload?.data))}
            : {})
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
};
