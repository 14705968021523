import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {tagLoading, tagFailure, tagSuccess} from 'redux/dashboard/master/tagSlice';

export const tagSagaActions = {
  FETCH_TAG_DATA: 'FETCH_TAG_DATA'
};

export function* fetchTagData(): any {
  try {
    yield put(tagLoading());
    const {data} = yield call(ProductAPIS.getTagData);
    yield put(tagSuccess(data));
  } catch (error) {
    yield put(tagFailure());
  }
}

export default function* watchFetchTagData() {
  yield takeEvery(tagSagaActions.FETCH_TAG_DATA, fetchTagData);
}
