import {Box} from '@mui/material';
import {useNavigate, useLocation} from 'react-router-dom';
import React from 'react';
import {ReactComponent as HeartBordered} from 'assets/images/icons/HeartBorder.svg';
import {ReactComponent as HeartColored} from 'assets/images/icons/Icons & UI-Heart.svg';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
interface PropType {
  title: string;
  timeStamp: string;
  status: boolean;
  path: string;
}

const ReportTile: React.FC<PropType> = (props) => {
  const location: any = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const HandleBoxClick = (path: string, title: string) => {
    navigate('/Report' + path, {state: {...location.state, title}});
  };
  return (
    <Box
      id='handle-box-click'
      sx={{
        height: '82px',
        borderRadius: '4px',
        bgcolor: 'common.white',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.5)',
        position: 'relative',
        padding: theme.spacing(1),
        maxWidth: 360,
        cursor: 'pointer',
        boxSizing: 'border-box'
      }}
      onClick={() => HandleBoxClick(props.path, props.title)}
    >
      <Box
        sx={{
          height: theme.spacing(4),
          padding: theme.spacing(1)
        }}
      >
        <Box
          sx={{
            color: 'primary.main',
            minHeight: theme.spacing(2),
            fontWeight: '500',
            float: 'left'
          }}
        >
          <Typography variant='body1' sx={{fontWeight: 600}} id={`report-${props.title}`}>
            {props.title}
          </Typography>
        </Box>
        <br></br>
        <Box
          sx={{
            color: 'common.black',
            fontSize: '14px',
            marginTop: '6px'
          }}
        >
          <Typography variant='body2' id={`report-timestamp`}>
            Data as of {props.timeStamp}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: theme.spacing(1.5),
          top: theme.spacing(1.5)
        }}
      >
        {props.status ? (
          <HeartColored fill={theme.palette.secondary.main} id={'report-heart-icon-red'} />
        ) : (
          <HeartBordered fill='grey' id={'report-heart-icon-grey'} />
        )}
      </Box>
    </Box>
  );
};
export default ReportTile;
