import {Box, Tooltip} from '@mui/material';

interface IVendorName {
  vendorName: string;
}
export const ContentComponent = (props: any) => {
  const row: IVendorName = props as unknown as IVendorName;
  if (row.vendorName.length > 30)
    return (
      <Tooltip title={row.vendorName} placement='top'>
        <Box
          id={`vendorutil-${row.vendorName}`}
          sx={{
            maxWidth: '220px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box'
          }}
        >
          {row.vendorName}
        </Box>
      </Tooltip>
    );
  else {
    return (
      <Box id={`vendorutil-${row.vendorName}`} sx={{maxWidth: '100px'}}>
        {row.vendorName}
      </Box>
    );
  }
};

export const CustomComponent = (props: any) => {
  const row: any = props;
  const departmentName = row?.departmentName || '';
  if (departmentName.length > 30)
    return (
      <Tooltip title={departmentName} placement='top'>
        <Box
          id={`vendorutil-${departmentName}`}
          sx={{
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {departmentName}
        </Box>
      </Tooltip>
    );
  else {
    return (
      <Box id={`vendorutil-${departmentName}`} sx={{maxWidth: '100px'}}>
        {departmentName}
      </Box>
    );
  }
};
