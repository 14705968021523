import React from 'react';
import Header from 'components/header';
import {Box, Container, styled} from '@mui/material';

const CustomBox = styled(Box)({
  width: '100%'
});

interface PropType {
  children: React.ReactNode;
  path: string;
}

export const Layout: React.FC<PropType> = (props) => {
  const currentPath = props.path.substring(1);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Header />
      <CustomBox
        sx={(theme) => ({
          paddingTop: '30px',
          minHeight: '100vh',
          backgroundColor:
            currentPath === 'Report'
              ? theme.palette.grayscale?.[100]
              : theme.palette.grayscale?.[50]
        })}
      >
        {props.children}
      </CustomBox>
    </Box>
  );
};

export const ContainerLayout: React.FC<any> = (props) => {
  const currentPath = props.path;
  if (currentPath === '/' || currentPath === '/Dashboard') {
    return (
      <>
        <Container
          disableGutters
          sx={(theme) => ({[theme.breakpoints.up(1536)]: {maxWidth: '1536px'}})}
          fixed
        >
          {props.children}
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Container
          disableGutters
          sx={(theme) => ({[theme.breakpoints.up(1536)]: {maxWidth: '1536px'}})}
        >
          {props.children}
        </Container>
      </>
    );
  }
};

export const ExtendedContainerLayout = styled(Box)(({theme}) => ({
  margin: '0 auto',
  [theme.breakpoints.down(1950)]: {
    maxWidth: '95%'
  },
  [theme.breakpoints.up(1950)]: {
    maxWidth: '90%'
  }
}));

export const LoginContainerLayout: React.FC<any> = (props) => {
  return (
    <>
      <Container disableGutters maxWidth={'xl'} fixed>
        {props.children}
      </Container>
    </>
  );
};
