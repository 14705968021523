import {createSlice} from '@reduxjs/toolkit';
export interface masterState {
  loading: boolean;
  master: Array<any>;
  error: string;
}

const initialState: masterState = {
  loading: false,
  master: [],
  error: ''
};

const masterSlice = createSlice({
  name: 'master',
  initialState,
  reducers: {
    masterLoading: (state) => {
      state.loading = true;
    },
    masterSuccess: (state, action) => {
      state.master = action.payload;
      state.loading = false;
    },
    masterFailure: (state) => {
      state.loading = false;
      state.master = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default masterSlice.reducer;
export const {masterLoading, masterSuccess, masterFailure} = masterSlice.actions;
