import {createSlice} from '@reduxjs/toolkit';
export interface IExpiryState {
  loading: boolean;
  expiry: Array<any>;
  error: string;
}

const initialState: IExpiryState = {
  loading: false,
  expiry: [],
  error: ''
};

const expirySlice = createSlice({
  name: 'expiry',
  initialState,
  reducers: {
    expiryLoading: (state) => {
      state.loading = true;
    },
    expirySuccess: (state, action) => {
      state.expiry = action.payload;
      state.loading = false;
    },
    expiryFailure: (state, action) => {
      state.loading = false;
      state.expiry = [];
      state.error = action.payload;
    }
  }
});

export default expirySlice.reducer;
export const {expiryLoading, expirySuccess, expiryFailure} = expirySlice.actions;
