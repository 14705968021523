import {createSlice} from '@reduxjs/toolkit';
export interface vendorDetailsState {
  loading: boolean;
  vendorDetails: any;
  error: string;
}

const initialState: vendorDetailsState = {
  loading: false,
  vendorDetails: {},
  error: ''
};

const vendorDetailsSlice = createSlice({
  name: 'vendorDetails',
  initialState,
  reducers: {
    vendorDetailsLoading: (state) => {
      state.loading = true;
    },
    vendorDetailsSuccess: (state, action) => {
      state.vendorDetails = action.payload;
      state.loading = false;
    },
    vendorDetailsFailure: (state) => {
      state.loading = false;
      state.vendorDetails = {};
      state.error = 'Request failed with status code 404';
    }
  }
});

export default vendorDetailsSlice.reducer;
export const {vendorDetailsLoading, vendorDetailsSuccess, vendorDetailsFailure} =
  vendorDetailsSlice.actions;
