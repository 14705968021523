import {createSlice} from '@reduxjs/toolkit';
export interface productItemState {
  loading: boolean;
  productItem: Array<any>;
  error: string;
}

const initialState: productItemState = {
  loading: false,
  productItem: [],
  error: ''
};

const productItemSlice = createSlice({
  name: 'productItem',
  initialState,
  reducers: {
    productItemLoading: (state) => {
      state.loading = true;
    },
    productItemSuccess: (state, action) => {
      state.productItem = action.payload;
      state.loading = false;
    },
    productItemFailure: (state) => {
      state.loading = false;
      state.productItem = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default productItemSlice.reducer;
export const {productItemLoading, productItemSuccess, productItemFailure} =
  productItemSlice.actions;
