import * as React from 'react';
import {Drawer, DrawerProps} from '@mui/material';

type IPropType = DrawerProps;

const CustomDrawer: React.FC<IPropType> = (props) => {
  return (
    <Drawer id={`drawer-${props.id}`} {...props}>
      {props.children}
    </Drawer>
  );
};
export default CustomDrawer;
