import {createSlice} from '@reduxjs/toolkit';
export interface receivedWarehouseLiabilityReportState {
  loading: boolean;
  receivedWarehouseLiabilityReport: Array<any>;
  error: string;
}

const initialState: receivedWarehouseLiabilityReportState = {
  loading: false,
  receivedWarehouseLiabilityReport: [],
  error: ''
};

const warehouseLiabilityReportSlice = createSlice({
  name: 'warehouseLiabilityReport',
  initialState,
  reducers: {
    warehouseLiabilityReportLoading: (state) => {
      state.loading = true;
    },
    warehouseLiabilityReportSuccess: (state, action) => {
      state.receivedWarehouseLiabilityReport = action.payload;
      state.loading = false;
    },
    warehouseLiabilityReportFailure: (state) => {
      state.loading = false;
      state.receivedWarehouseLiabilityReport = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default warehouseLiabilityReportSlice.reducer;
export const {
  warehouseLiabilityReportLoading,
  warehouseLiabilityReportSuccess,
  warehouseLiabilityReportFailure
} = warehouseLiabilityReportSlice.actions;
