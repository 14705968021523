import React from 'react';
import {Box, Tab, Tabs, Typography} from '@mui/material';
import CustomButton from 'components/baseComponents/customButton';
import CustomTabPanel from 'components/baseComponents/customTabPanel';
import {styled} from '@mui/system';
import InventoryStoreRegion from 'features/report/inventory/sections/inventoryStoreRegion';
import {BreadcrumbNav as ReceivedPoItemBreadcrumbNav} from 'features/report/reportBreadCrumbs';
import InventoryDistributionCenter from 'features/report/inventory/sections/inventoryDistributionCenter';
import ExportModal from 'components/baseComponents/exportOption';

const CustomTabs = styled(Tabs)(({theme}) => ({
  '& .MuiTab-root': {
    color: theme.palette.grayscale?.[400],
    padding: '12px 0',
    mr: 3,
    minWidth: 'fit-content'
  }
}));

const CustomTab = styled(Tab)(({theme}) => ({
  textTransform: 'uppercase',
  color: theme.palette.grayscale?.[400],
  padding: '12px 0',
  minWidth: 0,
  marginRight: '24px',
  '&.Mui-disabled': {
    color: theme.palette.grayscale?.[400]
  }
}));

const InventoryBalance: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const [showExpModal, setShowExpModal] = React.useState(false);
  //handle-click function
  const handleClick = () => {
    setShowExpModal(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{mx: 5, mt: 5}}>
      <ReceivedPoItemBreadcrumbNav />
      {/* For Making the two elements in same div left and right */}
      <Box sx={{pl: 0, pt: 1, pb: 1}} flexGrow={1}>
        <Typography variant='h4' align='left'>
          Inventory Balance
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}
      >
        <CustomTabs value={value} onChange={handleChange}>
          <CustomTab label='STORE REGION' value={0} />
          <CustomTab value={1} label='Distribution Center' />
        </CustomTabs>
        <CustomButton
          id='inventoryBalanceExport'
          disableElevation
          onClick={handleClick}
          sx={{
            bgcolor: 'primary.main',
            color: 'common.white',
            '&.Mui-disabled': {
              bgcolor: 'grayscale.200'
            }
          }}
          variant={'contained'}
        >
          <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
            Export
          </Typography>
        </CustomButton>
      </Box>
      <CustomTabPanel value={value} index={0} id='report-inventory-inventoryBalance'>
        <Box>
          <InventoryStoreRegion />
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1} id='report-inventory-inventoryBalance'>
        <Box>
          <InventoryDistributionCenter />
        </Box>
      </CustomTabPanel>
      {showExpModal && (
        <ExportModal
          id='inventoryBalance'
          open={showExpModal}
          handleClose={() => setShowExpModal(false)}
        />
      )}
    </Box>
  );
};

export default InventoryBalance;
