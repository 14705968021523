import React from 'react';
import Plot from 'react-plotly.js';
import {useTheme} from '@mui/material';

interface IPropType {
  //Div id for which the plotly needs to update
  id: string;
  // object for plotly
  layout?: Partial<Plotly.Layout>;
  // xAxis column names
  xAxis: string[];
  // yAxis column name
  yAxis: string[];
  // trace properties
  traceProps?: Partial<Plotly.Data>;
}

export interface IAxis {
  name: string;
  displayTitle?: string;
  color?: string;
  unit?: string;
  prefix?: string;
}

const TinyLineGraphComponent: React.FC<IPropType> = (props) => {
  const theme = useTheme();
  const layout = {
    width: 169,
    height: 41,
    autosize: false,
    hovermode: 'closest',
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    margin: {
      l: 2,
      r: 2,
      b: 2,
      t: 0,
      pad: 0
    },
    xaxis: {
      gridcolor: 'transparent',
      showgrid: false,
      zeroline: false,
      showticklabels: false,
      dtick: 0.5,
      ticklen: 20,
      tickwidth: 4,
      tickcolor: 'grey'
    },
    yaxis: {
      visible: false
    }
  } as unknown as Plotly.Layout;
  const plotlyLayout = {...layout, ...props.layout};

  const data = {
    x: props.xAxis,
    y: props.yAxis,
    type: 'scatter',
    mode: 'lines',
    line: {
      color: theme.palette.grayscale?.[500],
      smoothing: 1
    },
    ...props.traceProps
  } as Plotly.Data;

  return (
    <Plot
      data={[data]}
      layout={plotlyLayout}
      config={{staticPlot: true, displayModeBar: true, responsive: true}}
    />
  );
};

export default TinyLineGraphComponent;
