import * as React from 'react';
import {Box} from '@mui/material';
import GeneralInfoEdit from 'features/purchaseOrder/sections/generalInfo/generalInfoEdit';
import GeneralInfoView from 'features/purchaseOrder/sections/generalInfo/generalInfoView';
import {POStatus, Mode, IGeneralInfo} from 'features/purchaseOrder/types';

const GeneralInfo: React.FC<IGeneralInfo> = (props: IGeneralInfo) => {
  const {mode, poStatus, supplierData, onValueChange, data, dependentData} = props;

  return (
    <Box
      sx={{
        pt: '200px',
        mx: '160px'
      }}
    >
      {mode === Mode.VIEW && poStatus?.toUpperCase() !== POStatus.DRAFT ? (
        <GeneralInfoView
          mode={mode}
          poStatus={poStatus}
          supplierData={supplierData}
          onValueChange={onValueChange}
          data={data}
          submitStatus={false}
          dependentData={dependentData}
        />
      ) : (
        <GeneralInfoEdit
          mode={mode}
          poStatus={poStatus}
          submitStatus={props.submitStatus}
          supplierData={supplierData}
          onValueChange={onValueChange}
          dependentData={dependentData}
          data={data}
        />
      )}
    </Box>
  );
};

export default GeneralInfo;
