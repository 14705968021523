import {getLocalStorage, jwtTokenDecode} from './tokenizer';
import {data} from 'features/login/authRoles';

interface IAccessItemType {
  accessItems: string;
  access: string;
}
interface IPermissionType {
  accessItem: string;
  access: string;
  accessSubItem: Array<IAccessItemType>;
}

interface IFeatureType {
  name: string;
  routePath: string;
  permission: Array<IPermissionType>;
}

const handleRoleMapping = (getPath: string) => {
  const user_token = getLocalStorage('user_token');
  if (user_token) {
    const decoded_token = jwtTokenDecode(user_token);
    const temp: any =
      data &&
      data?.find((d) => {
        return decoded_token.role === d.role;
      });
    const result = temp.feature.find((f: IFeatureType) => {
      if (f.routePath === getPath) {
        return f.routePath;
      } else if (getPath.includes(f.routePath)) {
        return f.routePath;
      } else {
        return null;
      }
    });
    return result;
  }
  return null;
};

export const handleAccessFilter = (val: IFeatureType) => {
  return val.permission
    .filter((f: IPermissionType) => f.access.length > 0)
    .map((m: any) => m.accessItem);
};

export const getRoleFeatureList = () => {
  const user_token = getLocalStorage('user_token');
  if (user_token) {
    const decoded_token = jwtTokenDecode(user_token);
    const featureList: any = data && data?.find((d) => decoded_token.role === d.role);
    return featureList;
  }
  return null;
};

export default handleRoleMapping;
