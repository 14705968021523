/* eslint-disable no-useless-catch */
import instance from 'config/instance';
import {ReportEndPoints} from 'config/endpoints';
export const ReportAPIS = {
  getReceivedPoItemData: async (data: any) => {
    try {
      const response = await instance.post(`${ReportEndPoints.receivedPoItem}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getDemandForecastData: async (data: any) => {
    try {
      const response = await instance.post(`${ReportEndPoints.demandForecast}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getWarehouseLiabilityReportData: async (data: any) => {
    try {
      const response = await instance.post(`${ReportEndPoints.warehouseLiabilityReport}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getInventoryBalanceData: async (payload: any) => {
    try {
      let response;
      if (payload.regionId) {
        response = await instance.post(
          `${ReportEndPoints.inventoryBalance}?type=${payload.type}&regionId=${payload.regionId}`,
          payload.filter
        );
      } else {
        response = await instance.post(
          `${ReportEndPoints.inventoryBalance}?type=${payload.type}`,
          payload.filter
        );
      }
      return response;
    } catch (error) {
      throw error;
    }
  },
  getCategorySupplierData: async (payload: any) => {
    try {
      const response = await instance.post(
        `${ReportEndPoints.categorysupplier}?type=${payload.type}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getTopTenSalesItemData: async (payload: any) => {
    try {
      const response = await instance.get(
        `${ReportEndPoints.topTenItems}?type=${payload.type}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getPerpetualInventoryData: async () => {
    try {
      const response = await instance.post(`${ReportEndPoints.perpetualInventory}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
};
