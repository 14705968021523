import {createSlice} from '@reduxjs/toolkit';
export interface marginState {
  loading: {[key: string]: boolean};
  margin: {[key: string]: Array<any>};
  error: string;
}

const initialState: marginState = {
  loading: {},
  margin: {},
  error: ''
};

const marginSlice = createSlice({
  name: 'margin',
  initialState,
  reducers: {
    marginLoading: (state, action) => {
      state.loading[action.payload.type] = true;
    },
    marginSuccess: (state, action) => {
      state.margin[action.payload.type] = action.payload.result;
      state.loading[action.payload.type] = false;
      state.error = '';
    },
    marginFailure: (state, action) => {
      state.loading[action.payload.type] = false;
      state.margin[action.payload.type] = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default marginSlice.reducer;
export const {marginLoading, marginSuccess, marginFailure} = marginSlice.actions;
