import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {SelectProps} from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {ReactComponent as Arrow} from 'assets/images/icons/Icons & UI-Dropdown-Dropdown-Caret_Passive.svg';
import Checkbox from '@mui/material/Checkbox';
import {Box, Button, InputAdornment, MenuList, Popover, TextField} from '@mui/material';
import {useTheme} from '@mui/material';
import React from 'react';

export interface ISelectOptions {
  label: string;
  value: string;
}

interface ISelectProps extends SelectProps {
  options: ISelectOptions[];
  defaultValue: string[];
  value?: string[];
  onMultiSelectApply?: (event: any) => void;
}

const CustomMultiSelect = (props: ISelectProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValues(props.value as string[]);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const onApply = () => {
    const event = {
      target: {
        name: props.name,
        value: values
      }
    };
    props.onMultiSelectApply && props.onMultiSelectApply(event);
    setAnchorEl(null);
  };
  const onReset = () => {
    setValues([]);
  };
  const [values, setValues] = React.useState<string[]>(props?.defaultValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValues([...values, event.target.value]);
    } else {
      const index = values.indexOf(event.target.value);
      if (index > -1) {
        setValues([...values.slice(0, index), ...values.slice(index + 1)]);
      }
    }
  };
  const theme = useTheme();
  return (
    <FormControl
      sx={{
        alignSelf: 'center',
        '& .MuiSelect-icon.MuiSelect-iconStandard': {
          right: '4px',
          top: 'unset'
        }
      }}
      variant='standard'
    >
      {props.title && (
        <Typography
          id={`multiSelect-label-${props.id}`}
          sx={{
            p: 0,
            my: 0.8,
            fontWeight: 'bold',
            color: theme.palette.grayscale?.[600],
            fontSize: '12px'
          }}
          textAlign='left'
        >
          {props.title}
        </Typography>
      )}
      <TextField
        variant='standard'
        InputProps={{
          style: {
            height: '33px',
            width: '150px',
            paddingBottom: '20px',
            paddingTop: '15px'
          },
          disableUnderline: true,
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end'>
              <Button onClick={handleClick} sx={{minWidth: 0, alignContent: 'right'}}>
                <Arrow></Arrow>
              </Button>
            </InputAdornment>
          )
        }}
        value={values}
      ></TextField>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          maxHeight: '400px',
          width: '100%',
          borderRadius: 0,
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          '&.MuiPaper-root': {
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            borderBottom: '1px solid rgba(224, 224, 224, 1)'
          }
        }}
      >
        <Box>
          <Box>
            <MenuList sx={{height: '300px', overflowY: 'scroll'}}>
              {props.options.map((ele, i) => {
                return (
                  <MenuItem
                    id={`id-customMultiSelect${ele.label}menu`}
                    key={`customMultiSelect${ele.label}menu${i}`}
                    value={ele.value}
                  >
                    {values && (
                      <Checkbox
                        id={`checkBox-${ele.label + i}`}
                        checked={values.findIndex((item: any) => item === ele.value) > -1}
                        onChange={handleChange}
                        value={ele.label}
                      />
                    )}
                    <Typography
                      variant='subtitle2'
                      sx={{fontSize: '14px', minWidth: '120px'}}
                      textAlign='left'
                    >
                      {ele.label}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Box>
          <Box
            sx={{
              height: '25px',
              padding: '5px 2px',
              borderTop: '1px solid lightGrey',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              paddingTop: '10px'
            }}
          >
            <Button
              onClick={onReset}
              id={`${props.id}-gridFilter-resetAll`}
              sx={{
                marginBottom: '2px',
                float: 'left',
                '&:hover': {
                  bgcolor: 'unset'
                }
              }}
            >
              <Typography variant='button' sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                RESET
              </Typography>
            </Button>
            <Button
              disableElevation
              variant={'contained'}
              onClick={onApply}
              id={`date-filter-apply-selection-${props.id}`}
              sx={{
                marginBottom: '2px',
                float: 'right',
                bgcolor: (theme) => theme.palette.primary.main,
                fontFamily: 'Roboto Regular',
                color: 'common.whirete',
                '&.Mui-disabled': {
                  fontFamily: 'Roboto Regular',
                  bgcolor: (theme) => theme.palette.grayscale?.[200]
                }
              }}
            >
              APPLY
            </Button>
          </Box>
        </Box>
      </Popover>
    </FormControl>
  );
};

export default CustomMultiSelect;
