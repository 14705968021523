import {createSlice} from '@reduxjs/toolkit';
export interface newPOState {
  loading: boolean;
  newPO: Array<any>;
  error: string;
}

const initialState: newPOState = {
  loading: false,
  newPO: [],
  error: ''
};

const newPOSlice = createSlice({
  name: 'newPO',
  initialState,
  reducers: {
    newPOLoading: (state) => {
      state.loading = true;
    },
    newPOSuccess: (state, action) => {
      state.newPO = action.payload;
      state.loading = false;
    },
    newPOFailure: (state) => {
      state.loading = false;
      state.newPO = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default newPOSlice.reducer;
export const {newPOLoading, newPOSuccess, newPOFailure} = newPOSlice.actions;
