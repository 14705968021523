import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  productStoreListLoading,
  productStoreListFailure,
  productStoreListSuccess
} from 'redux/product/list/productStoreList/productStoreListSlice';

export const productStoreListSagaActions = {
  FETCH_PRODUCT_STORE_LIST_DATA: 'FETCH_PRODUCT_STORE_LIST_DATA'
};

export function* fetchProductStoreListData(action: any): any {
  try {
    yield put(productStoreListLoading());
    const {data} = yield call(ProductAPIS.getProductStoreData, action.payload);
    yield put(productStoreListSuccess(data));
  } catch (error) {
    yield put(productStoreListFailure());
  }
}

export default function* watchFetchProductStoreListData() {
  yield takeEvery(
    productStoreListSagaActions.FETCH_PRODUCT_STORE_LIST_DATA,
    fetchProductStoreListData
  );
}
