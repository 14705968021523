import {createSlice} from '@reduxjs/toolkit';
export interface salesState {
  loading: boolean;
  sales: Array<any>;
  error: string;
}

const initialState: salesState = {
  loading: false,
  sales: [],
  error: ''
};

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    salesLoading: (state) => {
      state.loading = true;
    },
    salesSuccess: (state, action) => {
      state.sales = action.payload;
      state.loading = false;
    },
    salesFailure: (state) => {
      state.loading = false;
      state.sales = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default salesSlice.reducer;
export const {salesLoading, salesSuccess, salesFailure} = salesSlice.actions;
