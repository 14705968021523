import {createSlice} from '@reduxjs/toolkit';
export interface receivedPoItemState {
  loading: boolean;
  receivedPoItem: Array<any>;
  error: string;
}

const initialState: receivedPoItemState = {
  loading: false,
  receivedPoItem: [],
  error: ''
};

const receivedPoItemSlice = createSlice({
  name: 'receivedPoItem',
  initialState,
  reducers: {
    receivedPoItemLoading: (state) => {
      state.loading = true;
    },
    receivedPoItemSuccess: (state, action) => {
      state.receivedPoItem = action.payload;
      state.loading = false;
    },
    receivedPoItemFailure: (state) => {
      state.loading = false;
      state.receivedPoItem = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default receivedPoItemSlice.reducer;
export const {receivedPoItemLoading, receivedPoItemSuccess, receivedPoItemFailure} =
  receivedPoItemSlice.actions;
