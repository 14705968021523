import {takeEvery, put, call} from 'redux-saga/effects';
import {ReportAPIS} from 'redux/report/report_service';
import {
  receivedPoItemLoading,
  receivedPoItemSuccess,
  receivedPoItemFailure
} from 'redux/report/inventory/receivedPoItem/receivedPoItemSlice';

export const receivedPoItemSagaActions = {
  FETCH_RECEIVEDPOITEM_SAGA: 'FETCH_RECEIVEDPOITEM_SAGA'
};

export function* fetchReceivedPoItemData(action: any): any {
  try {
    yield put(receivedPoItemLoading());
    const {data} = yield call(ReportAPIS.getReceivedPoItemData, action.payload);
    yield put(receivedPoItemSuccess(data));
  } catch (error) {
    yield put(receivedPoItemFailure());
  }
}

export default function* watchFetchReceivedPoItemData() {
  yield takeEvery(receivedPoItemSagaActions.FETCH_RECEIVEDPOITEM_SAGA, fetchReceivedPoItemData);
}
