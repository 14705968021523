import {Box, Typography, Modal, Avatar, Stack} from '@mui/material';
import {indigo} from '@mui/material/colors';
import CustomButton from 'components/baseComponents/customButton';
import {ReactComponent as Gear} from 'assets/images/icons/Icons & UI-Settings-Settings_Blue.svg';
import {ReactComponent as Logout} from 'assets/images/icons/Icons & UI-Door-Contract_Passive.svg';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import React from 'react';
import {getProfileSagaActions} from 'redux/dashboard/profile/getProfileSaga';
import {userOption} from 'features/purchaseOrder/sections/generalInfo/generalInfoEdit';

const style = {
  position: 'absolute',
  top: '25%',
  right: '-7%',
  transform: 'translate(-50%, -50%)',
  width: 290,
  height: 220,
  bgcolor: '#FFFFFF',
  boxShadow: 24,
  borderRadius: '4px',
  p: 4
};
interface IPropType {
  // atpEnabler?: (val: boolean) => void;
  open: boolean;
  handleClose: () => void;
  handleAccountModal: () => void;
  handleLogout: () => void;
}
interface IProfileData {
  name: string;
  workEmail: string;
  workPhoneNumber: string;
  mobilePhoneNumber: string;
  homePhoneNumber: string;
  personalEmail: string;
}
//We are creating the main componenet
const ProfileOverlay: React.FC<IPropType> = (props) => {
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const {getProfile} = useAppSelector((state) => state.getProfileSlice);
  //For Binding Data
  React.useEffect(() => {
    dispatch({
      type: getProfileSagaActions.FETCH_GETPROFILE_DATA
    });
  }, []);
  const profileData: IProfileData = getProfile as IProfileData;
  return (
    <>
      <Modal open={props.open} onClose={props.handleClose}>
        <Box id='main-logged-in-box' sx={style}>
          <Box id='header-box' sx={{borderBottom: '1px solid gray', pb: '20px'}}>
            {/* For Avatar and Name with Email stack in same row*/}
            <Stack direction='row' spacing={2}>
              {/* creating Avatar with  */}
              <Avatar sx={{bgcolor: indigo[700], width: '56px', height: '56px'}}>
                <Typography
                  sx={{
                    height: '28px',
                    width: '28px',
                    color: '#FFFFFF',
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    letterSpacing: 0,
                    lineHeight: '28px',
                    textAlign: 'center'
                  }}
                >
                  JS
                </Typography>
              </Avatar>
              {/* For Name and Email in same Column */}
              <Stack direction='column'>
                <Typography
                  sx={{
                    height: '14px',
                    width: '126px',
                    color: '#102B61',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontWeight: 500,
                    letterSpacing: '0.15px',
                    lineHeight: '24px',
                    // paddingLeft: '24px',
                    paddingRight: '91px',
                    paddingTop: '15px'
                  }}
                >
                  {userOption.map((element) => element.value)}
                </Typography>
                <Typography
                  sx={{
                    height: '20px',
                    width: '125px',
                    color: '#0B0B0B',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    letterSpacing: '0.25px',
                    lineHeight: '20px',
                    paddingLeft: '24px',
                    paddingRight: '91px'
                  }}
                >
                  {profileData.workEmail}
                  {/* xyx@gmail.com */}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box
            id='logged-in-body-box'
            sx={{
              paddingTop: '30px',
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column'
            }}
          >
            <Stack direction='row' spacing={2} sx={{marginBottom: '25px'}}>
              <Box>
                <CustomButton
                  id='custom-button-profileOverlay-setting'
                  onClick={props.handleAccountModal}
                  sx={{padding: 0}}
                >
                  <Gear />
                  ACCOUNT SETTINGS
                </CustomButton>
              </Box>
            </Stack>
            <Stack direction='row'>
              <Box>
                <CustomButton
                  id='custom-button-profileOverlay-logout'
                  onClick={props.handleLogout}
                  sx={{padding: 0}}
                >
                  <Logout />
                  LOG OUT
                </CustomButton>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default ProfileOverlay;
