import {
  Box,
  Grid,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  styled,
  Paper,
  TableContainer,
  Typography
} from '@mui/material';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import moment from 'moment';
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {channelSagaActions} from 'redux/dashboard/master/channelSaga';
import {categorySupplierSagaActions} from 'redux/report/sales/categorySupplier/categorySupplierSaga';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {filterFieldTypes, IFilterTemplate} from 'components/customTable/types';
import ReportLineGraphComponent, {IAxis} from './reportLineGraph';
import {districtSagaActions} from 'redux/dashboard/master/districtSaga';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';
import {vendorSagaActions} from 'redux/dashboard/master/vendorSaga';
import {warehouseSagaActions} from 'redux/dashboard/master/warehouseSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {decimalConverter, generateLabelValue, generatePayload} from 'util/arrayOperations';

const viewByOptions = [
  {
    label: 'Week',
    value: 'week'
  },
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Year',
    value: 'year'
  }
];

// const compSalesOptions = [
//   {
//     label: 'Yes',
//     value: 'Yes'
//   },
//   {
//     label: 'No',
//     value: 'No'
//   }
// ];

interface ICategorySupplierSales {
  comp: string;
  compPlan: string;
  duration: string;
  lastYearSales: string;
  sales: string;
  salesPlan: string;
  yearOverYear: string;
}

const LabelTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-right-style: solid;
    width: 40px;
    max-height: 20px;
    overflow: hidden;
    font-weight: bold;
    flex-grow: 1;
    border-right-color: ${(props) => props.theme.palette.grayscale?.[300]};
    border-right-width: 2px;
    padding-right: ${(props) => props.theme.spacing(2)};
    background-color: ${(props) => props.theme.palette.grayscale?.[100]};
  }
`;
const ValueTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-right-style: solid;
    border-right-color: ${(props) => props.theme.palette.grayscale?.[300]};
    border-right-width: 2px;
    flex-grow: 1;
    display: flex;
    max-width: 1140px;
    width: 1140px;
    padding: 0;
    justify-content: center;
    min-height: 52px;
  }
`;

const TotalTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-weight: bold;
    flex-grow: 1;
    width: 75px;
    background-color: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

const CategorySupplierSales: React.FC = () => {
  //For API Data Fetching
  const dispatch = useAppDispatch();
  const xAxis = {
    name: 'duration',
    displayTitle: 'duration',
    format: 'MMM YYYY'
  };

  const {master} = useAppSelector((state) => state.masterSlice);
  const {vendor} = useAppSelector((state) => state.vendorSlice);
  // const { channel } = useAppSelector((state) => state.channelSlice);
  const {district} = useAppSelector((state) => state.districtSlice);
  const {store} = useAppSelector((state) => state.storeSlice);
  const {region} = useAppSelector((state) => state.regionSlice);
  const {warehouse} = useAppSelector((state) => state.warehouseSlice);

  const [dataOne, dataTwo, dataThree] = master;

  const {categorySupplier, loading, error} = useAppSelector((state) => state.categorySupplierSlice);
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: vendorSagaActions.FETCH_VENDOR_DATA
    });
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
    // dispatch({
    //   type: channelSagaActions.FETCH_CHANNEL_DATA
    // });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: warehouseSagaActions.FETCH_WAREHOUSE_DATA
    });
  }, []);

  const defaultDateFormat = 'MM/DD/YYYY';
  const dataLength = 13;
  const dbData: ICategorySupplierSales[] = categorySupplier['sales'] as ICategorySupplierSales[];

  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewBy',
      title: 'View By',
      defaultValue: 'month',
      sx: {
        width: '150px'
      },
      options: viewByOptions
    },
    // {
    //   type: filterFieldTypes.DROPDOWN,
    //   property: 'compSales',
    //   title: 'Comp(Sales)',
    //   defaultValue: 'Yes',
    //   sx: {
    //     width: '150px'
    //   },
    //   options: compSalesOptions
    // },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      sx: {
        width: '150px'
      },
      placeholder: 'Departments',
      defaultValue: 'All Departments',
      allowAllOptions: true,
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      sx: {
        width: '150px'
      },
      allowAllOptions: true,
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'vendor',
      title: 'Vendor',
      placeholder: 'Vendor',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      options: generateLabelValue(vendor, 'vendorName')
    },
    // {
    //   type: filterFieldTypes.DROPDOWN,
    //   property: 'channel',
    //   title: 'Channel',
    //   defaultValue: 'All Channel',
    //   placeholder: 'Channel',
    //   sx: {
    //     width: '150px'
    //   },
    //   options: generateLabelValue(channel, 'channel')
    // },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'regionName',
      title: 'Store Region',
      defaultValue: 'All Regions',
      placeholder: 'Regions',
      mode: 'Secondary',
      allowAllOptions: true,
      sx: {
        width: '200px'
      },
      options: generateLabelValue(region, 'regionName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'districtName',
      title: 'Store District',
      defaultValue: 'All Districts',
      placeholder: 'Districts',
      allowAllOptions: true,
      mode: 'Secondary',
      sx: {
        width: '200px'
      },
      options: generateLabelValue(district, 'districtName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeName',
      title: 'Store',
      defaultValue: 'All Stores',
      placeholder: 'Stores',
      mode: 'Secondary',
      allowAllOptions: true,
      sx: {
        width: '200px'
      },
      options: generateLabelValue(store, 'storeName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'warehouseName',
      title: 'Warehouse',
      mode: 'Secondary',
      placeholder: 'Warehouse',
      defaultValue: 'All Warehouse',
      allowAllOptions: true,
      sx: {
        width: '200px'
      },
      options: generateLabelValue(warehouse, 'warehouseName')
    }
  ];
  const location: any = useLocation();

  const defaultData = filterTemplate.reduce((acc: any, obj) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);
  const [page, setPage] = React.useState<number>(0);
  const [selectedHeader, setHeader] = React.useState<string | undefined>(undefined);

  /**
   * Db method call when page no change
   * @param pageNo Page No
   */
  const salesDBApiCall = (pageNo: number) => {
    const fromDate =
      pageNo === 0
        ? moment().format(defaultDateFormat)
        : moment()
            .subtract(pageNo * dataLength, filtervalues.viewBy || 'months')
            .format(defaultDateFormat);
    const toDate =
      pageNo === 0
        ? moment(fromDate, defaultDateFormat, true)
            .subtract(dataLength, filtervalues.viewBy || 'months')
            .format(defaultDateFormat)
        : moment(fromDate, defaultDateFormat, true)
            .subtract(dataLength + 1, filtervalues.viewBy || 'months')
            .format(defaultDateFormat);
    dispatch({
      type: categorySupplierSagaActions.FETCH_CATEGORYSUPPLIER_SAGA,
      payload: {
        type: 'sales',
        fromDate: toDate,
        toDate: fromDate,
        data: generatePayload(filtervalues)
      }
    });
  };

  /**
   * When there is filter change set the page no to 0
   */
  React.useEffect(() => {
    if (page != 0) {
      setPage(0);
    }
    salesDBApiCall(0);
  }, [filtervalues]);
  /**
   * Fetch new previous set of data
   */
  const prevClick = () => {
    let pageNo = page;
    if (page === 1) {
      setPage(0);
      pageNo = 0;
    } else {
      pageNo = page - 1;
      setPage(page - 1);
    }
    salesDBApiCall(pageNo);
  };
  /**
   * Fetch new previous set of data
   */
  const nextClick = () => {
    setPage(page + 1);
    salesDBApiCall(page + 1);
  };

  ////// Create YAxis dynamically based on API object
  const yAxis: Array<IAxis> = [
    {
      name: 'sales',
      displayTitle: 'sales'
    },
    {
      name: 'lastYearSales',
      displayTitle: 'LY Sales'
    },
    {
      name: 'salesPlan',
      displayTitle: 'salesPlan'
    }
  ];

  /**
   * OnChange of filter
   * @param values Filter values
   */
  const onFilterApply = (values: IFilterValues) => {
    setFilterValues(values);
  };

  const layout = {
    width: 1170,
    height: 326,
    autosize: false,
    showlegend: false,
    paper_bgcolor: 'rgba(0,0,0,0)', // transparent
    plot_bgcolor: 'rgba(0,0,0,0)', // transparent
    font: {
      size: 12
    },
    xaxis: {
      showgrid: false,
      showline: false,
      automargin: false, ///used to display x axis label
      range: [0.5, dataLength + 0.5] /// In Sales, we can only view dataLength=13 records only.
    } as unknown as Plotly.LayoutAxis,
    yaxis: {
      showline: false,
      automargin: true
    },
    margin: {
      l: 1,
      r: 2,
      b: 1,
      t: 0,
      pad: 1
    }
  };

  /**
   * IF page is 0 then display dataLength + 1
   * else dataLength + 2
   * as we need to display extended line after node
   */
  const displayData = dbData ? dbData.slice(0, page === 0 ? dataLength + 1 : dataLength + 2) : [];
  const RenderRow = (props: any) => {
    const {data, property} = props;
    const pageData = data.slice(0, dataLength + 1);
    return (
      <>
        {pageData &&
          pageData.slice(1, pageData.length)?.map((ele: ICategorySupplierSales) => {
            const value = ele[property as keyof ICategorySupplierSales] as string;
            const afterDecimalConverter = Number(decimalConverter(value)).toLocaleString();
            return (
              <Box
                key={`categorySales-${property}-${ele.duration}`}
                sx={{
                  flexGrow: 1,
                  width: 5,
                  textAlign: 'right',
                  background: ele?.duration === selectedHeader ? 'rgba(38, 62, 181, 0.05)' : '',
                  borderRight:
                    ele?.duration === selectedHeader //add left right border to cell when node is selected
                      ? `2px solid #263EB5`
                      : `2px solid transparent`,
                  borderLeft:
                    ele?.duration === selectedHeader //add left right border to cell when node is selected
                      ? `2px solid #263EB5`
                      : `2px solid transparent`,
                  //change btm border clr of last cell in tble on node selectn
                  borderBottom:
                    property === 'compPlan' && ele?.duration === selectedHeader
                      ? `2px solid #263EB5`
                      : `none`,
                  py: 2
                }}
              >
                <Typography variant='body2' sx={{paddingRight: 0.625}}>
                  {
                    // eslint-disable-next-line no-constant-condition, @typescript-eslint/no-unused-vars
                    afterDecimalConverter == '0'
                      ? ''
                      : `${props?.prefixText ? props?.prefixText : ''}${afterDecimalConverter}${
                          props?.suffixText ? props?.suffixText : ''
                        }`
                  }
                </Typography>
              </Box>
            );
          })}
      </>
    );
  };

  /////// Calculate the sum to display Total column
  const aggrPageData = dbData.slice(0, dataLength + 1);

  const consolidatedData = aggrPageData.reduce(
    (accumulator: any, c: any) => {
      accumulator.sales = accumulator.sales + parseFloat(c['sales'] !== null ? c['sales'] : 0);
      accumulator.salesPlan =
        accumulator.salesPlan + parseFloat(c['salesPlan'] !== null ? c['salesPlan'] : 0);
      accumulator.yearOverYear =
        accumulator.yearOverYear + parseFloat(c['yearOverYear'] !== null ? c['yearOverYear'] : 0);
      accumulator.comp = accumulator.comp + parseFloat(c['comp'] !== null ? c['comp'] : 0);
      accumulator.compPlan =
        accumulator.compPlan + parseFloat(c['compPlan'] !== null ? c['compPlan'] : 0);
      return accumulator;
    },
    {
      sales: 0,
      salesPlan: 0,
      yearOverYear: 0,
      comp: 0,
      compPlan: 0
    }
  );

  const getSum = (arr: any[], property: string) => {
    const filteredArr = arr.filter((e) => e[property] !== null);
    let sum =
      filteredArr.length > 0 &&
      filteredArr.reduce((acc, cur) => {
        return acc + parseFloat(cur[property]);
      }, 0);
    if (property === 'onHandMargin') {
      if (sum) {
        sum = sum / filteredArr.length;
        sum = sum.toFixed(2);
      }
    }

    return sum ? sum : 0;
  };

  if (dbData && dbData.length > 0 && !error) {
    const onHandSum = getSum(dbData, 'onHandDollars');
    const onHandCasesSum = getSum(dbData, 'onHandUnits');
    const onHandMarginSum = getSum(dbData, 'onHandMargin');
    const onOrderSum = getSum(dbData, 'onOrderDollars');
    const onOrderCasesSum = getSum(dbData, 'onOrderUnits');
    const inQCSum = getSum(dbData, 'inQcDollars');
    const inQCCases = getSum(dbData, 'inQcUnits');

    return (
      <>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <GridFilter
              values={filtervalues}
              filterTemplate={filterTemplate}
              onFilterChange={onFilterApply}
              id='report-category-supplier-sales'
              onReset={() => {
                setFilterValues(defaultData);
              }}
            />
          </Grid>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <CustomLoader id='product-sales-load' />
            </Box>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={(theme) => ({
                lineHeight: theme.spacing(7),
                backgroundColor: theme.palette?.primary?.contrastText
              })}
            >
              <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'}>
                <Box sx={{textAlign: 'center'}}>
                  ON HAND: {onHandSum.toLocaleString()} Cases ${onHandCasesSum.toLocaleString()}{' '}
                  {onHandMarginSum.toLocaleString()}% Margin
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  ON ORDER: {onOrderSum.toLocaleString()} Cases ${onOrderCasesSum.toLocaleString()}{' '}
                  0% Margin
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  ON HAND + ON ORDER: {(onHandSum + onOrderSum).toLocaleString()} Cases $
                  {(onHandCasesSum + onOrderCasesSum).toLocaleString()}
                </Box>
                <Box sx={{textAlign: 'center'}}>
                  IN QC: {inQCSum.toLocaleString()} Cases ${inQCCases.toLocaleString()}
                </Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <ReportLineGraphComponent
                data={displayData}
                xAxis={xAxis}
                layout={layout}
                yAxis={[...yAxis]}
                id='categorySupplierSales'
                prevClick={prevClick}
                pageNo={page}
                nextClick={nextClick}
                size={'large'}
                selectedColumnHeader={selectedHeader}
                updateColumnHeader={setHeader}
                filterValue={filtervalues}
              />
            </Paper>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              overflow: 'hidden'
            }}
            xs={12}
            md={12}
            lg={12}
          >
            <TableContainer component={Paper} sx={{width: '99.9%'}}>
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LabelTableCell>Sales</LabelTableCell>
                    <ValueTableCell sx={{width: '1170px'}}>
                      <RenderRow
                        data={dbData}
                        property={'sales'}
                        id='totalCategorySuppliersSales'
                        shouldHighlight={true}
                        prefixText='$'
                      />
                    </ValueTableCell>
                    <TotalTableCell>${consolidatedData.sales.toLocaleString()}</TotalTableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LabelTableCell>Sales plan</LabelTableCell>
                    <ValueTableCell id='totalCategorySuppliersPlan'>
                      <RenderRow
                        id='totalCategorySuppliersPlan'
                        data={dbData}
                        property={'salesPlan'}
                        prefixText='$'
                      />
                    </ValueTableCell>
                    <TotalTableCell>
                      ${consolidatedData?.salesPlan?.toLocaleString()}
                    </TotalTableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LabelTableCell>Year Over Year</LabelTableCell>
                    <ValueTableCell>
                      <RenderRow
                        id='totalCategorySuppliersYoy'
                        data={dbData}
                        property={'yearOverYear'}
                        suffixText='%'
                      />
                    </ValueTableCell>
                    <TotalTableCell>
                      {(
                        consolidatedData.yearOverYear /
                        aggrPageData.filter((e) => e.yearOverYear !== null).length
                      ).toLocaleString()}
                      %
                    </TotalTableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LabelTableCell>Comp %</LabelTableCell>
                    <ValueTableCell>
                      <RenderRow
                        id='totalCategorySuppliersComp'
                        data={dbData}
                        property={'comp'}
                        suffixText='%'
                      />
                    </ValueTableCell>
                    <TotalTableCell>
                      {(consolidatedData.comp / aggrPageData.length).toLocaleString()}%
                    </TotalTableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LabelTableCell>Comp % Plan</LabelTableCell>
                    <ValueTableCell>
                      <RenderRow
                        id='totalCategorySuppliersCompPlan'
                        data={dbData}
                        property={'compPlan'}
                        suffixText='%'
                      />
                    </ValueTableCell>
                    <TotalTableCell>
                      {(consolidatedData.compPlan / aggrPageData.length).toLocaleString()}%
                    </TotalTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <GridFilter
            values={filtervalues}
            filterTemplate={filterTemplate}
            onFilterChange={onFilterApply}
            id='report-category-supplier-sales'
            onReset={() => {
              setFilterValues(defaultData);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              maxHeight: '844px'
            }}
          >
            {' '}
            {loading ? (
              <CustomLoader id='report-category-supplier-load' />
            ) : (
              <CustomError
                id='report-category-supplier-load'
                onReload={() => {
                  salesDBApiCall(0);
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
};

export default CategorySupplierSales;
