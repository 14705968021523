import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  districtLoading,
  districtFailure,
  districtSuccess
} from 'redux/dashboard/master/districtSlice';

export const districtSagaActions = {
  FETCH_DISTRICT_DATA: 'FETCH_DISTRICT_DATA'
};

export function* fetchDistrictData(): any {
  try {
    yield put(districtLoading());
    const {data} = yield call(ProductAPIS.getDistrictData);
    yield put(districtSuccess(data));
  } catch (error) {
    yield put(districtFailure());
  }
}

export default function* watchFetchDistrictData() {
  yield takeEvery(districtSagaActions.FETCH_DISTRICT_DATA, fetchDistrictData);
}
