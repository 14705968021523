import {takeEvery, put, call} from 'redux-saga/effects';
import {VendorAPIS} from 'redux/vendor/vendorDetails_service';
import {
  supplyChainLoading,
  supplyChainFailure,
  supplyChainSuccess
} from 'redux/vendor/detailsItemTab/supplyChainSlice';

export const supplyChainSagaActions = {
  FETCH_SUPPLYCHAIN_DATA: 'FETCH_SUPPLYCHAIN_DATA'
};

export function* fetchSupplyChainData(action: any): any {
  try {
    yield put(supplyChainLoading());
    const {data} = yield call(VendorAPIS.getSupplyChainData, action.payload);
    yield put(supplyChainSuccess(data));
  } catch (error) {
    yield put(supplyChainFailure());
  }
}

export default function* watchFetchsupplyChainData() {
  yield takeEvery(supplyChainSagaActions.FETCH_SUPPLYCHAIN_DATA, fetchSupplyChainData);
}
