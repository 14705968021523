import {createSlice} from '@reduxjs/toolkit';
export interface distrubutionCenterState {
  loading: boolean;
  distrubutionCenter: Array<any>;
  error: string;
}

const initialState: distrubutionCenterState = {
  loading: false,
  distrubutionCenter: [],
  error: ''
};

const distrubutionCenterSlice = createSlice({
  name: 'distrubutionCenter',
  initialState,
  reducers: {
    distrubutionCenterLoading: (state) => {
      state.loading = true;
    },
    distrubutionCenterSuccess: (state, action) => {
      state.distrubutionCenter = action.payload;
      state.loading = false;
    },
    distrubutionCenterFailure: (state) => {
      state.loading = false;
      state.distrubutionCenter = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default distrubutionCenterSlice.reducer;
export const {distrubutionCenterLoading, distrubutionCenterSuccess, distrubutionCenterFailure} =
  distrubutionCenterSlice.actions;
