import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  cycleCountFailure,
  cycleCountLoading,
  cycleCountSuccess
} from 'redux/dashboard/cycleCountVariance/cycleCountSlice';

export const cycleCountSagaActions = {
  FETCH_CYCLECOUNT_DATA: 'FETCH_CYCLECOUNT_DATA'
};

export function* fetchCycleCountData(action: any) {
  try {
    yield put(cycleCountLoading({type: action.payload}));
    const {data} = yield call(DashboardServiceAPIS.getCycleCountData, action.payload);
    yield put(cycleCountSuccess({result: data, type: action.payload.type}));
  } catch (error) {
    yield put(cycleCountFailure(action));
  }
}

export default function* watchFetchCycleCountData() {
  yield takeEvery(cycleCountSagaActions.FETCH_CYCLECOUNT_DATA, fetchCycleCountData);
}
