import {createSlice} from '@reduxjs/toolkit';
export interface productDetailsState {
  loading: boolean;
  productDetails: any;
  error: string;
}

const initialState: productDetailsState = {
  loading: false,
  productDetails: {},
  error: ''
};

const productDetailsSlice = createSlice({
  name: 'productDetails',
  initialState,
  reducers: {
    productDetailsLoading: (state) => {
      state.loading = true;
    },
    productDetailsSuccess: (state, action) => {
      state.productDetails = action.payload;
      state.loading = false;
    },
    productDetailsFailure: (state) => {
      state.loading = false;
      state.productDetails = {};
      state.error = 'Request failed with status code 404';
    }
  }
});

export default productDetailsSlice.reducer;
export const {productDetailsLoading, productDetailsSuccess, productDetailsFailure} =
  productDetailsSlice.actions;
