import * as React from 'react';
import {Box, Grid, Stack, Typography, useTheme} from '@mui/material';
import CustomSelect from 'components/baseComponents/customSelect';
import CustomInput from 'components/baseComponents/customInput';
import {IGeneralInfo, POStatus} from 'features/purchaseOrder/types';
import CustomDatePicker, {
  CalenderComponent,
  DatePickerInput
} from 'components/baseComponents/customDatePicker';
import moment from 'moment';
import CustomTimePicker, {TimePickerInput} from 'components/baseComponents/customTimePicker';
import {purchaseOrderTypeSagaActions} from 'redux/dashboard/master/purchaseOrderSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {generateLabelValue} from 'util/arrayOperations';
import {loadConditionSagaActions} from 'redux/dashboard/master/loadConditionSaga';
import {freightTermSagaActions} from 'redux/dashboard/master/freightTermSaga';
import {deliveryConditionSagaActions} from 'redux/dashboard/master/deliveryConditionSaga';
import {buyerSagaActions} from 'redux/dashboard/master/buyerSaga';
import {distrubutionCenterSagaActions} from 'redux/dashboard/master/distrubutionCenterSaga';
import {storeSagaActions} from 'redux/dashboard/master/storeSaga';

const poOptions = [
  {
    name: 'Standard PO',
    label: 'Standard PO',
    value: 'standardPO'
  },
  {
    name: 'Superior PO',
    label: 'Superior PO',
    value: 'superiorPO'
  }
];

const supplierOptions = [
  {
    label: '12345 Kellogg Company',
    value: 'kelloggCompany'
  }
];

const supplierCntctOpts = [
  {
    label: 'Danny Harris',
    value: 'DannyHarris'
  }
];

const buyerIdOpts = [
  {
    label: '80',
    value: '80'
  }
];
const paymentOption = [
  {
    label: 'NET 30',
    value: '0006'
  }
];

export const userOption = [
  {
    label: 'John Smith',
    value: 'John Smith'
  }
];

const siteOptions = [
  {
    label: 'WH 93 - Americold',
    value: 'Americold'
  }
];

const whOptions = [
  {
    label: 'F',
    value: 'F'
  }
];

const freightOptions = [
  {
    label: 'Pickup ',
    value: 'Pickup '
  }
  // {
  //   label: 'Delivery ',
  //   value: 'Delivery '
  // }
];

const fobOptions = [
  {
    label: 'Stockton CA 21',
    value: 'FOB'
  }
];

const loadOptions = [
  {
    label: 'Palletized',
    value: 'Palletized'
  }
];

const unloadingOptions = [
  {
    label: 'Driver',
    value: 'Driver'
  }
];

const deliveryOptions = [
  {
    label: '2 Trucks',
    value: '2 Trucks'
  }
];

const currencyOptions = [
  {
    label: 'USD',
    value: 'USD'
  }
];

const fixedRateOptions = [
  {
    label: 'Yes',
    value: 'true'
  },
  {
    label: 'No',
    value: 'false'
  }
];

const GeneralInfoEdit: React.FC<IGeneralInfo> = (props: IGeneralInfo) => {
  const mandatory = true;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {deliveryCondition} = useAppSelector((state) => state.deliveryConditionSlice);
  const {purchaseOrderType} = useAppSelector((state) => state.purchaseOrderTypeSlice);
  const {freightTerm} = useAppSelector((state) => state.freightTermSlice);
  const {loadCondition} = useAppSelector((state) => state.loadConditionSlice);
  const {buyer} = useAppSelector((state) => state.buyerSlice);
  const {distrubutionCenter} = useAppSelector((state) => state.distrubutionCenterSlice);
  const {store} = useAppSelector((state) => state.storeSlice);
  //Below we are combing the store and distribution center
  const combineWarehouseStore = [...distrubutionCenter, ...store];

  // We are trying to filter purchaseOrder type
  const purchaseOrder = purchaseOrderType.filter((element) => {
    return element.poTypeId === 'NB';
  });
  /**
   * Below we are filtering and mapping the combine warehouse and store to make same ID
   */
  const warehouseStore = combineWarehouseStore
    .filter((element) => {
      return element?.storeId !== 'R100';
    })
    .map((element) => {
      if (element.distributionCenterId || element.storeId) {
        return {
          warehouseStoreId: element.distributionCenterId || element.storeId,
          warehouseStoreName: element.distributionCenterName || element.storeName
        };
      }
    });

  React.useEffect(() => {
    dispatch({type: purchaseOrderTypeSagaActions.FETCH_PURCHASEORDER_TYPE_DATA});
    // dispatch({ type: deliveryConditionSagaActions.FETCH_DELIVERYCONDITION_DATA });
    // dispatch({ type: freightTermSagaActions.FETCH_FREIGHT_TERM_DATA });
    // dispatch({ type: loadConditionSagaActions.FETCH_LOADCONDITION_DATA });
    dispatch({
      type: distrubutionCenterSagaActions.FETCH_DISTRUBUTION_CENTER_DATA
    });
    dispatch({
      type: buyerSagaActions.FETCH_BUYER_DATA
    });
    dispatch({
      type: storeSagaActions.FETCH_STORE_DATA
    });
  }, []);
  return (
    <Box
      sx={{
        px: 2,
        py: 3,
        bgcolor: 'grayscale.50',
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.5)'
      }}
    >
      <Box sx={{pb: 2}}>
        <Typography
          variant='subtitle1'
          sx={{
            height: '22px',
            width: '78px',
            color: '#0B0B0B',
            fontFamily: 'Roboto',
            fontSize: '16px',
            letterSpacing: '0.15px',
            lineHeight: '22px'
          }}
        >
          Basic Data
        </Typography>
        {props?.poStatus.toUpperCase() === POStatus.DRAFT && (
          <Grid container spacing={2} sx={{pt: 2}}>
            <Grid item xs={3}>
              <Typography
                variant='caption'
                sx={{
                  color: 'grayscale.600',
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  display: 'flex'
                }}
              >
                PO Type
                <Typography
                  variant='caption'
                  sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
                >{`${mandatory ? '*' : ''}`}</Typography>
              </Typography>
              <CustomSelect
                name='purchaseOrder'
                id='custom-selectproduct-detail-grid'
                options={generateLabelValue(purchaseOrder, 'poTypeDesc', 'poTypeId')}
                sx={{
                  backgroundColor: 'common.white',
                  width: '300px',
                  px: '5px',
                  border: `1px solid ${theme.palette.grayscale?.[200]}`,
                  borderRadius: 1
                }}
                onChange={props.onValueChange}
                value={props.data.purchaseOrder || ''}
                required
              />
              {!props.data.purchaseOrder && props.submitStatus && (
                <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                  Please Select PO Type
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant='caption'
                sx={{
                  color: 'grayscale.600',
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  display: 'flex'
                }}
              >
                Issue Date
                <Typography
                  variant='caption'
                  sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
                >{`${mandatory ? '*' : ''}`}</Typography>
              </Typography>
              <CustomDatePicker
                value={props.data.issueDate || null}
                onChange={(newValue: any) => {
                  const event = {
                    target: {
                      name: 'issueDate',
                      value: moment(newValue).format('MM/DD/YYYY')
                    }
                  };

                  props.onValueChange(event);
                }}
                disablePast={true}
                disableFuture={true}
                renderInput={(params) => <DatePickerInput {...params} />}
                components={CalenderComponent}
              />
              {props.data.issueDate === 'Invalid date' && (
                <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                  InValid Date
                </Typography>
              )}
              {!props.data.issueDate &&
                (props.data.issueDate === 'Invalid date' || props.submitStatus) && (
                  <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                    Please Select Date
                  </Typography>
                )}
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant='caption'
                sx={{
                  color: 'grayscale.600',
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  display: 'flex'
                }}
              >
                Supplier
                <Typography
                  variant='caption'
                  sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
                >{`${mandatory ? '*' : ''}`}</Typography>
              </Typography>
              <CustomSelect
                id='custom-select-supplier'
                name='supplierName'
                options={generateLabelValue(props.supplierData, 'supplierName')}
                sx={{
                  backgroundColor: 'common.white',
                  width: '300px',
                  px: '5px',
                  border: `1px solid ${theme.palette.grayscale?.[200]}`,
                  borderRadius: 1
                }}
                onChange={props.onValueChange}
                value={props.data.supplierName || ''}
                required
              />
              {!props.data.supplierName && props.submitStatus && (
                <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                  Please Select Supplier
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant='caption'
                sx={{
                  color: 'grayscale.600',
                  fontFamily: 'Roboto Medium',
                  fontSize: '12px',
                  display: 'flex'
                }}
              >
                Supplier Contact
              </Typography>
              <CustomSelect
                id='customSelect-supplier-contact'
                name='contact'
                options={supplierCntctOpts}
                sx={{
                  backgroundColor: 'common.white',
                  width: '300px',
                  px: '5px',
                  border: `1px solid ${theme.palette.grayscale?.[200]}`,
                  borderRadius: 1
                }}
                onChange={props.onValueChange}
                value={props.data.contact || ''}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} sx={{pt: 2}}>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Purchasing Group (Buyer ID)
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-purchasing-group'
              name='buyerId'
              options={generateLabelValue(buyer, 'buyerId')}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.buyerId || ''}
              required
            />
            {!props.data.buyerId && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Purchasing Group (Buyer ID)
              </Typography>
            )}
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Created By (Buyer)
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-Created-by-(buyer)'
              name='buyerName'
              options={userOption}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.buyerName || ''}
              required
            />
            {!props.data.buyerName && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Created By (Buyer)
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{pt: 2}}>
        <Typography
          variant='subtitle1'
          sx={{
            height: '22px',
            width: '78px',
            color: '#0B0B0B',
            fontFamily: 'Roboto',
            fontSize: '16px',
            letterSpacing: '0.15px',
            lineHeight: '22px'
          }}
        >
          Delivery
        </Typography>
        <Grid container spacing={2} sx={{pt: 2}}>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Site (Warehouse/Store)
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-site-(Warehouse/Store)'
              name='warehouseStore'
              options={generateLabelValue(warehouseStore, 'warehouseStoreName', 'warehouseStoreId')}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.warehouseStore || ''}
              required
            />
            {!props.data.warehouseStore && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Site (Warehouse/Store)
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Warehouse Section
            </Typography>
            <CustomSelect
              id='custom-select-warehouse-section'
              name='warehouseSection'
              options={whOptions}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.warehouseSection || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Freight Terms
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-freight terms'
              name='freightTermDesc'
              options={freightOptions}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.freightTermDesc || ''}
              required
            />
            {!props.data.freightTermDesc && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Freight Terms
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              FOB/Orign
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-FOB/Orign'
              name='fobOrigin'
              options={fobOptions}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.fobOrigin || ''}
              required
            />
            {!props.data.fobOrigin && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select FOB/Orign
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{pt: 2}}>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Load Condition
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-load-condition'
              name='loadCondDesc'
              options={loadOptions}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.loadCondDesc || ''}
              required
            />
            {!props.data.loadCondDesc && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Load Condition
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Unloading
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-unloading'
              name='unloading'
              options={unloadingOptions}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.unloading || ''}
              required
            />
            {!props.data.unloading && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Unloading
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Delivery Condition
            </Typography>
            <CustomSelect
              id='custom-select-delivery-condition'
              name='deliveryCondDesc'
              options={deliveryOptions}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.deliveryCondDesc || ''}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{pt: 2}}>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Must Arrive By
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomDatePicker
              value={props.data.mustArriveBy || null}
              onChange={(newValue: any) => {
                const event = {
                  target: {
                    name: 'mustArriveBy',
                    value: moment(newValue).format('MM/DD/YYYY')
                  }
                };
                props.onValueChange(event);
              }}
              disablePast={true}
              renderInput={(params) => <DatePickerInput {...params} />}
              components={CalenderComponent}
            />
            {props.data.mustArriveBy === 'Invalid date' && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                InValid Date
              </Typography>
            )}
            {!props.data.mustArriveBy &&
              (props.data.mustArriveBy === 'Invalid date' || props.submitStatus) && (
                <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                  Please Select Date
                </Typography>
              )}
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Appointment Date
            </Typography>
            <CustomDatePicker
              value={props.data.appointmentDate || null}
              onChange={(newValue: any) => {
                const event = {
                  target: {
                    name: 'appointmentDate',
                    value: moment(newValue).format('MM/DD/YYYY')
                  }
                };
                props.onValueChange(event);
              }}
              renderInput={(params) => <DatePickerInput {...params} />}
              components={CalenderComponent}
            />
            {props.data.appointmentDate === 'Invalid date' ? (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                InValid Date
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Appointment Time
            </Typography>
            <Stack direction={'row'}>
              <CustomTimePicker
                value={props.data.appointmentTime || null}
                onChange={(newValue: any) => {
                  const event = {
                    target: {
                      name: 'appointmentTime',
                      value: moment(newValue).format('HH:mm (A|P)M')
                    }
                  };

                  props.onValueChange(event);
                }}
                renderInput={(params) => <TimePickerInput {...params} />}
              />
            </Stack>
            {props.data.appointmentTime === 'Invalid date' && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                InValid Time
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              ASN Date
            </Typography>
            <CustomDatePicker
              value={props.data.advanceShippingNotice || null}
              onChange={(newValue: any) => {
                const event = {
                  target: {
                    name: 'advanceShippingNotice',
                    value: moment(newValue).format('MM/DD/YYYY')
                  }
                };
                props.onValueChange(event);
              }}
              renderInput={(params) => <DatePickerInput {...params} />}
              components={CalenderComponent}
              disablePast
            />
            {props.data.advanceShippingNotice === 'Invalid date' && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                InValid Date
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{pt: 3}}>
        <Typography
          variant='subtitle1'
          sx={{
            height: '22px',
            width: '78px',
            color: '#0B0B0B',
            fontFamily: 'Roboto',
            fontSize: '16px',
            letterSpacing: '0.15px',
            lineHeight: '22px'
          }}
        >
          Invoice
        </Typography>
        <Grid container spacing={2} sx={{pt: 2}}>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Payment Terms
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-payment-terms'
              name='paymentTerms'
              options={paymentOption}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.paymentTerms || ''}
              required
            />
            {!props.data.paymentTerms && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Payment Terms
              </Typography>
            )}
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Invoicing Party
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-invoicing-party'
              name='invoicingParty'
              options={generateLabelValue(props.supplierData, 'invoicingParty')}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.invoicingParty || ''}
            />
            {!props.data.invoicingParty && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Invoicing Party
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{pt: 2}}>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Currency
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-currency'
              name='currency'
              options={currencyOptions}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.currency || ''}
              required
            />
            {!props.data.currency && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Currency
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Fixed Exchange Rate
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomSelect
              id='custom-select-fixed-exchange-rate'
              name='fixedExchangeRate'
              options={fixedRateOptions}
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                px: '5px',
                border: `1px solid ${theme.palette.grayscale?.[200]}`,
                borderRadius: 1
              }}
              onChange={props.onValueChange}
              value={props.data.fixedExchangeRate || ''}
              required
            />
            {!props.data.fixedExchangeRate && props.submitStatus && (
              <Typography sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}>
                Please Select Fixed Exchange Rate
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant='caption'
              sx={{
                color: 'grayscale.600',
                fontFamily: 'Roboto Medium',
                fontSize: '12px',
                display: 'flex'
              }}
            >
              Exchange Rate
              <Typography
                variant='caption'
                sx={{fontSize: '12px', pl: 0.4, color: 'error.main'}}
              >{`${mandatory ? '*' : ''}`}</Typography>
            </Typography>
            <CustomInput
              sx={{
                backgroundColor: 'common.white',
                width: '300px',
                borderRadius: 1
              }}
              name='exchangerate'
              value={props.data.exchangerate || '1.00000'}
              type='number'
              onChange={props.onValueChange}
              placeholder='Enter exchange rate'
              required
              InputProps={{
                readOnly: true
              }}
            />
            {/* {!props.data.exchangerate && props.submitStatus && (
              <Typography sx={{ fontSize: '12px', pl: 0.4, color: 'error.main' }}>
                Please Enter Exchange Rate
              </Typography>
            )} */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GeneralInfoEdit;
