import {createSlice} from '@reduxjs/toolkit';
export interface purchaseOrderState {
  loading: boolean;
  purchaseOrderType: Array<any>;
  error: string;
}

const initialState: purchaseOrderState = {
  loading: false,
  purchaseOrderType: [],
  error: ''
};

const purchaseOrderTypeSlice = createSlice({
  name: 'purchaseOrderType',
  initialState,
  reducers: {
    purchaseOrderTypeLoading: (state) => {
      state.loading = true;
    },
    purchaseOrderTypeSuccess: (state, action) => {
      state.purchaseOrderType = action.payload;
      state.loading = false;
    },
    purchaseOrderTypeFailure: (state) => {
      state.loading = false;
      state.purchaseOrderType = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default purchaseOrderTypeSlice.reducer;
export const {purchaseOrderTypeLoading, purchaseOrderTypeSuccess, purchaseOrderTypeFailure} =
  purchaseOrderTypeSlice.actions;
