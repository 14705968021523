import {createSlice} from '@reduxjs/toolkit';
export interface topTwentyVendorsState {
  loading: boolean;
  topTwentyVendors: Array<any>;
  error: string;
}

const initialState: topTwentyVendorsState = {
  loading: false,
  topTwentyVendors: [],
  error: ''
};

const topTwentyVendorsSlice = createSlice({
  name: 'topTwentyVendors',
  initialState,
  reducers: {
    topTwentyVendorsLoading: (state) => {
      state.loading = true;
    },
    topTwentyVendorsSuccess: (state, action) => {
      state.topTwentyVendors = action.payload;
      state.loading = false;
    },
    topTwentyVendorsFailure: (state) => {
      state.loading = false;
      state.topTwentyVendors = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default topTwentyVendorsSlice.reducer;
export const {topTwentyVendorsLoading, topTwentyVendorsSuccess, topTwentyVendorsFailure} =
  topTwentyVendorsSlice.actions;
