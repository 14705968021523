import {createSlice} from '@reduxjs/toolkit';
export interface deliveryConditionState {
  loading: boolean;
  deliveryCondition: Array<any>;
  error: string;
}

const initialState: deliveryConditionState = {
  loading: false,
  deliveryCondition: [],
  error: ''
};

const deliveryConditionSlice = createSlice({
  name: 'deliveryCondition',
  initialState,
  reducers: {
    deliveryConditionLoading: (state) => {
      state.loading = true;
    },
    deliveryConditionSuccess: (state, action) => {
      state.deliveryCondition = action.payload;
      state.loading = false;
    },
    deliveryConditionFailure: (state) => {
      state.loading = false;
      state.deliveryCondition = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default deliveryConditionSlice.reducer;
export const {deliveryConditionLoading, deliveryConditionSuccess, deliveryConditionFailure} =
  deliveryConditionSlice.actions;
