import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {alertLoading, alertFailure, alertSuccess} from 'redux/dashboard/master/alertSlice';

export const alertSagaActions = {
  FETCH_ALERT_DATA: 'FETCH_ALERT_DATA'
};

export function* fetchAlertData(): any {
  try {
    yield put(alertLoading());
    const {data} = yield call(ProductAPIS.getAlertData);
    yield put(alertSuccess(data));
  } catch (error) {
    yield put(alertFailure());
  }
}

export default function* watchFetchAlertData() {
  yield takeEvery(alertSagaActions.FETCH_ALERT_DATA, fetchAlertData);
}
