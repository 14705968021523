import {createSlice} from '@reduxjs/toolkit';
export interface vendorState {
  loading: boolean;
  vendor: Array<any>;
  error: string;
}

const initialState: vendorState = {
  loading: false,
  vendor: [],
  error: ''
};

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    vendorLoading: (state) => {
      state.loading = true;
    },
    vendorSuccess: (state, action) => {
      state.vendor = action.payload;
      state.loading = false;
    },
    vendorFailure: (state) => {
      state.loading = false;
      state.vendor = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default vendorSlice.reducer;
export const {vendorLoading, vendorSuccess, vendorFailure} = vendorSlice.actions;
