export const TABLE_ROWSPERPAGE = [5, 10, 25, 50, 100, 150];
export const TABLE_DEFAULTROWSPERPAGE = 5;
export const TicketingSystemUrl = 'https://www.google.com';
export const AdDetailPageUrl = 'https://www.google.com';
export const StoreAlertTicketingSystemUrl = 'https://www.google.com';
export const DepartmentLaborPageUrl = 'https://www.google.com';
export const StoreLaborPageUrl = 'https://www.google.com';
export const viewInFilterOptions = [
  {
    label: 'Dollars',
    value: 'Dollars'
  },
  {
    label: 'Units',
    value: 'Units'
  }
];

export const ROLE = {
  BUYER: 'BUYER',
  DISTRICTMANAGER: 'DISTRICT MANAGER',
  ASSTBUYER: 'ASST BUYER',
  STOREMANAGER: 'STORE MANAGER',
  EXECUTIVE: 'EXECUTIVE',
  ASSTBUYERSTOREMANAGER: 'ASSTBUYERSTOREMANAGER',
  ASSTBUYERSTOREDISRICTMANAGER: 'ASSTBUYERSTOREDISRICTMANAGER',
  ASSTBUYERSTOREDISTRICTMANAGEREXECUTIVE: 'ASSTBUYERSTOREDISTRICTMANAGEREXECUTIVE'
};

export const ROUTES = {
  PRODUCTS: '/Product',
  DASHBOARD: '/Dashboard',
  VENDORS: '/Vendor',
  REPORTS: '/Report',
  PO: '/PO',
  PREPRTUALINVENTORYAVERAGE: '/Report/PreprtualInventoryAverage',
  TASKMANAGEMENT: '/TaskManagement'
};

export const TILE_WIDTH = {
  one: '360px',
  two: '744px',
  three: '1128px'
};

export const TILE_SPACING = 24;
export const TILE_DEFULT_HEIGHT = '348px';

export const urlSeperator = '+&+';
