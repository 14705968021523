import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  itemAlertFailure,
  itemAlertSuccess,
  itemAlertLoading
} from 'redux/dashboard/itemAlert/itemAlertSlice';

export const itemAlertSagaActions = {
  FETCH_ITEMALERT_DATA: 'FETCH_ITEMALERT_DATA'
};

export function* fetchItemAlertData(action: any) {
  try {
    yield put(itemAlertLoading());
    const {data} = yield call(DashboardServiceAPIS.getItemAlertData, action.payload);
    yield put(itemAlertSuccess(data));
  } catch (error) {
    yield put(itemAlertFailure());
  }
}

export default function* watchFetchItemAlertData() {
  yield takeEvery(itemAlertSagaActions.FETCH_ITEMALERT_DATA, fetchItemAlertData);
}
