import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {Box} from '@mui/material';

interface IMessage {
  successMessage?: any;
  updateMessage?: any;
  handleClose: () => void;
}
export default function SuccessAlert(props: IMessage) {
  return (
    <>
      <Box
        sx={{
          height: 20,
          width: '20%',
          position: 'absolute',
          right: 0
        }}
      >
        <Stack spacing={2}>
          <Alert
            onClose={props.handleClose}
            icon={false}
            sx={{
              backgroundColor: 'grayscale.50',
              boxShadow: '2px',
              border: '0.1px solid lightGray'
            }}
          >
            <Stack direction='column' spacing={2}>
              <Stack
                id='Success-Update-Message'
                sx={{color: '#30d5c8 ', fontFamily: 'Roboto Medium', fontSize: '14px'}}
              >{`${
                props.successMessage?.message
                  ? props.successMessage?.message
                  : props.updateMessage?.message
              }`}</Stack>
              <Stack
                id='Success-Update-PO-ID'
                sx={{color: 'grayscale.600', fontFamily: 'Roboto Medium', fontSize: '12px'}}
              >{`PO # ${
                props.successMessage?.purchaseOrderId
                  ? props.successMessage?.purchaseOrderId
                  : props.updateMessage?.purchaseOrderId
              }`}</Stack>
              <Stack
                id='Success-Update-Supplier_Name'
                sx={{color: 'grayscale.600', fontFamily: 'Roboto Medium', fontSize: '12px'}}
              >{`${
                props.successMessage?.supplierName ? props.successMessage?.supplierName : ''
              }`}</Stack>
            </Stack>
          </Alert>
        </Stack>
      </Box>
    </>
  );
}
