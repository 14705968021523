import {configureStore, combineReducers} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {all} from 'redux-saga/effects';
import watchFetchGlobalNotificationData from 'redux/dashboard/globalNotification/globalNotificationSaga';
import channelSlice from 'redux/dashboard/master/channelSlice';
import watchFetchChannelData from 'redux/dashboard/master/channelSaga';
import categorySupplierSlice from 'redux/report/sales/categorySupplier/categorySupplierSlice';
import watchFetchCategorySupplierData from 'redux/report/sales/categorySupplier/categorySupplierSaga';
import watchFetchSalesData from 'redux/dashboard/sales/salesSaga';
import watchFetchMarginData from 'redux/dashboard/margin/marginSaga';
import salesSlice from 'redux/dashboard/sales/salesSlice';
import marginSlice from 'redux/dashboard/margin/marginSlice';
import inventorySlice from 'redux/dashboard/inventory/inventorySlice';
import productListSlice from 'redux/product/list/productList/productListSlice';
import watchFetchInventoryData from 'redux/dashboard/inventory/inventorySaga';
import poAlertSlice from 'redux/dashboard/poAlert/poAlertSlice';
import masterSlice from 'redux/dashboard/master/masterSlice';
import watchFetchPoAlertData from 'redux/dashboard/poAlert/poAlertSaga';
import watchFetchUrgentStoreData from 'redux/dashboard/urgentStore/urgentStoreSaga';
import urgentStoreSlice from 'redux/dashboard/urgentStore/urgentStoreSlice';
import classOTBSlice from 'redux/dashboard/otb/classOTBSlice';
import receivingTileSlice from 'redux/dashboard/receivingTile/receivingTileSlice';
import productWareHouseListSlice from 'redux/product/list/warehouseList/warehouseListSlice';
import watchFetchClassOTBData from 'redux/dashboard/otb/classOTBSaga';
import remainderSlice from 'redux/dashboard/remainder/remainderSlice';
import watchFetchRemainderData from 'redux/dashboard/remainder/remainderSaga';
import agedSlice from 'redux/dashboard/agedTile/agedSlice';
import watchFetchAgedData from 'redux/dashboard/agedTile/agedSaga';
import expirySlice from 'redux/dashboard/expiryTile/expirySlice';
import watchFetchExpiryData from 'redux/dashboard/expiryTile/expirySaga';
import watchFetchMasterData from 'redux/dashboard/master/masterSaga';
import storeAlertSlice from 'redux/dashboard/storeAlert/storeAlertSlice';
import watchFetchStoreAlertData from 'redux/dashboard/storeAlert/storeAlertSaga';
import {
  watchFetchProductListData,
  watchFetchProductListSearchData,
  watchFetchProductSearchData
} from 'redux/product/list/productList/productListSaga';
import vendorDetailsSlice from 'redux/vendor/details/vendorDetailsSlice';
import watchFetchVendorDetailsData from 'redux/vendor/details/vendorDetailsSaga';
import vendorListSlice from 'redux/vendor/list/vendorListSlice';
import watchFetchVendorListData from 'redux/vendor/list/vendorListSaga';
import watchFetchReceivingTileData from 'redux/dashboard/receivingTile/receivingTileSaga';
import watchFetchCycleCountData from 'redux/dashboard/cycleCountVariance/cycleCountSaga';
import cycleCountSlice from 'redux/dashboard/cycleCountVariance/cycleCountSlice';
import receivedPoItemSlice from 'redux/report/inventory/receivedPoItem/receivedPoItemSlice';
import watchFetchReceivedPoItemData from 'redux/report/inventory/receivedPoItem/receivedPoItemSaga';
import watchFetchDemandForecastData from 'redux/report/sales/demandForecast/demandForecastSaga';
import topWeeklyStoreSlice from 'redux/dashboard/topWeeklySales/topWeeklySalesSlice';
import watchFetchTopWeeklyStoreData from 'redux/dashboard/topWeeklySales/topWeeklySalesSaga';
import itemAlertSlice from 'redux/dashboard/itemAlert/itemAlertSlice';
import watchFetchItemAlertData from 'redux/dashboard/itemAlert/itemAlertSaga';
import watchFetchProductWareHouseListData from 'redux/product/list/warehouseList/warehouseListSaga';
import watchFetchSalesItemData from 'redux/product/details/salesItem/salesItemSaga';
import productDetailsSlice from 'redux/product/details/productDetails/productDetailsSlice';
import watchFetchProductDetailsData from 'redux/product/details/productDetails/productDetailsSaga';
import watchFetchWareHouseItemData from 'redux/product/details/warehouseItem/wareHouseItemSaga';
import wareHouseItemSlice from 'redux/product/details/warehouseItem/wareHouseItemSlice';
import salesItemSlice from 'redux/product/details/salesItem/salesItemSlice';
import watchFetchProductDemandForeCastItemData from 'redux/product/details/productDemandForeCastItem/productDemandForeCastItemSaga';
import productDemandForeCastItemSlice from 'redux/product/details/productDemandForeCastItem/productDemandForeCastItemSlice';
import watchFetchProductDemandForeCastIngItemData from 'redux/product/details/productDemandForeCastIngItem/productDemandForeCastIngItemSaga';
import productDemandForeCastItemIngSlice from 'redux/product/details/productDemandForeCastIngItem/productDemandForeCastIngItemSlice';
import watchFetchClassPerformanceData from 'redux/dashboard/classPerformance/classPerformanceSaga';
import classPerformanceSlice from 'redux/dashboard/classPerformance/classPerformanceSlice';
import topVendorStoreSlice from 'redux/dashboard/topVendor/topVendorSlice';
import watchFetchTopVendorStoreData from 'redux/dashboard/topVendor/topVendorSaga';
import adPromosTileSlice from 'redux/dashboard/adPromos/adPromosSlice';
import watchFetchAdPromos from 'redux/dashboard/adPromos/adPromosSaga';
import productSalesListSlice from 'redux/product/list/salesList/productSalesListSlice';
import regionSlice from 'redux/dashboard/master/regionSlice';
import watchFetchRegionData from 'redux/dashboard/master/regionSaga';
import vendorSalesItemSlice from 'redux/vendor/detailsItemTab/vendorSalesItemSlice';
import watchFetchVendorSalesItemData from 'redux/vendor/detailsItemTab/vendorSalesItemSaga';
import distrubutionCenterSlice from 'redux/dashboard/master/distrubutionCenterSlice';
import watchFetchdistrubutionCenterData from 'redux/dashboard/master/distrubutionCenterSaga';
import watchFetchNewPOData from 'redux/dashboard/newPOs/newPOSaga';
import newPOSlice from 'redux/dashboard/newPOs/newPOSlice';
import watchFetchTagData from 'redux/dashboard/master/tagSaga';
import tagSlice from 'redux/dashboard/master/tagSlice';
import buyerSlice from 'redux/dashboard/master/buyerSlice';
import districtSlice from 'redux/dashboard/master/districtSlice';
import sizeSlice from 'redux/dashboard/master/sizeSlice';
import packSlice from 'redux/dashboard/master/packSlice';
import storeSlice from 'redux/dashboard/master/storeSlice';
import onOrderRcvgItemSlice from 'redux/product/details/onOrderRcvgItem/onOrderRcvgItemSlice';
import alertSlice from 'redux/dashboard/master/alertSlice';
import watchFetchAlertData from 'redux/dashboard/master/alertSaga';
import watchFetchDistrictData from 'redux/dashboard/master/districtSaga';
import watchFetchSizeData from 'redux/dashboard/master/sizeSaga';
import watchFetchStoreData from 'redux/dashboard/master/storeSaga';
import watchFetchPackData from 'redux/dashboard/master/packSaga';
import warehouseSlice from 'redux/dashboard/master/warehouseSlice';
import watchFetchWarehouseData from 'redux/dashboard/master/warehouseSaga';
import expiryProductListSlice from 'redux/product/list/expiryList/expiryProductListSlice';
import watchFetchExpiryProductListData from 'redux/product/list/expiryList/expiryProductListSaga';
import laborSlice from 'redux/dashboard/labor/laborSlice';
import watchFetchLaborData from 'redux/dashboard/labor/laborSaga';
import shrinkSlice from 'redux/dashboard/shrinkTile/shrinkSlice';
import watchFetchShrinkData from 'redux/dashboard/shrinkTile/shrinkSaga';
import watchFetchsupplyChainData from 'redux/vendor/detailsItemTab/supplyChainSaga';
import supplyChainSlice from 'redux/vendor/detailsItemTab/supplyChainSlice';
import watchFetchBuyerData from 'redux/dashboard/master/buyerSaga';
import watchFetchProductStoreListData from 'redux/product/list/productStoreList/productStoreListSaga';
import productStoreListSlice from 'redux/product/list/productStoreList/productStoreListSlice';
import warehouseLiabilityReportSlice from 'redux/report/inventory/warehouseLiabilityReport/warehouseLiabilityReportSlice';
import watchFetchWarehouseLiabilityReportData from 'redux/report/inventory/warehouseLiabilityReport/warehouseLiabilityReportSaga';
import inventoryBalanceSlice from 'redux/report/inventory/inventoryBalances/inventoryBalanceSlice';
import watchFetchInventoryBalanceData, {
  watchFetchInventoryBalanceRegionData
} from 'redux/report/inventory/inventoryBalances/inventoryBalanceSaga';
import watchFetchOnOrderRcvgItemData from 'redux/product/details/onOrderRcvgItem/onOrderRcvgItemSaga';
import watchFetchProductSalesListData from 'redux/product/list/salesList/productSalesListSaga';
import topTwentyVendorsSlice from 'redux/dashboard/topTwentyVendors/topTwentyVendorsSlice';
import watchFetchtopTwentyVendorsData from 'redux/dashboard/topTwentyVendors/topTwentyVendorsSaga';
import productItemStoreSlice from 'redux/product/details/storeItem/storeItemSlice';
import watchFetchProductItemStoreData from 'redux/product/details/storeItem/storeItemSaga';
import watchFetchProductItemData from 'redux/vendor/detailsItemTab/productItemSaga';
import productItemSlice from 'redux/vendor/detailsItemTab/productItemSlice';
import poItemsSlice from 'redux/purchaseOrder/details/items/poItemsSlice';
import watchFetchPoItemsData from 'redux/purchaseOrder/details/items/poItemsSaga';
import watchFetchGeneralInfoData from 'redux/purchaseOrder/details/generalInfo/generalInfoSaga';
import generalInfoSlice from 'redux/purchaseOrder/details/generalInfo/generalInfoSlice';
import watchFetchPoListData from 'redux/purchaseOrder/poList/poListSaga';
import poListSlice from 'redux/purchaseOrder/poList/poListSlice';
import freightSummarySlice from 'redux/purchaseOrder/details/freightSummary/freightSummarySlice';
import watchFetchFreightSummaryData from 'redux/purchaseOrder/details/freightSummary/freightSummarySaga';
import supplierContactSlice from 'redux/purchaseOrder/details/supplierContact/supplierContactSlice';
import watchFetchSupplierContactData from 'redux/purchaseOrder/details/supplierContact/supplierContactSaga';
import watchFetchRetailZoneData from 'redux/product/details/retails/retailZoneSaga';
import retailZoneSlice from 'redux/product/details/retails/retailZoneSlice';
import getProfileSlice from 'redux/dashboard/profile/getProfileSlice';
import watchFetchGetProfileData from 'redux/dashboard/profile/getProfileSaga';
import demandForecastSlice from 'redux/report/sales/demandForecast/demandForecastSlice';
import vendorSlice from 'redux/dashboard/master/vendorSlice';
import watchFetchVendorData from 'redux/dashboard/master/vendorSaga';
import poNotesAttachmentSlice from 'redux/purchaseOrder/details/notes/poNotesAttachmentSlice';
import watchFetchPoNotesAttachmentData from 'redux/purchaseOrder/details/notes/poNotesAttachmentSaga';
import globalNotificationSlice from 'redux/dashboard/globalNotification/globalNotificationSlice';
import perpetualInventorySlice from 'redux/report/inventory/perpetualInventoryAvg/perpetualInventorySlice';
import watchFetchPerpetualInventoryData from 'redux/report/inventory/perpetualInventoryAvg/perpetualInventorySaga';
import topTenSalesItemsSlice from 'redux/report/sales/categorySupplier/topTenItemSalesSlice';
import watchFetchTopTenSalesItems from 'redux/report/sales/categorySupplier/topTenItemSalesSaga';
import watchPurchaseOrderTypeData from 'redux/dashboard/master/purchaseOrderSaga';
import purchaseOrderTypeSlice from 'redux/dashboard/master/purchaseOrderSlice';
import watchFetchMasterSupplierContactData from 'redux/dashboard/master/supplierContactSaga';
import supplierContactMasterSlice from 'redux/dashboard/master/supllierContactSlice';
import watchFetchFreightTermData from 'redux/dashboard/master/freightTermSaga';
import freightTermSlice from 'redux/dashboard/master/freightTermSlice';
import watchFetchLoadConditionData from 'redux/dashboard/master/loadConditionSaga';
import loadConditionSlice from 'redux/dashboard/master/loadConditionSlice';
import watchFetchCreatePOData, {
  watchFetchClearPOData
} from 'redux/purchaseOrder/poList/createPOSaga';
import createPOSlice from 'redux/purchaseOrder/poList/createPOSlice';
import watchFetchDeliveryConditionData from 'redux/dashboard/master/deliveryConditionSaga';
import deliveryConditionSlice from 'redux/dashboard/master/deliveryConditionSlice';
import watchFetchUpdatePOData, {
  watchFetchClearUpdatePOData
} from 'redux/purchaseOrder/poList/updatePOSaga';
import updatePOSlice from 'redux/purchaseOrder/poList/updatePOSlice';

const sagaMiddleware = createSagaMiddleware();

export const reducer = combineReducers({
  salesSlice,
  marginSlice,
  inventorySlice,
  classPerformanceSlice,
  cycleCountSlice,
  poAlertSlice,
  urgentStoreSlice,
  classOTBSlice,
  remainderSlice,
  masterSlice,
  regionSlice,
  tagSlice,
  vendorSlice,
  districtSlice,
  sizeSlice,
  packSlice,
  alertSlice,
  storeSlice,
  buyerSlice,
  warehouseSlice,
  storeAlertSlice,
  productListSlice,
  vendorDetailsSlice,
  vendorListSlice,
  supplyChainSlice,
  receivingTileSlice,
  receivedPoItemSlice,
  topWeeklyStoreSlice,
  expiryProductListSlice,
  productDetailsSlice,
  salesItemSlice,
  productWareHouseListSlice,
  demandForecastSlice,
  wareHouseItemSlice,
  productSalesListSlice,
  topVendorStoreSlice,
  adPromosTileSlice,
  vendorSalesItemSlice,
  newPOSlice,
  itemAlertSlice,
  productDemandForeCastItemSlice,
  productDemandForeCastItemIngSlice,
  warehouseLiabilityReportSlice,
  distrubutionCenterSlice,
  productStoreListSlice,
  onOrderRcvgItemSlice,
  laborSlice,
  shrinkSlice,
  productItemStoreSlice,
  inventoryBalanceSlice,
  topTwentyVendorsSlice,
  productItemSlice,
  poItemsSlice,
  generalInfoSlice,
  poListSlice,
  freightSummarySlice,
  supplierContactSlice,
  agedSlice,
  expirySlice,
  retailZoneSlice,
  getProfileSlice,
  channelSlice,
  poNotesAttachmentSlice,
  categorySupplierSlice,
  globalNotificationSlice,
  perpetualInventorySlice,
  topTenSalesItemsSlice,
  purchaseOrderTypeSlice,
  supplierContactMasterSlice,
  freightTermSlice,
  deliveryConditionSlice,
  loadConditionSlice,
  createPOSlice,
  updatePOSlice
});

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function* rootSaga() {
  yield all([
    watchFetchSalesData(),
    watchFetchMarginData(),
    watchFetchCycleCountData(),
    watchFetchInventoryData(),
    watchFetchClassPerformanceData(),
    watchFetchPoAlertData(),
    watchFetchUrgentStoreData(),
    watchFetchClassOTBData(),
    watchFetchRemainderData(),
    watchFetchMasterData(),
    watchFetchRegionData(),
    watchFetchChannelData(),
    watchFetchBuyerData(),
    watchFetchTagData(),
    watchFetchVendorData(),
    watchFetchDistrictData(),
    watchFetchSizeData(),
    watchFetchStoreData(),
    watchFetchPackData(),
    watchFetchAlertData(),
    watchFetchWarehouseData(),
    watchFetchStoreAlertData(),
    watchFetchProductListData(),
    watchFetchProductSearchData(),
    watchFetchProductListSearchData(),
    watchFetchVendorDetailsData(),
    watchFetchVendorListData(),
    watchFetchsupplyChainData(),
    watchFetchReceivingTileData(),
    watchFetchReceivedPoItemData(),
    watchFetchTopWeeklyStoreData(),
    watchFetchProductDetailsData(),
    watchFetchWareHouseItemData(),
    watchFetchSalesItemData(),
    watchFetchDemandForecastData(),
    watchFetchTopWeeklyStoreData(),
    watchFetchAdPromos(),
    watchFetchProductWareHouseListData(),
    watchFetchTopVendorStoreData(),
    watchFetchProductSalesListData(),
    watchFetchVendorSalesItemData(),
    watchFetchNewPOData(),
    watchFetchItemAlertData(),
    watchFetchExpiryProductListData(),
    watchFetchProductDemandForeCastItemData(),
    watchFetchProductDemandForeCastIngItemData(),
    watchFetchWarehouseLiabilityReportData(),
    watchFetchBuyerData(),
    watchFetchdistrubutionCenterData(),
    watchFetchProductStoreListData(),
    watchFetchOnOrderRcvgItemData(),
    watchFetchLaborData(),
    watchFetchShrinkData(),
    watchFetchtopTwentyVendorsData(),
    watchFetchProductItemStoreData(),
    watchFetchInventoryBalanceData(),
    watchFetchInventoryBalanceRegionData(),
    watchFetchProductItemData(),
    watchFetchPoItemsData(),
    watchFetchGeneralInfoData(),
    watchFetchPoListData(),
    watchFetchGetProfileData(),
    watchFetchFreightSummaryData(),
    watchFetchSupplierContactData(),
    watchFetchAgedData(),
    watchFetchExpiryData(),
    watchFetchRetailZoneData(),
    watchFetchPoNotesAttachmentData(),
    watchFetchCategorySupplierData(),
    watchFetchGlobalNotificationData(),
    watchFetchPerpetualInventoryData(),
    watchFetchTopTenSalesItems(),
    watchPurchaseOrderTypeData(),
    watchFetchMasterSupplierContactData(),
    watchFetchFreightTermData(),
    watchFetchLoadConditionData(),
    watchFetchDeliveryConditionData(),
    watchFetchCreatePOData(),
    watchFetchClearPOData(),
    watchFetchUpdatePOData(),
    watchFetchClearUpdatePOData()
  ]);
}

sagaMiddleware.run(rootSaga);

export default store;
