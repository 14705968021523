import {takeEvery, put, call} from 'redux-saga/effects';
import {ReportAPIS} from 'redux/report/report_service';
import {
  topTenSalesItemsLoading,
  topTenSalesItemsSuccess,
  topTenSalesItemsFailure
} from 'redux/report/sales/categorySupplier/topTenItemSalesSlice';

export const topTenSalesItemSagaActions = {
  FETCH_TOPTENITEMS_SAGA: 'FETCH_TOPTENITEMS_SAGA'
};

export function* fetchTopTenSalesItems(action: any): any {
  try {
    yield put(topTenSalesItemsLoading());
    const {data} = yield call(ReportAPIS.getTopTenSalesItemData, action.payload);
    yield put(topTenSalesItemsSuccess(data));
  } catch (error) {
    yield put(topTenSalesItemsFailure());
  }
}

export default function* watchFetchTopTenSalesItems() {
  yield takeEvery(topTenSalesItemSagaActions.FETCH_TOPTENITEMS_SAGA, fetchTopTenSalesItems);
}
