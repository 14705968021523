import React, {useEffect} from 'react';
import {TableHead, TableRow, Typography, Box, Stack, Button, Popover} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import EnhancedTable from 'components/customTable/enhancedTable';
import {DebounceInput} from 'react-debounce-input';

import {BorderedTableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell} from 'components/customTable/types';
import CustomInput from 'components/baseComponents/customInput';
import {ReactComponent as Dustbin} from 'assets/images/icons/Icons & UI-Edit-Delete_Passive.svg';
import {ReactComponent as ImportIcon} from 'assets/images/icons/Upload.svg';
import POSearchItems from './poSearchItems';
import {productListSagaActions} from 'redux/product/list/productList/productListSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {IPOItems} from 'features/purchaseOrder/types';
import {decimalConverter, generateLabelValue} from 'util/arrayOperations';
import {ISelectOptions} from 'components/baseComponents/customSelect';

interface IProductGridProps extends IPOItems {
  ln: string;
}

interface POItemEdit {
  poItems: IPOItems[];
  addNewItem: (item: any) => void; /// For adding new item
  deleteItems: (items: any) => void; /// For deleting new item
  updateItem: (item: any) => void; /// For updating existing item
  updateProductItem: (oldItem: any, newItem: any) => void;
}

const getDispalyablePOItems = (poItems: IPOItems[]) => {
  if (poItems && poItems?.length > 0) {
    /////Filter only displayable items
    const filteredItems: IPOItems[] =
      poItems.filter((ele: IPOItems) => {
        return ele.poStatus !== 'Delete';
      }) || [];

    /////Add Line number column
    const Items: IProductGridProps[] = filteredItems?.map((ele: IPOItems, index: number) => {
      return {
        ln: (index + 1).toString(),
        // ln: ele.lineNo,
        ...ele,
        asn: '-',
        rcv: '-',
        expiry: '-'
      } as IProductGridProps;
    });
    return Items;
  } else return [];
};

const POItemEdit: React.FC<POItemEdit> = (props: POItemEdit) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [rowData, setRowData] = React.useState<IProductGridProps[]>([]);
  const [productItemList, setProductItemList] = React.useState<ISelectOptions[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<IProductGridProps[]>([]);
  const {searchProductList, productList, searchLoading} = useAppSelector(
    (state) => state.productListSlice
  );

  const [searchText, setSearchText] = React.useState('');
  const [showSearchModel, setShowSearchModel] = React.useState(false);

  const generateProductList = (prodList: any) => {
    return generateLabelValue(prodList, 'itemId', 'itemId');
  };

  useEffect(() => {
    setRowData(getDispalyablePOItems(props.poItems));
  }, [props.poItems]);

  useEffect(() => {
    if (productList.length === 0) {
      dispatch({
        type: productListSagaActions.FETCH_PRODUCTLIST_DATA,
        payload: []
      });
    }
  }, []);

  useEffect(() => {
    setProductItemList(generateProductList(productList));
  }, [productList]);

  const handleSearchChange = (value: any) => {
    if (value != '') {
      setShowSearchModel(true);
    } else {
      setShowSearchModel(false);
    }
    setSearchText(value);
  };

  const handleClose = () => {
    setShowSearchModel(false);
    setSearchText('');
  };

  const removePOItems = () => {
    if (selectedItems.length > 0) {
      props.deleteItems(selectedItems);
    }
  };

  const getSelectedItems = (poSelectedItems: readonly string[]) => {
    if (poSelectedItems.length > 0) {
      const selectedPOItems = rowData?.filter((ele: IProductGridProps) => {
        if (ele.itemId) {
          return poSelectedItems.includes(ele.itemId);
        } else return false;
      });
      setSelectedItems(selectedPOItems);
    } else {
      setSelectedItems([]);
    }
  };

  const CLSComponent = (row: any) => {
    let data = '';

    if (
      row?.classId &&
      row?.classId.trim() != '' &&
      row?.subClassId &&
      row?.subClassId.trim() != ''
    ) {
      data = `${row?.classId} / ${row?.subClassId}`;
    } else if (row?.classId && row?.classId.trim() != '') {
      data = `${row?.classId}`;
    } else if (row?.subClassId && row?.subClassId.trim() != '') {
      data = `${row?.subClassId}`;
    }

    return <Box>{data}</Box>;
  };

  const PackComponent = (row: any) => {
    let data = '';
    if (row?.pack && row?.pack.trim() != '') {
      data = `${decimalConverter(row?.pack)} / ${decimalConverter(row?.size)}`;
    } else if (row?.size && row?.size.trim() != '') {
      data = decimalConverter(row?.size);
    }
    return <Box>{data}</Box>;
  };

  const inputChangeHandler = (e: any, row: IProductGridProps) => {
    let quantityValue: any;
    let vendCostValue: any;
    // let estFreightValue: any;
    if (e.target.name === 'quantity') {
      quantityValue = e.target.value;
      vendCostValue = row.vendCost;
      // estFreightValue = row.estFrightCost;
    } else if (e.target.name === 'vendCost') {
      vendCostValue = e.target.value;
      quantityValue = row.quantity;
      // estFreightValue = row.estFrightCost;
    }
    //  else if (e.target.name === 'estFrightCost') {
    //   estFreightValue = e.target.value;
    //   vendCostValue = row.vendCost;
    //   quantityValue = row.quantity;
    // }
    // console.log('151e', e.target.name, '151row', estFreightValue);
    const updatedRow = {
      ...row,
      netCost: Number(quantityValue) * Number(vendCostValue),
      // estFrightCost: estFreightValue,
      estimatedFreight: (Number(quantityValue) * Number(vendCostValue) * 10) / 100,
      [e.target.name]: e.target.value
    };

    props.updateItem(updatedRow);
  };

  const changeHandler = (e: any, row: IProductGridProps) => {
    if (e?.target?.value && row.itemId) {
      const newPOItem = productList.find((ele: any) => ele.itemId && ele.itemId === e.target.value);
      if (newPOItem?.itemId) {
        const newValues = {
          ...newPOItem,
          itemDescription: newPOItem.itemName
        };
        props.updateProductItem(row, newValues);
      }
    }
  };
  /**
   *
   * @param row
   * @returns Custom component for purchase unit
   */
  const purchaseUnitcustomInput = (row: any) => {
    return (
      <Box sx={{width: '100px'}}>
        <Stack direction='row' spacing={2}>
          <CustomInput
            InputProps={{
              readOnly: true
            }}
            value={'1'}
          />
          <CustomInput
            name='purchaseUnit'
            sx={{
              backgroundColor: 'common.white',
              width: '300px',
              borderRadius: 1
            }}
            value={row.purchaseUnit}
            InputProps={{
              readOnly: true
            }}
          />
        </Stack>
      </Box>
    );
  };
  // Below we are creating Column cell for PO Item Edit Screen
  const columnCell: IColumnCell<IProductGridProps>[] = [
    {
      id: 'ln',
      name: 'ln',
      displayName: 'LN',
      isColumnSortable: false,
      isGroupBorderRequire: true
    },
    {
      id: 'itemId',
      name: 'itemId',
      displayName: 'Item',
      isColumnSortable: false,
      editProps: {
        fieldSx: {
          width: '220px'
        },
        fieldType: filterFieldTypes.DROPDOWN,
        onChange: changeHandler,
        options: productItemList
      }
    },
    {
      id: 'classId',
      name: 'classId',
      displayName: 'cls',
      customCell: CLSComponent,
      isColumnSortable: false
    },
    {
      id: 'itemDescription',
      name: 'itemDescription',
      displayName: 'description',
      isColumnSortable: false
    },
    {
      id: 'pack',
      name: 'pack',
      displayName: 'Pack/size',
      customCell: PackComponent,
      isGroupBorderRequire: true,
      isColumnSortable: false
    },
    {
      id: 'rcv',
      name: 'rcv',
      displayName: 'rcv',
      isColumnSortable: false
    },
    {
      id: 'asn',
      name: 'asn',
      displayName: 'asn',
      isColumnSortable: false
    },
    {
      id: 'quantity',
      name: 'quantity',
      displayName: 'qty',
      isColumnSortable: false,
      isNumeric: true,
      isGroupBorderRequire: true,
      editProps: {
        fieldSx: {
          width: '70px',
          minWidth: '70px'
        },
        fieldType: filterFieldTypes.TEXTBOX,
        onChange: inputChangeHandler
      }
    },
    {
      id: 'vendCost',
      name: 'vendCost',
      displayName: 'vend Cost',
      isColumnSortable: false,
      isNumeric: true,
      editProps: {
        fieldSx: {
          width: '100px',
          minWidth: '100px'
        },
        fieldType: filterFieldTypes.TEXTBOX,
        onChange: inputChangeHandler
      }
    },
    {
      id: 'purchaseUnit',
      name: 'purchaseUnit',
      displayName: 'purchase Unit',
      isColumnSortable: false,
      isGroupBorderRequire: true,
      editProps: {
        fieldSx: {
          minWidth: '50px'
        },
        fieldType: filterFieldTypes.CUSTOM,
        onChange: inputChangeHandler,
        customCell: purchaseUnitcustomInput
      }
    },
    {
      id: 'netCost',
      name: 'netCost',
      displayName: 'net Cost',
      isColumnSortable: false,
      isNumeric: true,
      cellTextAlign: 'left',
      sx: {width: '200px', minWidth: '100px'}
    },
    {
      id: 'estimatedFreight',
      name: 'estimatedFreight',
      displayName: 'EST Freight Cost',
      isColumnSortable: false,
      isNumeric: true,
      cellTextAlign: 'left',
      sx: {width: '220px', minWidth: '120px'}
    },
    // {
    //   id: 'estFrightCost',
    //   name: 'estFrightCost',
    //   displayName: 'EST Freight Cost',
    //   isColumnSortable: false,
    //   isNumeric: true,
    //   editProps: {
    //     fieldSx: {
    //       width: '120px',
    //       minWidth: '100px'
    //     },
    //     fieldType: filterFieldTypes.TEXTBOX,
    //     onChange: inputChangeHandler
    //   }
    // },
    {
      id: 'landed',
      name: 'landed',
      displayName: 'landed',
      isNumeric: true,
      isColumnSortable: false
    },
    {
      id: 'retail',
      name: 'retail',
      displayName: 'retail',
      isColumnSortable: false,
      isNumeric: true,
      isGroupBorderRequire: true
    },
    {
      id: 'expiry',
      name: 'expiry',
      displayName: 'expiry',
      isColumnSortable: false,
      isGroupBorderRequire: true,
      sx: {
        textAlign: 'center'
      }
    },
    {
      id: 'status',
      name: 'status',
      displayName: 'status',
      isColumnSortable: false,
      isGroupBorderRequire: true,
      sx: {
        textAlign: 'right'
      }
    }
  ];

  const StickyHeaderCell = () => {
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              colSpan={2}
              sx={{top: '64px', color: 'grayscale.400', borderBottom: 0}}
            >
              <Typography variant='subtitle2'>ADD ITEM</Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell colSpan={4} align='left' sx={{top: '64px', px: 0.8}}>
              <DebounceInput
                minLength={2}
                value={searchText}
                placeholder='Search by item ID, UPC or description to add items'
                debounceTimeout={500}
                style={{
                  backgroundColor: 'common.white',
                  border: 'none',
                  outline: 'none',
                  width: '464px',
                  padding: '8px 0 8px 16px',
                  boxSizing: 'border-box'
                }}
                onChange={(e: any) => handleSearchChange(e.target.value)}
              />
            </BorderedTableStickyCell>
            <BorderedTableStickyCell colSpan={3} align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'></Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell colSpan={2} align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'></Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell colSpan={4} align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'></Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'></Typography>
            </BorderedTableStickyCell>
            <BorderedTableStickyCell align='right' sx={{top: '64px'}}>
              <Typography variant='subtitle2'></Typography>
            </BorderedTableStickyCell>
          </TableRow>
        </TableHead>
      </>
    );
  };
  return (
    <Box
      sx={{
        bgcolor: 'grayscale.50',
        borderRadius: 1,
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      }}
    >
      <Box
        sx={{
          mt: 4,
          height: '72px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: 'grayscale.50',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          borderBottom: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        <Typography variant='subtitle1' sx={{pl: 2}}>
          PO Items ({rowData.length})
        </Typography>
        <Stack direction={'row'} sx={{alignItems: 'center'}}>
          <CustomInput
            sx={{
              backgroundColor: 'common.white',
              width: '358px',
              pr: 1,
              borderRadius: 1
            }}
            name='time'
            value={''}
            type='number'
            placeholder='Find on table by ID or name'
          />
          <Button
            sx={{
              '&:hover': {
                bgcolor: 'unset'
              }
            }}
          >
            <ImportIcon />
            <Typography variant='button' sx={{pl: 1.25, fontWeight: 'bold', whiteSpace: 'nowrap'}}>
              IMPORT
            </Typography>
          </Button>
          <Button
            sx={{
              fontWeight: 'bold',
              whiteSpace: 'nowrap'
            }}
            disabled={selectedItems.length > 0 ? false : true}
            onClick={removePOItems}
          >
            <Dustbin
              fill={
                selectedItems.length > 0
                  ? `${theme.palette.primary.main}`
                  : `${theme.palette.grayscale?.[300]}`
              }
            />
            REMOVE
          </Button>
        </Stack>
      </Box>
      <EnhancedTable<IProductGridProps>
        rowData={rowData}
        stickyRows={<StickyHeaderCell />}
        columnCell={columnCell}
        rowKey={'itemId'}
        defaultSortKey={'ln'}
        defaultSortOrder={'desc'}
        componentType='paper'
        allowAlternateColor={true}
        rowsPerPage={25}
        selectable
        getSelectedIds={getSelectedItems}
        isEditable={true}
        paginationSx={{
          px: 2,
          pb: 1.2
        }}
        id='purchaseOrder-items-edit'
      />
      {showSearchModel ? (
        <Popover
          id={'id'}
          open={showSearchModel}
          onClose={handleClose}
          sx={{maxHeight: 512}}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <POSearchItems
            open={showSearchModel}
            searchItems={searchProductList}
            text={searchText}
            handleClose={handleClose}
            addNewItem={props.addNewItem}
            loading={searchLoading}
          />
        </Popover>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default POItemEdit;
