import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  productSalesListFailure,
  productSalesListLoading,
  productSalesListSuccess
} from 'redux/product/list/salesList/productSalesListSlice';

export const productSalesListSagaActions = {
  FETCH_PRODUCTSALESLIST_DATA: 'FETCH_PRODUCTSALESLIST_DATA'
};

export function* fetchProductSalesListData(action: any): any {
  try {
    yield put(productSalesListLoading());
    const {data} = yield call(ProductAPIS.getProductSalesData, action.payload);
    yield put(productSalesListSuccess(data));
  } catch (error) {
    yield put(productSalesListFailure());
  }
}

export default function* watchFetchProductSalesListData() {
  yield takeEvery(
    productSalesListSagaActions.FETCH_PRODUCTSALESLIST_DATA,
    fetchProductSalesListData
  );
}
