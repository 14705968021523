import {createSlice} from '@reduxjs/toolkit';
export interface topVendorState {
  loading: boolean;
  topVendorStore: {[key: string]: Array<any>};
  error: string;
}

const initialState: topVendorState = {
  loading: false,
  topVendorStore: {},
  error: ''
};

const topVendorStoreSlice = createSlice({
  name: 'topVendorStore',
  initialState,
  reducers: {
    topVendorStoreLoading: (state) => {
      state.loading = true;
    },
    topVendorStoreSuccess: (state, action) => {
      state.topVendorStore[action.payload.type] = action.payload.result;
      state.loading = false;
      state.error = '';
    },
    topVendorStoreFailure: (state, action) => {
      state.loading = false;
      state.topVendorStore[action?.payload.type] = [];
      state.error = 'Request failed with status code 404';
    }
  }
});

export default topVendorStoreSlice.reducer;
export const {topVendorStoreLoading, topVendorStoreSuccess, topVendorStoreFailure} =
  topVendorStoreSlice.actions;
