import {takeEvery, put, call} from 'redux-saga/effects';
import {ProductAPIS} from 'redux/product/product_service';
import {
  productListFailure,
  productListLoading,
  productListSuccess,
  productSearchFailure,
  productSearchLoading,
  productSearchPageFailure,
  productSearchPageLoading,
  productSearchPageSuccess,
  productSearchSuccess
} from 'redux/product/list/productList/productListSlice';

export const productListSagaActions = {
  FETCH_PRODUCTLIST_DATA: 'FETCH_PRODUCTLIST_DATA',
  FETCH_PRODUCTSEARCH_DATA: 'FETCH_PRODUCTSEARCH_DATA',
  FETCH_PRODUCTLISTSEARCH_DATA: 'FETCH_PRODUCTLISTSEARCH_DATA'
};

export function* fetchProductListData(action: any): any {
  try {
    yield put(productListLoading());
    const {data} = yield call(ProductAPIS.getProductListData, action.payload);
    yield put(productListSuccess(data));
  } catch (error) {
    yield put(productListFailure());
  }
}

export function* watchFetchProductListData() {
  yield takeEvery(productListSagaActions.FETCH_PRODUCTLIST_DATA, fetchProductListData);
}

export function* fetchProductSearchData(action: any): any {
  try {
    yield put(productSearchLoading());
    const {data} = yield call(ProductAPIS.getProductSearchData, action.payload);
    yield put(productSearchSuccess(data));
  } catch (error) {
    yield put(productSearchFailure());
  }
}

export function* watchFetchProductSearchData() {
  yield takeEvery(productListSagaActions.FETCH_PRODUCTSEARCH_DATA, fetchProductSearchData);
}
/**
 * for productListSearch data
 */
export function* fetchProductListSearchData(action: any): any {
  try {
    yield put(productSearchPageLoading());
    const {data} = yield call(ProductAPIS.getProductSearchData, action.payload);
    yield put(productSearchPageSuccess(data));
  } catch (error) {
    yield put(productSearchPageFailure());
  }
}

export function* watchFetchProductListSearchData() {
  yield takeEvery(productListSagaActions.FETCH_PRODUCTLISTSEARCH_DATA, fetchProductListSearchData);
}
