import * as React from 'react';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import EnhancedTable from 'components/customTable/enhancedTable';
import {IColumnCell} from 'components/customTable/types';
import {CustomCard} from 'components/baseComponents/customCard';
import {styled, useTheme} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {cycleCountSagaActions} from 'redux/dashboard/cycleCountVariance/cycleCountSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {Box, Grid, Popover, Typography} from '@mui/material';
import {generatePayload} from 'util/arrayOperations';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom';

interface IPropType {
  defaultValue: string;
  filteredData: any;
}
const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root .MuiBox-root:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 0
  },
  '& .MuiTableContainer-root': {
    overflowX: 'hidden'
  },
  '& .MuiTable-root': {
    marginRight: '16px'
  }
});

const CycleCountVariance: React.FC<IPropType> = (props) => {
  const [cycleCountData, setCycleCountData] = React.useState(props.defaultValue);
  const [deleteItem, setdeleteItem] = React.useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const data = [
    {
      label: 'Store Count Variance',
      value: 'Store'
    },
    {
      label: 'Item Count Variance',
      value: 'Item'
    }
  ];
  /**
   * dispatch cycleCount tile dispatch data
   */
  const dispatchData = () => {
    dispatch({
      type: cycleCountSagaActions.FETCH_CYCLECOUNT_DATA,
      payload: {
        type: cycleCountData,
        id: deleteItem,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [cycleCountData, deleteItem, props?.filteredData]);

  const {cycleCount, loading, error} = useAppSelector((state) => state.cycleCountSlice);

  interface IItemCycleCountGridProps {
    closeIcon?: string;
    itemID: string;
    whSTR: string;
    variance: string;
    id: string;
  }

  interface IStoreCycleCountGridProps {
    closeIcon?: string;
    store: string;
    itemCnt: string;
    variance: string;
    id: string;
  }
  const ItemCloseIconComponent = (row: any) => {
    return (
      <Grid direction='row' container>
        <Grid item md={4}>
          <Typography
            onClick={() => {
              setdeleteItem(row.itemId);
            }}
          >
            <CloseIcon sx={{fontSize: 20, color: 'red'}}></CloseIcon>
          </Typography>
        </Grid>
        <Grid item md={8} sx={{textAlign: 'right'}}>
          <Link to={{pathname: `/Product/${row.itemId}`}} state={row} style={{color: 'blue'}}>
            {row.itemId}
          </Link>
        </Grid>
      </Grid>
    );
  };

  const StoreCloseIconComponent = (row: any) => {
    return (
      <Grid direction='row' container>
        <Grid item md={4}>
          <Typography
            onClick={() => {
              setdeleteItem(row.store);
            }}
          >
            <CloseIcon sx={{fontSize: 20, color: 'red'}}></CloseIcon>
          </Typography>
        </Grid>
        <Grid item md={8} sx={{textAlign: 'right'}}>
          <Link
            to={{pathname: `/Product`}}
            style={{color: 'blue'}}
            state={{
              filter: {
                storeName: row.store || ''
              }
            }}
          >
            {row.store}
          </Link>
        </Grid>
      </Grid>
    );
  };

  const PopHoverComponent = (row: any) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
      <>
        <Box
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup='true'
          onMouseEnter={(e) => handlePopoverOpen(e)}
          onMouseLeave={handlePopoverClose}
        >
          <div>
            <p className='title'>
              <Typography variant='subtitle2' textAlign='center' sx={{margin: '0', padding: '0'}}>
                {row.variance}
              </Typography>
            </p>
          </div>
        </Box>
        <Popover
          id='mouse-over-popo'
          elevation={1}
          sx={{
            boxShadow: 'unset',
            pointerEvents: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus={true}
        >
          <Typography
            variant='subtitle1'
            sx={{
              fontFamily: 'Roboto Medium',
              fontWeight: 100,
              fontSize: 12,
              margin: 2,
              color: (theme: any) => theme.palette.grayscale?.[600]
            }}
            textAlign='left'
          >
            {row.cases}
          </Typography>
        </Popover>
      </>
    );
  };

  const itemColumnCell: IColumnCell<IItemCycleCountGridProps>[] = [
    {
      id: 'itemID',
      name: 'itemID',
      displayName: 'ITEM ID',
      headerTextAlign: 'right',
      customCell: ItemCloseIconComponent,
      isColumnSortable: false,
      textColor: theme.palette.primary.main
    },
    {
      id: 'whSTR',
      name: 'whSTR',
      displayName: 'WH/STR',
      isNumeric: true,
      headerTextAlign: 'center',
      isColumnSortable: false,
      cellTextAlign: 'right'
    },
    {
      id: 'variance',
      name: 'variance',
      displayName: 'VARIANCE',
      isNumeric: true,
      customCell: PopHoverComponent,
      isColumnSortable: false,
      headerTextAlign: 'center',
      cellTextAlign: 'right'
    }
  ];

  const storeColumnCell: IColumnCell<IStoreCycleCountGridProps>[] = [
    {
      id: 'store',
      name: 'store',
      displayName: 'STORE',
      customCell: StoreCloseIconComponent,
      headerTextAlign: 'right',
      isColumnSortable: false,
      textColor: theme.palette.primary.main
    },
    {
      id: 'itemCnt',
      name: 'itemCnt',
      displayName: 'ITEM CNT',
      isNumeric: true,
      headerTextAlign: 'center',
      isColumnSortable: false,
      cellTextAlign: 'center'
    },
    {
      id: 'variance',
      name: 'variance',
      displayName: 'VARIANCE',
      isNumeric: true,
      headerTextAlign: 'center',
      isColumnSortable: false,
      cellTextAlign: 'center'
    }
  ];

  /**
   * On Change event for dropdown
   * @param event Dropdown
   */
  const handleSelect = (event: any): void => {
    dispatch({
      type: cycleCountSagaActions.FETCH_CYCLECOUNT_DATA,
      payload: event?.target.value
    });
    setCycleCountData(event?.target.value);
  };

  /**
   * Tile Header
   */
  const Header = () => {
    return (
      <DropDownWrapper>
        <CustomSelect
          id='cycleCountDropdownTile'
          name={'cycleCountDropdown_tile'}
          value={cycleCountData}
          onChange={handleSelect}
          options={data}
        />
      </DropDownWrapper>
    );
  };

  const Content = () => {
    if (cycleCountData === 'Store')
      return (
        <>
          <EnhancedTable<IStoreCycleCountGridProps>
            width={'100%'}
            rowData={cycleCount && cycleCount[cycleCountData]}
            columnCell={storeColumnCell}
            rowKey={'store'}
            defaultSortKey={'store'}
            allowAlternateColor={false}
            componentType='paper'
            dense={true}
            hidePagination
            key={`${cycleCountData}CountVarianceTable`}
            id='dashboard-storeCycleCount'
          />
        </>
      );
    else
      return (
        <>
          <EnhancedTable<IItemCycleCountGridProps>
            width={'100%'}
            rowData={cycleCount && cycleCount[cycleCountData]}
            columnCell={itemColumnCell}
            rowKey={'itemID'}
            defaultSortKey={'itemID'}
            allowAlternateColor={false}
            componentType='box'
            dense={true}
            hidePagination
            key={`${cycleCountData}CountVarianceTable`}
            id='dashboard-itemCycleCount'
          />
        </>
      );
  };

  if (cycleCount[cycleCountData] && cycleCount[cycleCountData].length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='cycleCount-withData'
          header={<Header />}
          content={loading[cycleCountData] ? <CustomLoader id='cycleCount-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='cycleCount-withoutData'
          header={<Header />}
          content={
            loading[cycleCountData] ? (
              <CustomLoader id='cycleCount-load' />
            ) : (
              <CustomError
                id='cycleCount-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};
export default CycleCountVariance;
