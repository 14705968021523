import React from 'react';
import Typography from '@mui/material/Typography';
import {CustomCard} from 'components/baseComponents/customCard';
import {styled} from '@mui/system';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {urgentStoreActions} from 'redux/dashboard/urgentStore/urgentStoreSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {Box, Button, useTheme} from '@mui/material';
import {generatePayload} from 'util/arrayOperations';
import {TicketingSystemUrl} from 'config/constants';

const CustomBox = styled(Box)({
  '& .MuiPaper-root .MuiCardContent-root > div': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
});

interface IcareAlertItem {
  time: string;
  title: string;
}
interface IemptyAlertItem {
  id: string;
}

interface IPropType {
  filteredData: any;
}

const Icare: React.FC<IPropType> = (props) => {
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  /**
   * dispatch urgentStore tile data
   */
  const dispatchData = () => {
    dispatch({
      type: urgentStoreActions.FETCH_URGENTSTORE_SAGA,
      payload: {
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [props?.filteredData]);

  const {urgentStore, loading, error} = useAppSelector((state) => state.urgentStoreSlice);

  const IcareAlertItemWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grayscale?.[300]}`,
    height: 60,
    '& p': {
      fontSize: 14,
      borderRadius: 2,
      maxHeight: 24,
      color: theme.palette.primary.main,
      '&:last-child': {
        padding: '2px 8px',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.error.main,
        fontWeight: 'bold'
      }
    }
  });

  const AlertDropDownWrapper = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    '& span': {
      padding: '2px 5px',
      borderRadius: 3,
      maxHeight: 24,
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.error.main,
      '& p': {
        fontWeight: 500
      }
    }
  });

  const IcareAlertItem: React.FC<IcareAlertItem> = (props) => {
    return (
      <IcareAlertItemWrapper>
        <p>
          <Typography
            variant='subtitle2'
            textAlign='center'
            sx={{fontWeight: 500, letterSpacing: '0.25px', cursor: 'pointer'}}
            onClick={() => {
              window.open(`${TicketingSystemUrl}?${props?.title}`, '_blank');
            }}
          >
            {props?.title}
          </Typography>
        </p>
        <p>
          <Typography variant='body2' textAlign='center' sx={{fontFamily: 'Roboto Medium'}}>
            {props?.time}
          </Typography>
        </p>
      </IcareAlertItemWrapper>
    );
  };
  const IemptyAlertItem: React.FC<IemptyAlertItem> = (props) => {
    return (
      <CustomBox id={props?.id} sx={{textAlign: 'center'}}>
        <Typography variant='caption' sx={{letterSpacing: '0.25px', alignContent: 'center'}}>
          Good job! you cleared all Urgent Store <br /> Alerts. 30 unresolved tickets remaining,
          <br /> open ticketing system to view full list.
        </Typography>
        <Button
          sx={{
            '&:hover': {
              backgroundColor: 'white !important'
            },
            '@media (hover: none)': {
              '&:hover': {
                backgroundColor: 'white !important'
              }
            },
            alignContent: 'center'
          }}
        >
          <Typography
            variant='button'
            sx={{
              color: theme.palette.primary.main,
              marginTop: '60px',
              textAlign: 'center'
            }}
            onClick={() => {
              window.open(`${TicketingSystemUrl}`, '_blank');
            }}
          >
            OPEN TICKETING SYSTEM
          </Typography>
        </Button>
      </CustomBox>
    );
  };

  const Header = () => {
    return (
      <AlertDropDownWrapper sx={{paddingRight: '16px'}}>
        <Typography sx={{color: 'common.black'}} variant='subtitle2' textAlign='center'>
          Urgent Store Alerts
        </Typography>
        {!loading && !error && urgentStore.length && (
          <span>
            <Typography variant='body2' textAlign='center' sx={{fontWeight: 'bold'}}>
              {urgentStore.length > 0 && urgentStore.length}
            </Typography>
          </span>
        )}
      </AlertDropDownWrapper>
    );
  };

  const Content = () => {
    return (
      <>
        {urgentStore.map((item: any, index: number) => {
          return <IcareAlertItem key={`${item}-${index}`} time={item.time} title={item.store} />;
        })}
      </>
    );
  };

  if (urgentStore && urgentStore.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='urgentCare-ID'
          header={<Header />}
          content={loading ? <CustomLoader id='urgent-ICare-load' /> : <Content />}
          headerSx={{
            height: '32px'
          }}
        />
      </CustomBox>
    );
  } else if (urgentStore && urgentStore.length === 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='emptyUrgentCare-ID'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='urgent-ICare-load' />
            ) : (
              <IemptyAlertItem id='urgent-ICare-empty' />
            )
          }
          headerSx={{
            height: '32px'
          }}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='urgentCare'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='urgent-ICare-load' />
            ) : (
              <CustomError
                id='urgent-ICare-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
          headerSx={{
            height: '32px'
          }}
        />
      </CustomBox>
    );
  }
};

export default Icare;
