import {createSlice} from '@reduxjs/toolkit';
export interface notificationState {
  loading: boolean;
  notification: {};
  error: string;
}

const initialState: notificationState = {
  loading: false,
  notification: {},
  error: ''
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationLoading: (state) => {
      state.loading = true;
    },
    notificationSuccess: (state, action) => {
      state.notification = action.payload;
      state.loading = false;
    },
    notificationFailure: (state) => {
      state.loading = false;
      state.notification = {};
      state.error = 'Request failed with status code 404';
    }
  }
});

export default notificationSlice.reducer;
export const {notificationLoading, notificationSuccess, notificationFailure} =
  notificationSlice.actions;
