import {TableHead, TableRow, Typography, Box, Stack, Tooltip} from '@mui/material';
import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {BorderedTableStickyCell, TableStickyCell} from 'components/customTable/tableCell';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import StyledChip from 'components/baseComponents/customChip';
import {districtSagaActions} from 'redux/dashboard/master/districtSaga';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {packSagaActions} from 'redux/dashboard/master/packSaga';
import {regionSagaActions} from 'redux/dashboard/master/regionSaga';
import {sizeSagaActions} from 'redux/dashboard/master/sizeSaga';
import {tagSagaActions} from 'redux/dashboard/master/tagSaga';
import {distrubutionCenterSagaActions} from 'redux/dashboard/master/distrubutionCenterSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {generateLabelValue} from 'util/arrayOperations';
import {productStoreListSagaActions} from 'redux/product/list/productStoreList/productStoreListSaga';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {vendorSagaActions} from 'redux/dashboard/master/vendorSaga';
import {alertSagaActions} from 'redux/dashboard/master/alertSaga';
import {viewInFilterOptions} from 'config/constants';
import {useTheme} from '@mui/material';

interface IPropType {
  atpEnabler?: (val: boolean) => void;
}

interface IProductStore {
  itemName: string;
  itemId: string;
  pack: string;
  size: string;
  tags: [string];
  alert: [string];
  [key: string]: any;
  id: string;
}

interface IStoreData {
  storeNo: string;
  onHand: string;
  onOrder: string;
  fctsWos: string;
}

interface IProductStoreListProps {
  itemName: string;
  itemId: string;
  pack: string;
  size: string;
  store: [IStoreData];
  tags: [string];
  alert: [string];
  id: string;
}

interface IStaticGridRows {
  data: IProductStore[];
  storeData: IProductStoreListProps[];
}

const ContentComponent = (row: any) => {
  if (row.itemName.length > 20)
    return (
      <Tooltip title={row.itemName} placement='top'>
        <Box
          sx={{
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box'
          }}
        >
          {row.itemName}
        </Box>
      </Tooltip>
    );
  else {
    return <Box sx={{maxWidth: '100px'}}>{row.itemName}</Box>;
  }
};

const StoreList: React.FC<IPropType> = (props) => {
  const location: any = useLocation();
  const theme: any = useTheme();

  const {productStoreList, error, loading} = useAppSelector(
    (state: any) => state.productStoreListSlice
  );

  const LinkComponent = (row: any) => {
    return (
      <Box
        sx={{
          '& a:hover, a:visited, a:focus': {
            color: theme.palette?.primary.main
          }
        }}
      >
        <Link to={{pathname: `/Product/${row.itemId}`}} state={row}>
          {row.itemId}
        </Link>{' '}
      </Box>
    );
  };

  const columnCell: IColumnCell<IProductStore>[] = [
    {
      id: 'itemId',
      name: 'itemId',
      displayName: 'Item Id',
      customCell: LinkComponent,
      headerTextAlign: 'center',
      sticky: true,
      sx: {
        minWidth: '80px'
      }
    },
    {
      id: 'itemName',
      name: 'itemName',
      displayName: 'Item',
      sticky: true,
      hasTags: true,
      customCell: ContentComponent,
      headerTextAlign: 'left',
      cellTextAlign: 'left',
      sx: {
        minWidth: '200px'
      }
    },
    {
      id: 'pack',
      name: 'pack',
      isColumnSortable: false,
      headerTextAlign: 'left',
      cellTextAlign: 'right',
      sticky: true,
      sx: {
        minWidth: '50px'
      }
    },
    {
      id: 'size',
      name: 'size',
      isNumeric: true,
      isGroupBorderRequire: true,
      headerTextAlign: 'right',
      isColumnSortable: false,
      sticky: true,
      cellTextAlign: 'right',
      sx: {
        minWidth: '60px'
      }
    }
  ];

  const StickyHeaderCell = (props: IStaticGridRows) => {
    const singleRowElement: IProductStoreListProps =
      props.storeData && props.storeData[0] ? props.storeData[0] : ({} as IProductStoreListProps);
    const rowCellsData = singleRowElement?.store?.map((storeItem: IStoreData) => {
      const element = storeItem.storeNo;
      const options = ['onHand', 'onOrder', 'fctsWos'];
      const consolidatedData = props.data.reduce((accumulator: any, c: any) => {
        options.forEach((ele) => {
          if (accumulator[ele]) {
            accumulator[ele] = accumulator[ele] + parseFloat(c[`${element}.${ele}`]);
          } else {
            accumulator[ele] = parseFloat(c[`${element}.${ele}`]);
          }
        });
        return accumulator;
      }, {});
      return (
        <>
          <TableStickyCell sx={{top: '64px'}} colSpan={1} align='center'>
            <Typography variant='subtitle2'>${consolidatedData.onHand}</Typography>
          </TableStickyCell>
          <TableStickyCell sx={{top: '64px'}} colSpan={1} align='center'>
            <Typography variant='subtitle2'>${consolidatedData.onOrder}</Typography>
          </TableStickyCell>
          <BorderedTableStickyCell sx={{top: '64px'}} align='center'>
            <Typography variant='subtitle2'>{consolidatedData.fctsWos.toFixed(1)}</Typography>
          </BorderedTableStickyCell>
        </>
      );
    });
    return (
      <>
        <TableHead>
          <TableRow>
            <BorderedTableStickyCell
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'Background',
                zIndex: theme.zIndex?.appBar + 20,
                top: '64px'
              }}
            >
              <TableStickyCell align='right' sx={{top: '64px'}}></TableStickyCell>
              <TableStickyCell
                colSpan={4}
                sx={{
                  color: 'grayscale.400',
                  borderBottom: 0,
                  fontWeight: 'bold'
                }}
              >
                <Typography
                  variant='subtitle2'
                  sx={{
                    height: '20px',
                    width: '496px',
                    color: theme.palette?.grayscale?.[400],
                    fontFamily: 'Roboto Medium',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '0.2px',
                    paddingLeft: '8px'
                  }}
                >
                  CONSOLIDATED STATS
                </Typography>
              </TableStickyCell>
            </BorderedTableStickyCell>
            {rowCellsData}
          </TableRow>
        </TableHead>
      </>
    );
  };

  /**
   * OnMouse hover we get this component to render
   * @param row Grid row data
   * @returns Component

  /**
   * Mapper for converting IProductStoreListProps to IProductStore
   * @param listState  Array of IProductStoreListProps
   * @returns return IProductStore[]
   */
  const formateRowData = (listState: IProductStoreListProps[]) => {
    const gridData: IProductStore[] = listState.map((ele: IProductStoreListProps) => {
      const storeItem: IProductStore = {
        ...ele,
        hasAlert: ele.alert.length ? true : false
      };
      const storeNoItem: IStoreData[] = ele.store;
      storeNoItem.forEach((item: IStoreData) => {
        const {storeNo, onHand, onOrder, fctsWos} = item;
        storeItem[item.storeNo] = storeNo;
        storeItem[`${item.storeNo}.onHand`] = onHand;
        storeItem[`${item.storeNo}.onOrder`] = onOrder;
        storeItem[`${item.storeNo}.fctsWos`] = fctsWos;
      });
      delete storeItem.store;
      return storeItem;
    });
    return gridData;
  };

  /**
   * Add the Store column into grid
   * @param listState  Array of IProductStoreListProps
   * @returns return columnCell for grid
   */
  const addColumnCellData = (listState: IProductStoreListProps[]) => {
    listState[0]?.store?.forEach((item: IStoreData) => {
      const element = item.storeNo;

      if (!columnCell.find((e) => e.id === `${element}`)) {
        columnCell.push({
          id: `${element}`,
          name: `${element}`,
          displayName: element,
          isGroupBorderRequire: true,
          isNumeric: true,
          isGroupedCell: true,
          groupedKeys: [`${element}.onHand`, `${element}.onOrder`, `${element}.fctsWos`],
          groupSortKey: 'onHand',
          groupDisplayKeys: ['on Hand', 'on Order', 'fcst WOS'],
          isColumnSortable: false,
          sx: {
            textAlign: 'center',
            minWidth: '80px',
            whiteSpace: 'nowrap'
          }
        });
      }
    });
    return columnCell;
  };

  const rowData: IProductStore[] =
    productStoreList.length > 0 ? formateRowData(productStoreList) : [];

  const dispatch = useAppDispatch();
  //For Binding the MasterData
  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
    dispatch({
      type: tagSagaActions.FETCH_TAG_DATA
    });
    dispatch({
      type: vendorSagaActions.FETCH_VENDOR_DATA
    });
    dispatch({
      type: districtSagaActions.FETCH_DISTRICT_DATA
    });
    dispatch({
      type: regionSagaActions.FETCH_REGION_DATA
    });
    dispatch({
      type: distrubutionCenterSagaActions.FETCH_DISTRUBUTION_CENTER_DATA
    });
    dispatch({
      type: sizeSagaActions.FETCH_SIZE_DATA
    });
    dispatch({
      type: packSagaActions.FETCH_PACK_DATA
    });
    dispatch({
      type: alertSagaActions.FETCH_ALERT_DATA
    });
  }, []);

  const {master} = useAppSelector((state) => state.masterSlice);
  const [dataOne, dataTwo, dataThree] = master;
  const {vendor} = useAppSelector((state) => state.vendorSlice);
  const {district} = useAppSelector((state) => state.districtSlice);
  const {size} = useAppSelector((state) => state.sizeSlice);
  const {pack} = useAppSelector((state) => state.packSlice);
  const {region} = useAppSelector((state) => state.regionSlice);
  const {alert} = useAppSelector((state) => state.alertSlice);
  const {distrubutionCenter} = useAppSelector((state) => state.distrubutionCenterSlice);

  //For Binding the MasterTag data
  const {tag} = useAppSelector((state) => state.tagSlice);

  /**
   * Create the filter array to send as API request
   * @param values Array of objects
   */
  const generatePayload = (values: any) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      temp.push({key: key, value: value, condition: 'equal'});
    }
    return temp;
  };

  /**
   * OnChange of filter
   * @param values Filter values
   */
  const onFilterApply = (values: IFilterValues) => {
    generatePayload(values);
    setFilterValues(values);
  };

  ///Filter Template in json format
  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeRegion',
      title: 'Store Region',
      defaultValue: 'All Regions',
      placeholder: 'Regions',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(region, 'storeRegion')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'storeDistrict',
      title: 'Store District',
      defaultValue: 'All Districts',
      placeholder: 'Districts',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(district, 'storeDistrict')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'distributionCenter',
      title: 'Distribution Center',
      defaultValue: 'All Distribution Center',
      placeholder: 'Distribution Center',
      sx: {
        width: '150px'
      },
      options: generateLabelValue(distrubutionCenter, 'distributionCenter')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View in (OH, OO)',
      defaultValue: 'Units',
      sx: {
        width: '150px'
      },
      options: viewInFilterOptions
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'departmentName',
      title: 'Department',
      placeholder: 'Departments',
      defaultValue: 'All Departments',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      options: generateLabelValue(dataOne?.data, 'departmentName', 'departmentName') //masterdata, display label, value, dependsOn
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'departmentName',
      //masterdata, display label, value(can be label or id associated with the options), dependsOn(compulsory if dependantFilterName is present)
      // when targetType = 'value' last param in below function should be id or else it should be label string
      options: generateLabelValue(dataTwo?.data, 'className', 'className', 'departmentName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'subClassName', //must be similar to porperty name recieved in API mentioning option value
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      sx: {
        width: '150px'
      },
      dependantFilterName: 'className',
      options: generateLabelValue(dataThree?.data, 'subClassName', 'subClassName', 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'tag',
      title: 'Tag',
      defaultValue: 'All Tags',
      placeholder: 'Tags',
      mode: 'Secondary',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(tag, 'tag')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'vendor',
      title: 'Vendor',
      defaultValue: 'All Vendors',
      placeholder: 'Vendors',
      mode: 'Secondary',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(vendor, 'vendorName')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'alert',
      title: 'Alert',
      defaultValue: 'All Alerts',
      placeholder: 'Alerts',
      mode: 'Secondary',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(alert, 'alert')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'pack',
      title: 'Pack',
      defaultValue: 'All Pack',
      placeholder: 'Pack',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(pack, 'pack')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      property: 'size',
      title: 'Size',
      defaultValue: 'All Size',
      placeholder: 'Size',
      sx: {
        width: '178px'
      },
      mode: 'Secondary',
      options: generateLabelValue(size, 'size')
    }
  ];

  const defaultData = filterTemplate.reduce((acc: any, obj: IFilterTemplate) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const [filtervalues, setFilterValues] = useState(defaultData);
  /**
   * dispatch productStoreList data
   */
  const dispatchData = () => {
    dispatch({
      type: productStoreListSagaActions.FETCH_PRODUCT_STORE_LIST_DATA,
      payload: generatePayload(filtervalues)
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);
  const getRowIds = (ids: readonly string[]) => {
    //type should be same as selected state in enhanced table
    props.atpEnabler && props.atpEnabler(!(ids.length > 0));
  };

  if (productStoreList && productStoreList.length > 0 && !error) {
    return (
      <>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          id='product-storeList'
          onReset={() => {
            setFilterValues(defaultData);
          }}
        />
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='product-store-load' />
          </Box>
        )}
        <EnhancedTable<IProductStore>
          height={844}
          rowData={rowData}
          stickyRows={<StickyHeaderCell data={rowData} storeData={productStoreList} />}
          columnCell={addColumnCellData(productStoreList)}
          rowKey={'itemName'}
          defaultSortKey={'itemName'}
          showTags={true}
          componentType='paper'
          allowAlternateColor={true}
          getSelectedIds={getRowIds}
          rowsPerPage={25}
          selectable
          showAlertPopup
          id='productList-storeList'
        />
      </>
    );
  } else {
    return (
      <>
        <GridFilter
          values={filtervalues}
          filterTemplate={filterTemplate}
          onFilterChange={onFilterApply}
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='product-storeList'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {loading ? (
            <CustomLoader id='product-store-load' />
          ) : (
            <CustomError
              id='product-store-error'
              onReload={() => {
                dispatchData();
              }}
            />
          )}
        </Box>
      </>
    );
  }
};

export default StoreList;
