import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {expiryFailure, expiryLoading, expirySuccess} from 'redux/dashboard/expiryTile/expirySlice';

export const expirySagaActions = {
  FETCH_EXPIRY_DATA: 'FETCH_EXPIRY_DATA'
};

export function* fetchExpiryData(action: any) {
  try {
    yield put(expiryLoading());
    const {data} = yield call(DashboardServiceAPIS.getExpiryData, action.payload);
    yield put(expirySuccess(data));
  } catch (error) {
    yield put(expiryFailure('error occured'));
  }
}

export default function* watchFetchExpiryData() {
  yield takeEvery(expirySagaActions.FETCH_EXPIRY_DATA, fetchExpiryData);
}
