import {createSlice} from '@reduxjs/toolkit';
export interface freightSummaryState {
  loading: boolean;
  freightSummary: any;
  error: string;
}

const initialState: freightSummaryState = {
  loading: false,
  freightSummary: {},
  error: ''
};

const freightSummarySlice = createSlice({
  name: 'freightSummary',
  initialState,
  reducers: {
    freightSummaryLoading: (state) => {
      state.loading = true;
    },
    freightSummarySuccess: (state, action) => {
      state.freightSummary = action.payload;
      state.loading = false;
    },
    freightSummaryFailure: (state) => {
      state.loading = false;
      state.freightSummary = {};
      state.error = 'Request failed with status code 404';
    }
  }
});

export default freightSummarySlice.reducer;
export const {freightSummaryLoading, freightSummarySuccess, freightSummaryFailure} =
  freightSummarySlice.actions;
