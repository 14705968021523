import * as React from 'react';
import {Box} from '@mui/material';
import CustomSelect from 'components/baseComponents/customSelect';
import CustomMultiSelect from 'components/baseComponents/customMultiSelect';
import {filterFieldTypes} from 'components/customTable/types';
import {IFilterValues} from 'components/customTable/gridFilter';
import CustomDateRange from 'components/baseComponents/customDateRange';
import CustomRangeTextBox from 'components/baseComponents/customRangeTextbox';

interface IFilterMapper {
  id: string;
  filter: any;
  onFieldValueChange: (e: any) => void;
  values: IFilterValues;
  index: number;
}

const FilterMapper: React.FC<IFilterMapper> = (props: IFilterMapper) => {
  const {id, filter, onFieldValueChange, values, index} = props;
  const fieldValue = values[filter.property];
  switch (filter.type) {
    case filterFieldTypes.DATE_RANGE:
      return (
        <>
          <CustomDateRange
            id={`${id}-${filter.property}`}
            name={filter.property}
            value={fieldValue}
            onChange={onFieldValueChange}
            {...filter}
          />
        </>
      );
    case filterFieldTypes.MULTISELECT:
      return (
        <CustomMultiSelect
          id={`${id}-${filter.property}`}
          key={`multiSelect${index}`}
          name={filter.property}
          options={filter.options}
          onMultiSelectApply={onFieldValueChange}
          value={fieldValue}
          {...filter}
        />
      );
    case filterFieldTypes.RANGE_TEXTBOX:
      return (
        <Box sx={{mt: 2, mb: 2}}>
          <CustomRangeTextBox
            id={`${id}-${filter.property}`}
            name={filter.property}
            onChange={onFieldValueChange}
            value={fieldValue}
            {...filter}
          />
        </Box>
      );
    case filterFieldTypes.DROPDOWN:
    default:
      return (
        <CustomSelect
          id={`${id}-${filter.property}}`}
          key={`dropDown-${filter.property}-${index}-${filter.options.length}`}
          name={filter.property}
          onChange={(e) => {
            let selectedData = e.target.value;
            if (filter.targetType === 'label') {
              const obj = filter.options.find((ele: any) => {
                return ele.value === e.target.value;
              });
              selectedData = obj.label ? obj.label : e.target.value;
            } else {
              selectedData = e.target.value;
            }
            const event = {
              target: {
                value: selectedData,
                name: filter.property
              }
            };
            onFieldValueChange(event);
          }}
          value={fieldValue}
          {...filter}
          options={filter.options} //option must be declared after all other props are destructered in above line
        />
      );
  }
};

export default FilterMapper;
