import {takeEvery, put, call} from 'redux-saga/effects';
import {ReportAPIS} from '../../report_service';
import {
  perpetualInventoryLoading,
  perpetualInventorySuccess,
  perpetualInventoryFailure
} from './perpetualInventorySlice';

export const perpetualInventorySagaActions = {
  FETCH_PERPETUAL_SAGA: 'FETCH_PERPETUAL_SAGA'
};

export function* fetchPerpetualInventoryData() {
  try {
    yield put(perpetualInventoryLoading());
    const {data} = yield call(ReportAPIS.getPerpetualInventoryData);
    yield put(perpetualInventorySuccess(data));
  } catch (error) {
    yield put(perpetualInventoryFailure());
  }
}

export default function* watchFetchPerpetualInventoryData() {
  yield takeEvery(perpetualInventorySagaActions.FETCH_PERPETUAL_SAGA, fetchPerpetualInventoryData);
}
