import {Box, styled} from '@mui/material';
import React from 'react';
import BarGraphComponent, {IAxis} from 'components/barGraph';
import {DropDownWrapper} from 'util/commonStyles';
import CustomSelect from 'components/baseComponents/customSelect';
import {CustomCard} from 'components/baseComponents/customCard';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {sagaActions} from 'redux/dashboard/sales/salesSaga';
import {useTheme} from '@mui/material/styles';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import {generatePayload} from 'util/arrayOperations';

const CustomBox = styled(Box)({
  '& div.user-select-none': {
    left: '-2px'
  },
  '& .MuiPaper-root .MuiCardContent-root .MuiBox-root:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

interface IPropType {
  defaultValue: string;
  filteredData: any;
}

const dropdownData = [
  {
    label: 'Class Sales',
    value: 'class'
  },
  {
    label: 'Department Sales',
    value: 'department'
  },
  {
    label: 'Store Sales',
    value: 'store'
  }
];

const SalesTile: React.FC<IPropType> = (props) => {
  const [salesType, setSalesType] = React.useState(props.defaultValue);
  const [name, setName] = React.useState('departmentName');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  /**
   * dispatch sales tile data
   */
  const dispatchData = () => {
    dispatch({
      type: sagaActions.FETCH_DATA_SAGA,
      payload: {
        type: salesType,
        data: generatePayload(props?.filteredData)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [salesType, props?.filteredData]);

  const {sales, loading, error} = useAppSelector((state) => state.salesSlice);

  const handleSelect = (event: any): void => {
    dispatch({
      type: sagaActions.FETCH_DATA_SAGA,
      payload: {
        ...props?.filteredData,
        type: event?.target.value
      }
    });
    let updateType = salesType;
    if (event?.target.value === 'department') {
      updateType = 'departmentName';
    } else if (event?.target.value === 'store') {
      updateType = 'storeName';
    } else {
      updateType = 'className';
    }
    setSalesType(event?.target.value);
    setName(updateType);
  };

  const generateContent = () => {
    switch (salesType) {
      case 'department':
        return (
          <BarGraphComponent
            id='departmentSalesCompBar'
            data={sales}
            xAxis={[...xAxis]}
            yAxis={yAxis}
            showPlan={true}
            plan={planAxis}
            redirectUrl={'products'}
          />
        );
      case 'store':
        return (
          <BarGraphComponent
            id='storeSalesCompBar'
            data={sales}
            xAxis={[...xAxis]}
            yAxis={yAxis}
            showPlan={true}
            plan={planAxis}
            redirectUrl={'products'}
          />
        );
      default:
        return (
          <BarGraphComponent
            id='classSalesCompBar'
            data={sales}
            xAxis={[...xAxis]}
            yAxis={yAxis}
            showPlan={true}
            plan={planAxis}
            redirectUrl={'products'}
          />
        );
    }
  };
  const xAxis: Array<IAxis> = [
    {
      name: 'yearToDate',
      displayTitle: 'Year to Date',
      color: theme.palette.primary.dark,
      unit: 'K',
      prefix: '$'
    },
    {
      name: 'lastYear',
      displayTitle: 'Last Year',
      color: theme.palette.neutral?.secondary2,
      unit: 'K',
      prefix: '$'
    }
  ];

  const yAxis: IAxis = {
    name: name,
    displayTitle: 'name',
    unit: 'K',
    prefix: '$'
  };

  const planAxis: IAxis = {
    name: 'plan',
    displayTitle: 'plan',
    color: theme.palette.neutral?.secondary1,
    unit: 'K',
    prefix: '$'
  };

  const Header = () => {
    return (
      <DropDownWrapper>
        <CustomSelect
          id='departmentCompTile'
          name={'departmentCompTileSelect'}
          options={dropdownData}
          onChange={handleSelect}
          value={salesType}
        />
      </DropDownWrapper>
    );
  };

  const Content = () => {
    return <CustomBox>{generateContent()}</CustomBox>;
  };

  if (sales && sales.length > 0 && !error) {
    return (
      <CustomBox>
        <CustomCard
          id='saleTile-data'
          header={<Header />}
          content={loading ? <CustomLoader id='sales-load' /> : <Content />}
        />
      </CustomBox>
    );
  } else {
    return (
      <CustomBox>
        <CustomCard
          id='saleTile-withoutData'
          header={<Header />}
          content={
            loading ? (
              <CustomLoader id='sales-load' />
            ) : (
              <CustomError
                id='sales-error'
                onReload={() => {
                  dispatchData();
                }}
              />
            )
          }
        />
      </CustomBox>
    );
  }
};

export default SalesTile;
