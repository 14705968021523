import {takeEvery, put, call} from 'redux-saga/effects';
import {DashboardServiceAPIS} from 'redux/dashboard/dashboard_service';
import {
  adPromosLoading,
  adPromosSuccess,
  adPromosFailure
} from 'redux/dashboard/adPromos/adPromosSlice';

export const adPromosSagaActions = {
  FETCH_AD_PROMOS_DATA: 'FETCH_AD_PROMOS_DATA'
};

export function* fetchAdPromosData(action: any): any {
  try {
    yield put(adPromosLoading());
    const {data} = yield call(DashboardServiceAPIS.getAdPromosData, action.payload);
    yield put(adPromosSuccess(data));
  } catch (error) {
    yield put(adPromosFailure());
  }
}

export default function* watchFetchAdPromos() {
  yield takeEvery(adPromosSagaActions.FETCH_AD_PROMOS_DATA, fetchAdPromosData);
}
