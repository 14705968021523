import {useTheme} from '@emotion/react';
import {Box, Tooltip, Typography} from '@mui/material';
import {CustomError, CustomLoader} from 'components/baseComponents/customMessage';
import EnhancedTable from 'components/customTable/enhancedTable';
import GridFilter, {IFilterValues} from 'components/customTable/gridFilter';
import {filterFieldTypes, IColumnCell, IFilterTemplate} from 'components/customTable/types';
import {viewInFilterOptions} from 'config/constants';
import React, {useState} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {masterSagaActions} from 'redux/dashboard/master/masterSaga';
import {productListSagaActions} from 'redux/product/list/productList/productListSaga';
import {useAppDispatch, useAppSelector} from 'store/configureStore';
import {generateLabelValue} from 'util/arrayOperations';

interface IProductGridProps {
  itemId: string;
  itemName: string;
  upc: string;
  vendorData: string;
  pack: string;
  size: string;
  averageCost: string;
  averageRetail: string;
  onHand: string;
  onOrder: string;
  averageWeeklySales: string;
  last4Weeks: string;
  averageMargin: string;
}

const generateFilterTemplate = (masterData: Array<any>, size: any, pack: any) => {
  const [dataOne, dataTwo, dataThree] = masterData;
  const filterTemplate: IFilterTemplate[] = [
    {
      type: filterFieldTypes.DROPDOWN,
      id: 'product-search-filter-className',
      property: 'className',
      title: 'Class',
      defaultValue: 'All Classes',
      placeholder: 'Classes',
      allowAllOptions: true,
      sx: {
        width: '140px'
      },
      options: generateLabelValue(dataTwo?.data, 'className')
    },
    {
      type: filterFieldTypes.DROPDOWN,
      id: 'product-search-filter-subClassName',
      property: 'subClassName',
      title: 'Subclass',
      defaultValue: 'All Subclasses',
      placeholder: 'Subclasses',
      allowAllOptions: true,
      sx: {
        width: '140px'
      },
      options: generateLabelValue(dataThree?.data, 'subClassName')
    },
    {
      id: 'prduct-overview-filter-all-pack',
      type: filterFieldTypes.DROPDOWN,
      property: 'pack',
      title: 'Pack',
      defaultValue: 'All Pack',
      placeholder: 'Pack',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(pack, 'pack')
    },
    {
      id: 'prduct-overview-filter-size',
      type: filterFieldTypes.DROPDOWN,
      property: 'size',
      title: 'Size',
      defaultValue: 'All Size',
      placeholder: 'Size',
      sx: {
        width: '178px'
      },
      options: generateLabelValue(size, 'size')
    },
    {
      id: 'product-search-filter-viewIn',
      type: filterFieldTypes.DROPDOWN,
      property: 'viewIn',
      title: 'View in (OH, OO)',
      defaultValue: 'Dollars',
      sx: {
        width: '140px'
      },
      options: viewInFilterOptions
    }
  ];
  return filterTemplate;
};

const ProductSearch: React.FC = () => {
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const params = useParams();
  const searchText = params.searchText || '';

  React.useEffect(() => {
    dispatch({
      type: masterSagaActions.FETCH_MASTER_DATA
    });
  }, []);

  const {productSearchList, productSearchLoading} = useAppSelector(
    (state) => state.productListSlice
  );
  const {master} = useAppSelector((state) => state.masterSlice);
  const {size} = useAppSelector((state) => state.sizeSlice);
  const {pack} = useAppSelector((state) => state.packSlice);

  const defaultData = generateFilterTemplate(master, size, pack).reduce((acc: any, obj) => {
    if (!acc[obj.property]) {
      acc[obj['property']] = null;
    }
    // Add value to list for given key's value
    acc[obj['property']] =
      location.state?.filter && location?.state?.filter[obj.property]
        ? location?.state?.filter[obj.property]
        : obj.defaultValue || '';
    return acc;
  }, {});

  const generatePayload = (values: IFilterValues) => {
    const temp = [];
    for (const [key, value] of Object.entries(values)) {
      if (value && value != '' && value.toString().substring(0, 4).toLowerCase() != 'all ') {
        if (key === 'date') {
          temp.push({key: key, value: value, condition: 'BETWEEN'});
        } else {
          temp.push({key: key, value: value, condition: 'EQUAL'});
        }
      }
    }
    return temp;
  };

  const [filtervalues, setFilterValues] = useState(defaultData);
  const onFilterApply = (values: IFilterValues) => {
    setFilterValues(values);
  };
  const dispatchData = () => {
    dispatch({
      type: productListSagaActions.FETCH_PRODUCTLISTSEARCH_DATA,
      payload: {
        itemName: searchText,
        filter: generatePayload(filtervalues)
      }
    });
  };
  React.useEffect(() => {
    dispatchData();
  }, [filtervalues]);

  const ItemComponent = (row: any) => {
    if (row.itemDescription.length > 28)
      return (
        <Tooltip title={row.itemDescription} placement='top'>
          <Box
            sx={{
              maxWidth: '210px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              boxSizing: 'border-box'
            }}
          >
            {row.itemDescription}
          </Box>
        </Tooltip>
      );
    else {
      return <Box sx={{maxWidth: '210px'}}>{row.itemDescription}</Box>;
    }
  };

  const LinkComponent = (row: any) => {
    return (
      <Box
        sx={{
          '& a:hover, a:visited, a:focus': {
            color: theme?.palette?.primary?.main
          },
          textAlign: 'center'
        }}
      >
        <Link to={{pathname: `/Product/${row.itemId}`}} state={row}>
          {row.itemId}
        </Link>{' '}
      </Box>
    );
  };
  const columnCell: IColumnCell<IProductGridProps>[] = [
    {
      id: 'itemId',
      name: 'itemId',
      displayName: 'Item Id',
      customCell: LinkComponent,
      sticky: true,
      headerTextAlign: 'center',
      sx: {
        minWidth: '150px'
      }
    },
    {
      id: 'itemName',
      name: 'itemName',
      displayName: 'Item',
      sticky: true,
      hasTags: true,
      customCell: ItemComponent,
      headerTextAlign: 'left',
      cellTextAlign: 'left',
      sx: {
        minWidth: '220px'
      }
    },
    {
      id: 'upc',
      name: 'upc',
      displayName: 'UPC',
      sticky: true,
      headerTextAlign: 'left',
      cellTextAlign: 'right',
      sx: {
        minWidth: '130px'
      }
    },
    {
      id: 'vendorData',
      name: 'vendor',
      displayName: 'vendor',
      sticky: true,
      headerTextAlign: 'left',
      cellTextAlign: 'right',
      sx: {
        minWidth: '180px'
      }
    },
    {
      id: 'pack',
      name: 'pack',
      isColumnSortable: false,
      sticky: true,
      isNumeric: true,
      headerTextAlign: 'left',
      cellTextAlign: 'right',
      sx: {
        minWidth: '50px'
      }
    },
    {
      id: 'size',
      name: 'size',
      isNumeric: true,
      isGroupBorderRequire: true,
      isColumnSortable: false,
      sticky: true,
      headerTextAlign: 'right',
      cellTextAlign: 'right',
      sx: {
        minWidth: '60px'
      }
    },
    {
      id: 'averageCost',
      name: 'averageCost',
      displayName: 'AVG COST',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$'
    },
    {
      id: 'averageRetail',
      name: 'averageRetail',
      displayName: 'AVG RTL',
      isNumeric: true,
      prefixText: '$',
      headerTextAlign: 'center',
      cellTextAlign: 'center'
    },
    {
      id: 'onHand',
      name: 'onHand',
      displayName: 'OH HAND',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$'
    },
    {
      id: 'onOrder',
      name: 'onOrder',
      displayName: 'ON ORDER',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$',
      isGroupBorderRequire: true
    },
    {
      id: 'averageWeeklySales',
      name: 'averageWeeklySales',
      displayName: 'AWS',
      isNumeric: true,
      prefixText: '$',
      headerTextAlign: 'center',
      cellTextAlign: 'center'
    },
    {
      id: 'last4Weeks',
      name: 'last4Weeks',
      displayName: 'L4W Sales',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$'
    },
    {
      id: 'averageMargin',
      name: 'averageMargin',
      displayName: 'AVG MGN',
      isNumeric: true,
      headerTextAlign: 'center',
      cellTextAlign: 'center',
      prefixText: '$'
    }
  ];

  const ProductSearchHeader = () => {
    return (
      <Typography
        variant='h4'
        sx={{
          color: 'common.black',
          lineHeight: 1.25,
          py: 1.5,
          width: '750px'
        }}
        textAlign='left'
      >
        Product Results for {searchText}
      </Typography>
    );
  };

  if (productSearchList && productSearchList.length > 0) {
    return (
      <>
        <ProductSearchHeader />
        <GridFilter
          id='product-search-overview'
          values={filtervalues}
          filterTemplate={generateFilterTemplate(master, size, pack)}
          onFilterChange={onFilterApply}
          hideMoreFilter
          onReset={() => {
            setFilterValues(defaultData);
          }}
        />
        {productSearchLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <CustomLoader id='product-list-load' />
          </Box>
        )}
        <EnhancedTable<IProductGridProps>
          id={'productSearchOverview'}
          height={844}
          rowData={productSearchList}
          columnCell={columnCell}
          rowKey={'itemId'}
          defaultSortKey={'itemId'}
          showTags={true}
          componentType='paper'
          allowAlternateColor={true}
          rowsPerPage={25}
          selectable
          showAlertPopup
        />
      </>
    );
  } else {
    return (
      <>
        <ProductSearchHeader />
        <GridFilter
          values={filtervalues}
          filterTemplate={generateFilterTemplate(master, size, pack)}
          onFilterChange={onFilterApply}
          hideMoreFilter
          onReset={() => {
            setFilterValues(defaultData);
          }}
          id='productSearchOverview'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            maxHeight: '844px'
          }}
        >
          {' '}
          {productSearchLoading ? (
            <CustomLoader id='product-search-load' />
          ) : (
            <CustomError id='product-search-error' onReload={() => dispatchData()} />
          )}
        </Box>
      </>
    );
  }
};
export default ProductSearch;
