import {takeEvery, put, call} from 'redux-saga/effects';
import {PurchaseOrderAPIS} from 'redux/purchaseOrder/purchaseOrder_service';
import {
  updatePOFailure,
  updatePOSuccess,
  updatePOLoading,
  clearUpdatePOLoading,
  clearUpdatePOFailure
} from 'redux/purchaseOrder/poList/updatePOSlice';

export const updatePOSagaActions = {
  FETCH_UPDATEPO_DATA: 'FETCH_UPDATEPO_DATA',
  FETCH_CLEARUPADTEPO_DATA: 'FETCH_CLEARUPADTEPO_DATA'
};

export function* fetchUpdatePOData(action: any): any {
  try {
    yield put(updatePOLoading());

    const {data} = yield call(PurchaseOrderAPIS.getUpdatePOData, action.payload);
    yield put(updatePOSuccess(data));
  } catch (error) {
    const errorMessage = error?.response?.data?.errorMessage || error.message;
    yield put(updatePOFailure(errorMessage));
  }
}
export default function* watchFetchUpdatePOData() {
  yield takeEvery(updatePOSagaActions.FETCH_UPDATEPO_DATA, fetchUpdatePOData);
}
//for clear UpdatePO
export function* fetchClearUpdatePOData(): any {
  yield put(clearUpdatePOLoading());
  yield put(clearUpdatePOFailure());
}

export function* watchFetchClearUpdatePOData() {
  yield takeEvery(updatePOSagaActions.FETCH_CLEARUPADTEPO_DATA, fetchClearUpdatePOData);
}
