import React from 'react';
import {Typography, Box, Stack} from '@mui/material';
import EnhancedTable from 'components/customTable/enhancedTable';
import {IColumnCell} from 'components/customTable/types';
import CustomInput from 'components/baseComponents/customInput';
import {IPOItems} from 'features/purchaseOrder/types';
interface IProductGridProps extends IPOItems {
  ln: string;
}
const CLSComponent = (row: any) => {
  const data = `${row?.classId} / ${row?.subCLassId}`;
  return <Box>{data}</Box>;
};

const PackComponent = (row: any) => {
  let data = '';
  if (row?.pack && row?.pack.trim() != '') {
    data = `${row?.pack} / ${row?.size}`;
  } else {
    data = row?.size;
  }
  return <Box>{data}</Box>;
};
const columnCell: IColumnCell<IProductGridProps>[] = [
  {
    id: 'ln',
    name: 'ln',
    displayName: 'LN',
    isColumnSortable: false,
    isGroupBorderRequire: true
  },
  {
    id: 'itemId',
    name: 'itemId',
    displayName: 'Item',
    isColumnSortable: false
  },
  {
    id: 'classId',
    name: 'classId',
    displayName: 'cls',
    customCell: CLSComponent,
    isColumnSortable: false
  },
  {
    id: 'itemDescription',
    name: 'itemDescription',
    displayName: 'description',
    isColumnSortable: false
  },
  {
    id: 'pack',
    name: 'pack',
    displayName: 'Pack/size',
    customCell: PackComponent,
    isGroupBorderRequire: true,
    isColumnSortable: false
  },
  {
    id: 'rcv',
    name: 'rcv',
    displayName: 'rcv',
    isColumnSortable: false
  },
  {
    id: 'asn',
    name: 'asn',
    displayName: 'asn',
    isColumnSortable: false
  },
  {
    id: 'quantity',
    name: 'quantity',
    displayName: 'qty',
    isColumnSortable: false,
    isNumeric: true,
    isGroupBorderRequire: true
  },
  {
    id: 'vendCost',
    name: 'vendCost',
    displayName: 'vend Cost',
    isNumeric: true,
    isColumnSortable: false,
    prefixText: '$',
    cellTextAlign: 'left',
    sx: {width: '100px', minWidth: '100px'}
  },
  {
    id: 'purchaseUnit',
    name: 'purchaseUnit',
    displayName: 'purchase Unit',
    isColumnSortable: false,
    isGroupBorderRequire: true,
    sx: {width: '100px', minWidth: '100px'}
  },
  {
    id: 'netCost',
    name: 'netCost',
    displayName: 'net Cost',
    isColumnSortable: false,
    prefixText: '$',
    cellTextAlign: 'left',
    isNumeric: true,
    sx: {width: '100px', minWidth: '100px'}
  },
  {
    id: 'estimatedFreight',
    name: 'estimatedFreight',
    displayName: 'EST Freight Cost',
    isNumeric: true,
    isColumnSortable: false,
    prefixText: '$',
    cellTextAlign: 'left',
    sx: {width: '100px', minWidth: '120px'}
  },
  {
    id: 'landed',
    name: 'landed',
    displayName: 'landed',
    isColumnSortable: false,
    prefixText: '$',
    isNumeric: true
  },
  {
    id: 'retail',
    name: 'retail',
    displayName: 'retail',
    isColumnSortable: false,
    isGroupBorderRequire: true,
    prefixText: '$',
    isNumeric: true
  },
  {
    id: 'expiry',
    name: 'expiry',
    displayName: 'expiry',
    isColumnSortable: false,
    isGroupBorderRequire: true,
    sx: {
      textAlign: 'center'
    }
  },
  {
    id: 'status',
    name: 'status',
    displayName: 'status',
    isColumnSortable: false,
    isGroupBorderRequire: true,
    sx: {
      textAlign: 'center'
    }
  }
];

interface POItemView {
  poItems: any;
}

const POItemView: React.FC<POItemView> = (props: POItemView) => {
  const {poItems} = props;
  const handleRowData = (purchaseItems: any) => {
    if (purchaseItems && purchaseItems?.length > 0) {
      const Items: IProductGridProps[] = purchaseItems?.map((ele: any, index: number) => {
        return {
          ln: (index + 1).toString(),
          ...ele,
          asn: '-',
          rcv: '-',
          expiry: '-'
        };
      });
      return Items;
    } else return [];
  };

  return (
    <Box
      sx={{
        bgcolor: 'grayscale.50',
        borderRadius: 1,
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      }}
    >
      <Box
        sx={{
          height: '72px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: 'grayscale.50',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          borderBottom: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        <Typography variant='subtitle1' sx={{pl: 2}}>
          PO Items ({poItems && poItems?.length})
        </Typography>
        <Stack direction={'row'} sx={{alignItems: 'center'}}>
          <CustomInput
            sx={{
              backgroundColor: 'common.white',
              width: '358px',
              pr: 1,
              borderRadius: 1
            }}
            name='time'
            value={''}
            type='number'
            placeholder='Find on table by ID or name'
          />
        </Stack>
      </Box>
      {poItems && poItems?.length > 0 ? (
        <>
          <EnhancedTable<IProductGridProps>
            rowData={handleRowData(poItems)}
            columnCell={columnCell}
            rowKey={'itemId'}
            defaultSortKey={'ln'}
            defaultSortOrder={'desc'}
            allowAlternateColor={true}
            hidePagination={true}
            id='purchaseOrder-items-view'
          />
        </>
      ) : (
        <> No Records found </>
      )}
    </Box>
  );
};

export default POItemView;
